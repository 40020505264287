import React from "react";
import Button from "@mui/material/Button";
import MicIcon from "../../../icons/MicIcon";
import MicOffIcon from "../../../icons/MicOffIcon";
import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";

export default function ToggleAudioButton({
  disabled,
  className,
  textMobile,
}: {
  disabled?: boolean;
  className?: string;
  textMobile?: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some((track) => track.kind === "audio");

  return (
    <Button
      className={className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || disabled}
      startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      data-cy-audio-toggle
      data-testid="ToggleAudioButton"
    >
      <span className={textMobile}>
        {!hasAudioTrack ? "No Audio" : isAudioEnabled ? "Mute" : "Unmute"}
      </span>
    </Button>
  );
}
