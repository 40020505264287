import React, { useEffect } from "react";
import { debounce } from "lodash";
import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import { useGetCredentialedFacilities } from "hooks/dataCuration/useGetCredentialedFacilities";
import useTranslation from "hooks/useTranslation";
import { ProviderGeneralInfoAndLocation, TabPaneProps } from "../../types";

const PracticeSection: React.FC<TabPaneProps> = ({ shouldDisableForm }: TabPaneProps) => {
  const {
    web: {
      dataCuration: {
        providerProfile: {
          generalInformation: {
            practice: { website, practiceGroupsLabel, affiliationsLabel, affiliations, visitTypes },
          },
        },
      },
    },
  } = useTranslation();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [allFacilities, setAllFacilities] = React.useState<any>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [isAutocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const lastFetchedResults: any = React.useRef([]);

  const {
    providerGeneralInfoAndLocation: { practice },
    visitTypesList,
    affiliationsList,
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const { credentialedFacilitiesList, totalRecords, isLoading } = useGetCredentialedFacilities(
    page,
    1000,
    searchTerm,
  );

  const [formData, setFormData] = React.useState({
    ...practice,
  });

  // Debounced search input handler
  const handleSearch = React.useCallback(
    debounce((inputValue) => {
      setSearchTerm(inputValue);
      setPage(0); // Reset to page 0 on new search
      setAllFacilities([]); // Clear previous results
      setHasMore(true);
    }, 400),
    [],
  );

  const handleScrollEnd = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollHeight - scrollTop <= clientHeight + 10 && !isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getDeletedItems = (originalItems, newItems, idKey = "id") => {
    const deletedItems = originalItems
      .filter((item) => !newItems.some((newItem) => newItem[idKey] === item[idKey]))
      .map((item) => ({ ...item, deleted: true }));

    return deletedItems;
  };

  const handleChange = (key: string, e: any) => {
    const newValue = e?.target?.value !== undefined ? e.target.value : e;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: newValue,
    }));

    if (Array.isArray(practice[key]) && Array.isArray(newValue)) {
      const idKey = key === "visitTypes" ? "visitTypeId" : "id";
      const deletedItems = getDeletedItems(practice[key], newValue, idKey);

      setCurrentPayload((prev: ProviderGeneralInfoAndLocation) => ({
        ...(prev || {}),
        [key]: [...newValue, ...deletedItems],
      }));
    }

    if (!Array.isArray(practice[key]) && newValue !== practice[key]) {
      setCurrentPayload((prev: ProviderGeneralInfoAndLocation) => ({
        ...(prev || {}),
        [key]: newValue,
      }));
    }
  };

  const filterOptions = (options: any[], selectedValues: any[], key = "id") => {
    const selectedKeys = new Set(selectedValues.map((item) => item[key]));
    return options.filter((option) => !selectedKeys.has(option[key]));
  };

  useEffect(() => {
    if (credentialedFacilitiesList) {
      const newFacilities =
        page === 0 ? credentialedFacilitiesList : [...allFacilities, ...credentialedFacilitiesList];
      setAllFacilities(newFacilities);
      setHasMore(newFacilities.length < totalRecords);
      lastFetchedResults.current = newFacilities;
    }
  }, [credentialedFacilitiesList, totalRecords]);

  useEffect(() => {
    if (currentPayload === null) {
      setFormData({ ...practice });
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      style={{ padding: "24px 0 32px 0", width: "100%" }}
      alignItems="center"
      justifyContent="center"
      data-testid="practice-tab-section"
    >
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="website"
            name="website"
            size="medium"
            value={formData.practiceUrl ?? practice.practiceUrl ?? ""}
            placeholder="http://www.example.com"
            variant="outlined"
            label={website}
            onChange={(e) => handleChange("practiceUrl", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="Practice-WebsiteInput"
            inputProps={{ "data-testid": "Practice-WebsiteInput" }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="practice-group-autocomplete"
            data-testid="practice-group-autocomplete"
            disabled={shouldDisableForm}
            options={practice.practiceGroups || []}
            getOptionLabel={(option) => option.name}
            value={formData.practiceGroups || practice.practiceGroups || []}
            onChange={(event, newValue) =>
              handleChange("practiceGroups", { target: { value: newValue } })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={practiceGroupsLabel}
                placeholder="Select Practice Groups"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="network-affiliations-autocomplete"
            data-testid="network-affiliations-autocomplete"
            disabled={shouldDisableForm}
            options={filterOptions(affiliationsList || [], formData.networkAffiliations || [])}
            getOptionLabel={(option) => option.name}
            value={
              formData.networkAffiliations
                ? formData.networkAffiliations
                : practice.networkAffiliations ?? []
            }
            onChange={(e, newValue) =>
              handleChange("networkAffiliations", { target: { value: newValue } })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={affiliationsLabel}
                placeholder="Select Affiliations"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="practice-credentialedFacilities-autocomplete"
            data-testid="practice-credentialedFacilities-autocomplete"
            disabled={shouldDisableForm}
            options={filterOptions(allFacilities || [], formData.affiliatedHospitals || [])}
            open={isAutocompleteOpen} // Control the list visibility
            onOpen={() => {
              setAutocompleteOpen(true);
              if (!allFacilities.length && lastFetchedResults.current.length) {
                setAllFacilities(lastFetchedResults.current); // Restore previous results on refocus
              }
            }}
            onClose={() => setAutocompleteOpen(false)}
            getOptionLabel={(option) => option.name}
            value={formData.affiliatedHospitals ?? practice.affiliatedHospitals ?? []}
            onInputChange={(event, newInputValue) => handleSearch(newInputValue)}
            onChange={(event, newValue) =>
              handleChange("affiliatedHospitals", { target: { value: newValue } })
            }
            ListboxProps={{
              onScroll: handleScrollEnd,
            }}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={affiliations.title}
                placeholder="Select Facilities"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="practice-visit-types-autocomplete"
            disabled={shouldDisableForm}
            options={filterOptions(visitTypesList || [], formData.visitTypes || [], "epicId")}
            value={formData.visitTypes || practice.visitTypes || []}
            getOptionLabel={(option) => `${option.epicId} - ${option.visitTypeDisplayName}`}
            data-testid="practice-visit-types-autocomplete"
            onChange={(event, newValue) =>
              handleChange("visitTypes", { target: { value: newValue } })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={visitTypes}
                placeholder="Select Visit Type"
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PracticeSection;
