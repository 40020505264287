import React, { ReactNode, useEffect } from "react";
import { Message } from "@twilio/conversations";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  innerScrollContainer: {
    height: "calc(100vh - 115px)",
    boxSizing: "border-box",
    overflowY: "auto",
    padding: "10px 15px 60px 15px",
  },
  messageListContainer: {
    overflowY: "auto",
    flex: "1",
    paddingBottom: "1em",
  },
}));

interface MessageListScrollContainerProps {
  messages: Message[];
  children: ReactNode;
}

const MessageListScrollContainer = ({ children, messages }: MessageListScrollContainerProps) => {
  const { classes } = useStyles();

  const scrollToBottom = () => {
    const chatContainer = document.getElementById("chat-list");
    const anchor = document.getElementById("anchor");
    chatContainer?.scrollBy({ top: anchor?.offsetTop, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages?.length]);

  return (
    <div className={classes.innerScrollContainer} id="chat-list">
      <div className={classes.messageListContainer}>{children}</div>
      <div id="anchor" />
    </div>
  );
};

export default MessageListScrollContainer;
