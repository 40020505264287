export const QUERY_KEYS = {
  programs: "programs",
  department: "department",
  version: "version",
  staffList: "staffList",
  physicianList: "physicianList",
  specialtyList: "specialtyList",
  specimenTrackingLocations: "specimenTrackingLocations",
  specimenTrackingDepartments: "specimenTrackingDepartments",
  specimenTrackingHistory: "specimenTrackingHistory",
  specimenTrackingSpecimens: "specimenTrackingSpecimens",
  specimenTrackingLast: "specimenTrackingLast",
  specimenTrackingDeleteReason: "specimenTrackingDeleteReason",
  digitalImplantGet: "digitalImplantGet",
  campaignTags: "campaignTags",
  medicalExaminers: "medicalExaminers",
  networkAffiliations: "networkAffiliations",
  credentialedFacilities: "credentialedFacilities",
  locationTypes: "locationTypes",
  providerData: "providerData",
  dataCuration: "dataCuration",
  searchLocations: "searchLocations",
  specialtyServices: "specialtyServices",
  claimedSpecialties: "claimedSpecialties",
  conditionsAndSymptoms: "conditionsAndSymptoms",
};
