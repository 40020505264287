import React, { Ref } from "react";
import { CustomContentProps, closeSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";

interface AlertProps extends Omit<CustomContentProps, "variant"> {
  title?: string;
  severity?: "info" | "warning" | "error" | "success";
  variant?: "outlined" | "filled" | "standard"; // don't delete this or things get really stupid.
  action?: React.ReactNode;
}

export const DefaultAlert = React.forwardRef((props: AlertProps, ref: Ref<HTMLDivElement>) => {
  const { id, title, message, ...rest } = props;
  delete rest.variant;
  return (
    <Alert
      elevation={6}
      variant="filled"
      severity="info"
      data-cy="infoSnackbar"
      onClose={() => closeSnackbar(id)}
      ref={ref}
      {...rest}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      <Typography variant="body2" style={{ color: "inherit" }}>
        {message}
      </Typography>
    </Alert>
  );
});

export const InfoAlert = DefaultAlert;

export const WarningAlert = React.forwardRef((props: AlertProps, ref: Ref<HTMLDivElement>) => (
  <DefaultAlert ref={ref} severity="warning" {...props} />
));
export const ErrorAlert = React.forwardRef((props: AlertProps, ref: Ref<HTMLDivElement>) => (
  <DefaultAlert ref={ref} severity="error" {...props} />
));
export const SuccessAlert = React.forwardRef((props: AlertProps, ref: Ref<HTMLDivElement>) => (
  <DefaultAlert ref={ref} severity="success" {...props} />
));
