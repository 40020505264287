/** @format */

/* eslint-disable */
import React from "react";
import Theme from "Theme";
import { isEmpty } from "lodash";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TabPanel from "./TabPanel";
import VoucedVerifiedList from "./VoucedVerifiedList";
import Loader from "../../components/Loader";
import { useFetch } from "../../util/useFetch";
import { API_ENDPOINTS, GET_OPTIONS } from "../../util/api_helper";
import { a11yProps, tabs } from "./utils";
import "./style.css";
import { makeTabStyles } from "./styles";

function SimpleDialog(props) {
  const { isLoading } = useFetch(`/${API_ENDPOINTS.vouch_verified_get}`, GET_OPTIONS);
  const { classes } = makeTabStyles();
  const data = {}; // mockData.data;
  // const data = response?.data;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      data-testid="dialog"
    >
      <DialogTitle id="simple-dialog-title">Identity Status</DialogTitle>
      <div
        style={{
          maxWidth: "100%",
          minWidth: "360px",
          margin: "5px auto",
          height: "100vh",
          borderRadius: "5px",
          backgroundColor: Theme.palette.common.white,
        }}
      >
        {!isLoading ? (
          !isEmpty(data) ? (
            <div className={classes.root}>
              <div style={{ display: "flex" }}>
                <div style={{ width: 300, height: "auto", marginRight: "10px" }}>
                  <img style={{ width: "100%" }} alt="" />
                </div>
                <div style={{ width: 450, height: "auto" }}>
                  <img style={{ width: "100%" }} alt="" />
                </div>
              </div>
              <div position="static" className={classes.tabRoot}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {tabs.map(({ label, key }, index) => (
                    <Tab label={label} {...a11yProps(index)} />
                  ))}
                </Tabs>
              </div>
              {tabs.map((t, i) =>
                i === 0 ? (
                  <TabPanel value={value} index={i}>
                    <VoucedVerifiedList data={data} />
                  </TabPanel>
                ) : (
                  <TabPanel value={value} index={i}>
                    Coming Soon...
                  </TabPanel>
                ),
              )}
            </div>
          ) : (
            <Box
              style={{
                height: "70vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5">No Data Available</Typography>
            </Box>
          )
        ) : (
          <Box
            style={{
              height: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </Box>
        )}
      </div>
    </Dialog>
  );
}

export default function VouchVerifiedDialog() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        ID Verification
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
