import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

const DeviceSelectionLoader = () => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    direction="column"
    style={{ height: "100%" }}
    data-testid="DeviceSelectionLoader"
  >
    <div>
      <CircularProgress variant="indeterminate" />
    </div>
    <div>
      <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "16px" }}>
        Joining Meeting
      </Typography>
    </div>
  </Grid>
);

export default DeviceSelectionLoader;
