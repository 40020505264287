import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import useTranslation from "hooks/useTranslation";
import useStyles from "../styles/forms.styles";

interface FaqProps {
  copy: FaqSection[];
  linkedTo?: string;
}

interface FaqSection {
  heading: string;
  sectionId: string;
  content: FaqQuestion[];
}

interface FaqQuestion {
  summary: string;
  details: string;
  links: FaqLink[];
  questionId: string;
}

interface FaqLink {
  href: string;
  text: string;
}

// Parent can handle search state and pass filtered `copy` to <DotFaq>
const DotFaq = ({ copy, linkedTo = "" }: FaqProps) => {
  const [expanded, setExpanded] = useState(new Set<string>([linkedTo]));
  const toggle = (id: string) => () => {
    setExpanded((prev) => {
      // thanks to the unmitigated genius of the W3C and so forth, Set.add() returns the new Set... while Set.delete() returns a boolean
      if (prev.has(id)) {
        prev.delete(id);
        // and we need to create a new Set() so that React's shallow equality check for updates will succeed. i love javascript.
        return new Set(prev);
      }
      return new Set(prev.add(id));
    });
  };
  const { classes } = useStyles({});

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      data-testid="dot-faq"
    >
      {copy.map(({ content, heading, sectionId }) => (
        <Grid
          item
          className={classes.faqSection}
          id={sectionId}
          data-testid="dot-faq-section"
          key={sectionId}
        >
          <Typography variant="h4" component="h2">
            {heading}
          </Typography>
          {content.map(({ summary, details, links, questionId: id }) => (
            <Accordion id={id} key={id} expanded={expanded.has(id)} onChange={toggle(id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content`}
                id={`${id}-header`}
              >
                <Typography variant="h6" component="h3" style={{ fontWeight: "normal" }}>
                  {summary}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faqAnswer}>
                <Typography variant="body1">{details}</Typography>
                {!!links.length && (
                  <div>
                    <List>
                      {links.map(({ href, text }) => (
                        <ListItem component={Typography} variant="body1" key={href}>
                          <a rel="noopener noreferrer" target="_blank" href={href}>
                            {text} <LaunchIcon fontSize="inherit" />
                          </a>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

const Page = () => {
  const { classes } = useStyles({});
  const {
    web: {
      dotForm: { faq },
    },
  } = useTranslation();
  const { hash } = useLocation();

  return (
    <Grid container justifyContent="center" className={classes.faqPage}>
      <Grid item xs={11} md={7}>
        <Typography variant="h4" component="h1" className={classes.faqTitle}>
          {faq.title}
        </Typography>
      </Grid>
      <Grid item xs={11} md={7}>
        <DotFaq copy={faq.copy} linkedTo={hash.replace(/^#/, "")} />
      </Grid>
    </Grid>
  );
};

export default Page;
