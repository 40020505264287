import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  sectionWrapper: {
    maxWidth: "1200px",
  },
  chipsRoot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  practiceLocation_cardRoot: {
    minWidth: 275,
    minHeight: 100,
    backgroundColor: "#f3f3f3",
    padding: "16px",
    marginBottom: "16px",
    "& > div": {
      padding: "2px",
    },
    "& > div:last-child": {
      paddingBottom: "2px",
    },
  },
  insurancesList_wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    width: "100%",
    height: "350px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#f1f1f1",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#888",
    },
  },
  autocompletePaper: {
    textAlign: "left",
  },

  practiceLocation_cardTitle: {
    textDecoration: "underline",
    cursor: "pointer",

    "&:hover": {
      color: theme.palette.primary.main,
    },
  },

  // General Info and Location section
  generalInfo_rightColumn: {
    borderLeft: "1px solid #ccc",
    paddingLeft: "32px !important",
  },
}));
