import React from "react";
import { useNavigate } from "react-router-dom";
import { camelCase, upperFirst } from "lodash";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { waitingUsersTableStyles } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/styles";
import {
  CONNECT_VIRTUAL_VISIT,
  SELECT_CURRENT_PROFILE,
} from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { getQueueValues } from "util/constants";
import { useApp } from "util/AppContext";
import { useProgramTypeByPathname } from "util/programsHelpers";

type QueueViewProps = {
  queueView: string;
  setQueueView: (queueView: string) => void;
};

const QueueView = ({ queueView, setQueueView }: QueueViewProps) => {
  const { classes } = waitingUsersTableStyles();

  const navigate = useNavigate();
  const app = useApp();

  const params = new URLSearchParams(window.location.search);
  const { isConsultation } = useProgramTypeByPathname();
  const queueValues = getQueueValues(isConsultation);

  const handleQueueViewChange = (event) => {
    app.dispatch({ type: SELECT_CURRENT_PROFILE, payload: {} });

    setQueueView(event.target.value);
    params.delete("vsId");
    app.dispatch({
      type: CONNECT_VIRTUAL_VISIT,
      payload: null,
    });
    params.set("queueView", event.target.value);
    navigate({ search: params.toString() });
  };

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel id="demo-simple-select-label" className={classes.label}>
        Queue View
      </InputLabel>
      <Select
        variant="standard"
        className={(classes.select, "select-form-filter")}
        id="demo-simple-select"
        data-cy="queueView"
        value={queueView}
        onChange={(e) => handleQueueViewChange(e)}
      >
        {queueValues.map(({ value, label }) => (
          <MenuItem key={value} value={value} data-cy={upperFirst(camelCase(value))}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default QueueView;
