import { API_ENDPOINTS, axiosClient } from "util/api_helper";

export const useSaveCampaignTags = (submissionId: number) => {
  const saveCampaignTags = (data) => {
    const resp = axiosClient.put(`${API_ENDPOINTS.campaignTags}/${submissionId}`, {
      ...data,
    });
    return resp;
  };

  return {
    saveCampaignTags,
  };
};
