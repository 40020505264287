import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useApp } from "../../../util/AppContext";
import { axiosClient } from "../../../util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { useStyles } from "../../../styles/verificationViews/verificationViews.styles";

export const SmsPhoneVerification = () => {
  const { classes } = useStyles();

  const [lastFour, setLastFour] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const app = useApp();
  const { consentId } = useParams();
  const params = new URLSearchParams(location.search);
  const hash = params.get("s");
  const isMobile = useIsMobile();

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    const url = `/api/dfd/consent/${consentId}/validate-access`;
    const body = {
      secretHash: hash,
      lastFour,
    };
    try {
      await axiosClient.post(url, body);
      navigate(`/confirm-patient/consent?s=${hash}&lastFour=${lastFour}&consentId=${consentId}`);
    } catch (error) {
      console.log(error);
      app.addError("Oops, your last 4 digits of your phone are not correct, please verify them");
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      wrap="nowrap"
      className={classes.root}
    >
      <Paper elevation={!isMobile ? 24 : 0} className={classes.paper}>
        <Typography
          variant="h2"
          className={classes.title}
          data-testid="sms-phone-verification-title"
        >
          Phone Number Verification
        </Typography>
        <Typography variant="h3" className={classes.subtitle}>
          Please enter the last four digits of your cell phone number
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleLoginSubmit}>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            className={classes.dobContainer}
          >
            <div data-testid="sms-phone-verification-input-container">
              <ReactCodeInput
                fields={4}
                className={classes.codeInput}
                autoFocus
                fieldHeight={!isMobile ? 70 : 60}
                fieldWidth={!isMobile ? 70 : 50}
                values={lastFour.split("") || ""}
                onChange={setLastFour}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={!lastFour || lastFour.length < 4}
              data-testid="sms-phone-verification-verify"
              data-cy="sms-phone-verification-verify"
            >
              Verify
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default SmsPhoneVerification;
