import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { makeStyles } from "tss-react/mui";
import "../App.css";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.common.black,
  },
  media: {
    height: 140,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  backgroundSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  formLabel: {
    color: theme.palette.common.black,
  },
}));

function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

export default function VaccineQuestionsView() {
  const { classes } = useStyles();
  const [canProceed, setCanProceed] = useState(false);
  const [sick, setSick] = useState(null);
  const [allergic, setAllergic] = useState(null);
  const [anothervaccine, setAnothervaccine] = useState(null);
  const [hadcovid, setHadCovid] = useState(null);
  const [pregnant, setPregnant] = useState(null);

  const navigate = useNavigate();

  const forceUpdate = useForceUpdate();

  const evalForm = () => {
    if (
      sick === "no" &&
      allergic === "no" &&
      anothervaccine === "no" &&
      hadcovid === "no" &&
      pregnant === "no"
    ) {
      setCanProceed(true); // TODO not causing update initially
    } else {
      setCanProceed(false);
    }
    setTimeout(() => {
      forceUpdate();
    }, 500);
    console.log(`canProceed ${canProceed}`);
  };

  const handleProceedSubmit = (event) => {
    event.preventDefault();
    navigate("/standbybooking", { relative: true });
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    switch (fieldName) {
      case "sick":
        setSick(fieldValue);
        break;
      case "allergic":
        setAllergic(fieldValue);
        break;
      case "anothervaccine":
        setAnothervaccine(fieldValue);
        break;
      case "hadcovid":
        setHadCovid(fieldValue);
        break;
      case "pregnant":
        setPregnant(fieldValue);
        break;
      default:
        break;
    }

    evalForm();
  };

  return (
    <div className={classes.root}>
      <h1 className={classes.header}>Atlantic Health Notification System</h1>
      <h1 className={classes.header}>Standby Appointment Booking</h1>
      <br />
      <Grid
        container
        spacing={8}
        className={classes.root}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            Congratulations on being selected to recieve a COVID-19 vaccine from the standby list.
            Its an important community service that we vaccinate as many people as fast as possible
            to achieve herd immunity throughout the state of New Jersey.
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <FormControl variant="standard" component="fieldset">
            <Grid
              container
              spacing={8}
              className={classes.root}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.formLabel}>
                  Are you feeling sick today?
                </FormLabel>
                <RadioGroup aria-label="sick" name="sick" value={sick} onChange={handleChange}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.formLabel}>
                  Have you ever had a severe allergic reaction (e.g., anaphylaxis) to something?
                </FormLabel>
                <RadioGroup
                  aria-label="allergic"
                  name="allergic"
                  value={allergic}
                  onChange={handleChange}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.formLabel}>
                  Have you received another vaccine in the last 14 days?
                </FormLabel>
                <RadioGroup
                  aria-label="anothervaccine"
                  name="anothervaccine"
                  value={anothervaccine}
                  onChange={handleChange}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.formLabel}>
                  Have you had a positive test for COVID-19 or has a doctor ever told you that you
                  had COVID-19?
                </FormLabel>
                <RadioGroup
                  aria-label="hadcovid"
                  name="hadcovid"
                  value={hadcovid}
                  onChange={handleChange}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.formLabel}>
                  Are you pregnant or breastfeeding?
                </FormLabel>
                <RadioGroup
                  aria-label="pregnant"
                  name="pregnant"
                  value={pregnant}
                  onChange={handleChange}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {canProceed || (
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={handleProceedSubmit}
              className={classes.backgroundSecondary}
            >
              Proceed to booking
            </Button>
          )}
        </Grid>
      </Grid>

      <br />
      <br />
      <span style={{ display: "none" }}>{canProceed}</span>
    </div>
  );
}
