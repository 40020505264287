import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import useChatContext from "components/Video/hooks/useChatContext/useChatContext";
import useVideoContext from "components/Video/hooks/useVideoContext/useVideoContext";
import {
  CLEAR_VIRTUAL_VISIT,
  CONNECT_VIRTUAL_VISIT,
  TOGGLE_QUEUE_TAB,
} from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { WaitingRoomTab } from "util/constants";
import { useQuery } from "hooks/useQuery";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";

const UseDisconnectActiveSession = () => {
  const navigate = useNavigate();
  const app = useApp();
  const { room } = useVideoContext();
  const { chatClient } = useChatContext();
  const query = useQuery();
  const queryClient = useQueryClient();
  const { programId } = usePrograms();

  const disconnectActiveSession = () => {
    queryClient.invalidateQueries("fetch-awaiting-vs-visit", programId);
    room?.disconnect();
    app.dispatch({
      type: CLEAR_VIRTUAL_VISIT,
    });
    app.dispatch({
      type: CONNECT_VIRTUAL_VISIT,
      payload: null,
    });
    app.dispatch({
      type: TOGGLE_QUEUE_TAB,
      payload: WaitingRoomTab.QUEUE,
    });
    chatClient?.shutdown();
    query.delete("vsId");
    navigate({ search: query.toString() });
  };

  return disconnectActiveSession;
};

export default UseDisconnectActiveSession;
