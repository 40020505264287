const CONSTANTS = {
  mobileBaseUrl: "/mobile?l=",

  // HOME
  HOME: "home",
  CARE: "care",
  LOCATIONS: "locations",
  MORE: "more",
  BILLING: "billing",

  // APPOINTMENTS
  APPOINTMENTS: "appointments",

  // LOCATIONS
  FINDHOSPITAL: "findhospital",
  HOSPITAL: "hospital",
  FINDURGENTCARE: "findurgentcare",
  URGENTCARE: "urgentcare",
  PATIENTVISITOR: "patientvisitor",
  PARKING: "parking",

  ABOUT: "about",
  MAP: "map",
};

export const WEB_REDIRECTION_URL = "https://myatlantic-dev.atlantichealth.org/";

export const FIND_A_DOCTOR_URL =
  "https://findadoctor.atlantichealth.org/search?sort=networks%2Crelevance&location=40.7222321%2C-74.5687451&display_location=Bernardsville%2C%20NJ%2007924%2C%20USA&distance=25";

export const LOGIN_URL = "/login";
export const LOGIN_R_VIDEO_URL = "/login?r=video";

export const MY_CHART_VIRTUAL_URGENT_CARE =
  "https://mychart.atlantichealth.org/MyChart/scheduling/anonymoustelehealth";

export const URGENT_CARE_HEADER_TITTLE = "Urgent Care";
export const HOSPITAL_HEADER_TITTLE = "Hospital";

export default CONSTANTS;
