import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, Grid } from "@mui/material";
import { RNWebView } from "util/RNWebView";
import useStyles from "../NewAppointment.styles";

const FILTER = {
  gender: "gender",
  online: "online",
  city: "city",
  insurance: "insurance",
};

const FilterOptions = ({ gender, location, isOnlineBooking, insurance, setFilterData }) => {
  const { classes, cx } = useStyles();

  const cancelFilter = (filter) => {
    if (filter === FILTER.gender) {
      setFilterData((state) => ({ ...state, gender: "" }));
    }
    if (filter === FILTER.online) {
      setFilterData((state) => ({ ...state, isOnlineAppointment: "" }));
    }
    if (filter === FILTER.city) {
      setFilterData((state) => ({
        ...state,
        city: "",
        zip: "",
        state: "",
        location: "",
      }));
    }
    if (filter.insurance === FILTER.insurance) {
      const CanceledInsurance = insurance.filter((it) => it !== filter.cancel);
      setFilterData((state) => ({ ...state, insurance: CanceledInsurance }));
    }
  };

  return (
    <Grid container justifyContent="flex-start">
      {!!gender && (
        <Button
          className={cx(classes.filterItem, {
            [classes.filterItemRN]: RNWebView,
          })}
          onClick={() => cancelFilter(FILTER.gender)}
        >
          {gender} <ClearIcon />
        </Button>
      )}
      {!!location && (
        <Button
          className={cx(classes.filterItem, {
            [classes.filterItemRN]: RNWebView,
          })}
          onClick={() => cancelFilter(FILTER.city)}
        >
          {location} <ClearIcon />
        </Button>
      )}
      {isOnlineBooking && (
        <Button
          className={cx(classes.filterItem, {
            [classes.filterItemRN]: RNWebView,
          })}
          onClick={() => cancelFilter(FILTER.online)}
        >
          Online booking
          <ClearIcon />
        </Button>
      )}
      {insurance.length > 0 &&
        insurance.map((item) => (
          <Button
            className={cx(classes.filterItem, {
              [classes.filterItemRN]: RNWebView,
            })}
            onClick={() => cancelFilter({ insurance: FILTER.insurance, cancel: item })}
            key={item}
          >
            {item}
            <ClearIcon />
          </Button>
        ))}
    </Grid>
  );
};

export default FilterOptions;
