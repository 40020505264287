export enum Steps {
  visitReason,
  insuranceDetails,
  Consent,
  StartTestStep,
  AudioPermissionStep,
  VideoPermissionStep,
  deviceSelectionStep,
}

export enum QuerySteps {
  VISIT_REASON = "visit_reason",
  INSURANCE_DETAILS = "insurance_details",
  CONSENT = "consent",
  START_TEST = "start_test",
  AUDIO_PERMISSION = "audio_permission",
  VIDEO_PERMISSION = "video_permission",
  PREVIEW = "preview",
  IN_SESSION = "in-session",
}

export const StepsMapping = {
  [QuerySteps.VISIT_REASON]: Steps.visitReason,
  [QuerySteps.INSURANCE_DETAILS]: Steps.insuranceDetails,
  [QuerySteps.CONSENT]: Steps.Consent,
  [QuerySteps.START_TEST]: Steps.StartTestStep,
  [QuerySteps.AUDIO_PERMISSION]: Steps.AudioPermissionStep,
  [QuerySteps.VIDEO_PERMISSION]: Steps.VideoPermissionStep,
  [QuerySteps.PREVIEW]: Steps.deviceSelectionStep,
  [QuerySteps.IN_SESSION]: Steps.deviceSelectionStep,
};
