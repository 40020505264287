/* eslint-disable */
import React from "react";
import DateFnsAdapter from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "tss-react/mui";
import { useApp } from "../util/AppContext";
import { useAuth } from "../util/Security";
import { axiosClient } from "../util/api_helper";
import { getEmptyFields, normalizePhoneNumber, validateEmail } from "../util/util_functions";

const useStyles = makeStyles()((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 200,
    width: 520,
  },
  textFieldWrapper: {
    marginTop: 10,
  },
  contentText: {
    fontSize: 16,
  },
}));

export default function HelpModal({ isOpen, handleClose }) {
  const { classes } = useStyles();
  const { user = {} } = useAuth();
  const [firstName, setFirstName] = React.useState(user?.firstName || "");
  const [lastName, setLastName] = React.useState(user?.lastName || "");
  const [dob, setDob] = React.useState(user?.dob ? new Date(user?.dob) : null);
  const [phone, setPhone] = React.useState(user?.cellPhone || "");
  const [email, setEmail] = React.useState(user?.email || "");
  const [comments, setComments] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    dob: "",
    email: "",
    comments: "",
  });
  const [isDateError, setIsDateError] = React.useState(false);
  const app = useApp();
  const dateFns = new DateFnsAdapter();

  React.useEffect(() => {
    if (isOpen && user) {
      setFirstName(user?.firstName || "");
      setLastName(user?.lastName || "");
      setDob(user?.dob ? new Date(user?.dob) : null);
      setEmail(user?.email || "");
    }
    return () => {
      setFirstName("");
      setLastName("");
      setDob(null);
      setPhone("");
      setEmail("");
      setComments("");
    };
  }, [isOpen, user]);

  const handleFirstNameChange = (e) => {
    setErrors({
      ...errors,
      firstName: "",
    });
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setErrors({
      ...errors,
      lastName: "",
    });
    setLastName(e.target.value);
  };

  const handleDobChange = (date) => {
    setErrors({
      ...errors,
      dob: "",
    });
    setDob(date);
  };

  const handlePhoneChange = (e) => {
    setErrors({
      ...errors,
      phone: "",
    });
    setPhone(normalizePhoneNumber(e.target.value));
  };

  const handleEmailChange = (e) => {
    setErrors({
      ...errors,
      email: "",
    });
    setEmail(e.target.value);
  };

  const handleCommentsChange = (e) => {
    setErrors({
      ...errors,
      comments: "",
    });
    setComments(e.target.value);
  };

  const handleModalClose = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setComments("");
    setPhone("");

    handleClose();
  };

  const handleSubmitClick = async () => {
    const emptyFields = getEmptyFields({
      firstName,
      lastName,
      dob,
      email,
      phone,
      comments,
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors(emptyFields);

      if (!!phone && phone.length < 12) {
        setErrors({
          ...errors,
          phone: "Enter a valid phone number",
        });
      }

      if (!!email && !validateEmail(email)) {
        setErrors({
          ...errors,
          email: "Enter a valid email",
        });
      }
      return;
    }

    if (isDateError) {
      return;
    }

    // It appears that this conditional is already covered by the conditional on line 152.
    if (phone.length < 12 || !validateEmail(email)) {
      if (phone.length < 12) {
        setErrors({
          ...errors,
          phone: "Enter a valid phone number",
        });
      }

      if (!validateEmail(email)) {
        setErrors({
          ...errors,
          email: "Enter a valid email",
        });
      }
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await axiosClient.post("api/send-support-email", {
        firstname: firstName,
        lastname: lastName,
        dob: dateFns.format(new Date(dob), "MM/dd/yyyy"),
        comments,
        phone_number: phone,
        email,
      });
      if (data.error) {
        app.addError("Problem sending email.");
        return;
      }
      handleModalClose();
    } catch (error) {
      app.addError("Problem sending email.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleModalClose} aria-labelledby="help-dialog-title">
      <DialogTitle id="help-dialog-title">Help?</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          If you need technical assistance with your account, complete the below form or call{" "}
          <a href="tel:+1-800-250-9953">1-800-250-9953</a>
        </DialogContentText>

        <div className={classes.textFieldWrapper}>
          <TextField
            error={!!errors.firstName}
            helperText={errors.firstName}
            value={firstName}
            onChange={handleFirstNameChange}
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            variant="outlined"
            required
            fullWidth
          />
        </div>

        <div className={classes.textFieldWrapper}>
          <TextField
            error={!!errors.lastName}
            helperText={errors.lastName}
            value={lastName}
            onChange={handleLastNameChange}
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            variant="outlined"
            required
            fullWidth
          />
        </div>

        <div className={classes.textFieldWrapper}>
          <DatePicker
            slotProps={{
              textField: {
                fullWidth: true,
                helperText: errors?.dob ?? "MM/DD/YYYY",
                inputVariant: "inline",
                id: "help-modal-dob",
                error: !!errors?.dob,
              },
              openPickerButton: {
                "aria-label": "change date",
              },
            }}
            required
            format="MM/dd/yyyy"
            margin="normal"
            label="Date of Birth"
            value={dob}
            onChange={handleDobChange}
            maxDate={new Date()}
            onError={(error) => {
              if (error && !errors.dob) {
                setIsDateError(true);
                setErrors({
                  ...errors,
                  dob: error,
                });
              }

              if (!error) {
                setIsDateError(false);
              }
            }}
          />
        </div>

        <div className={classes.textFieldWrapper}>
          <TextField
            error={!!errors.phone}
            helperText={errors.phone}
            value={phone}
            onChange={handlePhoneChange}
            margin="dense"
            id="phone"
            label="Phone"
            type="text"
            variant="outlined"
            required
            fullWidth
          />
        </div>

        <div className={classes.textFieldWrapper}>
          <TextField
            error={!!errors.email}
            helperText={errors.email}
            value={email}
            onChange={handleEmailChange}
            margin="dense"
            id="email"
            label="Email"
            type="email"
            variant="outlined"
            required
            fullWidth
          />
        </div>

        <div className={classes.textFieldWrapper}>
          <TextField
            error={!!errors.comments}
            helperText={errors.comments}
            value={comments}
            onChange={handleCommentsChange}
            margin="dense"
            id="comments"
            label="Comments"
            multiline
            minRows={3}
            maxRows={4}
            variant="outlined"
            required
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleModalClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmitClick} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
