/** @format */
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: (props) => props.width,
  },
}));
const AddPatient = ({
  title,
  showIcon,
  showForm = () => {},
  width = "fit-content",
  variant = "contained",
}) => {
  const { classes } = useStyles({ width });
  return (
    <Button
      variant={variant}
      color="primary"
      className={classes.button}
      startIcon={showIcon && <AddIcon />}
      size="small"
      onClick={() => showForm()}
      data-cy="confirmButton"
    >
      {title}
    </Button>
  );
};

export default AddPatient;
