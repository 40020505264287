import React, { useEffect, useRef, useState } from "react";
import {
  Create,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  List,
  Save,
  ViewCarousel,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import EditWarningDialog from "../staff/EditWarningModal";
import { DOT_PATIENT_STEPS, QUESTIONS_DATA, QuestionsData, StepsProps } from "../types/constants";
import { useGetMedicalExaminers } from "../hooks/useGetMedicalExaminers";
import useTranslation from "hooks/useTranslation";
import { dateFmt } from "../utils";
import { useApp } from "util/AppContext";
import { useIsMobile } from "util/deviceUtils";
import useStyles from "../styles/forms.styles";

const LAST_GROUP = 9;
const MAX_GROUP = 10;

export const GetStepContent = ({
  stepQuestions,
  data,
  handleQuestionChange,
  classes,
  shouldDisableForm = false,
  isStaff = false,
}) => {
  return (
    <>
      {stepQuestions.map(
        (
          {
            question,
            allowRadioButtons,
            allowNotSureOption,
            showTextField,
            id,
            additionalInformationId,
            isCommentRequired = false,
            placeholder = "Please comment further",
          },
          index,
        ) => (
          <Grid
            key={id}
            className={classes.questions_wrapper}
            style={{ background: index % 2 === 0 ? "#ECECEC" : "#ffffff" }}
            data-testid="get-step-content-question"
          >
            <FormControl variant="standard" fullWidth>
              <Typography align="left" className={classes.timeWindowTitle}>
                {question}
              </Typography>
              {allowRadioButtons && (
                <RadioGroup
                  aria-label={question}
                  name={question}
                  data-cy={id}
                  value={data?.[id] ? data[id].answer : ""}
                  onChange={(ev) => handleQuestionChange(ev, id, "answer", additionalInformationId)}
                  row
                >
                  <FormControlLabel
                    value="YES"
                    control={<Radio />}
                    label="Yes"
                    disabled={shouldDisableForm}
                  />
                  <FormControlLabel
                    value="NO"
                    control={<Radio />}
                    label="No"
                    disabled={shouldDisableForm}
                  />
                  {allowNotSureOption && (
                    <FormControlLabel
                      value="NOT_SURE"
                      control={<Radio />}
                      label="Not Sure"
                      disabled={shouldDisableForm}
                    />
                  )}
                </RadioGroup>
              )}
              <Grid item xs={12}>
                {data?.[id]?.answer === "YES" && (
                  <TextField
                    error={false}
                    value={data?.[id] ? data[id].text : ""}
                    onChange={(ev) => handleQuestionChange(ev, id, "text", additionalInformationId)}
                    margin="dense"
                    id={`${id}_comments`}
                    name={`${id}_comments`}
                    label={placeholder}
                    multiline
                    minRows={2}
                    maxRows={2}
                    variant="outlined"
                    required={isCommentRequired}
                    fullWidth
                    disabled={shouldDisableForm}
                    InputLabelProps={{ shrink: true }}
                    data-testid={`${id}_comments`}
                  />
                )}
                {showTextField && (
                  <TextField
                    error={false}
                    value={data?.[id] ? data[id].text : ""}
                    onChange={(ev) => handleQuestionChange(ev, id, "text", additionalInformationId)}
                    margin="dense"
                    id={`${id}_comments`}
                    name={`${id}_comments`}
                    multiline
                    minRows={8}
                    maxRows={8}
                    variant="outlined"
                    className={classes.textArea}
                    fullWidth
                    disabled={shouldDisableForm}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Grid>
              {isStaff && data?.[id]?.medicalExaminer && (
                <Typography
                  align="left"
                  className={classes.updatedBy}
                  data-testid="medical-examiner-legend"
                >
                  {`Updated By ${data?.[id]?.medicalExaminer?.medExaminerName} on ${dateFmt.format(
                    new Date(data?.[id]?.medicalExaminer?.medExmUpdateDttm),
                  )}`}
                </Typography>
              )}
            </FormControl>
          </Grid>
        ),
      )}
    </>
  );
};

interface HealthHistoryStepProps extends StepsProps {
  shouldShowDisplayOptions?: boolean;
}

const HealthHistoryStep: React.FC<HealthHistoryStepProps> = ({
  handleChange,
  formData,
  isFullPage = false,
  shouldShowDisplayOptions = false,
  showTitle = false,
  isFormDisabled = false,
  handleStepChange,
  isStaff = false,
  formRef,
  isLoading,
}) => {
  const { classes } = useStyles({
    alignLeft: shouldShowDisplayOptions,
  });
  const isMobile = useIsMobile();
  const title = DOT_PATIENT_STEPS[3];
  const [activeStep, setActiveStep] = useState<number>(0);
  useEffect(() => {
    if (isStaff) {
      return;
    }
    const found = document.querySelector("#dot-form-health-history input");
    (found as HTMLInputElement)?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);
  const [shouldCenterQuestionsContent, setShouldCenterQuestionsContent] = useState<boolean>(false);
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(isFormDisabled);
  const [isSaveMode, setIsSaveMode] = useState<boolean>(false);
  const [isCarouselView, setIsCarouselView] = useState<boolean>(true);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState<boolean>(false);
  const { addError, enqueueSnackbar } = useApp();

  const containerRef = useRef<HTMLDivElement>(null);

  const { isUserMedicalExaminer } = useGetMedicalExaminers();

  const { healthHistory: currentHealthHistory, submissionStatus } = formData;
  const data = currentHealthHistory?.healthHistory;

  const {
    web: {
      common: { next, previous, progressSaved },
      dotForm: {
        patient: { healthHistory },
        staff: {
          common: { list, slider, saveForm, edit, warningModalTitle, unauthorizedWarning },
        },
      },
    },
  } = useTranslation();

  const groupedQuestions = QUESTIONS_DATA.reduce((acc, question) => {
    const group = question.questionGroup;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(question);
    return acc;
  }, {});

  const steps = Object.keys(groupedQuestions);
  const stepQuestions = isCarouselView ? groupedQuestions[steps[activeStep]] : QUESTIONS_DATA;

  useEffect(() => {
    const setContinueButtonAsDisabled = () => {
      let handleStepPerformed = false;
      if (Object.keys(data || {}).length > 0) {
        const questionIndex = Object.keys(data).length > 0 ? Object.keys(data).length - 1 : 0;
        const lastQuestion = QUESTIONS_DATA[questionIndex];
        if (lastQuestion && Object.keys(data).length >= 2) {
          if (
            (lastQuestion.questionGroup === LAST_GROUP ||
              lastQuestion.questionGroup === MAX_GROUP) &&
            handleStepChange
          ) {
            handleStepChange({ shouldDisableContinueButton: false });
            handleStepPerformed = true;
          }
        }
      }

      const shouldDisableContinueButton = activeStep < steps.length - 1;
      if (handleStepChange && !handleStepPerformed) {
        handleStepChange({ shouldDisableContinueButton });
      }
    };
    if (!isStaff) {
      setContinueButtonAsDisabled();
    } else if (handleStepChange) {
      handleStepChange({ shouldDisableContinueButton: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStaff, activeStep, data]);

  const scrollToTop = () => {
    containerRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleNext = async () => {
    if (formRef?.current?.checkValidity()) {
      if (activeStep < steps.length - 1) {
        setShouldCenterQuestionsContent(
          groupedQuestions[steps[activeStep + 1]].some((t: QuestionsData) => t.centerContent),
        );

        if (handleStepChange) {
          const shouldDisableContinueButton = activeStep < steps.length - 1;
          const success = await handleStepChange({
            shouldDisableContinueButton,
            saveProgress: true,
            shouldMoveToTheNextStep: !shouldDisableContinueButton,
          });

          // @ts-ignore-next-line
          if (success) {
            setActiveStep(activeStep + 1);
          }
        }
      }
    } else {
      formRef?.current?.reportValidity();
      addError("All fields should be filled out before moving to the next step.");
    }
    scrollToTop();
    if (handleStepChange) {
      handleStepChange({ shouldReloadFormData: isSaveMode });
    }
  };

  const nextDisabled = (() => {
    if (stepQuestions.some((q) => !data?.[q.id] || !data[q.id]?.answer)) {
      return true; // some question in this step is unanswered
    }
    if (activeStep >= steps.length - 1) {
      return true; // this is the last step
    }
    return false; // user can go forward
  })();

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      setShouldCenterQuestionsContent(
        groupedQuestions[steps[activeStep - 1]].some((t: QuestionsData) => t.centerContent),
      );

      if (handleStepChange) {
        handleStepChange({ shouldDisableContinueButton: true, shouldReloadFormData: isSaveMode });
      }
    }

    scrollToTop();
  };

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionId: string,
    responseType: string,
    additionalInformationId: number,
  ) => {
    const { value } = event.target;

    handleChange(`healthHistory.healthHistory.${questionId}.${responseType}`, value);

    if (additionalInformationId) {
      handleChange(
        `healthHistory.healthHistory.${questionId}.additionalInformationId`,
        additionalInformationId,
      );
    }
  };

  const showStepTitle = () => {
    if (showTitle || isMobile) {
      return (
        <Typography align="center" className={classes.formTitle}>
          {title}
        </Typography>
      );
    }
  };

  const actionClickHandler = async (isSaveMode: boolean) => {
    if (!isSaveMode) {
      setShouldDisableForm(false);
      setIsSaveMode(true);
      setIsOpenWarningModal(false);
    } else {
      setShouldDisableForm(true);
      setIsSaveMode(false);
      if (handleStepChange) {
        const success = await handleStepChange({ saveProgress: true, shouldReloadFormData: true });
        // @ts-ignore-next-line
        if (success) {
          enqueueSnackbar(progressSaved, { variant: "success" });
        }
      }
    }
  };

  const handleShowWarningModal = () => {
    if (submissionStatus === "COMPLETED") {
      return setIsOpenWarningModal(true);
    }
    actionClickHandler(isSaveMode);
  };

  const stepContentClass = () => {
    const notCentered =
      isFullPage && !isCarouselView
        ? classes.alignedLeftQuestionsWithFullPage
        : classes.alignedSlideLeftQuestionsWithFullPage;
    return shouldCenterQuestionsContent ? classes.alignedCenterQuestions : notCentered;
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        data-testid="healthHistory"
        id="dot-form-health-history"
        className={
          isFullPage ? classes.healthHistory_fullPage_wrapper : classes.healthHistory_wrapper
        }
        ref={containerRef}
      >
        <Grid item container xs={12} md={12} style={{ paddingBottom: isFullPage ? "20px" : "0px" }}>
          <Grid item xs={12} md={6}>
            {showStepTitle()}
          </Grid>
          <Grid item xs={6} md={6} className={classes.closeButton}>
            {shouldShowDisplayOptions && (
              <div style={{ display: "flex" }}>
                <div className={classes.inlineActionButtons}>
                  <IconButton
                    aria-label="display options"
                    color={isCarouselView ? "inherit" : "primary"}
                    onClick={() => setIsCarouselView(false)}
                  >
                    <List />
                  </IconButton>
                  <span>{list}</span>
                </div>
                <div className={classes.inlineActionButtons}>
                  <IconButton
                    aria-label="display options"
                    color={isCarouselView ? "primary" : "inherit"}
                    onClick={() => setIsCarouselView(true)}
                  >
                    <ViewCarousel />
                  </IconButton>
                  <span>{slider}</span>
                </div>
                {(shouldDisableForm || isSaveMode) && (
                  <div className={classes.inlineActionButtons}>
                    <IconButton
                      aria-label="disable form"
                      onClick={() => {
                        if (!isUserMedicalExaminer) {
                          addError(unauthorizedWarning);
                          return;
                        }
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        isSaveMode ? actionClickHandler(isSaveMode) : handleShowWarningModal();
                      }}
                    >
                      {isSaveMode ? <Save /> : <Create />}
                    </IconButton>
                    <span>{isSaveMode ? saveForm : edit}</span>
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Grid>
        <FormControl
          variant="outlined"
          fullWidth
          className={stepContentClass()}
          style={{ minHeight: "20em" }}
        >
          <Typography className={classes.centeredTitle}>{healthHistory.title}</Typography>
          {isLoading ? (
            <CircularProgress style={{ margin: "auto" }} />
          ) : (
            GetStepContent({
              stepQuestions,
              data,
              handleQuestionChange,
              classes,
              shouldDisableForm,
              isStaff,
            })
          )}
        </FormControl>

        {isCarouselView && (
          <MobileStepper
            steps={steps.length}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                name="carouselNext"
                disabled={nextDisabled || isLoading}
                onClick={handleNext}
                data-cy="HealthHistory-Carousel-Next-Button"
              >
                {next}
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                onClick={handleBack}
                disabled={activeStep === 0 || isLoading}
                data-cy="HealthHistory-Carousel-Back-Button"
              >
                <KeyboardArrowLeft />
                {previous}
              </Button>
            }
            style={{ width: "100%", background: "#cfcfcf" }}
          />
        )}
      </Grid>
      {isOpenWarningModal && (
        <EditWarningDialog
          bodyText={healthHistory.warningModalBodyText}
          title={warningModalTitle}
          handleClose={() => setIsOpenWarningModal(false)}
          handleSubmit={() => actionClickHandler(isSaveMode)}
        />
      )}
    </>
  );
};

export default HealthHistoryStep;
