import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 34,
    textAlign: "center",
    marginBottom: "48px",
    fontFamily: "Alegreya Sans, sans-serif",
  },
  subtitle: {
    fontSize: "24px",
    lineHeight: "32px",
    textAlign: "center",
    fontFamily: "Alegreya Sans, sans-serif",
  },
  avatarImage: {
    maxWidth: "100%",
    border: "8px solid transparent",
    objectFit: "contain",
    cursor: "pointer",
  },
  avatarName: {
    color: theme.palette.common.black,
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "center",
  },
  grid: {
    marginTop: "24px",
    maxWidth: "100%",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "16px",
    cursor: "pointer",
    transition: "box-shadow 0.3s",
    width: "164px",
    "&:hover": {
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
      transition: "box-shadow 0.3s",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  container: {
    paddingTop: "48px",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
    },
  },
  selectedMember: {
    border: `8px solid ${theme.palette.primary.light}`,
  },
  editIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0px 8px 0px",
  },
  iconContainer: {
    position: "absolute",
    cursor: "pointer",
    top: -7,
    right: -7,
    backgroundColor: theme.palette.common.white,
    width: "28px",
    height: "28px",
    borderRadius: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.54)",
    boxShadow:
      "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      color: theme.palette.common.white,
      transition: "background-color 0.3s, color 0.3s",
    },
  },
  defaultAvatar: {
    width: 53,
    height: 53,
  },
  userAvatar: {
    width: 164,
    height: 188,
  },
  avatarContainer: {
    backgroundColor: "transparent",
    position: "relative",
  },
  defaultAvatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    width: 164,
    height: 188,
    backgroundColor: "#DDDDDD",
  },
}));

export default useStyles;
