import { useState } from "react";
import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";
import { SpecimenLocationInfo } from "views/SpecimenTracking/types";

interface SpecimenLocationsDataResponse {
  data: {
    data?: SpecimenLocationInfo[];
    totalRecords: number;
  };
}
export const useGetSpecimenLocations = () => {
  const [barCode, setBarCode] = useState<string | null>(null);
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const getSpecimenLocationsList = (barCode: string | null = null) => {
    setBarCode(barCode);
  };

  const fetchQuery = (): Promise<SpecimenLocationsDataResponse> =>
    axiosClient.get(API_ENDPOINTS.specimenTrackingLocations, {
      params: {
        barCode,
        query: barCode?.trim(),
      },
    });
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.specimenTrackingLocations, { barCode }],
    queryFn: fetchQuery,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  const specimenLocationList = data?.data.data;

  return {
    specimenLocationList,
    getSpecimenLocationsList,
    refetch,
    isLoading,
    isFetching,
  };
};
