import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import BannerButton from "../../../components/Mobile/BannerButton/BannerButton";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import UrgentCareBanner from "../../../components/Mobile/UrgentCareBanner/UrgentCareBanner";
import CONSTANTS from "../constants";
import useTranslation from "../../../hooks/useTranslation";
import { Location } from "./utils";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

interface LocationListProps {
  variant: "hospital" | "urgentCare";
}

const useStyles = makeStyles()(() => ({
  textContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "30px 1.2em 20px 1.2em",
  },
  textContainerUrgentCareVariant: {
    display: "flex",
    flexDirection: "column",
    margin: "84px 1.2em 20px 1.2em",
  },
  title: {
    fontWeight: 500,
    fontSize: "1.3em",
    textAlign: "left",
    lineHeight: 1,
  },
  subtitle: {
    fontSize: "1em",
    textAlign: "left",
    lineHeight: 1,
  },
  input: {
    borderRadius: "0.25em",
    border: "1px solid #868686",
    padding: "0.5em",
    fontSize: "0.9em",
    color: "#5d5d5d",
    margin: "1em 0 0 0",

    "&:focus": {
      outline: "none",
    },
  },
  buttonContainer: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "0.6em",

    "& > *:last-child": {
      margin: "0 1.2em 1.2em 1.2em",
    },
  },
  circularProgressContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
  errorTextContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    marginTop: "12px",
    marginLeft: "40px",
    marginRight: "40px",
  },
}));

const LocationList = ({ variant }: LocationListProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { mobile } = useTranslation();
  const { SearchHospitals, SearchUrgentCare, ByCityOrZip } = mobile;
  const [searchQuery, setSearchQuery] = useState("");
  const [retrievingLocations, setRetrievingLocations] = useState(false);
  const [locationsError, setLocationsError] = useState(false);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const isHospitalVariant = variant === "hospital";

  const locationsToShow = useMemo(() => {
    return searchQuery.length > 0
      ? locations.filter((location) => {
          const lowSearch = searchQuery.toLowerCase();
          const fullLocation = `${location.title} ${location.address} ${location.cityzipcode}`;
          return fullLocation.toLowerCase().includes(lowSearch);
        })
      : locations;
  }, [searchQuery, locations]);

  const onChangeSearchInput = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const onClickLocation = useCallback(
    (location) => {
      navigate(
        `${CONSTANTS.mobileBaseUrl}${
          isHospitalVariant ? CONSTANTS.HOSPITAL : CONSTANTS.URGENTCARE
        }&isApp=true`,
        {
          state: {
            from: isHospitalVariant ? CONSTANTS.FINDHOSPITAL : CONSTANTS.FINDURGENTCARE,
            location,
          },
        },
      );
    },
    [navigate, isHospitalVariant],
  );

  useEffect(() => {
    const fetchLocations = async () => {
      const url = isHospitalVariant
        ? API_ENDPOINTS.mobile_hospitals
        : API_ENDPOINTS.mobile_urgentcare;
      setRetrievingLocations(true);
      try {
        const response = await axiosClient.get(url);
        if (response.status === 200) {
          setLocations(response.data?.data);
        }
      } catch (e) {
        setLocationsError(true);
      }
      setRetrievingLocations(false);
    };
    fetchLocations();
    // eslint-disable-next-line
  }, []);

  if (retrievingLocations) {
    return (
      <div className={classes.circularProgressContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (locationsError) {
    return (
      <div className={classes.errorTextContainer}>
        <Typography>
          An error occured while fetching the{" "}
          {isHospitalVariant ? "hospitals" : "urgent care locations"}. Please try again later.
        </Typography>
      </div>
    );
  }

  return (
    <PageContainer>
      {!isHospitalVariant && <UrgentCareBanner />}
      <div
        className={
          isHospitalVariant ? classes.textContainer : classes.textContainerUrgentCareVariant
        }
      >
        <Typography className={classes.title}>
          {isHospitalVariant ? SearchHospitals : SearchUrgentCare}
        </Typography>
        <Typography className={classes.subtitle}>{ByCityOrZip}</Typography>
        <input
          className={classes.input}
          type="text"
          onChange={onChangeSearchInput}
          value={searchQuery}
          data-cy="location-list-view-search-input"
        />
      </div>
      <div className={classes.buttonContainer}>
        {locationsToShow.map((location) => {
          const { id, title, img } = location;
          return (
            <BannerButton
              key={id}
              title={title}
              image={img}
              showRightArrow
              border="none"
              customOnClick={() => onClickLocation(location)}
              testId={`${isHospitalVariant ? "hospital-" : "urgent-care-"}${id}`}
            />
          );
        })}
      </div>
    </PageContainer>
  );
};
export default LocationList;
