import { useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

export interface Record {
  id: string;
  name: string;
}

export interface PhysiciansListData {
  data: { records: Record[]; totalRecords: number };
}

export const useGetPhysicians = () => {
  const { addError } = useApp();
  const [specialty, setSpecialty] = useState<string | undefined>(undefined);
  const [physician, setPhysician] = useState<string>("");
  const PAGE_SIZE = useRef(30).current;
  const state = useRef("NJ").current;

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const getPhysiciansList = (specialty: string, physician?: string) => {
    setSpecialty(specialty);
    setPhysician(physician || "");
  };

  const fetchQuery = ({ pageParam = 0 }): Promise<PhysiciansListData> =>
    axiosClient.get(API_ENDPOINTS.get_providers_list, {
      params: {
        specialty,
        page_num: pageParam,
        page_size: PAGE_SIZE,
        state,
        query: physician.trim(),
      },
    });

  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.physicianList, { specialty, state, physician }],
    queryFn: fetchQuery,
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length * PAGE_SIZE < lastPage.data.totalRecords) {
        return allPages.length + 1;
      }
    },
    enabled: !!specialty,
    staleTime: Infinity,
    onError: () => {
      addError(errorMsg);
    },
  });

  return {
    data,
    getPhysiciansList,
    isSuccess,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};
