// @ts-nocheck
import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import MenuContainer from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import SettingsIcon from "../../../../icons/SettingsIcon";
import { useAppState } from "../../../../state";
import AboutDialog from "../../../AboutDialog/AboutDialog";
import ConnectionOptionsDialog from "../../../ConnectionOptionsDialog/ConnectionOptionsDialog";
import DeviceSelectionDialog from "../../../DeviceSelectionDialog/DeviceSelectionDialog";
import { makeStyles } from "tss-react/mui";
import { useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()({
  settingsButton: {
    margin: "1.8em 0 0",
  },
});

export default function SettingsMenu({
  mobileButtonClass,
  handleHelpModalOpen,
}: {
  mobileButtonClass?: string;
  handleHelpModalOpen?: () => void;
}) {
  const { classes } = useStyles();
  const { roomType } = useAppState();
  const isMobile = useIsMobile();
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setMenuOpen(true)}
        startIcon={<SettingsIcon />}
        className={isMobile ? mobileButtonClass : classes.settingsButton}
      >
        Settings
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: isMobile ? "left" : "right",
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: "center",
        }}
        data-testid="MenuContainer"
      >
        <MenuItem onClick={() => setAboutOpen(true)} data-testid="setAboutOpen">
          <Typography variant="body1">About</Typography>
        </MenuItem>
        <MenuItem onClick={() => setDeviceSettingsOpen(true)} data-testid="setDeviceSettingsOpen">
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>
        {roomType !== "peer-to-peer" && roomType !== "go" && (
          <MenuItem
            onClick={() => setConnectionSettingsOpen(true)}
            data-testid="setConnectionSettingsOpen"
          >
            <Typography variant="body1">Connection Settings</Typography>
          </MenuItem>
        )}
        {handleHelpModalOpen && (
          <MenuItem onClick={() => handleHelpModalOpen()}>
            <Typography variant="body1">Help?</Typography>
          </MenuItem>
        )}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
