/** @format */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Calender from "components/Calender/Calender";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import "../../../../App.css";
import { usePatientSearchStyles } from "./styles";

export default function PatientForm({
  firstNameDefault,
  lastNameDefault,
  dobDefault,
  zipDefault,
  cellPhoneDefault,
  genderDefault,
  handleCancelPatientFormBtn,
  redirectAfterCreated = true,
  getPatientProfile,
  currentVirtualVisit,
}) {
  const { classes } = usePatientSearchStyles();

  const app = useApp();
  const { url } = useLocation();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(firstNameDefault);
  const [lastName, setLastName] = useState(lastNameDefault);
  const [email, setEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");
  const [dob, setDob] = useState(dobDefault);
  const [zipCode, setZipCode] = useState(zipDefault);
  const [cellPhone, setCellPhone] = useState(cellPhoneDefault);
  const [gender, setGender] = useState(genderDefault);
  const [isLoading, setLoading] = useState(false);
  const [disableCreatePatient, setDisableCreatePatient] = useState(false);
  const [cellPhoneTouched, setCellPhoneTouched] = useState(false);

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setStreetAddress("");
    setStreetAddress2("");
    setDob("");
    setZipCode("");
    setCellPhone("");
    setGender("");
  };

  const handleSaveBtn = async (event) => {
    event.preventDefault();
    setLoading(true);
    const postUrl = "/api/s/console/patient/create";
    const newPatient = {
      firstName,
      lastName,
      email,
      streetAddress,
      streetAddress2,
      dob,
      zipCode,
      cellPhone,
      gender,
      patient_user_id: currentVirtualVisit?.patient_id,
    };

    try {
      const { data } = await axiosClient.post(postUrl, newPatient);

      if (data && data?.data && data?.data?.pat_id) {
        setLoading(false);
        app.addInfoMsg(
          `New Patient Record has been created successfully with patient ID ${data.data.pat_id} MRN ${data.data.pat_mrn_id}`,
        );
        clearFields();
        if (redirectAfterCreated) {
          const forward = `${url}/${data.data.pat_id}`;
          navigate(forward);
        }
        if (getPatientProfile && currentVirtualVisit) {
          getPatientProfile(currentVirtualVisit?.id);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(`Problem calling patient create, error ${error}`);
      if (error.response.status === 409) {
        app.addInfoMsg("Existing Patient Record was matched with existing patient");
      } else {
        app.addError(
          "Error: Patient create failed to saved, unexpected error. Please try again or contact support.",
        );
      }
    }
  };
  const handleCellPhoneOnChange = (e) => {
    const { value } = e.target;
    if ((value && value.match(/^[0-9]+$/) == null) || value.toString().length > 10) {
      return;
    }

    setCellPhone(e.target.value);
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    if (email.indexOf("@") !== -1) {
      const emailServer = email.split("@")[1].toLowerCase();
      setDisableCreatePatient(emailServer.indexOf("atlantichealth.org") !== -1);
    }
  };

  return (
    <div className={classes.patientFormContainer}>
      <div className={classes.fieldsContainer}>
        <div>
          <TextField
            label="First Name"
            id="firstName"
            value={firstName}
            className={classes.textField}
            InputProps={{
              className: classes.inputRoot,
            }}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="Last Name"
            id="lastName"
            value={lastName}
            className={classes.textField}
            InputProps={{
              className: classes.inputRoot,
            }}
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <TextField
        id="email_address"
        label="Email Address"
        value={email}
        className={classes.textField}
        margin="normal"
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => handleEmailChange(e.target.value)}
      />
      <Calender dateProp={dob} setDateProp={setDob} label="Date of Birth" />
      <TextField
        id="street_address"
        label="Street Address"
        value={streetAddress}
        className={classes.textField}
        InputProps={{
          className: classes.inputRoot,
        }}
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        helperText="This field is required"
        required
        onChange={(e) => setStreetAddress(e.target.value)}
      />
      <TextField
        fullWidth
        id="street_address"
        label="Street Address Line 2"
        value={streetAddress2}
        className={classes.textField}
        InputProps={{
          className: classes.inputRoot,
        }}
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => setStreetAddress2(e.target.value)}
      />
      <div className={classes.fieldsContainer}>
        <div>
          <TextField
            label="Zip Code"
            id="zip"
            value={zipCode}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            onChange={(e) => setZipCode(e.target.value)}
            InputProps={{
              className: classes.inputRoot,
            }}
          />
        </div>
        <div>
          <TextField
            label="Cell Phone"
            id="cellPhone"
            value={cellPhone}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            onChange={(e) => handleCellPhoneOnChange(e)}
            helperText="Phone number must be 10 digit"
            InputProps={{
              className: classes.inputRoot,
            }}
            placeholder="##########"
            error={cellPhone?.length < 10 && cellPhoneTouched}
            onBlur={() =>
              cellPhone
                ? cellPhone.length < 10
                  ? setCellPhoneTouched(true)
                  : setCellPhoneTouched(false)
                : setCellPhoneTouched(true)
            }
          />
        </div>
      </div>
      <TextField
        id="gender"
        label="Sex at Birth"
        select
        value={gender}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.inputRoot,
        }}
        helperText="This field is required"
        required
        onChange={(e) => setGender(e.target.value)}
      >
        <MenuItem key="male" value="male">
          Male
        </MenuItem>
        <MenuItem key="female" value="female">
          Female
        </MenuItem>
      </TextField>
      <div className={classes.fieldsContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={(e) => handleSaveBtn(e)}
          disabled={isLoading || !gender || !streetAddress || disableCreatePatient}
          startIcon={isLoading ? <CircularProgress /> : ""}
        >
          Create Patient
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          disabled={isLoading}
          onClick={() => handleCancelPatientFormBtn()}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
