import React, { useEffect, useState } from "react";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ChipDropdown } from "./ChipDropdown";
import { format, formatISO, isValid } from "date-fns";
import PropTypes from "prop-types";
import { useApp } from "../../../util/AppContext";
import { usePrograms } from "../../../util/ProgramsContext";
import { axiosClient } from "../../../util/api_helper";
import { useStyles } from "../styles";

const SearchInputByField = ({
  customInput,
  handleCustomInputValueChange,
  classes,
  handleTagChange,
  setSearchText,
  doSearch,
  selectedTag,
  searchText,
  handleRefreshData,
  tagList,
  date,
  clearFilters,
  handlePressEnter,
  selectedOptions,
  setSelectedOptions,
}) => {
  if (customInput?.type === "tagsDropdown") {
    return (
      <div className={classes.tagsContainer}>
        <TextField
          variant="outlined"
          value={selectedTag}
          fullWidth
          name="tags"
          onChange={(e) => handleTagChange(e.target.value)}
          select
          data-testid="tagsDropdown"
          onKeyDown={handlePressEnter}
        >
          {tagList.map(({ value, name }) => (
            <MenuItem value={value} key={value} data-testid={`tags-dropdown-${value}`}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          color="primary"
          variant="text"
          type="button"
          onClick={() => handleTagChange(null)}
          disabled={!selectedTag}
          data-testid="clearTags"
          aria-label="clear tags"
        >
          <Button variant="outlined" color="primary" className={classes.clearLable}>
            Clear
          </Button>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={doSearch}
          className={classes.searchButton}
          data-testid="search-button"
        >
          Search
        </Button>
      </div>
    );
  }
  if (customInput?.type === "chipDropdown") {
    return (
      <div className={classes.chipContainer}>
        <ChipDropdown
          options={customInput.options ? customInput.options : tagList}
          onChangeSelectedOptions={handleCustomInputValueChange}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          transformValueToName={customInput.transformValueToName}
        />
        <Button
          color="primary"
          variant="text"
          type="button"
          onClick={() => clearFilters(null)}
          disabled={selectedOptions.length > 0}
          data-testid="clearTags"
          aria-label="clear tags"
        >
          <Button variant="outlined" color="primary">
            Clear
          </Button>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={doSearch}
          className={classes.searchButton}
          data-testid="search-button"
        >
          Search
        </Button>
      </div>
    );
  }
  if (customInput?.type === "date") {
    return (
      <div className={classes.calenderWrapper}>
        <DatePicker
          fullWidth
          format="MM/dd/yyyy"
          margin="normal"
          label="Date"
          value={date}
          onChange={handleCustomInputValueChange}
          disableFuture={customInput?.disableFuture ?? true}
          required={false}
          slotProps={{
            textField: {
              inputProps: {
                "data-testid": "filter-date-picker",
                "data-cy": "filter-date-picker",
              },
              id: "filter-date-picker",
              variant: "outlined",
              fullWidth: true,
            },
          }}
        />
        <IconButton onClick={clearFilters} data-testid="clearFiltersBtn" size="large">
          <CancelIcon />
        </IconButton>
      </div>
    );
  }
  return (
    <div className={classes.tagsContainer}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={searchText}
        fullWidth
        onInput={(e) => setSearchText(e.target.value)}
        InputProps={{
          endAdornment: <RefreshIcon onClick={handleRefreshData} data-testid="refresh-icon" />,
        }}
        data-testid="searchByType"
        onKeyDown={handlePressEnter}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={doSearch}
        className={classes.searchButton}
        data-testid="search-button"
      >
        Search
      </Button>
    </div>
  );
};

SearchInputByField.propTypes = {
  clearFilters: PropTypes.func,
  classes: PropTypes.any,
};

export function SearchFilter({
  filterSourceData,
  colDefs,
  handleRefreshData,
  setSearchByTag,
  defaultSearchBy,
}) {
  const app = useApp();
  const { classes } = useStyles({});
  const { programId } = usePrograms();

  const [searchBy, setSearchBy] = useState(defaultSearchBy || "");
  const [customInput, setCustomInput] = useState();
  const [searchText, setSearchText] = useState("");
  const [tagList, setTagList] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [date, setDate] = useState(formatISO(new Date(), { representation: "date" }));
  const isSearchBarDisabled = defaultSearchBy === "collectedDatetime" && !searchBy;

  const getTags = async () => {
    try {
      const url = `/api/s/console/tags/program/${programId}`;
      const response = await axiosClient.get(url);
      const currentTags = response?.data;
      if (currentTags) {
        const newTagList = currentTags.map((item) => {
          return { ...item, value: item.id };
        });
        setTagList(newTagList);
      }
    } catch (e) {
      app.addError("There was an error trying to get Tags");
    }
  };

  const searchByRadioOnChange = (id, customInput) => {
    if (customInput?.type === "tagsDropdown") {
      getTags();
    }
    setSelectedOptions([]);
    setSearchBy(id);
    setSearchText("");
    setCustomInput(customInput);
  };

  const doSearchWithParams = (_searchBy = null, _searchText = null) => {
    filterSourceData(_searchBy, _searchText);
  };

  const doSearch = () => {
    filterSourceData(searchBy, searchText);
  };

  const handlePressEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      doSearch();
    }
  };

  const handleTagChange = (id) => {
    setSelectedTag(id);
    setSearchByTag(Number(id));
    doSearch();
  };

  const handleCustomInputValueChange = (newVal) => {
    if (customInput.type === "date") {
      if (isValid(newVal)) {
        const formattedDate = format(new Date(newVal), "MM/dd/yyyy");
        setDate(formattedDate);
        setSearchText(formattedDate);
        doSearchWithParams(searchBy, formattedDate);
      } else {
        // do nothing: the date picker will update its own value.
      }
    } else {
      setSearchText(newVal);
      doSearch();
    }
  };

  const handleRefresh = () => {
    handleRefreshData(searchBy);
  };

  const clearFilters = () => {
    setSearchText("");
    setSearchBy("");
    doSearchWithParams();
  };

  useEffect(() => {
    setCustomInput(colDefs.find((col) => col.id === searchBy)?.customInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy, colDefs]);

  return (
    <FormControl variant="standard" component="fieldset" className={classes.searchContainer}>
      <FormLabel component="legend" className={classes.searchLabel}>
        Search By
      </FormLabel>
      <RadioGroup aria-label="search_by" name="search_by" value={searchBy} row>
        {colDefs.map((col) => {
          return (
            col.searchable && (
              <FormControlLabel
                key={col.id}
                value={col.id}
                control={<Radio />}
                label={col.label}
                onChange={() => searchByRadioOnChange(col.id, col.customInput)}
                data-testid={`radio-button-${col.id}`}
              />
            )
          );
        })}
      </RadioGroup>
      <div
        style={{
          opacity: isSearchBarDisabled ? 0.5 : 1,
          pointerEvents: isSearchBarDisabled ? "none" : "all",
        }}
      >
        <SearchInputByField
          customInput={customInput}
          handleCustomInputValueChange={handleCustomInputValueChange}
          classes={classes}
          handleTagChange={handleTagChange}
          setSearchText={setSearchText}
          doSearch={doSearch}
          selectedTag={selectedTag}
          searchText={searchText}
          handleRefreshData={handleRefresh}
          tagList={tagList}
          date={date}
          clearFilters={clearFilters}
          handlePressEnter={handlePressEnter}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </div>
    </FormControl>
  );
}

SearchFilter.propTypes = {
  colDefs: PropTypes.any,
};
