import React, { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PhoneIcon from "@mui/icons-material/Phone";
import { Button, Card, Grid, IconButton, Modal, Typography } from "@mui/material";
import useStyles from "./DoctorInfo.styles";

interface Props {
  fullName: string;
  isShown: boolean;
  providerNumber: string;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  isOnlineBoking: boolean;
  providerId: string;
}

const ModalMakeAppointment: FC<Props> = ({
  fullName,
  isShown,
  providerNumber,
  setIsShown,
  isOnlineBoking,
  providerId,
}) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const toBookOnline = () => {
    navigate("/booking-online", { state: providerId });
  };

  return (
    <Modal className={classes.modalContainer} open={isShown}>
      <div className={classes.modal}>
        <Typography className={classes.modalTitle}>
          Schedule the care you need now. Book an appointment with {fullName}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setIsShown(false)}
          className={classes.closeButton}
          size="large"
        >
          <Close />
        </IconButton>
        <Grid container justifyContent="center" className={classes.cardContainer}>
          {isOnlineBoking && (
            <Grid item className={classes.card}>
              <Card className={classes.item}>
                <EventAvailableIcon className={classes.icon} />
                <Typography align="center">Book an appointment directly online.</Typography>
                <Button className={classes.bookingButtonOnline} fullWidth onClick={toBookOnline}>
                  Book online
                </Button>
              </Card>
            </Grid>
          )}
          <Grid item className={classes.card}>
            <Card className={classes.item}>
              <PhoneIcon className={classes.icon} />
              <Typography align="center">Call and we'll help book your appointment.</Typography>
              <Button className={classes.bookingButtonPhone} fullWidth>
                {providerNumber}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default ModalMakeAppointment;
