/* eslint-disable */
import { getTimeByMs } from "util/util_functions";

export const colDefs = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
    searchable: false,
    sortable: true,
  },
  {
    id: "user_first_name",
    numeric: false,
    disablePadding: false,
    label: "First Name",
    searchable: false,
  },
  {
    id: "user_last_name",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    searchable: false,
  },
  {
    id: "user_dob",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "DOB",
    searchable: false,
  },
  {
    id: "user_cell_phone",
    numeric: false,
    disablePadding: false,
    label: "Cell Phone",
    searchable: false,
  },
  {
    id: "user_city",
    numeric: false,
    disablePadding: false,
    label: "City",
    searchable: false,
  },
  {
    id: "user_state",
    numeric: false,
    disablePadding: false,
    label: "State",
    searchable: false,
  },
  {
    id: "user_zip",
    numeric: false,
    disablePadding: false,
    label: "Zip code",
    searchable: false,
  },
  {
    id: "patient_reason",
    numeric: false,
    disablePadding: false,
    label: "Patient Reason",
    searchable: false,
  },
  {
    id: "patient_other_reason",
    numeric: false,
    disablePadding: false,
    label: "Patient Other Reason",
    searchable: false,
  },
  {
    id: "visit_details",
    numeric: false,
    disablePadding: false,
    label: "Visit Details",
    searchable: false,
  },
  {
    id: "emergency_contact_name",
    numeric: false,
    disablePadding: false,
    label: "Emer Contact Name",
    searchable: false,
  },
  {
    id: "emergency_contact_phone",
    numeric: false,
    disablePadding: false,
    label: "Emer Contact Phone",
    searchable: false,
  },
  {
    id: "pcp_name",
    numeric: false,
    disablePadding: false,
    label: "PCP Name",
    searchable: false,
  },
  {
    id: "start_dttm",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "Start On",
    searchable: false,
  },
  {
    id: "end_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "End On",
    searchable: false,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    searchable: true,
  },
  {
    id: "context",
    numeric: false,
    disablePadding: false,
    label: "Context",
    searchable: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    searchable: true,
    customInput: {
      type: "chipDropdown",
      options: [
        "Consumer initiated",
        "Ended",
        "New",
        "Patient Waiting",
        "Staff Initiated",
        "Started",
        "Expired",
      ],
    },
  },
  {
    id: "external_tracking_id",
    numeric: false,
    disablePadding: false,
    label: "Ext Tracking ID",
    searchable: false,
  },
  {
    id: "external_emr_encounter_id",
    numeric: false,
    disablePadding: false,
    label: "Encounter ID",
    searchable: false,
  },
  {
    id: "staff_ended_user_id",
    numeric: false,
    disablePadding: false,
    label: "Staff Ended Usr ID",
    searchable: false,
  },
  {
    id: "callback_phone",
    numeric: false,
    disablePadding: false,
    label: "Callback Phone",
    searchable: false,
  },
  {
    id: "visit_place",
    numeric: false,
    disablePadding: false,
    label: "Visit Place",
    searchable: false,
  },
  {
    id: "source",
    numeric: false,
    disablePadding: false,
    label: "Source",
    searchable: false,
  },
  {
    id: "patient_id",
    numeric: false,
    disablePadding: false,
    label: "User ID",
    searchable: false,
  },
  {
    id: "user_emr_mrn",
    numeric: false,
    disablePadding: false,
    label: "Patient MRN",
    searchable: false,
  },
  {
    id: "queue",
    numeric: false,
    disablePadding: false,
    label: "Queue",
    searchable: false,
  },
  {
    id: "urgency",
    numeric: false,
    disablePadding: false,
    label: "Urgency",
    searchable: false,
  },
  {
    id: "currently_assigned_staff_user_email",
    numeric: false,
    disablePadding: false,
    label: "Curr Assigned Staff Usr Email",
    searchable: false,
  },
  {
    id: "last_message_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Last Message On",
    searchable: false,
  },
  {
    id: "external_convo_id",
    numeric: false,
    disablePadding: false,
    label: "Ext Convo ID",
    searchable: false,
  },
  {
    id: "accident_related",
    numeric: false,
    disablePadding: false,
    label: "Is Accident Related",
    searchable: false,
    isCustomContent: true,
    func(val) {
      return val === true ? "Yes" : "No";
    },
  },
  {
    id: "checkout_note",
    numeric: false,
    disablePadding: false,
    label: "Checkout Note",
    searchable: false,
  },
  {
    id: "patient_reference_source",
    numeric: false,
    disablePadding: false,
    label: "Patient Reference Source",
    searchable: false,
  },
  {
    id: "physician_first_connection_date",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "First Connection Date",
    searchable: false,
  },
  {
    id: "physician_last_disconnection_date",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Last Disconnection Date",
    searchable: false,
  },
  {
    id: "physician_in_session_time",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "In Session Time",
    searchable: false,
    isCustomContent: true,
    func: (val) => (val ? getTimeByMs(val * 1000) : <>&ndash;</>),
  },
  {
    id: "tags",
    numeric: false,
    disablePadding: false,
    label: "Tags",
    searchable: true,
    customInput: {
      type: "tagsDropdown",
    },
  },
];

export const consultationColDefs = [
  ...colDefs,
  {
    id: "surgery",
    numeric: false,
    disablePadding: false,
    label: "Previous Surgery",
    searchable: false,
  },
  {
    id: "receiving_treatment",
    numeric: false,
    disablePadding: false,
    label: "Current Treatment",
    searchable: false,
  },
  {
    id: "clinical_trials",
    numeric: false,
    disablePadding: false,
    label: "Clinical Trials",
    searchable: false,
  },
];
