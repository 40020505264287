import React from "react";
import { Box } from "@mui/material";
import { SpecimentReportListDataReport } from "hooks/specimenTracking/useGetSpecimenReports/useGetSpecimenReports";
import { SpecimenReportCard } from "views/SpecimenTracking/components/ReportCard";
import { SpecimenReportCardType } from "views/SpecimenTracking/components/ReportCard/types";
import { SpecimenReportFilters } from "views/SpecimenTracking/components/ReportFilter";
import useTranslation from "hooks/useTranslation";
import { useSpecimenTrackingReportsStyle } from "../styles";

interface Props {
  handleDates: (initDate: string, finDate: string) => void;
  report: SpecimentReportListDataReport | undefined;
  totalRecords: number;
  initialDate: Date;
  finalDate: Date;
}

interface FilterCard {
  header: string;
  text: string;
  footer: string;
  type: SpecimenReportCardType;
}
export const ReportBySpecimenFilters = ({
  handleDates,
  report,
  totalRecords,
  initialDate,
  finalDate,
}: Props) => {
  const {
    web: {
      specimenReportCards: {
        averageJourneyTimeHeader,
        averageJourneyTimeFooter,
        completedDropOffsHeader,
        currentlyInTransitHeader,
      },
    },
  } = useTranslation();
  const { classes } = useSpecimenTrackingReportsStyle();
  const {
    averageJourneyTime = 0,
    countCurrentlyInTransit = 0,
    countCompletedDropOffs = 0,
  } = report ?? {};

  const filtersCards: FilterCard[] = [
    {
      header: averageJourneyTimeHeader,
      text: `${Math.floor(averageJourneyTime) || 0} minutes`,
      footer: averageJourneyTimeFooter,
      type: "primary",
    },
    {
      header: completedDropOffsHeader,
      text: `${countCompletedDropOffs || 0} samples`,
      footer: `out of ${totalRecords || 0} samples`,
      type: "secondary",
    },
    {
      header: currentlyInTransitHeader,
      text: `${countCurrentlyInTransit || 0} samples`,
      footer: `out of ${totalRecords || 0} samples`,
      type: "primary",
    },
  ];
  return (
    <Box className={classes.cardContainer}>
      <SpecimenReportFilters
        handleDates={handleDates}
        initialDate={initialDate}
        finalDate={finalDate}
      />
      {filtersCards.map(({ header, text, footer, type }, index) => (
        <SpecimenReportCard
          key={`reportcard-${index.toString()}`}
          header={header}
          text={text}
          footer={footer}
          type={type}
        />
      ))}
    </Box>
  );
};
