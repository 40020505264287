import React, { Fragment } from "react";
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import PropTypes from "prop-types";
import { GenericEnhacedTableHeadProps } from "../types";
import { useStyles } from "../styles";

export function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  colDefs,
  gridConfig,
  hideCheckboxes,
  collapsedTable,
}: Readonly<GenericEnhacedTableHeadProps>) {
  const { classes, cx } = useStyles({});

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {collapsedTable && <TableCell style={{ width: "5em" }} />}
        {!hideCheckboxes && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              className={classes.checkbox}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {gridConfig.showActionColumn && gridConfig.showActionColumnOnStart && (
          <TableCell className={classes.cellHead} data-testid="action-start">
            Action
          </TableCell>
        )}
        {colDefs.map((headCell) => {
          const align = headCell.numeric ? "right" : "left";
          const padding = headCell.disablePadding ? "none" : "normal";

          return (
            <Fragment key={headCell.id}>
              {!!headCell?.sortable && (
                <TableCell
                  align={align}
                  padding={padding}
                  sortDirection={orderBy === headCell.id ? order : false}
                  className={classes.cellHead}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id && (
                      <span className={classes.visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </span>
                    )}
                  </TableSortLabel>
                </TableCell>
              )}
              {!headCell?.sortable && (
                <TableCell
                  key={headCell.id}
                  align={align}
                  padding={padding}
                  className={cx(classes.cellHead, classes.unsoratbleCellHead)}
                >
                  {headCell.label}
                </TableCell>
              )}
            </Fragment>
          );
        })}
        {gridConfig.showActionColumn && !gridConfig.showActionColumnOnStart && (
          <TableCell className={classes.cellHead} data-testid="action-end">
            Action
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  colDefs: PropTypes.array.isRequired,
};
