/** @format */

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Add as AddIcon } from "@mui/icons-material";
import { CardMedia, Grid, Typography } from "@mui/material";
import Loader from "../Loader";
import ConsentCheckbox from "./ConsentCheckbox";
import ConsentButton from "./ConsetButton";
import PatientData from "./PatientData";
import { makeStyles } from "tss-react/mui";
import { ConsentPath } from "util/constants";
import { useClientAuthorize } from "./useClientAuthorize";
import { useApp } from "../../util/AppContext";
import { POST_OPTIONS, getApiHost } from "../../util/api_helper";
import ClearLogo from "../../static/images/clear.png";
import AtlanticLogo from "../../static/svg/logo_header.svg";

const useStyles = makeStyles()({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 60,
    width: 60,
    margin: "2px 5px",
  },
  media2: {
    height: 60,
    width: 180,
    margin: "2px 5px",
    marginTop: "5px",
  },
  image: {
    maxWidth: "90%",
    height: "auto",
  },
  para: {
    textAlign: "left",
    fontSize: "16pt",
  },
  iconDiv: {
    height: "60px",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  error: {
    color: "red",
  },
});
const VaccineAuth = () => {
  const { name } = useParams();
  const { classes } = useStyles();
  const [postResponse, setPostResponse] = useState(null);
  const [postError, setPostError] = useState(null);
  const [postLoading, setPostLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState(null);
  const { isLoading, response, error, clientId } = useClientAuthorize();
  const app = useApp();

  const handleSubmit = () => {
    if (checked) {
      setPostLoading(true);
      const host = getApiHost();
      const post_url = `${host}/api/s/start/oauth/authorize`;
      fetch(post_url, {
        ...POST_OPTIONS,
        body: JSON.stringify({
          confirm: true,
          client_id: clientId,
          name,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setPostLoading(false);
          setPostResponse({ ...res.data });
        })
        .catch((err) => {
          setPostError(err);
          setPostLoading(false);
        });
    } else {
      setCheckboxError("Please tick the check box to confirm");
    }
  };

  useEffect(() => {
    if (postResponse) {
      if (postResponse.code) {
        setPostLoading(true);
        const url = new URL(window.location.href);
        //  TODO update scope and response type from server
        const state = url.searchParams.get("state");
        const schemaParts = postResponse.redirect_uri.split(":");
        const redirectUrl = `${postResponse.redirect_uri}?code=${postResponse.code}&scope=profile&response_type=code&state=${state}`;
        try {
          // TODO make generic, and  do  we need this?
          navigator.registerProtocolHandler(schemaParts[0], redirectUrl, "CLEAR App");
        } catch (err) {
          console.log(`problem registering custom schema handler, ${err}`);
          app.addError("Problem redirecting back to requesting application.");
        }

        window.open(redirectUrl, "_self");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postResponse]);

  return (
    <>
      {!postLoading ? (
        !postError ? (
          <>
            <br />
            <br />
            <Grid container direction="row" justifyContent="center">
              <CardMedia className={classes.media} image={ClearLogo} title="Clear Logo" />
              <div className={(classes.iconDiv, classes.flexCenter)}>
                <AddIcon color="secondary" />
              </div>
              <div className={classes.media2}>
                <img className={classes.image} src={AtlanticLogo} alt="Atlantic Logo" />
              </div>
            </Grid>
            {!isLoading ? (
              !error?.message ? (
                <>
                  <PatientData
                    error={error}
                    clientName={response?.data?.client_name}
                    showQRCode={false}
                  />
                  <Typography variant="body2" paragraph className={classes.para}>
                    Atlantic Health is happy to offer you a <span>"digital vaccine card"</span> via
                    our integration with CLEAR. Please click here to initiate sharing your COVID-19
                    immunization records with CLEAR.
                    <br />
                  </Typography>
                  <ConsentCheckbox
                    setChecked={setChecked}
                    error={checkboxError}
                    setError={setCheckboxError}
                    consentPath={ConsentPath.HIPAA_AUTH_CLEAR}
                  />
                  <ConsentButton className={classes.flexCenter} onClick={handleSubmit} />
                </>
              ) : (
                <div className="flex-center">
                  <Typography className={classes.error}>{error?.message}</Typography>
                </div>
              )
            ) : (
              <div className="flex-center">
                <Loader />
              </div>
            )}
          </>
        ) : (
          <>
            {postError?.message && (
              <Typography variant="h6">
                {postError?.message.includes("Unexpected")
                  ? "Something Went Wrong"
                  : postError?.message}
              </Typography>
            )}
            {error?.message && (
              <Typography variant="h6">
                {error?.message.includes("Unexpected") ? "Something Went Wrong" : error?.message}
              </Typography>
            )}
          </>
        )
      ) : (
        <div className="flex-center" style={{ width: "100%" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default VaccineAuth;
