import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AiDiagnoseModal } from "./components/AiDiagnose";
import { UseGetAvailableCards } from "./components/GetAvailableCards";
import { createCYidfromText, useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    height: "100%",
  },
  container: {
    maxWidth: "960px",
    width: "90%",
    margin: "auto",
  },
  iconBg: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardMedia: {
    padding: "16px",
  },
  cardContent: {
    paddingBottom: "16px",
    textAlign: "left",
  },
  title: {
    margin: "48px 0",
  },
}));

export default function ConsoleHome() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { availableCards } = UseGetAvailableCards();
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useIsMobile();

  return (
    <>
      <Typography
        variant="h3"
        className={classes.title}
        style={{ paddingTop: isMobile ? "50px" : "0px" }}
      >
        Digital Experience Platform
      </Typography>
      <div className={classes.container}>
        <Grid container spacing={2}>
          {availableCards.map(({ key, global, text, link, icon, hasModal }) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Card
                className={classes.root}
                onClick={() => {
                  if (hasModal) {
                    setOpenModal(true);
                  } else {
                    return global === true ? window.open(link) : navigate(link);
                  }
                }}
                data-cy={createCYidfromText(text)}
                primary={key === "Menu" ? "Menu" : null}
              >
                <CardMedia classes={{ root: classes.cardMedia }}>
                  <div className={classes.iconBg}>{icon}</div>
                </CardMedia>
                <CardContent classes={{ root: classes.cardContent }}>
                  <Typography variant="body1">{text}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      {openModal && <AiDiagnoseModal onClose={() => setOpenModal(false)} />}
    </>
  );
}
