import { FETCH_PATIENT_VIRTUAL_SESSION } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

export const useGetPatientVS = () => {
  const app = useApp();

  const getMessages = async ({ vsId }) => {
    try {
      const apiEndpoint = API_ENDPOINTS.twilio_get_virtual_session;
      const { data } = await axiosClient.post(apiEndpoint, { vs_id: vsId });
      app.dispatch({
        type: FETCH_PATIENT_VIRTUAL_SESSION,
        payload: data,
      });
    } catch (error) {
      app.addError("Problem loading last message, please reload and try again or contact support.");
    }
  };
  return { getMessages };
};
