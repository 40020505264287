import React from "react";
import { useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Steps } from "components/Consultation/Steps";
import { preJoinStyles } from "components/Video/components/PreJoinScreens/prejoinStyles";
import { ProgramNames } from "util/constants";
import { isRNApp } from "util/RNWebView";

type Props = {
  step: Steps;
  children: React.ReactElement;
};

const Tab = ({ activeStep, checkStep, children }) => {
  const { classes } = preJoinStyles();

  return (
    <Grid item xs>
      <div className={classes.tabItem}>
        <div style={{ height: 24 }}>
          {activeStep > checkStep ? <CheckIcon data-testid={`checked-${checkStep}`} /> : ""}
        </div>
        <div>{children}</div>
      </div>
      <hr
        className={
          activeStep > checkStep
            ? classes.stepperHorizontalLineCompleted
            : classes.stepperHorizontalLineActive
        }
      />
    </Grid>
  );
};

const ConsultationLayout: React.FC<Props> = ({ step, children }) => {
  const { classes, cx } = preJoinStyles();
  const { programName } = useParams<{ programName: string }>();

  const isPhysicalTherapy = programName === ProgramNames.PHYSICAL_THERAPY;

  const url = new URL(window.location.href);
  const embedded = url.searchParams.get("embedded");
  const isEmbedded = embedded === "true" || isRNApp;

  return (
    <>
      {step !== Steps.AppointmentScheduledStep && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={cx(classes.rootGrid, {
            [classes.rootGridEmbedded]: isEmbedded,
          })}
          data-testid="stepper"
        >
          <Grid item md />
          <Tab activeStep={step} checkStep={Steps.SignInStep}>
            About You
          </Tab>
          <Tab activeStep={step} checkStep={Steps.ScreeningStep}>
            Care Needs
          </Tab>
          <Tab activeStep={step} checkStep={Steps.UploadStep}>
            Upload
          </Tab>
          <Tab
            activeStep={step}
            checkStep={isPhysicalTherapy ? Steps.LocationStep : Steps.SchedulingStep}
          >
            Schedule
          </Tab>
          <Grid item md />
        </Grid>
      )}
      <Box boxSizing="border-box" maxWidth="640px" margin="0px auto" padding={2} paddingBottom={5}>
        {children}
      </Box>
    </>
  );
};

export default ConsultationLayout;
