import React, { FC, useEffect, useState } from "react";
import Create from "@mui/icons-material/Create";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import FederalMedicalExaminerDeterminationStep from "./Determinations/FederalMedicalExaminerDeterminationStep";
import StateMedicalExaminerDeterminationStep from "./Determinations/StateMedicalExaminerDeterminationStep";
import EditWarningDialog from "views/DOTForm/staff/EditWarningModal";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { StepsProps } from "views/DOTForm/types/constants";
import useTranslation from "hooks/useTranslation";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import useStyles from "../../styles/forms.styles";

export interface MedicalExaminer {
  medicalExaminerId: number;
  firstName: string;
  lastName: string;
  email: string;
  NPI: string;
  locationName: string;
  providerSpecialty: string;
  nationalRegistryNumber: string;
  certificationNumber: string;
  issuingState: string;
  userId: number;
}

export const MedicalDeterminationStep: FC<StepsProps> = ({
  formData,
  handleChange,
  handleStepChange,
  setShouldDisableContinueButton,
}) => {
  const [selectedMedicalDetermination, setSelectedMedicalDetermination] =
    React.useState<string>("FEDERAL");
  const { classes } = useStyles({
    alignLeft: false,
  });

  const {
    web: {
      dotForm: {
        staff: { medicalDetermination: copy },
      },
    },
  } = useTranslation();
  const { user } = useAuth();
  const currentUserId = Number(user.userId);

  const { medicalDetermination, submissionStatus } = formData;

  const [medicalExaminers, setMedicalExaminers] = useState<MedicalExaminer[]>([]);
  const [selectedExaminerId, setSelectedExaminerId] = useState<number | undefined>();
  const [isOpenWarningModal, setIsOpenWarningModal] = useState<boolean>(false);
  const [enableDetermination, setEnableDetermination] = useState<boolean>(false);
  const disabledInput = submissionStatus === "COMPLETED" && !enableDetermination;
  const isStatusCompleted = submissionStatus === "COMPLETED";

  // Fetch the list of medical examiners from the API
  useEffect(() => {
    const fetchExaminers = async () => {
      try {
        const response = await axiosClient.get(API_ENDPOINTS.dotMedicalExaminers);
        const medicalExaminersList = response?.data?.data;
        if (medicalExaminersList) {
          setMedicalExaminers(medicalExaminersList);
        } else {
          console.error("Failed to get medical examiners:", response);
        }
        const getExaminerIdByUserId: MedicalExaminer = medicalExaminersList.find(
          (t: MedicalExaminer) => t.userId === currentUserId,
        );
        if (medicalDetermination?.medicalExaminerId) {
          setSelectedExaminerId(medicalDetermination?.medicalExaminerId);
        } else if (getExaminerIdByUserId) {
          setSelectedExaminerId(getExaminerIdByUserId?.medicalExaminerId);
        } else if (handleStepChange) {
          handleStepChange({ shouldDisableContinueButton: true });
        }
      } catch (error) {
        console.error("Error fetching medical examiners:", error);
      }
    };

    fetchExaminers();
  }, []);

  useEffect(() => {
    setSelectedMedicalDetermination(medicalDetermination?.determinationType || "FEDERAL");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const MEET_WITH_OBSERVATIONS = "MEET_WITH_OBSERVATIONS";
    const isQualifiedFor = !medicalDetermination?.data?.monitoring?.qualifiedFor;
    const isMeetWithObservations =
      medicalDetermination?.federalDetermination === MEET_WITH_OBSERVATIONS ||
      medicalDetermination?.stateDetermination === MEET_WITH_OBSERVATIONS;

    if (setShouldDisableContinueButton) {
      setShouldDisableContinueButton(false);

      if (isMeetWithObservations && isQualifiedFor) {
        return setShouldDisableContinueButton(true);
      }

      if (!medicalDetermination) {
        setShouldDisableContinueButton(true);
      }
    }
  }, [medicalDetermination]);

  useEffect(() => {
    if (setShouldDisableContinueButton) {
      setShouldDisableContinueButton(true);
    }
  }, [selectedMedicalDetermination]);

  const { title, federalDetermination, stateDetermination } = copy;

  const isMobile = useIsMobile();

  const handleChangeMedicalExaminer = (e: SelectChangeEvent<number>) => {
    const selectedExaminerId = (() => {
      if (typeof e.target.value === "number") {
        return e.target.value;
      }
      return parseInt(e.target.value, 10);
    })();
    setSelectedExaminerId(selectedExaminerId);
    handleChange(`medicalDetermination.medicalExaminerId`, selectedExaminerId);
    if (handleStepChange) {
      handleStepChange({ shouldDisableContinueButton: false });
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.personalInformation_wrapper}
        data-testid="medicalDeterminationStep"
      >
        <Grid item container xs={12} md={12}>
          <Grid item xs md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="medical-examiner-select-label">{copy.medicalExaminer}*</InputLabel>
              <Select
                variant="standard"
                fullWidth
                labelId="medical-examiner-select-label"
                value={selectedExaminerId ?? ""}
                onChange={handleChangeMedicalExaminer}
                label="Select Medical Examiner"
                error={!selectedExaminerId}
                required
              >
                {medicalExaminers.map((examiner) => (
                  <MenuItem key={examiner.medicalExaminerId} value={examiner.medicalExaminerId}>
                    {examiner.lastName}, {examiner.firstName}
                  </MenuItem>
                ))}
              </Select>
              {!selectedExaminerId && (
                <FormHelperText>{copy.medicalExaminerSelectHelper}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {isStatusCompleted && !enableDetermination && (
            <Grid item xs md={3}>
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <div className={classes.inlineActionButtons}>
                  <IconButton
                    aria-label="edit determination"
                    data-cy="edit-determination"
                    onClick={() => setIsOpenWarningModal(true)}
                  >
                    <Create />
                  </IconButton>
                </div>
              </div>
            </Grid>
          )}
          <Box width="100%" />
          <Grid item xs md style={{ marginTop: "2em" }}>
            <FormControl
              variant="outlined"
              fullWidth
              className={
                isMobile ? classes.radioButtons_container : classes.radioButtons_containerLeftNoGap
              }
            >
              <Typography
                align="left"
                className={classes.timeWindowTitle}
                style={{ fontWeight: 700 }}
              >
                {title}
              </Typography>
              <RadioGroup
                row
                aria-label="determination"
                name="determination"
                value={selectedMedicalDetermination}
                onChange={(e) => setSelectedMedicalDetermination(e.target.value)}
              >
                <FormControlLabel
                  value="FEDERAL"
                  control={<Radio />}
                  label={federalDetermination}
                  disabled={disabledInput}
                />
                <FormControlLabel
                  value="STATE"
                  control={<Radio />}
                  label={stateDetermination}
                  disabled={disabledInput}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Box width="100%" />
          <div style={{ marginTop: "2em" }}>
            {selectedMedicalDetermination === "FEDERAL" ? (
              <FederalMedicalExaminerDeterminationStep
                formData={medicalDetermination}
                handleChange={handleChange}
                isDisabled={disabledInput}
              />
            ) : (
              <StateMedicalExaminerDeterminationStep
                formData={medicalDetermination}
                handleChange={handleChange}
                isDisabled={disabledInput}
              />
            )}
          </div>
        </Grid>
      </Grid>
      {isOpenWarningModal && (
        <EditWarningDialog
          bodyText={copy.warningModal.body}
          title={copy.warningModal.title}
          handleClose={() => {
            setIsOpenWarningModal(false);
            setEnableDetermination(false);
          }}
          handleSubmit={() => {
            setIsOpenWarningModal(false);
            setEnableDetermination(true);
          }}
        />
      )}
    </>
  );
};

export default MedicalDeterminationStep;
