import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Webcam from "react-webcam";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import AttachFile from "@mui/icons-material/AttachFile";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { permissions } from "components/shared/permissions";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { dataURLtoFile } from "util/urlToFile";
import { useStyles } from "../../styles/verificationViews/verificationViews.styles";

const ALLOWED_FILE_TYPES = "application/pdf, image/*, .pdf";

function InitialView({ onConfirm }) {
  const {
    web: {
      dotForm: {
        patient: {
          licenseScanner: { prelude: copy },
        },
      },
    },
  } = useTranslation();

  return (
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      <Grid item>
        <Typography variant="body1">{copy[1]}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{copy[2]}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{copy[3]}</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          data-testid="dot-begin-license-scan"
        >
          Start
        </Button>
      </Grid>
    </Grid>
  );
}

function FileDisplay({ file }) {
  const { classes } = useStyles();
  const [dataUrl, setDataUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => setDataUrl(reader.result as string);
    reader.onerror = (error) => console.error(error);
    reader.readAsDataURL(file);

    return () => {
      reader.abort();
    };
  }, [file]);

  if (!dataUrl) {
    return <CircularProgress />;
  }

  return (
    <img
      src={dataUrl}
      alt={file.name}
      className={classes.fileDisplay}
      data-testid="dot-license-preview"
      id="dot-license-preview"
    />
  );
}

function ImageSelect({ onNewFile }) {
  const { classes } = useStyles();

  const {
    web: {
      dotForm: {
        patient: { licenseScanner: copy },
      },
    },
  } = useTranslation();

  const [mediaError, setMediaError] = useState<DOMException | string | null>(null);
  const webcamRef = useRef<Webcam>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const capturePhoto = (e) => {
    e.preventDefault();

    if (!webcamRef.current) {
      console.error("Could not get webcam to capture screenshot");
      return;
    }

    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) {
      return;
    }

    const fileName = `${uuidv4().toString()}.png`;
    const file = dataURLtoFile(imageSrc, fileName);
    onNewFile(file);
  };

  const selectFile = (e) => {
    const image = e.target.files[0];
    onNewFile(image);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
      <Grid container direction="column" alignItems="stretch" className={classes.gapContainer}>
        {!mediaError ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            minScreenshotWidth={1280}
            screenshotQuality={1.0}
            imageSmoothing={false}
            videoConstraints={{
              aspectRatio: 1 / 1,
              width: 1280,
              facingMode: "environment",
            }}
            onUserMediaError={(err) => setMediaError(err)}
            // passthrough props for the underlying <video> element
            height={300}
            width="100%"
            className={classes.scanner}
            data-testid="dot-license-capture"
          />
        ) : (
          <Box className={classes.mediaErrorNotice}>
            <Typography variant="body1" data-testid="dot-license-error-detail">
              {copy.mediaError}
            </Typography>
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={capturePhoto}
          endIcon={<PhotoCamera />}
          disabled={!!mediaError}
        >
          Capture Photo
        </Button>
        <span>OR</span>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<AttachFile />}
          fullWidth
          size="large"
          onClick={() => inputFileRef.current?.click()}
        >
          Upload Photo
        </Button>
        <input
          ref={inputFileRef}
          id="dot-license-upload"
          name="dot-license-upload"
          data-testid="dot-license-upload"
          style={{ display: "none" }}
          type="file"
          multiple={false}
          value=""
          accept={ALLOWED_FILE_TYPES}
          onChange={(e) => selectFile(e)}
        />
      </Grid>
      <Typography variant="body1" className={classes.faqLink}>
        <Link to={`/${permissions.patient}/dot-form/faq`}>Need Help? View FAQ</Link>
      </Typography>
    </form>
  );
}

const DOTDriverLicenseReader = () => {
  const { classes } = useStyles();
  const [started, setStarted] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const navigate = useNavigate();
  const { addError } = useApp();
  const { formId } = useParams();
  const isMobile = useIsMobile();
  const {
    web: {
      dotForm: {
        patient: {
          licenseScanner: copy,
          addressVerification: { faqText },
        },
      },
    },
  } = useTranslation();

  const addDriversLicensePhoto = async () => {
    if (!image) {
      console.error("cannot submit without an image");
      return;
    }

    setSubmitting(true);

    const formData = new FormData();
    formData.append("photo", image);
    formData.append("dotFormId", `${formId}`);

    try {
      const result = await axiosClient.post(API_ENDPOINTS.dotReadDriverLicense, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (result.data.data.response.driver_license_number) {
        navigate(`/${permissions.patient}/dot-form/form/${formId}`);
      } else {
        setRetryCount((count) => count + 1);
        addError("There was an error trying to read your driver's license. Please try again.");
      }
    } catch (error) {
      setRetryCount((count) => count + 1);
      console.error(error);
      addError("There was an error trying to read your driver's license. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const reset = () => {
    // reset the retry count
    setRetryCount(0);
    setImage(null);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      wrap="nowrap"
      className={classes.root}
    >
      <Paper elevation={!isMobile ? 24 : 0} className={classes.paper}>
        {!started && (
          <>
            <Typography component="h1" variant="h2" className={classes.title}>
              {copy.pageTitle}
            </Typography>
            <InitialView onConfirm={() => setStarted(true)} />
          </>
        )}
        {!!started && !image && (
          <>
            <Typography component="h1" variant="h5">
              {copy.prompt}
            </Typography>
            <ImageSelect onNewFile={setImage} />
          </>
        )}
        {!!started && !!image && (
          <Grid container direction="column" alignItems="stretch" className={classes.gapContainer}>
            <Typography component="h1" variant="h5">
              {copy.upload}
            </Typography>
            <FileDisplay file={image} />
            <Typography variant="body1">{copy.confirmation}</Typography>
            <Grid container direction="row" alignItems="stretch" wrap="wrap" spacing={1}>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={reset}
                  disabled={submitting}
                  endIcon={<Clear />}
                >
                  {copy.retakeButton}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={addDriversLicensePhoto}
                  disabled={submitting}
                  endIcon={submitting ? <CircularProgress size={20} /> : <Check />}
                >
                  {copy.uploadButton}
                </Button>
              </Grid>
            </Grid>
            <Typography variant="body1" className={classes.faqLink}>
              <Link to={`/${permissions.patient}/dot-form/faq`}>{faqText}</Link>
            </Typography>
          </Grid>
        )}
        <Dialog
          open={retryCount > 1}
          aria-labelledby="help-dialog-title"
          style={{ padding: "10px" }}
        >
          <DialogContent>{copy.errorModalDescription}</DialogContent>
          <DialogActions style={{ justifyContent: "center", marginBottom: 10 }}>
            <Button
              onClick={reset}
              color="primary"
              variant="outlined"
              autoFocus
              style={{ width: 150 }}
            >
              {copy.cancelButton}
            </Button>
            <Button
              onClick={() => navigate(`/${permissions.patient}/dot-form/form/${formId}`)}
              color="primary"
              variant="contained"
              autoFocus
              style={{ width: 150 }}
            >
              {copy.continueButton}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Grid>
  );
};

export default DOTDriverLicenseReader;
