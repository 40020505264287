/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DateFnsAdapter from "@date-io/date-fns";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Button, Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers";
import UploadService from "components/FileUploadWidget/upload-files.service";
import UploadImages from "components/FileUploadWidget/upload-images.component";
import { CLEAR_IMAGES_LIST } from "components/Video/hooks/usePatientStepsReducer/Actions";
import CellPhone from "components/shared/CellPhone";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { getEmptyFields, normalizeZipCode } from "util/util_functions";
import useStyles from "./AddFamilyMember.styles";

const AddFamilyMember = () => {
  const app = useApp();
  const navigate = useNavigate();
  const dateFns = new DateFnsAdapter();
  const { classes } = useStyles();

  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [relationshipType, setRelationshipType] = useState("");

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    zipCode: "",
    relationshipType: "",
    dob: "",
    phone: "",
    emergencyContactName: "",
    emergencyContactNumber: "",
  });
  const [isDateError, setIsDateError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const url = window.location.href.split("family-member/");
    const operationUrl = url[1].split("/");
    const mode = operationUrl[0];

    if (operationUrl.length !== 1 && mode === "update") {
      setIsEdit(true);
      const userID = parseInt(operationUrl[1]);
      getUserAgainstID(userID);
    }
    // eslint-disable-next-line
  }, []);

  const getUserAgainstID = async (userID) => {
    try {
      const uri = "/api/s/get_user_by_id";
      const { data } = await axiosClient.get(uri, { params: { user_id: userID } });
      setId(data.data.id);
      setFirstName(data.data.first_name);
      setLastName(data.data.last_name);
      setZipCode(data.data.zip);
      setDob(data.data.dob ? new Date(data.data.dob) : new Date(), "yyyy-MM-dd");
      setGender(data.data?.gender?.toLowerCase());
      setPhone(data.data.cell_phone);
      setRelationshipType(data.data.relationship_type);
    } catch (error) {
      app.addError("Error: Failed to get user info. Please try again or contact support.");
      console.error(error);
    }
  };
  const handleFirstNameChange = (e) => {
    setErrors({
      ...errors,
      firstName: "",
    });
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setErrors({
      ...errors,
      lastName: "",
    });
    setLastName(e.target.value);
  };
  const handleZipChange = (e) => {
    setErrors({
      ...errors,
      zipCode: "",
    });
    setZipCode(normalizeZipCode(e.target.value));
  };
  const handleGenderChange = (e) => {
    setErrors({
      ...errors,
      gender: "",
    });
    setGender(e.target.value);
  };
  const handleRelationshipTypeChange = (e) => {
    setErrors({
      ...errors,
      relationshipType: "",
    });
    setRelationshipType(e.target.value);
  };
  const handleDobChange = (date) => {
    setErrors({
      ...errors,
      dob: "",
    });
    const d = new Date(date);
    const new_date = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    setDob(new_date);
  };
  const handlePhoneChange = (e) => {
    setErrors({
      ...errors,
      phone: "",
    });
    setPhone(e.target.value);
  };
  const uploadImage = async (user_id) => {
    const imagesList2 = app.patient_steps_state.imagesList;
    const { contextType } = app.patient_steps_state;
    const { contextID } = app.patient_steps_state;
    let uploadedImages = 0;

    for (let i = 0; i < imagesList2.length; i++) {
      const imageInfo = imagesList2[i];
      let imageToBeUpload = imageInfo.currentFile;
      const imageType = imageInfo.imageType ? imageInfo.imageType : "General";
      if (imageInfo.capturedImage) {
        imageToBeUpload = new File([imageInfo.currentFile], imageInfo.name, {
          type: "image/png",
        });
      }
      await UploadService.upload(imageToBeUpload, contextType, contextID, imageType, user_id)
        // eslint-disable-next-line
        .then((response) => {
          if (response.status === 200) {
            uploadedImages += 1;
          }
          console.log("file uploaded successfully.");
        })
        .catch((err) => {
          console.log("err=", err);
        });
    }
    if (imagesList2.length !== uploadedImages) {
      console.log("Some images not uploaded.");
      app.addError("Problem uploading images, please contact support.");
    } else {
      app.patient_steps_dispatch({ type: CLEAR_IMAGES_LIST, payload: null });
    }
  };
  const goBackToSelectionScreen = () => {
    setId("");
    setFirstName("");
    setLastName("");
    setZipCode("");
    setDob(dateFns.format(new Date(), "yyyy-MM-dd"));
    setGender("");
    setPhone("");
    setRelationshipType("");
    app.patient_steps_dispatch({ type: CLEAR_IMAGES_LIST, payload: null });
    navigate(`/patient-selection`);
  };
  const getPageTitle = () => {
    if (isEdit) {
      if (relationshipType === "Self") {
        return "Update Self info";
      }
      return "Update Family Member";
    }
    return "Add New Family Member";
  };
  const handleSave = async () => {
    const emptyFields = getEmptyFields({
      firstName,
      lastName,
      dob,
      zipCode,
      gender,
      relationshipType,
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors(emptyFields);

      if (phone && phone.length > 0 && phone.length < 10) {
        setErrors({
          ...errors,
          ...emptyFields,
          phone: "Enter a valid phone number",
        });
      }

      if (zipCode && zipCode.length !== 5) {
        setErrors({
          ...errors,
          ...emptyFields,
          zipCode: "Enter a valid zip code",
        });
      }

      if (gender && gender.length < 1) {
        setErrors({
          ...errors,
          ...emptyFields,
          gender: "Select a gender",
        });
      }

      if (relationshipType && relationshipType.length < 1) {
        setErrors({
          ...errors,
          ...emptyFields,
          relationshipType: "Select a relationship type",
        });
      }

      return;
    }

    if (isDateError) {
      return;
    }
    if (isEdit) {
      try {
        const updateUrl = "/api/s/update-family-member";
        const updatedPatient = {
          user_id: id,
          first_name: firstName,
          last_name: lastName,
          dob: dateFns.format(new Date(dob), "MM/dd/yyyy"),
          zip: zipCode,
          cell_phone: phone,
          gender,
          relationship_to_id: id,
          relationships_type: relationshipType,
        };
        await axiosClient.post(updateUrl, updatedPatient);
        try {
          await uploadImage(id);
        } catch (error) {
          app.addError("Error: Failed to upload image");
        }
        app.addInfoMsg("Record has been updated.");
        goBackToSelectionScreen();
      } catch (error) {
        console.log("ERROR ", error);
        app.addError(
          "Error: Failed to update family patient record. Please try again or contact support.",
        );
      }
    } else {
      try {
        const post_url = "/api/s/create-proxy-user";
        const newPatient = {
          firstName,
          lastName,
          dob: dateFns.format(new Date(dob), "MM/dd/yyyy"),
          zipCode,
          cellPhone: phone,
          gender,
          relationshipType,
        };
        const { data } = await axiosClient.post(post_url, newPatient);
        if (data && data?.userId) {
          try {
            await uploadImage(data.userId);
          } catch (error) {
            app.addError("Error: Failed to upload image");
          }
          app.addInfoMsg("New Family Patient Record has been created successfully.");
          goBackToSelectionScreen();
        } else {
          app.addError(
            "Error: Failed to create new family patient record. Please try again or contact support.",
          );
          console.error(response);
        }
      } catch {
        app.addError(
          "Error: Failed to create new family patient record. Please try again or contact support.",
        );
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>{getPageTitle()}</div>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            InputProps={{
              className: classes.inputRoot,
            }}
            error={!!errors.firstName}
            helperText={errors.firstName}
            value={firstName || ""}
            onChange={handleFirstNameChange}
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            variant="outlined"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputProps={{
              className: classes.inputRoot,
            }}
            error={!!errors.lastName}
            helperText={errors.lastName}
            value={lastName || ""}
            onChange={handleLastNameChange}
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            variant="outlined"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            format="MM/dd/yyyy"
            margin="normal"
            label="Date of Birth"
            value={dob}
            onChange={handleDobChange}
            maxDate={new Date().setDate(new Date().getDate() - 1)}
            onError={(error) => {
              setIsDateError(!!error);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                helperText: "MM/DD/YYYY",
                id: "family-member-dob",
              },
              openPickerButton: {
                "aria-label": "change date",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputProps={{
              className: classes.inputRoot,
            }}
            value={zipCode || ""}
            inputProps={{
              maxLength: 30,
            }}
            error={!!errors.zipCode}
            helperText={errors.zipCode}
            onChange={handleZipChange}
            margin="dense"
            id="zipCode"
            label="Zip Code"
            type="text"
            variant="outlined"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="gender"
            label="Sex at Birth"
            select
            value={gender || ""}
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.gender}
            helperText={errors.gender}
            required
            fullWidth
            onChange={handleGenderChange}
            InputProps={{
              className: classes.inputRoot,
            }}
          >
            <MenuItem key="male" value="male">
              Male
            </MenuItem>
            <MenuItem key="female" value="female">
              Female
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="relationshipType"
            label="Relationship Type"
            select
            value={relationshipType}
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              className: classes.inputRoot,
            }}
            error={!!errors.relationshipType}
            helperText={errors.relationshipType}
            required
            fullWidth
            onChange={handleRelationshipTypeChange}
            disabled={relationshipType === "Self"}
          >
            <MenuItem key="Daughter" value="Daughter">
              Daughter
            </MenuItem>
            <MenuItem key="Son" value="Son">
              Son
            </MenuItem>
            <MenuItem key="Father" value="Father">
              Father
            </MenuItem>
            <MenuItem key="Mother" value="Mother">
              Mother
            </MenuItem>
            <MenuItem key="Spouse" value="Spouse">
              Spouse
            </MenuItem>
            <MenuItem key="GrandFather" value="GrandFather">
              GrandFather
            </MenuItem>
            <MenuItem key="GrandMother" value="GrandMother">
              GrandMother
            </MenuItem>
            <MenuItem key="Uncle" value="Uncle">
              Uncle
            </MenuItem>
            <MenuItem key="Aunt" value="Aunt">
              Aunt
            </MenuItem>
            <MenuItem key="Cousin" value="Cousin">
              Cousin
            </MenuItem>
            <MenuItem key="Legal Guardian" value="Legal Guardian">
              Legal Guardian
            </MenuItem>
            <MenuItem key="Other" value="Other">
              Other
            </MenuItem>
            {relationshipType === "Self" && (
              <MenuItem key="Self" value="Self">
                Self
              </MenuItem>
            )}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <CellPhone
            setCellPhone={setPhone}
            cellPhone={phone}
            error={Boolean(errors.phone)}
            helperText={errors.phone}
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
        >
          <UploadImages
            contextType="AddFamilyMember"
            contextID={id}
            upload_msg="Take a picture of your symptoms"
            imageType="Profile Picture"
            allowMultipleFiles={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Button className={classes.btn} color="primary" variant="contained" onClick={handleSave}>
            {getPageTitle()}
            <PersonAddIcon fontSize="small" style={{ marginLeft: 10 }} />
          </Button>
          <br />
          <Button
            className={classes.btn}
            color="secondary"
            variant="contained"
            onClick={goBackToSelectionScreen}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddFamilyMember;
