import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CellPhone from "components/shared/CellPhone";
import { Features } from "util/constants/config";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { hasFeature } from "util/hasFeature";

const useStyles = makeStyles()((theme) => ({
  paper: {
    boxSizing: "border-box",
    maxWidth: "640px",
    width: "100%",
    padding: "32px 40px",

    [theme.breakpoints.down("md")]: {
      padding: "28px 22px",
      maxWidth: "560px",
    },
  },
  title: {
    margin: 0,
    marginBottom: "8px",
    fontSize: "32px",
    fontFamily: "unset",
    lineHeight: "100%",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
  },
  subtitle: {
    margin: 0,
    marginBottom: "24px",
    fontSize: "16px",
    color: theme.palette.primary.main,
  },
  textMessageLoginButton: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    fontWeight: 600,
    marginTop: "24px",
  },
  label: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Alegreya Sans",
    fontSize: "16px",
    color: theme.palette.common.black,
    maxWidth: "calc(100% - 20px)",
  },
  textDisclaimer: {
    margin: "1em 0 .5em 0",
    padding: "0 1em",
  },
}));

const EndocrinologySurveysVerifyPhone = () => {
  const [cellPhone, setCellPhone] = useState("");
  const [loadCaptcha, setLoadCaptcha] = useState("");
  const [submitCaptcha, setSubmitCaptcha] = useState("");
  const isCaptchaEnabled = hasFeature(Features.ENABLE_CAPTCHA);
  const [inValidCaptcha, setInvalidCaptcha] = useState(isCaptchaEnabled);
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    web: { endocrinologySurveys },
  } = useTranslation();

  const app = useApp();
  const captchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY;
  const isCellPhoneInputDisabled = isCaptchaEnabled ? inValidCaptcha || !submitCaptcha : false;
  const isSubmitButtonDisabled = isCaptchaEnabled
    ? cellPhone === "" || inValidCaptcha
    : cellPhone === "";

  const removeRecapatchaScript = () => {
    const recaptchaScriptTag = document.getElementById("recaptchaScript");
    if (recaptchaScriptTag) {
      document.body.removeChild(recaptchaScriptTag);
    }
  };

  const getReCaptchaToken = (action) => {
    if (window.grecaptcha) {
      const {
        grecaptcha: { enterprise },
      } = window;
      enterprise.ready(() => {
        enterprise.execute(captchaKey, { action: "pedsendosurvey" }).then((token) => {
          if (action === "load") {
            setLoadCaptcha(token);
          } else if (action === "submit") {
            setSubmitCaptcha(token);
          }
        });
      });
    }
  };

  const validateCaptcha = async (token) => {
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.validateCaptcha, {
        captcha: token,
        action: "pedsendosurvey",
      });
      if (!data.success) {
        setInvalidCaptcha(false);
        getReCaptchaToken("submit"); // re-validate captcha on submit
      }
    } catch (error) {
      app.addError("Oops, an unexpected error occurred validating your humanity!");
    }
  };

  useEffect(() => {
    if (location.pathname !== "/endocrinology/phone-consent") {
      removeRecapatchaScript();
      window.reCaptchaInitialized = false;
    }
  }, [location]);

  useEffect(() => {
    if (!window.reCaptchaInitialized && captchaKey && isCaptchaEnabled) {
      const scriptTag = document.createElement("script");
      scriptTag.id = "recaptchaScript";
      scriptTag.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaKey}`;
      scriptTag.async = false;
      document.body.appendChild(scriptTag);
      scriptTag.onload = () => {
        getReCaptchaToken("load");
      };
      window.reCaptchaInitialized = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptchaEnabled]);

  useEffect(() => {
    if (inValidCaptcha && loadCaptcha && isCaptchaEnabled) {
      validateCaptcha(loadCaptcha);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inValidCaptcha, loadCaptcha, isCaptchaEnabled]);

  const handleConfirmPhoneNumber = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.pediatricEocSurvey, {
        cellphone: cellPhone,
        captcha: submitCaptcha,
        action: "pedsendosurvey",
      });
      navigate(`/endocrinology/f/${data.form_submission}/s/${data.hash}`);
    } catch (error) {
      app.addError("Oops, an unexpected error has occurred!");
    }
  };

  const componentStyle = {
    marginTop: "30px",
  };
  return (
    <Grid container alignItems="center" justifyContent="center" style={componentStyle}>
      {inValidCaptcha ? (
        <div data-testid="checking-recaptcha">
          <Typography variant="h1" className={classes.title} data-testid="phone-verification-title">
            Please wait while we check you are not a robot...
          </Typography>
        </div>
      ) : (
        <Paper elevation={!isMobile ? 24 : 0} className={classes.paper}>
          <Typography variant="h1" className={classes.title} data-testid="phone-verification-title">
            {endocrinologySurveys.phoneVerificationTitle}
          </Typography>
          <form onSubmit={handleConfirmPhoneNumber}>
            <p className={classes.subtitle}>Confirm Patient Phone</p>
            <div data-testid="phone-verification-input-container">
              <CellPhone
                cellPhone={cellPhone}
                setCellPhone={(value) => setCellPhone(value)}
                disabled={isCellPhoneInputDisabled}
              />
            </div>
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              className={classes.textMessageLoginButton}
              data-testid="phone-verification_phone-verify"
              disabled={isSubmitButtonDisabled}
              fullWidth
            >
              Confirm Phone
            </Button>
          </form>
          <Typography color="primary" className={classes.textDisclaimer}>
            {endocrinologySurveys.phoneVerificationDisclaimer}
          </Typography>
        </Paper>
      )}
    </Grid>
  );
};

export default EndocrinologySurveysVerifyPhone;
