import React, { useEffect, useState } from "react";
import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import { useGetConditionsAndSymptoms } from "hooks/dataCuration/useGetConditionsAndSymptoms";
import useTranslation from "hooks/useTranslation";
import { TabPaneProps } from "../../types";

const ConditionsAndSymptomsSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}: TabPaneProps) => {
  const {
    web: {
      dataCuration: { providerProfile },
    },
  } = useTranslation();

  const {
    clinical: { conditionsAndSymptoms },
  } = providerProfile;

  const {
    providerClinical: {
      clinical: { conditionsSymptoms },
    },
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const { conditionsAndSymptomsList } = useGetConditionsAndSymptoms();

  const [autocompleteValue, setAutocompleteValue] = useState(conditionsSymptoms || []);

  const handleChangeConditions = (event: React.SyntheticEvent, value) => {
    const currentValue = autocompleteValue || [];

    const removedItems = currentValue.filter(
      (item) => !value.some((newItem) => newItem.id === item.id),
    );
    const addedItems = value.filter(
      (item) => !currentValue.some((oldItem) => oldItem.id === item.id),
    );

    setAutocompleteValue(value);

    if (removedItems.length > 0) {
      setCurrentPayload((prev) => ({
        ...(prev || {}),
        conditionsSymptoms: [
          ...(prev?.conditionsSymptoms || []),
          ...removedItems.map((item) => ({ ...item, deleted: true })),
        ],
      }));
    }

    if (addedItems.length > 0) {
      setCurrentPayload((prev) => ({
        ...(prev || {}),
        conditionsSymptoms: [
          ...(prev?.conditionsSymptoms || []).filter((item) => !item.delete),
          ...addedItems,
        ],
      }));
    }
  };

  useEffect(() => {
    if (currentPayload === null) {
      setAutocompleteValue(conditionsSymptoms || []);
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      paddingY="16px"
      data-testid="conditions-and-symptoms-section"
    >
      <Grid item xs={12} md={12} style={{ marginTop: "16px" }}>
        <div style={{ textAlign: "start" }}>
          <h6 style={{ color: "gray" }}>{conditionsAndSymptoms.subtitle}</h6>
          <h6 style={{ color: "gray" }}>{conditionsAndSymptoms.subtitle2}</h6>
        </div>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            disablePortal
            id="conditions-and-symptoms-autocomplete"
            data-testid="conditions-and-symptoms-autocomplete"
            disabled={shouldDisableForm}
            options={conditionsAndSymptomsList || []}
            value={autocompleteValue || []}
            onChange={(event, newValue) => handleChangeConditions(event, newValue)}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => <TextField {...params} label="Search Conditions & Symptoms" />}
            style={{ margin: "24px 0 16px 0", maxWidth: "600px" }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ConditionsAndSymptomsSection;
