import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { Close, PhotoCamera } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { SkeletonSpecimenPhoto } from "../skeletonSpecimenPhoto";
import { FacingModesTypes } from "components/WebcamPhoto/types";
import { useAddSpecimenPhoto } from "hooks/specimenTracking/useAddSpecimenPhoto/useAddSpecimenPhoto";
import useTranslation from "hooks/useTranslation";
import { capturePhoto } from "../../views/utils";
import { useApp } from "util/AppContext";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";

const videoConstraints = {
  facingMode: FacingModesTypes.ENVIRONMENT,
};

const SpecimenPhotoModal = ({
  open,
  onClose,
  specimenDetail,
  replacePhoto,
  updateSpecimenImage,
}) => {
  const app = useApp();

  const { classes } = useSpecimenStyles();
  const {
    web: {
      specimenTracking: { specimenPhoto },
    },
  } = useTranslation();

  const { captureSpecimenPhoto } = useAddSpecimenPhoto();
  const webcamRef = useRef<Webcam>(null);
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);

  const addSpecimenPhoto = async (image: File) => {
    const formData = new FormData();
    formData.append("photo", image);

    try {
      const { data } = await captureSpecimenPhoto(specimenDetail.id, formData);
      if (data?.data) {
        const updatedImage = data.data.specimenImage;

        updateSpecimenImage(updatedImage, specimenDetail.specimenExternalId);

        onClose();
        app.addInfoMsg("Specimen image added successfully.");
      } else {
        app.addError(data?.error);
      }
    } catch (e) {
      app.addError(`Could not read Specimen image. Please, try again.`);
    } finally {
      setLoadingPhoto(false);
    }
  };

  return (
    <div>
      {open && (
        <div className={classes.scannedDetailBackdrop} style={{ paddingTop: "16px" }}>
          <Box className={classes.scannedDetailModal} style={{ gap: 0 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography variant="h5" style={{ fontWeight: "800" }}>
                {specimenPhoto}
              </Typography>
              <IconButton
                onClick={onClose}
                style={{ padding: 0 }}
                data-testid="closeIcon"
                data-cy="closeIconBtn"
                size="large"
              >
                <Close />
              </IconButton>
            </Box>

            <Typography variant="h6" style={{ fontWeight: "800", marginRight: "auto" }}>
              {specimenDetail.specimenExternalId}
            </Typography>

            {!specimenDetail.specimenImage || replacePhoto ? (
              <div
                style={{
                  height: "320px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "16px 0",
                }}
              >
                {loadingPhoto ? (
                  <CircularProgress style={{ margin: "auto" }} />
                ) : (
                  <Box style={{ position: "relative" }}>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      height={320}
                      width="100%"
                      videoConstraints={videoConstraints}
                      screenshotFormat="image/png"
                      style={{ objectFit: "cover", margin: "16px 0" }}
                      screenshotQuality={1}
                    />
                    <SkeletonSpecimenPhoto />
                  </Box>
                )}
              </div>
            ) : (
              <img
                src={specimenDetail.specimenImage}
                alt="Please reload."
                style={{ maxWidth: "100%", margin: "32px 0" }}
                data-testid="specimenImage"
              />
            )}

            {!specimenDetail.specimenImage || replacePhoto ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<PhotoCamera />}
                style={{ width: "100%" }}
                // @ts-ignore-next-line
                onClick={(e) => capturePhoto(e, addSpecimenPhoto, setLoadingPhoto, webcamRef)}
              >
                Capture
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ width: "100%" }}
                onClick={onClose}
              >
                OK
              </Button>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

export default SpecimenPhotoModal;
