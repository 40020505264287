import React from "react";
import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import EnhancedTableColDefs from "./EnhancedTableColDefs";
import { useStyles } from "../styles";

interface WithId {
  label?: string;
  id: number;
}

interface Props<TDataType extends WithId> {
  data: TDataType[];
  open: boolean;
  collapseTableColDefs?: TDataType[];
  rowToId: (row) => number;
}

export function EnhacedTableCollapsed<TDataType extends WithId>({
  data,
  open,
  collapseTableColDefs,
  rowToId,
}: Readonly<Props<TDataType>>) {
  // @ts-ignore-next-line
  const { classes } = useStyles();

  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={0}>
            <Table size="small">
              <TableHead style={{ backgroundColor: "#ffb74d" }}>
                <TableRow>
                  <TableCell style={{ width: "5em" }} />
                  {collapseTableColDefs?.map((colDef, index) =>
                    index > 0 ? (
                      <TableCell key={colDef.label} style={{ fontSize: "12px" }}>
                        {colDef.label}
                      </TableCell>
                    ) : (
                      <TableCell key={colDef.label} style={{ fontSize: "12px" }} />
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  collapseTableColDefs &&
                  data.map((row) => {
                    return (
                      <TableRow key={rowToId(row)} className={classes.tableCellCollapse}>
                        <TableCell style={{ width: "5em" }} />
                        <EnhancedTableColDefs
                          row={row}
                          colDefs={collapseTableColDefs}
                          rowToId={rowToId}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
