import { cloneDeepWith, isPlainObject } from "lodash";
import { getISODay } from "date-fns";

enum WeekDays {
  "monday" = 0,
  "tuesday" = 1,
  "wednesday" = 2,
  "thursday" = 3,
  "friday" = 4,
}

export const convertDateArrayToWorkDays = (dates: Date[]) => {
  const arr: number[] = [];

  dates.forEach((day) => {
    const isoDay = getISODay(day) - 1;
    if (isoDay <= 4) {
      arr.push(isoDay);
    }
  });

  const newArr = arr.slice(0, 5);
  const sortArr = newArr.sort((a, b) => a - b);
  return sortArr.map((num) => WeekDays[num]);
};

export const getDateTimeFromTimeString = (str: string) => {
  const times = str.split(":");
  times.map((time) => {
    return time?.length ? time : 0;
  });
  return new Date(0, 0, 0, Number(times[0]), Number(times[1]));
};

export const getStrTimeFromDate = (date: Date) => {
  return date.getHours() > 9
    ? `${date.getHours()}:${date.getMinutes() === 0 ? "00" : date.getMinutes()}:00`
    : `0${date.getHours()}:${date.getMinutes() === 0 ? "00" : date.getMinutes()}:00`;
};

export const convertAllObjectStringToDate = (obj) =>
  cloneDeepWith(obj, (value) =>
    !isPlainObject(value) ? getDateTimeFromTimeString(value) : undefined,
  );

export const convertAllObjectDateToString = (obj) =>
  cloneDeepWith(obj, (value) => (!isPlainObject(value) ? getStrTimeFromDate(value) : undefined));
