import { Program } from "components/Video/utils/types";

export type TStaffUser = {
  first_name: string;
  last_name: string;
  id: number;
  email: string;
};

export type TVirtualSession = {
  id: number;
  type: string;
  patient_reason: string;
  start_dttm?: string;
  requested_start_beg: string;
  requested_start_end: string;
};

export type TMessage = {
  id: number;
  message: string;
  event_dttm: Date;
};
export type TConversation = {
  program: Program;
  vs: TVirtualSession;
  staff_user: TStaffUser;
  vs_message: TMessage;
};
export interface ConversationListProps {
  conversations: TConversation[];
}

export const MessageFilters = {
  ACTIVE: "Active",
  CLOSED: "Closed",
};

export const MessageFiltersArray = ["Active", "Closed"];
export interface FiltersProps {
  filter: string;
  handleChangeFilter: (filter: string) => void;
}

export interface IConversationListItemProps {
  lastMessage: string;
  listItemTitle: string;
  sessionId: number;
  icon: string;
  timeStr: string;
  program: Program;
  canBeRescheduled: boolean;
}

export interface IMessageProps {
  sentByString: string;
  message: string;
}
