import React from "react";
import { useNavigate } from "react-router-dom";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
import { UseGetAvailableCards } from "./GetAvailableCards";
import ProgramSelection from "./ProgramSelection";
import { makeStyles } from "tss-react/mui";
import { useAuth } from "../../../util/Security";
import { useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()({
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
});

export default function ConsoleNav({ toggleMenuDrawer, setCurrentDepartmentName }) {
  const { classes } = useStyles();
  const auth = useAuth();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { availableCards } = UseGetAvailableCards();

  return (
    <div className={classes.list} role="presentation">
      {isMobile && auth?.user && (
        <>
          <ProgramSelection
            toggleMenuDrawer={toggleMenuDrawer}
            setCurrentDepartmentName={setCurrentDepartmentName}
          />
          <Divider />
        </>
      )}
      <List onClick={toggleMenuDrawer(false)} onKeyDown={toggleMenuDrawer(false)}>
        {availableCards.map(({ key, global, text, link }) => (
          <ListItem button key={key}>
            <ListItemText
              onClick={() => (global === true ? window.open(link) : navigate(link))}
              primary={key === "Menu" ? "Menu" : null}
            >
              {text}
            </ListItemText>
          </ListItem>
        ))}
        <Divider />
      </List>
    </div>
  );
}
