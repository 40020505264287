import React from "react";
import moment from "moment";
import { Paper } from "@mui/material";
import { useTimeLineStyles } from "./styles";

const TimeLineCard = ({ lot, date_administered, title }) => {
  const { classes } = useTimeLineStyles();
  const timeToDisplay = moment(date_administered).format("DD MMM YYYY");

  return (
    <Paper elevation={1} className={classes.paper}>
      <span className={classes.title}>{title}</span>
      <div>
        <small>
          <span className={classes.subtitle}> Date Administered: </span>
          <span className={classes.subText}>{timeToDisplay}</span>
        </small>
        {", "}
        <small>
          <span className={classes.subtitle}> Lot: </span>
          <span className={classes.subText}>{lot}</span>
        </small>{" "}
      </div>
    </Paper>
  );
};

export default TimeLineCard;
