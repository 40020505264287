import { useQueryClient } from "react-query";
import { API_ENDPOINTS, AXIOS_OPTIONS, axiosClient, getApiHost } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

interface Department {
  address: string;
  dept_type: string;
  id: number;
  lat: number | null;
  lng: number | null;
  name: string;
  phone_number: string;
  short_name: string;
}

interface DepartmentsResponse {
  data: Department[];
}

export const useGetDepartments = () => {
  const queryClient = useQueryClient();

  const fetchQuery = async (programId: string): Promise<DepartmentsResponse> => {
    const apiHost = getApiHost();
    const url = `${apiHost}${API_ENDPOINTS.get_programs_list}/${programId}/departments`;
    const response = await axiosClient.get(url, AXIOS_OPTIONS);
    return response.data;
  };

  const getDepartmentsList = async (
    newProgramId: string,
  ): Promise<DepartmentsResponse | undefined> => {
    const cachedData = queryClient.getQueryData<DepartmentsResponse>([
      QUERY_KEYS.department,
      newProgramId,
    ]);

    if (cachedData) {
      return cachedData;
    }

    try {
      const result = await queryClient.fetchQuery<DepartmentsResponse>(
        [QUERY_KEYS.department, newProgramId],
        () => fetchQuery(newProgramId),
      );
      return result;
    } catch {
      return undefined;
    }
  };

  return {
    getDepartmentsList,
  };
};
