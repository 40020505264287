import React, { SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { NewLocationSchema } from "./NewLocationSchema";
import { makeStyles } from "tss-react/mui";
import LocationEnabler from "views/DataCuration/common/LocationEnabler";
import CellPhone from "components/shared/CellPhone";
import InputErrorMsg from "components/shared/InputErrorMsg/InputErrorMsg";
import ZipCode from "components/shared/ZipCode/ZipCode";
import { useGetLocationTypes } from "hooks/dataCuration/useGetLocationTypes";
import {
  ParentLocation,
  useSearchParentLocations,
} from "hooks/dataCuration/useSearchParentLocations";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { NewLocationType } from "./types";
import useStyles from "views/DOTForm/styles/forms.styles";

const useInternalStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  titlePaddings: {
    margin: "10px 0px 10px 10px",
  },
  radioSubtitle: { paddingLeft: "2rem", color: "gray", fontSize: 12 },
}));

const FormInput = ({
  formKey,
  label,
  required,
  dataCy,
  testId,
  values,
  handleChange,
  touched,
  errors,
  setFieldValue,
  helperText = "",
}) => {
  return (
    <Grid item xs={12} md={12}>
      <FormControl variant="outlined" fullWidth>
        {formKey === "phone" ? (
          <CellPhone
            label={label}
            required={required}
            cellPhone={values.phone}
            setCellPhone={(cellPhone) => setFieldValue("phone", cellPhone)}
            error={touched[formKey] && required && errors?.[formKey]}
            isShrink
            showMask={false}
            showRequiredInLabel
            data-testid={testId}
            helperText={helperText}
          />
        ) : formKey === "zip" ? (
          <>
            <ZipCode
              setZip={(zip) => {
                setFieldValue("zip", zip);
              }}
              zip={values.zip}
              isError={!!(touched.zip && required && errors?.zip)}
              data-testid={testId}
              data-cy={dataCy}
              label={label}
              required={required}
            />
            {helperText && <Typography variant="caption">{helperText}</Typography>}
          </>
        ) : (
          <TextField
            id={formKey}
            name={formKey}
            size="medium"
            value={values[formKey]}
            variant="outlined"
            label={label}
            onChange={(e) => handleChange(e)}
            data-cy={dataCy}
            data-testid={testId}
            required={required}
            error={touched[formKey] && required && errors?.[formKey]}
            helperText={helperText}
          />
        )}

        {required && touched[formKey] && <InputErrorMsg error={errors?.[formKey]} />}
      </FormControl>
    </Grid>
  );
};

const NewLocation: React.FC = () => {
  const { classes: internalClasses } = useInternalStyles();
  const { classes } = useStyles({ alignLeft: true });
  const app = useApp();
  const navigate = useNavigate();
  const { locationTypesList } = useGetLocationTypes();
  const { data: parentLocations = [], isLoading, isError, error } = useSearchParentLocations();

  const {
    web: {
      dataCuration: {
        location: {
          newLocation: { title, subtitle, nameSection, addressSection, cancelBtn, saveLocationBtn },
          generalInfo: {
            nameSection: {
              parentLocationLabel,
              locationTypeTitle,
              locationName,
              locationPageTitle,
              locationPageName,
              phoneNumber,
            },
            addressSection: { address1Label, address2Label, cityLabel, stateLabel, zipLabel },
          },
        },
      },
    },
  } = useTranslation();

  const nameFormElements = [
    {
      label: locationName.title,
      key: "name",
      helperText: locationName.helperText,
      required: true,
      error: false,
    },
    {
      label: locationPageTitle.title,
      key: "pageTitle",
      helperText: locationPageTitle.helperText,
      required: true,
      error: false,
    },
    {
      label: locationPageName.title,
      key: "pageName",
      helperText: locationPageName.helperText,
      required: false,
      error: false,
    },
  ];

  const addressFormElements = [
    {
      label: address1Label,
      key: "streetAddress1",
      required: true,
      error: false,
    },
    {
      label: address2Label,
      key: "streetAddress2",
      required: false,
      error: false,
    },
    {
      label: cityLabel,
      key: "city",
      required: true,
      error: false,
    },
    {
      label: stateLabel,
      key: "state",
      required: true,
      error: false,
    },
    {
      label: zipLabel,
      key: "zip",
      required: true,
      error: false,
    },
    {
      label: phoneNumber.title,
      key: "phone",
      required: true,
      error: false,
    },
  ];

  const createNewLocation = async (newLocationFormValues: any) => {
    const {
      name,
      pageTitle,
      pageName,
      locationType,
      parentLocation,
      streetAddress1,
      streetAddress2,
      city,
      state,
      zip,
      phone,
      enabledOnLocationFinder,
      enabledAsPracticeLocationProvider,
    } = newLocationFormValues;
    try {
      const payload: NewLocationType = {
        name,
        nameFull: pageTitle,
        type: locationType,
        nameShort: pageName,
        parentLocation: parentLocation?.id,
        streetAddress1,
        streetAddress2,
        city,
        state,
        zip,
        phone,
        locationFinder: enabledOnLocationFinder,
        providerFinder: enabledAsPracticeLocationProvider,
      };

      await axiosClient.post(API_ENDPOINTS.dataCurationLocations, payload);
      app.addInfoMsg("Location created successfully");
      navigate("/console/data-curation/?selectedTab=1");
    } catch (error) {
      app.addError("Error creating new location");
    }
  };

  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      name: "",
      pageTitle: "",
      pageName: "",
      parentLocation: null,
      locationType: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      enabledOnLocationFinder: false,
      enabledAsPracticeLocationProvider: false,
    },
    validationSchema: NewLocationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      createNewLocation(values);
    },
  });

  const handleLocationTypeChange = (e: SelectChangeEvent<string>) => {
    const newType = e.target.value;
    setFieldValue("locationType", newType);
  };

  const handleParentLocationChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: ParentLocation | null,
  ) => {
    setFieldValue("parentLocation", newValue);
  };

  return (
    <Grid container className={internalClasses.root}>
      <div
        id="new-location"
        style={{ height: "100%", overflowY: "auto", width: "100%", textAlign: "left" }}
      >
        <div className={internalClasses.titlePaddings}>
          <Typography variant="h5" fontWeight="bold">
            {title}
          </Typography>
          <Divider />
        </div>
        <div className={internalClasses.titlePaddings}>
          <span>{subtitle}</span>
        </div>
        <form onSubmit={handleSubmit} noValidate>
          {/* Name Section */}
          <div style={{ display: "flex" }}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.personalInformation_wrapper}
              data-testid="New-Location-NameSection"
            >
              <FormControl variant="outlined" fullWidth className={internalClasses.titlePaddings}>
                <Typography variant="h5">{nameSection}</Typography>
                <Divider />
              </FormControl>
              {nameFormElements.map(({ label, key, required, helperText }, index) => (
                <FormInput
                  key={key + index.toString()}
                  formKey={key}
                  label={label}
                  required={required}
                  dataCy={`New-Location-NameSection-${key}Input`}
                  testId={`New-Location-NameSection-${key}Input`}
                  values={values}
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  helperText={helperText}
                />
              ))}
              <Grid item xs={12} md={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "24px" }}
                  error={isError}
                >
                  <Autocomplete
                    id="parentLocation"
                    options={parentLocations}
                    getOptionLabel={(option) => option?.nameFull || ""}
                    renderInput={(params) => (
                      <TextField {...params} label={error || parentLocationLabel} />
                    )}
                    onChange={handleParentLocationChange}
                    loading={isLoading}
                    value={values?.parentLocation || null}
                    clearOnEscape
                    data-cy="New-Location-parentLocationInput"
                    data-testid="New-Location-parentLocationInput"
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="LocationType-label">{locationTypeTitle}</InputLabel>
                  <Select
                    labelId="locationType-label"
                    id="locationType"
                    name="locationType"
                    aria-labelledby={locationTypeTitle}
                    value={values?.locationType || ""}
                    label={locationTypeTitle}
                    style={{ textAlign: "start" }}
                    onChange={handleLocationTypeChange}
                    error={touched?.locationType && !!errors?.locationType}
                    inputProps={{ "data-testid": "New-Location-location-type" }}
                  >
                    {locationTypesList?.map((type, index) => (
                      <MenuItem value={type} key={type + index.toString()}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.locationType && touched?.locationType && (
                    <InputErrorMsg error={errors?.locationType} />
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </div>
          {/* Address Section */}
          <div style={{ display: "flex", marginLeft: "auto" }}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.personalInformation_wrapper}
              data-testid="New-Location-AddressSection"
            >
              <FormControl variant="outlined" fullWidth className={internalClasses.titlePaddings}>
                <Typography variant="h5">{addressSection}</Typography>
                <Divider />
              </FormControl>
              {addressFormElements.map(({ label, key, required }, index) => (
                <FormInput
                  formKey={key}
                  key={key + index.toString()}
                  label={label}
                  required={required}
                  dataCy={`New-Location-AddressSection-${key}Input`}
                  testId={`New-Location-AddressSection-${key}Input`}
                  values={values}
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              ))}
            </Grid>
          </div>

          <LocationEnabler values={values} setFieldValue={setFieldValue} />
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            alignItems="center"
            flexDirection="row"
            style={{ padding: "16px" }}
          >
            <Grid item xs={12} sm={6} style={{ paddingRight: "16px" }}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                type="button"
                data-testid="button-cancel"
                onClick={() => navigate("/console/data-curation?selectedTab=1")}
              >
                {cancelBtn}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
                data-testid="button-submit"
              >
                {saveLocationBtn}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default NewLocation;
