import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { QuerySteps } from "../../PreJoinScreens/Steps";
import { makeStyles } from "tss-react/mui";
import useCaptionsContext from "components/Video/hooks/useCaptionsContext/useCaptionsContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { CONNECT_VIRTUAL_SESSION } from "../../../hooks/useVirtualVisitReducer/Actions";
import { useApp } from "../../../../../util/AppContext";

const useStyles = makeStyles()((theme) => ({
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: "#600101",
    },
  },
}));

export default function EndCallButton({ className }: { className?: string }) {
  const { onDisconnect } = useCaptionsContext();
  const { classes, cx } = useStyles();
  const app = useApp();
  const { room, removeLocalVideoTrack, removeLocalAudioTrack } = useVideoContext();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const { virtual_session_id: vsId } = useParams<{ virtual_session_id: string }>();

  return (
    <Button
      onClick={() => {
        onDisconnect();
        room?.disconnect();
        removeLocalVideoTrack();
        removeLocalAudioTrack();
        app.setVideoFinished(true);
        app.dispatch({
          type: CONNECT_VIRTUAL_SESSION,
          payload: null,
        });
        params.set("step", QuerySteps.PREVIEW);
        navigate({ pathname: `/video/${vsId}`, search: params.toString() });
      }}
      className={cx(classes.button, className)}
      data-cy-disconnect
      data-cy="EndCallButton"
    >
      Disconnect
    </Button>
  );
}
