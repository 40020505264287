import { useEffect, useState } from "react";
import useVideoContext from "components/Video/hooks/useVideoContext/useVideoContext";

export const useCheckPermissions = () => {
  const { getLocalAudioTrack, getLocalVideoTrack } = useVideoContext();

  const [audioError, setAudioError] = useState<string>("");
  const [videoError, setVideoError] = useState<string>("");
  const [mediaError, setMediaError] = useState<Error>();

  const checkVideoPermissions = async () => {
    try {
      await getLocalVideoTrack();
      if (videoError) {
        setVideoError("");
      }
    } catch (e) {
      setVideoError("Video permission denied.");
      setMediaError(e as Error);
    }
  };

  const checkAudioPermissions = async () => {
    try {
      await getLocalAudioTrack();
      if (audioError) {
        setAudioError("");
      }
    } catch (e) {
      setAudioError("Audio permission denied.");
      setMediaError(e as Error);
    }
  };

  useEffect(() => {
    checkVideoPermissions();
    checkAudioPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    audioError,
    videoError,
    mediaError,
  };
};
