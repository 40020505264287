import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Theme from "Theme";
import CallIcon from "@mui/icons-material/Call";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DateRangeIcon from "@mui/icons-material/DateRange";
import WcIcon from "@mui/icons-material/Wc";
import { Avatar, Box, CircularProgress, List, ListItem, ListItemAvatar } from "@mui/material";
import AddPatient from "./AddPatient";
import SearchHeader from "./SearchHeader";
import SinglePatient from "./SinglePatient";
import { usePatientListStyles } from "./styles";

const PatientList = ({
  patients,
  showForm,
  onConfirmMatchButtonClicked,
  ConfirmBtnTitle = "Confirm Match",
  isConfirmLoading = false,
}) => {
  const { classes } = usePatientListStyles();
  const { pathname } = useLocation();

  if (isConfirmLoading) {
    return (
      <Box margin="40px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Routes>
      <Route
        path={pathname}
        element={
          <div>
            <SearchHeader records={patients} />
            <List className={classes.root}>
              {patients.map((p) => (
                <div key={p.firstName + p.lastName} className={classes.itemContainer}>
                  <ListItem alignItems="flex-start" key={p.firstName + p.lastName + p.gender}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        className={classes.large}
                      />
                    </ListItemAvatar>
                    <div>
                      <div className={classes.name}>{`${p.firstName} ${p.lastName}`}</div>
                      <div className={classes.inline}>
                        {p.dob ? (
                          <>
                            <span>
                              <DateRangeIcon className={classes.listIcon} />
                            </span>
                            <span>Born {p.dob} </span>
                          </>
                        ) : (
                          ""
                        )}
                        {p.gender ? (
                          <>
                            <span>
                              <WcIcon className={classes.listIcon} />
                            </span>
                            <span>{p.gender}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {p.address ? (
                          <>
                            <span>
                              <ContactMailIcon
                                className={classes.listIcon}
                                style={{
                                  left: 2,
                                }}
                              />
                            </span>
                            <span>{p.address}</span>
                          </>
                        ) : (
                          ""
                        )}
                        {p.cellPhone ? (
                          <>
                            <span>
                              <CallIcon
                                className={classes.listIcon}
                                style={{ marginLeft: "5px" }}
                              />
                            </span>
                            <span>{p.cellPhone}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {p.MRN ? (
                          <>
                            <span>
                              <ConfirmationNumberIcon
                                className={classes.listIcon}
                                style={{ color: Theme.palette.common.black }}
                              />
                            </span>
                            <small style={{ color: Theme.palette.common.black }}>
                              MRN: {p.MRN}
                            </small>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </ListItem>
                  <AddPatient
                    title={ConfirmBtnTitle}
                    showForm={() => onConfirmMatchButtonClicked(p)}
                    width="150px"
                    variant="outlined"
                  />
                </div>
              ))}
            </List>
            <AddPatient title="Add New Patient" showIcon showForm={showForm} />
          </div>
        }
      />
      <Route path={`${pathname}/:patientId`} element={<SinglePatient />} />
    </Routes>
  );
};

export default PatientList;
