export * from "./campaign";
export * from "./config";
export * from "./usa_states";

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

export const TWILIO_ROOMSTATES = {
  DISCONNECTED: "disconnected",
};
export const PHONE_MAX_LENGTH = 10;

export enum WaitingRoomTab {
  QUEUE = 0,
  PATIENT = 1,
}

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = "TwilioVideoApp-selectedAudioInput";
export const SELECTED_AUDIO_OUTPUT_KEY = "TwilioVideoApp-selectedAudioOutput";
export const SELECTED_VIDEO_INPUT_KEY = "TwilioVideoApp-selectedVideoInput";

// Virtual visit queue values
export enum VirtualSessionQueue {
  REGISTERING = "registering",
  NURSE_TRIAGE = "nurse_triage",
  PHYSICIAN_VISIT = "physician_visit",
  CHECKOUT = "checkout",
  REGISTERING_AND_CHECKOUT = "registering_and_checkout",
}

// Virtual visit urgency values
export enum Urgency {
  ROUTINE = "routine",
  URGENT = "urgent",
  LOW_PRIORITY = "low_priority",
}

export const ALL_PATIENTS = "all_patients";
export const ASSIGNED_TO_ME = "assigned_to_me";
export const UNASSIGNED = "unassigned";
export const ASSIGNED = "assigned";

export const circle = {
  [Urgency.URGENT]: "rgba(235, 87, 87, 0.81)",
  [Urgency.ROUTINE]: "rgba(242, 201, 76, 0.78)",
  [Urgency.LOW_PRIORITY]: "#DEEBFC",
};

export const PROGRAM_TYPES = {
  careNavigation: "care_navigation",
  virtualSessionsQueue: "virtual_sessions_queue",
  specialistOnDemand: "specialist_on_demand",
  consultation: "consultation",
  inPatientCommunications: "in_patient_communications",
  adhocVirtual: "adhoc_virtual",
  rpm: "rpm",
  specimen_tracking: "specimen_tracking",
};

export enum ProgramNames {
  VIRTUAL_URGENT_CARE = "Virtual Urgent Care",
  VIRTUAL_BEHAVIORAL_HEALTH = "Virtual Behavioral Health",
  VIRTUAL_NEURO_TRIAGE = "Virtual Neuro Triage",
  MATERNITY_MONITORING_PROGRAM = "Maternity Monitoring Program",
  FAMILY_JOURNAL = "Family Journal",
  NEUROLOGY_INQUIRY = "Neurology Inquiry",
  ONCOLOGY_INQUIRY = "Oncology Inquiry",
  JOINT_REPLACEMENT = "Joint Replacement",
  ADHOC_VIRTUAL_VISITS = "Adhoc Virtual Visits",
  COPD_MONITORING_PROGRAM = "COPD Monitoring Program",
  HYPERTENSION_MONITORING_PROGRAM = "Hypertension Monitoring Program",
  DIABETES_MONITORING_PROGRAM = "Diabetes Monitoring Program",
  REMOTE_MONITORING_PROGRAM = "Remote Monitoring Program",
  EMPLOYEE_PREVENTATIVE_CARE = "Employee Preventative Care",
  VIRTUAL_IMMEDIATE_CARE = "Virtual Immediate Care",
  PHYSICAL_THERAPY = "Physical Therapy & Rehab Inquiry",
}

export const queueItems = [
  VirtualSessionQueue.REGISTERING,
  VirtualSessionQueue.NURSE_TRIAGE,
  VirtualSessionQueue.PHYSICIAN_VISIT,
  VirtualSessionQueue.CHECKOUT,
];

export type TQueueValuesType = {
  label: string;
  value: string;
};
const consultationQueueValues: TQueueValuesType[] = [
  { label: "All", value: "all" },
  { label: "Inquiry", value: "Inquiry" },
  { label: "2nd Opinion", value: "2nd Opinion" },
];

export const consultationQueueView = {
  ALL: "all",
  INQUIRY: "Inquiry",
  SECOND_OPINION: "2nd Opinion",
};
const nonConsultationQueueValues: TQueueValuesType[] = [
  { label: "Registering", value: VirtualSessionQueue.REGISTERING as string },
  { label: "Nurse Triage", value: VirtualSessionQueue.NURSE_TRIAGE as string },
  { label: "Physician Visit", value: VirtualSessionQueue.PHYSICIAN_VISIT as string },
  { label: "Checkout", value: VirtualSessionQueue.CHECKOUT as string },
  {
    label: "Registering & Checkout",
    value: VirtualSessionQueue.REGISTERING_AND_CHECKOUT as string,
  },
];
export const getQueueValues = (isConsultation: boolean): TQueueValuesType[] => {
  if (isConsultation) {
    return [...consultationQueueValues];
  }
  return [...nonConsultationQueueValues];
};

export const USERTYPES = {
  USER_STAFF: "STAFF",
  USER_ADMIN: "ADMIN",
  USER_PHYSICIAN: "PHYSICIAN",
  USER_PATIENT: "IN_PATIENT_MGR",
  USER_REGISTRATION_STAFF: "REGISTRATION_STAFF",
  USER_NURSE: "NURSE",
};

export enum ORDER {
  asc = "asc",
  desc = "desc",
}

export enum OrderBy {
  id = "id",
  requestedStartBeg = "requested_start_beg",
  startDttm = "start_dttm",
  name = "name",
}

export const OTHER_VISIT_REASON = "Other";

export enum Mask {
  ZIP_CODE = "99999",
  CELL_PHONE = "999-999-9999",
}

export enum ConsentPath {
  VIRTUAL_VISIT = "/virtual_visit_consent.pdf",
  HIPAA_AUTH_CLEAR = "/hipaa_auth_clear.pdf",
  CLOSED_CAPTIONS_CONSENT = "/closed_captions_consent.pdf",
}

export const cellPhoneMask = "+19999999999";

export const zipCodeMask = "99999";

export const UserTableTitles = {
  newVirtualSession: "New Virtual Session",
  newConsultation: "New Inquiry",
  newConsultRequest: "New Consult Request",
};
