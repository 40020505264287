import React from "react";
import { CardActionArea, CardContent, CardMedia, Link, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Loader from "components/Loader";
import { useFetch } from "util/useFetch";
import { API_ENDPOINTS, GET_OPTIONS } from "util/api_helper";
import ClearLogo from "static/images/clear.png";

const useStyles = makeStyles()((theme) => ({
  image: {
    maxWidth: 320,
    width: "70%",
    margin: "0 auto",
  },
  error: {
    color: "red",
  },
  sm: {
    width: 255,
    [theme.breakpoints.down("lg")]: {
      width: 130,
      height: 130,
    },
    margin: "0 auto",
  },
  clearLogo: {
    width: 100,
    verticalAlign: "middle",
  },
}));
const VaccinationDetails = ({ size, altQRCodeURL }) => {
  const { classes } = useStyles();
  let uri = `/${API_ENDPOINTS.QR_code}`;
  if (altQRCodeURL && altQRCodeURL.length > 0) {
    uri = altQRCodeURL;
  }
  const url = new URL(window.location.href);
  const showClearParam = url.searchParams.get("showClear");

  let showClear = true;
  if (showClearParam && showClearParam === "false") {
    showClear = false;
  }

  const { isLoading, error, response } = useFetch(uri, {
    ...GET_OPTIONS,
  });

  return (
    <div>
      {!isLoading ? (
        response &&
        response.data && (
          <>
            <CardActionArea>
              <CardMedia
                style={{ position: "relative", zIndex: 20 }}
                className={size === "sm" ? classes.sm : classes.image}
                component="img"
                src={`data:image/jpeg;base64,${response.data}`}
              />
            </CardActionArea>

            <br />
            <br />
            {showClear && (
              <>
                <CardContent>
                  <Typography className="flex-center">
                    <Link
                      href="https://www.atlantichealth.org/conditions-treatments/coronavirus-covid-19/covid-vaccine/digital-vaccine-card/clear-proof-of-vaccine.html"
                      target="_blank"
                      style={{ margin: 0, fontSize: "16pt" }}
                      underline="hover"
                    >
                      <span
                        style={{
                          margin: 0,
                          fontSize: "16pt",
                          verticalAlign: "middle",
                        }}
                      >
                        Link with&nbsp;
                      </span>
                      <img className={classes.clearLogo} src={ClearLogo} alt="" />
                    </Link>
                  </Typography>
                </CardContent>
                <br />
                <br />
              </>
            )}

            <CardContent>
              <Typography className="flex-center">
                <Link
                  href={response.qrcode_url}
                  target="_blank"
                  style={{ margin: 0, fontSize: "12pt" }}
                  underline="hover"
                >
                  Verify Link
                </Link>
              </Typography>
            </CardContent>
          </>
        )
      ) : (
        <Loader />
      )}
      {!isLoading && error && error.message && (
        <Typography className={classes.error}>{error.message}</Typography>
      )}
    </div>
  );
};

export default VaccinationDetails;
