import React from "react";
import { Box } from "@mui/material";

export const SkeletonSpecimenPhoto = () => {
  return (
    <Box
      position="absolute"
      top={0}
      right={0}
      left={0}
      bottom={10}
      style={{
        borderWidth: 2,
        borderStyle: "dashed",
        borderColor: "white",
        height: "70%",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    />
  );
};
