import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Theme, Typography } from "@mui/material";
import MUISnackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import ErrorIcon from "../../icons/ErrorIcon";
import InfoIcon from "../../icons/InfoIcon";
import WarningIcon from "../../icons/WarningIcon";
import { makeStyles } from "tss-react/mui";

interface SnackbarProps {
  headline: string;
  message: string | React.ReactNode;
  variant?: "error" | "warning" | "info";
  open: boolean;
  handleClose?: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "400px",
    minHeight: "50px",
    background: theme.palette.common.white,
    padding: "1em",
    borderRadius: "3px",
    boxShadow: "0 12px 24px 4px rgba(40,42,43,0.2)",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  contentContainer: {
    display: "flex",
    lineHeight: 1.8,
  },
  iconContainer: {
    display: "flex",
    padding: "0 1.3em 0 0.3em",
    transform: "translateY(3px)",
  },
  headline: {
    fontWeight: "bold",
  },
  error: {
    borderLeft: `4px solid ${theme.palette.error.dark}`,
  },
  warning: {
    borderLeft: `4px solid ${theme.palette.warning.main}`,
  },
  info: {
    borderLeft: `4px solid ${theme.palette.info.main}`,
  },
}));

export default function Snackbar({ headline, message, variant, open, handleClose }: SnackbarProps) {
  const { classes, cx } = useStyles();

  const handleOnClose = (_: Event | React.SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    handleClose?.();
  };

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      onClose={handleOnClose}
      autoHideDuration={10000}
    >
      <div
        className={cx(classes.container, {
          [classes.error]: variant === "error",
          [classes.warning]: variant === "warning",
          [classes.info]: variant === "info",
        })}
      >
        <div className={classes.contentContainer}>
          <div className={classes.iconContainer}>
            {variant === "warning" && <WarningIcon />}
            {variant === "error" && <ErrorIcon />}
            {variant === "info" && <InfoIcon />}
          </div>
          <div>
            <Typography variant="body1" className={classes.headline} component="span">
              {headline}
            </Typography>
            <Typography variant="body1" component="span">
              {" "}
              {message}
            </Typography>
          </div>
        </div>
        <div>
          {handleClose && (
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
    </MUISnackbar>
  );
}
