import React from "react";
import Theme from "Theme";
import { CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    // display: 'flex',
    "& > * + *": {
      margin: theme.spacing(2),
    },
  },
  heading: {
    marginTop: theme.spacing(2),
  },
}));

export default function CircularIndeterminate({ text, bgColor = Theme.palette.common.black }) {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h5" component="h2" style={{ backgroundColor: bgColor }}>
          {text}
        </Typography>
      </div>
      <div>
        <CircularProgress />
      </div>
    </div>
  );
}
