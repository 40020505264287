import React, { FC } from "react";
import { Card, Divider, Typography } from "@mui/material";
import { BordCertification, ProviderTraining } from "components/FindADoctor/types";
import useStyles from "./DoctorInfo.styles";

interface Props {
  trainee: Array<ProviderTraining>;
  boardCertifications: Array<BordCertification>;
}

const DoctorExperience: FC<Props> = ({ trainee, boardCertifications }) => {
  const { classes } = useStyles();

  return (
    <Card className={classes.appCard} elevation={8}>
      <Typography className={classes.title} align="center">
        Experience
      </Typography>

      <div>
        <Typography className={classes.titleDescription} align="center">
          Training
        </Typography>
        <div>
          {trainee.map((item) => (
            <div key={item.name}>
              <Typography className={classes.education} align="center">
                {item.name}
              </Typography>
              <Typography className={classes.educationType} align="center">
                {item.type}
              </Typography>
            </div>
          ))}
        </div>

        <Divider variant="middle" className={classes.divider} />

        <Typography className={classes.titleDescription} align="center">
          Board Certifications
        </Typography>
        <div>
          {boardCertifications.map((item) => (
            <div key={item.specialty_name}>
              <Typography className={classes.education} align="center">
                {item.specialty_name}
              </Typography>
              <Typography className={classes.educationType} align="center">
                {item.board_name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default DoctorExperience;
