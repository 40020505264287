export const colDefsProviders = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    showDropdownFilter: false,
    isLink: true,
    searchable: true,
    searchByValue: "lastName",
    isSorteable: true,
  },
  {
    id: "NPI",
    numeric: false,
    disablePadding: false,
    label: "NPI",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "NPI",
    isSorteable: true,
  },
  {
    id: "providerType",
    numeric: false,
    disablePadding: false,
    label: "Provider Type",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "providerType",
    isSorteable: true,
  },
  {
    id: "specialties",
    numeric: false,
    disablePadding: false,
    label: "Specialty",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "specialties",
    isSorteable: false,
  },
  {
    id: "practiceGroups",
    numeric: false,
    disablePadding: false,
    label: "Practice Group(s)",
    showDropdownFilter: false,
    isLink: false,
    isSorteable: false,
    searchable: true,
    searchByValue: "practiceGroups",
  },
];

export const colDefsLocations = [
  {
    id: "ahsId",
    numeric: false,
    disablePadding: true,
    label: "Location ID",
    showDropdownFilter: false,
    isLink: true,
    searchable: true,
    searchByValue: "ahsId",
    isSorteable: true,
  },
  {
    id: "locationFinder",
    numeric: false,
    disablePadding: true,
    label: "Location Finder",
    showDropdownFilter: false,
    isLink: false,
    searchable: false,
    isSorteable: false,
  },
  {
    id: "providerFinder",
    numeric: false,
    disablePadding: true,
    label: "Practice Location",
    showDropdownFilter: false,
    isLink: false,
    searchable: false,
    isSorteable: false,
  },
  {
    id: "nameFull",
    numeric: false,
    disablePadding: false,
    label: "Page Title",
    showDropdownFilter: false,
    isLink: true,
    searchable: true,
    searchByValue: "nameFull",
    isSorteable: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "type",
    isSorteable: true,
  },
  {
    id: "source",
    numeric: true,
    disablePadding: false,
    label: "Source",
    showDropdownFilter: false,
    isLink: false,
    searchable: false,
  },
  {
    id: "parentLocationName",
    numeric: true,
    disablePadding: false,
    label: "Parent Location",
    showDropdownFilter: false,
    searchable: true,
    searchByValue: "parentLocationName",
    isLink: false,
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Location Address",
    showDropdownFilter: false,
    searchable: true,
    searchByValue: "address",
    isLink: false,
  },
];

export const colDefsNeedsReview = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Location ID",
    showDropdownFilter: false,
    isLink: true,
    searchable: true,
    searchByValue: "id",
    isSorteable: true,
    isModal: true,
  },
  ...colDefsLocations.slice(1),
];

export const colDefsNeedsReviewModalTable = [
  {
    id: "pageTitle",
    numeric: false,
    disablePadding: false,
    label: "Page Title",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "pageTitle",
    isSorteable: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "type",
    isSorteable: true,
  },
  {
    id: "parentLocationName",
    numeric: true,
    disablePadding: false,
    label: "Parent Location",
    showDropdownFilter: false,
    searchable: true,
    searchByValue: "parentLocationName",
    isLink: false,
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Location Address",
    showDropdownFilter: false,
    searchable: true,
    searchByValue: "address",
    isLink: false,
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
    showDropdownFilter: false,
    searchable: true,
    searchByValue: "phone",
    isLink: false,
  },
  {
    id: "providers",
    numeric: true,
    disablePadding: false,
    label: "Providers",
    showDropdownFilter: false,
    searchable: true,
    searchByValue: "providers",
    isLink: false,
  },
];
export const colDefsChangeLog = [
  {
    id: "dateAndTime",
    numeric: false,
    disablePadding: true,
    label: "Date & Time",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "dateAndTime",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Edit Type",
    showDropdownFilter: false,
    isLink: false,
  },
  {
    id: "entityType",
    numeric: true,
    disablePadding: false,
    label: "Entity Type",
    showDropdownFilter: false,
    isLink: false,
  },
  {
    id: "editedPage",
    numeric: true,
    disablePadding: false,
    label: "Edited Page",
    showDropdownFilter: false,
    isLink: false,
  },
  {
    id: "entityId",
    numeric: true,
    disablePadding: false,
    label: "Entity ID",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "entityId",
  },
  {
    id: "user",
    numeric: true,
    disablePadding: false,
    label: "User",
    showDropdownFilter: false,
    isLink: false,
    searchable: true,
    searchByValue: "user",
  },
];
