import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Paper, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface BannerButtonProps {
  border?: "orange" | "blue" | "none";
  icon?: string;
  image?: string;
  title: string;
  subtitle?: string;
  showRightArrow?: boolean;

  navigateTo?: string;
  navigateFrom?: string;
  // indicates wheter the navigation should be treated as history.push or not
  shouldPushNavigation?: boolean;

  customOnClick?: () => void;

  testId: string;
}

const useStyles = makeStyles()(() => ({
  bannerButtonContainer: {
    margin: "0 1.2em",
    "-webkit-box-shadow": "0.1em 0.1em 0.75em 0 rgba(0, 0, 0, 0.1)",
    boxShadow: "0.1em 0.1em 0.75em 0 rgba(0, 0, 0, 0.1)",
    minHeight: "80px",
  },
  orangeBorder: {
    borderLeft: "5px solid #f3910d",
  },
  blueBorder: {
    borderLeft: "5px solid #3676c4",
  },
  buttonContainer: {
    backgroundColor: "white",
    width: "100%",
    border: "0",
    outline: "none",
    display: "flex",
    flexDirection: "row",
    flex: "none",
    alignItems: "center",
    fontSize: "1em",
    gap: "0.5em 1em",
    padding: "1em",

    "&:focus": {
      outline: "none",
    },
  },
  onlyRightPadding: {
    padding: "0 1em 0 0",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    textAlign: "left",
  },
  title: {
    color: "#171a5b",
    fontWeight: 500,
  },
  subtitle: {
    fontSize: "15px",
    color: "#5d5d5d",
    lineHeight: "18px",
  },
  arrowIcon: {
    color: "#5d5d5d",
    height: "20px",
    width: "20px",
  },
  icon: {
    width: "2.4em",
    height: "2.4em",
    margin: "4px",
  },
  image: {
    objectFit: "cover",
    width: "80px",
    height: "80px",
  },
}));

const BannerButton = ({
  border = "none",
  icon,
  image,
  title,
  subtitle,
  showRightArrow = false,
  navigateTo,
  navigateFrom,
  shouldPushNavigation = true,
  customOnClick,
  testId,
}: BannerButtonProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const containerClassName = `${classes.bannerButtonContainer}
  ${border === "orange" ? classes.orangeBorder : ""}
  ${border === "blue" ? classes.blueBorder : ""}`;

  const handleOnClick = useCallback(() => {
    if (!navigateTo || !navigateFrom) {
      return;
    }
    if (shouldPushNavigation) {
      navigate(navigateTo, { state: { from: navigateFrom } });
      return;
    }
    window.open(navigateTo, "_blank");
  }, [navigateTo, navigateFrom, shouldPushNavigation, navigate]);

  return (
    <Paper className={containerClassName}>
      <button
        type="button"
        className={`${classes.buttonContainer} ${image ? classes.onlyRightPadding : ""}`}
        onClick={customOnClick || handleOnClick}
        data-cy={testId}
      >
        {icon && <img src={icon} className={classes.icon} alt="" />}
        {image && <img src={image} className={classes.image} alt="" />}
        <div className={classes.textContainer}>
          <Typography className={classes.title}>{title}</Typography>
          {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
        </div>
        {showRightArrow && (
          <ArrowForward data-testid="arrowForward" className={classes.arrowIcon} />
        )}
      </button>
    </Paper>
  );
};

export default BannerButton;
