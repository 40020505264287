import React from "react";
import { Container, Typography } from "@mui/material";
import { SpecimenReportCardProps } from "./types";
import { useSpecimenReportCardStyles } from "./styles";

export const SpecimenReportCard = ({ type, header, text, footer }: SpecimenReportCardProps) => {
  const {
    classes: { container, headerStyle, textStyle, footerStyle },
  } = useSpecimenReportCardStyles({ type });
  return (
    <Container className={container}>
      <Typography className={headerStyle} variant="subtitle1">
        {header}
      </Typography>
      <Typography className={textStyle} variant="h4">
        {text}
      </Typography>
      <Typography className={footerStyle} variant="caption">
        {footer}
      </Typography>
    </Container>
  );
};
