import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import icon from "static/images/ahsIcons/vaccine_card.svg";

const useStyles = makeStyles()(() => ({
  container: {
    height: "calc(100vh - 200px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    margin: "16px 0",
  },
  subtitle: {
    fontSize: "18px",
    lineHeight: "32px",
    width: "60%",
    maxWidth: "600px",
    minWidth: "250px",
    margin: "0 auto 40px auto",
  },
  homeButton: {
    textTransform: "uppercase",
  },
  image: {
    height: "60px",
    marginBottom: "20px",
  },
}));

const FormSubmitted = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const [campaignPhoneNumber, setCampaignPhoneNumber] = useState<string>("973-971-7883");
  const { classes } = useStyles();
  const navigate = useNavigate();

  const getCampaignCallBackNumber = async (campaignId) => {
    try {
      const getUrl = `${API_ENDPOINTS.getCampaignCallbackNumber}/${campaignId}`;
      const { data } = await axiosClient.get(getUrl);
      if (data.callbackPhoneNumber !== undefined) {
        // If we get a callbackPhoneNumber (even if it's null), set it
        setCampaignPhoneNumber(data.callbackPhoneNumber);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (campaignId) {
      getCampaignCallBackNumber(campaignId);
    }
  }, [campaignId]);

  let reachUsMsg = "";
  if (campaignPhoneNumber) {
    reachUsMsg = `If you have any questions about your treatment, please call us at ${campaignPhoneNumber}.`;
  }

  return (
    <div className={classes.container}>
      <img src={icon} alt="" className={classes.image} />
      <div className={classes.title}>Thank You!</div>
      <div className={classes.subtitle}>
        Your answers have been sent to your care team.
        {reachUsMsg}
      </div>
      <Button
        variant="contained"
        className={classes.homeButton}
        color="primary"
        onClick={() => navigate("/home")}
        data-cy="returnHomeBtn"
      >
        Return home
      </Button>
    </div>
  );
};

export default FormSubmitted;
