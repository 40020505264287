import React from "react";
import Button from "@mui/material/Button";
import { orange } from "@mui/material/colors";
import { makeStyles, withStyles } from "tss-react/mui";

const ColorButton = withStyles(Button, (theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: orange[600],
    borderRadius: 0,
    fontWeight: "bold",
    width: "100%",
    "&:hover": {
      backgroundColor: orange[800],
    },
  },
}));

const useStyles = makeStyles()((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizedButtons({ onClick }) {
  const { classes } = useStyles();

  return (
    <div>
      <ColorButton variant="contained" color="primary" className={classes.margin} onClick={onClick}>
        LINK VACCINE RECORD
      </ColorButton>
    </div>
  );
}
