import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import DoctorDescription from "./DoctorDescription";
import DoctorExperience from "./DoctorExperience";
import DoctorInfo from "./DoctorInfo";
import DoctorLocation from "./DoctorLocation";
import Insurance from "./Insurance";
import ModalMakeAppointment from "./ModalMakeAppointment";
import { ProviderInfo } from "components/FindADoctor/types";
import { API_HOST } from "util/constants/config";
import { useApp } from "util/AppContext";

const DoctorBooking = () => {
  const { id } = useParams<{ id: string }>();
  const [isShown, setIsShown] = useState<boolean>(false);
  const [provider, setProvider] = useState<ProviderInfo>();
  const [providerNumber, setProviderNumder] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const app = useApp();

  const getProvider = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("dfd.kyruus.token");
      const url = `${API_HOST.kyruusApi}v9/atlantic/providers/${id}`;
      const params = {
        access_token: accessToken,
      };
      const { data } = await axios.get(url, { params });
      setProvider(data);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelShowModal = () => {
    setIsShown(true);
    if (provider) {
      setProviderNumder(provider.provider.contacts[0].value);
    }
  };

  if (isLoading) {
    return (
      <Box marginTop="24px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      {provider && (
        <ModalMakeAppointment
          fullName={provider.provider.name.full}
          isShown={isShown}
          providerNumber={providerNumber}
          setIsShown={setIsShown}
          isOnlineBoking={provider.provider.direct_book_pmc}
          providerId={id || ""}
        />
      )}
      {provider ? (
        <>
          <DoctorInfo
            picture={provider.provider.image_url}
            fullName={provider.provider.name.full}
            specialty={provider.provider.specialties[0]?.name}
            clinic={provider.locations[0]?.name}
            setIsShown={handelShowModal}
          />
          <DoctorDescription
            fullName={provider.provider.name.full}
            video={provider.provider.provider_videos[0]}
            gender={provider.provider.gender}
            aboutDoctor={provider.provider.about_the_provider}
            languages={provider.provider.languages}
            affiliations={provider.provider.network_affiliations}
            notes={provider.provider.notes}
          />
          <DoctorExperience
            trainee={provider.provider.training}
            boardCertifications={provider.provider.board_certifications}
          />
          <DoctorLocation doctor={provider} />
          <Insurance acceptedInsurance={provider.provider.insurance_accepted} />
        </>
      ) : (
        <Typography> No info </Typography>
      )}
    </Container>
  );
};

export default DoctorBooking;
