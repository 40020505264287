import React, { useEffect, useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import useTranslation from "hooks/useTranslation";

const useStyles = makeStyles()(() => ({
  progressBar: {
    height: "8px",
    borderRadius: "16px",
    background: "#64646412",
    marginBottom: "8px",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#f3910d",
    },
  },
}));

const OrderProgressBar = ({ orderId, specimensCount, scannedCodes, actionType }) => {
  const {
    web: {
      specimenTrackingAdd: { specimensTotal },
    },
  } = useTranslation();
  const { classes } = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const validScannedCodes = Math.min(scannedCodes, specimensCount);
    const calculatedProgress = (validScannedCodes / specimensCount) * 100;

    setProgress(calculatedProgress);
  }, [scannedCodes, specimensCount]);

  return (
    <Box width="100%" padding="0 16px" data-testid="OrderProgressBar">
      <Box display="flex" justifyContent="space-between">
        <Typography color="primary" variant="h6" style={{ fontSize: "18px" }}>
          {orderId}
        </Typography>
        <Typography color="primary">
          {actionType !== "ADD" && `${scannedCodes}/`}
          {specimensCount} {specimensTotal}
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={progress} className={classes.progressBar} />
    </Box>
  );
};

export default OrderProgressBar;
