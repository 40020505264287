/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import TeamMembers from "../TeamMembers";
import { makeStyles } from "tss-react/mui";
import { EditUser, EditUserCampaignPermissions, EditUserProgram } from "./components";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import {
  DialogTitleProps,
  EditUserModalProps,
  IDepartmentsData,
  IRolesList,
  IUserRecord,
  ProgramsList,
} from "./types";
import { makeNewReferalStyles } from "../NewReferral/styles";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: 0,
    padding: "16px 0 0",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const { classes } = useStyles();

  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <b>{children}</b>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

const TABS = {
  user: 0,
  userDepartment: 1,
  userTeamMembers: 2,
  userCampaignPermissions: 3,
};

export default function EditUserModal({
  show,
  setShow,
  record,
  mode,
}: Readonly<EditUserModalProps>) {
  const { classes } = makeNewReferalStyles();
  const app = useApp();

  const [open, setOpen] = useState(false);

  const emptyUserRecord = {
    userId: "",
    username: "",
    firstName: null,
    lastName: null,
    cellPhone: "",
    userType: "",
    userStatus: "",
    email: "",
    externalId: null,
    emrStaffId: null,
    emrStaffUsername: null,
  };
  const [userRecord, setUserRecord] = useState<IUserRecord>(emptyUserRecord);
  const [isLockedUser, setIsLockedUser] = useState(false);
  const [userTypeRefList, setUserTypeRefList] = useState([]);

  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedRole, setSelectedRole] = useState<string | number>("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [programsList, setProgramsList] = useState<ProgramsList[]>([]);
  const [rolesList, setRolesList] = useState<IRolesList[]>([]);
  const [departmentUsersData, setDepartmentUsersData] = useState<IDepartmentsData[]>([]);
  const [tabValue, setTabValue] = useState(TABS.user);
  const [error, setError] = useState({
    username: false,
    userType: false,
    userStatus: false,
  });
  const isError = !userRecord.username || !userRecord.userType || !userRecord.userStatus;
  const isMobile = useIsMobile();

  useEffect(() => {
    showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    getProgramsList();
    getRolesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSelectedRoleId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRecord.userType]);

  useEffect(() => {
    if (isError) {
      setError({
        username: false,
        userType: false,
        userStatus: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRecord.username, userRecord.userType, userRecord.userStatus]);

  const handleClose = () => {
    setOpen(false);
    setShow(false);
    setUserRecord(emptyUserRecord);
    setIsLockedUser(false);
    setDepartmentUsersData([]);
    setTabValue(TABS.user);
    setError({
      username: false,
      userType: false,
      userStatus: false,
    });
  };

  const showModal = () => {
    if (show) {
      setUserRecord({
        userId: record.id,
        username: record.username,
        firstName: record.first_name,
        lastName: record.last_name,
        cellPhone: record.cell_phone,
        userType: record.user_type,
        userStatus: record.user_status,
        email: record.email ? record.email.split("@")[0] : "",
        externalId: record.external_tracking_id,
        emrStaffId: record.emr_staff_id,
        emrStaffUsername: record.emr_staff_username,
      });

      const isUserLocked = parseInt(String(record?.failed_login_attempts) || "0", 10) >= 10;
      setIsLockedUser(isUserLocked);
      setOpen(true);
      getSelectedUserDepartmentsData(record.id);
    } else {
      handleClose();
    }
  };

  const getProgramsList = async () => {
    try {
      const { data } = await axiosClient.get(API_ENDPOINTS.get_programs_list);
      setProgramsList(data.data);
    } catch (error) {
      app.addError("Failed to get programs list.");
    }
  };

  const getRolesList = async () => {
    try {
      const { data } = await axiosClient.get(API_ENDPOINTS.get_roles_list);
      setRolesList(data.data);
      setUserTypeRefList(data.data);
    } catch (error) {
      app.addError("Failed to get roles list.");
    }
  };

  const getSelectedUserDepartmentsData = async (user_id) => {
    if (!user_id) {
      return;
    }
    try {
      const { data } = await axiosClient.get(API_ENDPOINTS.get_department_users_data, {
        params: { user_id },
      });
      setDepartmentUsersData(data.data);
    } catch (error) {
      app.addError("Failed to get department users data.");
    }
  };

  const getSelectedRoleId = () => {
    if (!userRecord.userType) {
      return null;
    }
    const sel_role = rolesList.filter((role) => {
      return (
        role.role_name.toString().toUpperCase() === userRecord.userType.toString().toUpperCase()
      );
    });
    if (sel_role.length === 0) {
      return null;
    }

    const sel_role_id = sel_role[0].id;
    const sel_role_name = sel_role[0].role_name;
    setSelectedRole(sel_role_id);
    setSelectedRoleName(sel_role_name);
    return sel_role_id;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onSaveClicked = async (closeModal: boolean): Promise<void> => {
    const {
      username,
      userType,
      userStatus,
      firstName,
      lastName,
      email,
      cellPhone,
      emrStaffUsername,
      emrStaffId,
      externalId,
    } = userRecord;

    setError({
      username: !username,
      userType: !userType,
      userStatus: !userStatus,
    });

    const getEmailWithoutDomain = (email: string) => {
      const trimmedEmail = email?.trim();
      if (trimmedEmail) {
        return `${trimmedEmail.split("@")[0]}@atlantichealth.org`;
      }

      return null;
    };

    if (!isError) {
      try {
        const user = {
          username: username?.trim(),
          firstName: firstName?.trim() ? firstName?.trim() : null,
          lastName: lastName?.trim() ? lastName?.trim() : null,
          email: getEmailWithoutDomain(email),
          cellPhone: cellPhone?.trim() ? cellPhone?.trim() : null,
          emrStaffUsername: emrStaffUsername || null,
          emrStaffId: emrStaffId || null,
          externalTrackingId: externalId || null,
          userType: userType,
          userStatus: userStatus,
          ...(mode === "edit" && {
            isLockedUser,
          }),
        };

        if (mode === "add") {
          const url = "api/s/console/user";
          const { data } = await axiosClient.post(url, user);
          const newUserId = data.data.id;
          app.addInfoMsg("Record has been saved successfully.");
          if (closeModal) {
            handleClose();
          }
          return newUserId;
        } else if (mode === "edit") {
          const url = `api/s/console/user/${userRecord.userId}`;
          await axiosClient.patch(url, user);
          app.addInfoMsg("Record has been saved successfully.");
          if (closeModal) {
            handleClose();
          }
        }
      } catch (e: any) {
        app.addError(e.response?.data?.description || e.response?.data?.error);
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      classes={{ paperWidthMd: classes.modalDialog }}
      fullScreen={isMobile}
      data-testid="userListModal"
    >
      <DialogTitle onClose={handleClose}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="createVirtualSessionOptions">
          <Tab label={`${mode === "add" ? "Add" : "Edit"} user`} data-testid="addUserTab" />
          <Tab label="Set Program/Dept Roles" data-testid="deptRolesTab" />
          <Tab label="Linking Team Members" data-testid="teamMembersTab" />
          {mode !== "add" &&
            (selectedRoleName === "CAMPAIGN_MGMT" || selectedRoleName === "ADMIN") && (
              <Tab label="Campaign Permissions" />
            )}
        </Tabs>
      </DialogTitle>
      <MuiDialogContent dividers classes={{ root: classes.formContainer }}>
        {tabValue === TABS.user && (
          <EditUser
            isLockedUser={isLockedUser}
            record={record}
            setIsLockedUser={setIsLockedUser}
            setUserRecord={setUserRecord}
            userRecord={userRecord}
            userTypeRefList={userTypeRefList}
            onSaveClicked={onSaveClicked}
            error={error}
          />
        )}
        {tabValue === TABS.userDepartment && (
          <EditUserProgram
            selectedProgram={selectedProgram}
            setSelectedProgram={setSelectedProgram}
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
            setSelectedRoleName={setSelectedRoleName}
            programsList={programsList}
            selectedRole={selectedRole}
            departmentUsersData={departmentUsersData}
            setDepartmentUsersData={setDepartmentUsersData}
            setSelectedRole={setSelectedRole}
            userID={userRecord?.userId}
            getSelectedUserDepartmentsData={getSelectedUserDepartmentsData}
            rolesList={rolesList}
            mode={mode}
            onSaveClicked={onSaveClicked}
            handleClose={handleClose}
            userRecord={userRecord}
            setUserRecord={setUserRecord}
          />
        )}
        {tabValue === TABS.userTeamMembers && <TeamMembers userRecord={userRecord} isConfigView />}
        {tabValue === TABS.userCampaignPermissions && (
          <EditUserCampaignPermissions userId={record.id} />
        )}
      </MuiDialogContent>
    </Dialog>
  );
}
