import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  "@keyframes entrance": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  filtersWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    padding: "16px 16px 8px 16px",
    position: "absolute",
    width: "95%",
    margin: "0 auto",
    top: "18.3em",
    zIndex: 5,
    animation: `$entrance .2s ease both`,
  },
  inputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    width: "100%",
  },
  input: {
    width: "100%",
    fontSize: "18px",
  },
  calender: {
    margin: 0,
  },
  clearFilterBtn: {
    display: "flex",
    gap: "8px,",
    alignItems: "center",
    marginLeft: "auto",
    color: "var(--info)",
  },
}));
