import { getFullNameFromParts } from "util/util_functions";

export const colDefsOrderListReport = [
  {
    id: "orderExternalId",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Order Id",
    searchable: false,
    sortable: true,
    mobile: true,
    isString: true,
  },
  {
    id: "specimenCount",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Specimens in Order",
    searchable: false,
    sortable: false,
    mobile: true,
    isString: true,
  },
  {
    id: "department",
    numeric: false,
    disablePadding: true,
    label: "Department",
    searchable: false,
    sortable: false,
    mobile: true,
    isCustomContent: true,
    func: (_, row) => row.department?.shortName,
  },
  {
    id: "mrn",
    numeric: false,
    disablePadding: true,
    label: "MRN",
    searchable: false,
    sortable: false,
    mobile: true,
  },
  {
    id: "collectedAtName",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Location",
    searchable: false,
    sortable: false,
    mobile: true,
    isString: true,
  },
  {
    id: "specimenStatus",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Status",
    searchable: false,
    sortable: false,
    mobile: true,
    isString: true,
  },
  {
    id: "type",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Type",
    searchable: false,
    sortable: false,
    mobile: true,
    isString: true,
  },
  {
    id: "specimenOrderPatientName",
    numeric: false,
    disablePadding: true,
    label: "Patient Name",
    searchable: false,
    sortable: true,
    mobile: true,
    isCustomContent: true,
    func: (_, row) =>
      getFullNameFromParts(row.specimenOrderPatientFirstName, row.specimenOrderPatientLastName),
  },
  {
    id: "lastTrackedBy",
    numeric: false,
    disablePadding: true,
    label: "Last Tracked By",
    searchable: false,
    sortable: false,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
  {
    id: "firstTrackedDate",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "First Tracked Date",
    searchable: false,
    sortable: false,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
  {
    id: "lastTrackedDate",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "Last Tracked Date",
    searchable: false,
    sortable: false,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
];

export const colDefsOrderListCollapsedReport = [
  {
    id: "specimenOrderOrderExternalId",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Order Id",
    searchable: false,
    sortable: true,
    mobile: true,
    isString: false,
    func: () => {
      return "";
    },
  },
  {
    id: "specimenSpecimenExternalId",
    isLink: true,
    href: "/console/specimen-tracking/history/",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Specimens in Order",
    searchable: false,
    sortable: false,
    mobile: true,
    isString: true,
    func: (_, row) => {
      return row.specimenId;
    },
  },
  {
    id: "departmentShortName",
    numeric: false,
    isCustomContent: false,
    disablePadding: true,
    label: "Department",
    searchable: false,
    sortable: false,
    mobile: true,
  },
  {
    id: "specimenOrderMrn",
    numeric: false,
    disablePadding: true,
    label: "MRN",
    searchable: false,
    sortable: false,
    mobile: true,
  },
  {
    id: "collectedAtName",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Location",
    searchable: false,
    sortable: false,
    mobile: true,
    isString: true,
  },
  {
    id: "specimenStatus",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Status",
    searchable: false,
    sortable: false,
    mobile: true,
    isString: true,
  },
  {
    id: "type",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Type",
    searchable: false,
    sortable: false,
    mobile: true,
    isString: true,
  },
  {
    id: "specimenOrderPatientName",
    numeric: false,
    disablePadding: true,
    label: "Patient Name",
    searchable: false,
    sortable: true,
    mobile: true,
  },
  {
    id: "collectedByName",
    numeric: false,
    disablePadding: true,
    label: "Last Tracked By",
    searchable: false,
    sortable: false,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
  {
    id: "firstTrackedDate",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "First Tracked Date",
    searchable: false,
    sortable: false,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
  {
    id: "lastTrackedDate",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "Last Tracked Date",
    searchable: false,
    sortable: false,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
];
