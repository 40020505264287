import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
  textField: {
    marginBottom: "15px",
    width: "100%",
    flexGrow: "unset",
  },
  subtitle: {
    textAlign: "center",
    fontSize: "18px",
    marginTop: 0,
    marginBottom: "10px",
  },
  title: {
    fontSize: "26px",
    color: "rgb(40, 42, 43)",
    fontWeight: 500,
    lineHeight: "34px",
    textAlign: "center",
    marginBottom: "15px",
  },
  conferenceText: {
    marginTop: 0,
    marginBottom: "15px",
    textAlign: "center",
  },
  buttonsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    marginTop: "auto",
  },
  button: {
    height: "42px",
    textTransform: "uppercase",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btnIcon: {
    marginRight: "8px",
    height: "18px",
  },
  radioGroup: {
    marginBottom: "15px",
  },
  radioGroupLabelWithSkip: {
    marginBottom: "0px",
  },
  skipRegistrationCheckbox: {
    marginBottom: "20px",
  },
}));
