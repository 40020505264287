import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { createCYidfromText } from "util/deviceUtils";
import useStyles from "./MediaCard.styles";

interface Props {
  title: string;
  imgUrl: string;
  desc: string;
  actionUrl?: string;
  msg: string;
  handleCardClick: (value: string) => void;
}

const MediaCard = ({ title, imgUrl, desc, actionUrl, msg, handleCardClick }: Props) => {
  const navigate = useNavigate();

  const { classes } = useStyles();

  return (
    <Card className={classes.chatCard} elevation={24}>
      <CardActionArea
        onClick={() => {
          if (actionUrl && actionUrl.length > 0) {
            if (actionUrl.startsWith("/")) {
              navigate(actionUrl);
            } else {
              window.open(actionUrl, "_blank");
            }
          } else if (handleCardClick) {
            handleCardClick(msg);
          } else {
            console.log(`warning, no click handler setup actionUrl: ${actionUrl} msg: ${msg}`);
          }
        }}
        data-testid="cardAction"
        data-cy={createCYidfromText(title)}
      >
        <Grid container>
          <Grid item xs={2} sm={12}>
            <CardContent className={classes.imgContainer}>
              <img src={imgUrl} alt="" height={40} width={40} />
            </CardContent>
          </Grid>
          <Grid item xs={10} sm={12}>
            <CardContent className={classes.descContainer}>
              <Typography variant="h5" className={classes.title}>
                {title}
              </Typography>
              <div className={classes.cardDesc}>
                <Typography variant="body1" color="textSecondary">
                  {desc}
                </Typography>
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

MediaCard.defaultProps = {
  actionUrl: "",
};

export default MediaCard;
