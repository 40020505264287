import { makeStyles } from "tss-react/mui";

export const useModalStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  modalDialog: {
    width: "840px",
  },
  formContainer: {
    padding: theme.spacing(2),
    height: "auto",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
  },
  tableContainerHeight: {
    height: "auto",
  },
  titleContainer: {
    margin: 0,
    padding: "16px",
  },
  title: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
  },
  textField: {
    marginTop: "24px",
  },
  error: {
    color: theme.palette.error.main,
    marginTop: "4px",
    fontSize: "12px",
  },
  tagsSelect: {
    marginTop: "26px",
    "& .MuiInputLabel-outlined": {
      background: "white",
      padding: "0 2px",
    },
    "& .MuiSelect-outlined": {
      padding: "12px",
      height: "auto",
      minHeight: "32px",
    },
    "& .MuiSelect-outlined:focus": {
      background: "white",
    },
  },
  chipsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
  },
  tagChip: {
    background: "#043070",
    color: "white",
  },
}));
