/* eslint-disable */
import { BarcodeReader } from "dynamsoft-javascript-barcode";

/** LICENSE ALERT - README
 * To use the library, you need to first specify a license key using the API "license" as shown below.
 */

BarcodeReader.license =
  "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyMjYxODM1LTEwMjI2OTg0MCIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMjI2MTgzNSIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi04MDYwNjExNH0";

/**
 * You can visit https://www.dynamsoft.com/customer/license/trialLicense?utm_source=zip&product=dbr&package=js to get your own trial license good for 30 days.
 * Note that if you downloaded this sample from Dynamsoft while logged in, the above license key may already be your own 30-day trial license.
 * For more information, see https://www.dynamsoft.com/barcode-reader/programming/javascript/user-guide/?ver=9.6.30&utm_source=zip#specify-the-license or contact support@dynamsoft.com.
 * LICENSE ALERT - THE END
 */

BarcodeReader.engineResourcePath =
  "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.42/dist/";
