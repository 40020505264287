import React from "react";
import { makeStyles } from "tss-react/mui";
import Hero from "../../../components/Mobile/Hero/Hero";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import SquareButton from "../../../components/Mobile/SquareButton/SquareButton";
import CONSTANTS, { FIND_A_DOCTOR_URL } from "../constants";
import useTranslation from "../../../hooks/useTranslation";
import FindDoctorIcon from "../../../static/svg/mobile/finddoctor.svg";
import FindUrgentCareIcon from "../../../static/svg/mobile/findurgentcare.svg";
import MakeAppointmentIcon from "../../../static/svg/mobile/makeappointment.svg";
import PayMyBillIcon from "../../../static/svg/mobile/paymybill.svg";

const useStyles = makeStyles()(() => ({
  twoColumnContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "0.8em",
    padding: "0 1em",
  },
}));

const Home = () => {
  const { mobile } = useTranslation();
  const { classes } = useStyles();
  const {
    FindDoctor,
    FindDoctorDesc,
    VirtualCare,
    FindUrgentCareDesc,
    MakeAppointment,
    MakeAppointmentDesc,
    PayMyBill,
    PayMyBillDesc,
  } = mobile;

  return (
    <PageContainer>
      <Hero variant="home" />
      <div className={classes.twoColumnContainer}>
        <SquareButton
          icon={FindDoctorIcon}
          title={FindDoctor}
          subtitle={FindDoctorDesc}
          navigateTo={FIND_A_DOCTOR_URL}
          navigateFrom={CONSTANTS.HOME}
          shouldPushNavigation={false}
          testId="home-view-find-doctor-button"
        />
        <SquareButton
          icon={FindUrgentCareIcon}
          title={VirtualCare}
          subtitle={FindUrgentCareDesc}
          navigateTo="https://www.atlantichealth.org/conditions-treatments/urgent-care/virtual-urgent-care.html"
          navigateFrom={CONSTANTS.HOME}
          shouldPushNavigation={false}
          testId="home-view-find-urgent-care-button"
        />
        <SquareButton
          icon={MakeAppointmentIcon}
          title={MakeAppointment}
          subtitle={MakeAppointmentDesc}
          navigateTo={`${CONSTANTS.mobileBaseUrl}${CONSTANTS.APPOINTMENTS}`}
          navigateFrom={CONSTANTS.HOME}
          testId="home-view-make-appointment-button"
        />
        <SquareButton
          icon={PayMyBillIcon}
          title={PayMyBill}
          subtitle={PayMyBillDesc}
          navigateTo={`${CONSTANTS.mobileBaseUrl}${CONSTANTS.BILLING}`}
          navigateFrom={CONSTANTS.HOME}
          testId="home-view-pay-my-bill-button"
        />
      </div>
    </PageContainer>
  );
};
export default Home;
