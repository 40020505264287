import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import icon from "static/images/ahsIcons/vaccine_card.svg";

const useStyles = makeStyles()(() => ({
  container: {
    height: "calc(100vh - 200px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    margin: "16px 0",
  },
  subtitle: {
    fontSize: "18px",
    lineHeight: "32px",
    width: "60%",
    maxWidth: "600px",
    minWidth: "250px",
    margin: "0 auto 40px auto",
  },
  staffHints: {
    fontSize: "14px",
    lineHeight: "32px",
    width: "100%",
    maxWidth: "600px",
    minWidth: "250px",
    margin: "20px auto 0 auto",
  },
  homeButton: {
    textTransform: "uppercase",
  },
  image: {
    height: "60px",
    marginBottom: "20px",
  },
}));

const ThankYouPage = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const isStaff = params.get("is_staff");

  return (
    <div className={classes.container}>
      <img src={icon} alt="" className={classes.image} />
      <div className={classes.title}>Thank You!</div>
      {!isStaff ? (
        <div className={classes.subtitle}>
          Your acknowledgement has been submitted and sent to the practice.
        </div>
      ) : (
        <div className={classes.subtitle}>
          <p>Confirm Patient has recieved the SMS and can view the Acknowledgement</p>
          <p>
            Verify patient chart in Epic that the signed acknowledgement was received. This can take
            upto a couple of minutes.
          </p>
        </div>
      )}

      <hr />

      <Button
        variant="contained"
        className={classes.homeButton}
        color="primary"
        onClick={() => navigate("/")}
        data-cy="returnHomeBtn"
      >
        Return home
      </Button>
    </div>
  );
};

export default ThankYouPage;
