import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import AhsCard from "../shared/ahsCard";
import { makeStyles } from "tss-react/mui";
import { getKyruusToken } from "views/NewAppointment/KyruusRequests";
import NewAppointmetIconSource from "static/images/ahsIcons/new_appointment.svg";
import ResumeIconSource from "static/images/ahsIcons/resume.svg";
import SheduleIconSource from "static/images/ahsIcons/shedule.svg";

const useStyles = makeStyles()(() => ({
  container: {
    paddingTop: "48px",
    paddingBottom: "24px",
    maxWidth: "800px",
  },
  title: {
    margin: 0,
    marginBottom: "24px",
  },
  subtitle: {
    margin: 0,
    marginBottom: "16px",
  },
}));

const DoctorFinder = () => {
  const { classes } = useStyles();

  useEffect(() => {
    getKyruusToken();
  }, []);

  const prevEncounter = [
    {
      id: 1,
      doctor: "Samia Tariq, DO",
      specialty: "Emergency Medicine",
      photo:
        "https://kyruus-app-static.kyruus.com/providermatch/atlantic/photos/orig/pmc-avatar-female_316x392.png",
    },
    {
      id: 2,
      doctor: "Jason A. Smith, MD",
      specialty: "General Surgery",
      photo:
        "https://kyruus-app-static.kyruus.com/providermatch/atlantic/photos/orig/pmc-avatar-male_316x392.png",
    },
    {
      id: 3,
      doctor: "Tina Min, MD",
      specialty: "Emergency Medicine",
      photo:
        "https://kyruus-app-static.kyruus.com/providermatch/atlantic/photos/orig/pmc-avatar-female_316x392.png",
    },
  ];

  const navigate = useNavigate();

  const handleCardClick = (intent) => {
    if (intent === "New Appointment") {
      navigate("/new-appointment");
      return;
    }

    if (intent === "Previous Appointment") {
      navigate("/prev-appointment");
    }
  };

  return (
    <Container maxWidth="md" classes={{ maxWidthMd: classes.container }}>
      <Typography variant="h4" className={classes.title}>
        What type of care are you looking for today?
      </Typography>
      <AhsCard
        title="Schedule New Appointment"
        desc="Get care for a specific treatment you need today"
        imgUrl={NewAppointmetIconSource}
        msg="New Appointment"
        handleCardClick={handleCardClick}
      />
      <AhsCard
        title="Resume your last search"
        desc="Get care for a specific treatment you need today"
        imgUrl={ResumeIconSource}
        msg=""
        handleCardClick={handleCardClick}
      />
      <AhsCard
        title="Schedule your last missed calls"
        desc="Your have outstanding appointments"
        imgUrl={SheduleIconSource}
        msg=""
        handleCardClick={handleCardClick}
      />
      <Typography variant="h5" className={classes.subtitle}>
        Previous providers you visited
      </Typography>
      {prevEncounter.map((appt) => (
        <AhsCard
          key={appt.id}
          title={appt.doctor}
          desc={appt.specialty}
          imgUrl={appt.photo}
          msg="Previous Visit"
          handleCardClick={handleCardClick}
        />
      ))}
    </Container>
  );
};

export default DoctorFinder;
