import React, { ReactNode, createContext, useContext, useMemo, useState } from "react";

interface ProgramsLoadingContextType {
  isProgramsLoading: boolean;
  setIsProgramsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProgramsLoadingContext = createContext<ProgramsLoadingContextType | undefined>(
  undefined,
);

interface ProgramsLoadingProviderProps {
  children: ReactNode;
}

export const ProgramsLoadingProvider = ({ children }: ProgramsLoadingProviderProps) => {
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  const value = useMemo(() => ({ isProgramsLoading, setIsProgramsLoading }), [isProgramsLoading]);

  return (
    <ProgramsLoadingContext.Provider value={value}>{children}</ProgramsLoadingContext.Provider>
  );
};

export const useProgramsLoading = (): ProgramsLoadingContextType => {
  const context = useContext(ProgramsLoadingContext);
  if (!context) {
    throw new Error("useProgramsLoading must be used within a ProgramsLoadingProvider");
  }
  return context;
};
