/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from "react";
import { camelCase } from "lodash";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import { CameraAlt } from "@mui/icons-material";
import { Box, Button, List } from "@mui/material";
import ImageInformation from "./ImageInformation";
import WebcamCapture from "./webcam/webcam-widget";
import {
  ADD_IMAGE,
  REMOVE_IMAGE,
  SET_CONTEXT_DATA,
  SET_IS_IMAGES_LIST_SAVED,
} from "components/Video/hooks/usePatientStepsReducer/Actions";
import { useApp } from "util/AppContext";
import upload from "static/svg/upload.svg";

const ALLOWED_FILE_TYPES = "application/pdf, image/*, .pdf";

export default function UploadImages({
  contextType,
  contextID,
  upload_msg,
  imageType,
  allowMultipleFiles = true,
  isCameraUploadOnly = false,
}) {
  const app = useApp();
  const { imagesList } = app.patient_steps_state;
  const [openCamera, setOpenCamera] = useState("");
  const [stopMoreFiles, setStopMoreFiles] = useState(false);
  const webcamRef = React.useRef(null);
  const inputFileRef = useRef(null);

  useEffect(() => {
    app.patient_steps_dispatch({
      type: SET_CONTEXT_DATA,
      payload: { contextType, contextID },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextType, contextID]);

  useEffect(() => {
    if (!allowMultipleFiles && imagesList.length) {
      setStopMoreFiles(true);
    } else {
      setStopMoreFiles(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesList]);

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const calculateImageSize = (imgSrc) => {
    if (imgSrc) {
      const stringLength = imgSrc.length - "data:image/png;base64,".length;
      return Math.round(4 * Math.ceil(stringLength / 3) * 0.5624896334383812);
    }
    return 0;
  };

  const capture = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) {
      return;
    }

    const contentType = "image/png";
    const b64Data = imageSrc.split(",")[1];
    const blob = b64toBlob(b64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    const fileName = `${uuidv4().toString()}.png`;
    const file = dataURLtoFile(imageSrc, fileName);

    const imgObj = {
      currentFile: file,
      previewImage: blobUrl,
      name: fileName,
      size: calculateImageSize(blobUrl),
      capturedImage: true,
      type: "image/png",
      imageType,
      message: "",
      isError: false,
      imageInfos: [],
    };
    app.patient_steps_dispatch({ type: ADD_IMAGE, payload: imgObj });
    app.patient_steps_dispatch({ type: SET_IS_IMAGES_LIST_SAVED, payload: false });
  };

  const selectFile = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const image = e.target.files[i];
      const imgObj = {
        currentFile: image,
        previewImage: URL.createObjectURL(image),
        name: image.name,
        size: image.size,
        type: image.type,
        capturedImage: false,
        imageType,
        message: "",
        isError: false,
        imageInfos: [],
      };
      app.patient_steps_dispatch({ type: ADD_IMAGE, payload: imgObj });
      app.patient_steps_dispatch({ type: SET_IS_IMAGES_LIST_SAVED, payload: false });
    }
  };

  const removeImage = (index) => {
    app.patient_steps_dispatch({ type: REMOVE_IMAGE, payload: index });
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap="8px">
        <Button
          variant="outlined"
          color="primary"
          endIcon={<CameraAlt />}
          fullWidth
          size="large"
          onClick={(e) => {
            if (!stopMoreFiles) {
              setOpenCamera("user");
              e.stopPropagation();
            }
          }}
          disabled={stopMoreFiles}
          data-cy={camelCase(upload_msg)}
        >
          {upload_msg.toUpperCase()}
        </Button>

        {!isCameraUploadOnly && (
          <>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<img src={upload} alt="" />}
              fullWidth
              size="large"
              onClick={() => inputFileRef.current.click()}
              disabled={stopMoreFiles}
            >
              OR UPLOAD A PHOTO FROM YOUR DEVICE
            </Button>
            <input
              ref={inputFileRef}
              id="btn-upload"
              name="btn-upload"
              style={{ display: "none" }}
              type="file"
              multiple={allowMultipleFiles}
              value=""
              disabled={stopMoreFiles}
              accept={ALLOWED_FILE_TYPES}
              onChange={(e) => selectFile(e)}
            />
          </>
        )}
      </Box>

      {openCamera && (
        <WebcamCapture
          openCamera={openCamera}
          setOpenCamera={setOpenCamera}
          webcamRef={webcamRef}
          capture={capture}
        />
      )}

      <List>
        {imagesList?.map((imageInfo, index) => {
          return (
            <ImageInformation
              key={index}
              index={index}
              imageInfo={imageInfo}
              removeImage={removeImage}
            />
          );
        })}
      </List>
    </>
  );
}
