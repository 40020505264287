import React from "react";
import { Typography } from "@mui/material";
import { MobileNavigationLink } from "../MobileNavigationLink";
import { makeStyles } from "tss-react/mui";
import BannerButton from "../../../components/Mobile/BannerButton/BannerButton";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import CONSTANTS from "../constants";
import { epicUrl } from "util/constants/config";
import useTranslation from "../../../hooks/useTranslation";
import FindDoctorIcon from "../../../static/svg/mobile/finddoctor.svg";
import HospitalIcon from "../../../static/svg/mobile/hospital.svg";
import LabWorkIcon from "../../../static/svg/mobile/labwork.svg";
import PrimaryCareIcon from "../../../static/svg/mobile/primarycare.svg";
import UrgentCareIcon from "../../../static/svg/mobile/urgentcare.svg";

const useStyles = makeStyles()(() => ({
  title: {
    margin: "1em",
    fontSize: "1.5em",
    textAlign: "left",
    fontWeight: 600,
    lineHeight: 1.3,
    color: "#5D5D5D",
  },
}));

const Billing = () => {
  const { classes } = useStyles();
  const { mobile } = useTranslation();
  const {
    BillingTitle,
    HospitalOutpatient,
    PayAsGuest,
    PhysicianPractices,
    UrgentCareSpartaNewtonOrPhillipsburg,
    AdvancedUrgentCare,
    ImmediateCare,
    VisitingNurse,
    LaboratoryServices,
    Estimates,
  } = mobile;

  const appointmentLinks: Array<MobileNavigationLink> = [
    {
      destination: "myatlantic://openlogin",
      title: HospitalOutpatient,
      icon: HospitalIcon,
      testId: "appointments-view-hospital-outpatient-button",
      shouldPushNavigation: false,
    },
    {
      destination:
        "https://mychart.atlantichealth.org/MyChart/Billing/GuestPay/PayasGuest?mobile=1",
      title: PayAsGuest,
      icon: HospitalIcon,
      testId: "appointments-view-hospital-outpatient-button",
      shouldPushNavigation: false,
    },
    {
      destination: "https://mydocbill.com/pamg/login",
      title: PhysicianPractices,
      icon: FindDoctorIcon,
      testId: "appointments-physician-practices-button",
      shouldPushNavigation: false,
    },
    {
      destination: "myatlantic://openlogin",
      title: UrgentCareSpartaNewtonOrPhillipsburg,
      icon: UrgentCareIcon,
      testId: "appointments-urgent-care-button",
      shouldPushNavigation: false,
    },
    {
      destination: "https://mydocbill.com/aauc",
      title: AdvancedUrgentCare,
      icon: UrgentCareIcon,
      testId: "appointments-advanced-urgent-care-button",
      shouldPushNavigation: false,
    },
    {
      destination: "https://pay.instamed.com/Form/PaymentPortal/Default?id=ucp.icmw",
      title: ImmediateCare,
      icon: UrgentCareIcon,
      testId: "appointments-immediate-care-button",
      shouldPushNavigation: false,
    },
    {
      destination: "tel:973-379-8450",
      title: VisitingNurse,
      icon: PrimaryCareIcon,
      testId: "appointments-visiting-nurse-button",
      shouldPushNavigation: false,
    },
    {
      destination: "myatlantic://openlogin",
      title: LaboratoryServices,
      icon: LabWorkIcon,
      testId: "appointments-laboratory-services-button",
      shouldPushNavigation: false,
    },
    {
      destination: `${epicUrl}/GuestEstimates/AcceptDisclaimer?svcArea=WP-24K-2Bovoknll3BnGijBz-2FP8VA-3D-3D-24ebo2uqq0EjOuRxJqYewlr4Qvheo-2Bea3EkLCA8RnYDNY-3D&isMultiSA=false`,
      title: Estimates,
      icon: LabWorkIcon,
      testId: "appointments-estimates-button",
      shouldPushNavigation: false,
    },
  ];

  return (
    <PageContainer>
      <Typography className={classes.title}>{BillingTitle}</Typography>
      {appointmentLinks.map(({ title, icon, destination, testId, shouldPushNavigation }) => (
        <BannerButton
          key={title}
          title={title}
          icon={icon}
          navigateTo={destination as string}
          navigateFrom={CONSTANTS.BILLING}
          showRightArrow
          border="orange"
          testId={testId}
          shouldPushNavigation={shouldPushNavigation}
        />
      ))}
    </PageContainer>
  );
};
export default Billing;
