import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { useDeleteSpecimen } from "hooks/specimenTracking/useDeleteSpecimen/useDeleteSpecimen";
import { useGetSpecimenDeleteReasons } from "hooks/specimenTracking/useGetSpecimenDeleteReasons/useGetSpecimentDeleteReasons";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { Props } from "./types";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    textAlign: "center",
    width: "100%",
  },
  "& .MuiDialogContent-root p": {
    marginTop: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .MuiDialogActions-root > button: first-child": {},
  "& .MuiDialogActions-root button": {
    marginTop: theme.spacing(2),
  },
}));

const DeleteSpecimenModal = ({
  open,
  onClose,
  onCancel,
  specimenDetail,
  navigateTo = undefined,
}: Props) => {
  const { addError, addInfoMsg } = useApp();
  const {
    web: {
      common: { cancel },
      specimenTracking: { confirmDeleteSpecimenTitle, confirmDeleteSpecimenMsg },
    },
  } = useTranslation();

  const { deleteSpecimen } = useDeleteSpecimen();
  const { data: dataReasons } = useGetSpecimenDeleteReasons();
  const navigate = useNavigate();

  const [reason, setReason] = useState<string | undefined>(undefined);
  const [justification, setJustification] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const cleanModal = () => {
    setReason(undefined);
    setJustification(null);
    setError(null);
  };

  const handleClose = () => {
    cleanModal();
    onClose();
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  const handleCancel = () => {
    cleanModal();
    onCancel();
  };

  const handleDeleteSpecimen = async () => {
    if (reason) {
      try {
        await deleteSpecimen(specimenDetail.id, reason, justification);
        addInfoMsg("Specimen has been deleted successfully.");
        handleClose();
      } catch {
        addError("Problem deleting specimen, please contact support.");
      }
    } else {
      setError("Please select a reason for deletion.");
    }
  };

  const handleChangeOption = (option) => {
    setError(null);
    setReason(option.target.value);
  };

  const handleJustificationChange = (event) => {
    setJustification(event.target.value);
  };

  return (
    <CustomDialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      data-testid="Dialog"
    >
      <DialogTitle id="confirmation-dialog-title">{confirmDeleteSpecimenTitle}</DialogTitle>
      <DialogContent dividers>
        <b>Are you sure you want to delete specimen '{specimenDetail.specimenExternalId}'?</b>
        <p>{confirmDeleteSpecimenMsg}</p>

        <FormControl fullWidth>
          <InputLabel id="reason-select">Reason for deletion</InputLabel>
          <Select
            labelId="reason-select"
            id="demo-simple-select"
            value={reason}
            label="Reason for deletion"
            onChange={handleChangeOption}
            required
          >
            {dataReasons?.map(({ value, label }) => {
              return (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
        {reason === "OTHER" && (
          <TextField
            style={{ marginTop: "10px" }}
            id="filled-basic"
            label="Explain deletion (optional)"
            variant="filled"
            fullWidth
            onChange={handleJustificationChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" variant="contained" fullWidth>
          {cancel}
        </Button>
        <Button
          onClick={handleDeleteSpecimen}
          variant="outlined"
          color="primary"
          data-testid="onOk"
          fullWidth
        >
          <span>Confirm Delete</span>
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default DeleteSpecimenModal;
