import React from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
} from "@mui/material";
import VideoVisitInvite from "./VideoVisitInvite";
import { withStyles } from "tss-react/mui";
import { useIsMobile } from "util/deviceUtils";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    margin: 0,
  },
});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      padding: "21px",
    },
  },
}));

const SendInviteDialog = ({
  open,
  handleClose,
  defaultCellPhone = "",
  isVirtualVisitCreated = false,
  isAdhocSession = false,
}) => {
  const isMobile = useIsMobile();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={isMobile}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Send Virtual Visit Invite
      </DialogTitle>
      <DialogContent dividers>
        <VideoVisitInvite
          defaultCellPhone={defaultCellPhone}
          handleClose={handleClose}
          isVirtualVisitCreated={isVirtualVisitCreated}
          isAdhocSession={isAdhocSession}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SendInviteDialog;
