import React from "react";
import Grid from "@mui/material/Grid";
import VaccineAuth from "../components/LoginWithAtlanticHealth/VaccineAuth";

const VaccineOAuthView = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="space-between">
      <Grid item xs={11} md={10} lg={10}>
        <VaccineAuth />
      </Grid>
    </Grid>
  );
};

export default VaccineOAuthView;
