import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Grid } from "@mui/material";
import { getTitlesByRedirect } from "./helpers";
import { useQuery } from "hooks/useQuery";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import useStyles from "./FamilySeletion.styles";
import addCircle from "static/svg/add_circle.svg";
import avatar from "static/svg/default_avatar.svg";

const FamilySelection = () => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const auth = useAuth();
  const app = useApp();
  const query = useQuery();

  const [members, setMembers] = React.useState([]);
  const [userSelf, setUserSelf] = React.useState();

  const { redirectPath } = useParams();
  const redirect = query.get("r");
  const program = query.get("program");
  const { title, subtitle } = getTitlesByRedirect(redirect);
  const loadFamily = async () => {
    try {
      const uri = "/api/s/load_user_relationships";
      const { data } = await axiosClient.get(uri);
      setMembers(data.user_rels);
      setUserSelf(data.logged_in_user);
    } catch (error) {
      app.addError("Error: Failed to load family members. Please try again or contact support.");
    }
  };

  useEffect(() => {
    loadFamily();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMemberCardClick = async (related_user_id) => {
    try {
      const uri = "/api/s/select-proxy-user";
      const { data } = await axiosClient.post(uri, { proxyUserId: related_user_id });
      auth.setUserDirectly(data);
      app.addInfoMsg(`Using patient ${data?.firstName || data?.cellPhone}`);
      let redirectFinal = "/home";
      if (redirect && redirect.length > 0) {
        redirectFinal = `/${redirect}`;
      } else if (redirectPath && redirectPath.length > 0) {
        redirectFinal = `/${redirectPath}`;
      }
      navigate(`${redirectFinal}?from-ps=true&program=${program}`);
    } catch (error) {
      app.addError("Error: Failed to select. Please try again or contact support.");
      console.error(error);
    }
  };

  const onAddMemberClick = () => {
    navigate(`/family-member/create`);
  };

  const onEditIconClick = (user_id) => {
    navigate(`/family-member/update/${user_id}`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      <Grid className={classes.grid} container justifyContent="center">
        {userSelf && (
          <div
            className={classes.gridItem}
            key={userSelf.id}
            onClick={() => {
              onMemberCardClick(userSelf.id);
            }}
          >
            <div
              className={cx({
                [classes.avatarContainer]: true,
                [classes.defaultAvatarContainer]: !userSelf.profile_picture.url,
              })}
            >
              <div
                className={classes.iconContainer}
                onClick={(e) => {
                  e.stopPropagation();
                  onEditIconClick(userSelf.id);
                }}
              >
                <EditIcon fontSize="small" />
              </div>
              <img
                className={cx({
                  [classes.defaultAvatar]: !userSelf.profile_picture.url,
                  [classes.userAvatar]: userSelf.profile_picture.url,
                })}
                src={userSelf.profile_picture.url ? userSelf.profile_picture.url : avatar}
                alt="Avatar"
              />
            </div>
            <div className={classes.editIconContainer}>
              <div className={classes.avatarName}>
                {userSelf.first_name
                  ? `${userSelf.first_name} ${userSelf.last_name} (Self)`
                  : `+1${userSelf?.cell_phone || ""} (Self)`}
              </div>
            </div>
          </div>
        )}
        {members &&
          !!members.length &&
          members.map((member) => (
            <div className={classes.gridItem} key={member.id}>
              <div
                className={cx({
                  [classes.avatarContainer]: true,
                  [classes.defaultAvatarContainer]: !member.profile_picture.url,
                })}
                onClick={() => {
                  onMemberCardClick(member.id);
                }}
              >
                <div
                  className={classes.iconContainer}
                  onClick={(e) => {
                    onEditIconClick(member.id);
                    e.stopPropagation();
                  }}
                >
                  <EditIcon fontSize="small" />
                </div>
                <img
                  className={cx({
                    [classes.defaultAvatar]: !member.profile_picture.url,
                    [classes.userAvatar]: member.profile_picture.url,
                  })}
                  src={member.profile_picture.url ? member.profile_picture.url : avatar}
                  alt="Avatar"
                />
              </div>
              <div className={classes.editIconContainer}>
                <div className={classes.avatarName}>
                  {member.first_name} {member.last_name}
                </div>
              </div>
            </div>
          ))}
        <div onClick={onAddMemberClick} className={classes.gridItem}>
          <div className={classes.defaultAvatarContainer}>
            <img className={classes.defaultAvatar} src={addCircle} alt="add" />
          </div>
          <div className={classes.editIconContainer}>
            <div className={classes.avatarName}>Add Family Member</div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default FamilySelection;
