import moment, { Moment } from "moment";

export const buildDatesArray = (days) => {
  const result: Array<Moment> = [];
  for (let i = 0; i < days; i++) {
    result.push(moment().add(i, "d"));
  }
  return result;
};

export const mockTimeSlotInfo = {
  days: [
    {
      date: "Jun 13",
      timeSlots: ["9:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "2:00 PM"],
    },
    {
      date: "Jun 14",
      timeSlots: ["9:00 AM", "10:00 AM", null, "12:00 PM", "2:00 PM"],
    },
    {
      date: "Jun 15",
      timeSlots: ["9:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "2:00 PM"],
    },
    {
      date: "Jun 16",
      timeSlots: ["9:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "2:00 PM"],
    },
    {
      date: "Jun 17",
      timeSlots: [],
    },
  ],
};
