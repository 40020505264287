/** @format */
import React from "react";
import { useTimeLineHeaderStyles } from "./styles";

const TimeLineHeader = ({ productName, providerName }) => {
  const { classes } = useTimeLineHeaderStyles();
  return (
    <div>
      <div className={classes.timeLineHeader}>
        <a
          href="https://www.cdc.gov/vaccines/covid-19/index.html"
          target="_blank"
          rel="noreferrer"
          className="normal-link"
        >
          COVID-19 Vaccine
        </a>
      </div>
      <div style={{ marginLeft: "-13px", marginBottom: 0, marginTop: 15 }}>
        <div className={classes.subtitle}>manufacturer</div>
        <div className={classes.title}>{productName}</div>
        <br />
        <div className={classes.subtitle}>provider</div>
        <div className={classes.title}>
          {providerName === "Atlantic Health System" && (
            <span>
              <a href="https://www.atlantichealth.org/" target="_blank" rel="noreferrer">
                Atlantic Health System
              </a>
            </span>
          )}
          {providerName === "NJ Immunization Registry" && (
            <span>
              <a href="https://njiis.nj.gov/" target="_blank" rel="noreferrer">
                NJ Registry Sourced
              </a>
            </span>
          )}
        </div>
      </div>
      <br />
    </div>
  );
};

export default TimeLineHeader;
