import * as Yup from "yup";

export const NewLocationSchema = Yup.object().shape({
  name: Yup.string().required("Location Name is required"),
  pageTitle: Yup.string().required("Location page title is required"),
  locationType: Yup.string().required("Location type is required"),
  streetAddress1: Yup.string().required("Street Address 1 is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  phone: Yup.string().required("Phone is required").min(10, "Phone must be 10 digit"),
  zip: Yup.string().required("Zip Code is required").min(5, "Zip code must be 5 digit"),
});
