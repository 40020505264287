import React from "react";
import moment from "moment";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { buildDatesArray } from "./helpers";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: 16,
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  halfWidth: {
    width: "55%",
  },
  dayList: {
    display: "flex",
    flexDirection: "row",
    gap: 6,
  },
  dayContainer: {
    display: "flex",
    flexDirection: "column",
    width: 64,
  },
  selectedDayText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

const TimeSlotPickerHeader = ({ amountOfDaysToShow }) => {
  const { classes, cx } = useStyles();
  const momentArrayOfDays = buildDatesArray(amountOfDaysToShow);

  const getPreviousWeek = () => {};

  const getNextWeek = () => {};

  return (
    <Box className={classes.container}>
      <div className={classes.halfWidth} />
      <div className={classes.content}>
        <IconButton color="primary" onClick={getPreviousWeek} disabled size="large">
          <ArrowBackIos fontSize="small" />
        </IconButton>
        <div className={classes.dayList}>
          {momentArrayOfDays.map((momentDate) => {
            const isSelected = momentDate.isSame(moment());
            return (
              <div className={classes.dayContainer} key={momentDate.format("MMM DD")}>
                <Typography variant="body2" className={cx(isSelected && classes.selectedDayText)}>
                  {momentDate.format("dddd")}
                </Typography>
                <Typography variant="body2" className={cx(isSelected && classes.selectedDayText)}>
                  {momentDate.format("MMM DD")}
                </Typography>
              </div>
            );
          })}
        </div>
        <IconButton color="primary" onClick={getNextWeek} data-cy="nextBtn" disabled size="large">
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </div>
    </Box>
  );
};

export default TimeSlotPickerHeader;
