import React, { FC } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import useTranslation from "hooks/useTranslation";
import useStyles from "./style";

interface Props {
  isShown: boolean;
  goBack: () => void;
  text?: string;
}

const WarningModal: FC<Props> = ({
  isShown,
  goBack,
  text = "Something went wrong. Please contact support.",
}) => {
  const { classes } = useStyles();

  const {
    web: {
      common: { back, warning },
    },
  } = useTranslation();

  return (
    <Dialog onClose={goBack} open={isShown}>
      <DialogTitle className={classes.warning}>
        <WarningIcon className={classes.img} />
        <Typography align="center" variant="h4" className={classes.textWarning}>
          {warning}!
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.top}>
        <Typography align="center" variant="body1" className={classes.text}>
          {text}
        </Typography>
        <DialogActions className={classes.back}>
          <Button onClick={goBack} className={classes.button}>
            {back.toUpperCase()}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default WarningModal;
