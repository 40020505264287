import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { getDoctorsCoordinates } from "views/NewAppointment/helpers";
import GoogleMap from "components/shared/Map";
import useStyles from "./DoctorInfo.styles";

const DoctorLocation = ({ doctor }) => {
  const { classes } = useStyles();
  const [markerConfig, setMarkerConfig] = useState([]);

  useEffect(() => {
    const coordinates = getDoctorsCoordinates([doctor]);
    setMarkerConfig(coordinates);
  }, [doctor]);

  return (
    <Card className={classes.appCard} elevation={8}>
      <GoogleMap
        containerStyle={{
          width: "100%",
          height: "500px",
        }}
        markers={markerConfig}
      />
    </Card>
  );
};

export default DoctorLocation;
