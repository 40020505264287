import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { states } from "util/constants/usa_states";
import useTranslation from "hooks/useTranslation";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

function validateStatus(status) {
  if (status >= 200 && status < 300) {
    // success
    return true;
  }
  if (status >= 400 && status < 500) {
    // 409 or some other kind of error we should indicate to the user
    return true;
  }
  return false;
}
const createHandler = (values) =>
  axiosClient.post(API_ENDPOINTS.dotMedicalExaminers, values, {
    headers: { "Content-Type": "application/json" },
    validateStatus,
  });
const updateHandler = (values) =>
  axiosClient.patch(`${API_ENDPOINTS.dotMedicalExaminers}/${values.medicalExaminerId}`, values, {
    headers: { "Content-Type": "application/json" },
    validateStatus,
  });
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  NPI: Yup.string()
    .min(10, "10-digit NPI")
    .max(10, "10-digit NPI")
    .required("Required")
    .test((s) => !Number.isNaN(Number(s))),
  locId: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  providerSpecialty: Yup.string(),
  nationalRegistryNumber: Yup.string()
    .min(10, "10-digit FMCSA registry number")
    .max(10, "10-digit FMCSA registry number")
    .required("Required"),
  certificationNumber: Yup.string().required("Required"),
  issuingState: Yup.string().required("Required"),
});

interface ExaminerData {
  email: string;
  NPI: string;
  locId: string;
  firstName: string;
  lastName: string;
  providerSpecialty?: string;
  nationalRegistryNumber: string;
  certificationNumber: string;
  issuingState: string;
}

const ExaminerForm = (props: FormikProps<ExaminerData & { submit: string }>) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setErrors,
    setValues,
    status: { editing, onClose },
    touched,
    values,
  } = props;
  const {
    web: {
      common: commonCopy,
      dotForm: {
        staff: {
          medicalExaminers: { autoFill, inputLabels: label, npiNotFoundError },
        },
      },
    },
  } = useTranslation();

  const helperText = (prop, defaultText = !editing ? autoFill : "") => {
    return !touched[prop] ? defaultText : errors?.[prop] ?? defaultText;
  };

  const hasError = (prop) => !!errors[prop] && !!touched[prop];

  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    setFetched(false);
  }, [values.NPI]);

  // when the NPI becomes valid, send the request to get provider details
  // if the NPI is unrecognized, set an error state and disable the submission button
  // if the NPI is recognized, set the provider details and enable the submission button
  useEffect(() => {
    let mounted = true;

    if (!!values?.NPI && !errors?.NPI && !fetched) {
      // implies the NPI is valid
      setFetched(true);
      axiosClient
        .get(API_ENDPOINTS.dotGetProviderByNpi, { params: { NPI: values.NPI } })
        .then((res) => {
          if (!mounted) {
            return;
          }
          if (!res.data?.NPI) {
            setErrors({ NPI: npiNotFoundError });
            return;
          }
          setValues({
            ...values,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            providerSpecialty: res.data.providerSpecialty,
          });
        });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, errors.NPI, setErrors, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>{editing ? commonCopy.update : commonCopy.add} Medical Examiner</DialogTitle>
      <DialogContent>
        <Grid container flexDirection="column" alignItems="stretch" gap={2} sx={{ minWidth: 4 }}>
          <TextField
            variant="outlined"
            name="email"
            label={label.email}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={editing || isSubmitting}
            error={hasError("email")}
            helperText={helperText("email", "")}
            id="ex-email"
            InputLabelProps={{ htmlFor: "ex-email" }}
          />
          <TextField
            variant="outlined"
            name="NPI"
            label={label.NPI}
            value={values.NPI}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={editing || isSubmitting}
            error={hasError("NPI")}
            helperText={helperText("NPI", "")}
            id="ex-npi"
            InputLabelProps={{ htmlFor: "ex-npi" }}
          />
          <FormControl variant="outlined" error={hasError("locId")}>
            <InputLabel id="locationNameLabel">{label.officeLocation}</InputLabel>
            <Select
              id="locId"
              name="locId"
              labelId="locationNameLabel"
              label={label.officeLocation}
              value={values.locId}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            >
              <MenuItem value="loc-797ecc31d3">Chilton Medical Center</MenuItem>
            </Select>
            {hasError("locId") ? <FormHelperText>{errors.locId}</FormHelperText> : null}
          </FormControl>
          <TextField
            variant="outlined"
            name="firstName"
            label={label.firstName}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            error={hasError("firstName")}
            helperText={helperText("firstName")}
            id="ex-first-name"
            InputLabelProps={{ htmlFor: "ex-first-name" }}
          />
          <TextField
            variant="outlined"
            name="lastName"
            label={label.lastName}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            error={hasError("lastName")}
            helperText={helperText("lastName")}
            id="ex-last-name"
            InputLabelProps={{ htmlFor: "ex-last-name" }}
          />
          <TextField
            variant="outlined"
            name="providerSpecialty"
            label={label.providerSpecialty}
            value={values.providerSpecialty}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            error={hasError("providerSpecialty")}
            helperText={helperText("providerSpecialty")}
            id="ex-provider-specialty"
            InputLabelProps={{ htmlFor: "ex-provider-specialty" }}
          />
          <TextField
            variant="outlined"
            name="nationalRegistryNumber"
            label={label.nationalRegistryNumber}
            value={values.nationalRegistryNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            error={hasError("nationalRegistryNumber")}
            helperText={helperText("nationalRegistryNumber", "")}
            id="ex-national-registry-number"
            InputLabelProps={{ htmlFor: "ex-national-registry-number" }}
          />
          <TextField
            variant="outlined"
            name="certificationNumber"
            label={label.certificationNumber}
            value={values.certificationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            error={hasError("certificationNumber")}
            helperText={helperText("certificationNumber", "")}
            id="ex-certification-number"
            InputLabelProps={{ htmlFor: "ex-certification-number" }}
          />
          <FormControl variant="outlined" error={hasError("issuingState")}>
            <InputLabel id="issuingStateLabel">{label.issuingState}</InputLabel>
            <Select
              name="issuingState"
              labelId="issuingStateLabel"
              label={label.issuingState}
              value={values.issuingState}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={hasError("issuingState")}
              id="ex-issuing-state"
            >
              {states.map(([code, name]) => (
                <MenuItem key={code} value={code}>
                  {`${code} - ${name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {errors.submit && <FormHelperText error>{errors.submit}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !!Object.values(errors).length}
        >
          {editing ? commonCopy.save : commonCopy.add}
        </Button>
      </DialogActions>
    </form>
  );
};

/**
 * @description AddExaminerModal - if the initialValues object has an id, the modal will be in edit mode and submission will send a PATCH.
 */
const ExaminerModal = (props) => {
  const { initialValues, editing = false, onClose, open, onAfterSubmit } = props;

  const handleSubmit = async (values, actions) => {
    try {
      const handler = editing ? updateHandler : createHandler;
      const { locationName, username, userId, cellphone, ...restValues } = values;
      const toSubmit = {
        ...restValues,
        ...(cellphone && { cellphone }),
      };
      const result = await handler(toSubmit);
      if (result?.data?.description) {
        actions.setErrors({ submit: result.data.description });
      } else {
        onClose();
      }
    } catch (err) {
      console.error(err);
      actions.setErrors({ submit: "Error during submission" });
    } finally {
      onAfterSubmit();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={ExaminerForm}
        initialStatus={{ editing, onClose }} // I don't know how else to pass these props down...
      />
    </Dialog>
  );
};

export default ExaminerModal;
