import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  itemContainer: {
    display: "flex",
    textAlign: "left",
    borderBottom: "solid 1px rgba(0, 0, 0, 0.06)",
    padding: "14px 16px 19px 16px",
  },
  userName: {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Alegreya Sans",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "Alegreya Sans",
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: "5px",
  },
  imageIcon: {
    marginRight: "12px",
  },
}));

export default useStyles;
