import React, { useEffect, useRef, useState } from "react";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Conversation } from "@twilio/conversations";
import FileAttachmentIcon from "../../../icons/FileAttachmentIcon";
import SendMessageIcon from "../../../icons/SendMessageIcon";
import Snackbar from "../../Snackbar/Snackbar";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import { useSendStaffMessage } from "../../../hooks/useSendStaffMessage/useSendStaffMessage";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useChatInputStyles } from "./useChatInputStyles";
import { useApp } from "../../../../../util/AppContext";
import { useAuth } from "../../../../../util/Security";
import { UserTypes, axiosClient } from "../../../../../util/api_helper";
import { useIsMobile } from "util/deviceUtils";

interface ChatInputProps {
  conversation: Conversation;
  isChatWindowOpen: boolean;
  vsId?: number | null;
  handleGetMessages?: () => void;
}

type Phrase = { phrase: string; id: number };

const ALLOWED_FILE_TYPES = "application/pdf, image/*, .pdf";

export default function ChatInput({
  conversation,
  isChatWindowOpen,
  vsId = null,
  handleGetMessages,
}: ChatInputProps) {
  const { classes, cx } = useChatInputStyles();
  const auth = useAuth();
  const { mutate: sendMessage } = useSendStaffMessage();
  const [messageBody, setMessageBody] = useState("");
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  const isValidMessage = /\S/.test(messageBody);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [isReschheduleBtnDisabled, setIsReschheduleBtnDisabled] = useState<boolean>(false);
  const [quickPhrase, setQuickPhrase] = useState<Phrase | null>(null);
  const [quickPhrasesList, setQuickPhrasesList] = useState<Phrase[]>([]);
  const app = useApp();
  const { room } = useVideoContext();
  const isMobile = useIsMobile();
  const { connect: chatConnect, convRecvd, setLoading, loading } = useChatContext();
  const isPatient =
    auth.user.userType === UserTypes.PATIENT || auth.user.userType === UserTypes.CONSUMER;
  const {
    vs_visits_state: { currentVirtualVisit },
  } = app;
  const isConsultation = currentVirtualVisit?.type === "consultation";

  const getNewToken = async () => {
    try {
      const url = `/api/s/console/virtualvisit/sessions/${vsId}/outroom-token`;
      const { data } = await axiosClient.get(url);
      chatConnect(data?.token);
    } catch (error) {
      console.error(error);
      app.addError("Problem Joining Virtual Session.");
      setLoading(false);
    }
  };

  const handleSendQuickPhrase = async (id: number) => {
    try {
      const url = `/api/s/console/virtualvisit/sessions/${
        currentVirtualVisit?.virtual_session_id || currentVirtualVisit?.id
      }/messages`;
      await axiosClient.post(url, { quickPhraseId: id });
      setQuickPhrase(null);
      setMessageBody("");
    } catch (err) {
      // @ts-ignore
      app.addError(err.response.data.message || "Something went wrong!");
    }
  };

  const handleSendMessage = (message: string) => {
    if (quickPhrase) {
      handleSendQuickPhrase(quickPhrase?.id);
      return;
    }
    if (isValidMessage) {
      conversation?.sendMessage(message.trim());
      setMessageBody("");
    }
  };

  const handleSendRescheduleLink = async () => {
    try {
      setIsReschheduleBtnDisabled(true);
      const url = `/api/s/console/virtualvisit/sessions/${currentVirtualVisit?.id}/time-slots/selected`;
      await axiosClient.delete(url);
      if (handleGetMessages) {
        handleGetMessages();
      }
      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...app.vs_visits_state.currentVirtualVisit,
          requested_start_beg: null,
        },
      });
    } catch (err) {
      // @ts-ignore
      app.addError(err.response.data.description);
    }
  };

  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      textInputRef.current?.focus();
    }
  }, [isChatWindowOpen]);

  useEffect(() => {
    async function getQuickPhrases() {
      try {
        const url =
          "/api/s/console/virtualvisit/quick_phrases/list-by-context?context=ConsultationRequest";
        const { data } = await axiosClient.get(url);
        setQuickPhrasesList(data?.data);
      } catch (error) {
        console.error(error);
        app.addError("Problem getting quick Phrases.");
        setLoading(false);
      }
    }
    if (!isPatient && (currentVirtualVisit?.virtual_session_id || currentVirtualVisit?.id)) {
      getQuickPhrases();
    }
    // eslint-disable-next-line
  }, [currentVirtualVisit, isPatient]);

  useEffect(() => {
    setIsReschheduleBtnDisabled(!currentVirtualVisit?.requested_start_beg);
  }, [currentVirtualVisit?.requested_start_beg]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(event.target.value);
    if (!event.target.value) {
      setQuickPhrase(null);
    }
  };

  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (isPatient || room) {
        handleSendMessage(messageBody);
        return;
      }
      if (!convRecvd) {
        setLoading(true);
        sendMessage({ msg: messageBody.trim() });
        getNewToken();
      } else {
        handleSendMessage(messageBody);
      }
    }
  };

  useEffect(() => {
    if (convRecvd && !isPatient) {
      handleSendMessage(messageBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convRecvd]);

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("userfile", file);
      setIsSendingFile(true);
      setFileSendError(null);
      if (conversation) {
        conversation
          ?.sendMessage(formData)
          .catch((e) => {
            if (e.code === 413) {
              setFileSendError("File size is too large. Maximum file size is 150MB.");
            } else {
              setFileSendError("There was a problem uploading the file. Please try again.");
            }
            console.log("Problem sending file: ", e);
          })
          .finally(() => {
            setIsSendingFile(false);
          });
      }
    }
  };

  const onPhraseSelected = (e) => {
    const { value } = e.target;
    const selectedPhrase: Phrase | null =
      quickPhrasesList.find((item: Phrase) => item?.id === value) || null;
    setQuickPhrase(selectedPhrase);
    // @ts-nocheck
    setMessageBody(selectedPhrase?.phrase || "");
  };

  return (
    <div className={classes.chatInputContainer}>
      <Snackbar
        open={Boolean(fileSendError)}
        headline="Error"
        message={fileSendError || ""}
        variant="error"
        handleClose={() => setFileSendError(null)}
      />
      {!isPatient && (
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="quick-phrases-label">Quick Phrases</InputLabel>
          <Select
            variant="standard"
            labelId="quick-phrases-label"
            value={quickPhrase?.id || ""}
            id="phrases-bucket-dropdown"
            data-testid="quickPhrases-select"
            placeholder="Select a quick Phrase"
            data-cy="quickPhrases"
            autoWidth={false}
            onChange={onPhraseSelected}
          >
            {quickPhrasesList &&
              quickPhrasesList.map((item: { phrase: string; id: number }) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={classes.quickPhrasesMenuItem}
                  >
                    {item?.phrase}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}

      <div
        className={cx(classes.textAreaContainer, {
          [classes.isTextareaFocused]: isTextareaFocused,
        })}
      >
        {/* 
        Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
        This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
        component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
        */}
        <TextareaAutosize
          minRows={1}
          maxRows={3}
          className={classes.textArea}
          aria-label="chat input"
          placeholder="Write a message..."
          onKeyPress={handleReturnKeyPress}
          onChange={handleChange}
          value={messageBody}
          data-cy-chat-input
          ref={textInputRef}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => setIsTextareaFocused(false)}
        />
      </div>
      <Grid container alignItems="flex-end" justifyContent="flex-end" wrap="nowrap">
        {/* Since the file input element is invisible, we can hardcode an empty string as its value.
        This allows users to upload the same file multiple times. */}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleSendFile}
          value=""
          accept={ALLOWED_FILE_TYPES}
        />
        <div className={classes.buttonContainer}>
          <div className={classes.fileButtonContainer}>
            {!isPatient && isConsultation && (
              <Tooltip title="Reschedule visit">
                <Button
                  data-cy="RescheduleVisit"
                  className={classes.button}
                  onClick={handleSendRescheduleLink}
                  disabled={isReschheduleBtnDisabled}
                >
                  <EventNoteOutlinedIcon
                    className={
                      isReschheduleBtnDisabled ? classes.sendIconDisabled : classes.sendIcon
                    }
                  />
                </Button>
              </Tooltip>
            )}
            <Button
              className={classes.button}
              onClick={() => fileInputRef.current?.click()}
              disabled={isSendingFile}
            >
              <FileAttachmentIcon />
            </Button>
            {isSendingFile && (
              <CircularProgress size={24} className={classes.fileButtonLoadingSpinner} />
            )}
          </div>

          <Button
            className={classes.button}
            onClick={() => {
              if (isPatient || room) {
                handleSendMessage(messageBody);
                return;
              }

              if (!convRecvd) {
                setLoading(true);
                sendMessage({ msg: messageBody.trim() });
                getNewToken();
              } else {
                handleSendMessage(messageBody);
              }
            }}
            color="primary"
            variant="contained"
            disabled={!isValidMessage || loading}
            data-testid="send-message-button"
          >
            <SendMessageIcon />
          </Button>
        </div>
      </Grid>
    </div>
  );
}
