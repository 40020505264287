import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Button, Paper, Typography } from "@mui/material";
import ErrorModal from "components/ErrorBoundary/ErrorModal";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import RoutesHelper from "util/routesHelper";
import useStyles from "../../styles/Authorization/authorization.styles";

const TIMER = 180; // In second
const MAX_ATTEMPS = 4;
const EXPIRED_TIMER = 180000;

const CodeVerification = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const app = useApp();
  const { setUserDirectly } = useAuth();
  const { redirectHelper } = RoutesHelper();

  const isMobile = useIsMobile();
  const params = new URLSearchParams(window.location.search);
  const cellPhone = params.get("cell_phone");
  const authCode = params.get("auth_code");
  const search = params.get("search");

  const isCellPhoneVerified = !!authCode;

  const [verificationCode, setVerificationCode] = useState(authCode || "");
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [attemps, setAttemps] = useState(0);
  const [showErrorLoginModal, setShowErrorLoginModal] = useState(false);
  const [resendTimer, setResendTimer] = useState(TIMER);
  const [isCodeExpired, setIsCodeExpired] = useState(false);
  let expiredCodeInterval;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const codeRef = useRef<any>(null);

  const {
    web: {
      signinView: { cellphoneLoginMsgSent },
    },
  } = useTranslation();

  const startExpiredCodeInterval = () => {
    expiredCodeInterval = setTimeout(() => {
      setIsCodeExpired(true);
      app.addError(
        "Your one-time password has expired, please use the re-send option to request a new one",
      );
      clearInterval(expiredCodeInterval);
    }, EXPIRED_TIMER);
  };

  useEffect(() => {
    if (isResendDisabled) {
      setIsCodeExpired(false);
      const resendInterval = setInterval(() => {
        setResendTimer((resendTimer) => resendTimer - 1);
        if (resendTimer <= 1) {
          setIsResendDisabled(false);
          clearInterval(resendInterval);
        }
      }, 1000);
      return () => clearInterval(resendInterval);
    }

    if (!isResendDisabled) {
      startExpiredCodeInterval();
      return () => {
        clearTimeout(expiredCodeInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResendDisabled, setResendTimer, resendTimer]);

  const handleSmsLogin = async () => {
    clearInterval(expiredCodeInterval);
    setResendTimer(TIMER);
    setIsResendDisabled(true);
    const apiUrl = "/api/cellphonelogin";
    const localParams = { uiEmbedded: false, userContext: "" };
    if (app.isEmbedded()) {
      localParams.uiEmbedded = true;
    }
    const url = new URL(window.location.href);
    const uc = url.searchParams.get("userContext");
    if (uc && uc.length > 0) {
      localParams.userContext = uc;
    }

    try {
      await axiosClient.post(apiUrl, { cell_phone: cellPhone }, { params });
      app.addInfoMsg(cellphoneLoginMsgSent);
    } catch {
      app.addError("Failed to resend Text message, please contact support.");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { data } = await axiosClient.post("/api/sms/verify", {
        cellPhone,
        authCode: verificationCode,
      });

      setUserDirectly(data);

      if (!data.dob || (!(data.firstName || data.lastName) && data.patientExists)) {
        navigate(
          `/activation${window.location.search}&sync_demographic_info=true&auth_code=${verificationCode}`,
        );
        return;
      }

      redirectHelper(params, search || undefined, "/");
    } catch (e) {
      // @ts-ignore
      if (e?.response?.status === 401) {
        if (isCodeExpired) {
          return app.addError("Your code has expired. Please generate it again.");
        }
        app.addError("Your code is invalid. Please, try again.");
        // @ts-ignore
      } else if (e?.response?.status === 412) {
        app.addInfoMsg("Your device isn't trusted. Please, verify your device.");
        navigate(`/activation${window.location.search}&auth_code=${verificationCode}`);
      } else {
        app.addError("Something went wrong. Please, contact support.");
      }
      setAttemps((attemps) => attemps + 1);
      if (attemps + 1 >= MAX_ATTEMPS) {
        setShowErrorLoginModal(true);
      }
      // eslint-disable-next-line no-underscore-dangle
      codeRef?.current?.__clearvalues__();
    }
  };

  const handleCloseLoginModal = () => {
    setShowErrorLoginModal(false);
    window.location.replace("http://atlantichealth.org/");
  };

  return (
    <Paper elevation={!isMobile ? 24 : 0} className={classes.verificationPaper}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h1" className={classes.title}>
          Cell Phone Verification
        </Typography>
        <Typography variant="h2" className={classes.subtitle}>
          Please check your text message for the code to enter
        </Typography>
        <div data-cy="authCodeInput">
          <ReactCodeInput
            ref={codeRef}
            className={classes.codeInput}
            autoFocus
            fieldHeight={!isMobile ? 70 : 60}
            fieldWidth={!isMobile ? 70 : 50}
            values={verificationCode.split("") || ""}
            onChange={setVerificationCode}
            disabled={isCellPhoneVerified}
          />
        </div>
        {!isResendDisabled && (
          <Box display="flex" flexDirection="column">
            <Button
              color="primary"
              variant="text"
              type="button"
              onClick={() => handleSmsLogin()}
              disabled={isResendDisabled}
              data-testid="resendBtn"
            >
              <Typography variant="h3" className={classes.resendBtn}>
                <ReplayIcon />
                Resend Code
              </Typography>
            </Button>
            {isCodeExpired && (
              <Typography variant="h3" className={classes.resendBtn}>
                Your one time password <span>has expired</span>, please use the <br />
                re-send option to request a new one
              </Typography>
            )}
          </Box>
        )}
        {isResendDisabled && (
          <Typography variant="h2" data-testid="resendTimerCountdown" className={classes.subtitle}>
            The code has been sent, you can try again in {resendTimer} seconds
          </Typography>
        )}
        <Button
          className={classes.verifyBtn}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={verificationCode?.length !== 6 || isCellPhoneVerified}
          data-testid="verifyBtn"
          data-cy="verifyBtn"
        >
          Verify
        </Button>
      </form>
      {showErrorLoginModal && (
        <ErrorModal
          isShown={showErrorLoginModal}
          goBack={handleCloseLoginModal}
          text="We are sorry are having trouble logging in, Please contact support"
        />
      )}
    </Paper>
  );
};

export default CodeVerification;
