export enum ProgramType {
  Dot = "dot",
  VirtualSessionsQueue = "virtual_sessions_queue",
  InPatientCommunications = "in_patient_communications",
  ContactCenter = "contact_center",
  CareNavigation = "care_navigation",
  AdhocVirtual = "adhoc_virtual",
  Rpm = "rpm",
  WellnessProgram = "wellness_program",
  SpecimenTracking = "specimen_tracking",
  SpecialistOnDemand = "specialist_on_demand",
  Consultation = "consultation",
}
