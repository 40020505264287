import { makeStyles } from "tss-react/mui";

export const useConsultationStyles = makeStyles()((theme) => ({
  smallText: {
    fontSize: "14px",
  },
  innerContainer: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      margin: "auto",
    },
  },
  scheduledTitle: {
    fontSize: "20px",
    lineHeight: "32px",
    margin: "32px 0px",
    fontWeight: 600,
    color: theme.palette.common.black,
    fontFamily: "Alegreya Sans",
  },
  scheduledSubTitle: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Alegreya Sans",
    marginBottom: "24px",
  },
  emailText: {
    color: theme.palette.info.main,
  },
  boldText: {
    fontWeight: 600,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  homeButton: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "uppercase",
    width: "100%",
  },
  heading: {
    color: theme.palette.primary.main,
    fontFamily: "Alegreya Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    width: "100%",
    textAlign: "center",
    marginBottom: "25px",
  },
  locationTitle: {
    marginTop: "16px",
    marginBottom: "10px",
    fontWeight: 450,
  },
  pTHeading: {
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    width: "100%",
    marginBottom: "25px",
    textAlign: "left",
  },
  subTitle: {
    fontFamily: "Alegreya Sans",
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.text.main,
    marginBottom: "16px",
  },
  formControl: {
    width: "100%",
    fontFamily: "Alegreya Sans",
    marginBottom: "20px",
  },
  formControlContainer: {
    width: "100%",
  },
  label: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Alegreya Sans",
    fontSize: "16px",
    color: theme.palette.common.black,
    maxWidth: "calc(100% - 20px)",
  },
  sublabel: {
    backgroundColor: theme.palette.common.white,
    fontSize: "16px",
    color: theme.palette.common.black,
    maxWidth: "calc(100% - 20px)",
    textAlign: "left",
    marginBottom: "20px",
  },
  sublabelDiagnosis: {
    backgroundColor: "#ececec",
    fontSize: "16px",
    color: theme.palette.common.black,
    fontWeight: "bold",
    letterSpacing: ".5px",
    maxWidth: "calc(100% - 20px)",
    textAlign: "left",
    marginBottom: "20px",
    padding: "10px",
  },
  textLabel: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Alegreya Sans",
    fontSize: "16px",
    color: theme.palette.common.black,
    maxWidth: "calc(100% - 15px)",
  },
  consentLabel: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Alegreya Sans",
    fontSize: "16px",
    color: theme.palette.common.black,
    letterSpacing: "0.15px",
    lineHeight: "22px",
    fontWeight: "normal",
    margin: "10px 0px",
  },
  text: {
    fontSize: "16px",
    fontFamily: "Alegreya Sans",
    marginBottom: "25px",
    letterSpacing: "0.15px",
    color: theme.palette.common.black,
    textAlign: "center",
  },
  subTitleText: {
    fontSize: "16px",
    fontFamily: "Alegreya Sans",
    marginBottom: "25px",
    letterSpacing: "0.15px",
    color: theme.palette.common.black,
    textAlign: "center",
  },
  button: {
    width: "calc(50% - 10px)",
  },
  call: {
    fontWeight: "bold",
    fontSize: "20px",
    color: theme.palette.primary.main,
    fontFamily: "Alegreya Sans",
    marginTop: "16px",
  },
  reasonSelect: {
    textAlign: "left",
    "&:focus": {
      backgroundColor: theme.palette.common.white,
    },
  },
  radioFormControl: {
    textAlign: "left",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "0px 10px",
    marginBottom: "15px",
    boxSizing: "border-box",
    width: "100%",
  },
  scheduleHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    borderBottom: "1px solid #D4D4D4",
    backgroundColor: "rgba(4, 48, 112, 0.08)",
    padding: "12px 16px",
  },
  scheduleSubHeader: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    boxSizing: "border-box",
  },
  timeSlotActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "10px",
    width: "100%",
    boxSizing: "border-box",
    fontSize: "14px",
    lineHeight: "20px",
    margin: "2px auto",
    fontWeight: 500,
  },
  timeSlot: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
    padding: "10px",
    width: "80%",
    fontSize: "14px",
    margin: "10px auto",
    fontFamily: "Alegreya Sans",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  timeSlotSelected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 500,
  },
  slots: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  slotCard: {
    width: "50%",
  },
  nextDays: {
    padding: "7px",
  },
  paper: {
    marginTop: "10px",
    marginBottom: "50px",
  },
  calDatesHeader: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontFamily: "Alegreya Sans",
    fontSize: "14px",
    margin: 0,
  },
  calDates: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontFamily: "Alegreya Sans",
    fontSize: "14px",
    margin: "15px 0px",
  },
  noSlot: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "10px",
    color: theme.palette.primary.main,
    fontSize: "14px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    margin: "10px auto",
    width: "80%",
  },
  emailAlert: {
    fontFamily: "Alegreya Sans",
    fontSize: "24px",
    lineHeight: "29px",
    height: "70px",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "70px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
  },
  uploadLabel: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "16px",
    fontFamily: "Alegreya Sans",
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  callLabel: {
    fontFamily: "Alegreya Sans",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "10px",
  },
  locationList: {
    maxHeight: "50vh",
    overflow: "auto",
  },
}));
