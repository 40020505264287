import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { overwriteConfigSchema } from "views/Config/OverwriteConfigModal/overwriteConfigSchema";
import HolidaysEdit from "components/HolidaysEdit/HolidaysEdit";
import TimeSlotsOverwrite from "components/TimeSlotsOverwrite/TimeSlotsOverwrite";
import { useApp } from "util/AppContext";
import { axiosClient, getApiHost } from "util/api_helper";
import { convertAllObjectStringToDate } from "util/dateHelpers";
import { transformTimeSlotsToString } from "util/util_functions";

const useStyles = makeStyles()((theme) => ({
  container: {
    minWidth: 540,
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
    },
  },
  content: {
    padding: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function CustomizedDialogs({ show, setShow, record, onSave }) {
  const { classes } = useStyles();
  const { addError, addInfoMsg } = useApp();
  const host = getApiHost();

  const [open, setOpen] = useState(false);
  const [configID, setConfigID] = useState("");
  const [orgID, setOrgID] = useState("");
  const [orgKey, setOrgKey] = useState("");
  const [configName, setConfigName] = useState("");
  const [configJSON, setConfigJSON] = useState("");
  const [config, setConfig] = useState({});
  const [editMode, setEditMode] = useState("JSON");
  const [initialValues, setInitialValues] = useState({});
  const [holidays, setHolidays] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setShow(false);
    setConfigID("");
    setOrgID("");
    setOrgKey("");
    setConfigName("");
    setConfigJSON("");
    setEditMode("JSON");
    setInitialValues({});
    setHolidays([]);
    setConfig({});
  };
  const showModal = async () => {
    if (show) {
      setConfigID(record.id);
      setOrgID(record.orgId);
      setOrgKey(record.orgKey);
      setConfigName(record.name);
      setConfigJSON(JSON.stringify(record.json));
      setOpen(true);
      setConfig(record.json);

      if (record.json.holidays) {
        try {
          const { data } = await axiosClient.get(
            `${host}/api/s/console/config/${record.id}/holidays`,
          );
          setHolidays(data);
        } catch {
          addError("Problem with getting Holidays. Please contact support");
        }
      }

      if (record.json.virtual_sessions?.time_slots) {
        const timeSlotsInitValues = {
          capacity: record.json.virtual_sessions.time_slots.capacity,
          duration: record.json.virtual_sessions.time_slots.duration,
          working_hours: convertAllObjectStringToDate(
            record.json.virtual_sessions.time_slots.working_hours,
          ),
        };
        setInitialValues({ time_slots: timeSlotsInitValues });
      }
    } else {
      handleClose();
    }
  };

  const submitForm = async (values) => {
    const timeSlotsString = transformTimeSlotsToString(values);
    const disabledHolidays = holidays
      .filter((holiday) => holiday.enabled === false)
      .map((holiday) => holiday.name);

    const newRecord = {
      id: configID,
      orgId: orgID,
      orgKey,
      name: configName,
      json: {
        ...config,
        virtual_sessions: {
          ...config.virtual_sessions,
          time_slots: timeSlotsString.time_slots,
        },
        holidays: { disabled: disabledHolidays },
      },
    };
    delete newRecord.id;

    try {
      await axiosClient.patch(`/api/s/console/config/${configID}`, newRecord);
      addInfoMsg("Record has been updated successfully.");
      setShow(false);
    } catch {
      addError("Problem updating record, please contact support.");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: overwriteConfigSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: true,
    onSubmit: submitForm,
  });

  const handleChangeEditMode = (event) => {
    setEditMode(event.target.value);
  };

  const handleChangeTextArea = (event) => {
    setConfigJSON(event.target.value);
  };

  const handleSaveConfig = () => {
    if (editMode === "JSON") {
      try {
        const parsedJSON = JSON.parse(configJSON);
        onSave({
          id: configID,
          orgId: orgID,
          orgKey,
          name: configName,
          json: parsedJSON,
        });
      } catch {
        addError("Invalid config JSON");
      }
    } else {
      formik.submitForm();
    }
  };

  useEffect(() => {
    showModal();
    // eslint-disable-next-line
  }, [show]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.title}>
          <Typography variant="h6">Edit Record</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <FormControl variant="standard" component="fieldset">
            <Grid container spacing={3} alignItems="center" justifyContent="center">
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  id="org_id"
                  value={orgID}
                  name="org-id"
                  variant="outlined"
                  label="Org ID"
                  helperText="This field is Required"
                  onChange={(e) => setOrgID(e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  id="org_key"
                  value={orgKey}
                  name="org-key"
                  error={false}
                  label="Org Key"
                  helperText="This field is Required"
                  variant="outlined"
                  onChange={(e) => setOrgKey(e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  id="config-name"
                  className={classes.textField}
                  variant="outlined"
                  error={false}
                  label="Config Name"
                  helperText="This field is Required"
                  value={configName}
                  onChange={(e) => setConfigName(e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl variant="standard" component="fieldset">
                  <FormLabel component="legend">Edit mode</FormLabel>
                  <RadioGroup
                    row
                    aria-label="edit mode"
                    name="editMode"
                    value={editMode}
                    onChange={handleChangeEditMode}
                  >
                    <FormControlLabel
                      value="JSON"
                      control={<Radio color="primary" />}
                      label="JSON mode"
                    />
                    <FormControlLabel
                      value="UI"
                      disabled={show && !holidays.length && !config.virtual_sessions?.time_slots}
                      control={<Radio color="primary" />}
                      label="UI mode"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {editMode === "JSON" && (
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    id="config-json"
                    label="Config Json"
                    multiline
                    minRows={4}
                    value={configJSON}
                    variant="outlined"
                    onChange={handleChangeTextArea}
                    onBlur={() => {}}
                    helperText="This field is Required"
                  />
                </Grid>
              )}
              {editMode === "UI" && config.holidays && configJSON && (
                <Grid item md={12} xs={12}>
                  <HolidaysEdit holidays={holidays} setHolidays={setHolidays} />
                </Grid>
              )}
              {editMode === "UI" &&
                config.virtual_sessions?.time_slots &&
                !isEmpty(initialValues) && (
                  <Grid item md={12} xs={12}>
                    <TimeSlotsOverwrite
                      timeSlots={config.virtual_sessions.time_slots}
                      daysToOverwrite={["monday", "tuesday", "wednesday", "thursday", "friday"]}
                      formik={formik}
                    />
                  </Grid>
                )}
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveConfig} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
