import React from "react";
import InputMask from "react-input-mask";
import { TextField } from "@mui/material";
import { zipCodeMask } from "util/constants";

interface ZipCodeProps extends React.InputHTMLAttributes<HTMLInputElement> {
  zip: string;
  setZip: (value: string) => void;
  isError?: boolean;
  label?: string;
}

const ZipCode = ({ zip, setZip, isError = false, label = "Zip Code", ...props }: ZipCodeProps) => {
  const zipCodeHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZip(e.target.value.replace(/\D/g, ""));
  };

  return (
    <InputMask
      alwaysShowMask={false}
      mask={zipCodeMask}
      onChange={zipCodeHandleChange}
      value={zip}
      {...props}
    >
      {/* @ts-ignore */}
      {(inputProps) => (
        <TextField
          InputLabelProps={{ ...inputProps.InputLabelProps }}
          InputProps={{ ...inputProps.InputProps }}
          {...props}
          {...inputProps}
          label={label}
          id="zip"
          value={zip}
          variant="outlined"
          onChange={zipCodeHandleChange}
          error={isError}
          fullWidth
        />
      )}
    </InputMask>
  );
};

export default ZipCode;
