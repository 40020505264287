import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { camelCase, upperFirst } from "lodash";
import { Check } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { QuerySteps } from "components/Consultation/Steps";
import { useConsultationStyles } from "components/Consultation/styles";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import Map, { MarkerProp } from "components/shared/Map";
import ZipCode from "components/shared/ZipCode/ZipCode";
import { zipCodeMask } from "util/constants";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

type Department = {
  address: string;
  deptType: string;
  lat: number;
  lng: number;
  name: string;
  phoneNumber: string;
  shortName: string;
  distance: { units: string; value: string; display: string };
};

type DepartmentMarker = MarkerProp & Omit<Department, "lat" | "lng" | "id">;

type Props = {
  setStep: (queryStep: QuerySteps) => void;
};

const InfoWindow: React.FC<{ department?: DepartmentMarker }> = ({ department }) => {
  if (department) {
    return (
      <Container maxWidth="xs">
        <Typography gutterBottom variant="body1">
          {department?.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {department.address}
        </Typography>
      </Container>
    );
  }

  return null;
};

const Location: React.FC<Props> = ({ setStep }) => {
  const {
    web: {
      common: { back, next },
      location: {
        locationToContact,
        searchByZipCode,
        selectLocation,
        noAvailableLocations,
        selectLocationSubtitle,
      },
    },
  } = useTranslation();

  const { classes } = useConsultationStyles();
  const app = useApp();
  const { currentVirtualVisit } = app.vs_visits_state;
  const programId = currentVirtualVisit?.program?.id || currentVirtualVisit?.program_id;
  const { vsId } = useParams<{ vsId: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const [chosenMarker, setChosenMarker] = useState<DepartmentMarker>();
  const [markers, setMarkers] = useState<DepartmentMarker[]>([]);
  const [zipCode, setZipCode] = useState<string>("");
  const listRef = useMemo(() => markers.map(() => React.createRef<HTMLDivElement>()), [markers]);

  const getDepartments = async (zipCode?: string) => {
    try {
      let zipCodeSearch = "";
      if (zipCode) {
        zipCodeSearch = `?zipDistance=${zipCode}`;
      }
      const { data } = await axiosClient.get(
        `api/s/virtualvisit/${vsId}/ahslocations${zipCodeSearch}`,
      );
      const departmentMarkers = data.data.locations.map((department) => {
        const { id, lat, lng, shortName, name, phoneNumber, address, deptType, distance } =
          department;
        const displayUnits = distance?.units ?? "miles";
        let displayDistance = "-";
        if (Number.isFinite(distance?.value)) {
          displayDistance = `${(Math.round(distance.value * 10) / 10).toFixed(1)} ${displayUnits}`;
        }
        return {
          id,
          coordinates: { lat, lng },
          shortName,
          name,
          phoneNumber,
          address,
          deptType,
          distance: {
            units: distance?.units,
            value: distance?.value,
            display: displayDistance,
          },
        };
      });
      const sortedMarkers = departmentMarkers.sort((prev, cur) => {
        // Order non numeric values last
        if (!Number.isFinite(prev.distance.value)) {
          return 1;
        }
        if (!Number.isFinite(cur.distance.value)) {
          return -1;
        }
        return prev.distance.value - cur.distance.value;
      });
      setMarkers(sortedMarkers);
      setZipCode(data.data.distanceFromZip);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (programId) {
      getDepartments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  const onMarkerClick = (id) => {
    const currentMarker = markers.find((marker) => marker.id === id);
    setChosenMarker(currentMarker?.id === chosenMarker?.id ? undefined : currentMarker);
    if (currentMarker) {
      document
        .querySelector(`[data-departmentid="${currentMarker.id}"]`)
        ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { data } = await axiosClient.patch(`/api/s/virtualvisit/sessions/${vsId}`, {
        department_id: chosenMarker?.id,
      });
      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: data?.data,
      });
      setStep(QuerySteps.SCHEDULE);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Typography className={classes.heading}>{locationToContact}</Typography>
      <Map
        containerStyle={{
          width: "100%",
          height: "400px",
        }}
        markers={markers}
        chosenMarker={chosenMarker}
        onMarkerClick={onMarkerClick}
        infoWindowProps={{
          children: <InfoWindow department={chosenMarker} />,
        }}
      />
      <Typography variant="body1" className={classes.locationTitle}>
        {searchByZipCode}
      </Typography>
      <ZipCode
        setZip={(zip) => {
          setZipCode(zip);
          if (zip.length >= zipCodeMask.length) {
            getDepartments(zip);
          }
        }}
        zip={zipCode}
        isError={zipCode?.length > 0 && zipCode.length < zipCodeMask.length}
        data-testid="zipInput"
        data-cy="locationZipInput"
      />
      <Typography variant="body1" className={classes.locationTitle}>
        {selectLocation}
      </Typography>
      <Typography className={classes.subTitleText}>{selectLocationSubtitle}</Typography>
      <Box className={classes.locationList}>
        <List>
          {isLoading && <CircularProgress />}
          {!isLoading && markers.length === 0 && (
            <Typography variant="body1">{noAvailableLocations}</Typography>
          )}
          {markers.map((department, index) => (
            <ListItemButton
              ref={listRef[index]}
              key={department.id}
              divider
              onClick={() => onMarkerClick(department.id)}
              data-cy={`item${upperFirst(camelCase(department.name))}`}
              data-departmentid={department.id}
            >
              <ListItemText primary={department.name} secondary={department.address} />
              {chosenMarker?.id === department.id ? (
                <ListItemIcon data-cy={`icon${upperFirst(camelCase(department.name))}`}>
                  <Check color="primary" data-testid={`icon-${department.id}`} />
                </ListItemIcon>
              ) : (
                <ListItemIcon
                  data-testid={`distance${upperFirst(camelCase(department.name))}`}
                  data-cy={`distance${upperFirst(camelCase(department.name))}`}
                >
                  <ListItemText primary={`${department.distance.display}`} />
                </ListItemIcon>
              )}
            </ListItemButton>
          ))}
        </List>
      </Box>

      <Box display="flex" alignItems="space-between" gap="20px" mt="20px">
        <Button variant="contained" fullWidth onClick={() => setStep(QuerySteps.UPLOAD)}>
          {back}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!chosenMarker}
          data-cy="nextButton"
        >
          {next}
        </Button>
      </Box>
    </form>
  );
};

export default Location;
