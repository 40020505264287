import React from "react";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import { LocalAudioTrack } from "twilio-video";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

export default function LocalAudioLevelIndicator() {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;

  return <AudioLevelIndicator audioTrack={audioTrack} />;
}
