import React from "react";
import { Typography } from "@mui/material";
import { MobileNavigationLink } from "../MobileNavigationLink";
import { makeStyles } from "tss-react/mui";
import BannerButton from "../../../components/Mobile/BannerButton/BannerButton";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import CONSTANTS from "../constants";
import useTranslation from "../../../hooks/useTranslation";
import LabWorkIcon from "../../../static/svg/mobile/labwork.svg";
import MakeAppointmentIcon from "../../../static/svg/mobile/makeappointment.svg";
import MammogramIcon from "../../../static/svg/mobile/mammogram.svg";
import PhysicalTherapyIcon from "../../../static/svg/mobile/physicaltherapy.svg";
import PrimaryCareIcon from "../../../static/svg/mobile/primarycare.svg";
import RadiologyIcon from "../../../static/svg/mobile/radiology.svg";
import VaccinesIcon from "../../../static/svg/mobile/vaccines.svg";

const useStyles = makeStyles()(() => ({
  title: {
    margin: "1em",
    fontSize: "1.5em",
    textAlign: "left",
    fontWeight: 500,
  },
}));

const Appointments = () => {
  const { classes } = useStyles();
  const { mobile } = useTranslation();
  const {
    TypeAppointments,
    PrimaryCare,
    Vaccines,
    LabWork,
    Mammogram,
    OtherAppointments,
    Radiology,
    PhysicalTherapy,
  } = mobile;

  const appointmentLinks: Array<MobileNavigationLink> = [
    {
      destination:
        "https://findadoctor.atlantichealth.org/search?sort=networks%2Crelevance&filter=is_primary_care%3AT",
      title: PrimaryCare,
      icon: PrimaryCareIcon,
      testId: "appointments-view-primary-care-button",
      shouldPushNavigation: false,
    },
    {
      destination:
        "https://www.atlantichealth.org/conditions-treatments/coronavirus-covid-19/covid-vaccine/schedule/under-5.html",
      title: Vaccines,
      icon: VaccinesIcon,
      testId: "appointments-view-vaccines-button",
      shouldPushNavigation: false,
    },
    {
      destination:
        "https://findadoctor.atlantichealth.org/search?sort=networks%2Crelevance&filter=provider_type%3ABreast%20Imaging%20Center",
      title: Mammogram,
      icon: MammogramIcon,
      testId: "appointments-view-mammogram-button",
      shouldPushNavigation: false,
    },
    {
      destination:
        "https://www.atlantichealth.org/conditions-treatments/radiology/radiology-appointments-locations.html",
      title: Radiology,
      icon: RadiologyIcon,
      testId: "appointments-view-radiology-button",
      shouldPushNavigation: false,
    },
    {
      destination:
        "https://www.atlantichealth.org/conditions-treatments/rehabilitation-physical-therapy.html",
      title: PhysicalTherapy,
      icon: PhysicalTherapyIcon,
      testId: "appointments-view-physical-therapy-button",
      shouldPushNavigation: false,
    },
    {
      destination:
        "https://www.atlantichealth.org/conditions-treatments/laboratory-services/lab-appointments.html",
      title: LabWork,
      icon: LabWorkIcon,
      testId: "appointments-view-lab-work-button",
      shouldPushNavigation: false,
    },
    {
      destination: "https://www.atlantichealth.org/patients-visitors/appointments.html",
      title: OtherAppointments,
      icon: MakeAppointmentIcon,
      testId: "appointments-view-other-button",
      shouldPushNavigation: false,
    },
  ];

  return (
    <PageContainer>
      <Typography className={classes.title}>{TypeAppointments}</Typography>
      {appointmentLinks.map(({ title, icon, destination, testId, shouldPushNavigation }) => (
        <BannerButton
          key={title}
          title={title}
          icon={icon}
          navigateTo={destination as string}
          navigateFrom={CONSTANTS.APPOINTMENTS}
          showRightArrow
          border="orange"
          testId={testId}
          shouldPushNavigation={shouldPushNavigation}
        />
      ))}
    </PageContainer>
  );
};
export default Appointments;
