import React, { FC } from "react";
import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import { PatientInformation } from "components/FindADoctor/types";
import { RNWebView } from "util/RNWebView";
import useStyles from "./BookingOnline.styles";

interface Props {
  patientInformation?: PatientInformation;
}

const ReviewAndBook: FC<Props> = ({ patientInformation }) => {
  const { classes } = useStyles();

  const name = [
    patientInformation?.firstName,
    patientInformation?.middleName,
    patientInformation?.lastName,
  ]
    .filter(Boolean)
    .join(" ");
  const address = `${patientInformation?.address}, ${patientInformation?.city}, ${patientInformation?.state}, ${patientInformation?.zip}`;

  return (
    <Container maxWidth={RNWebView ? "lg" : "md"}>
      <Typography align="left" className={classes.titleReview}>
        Is all your information correct?
      </Typography>
      <Typography align="left" className={classes.textReview}>
        Check your details below and click the Book Appointment button to complete your booking. If
        any information needs to be changed, click 'Patient Info' above and make updates.
      </Typography>
      <Grid container alignItems="flex-start">
        <Button className={classes.nextButton}>Book Appointment</Button>
      </Grid>
      <Divider />
      {patientInformation && (
        <div className={classes.infoContainer}>
          <Grid container>
            <Typography align="left" className={classes.listTitle}>
              Patient name:
            </Typography>
            <Typography align="left" className={classes.listText}>
              {name}
            </Typography>
          </Grid>
          <Grid container>
            <Typography align="left" className={classes.listTitle}>
              Address:
            </Typography>
            <Typography align="left" className={classes.listText}>
              {address}
            </Typography>
          </Grid>
          <Grid container>
            <Typography align="left" className={classes.listTitle}>
              Date of birth:
            </Typography>
            <Typography align="left" className={classes.listText}>
              {patientInformation.date}
            </Typography>
          </Grid>
          <Grid container>
            <Typography align="left" className={classes.listTitle}>
              Phone number:
            </Typography>
            <Typography align="left" className={classes.listText}>
              {patientInformation.homePhone}
            </Typography>
          </Grid>
          {patientInformation.primaryInsurance && (
            <Grid container>
              <Typography align="left" className={classes.listTitle}>
                Insurance:
              </Typography>
              <Typography align="left" className={classes.listText}>
                {patientInformation.primaryInsurance}
              </Typography>
            </Grid>
          )}
          <Grid container>
            <Typography align="left" className={classes.listTitle}>
              Patient notes:
            </Typography>
            <Typography align="left" className={classes.listText}>
              {patientInformation.reason}
            </Typography>
          </Grid>
        </div>
      )}
    </Container>
  );
};

export default ReviewAndBook;
