import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  rowContainer: {
    marginBottom: 7,
  },
  inputContainer: {
    [theme.breakpoints.down("md")]: {
      width: 180,
    },
  },
  timeInputContainer: {
    [theme.breakpoints.down("md")]: {
      width: 87,
    },
  },
  title: {
    margin: "20px 0",
  },
  daysContainer: {
    marginTop: 9,
    "&:first-child": {
      marginRight: 6,
    },
  },
}));
