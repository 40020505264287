import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "@formio/react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { axiosClient } from "util/api_helper";
import "./FormEmbeddedView.css";
import { useStyles } from "./styles";

const useLocalStyles = makeStyles()((theme) => ({
  patientInfoContainer: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    width: "100%",
    marginBottom: 30,
  },
  title: {
    fontSize: "24px",
    fontFamily: "unset",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
    lineHeight: "inherit",
  },
  noInfoTitle: {
    fontSize: "20px",
    fontFamily: "unset",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
    lineHeight: "inherit",
    marginBottom: 20,
  },
  info: {
    display: "flex",
    marginTop: 10,
    fontSize: "20px",
    marginBottom: 20,
  },
  bold: {
    fontWeight: 700,
    paddingRight: 5,
  },
}));

export default function FormSubmissionView() {
  const { classes } = useLocalStyles();
  const { classes: genericClasses } = useStyles();
  const { form_external_tracking_id: formExternalTrackingId, submission_id: submissionId } =
    useParams();
  const [formSubmissionData, setFormSubmissionData] = useState(null);
  const [mrn, setMrn] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formContext, setFormContext] = useState(null);
  const [doctorsInformation, setDoctorsInformation] = useState(null);

  const loadForm = async () => {
    try {
      const getUrl = `/api/s/formio/forms/${formExternalTrackingId}`;
      const { data } = await axiosClient.get(getUrl);
      setFormData(data);
    } catch (error) {
      console.error(`Error loading form: ${error?.response?.data?.description}`);
    }
  };

  const loadFormSubmission = async () => {
    try {
      const getUrl = `/api/s/formio/forms/${formExternalTrackingId}/submissions/${submissionId}`;
      const { data } = await axiosClient.get(getUrl);
      setFormSubmissionData(data?.submission);
      setFormContext({ ...data?.context });
      setMrn(data?.mrn);
      if (data?.npi) {
        const { npi, providerFirstName, providerLastName } = data;
        setDoctorsInformation({
          npi,
          providerFirstName,
          providerLastName,
        });
      }
    } catch (error) {
      console.error(`Error loading form: ${error?.response?.data?.description}`);
    }
  };

  useEffect(() => {
    if (formExternalTrackingId) {
      loadForm();
      if (submissionId) {
        loadFormSubmission();
      }
    }
    // eslint-disable-next-line
  }, [formExternalTrackingId, submissionId]);

  return (
    <div className={genericClasses.root}>
      {formData && formSubmissionData && (
        <>
          {doctorsInformation ? (
            <div data-testid="patient-information" className={classes.patientInfoContainer}>
              <Typography variant="h1" className={classes.title}>
                Provider Information
              </Typography>
              <Typography variant="h3" className={classes.info}>
                <span className={classes.bold}>NPI:</span>
                {doctorsInformation.npi}
              </Typography>
              <Typography variant="h3" className={classes.info}>
                <span className={classes.bold}>Name:</span>
                {`${doctorsInformation.providerFirstName} ${doctorsInformation.providerLastName}`}
              </Typography>
            </div>
          ) : mrn ? (
            <div data-testid="patient-information" className={classes.patientInfoContainer}>
              <Typography variant="h1" className={classes.title}>
                Patient Information
              </Typography>
              <Typography variant="h3" className={classes.info}>
                <span className={classes.bold}>MRN:</span>
                {mrn}
              </Typography>
            </div>
          ) : (
            <div data-testid="no-patient-information" className={classes.patientInfoContainer}>
              <Typography
                variant="h1"
                className={classes.noInfoTitle}
                data-testid="no-patient-information"
              >
                No Patient Information available
              </Typography>
            </div>
          )}
          <Form
            disabled
            form={{ components: formData.components }}
            submission={{ data: formSubmissionData }}
            options={{
              readOnly: true,
              evalContext: {
                context: formContext,
              },
            }}
          />
        </>
      )}
    </div>
  );
}
