import React, { useState } from "react";
import { ReportByOrderIdFilters } from "./reportByOrderIdFilters";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { axiosClient } from "util/api_helper";
import { exportCSV as exportCsvFile } from "util/util_functions";
import {
  colDefsOrderListCollapsedReport,
  colDefsOrderListReport,
} from "views/SpecimenTracking/utils/definitionColumnsOrderListReport";
import { UrlParamType } from "views/SpecimenTracking/types";

export const ReportByOrderId = () => {
  const [urlParams, setUrlParams] = useState<UrlParamType>({});

  const exportToCSV = async () => {
    try {
      const { data } = await axiosClient.get(API_ENDPOINTS.specimenTrackingOrderCsv, {
        headers: { Accept: "text/csv" },
        params: urlParams,
      });
      exportCsvFile(data, "specimen-tracking-report");
    } catch (err) {
      console.log(err);
    }
  };

  const removeKeyParams = (key: keyof UrlParamType) => {
    setUrlParams((current) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: toDelete, ...rest } = current;
      return rest;
    });
  };

  const handleLocations = (locationsId: string) => {
    if (locationsId.length === 0) {
      removeKeyParams("location_ids");
    } else {
      setUrlParams({ ...urlParams, location_ids: locationsId });
    }
  };

  const handleToggle = (value: string, paramName: keyof UrlParamType) => {
    if (!value) {
      removeKeyParams(paramName);
    } else {
      setUrlParams({ ...urlParams, [paramName]: value });
    }
  };

  const handleDepartments = (departmentsId?: string) => {
    if (departmentsId?.length === 0) {
      removeKeyParams("department_ids");
    } else {
      setUrlParams({ ...urlParams, department_ids: departmentsId });
    }
  };
  return (
    <div data-testid="tableReportByOrderId">
      <GenericEnhancedTable
        gridConfig={{
          ...defaultGridConfig,
          showActionColumn: false,
          allowDelete: false,
          allowEdit: false,
          allowHistory: false,
          showSearchBar: true,
          showTableToolbar: false,
          showCustomButton: false,
          showScannedSpecimenImage: false,
        }}
        colDefs={colDefsOrderListReport}
        defaultSearchBy="orderExternalId"
        orderBy="orderExternalId"
        order="desc"
        hideCheckboxes
        collapsedTableKey="specimens"
        collapsedTableColDefs={colDefsOrderListCollapsedReport}
        url={API_ENDPOINTS.specimenTrackingOrderList}
        onShowScannedSpecimenImageHandler={undefined}
        customFilter={
          <ReportByOrderIdFilters
            handleLocations={handleLocations}
            handleToggle={handleToggle}
            handleDepartments={handleDepartments}
            exportToCSV={exportToCSV}
          />
        }
        urlParams={urlParams}
      />
    </div>
  );
};
