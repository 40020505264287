import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AboutAtlanticHealth from "./AboutAtlanticHealth/AboutAtlanticHealth";
import Billing from "./Billing/Billing";
import GetCare from "./GetCare/GetCare";
import Home from "./Home/Home";
import LocationDetail from "./LocationDetail/LocationDetail";
import LocationList from "./LocationList/LocationList";
import Locations from "./Locations/Locations";
import Map from "./Map/Map";
import More from "./More/More";
import ParkingAndAccommodations from "./ParkingAndAccommodations/ParkingAndAccommodations";
import PatientAndVisitor from "./PatientAndVisitor/PatientAndVisitor";
import qString from "query-string";
import { makeStyles } from "tss-react/mui";
import Footer from "../../components/Mobile/Footer/Footer";
import Header from "../../components/Mobile/Header/Header";
import Appointments from "./Appointments/Appointments";
import CONSTANTS from "./constants";
import "./style.css";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    margin: "0",
    padding: "0",
  },
  hidden: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: 1,
    overflow: "hidden",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: 1,
    backgroundColor: "rgba(218, 218, 218, 0.5)",
    margin: "78px 0 0 0",
    padding: "0 0 8px 0",
    overflow: "auto",
  },
  homeContentSpacing: {
    margin: "0 0 0 0",
  },
}));

const MobileView = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLocation = qString.parse(location.search).l ?? "home";

  const addIsAppParam = () => {
    const params = new URLSearchParams(location.search);
    if (!params.has("isApp")) {
      params.set("isApp", "true");
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  };

  const loadGtm = () => {
    if (!document.getElementById("gtm-script")) {
      const { head } = document;
      const script = document.createElement("script");
      script.id = "gtm-script";
      script.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0], j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WDJZWXG');
      `;
      head.insertBefore(script, head.firstChild);

      const noscript = document.createElement("noscript");
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WDJZWXG"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.insertBefore(noscript, document.body.firstChild);
    }
  };

  useEffect(() => {
    if (location.pathname.startsWith("/mobile")) {
      addIsAppParam();
      loadGtm();
    }
  }, [location.pathname, location.search, navigate]);

  useEffect(() => {
    const { head } = document;
    const style = document.createElement("style");
    style.appendChild(
      document.createTextNode(`#topappbar {
      display: none;
      visibility: hidden;
    }
    `),
    );

    head.appendChild(style);

    return () => {
      head.removeChild(style);
    };
  }, []);

  const getView = () => {
    switch (currentLocation) {
      case CONSTANTS.HOME:
        return <Home />;
      case CONSTANTS.APPOINTMENTS:
        return <Appointments />;
      case CONSTANTS.BILLING:
        return <Billing />;
      case CONSTANTS.CARE:
        return <GetCare />;
      case CONSTANTS.LOCATIONS:
        return <Locations />;
      case CONSTANTS.MAP:
        return <Map />;
      case CONSTANTS.FINDHOSPITAL:
        return <LocationList variant="hospital" />;
      case CONSTANTS.FINDURGENTCARE:
        return <LocationList variant="urgentCare" />;
      case CONSTANTS.HOSPITAL:
        return <LocationDetail variant="hospital" />;
      case CONSTANTS.URGENTCARE:
        return <LocationDetail variant="urgentCare" />;
      case CONSTANTS.PATIENTVISITOR:
        return <PatientAndVisitor />;
      case CONSTANTS.PARKING:
        return <ParkingAndAccommodations />;
      case CONSTANTS.MORE:
        return <More />;
      case CONSTANTS.ABOUT:
        return <AboutAtlanticHealth />;
      default:
        return <Home />;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.hidden}>
        <Header location={currentLocation} />
        <div
          className={`${classes.content} ${
            currentLocation === CONSTANTS.HOME ? classes.homeContentSpacing : ""
          }`}
        >
          {getView()}
        </div>
      </div>
      <Footer location={currentLocation} />
    </div>
  );
};
export default MobileView;
