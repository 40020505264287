import { useQuery } from "react-query";
import { VirtualSessionQueue, consultationQueueView } from "util/constants";
import {
  FETCH_VIRTUAL_VISITS,
  FETCH_VIRTUAL_VISITS_CONVERSATIONS,
} from "../useVirtualVisitReducer/Actions";
import { useUserInfo } from "hooks/useUserInfo/useUserInfo";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { GET_OPTIONS } from "util/api_helper";
import { useProgramType } from "util/programsHelpers";

const fetchVirtualVisits = (options) => {
  const { url } = options;

  return (
    !!url &&
    fetch(url, GET_OPTIONS).then((response) => {
      if (!response.ok) {
        return null;
      }
      return response.json();
    })
  );
};

const useWaitingVsVisits = (options) => {
  const app = useApp();
  const { programId, departmentId } = usePrograms();
  const { user } = useUserInfo();
  const { isPatient } = useUserType();
  const { isConsultation } = useProgramType();
  if (isConsultation) {
    // eslint-disable-next-line
    options.queueView = VirtualSessionQueue.REGISTERING;
  }
  const newOptions = { ...options };
  const { queueView, filterBy, url, order, orderBy, patientReason } = options;

  if (!isPatient) {
    const patientReasonParam =
      patientReason === consultationQueueView.INQUIRY ||
      patientReason === consultationQueueView.SECOND_OPINION
        ? `&patient_reason=${patientReason}`
        : "";
    const optionalDepartmantIdParam = departmentId ? `&department_id=${departmentId}` : "";
    newOptions.url = `${url}?page_num=0&page_size=30&order=${order}&order_by=${orderBy}&queue_view=${queueView}&filter_by=${filterBy}${patientReasonParam}&program_id=${programId}${optionalDepartmantIdParam}`;
  } else {
    newOptions.url = url;
  }
  const id = !isPatient ? programId : user?.userId;
  return useQuery(
    ["fetch-awaiting-vs-visit", id],
    () => (isPatient || !!programId) && fetchVirtualVisits({ ...newOptions }),
    {
      refetchInterval: 60000,
      onSuccess: (json) => {
        if (!isPatient) {
          app.dispatch({
            type: FETCH_VIRTUAL_VISITS,
            payload: json?.data || [],
            departmentId,
          });
        } else {
          app.dispatch({
            type: FETCH_VIRTUAL_VISITS_CONVERSATIONS,
            payload: json?.data || [],
          });
        }
      },
    },
  );
};

export default useWaitingVsVisits;
