/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Box, Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import MessageList from "components/Video/components/ChatWindow/MessageList/MessageList";
import PatientProfileCard from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/PatientProfileCard";
import PhysicianNotes from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/PhysicianNotes";
import VirtualSessionEvents from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/VirtualSessionEvents";
import VirtualSessionUsers from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/VirtualSessionUsers";
import VisitDetails from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/VisitDetails";
import { DialogContent, DialogTitle } from "views/Report/UIComponents";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const useStyles = makeStyles()((theme) => ({
  requestDetailsTitle: {
    marginTop: "32px",
    marginBottom: "8px",
  },
  requestDetails: {
    padding: "16px",
    marginBottom: "24px",
    borderRadius: "6px",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  chatHeader: {
    height: "56px",
    background: "#F4F4F6",
    borderBottom: "1px solid #E4E7E9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1em",
  },
  chatTitle: {
    margin: 0,
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

const VSDetailsModal = ({ show, setShow, currentVirtualVisit }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [messages, setMessages] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpen(false);
    setShow(false);
  };

  const showModal = () => {
    if (show) {
      getPatientProfile(currentVirtualVisit.id);
      setOpen(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const getPatientProfile = async (vvId) => {
    try {
      const req_url = "/api/s/console/virtualvisitconsole/patient-profile-info";
      const { data } = await axiosClient.get(req_url, { params: { virtual_session_id: vvId } });
      setProfile(data?.data);
    } catch (error) {
      app.addError(error.response?.data?.description);
    }
  };

  const handleGetMessages = async () => {
    try {
      const url = `/api/s/console/virtualvisit/load_messages?vv_id=${currentVirtualVisit?.id}`;
      const { data } = await axiosClient.get(url);
      setMessages(data.data);
    } catch (err) {
      app.addError(err.response?.data?.description);
    }
  };

  useEffect(() => {
    if (currentVirtualVisit?.id) {
      handleGetMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.id]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Virtual session details
      </DialogTitle>
      <DialogContent dividers>
        <PhysicianNotes currentVirtualVisit={currentVirtualVisit} />
        <PatientProfileCard profile={profile} />
        <Box marginTop="20px">
          <VisitDetails
            currentVirtualVisit={currentVirtualVisit}
            handleClose={handleClose}
            profile={profile}
            isEditable={false}
          />
        </Box>
        <VirtualSessionUsers profile={profile} />
        <VirtualSessionEvents profile={profile} />
        <Box marginTop="56px" border="1px solid rgba(0, 0, 0, 0.12)">
          <div className={classes.chatHeader}>
            <Typography variant="h4" className={classes.chatTitle}>
              Chat
            </Typography>
          </div>
          <MessageList messages={messages} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VSDetailsModal;
