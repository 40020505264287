import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { emptyPatientQuery, makeQuery } from "./util";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { Patient, PatientSearchProps } from "./types";

export * from "./types";

const useStyles = makeStyles()(() => ({
  searchForm: {
    flexBasis: "13rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    alignItems: "stretch",

    "& > .MuiTextField-root": {
      flexGrow: 0,
    },
  },
  searchResults: {
    minHeight: "4rem",
    overflowY: "auto",
    paddingTop: 0,
    paddingBottom: 0,
  },
  noResults: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

// For the moment, I only need the ability to search by MRN, but I'm doing my
// best to leave this open for future expansion with additional fields.
const PatientSearchForm: React.FC<FormikProps<Partial<Patient>>> = (props) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    isSubmitting,
    touched,
    values,
  } = props;
  const { classes } = useStyles();

  const hasErrors = Object.values(errors).some((e) => !!e);

  return (
    <form
      data-testid="patient-search-form"
      className={classes.searchForm}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <TextField
        variant="outlined"
        name="MRN"
        label="MRN"
        value={values?.MRN}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!errors?.MRN && !!touched?.MRN}
        helperText={(touched?.MRN && errors?.MRN) || ""}
      />
      <Button
        type="submit"
        variant="contained"
        disabled={hasErrors || isSubmitting}
        data-cy="search-patient-btn"
      >
        Search
      </Button>
    </form>
  );
};

const PatientSearch: React.FC<PatientSearchProps> = (props) => {
  const { onChange, initialValues = emptyPatientQuery } = props;
  const app = useApp();
  const { classes } = useStyles();

  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<Map<string, Patient>>(new Map());
  const [selected, setSelected] = useState<Patient | undefined>();
  useEffect(() => {
    // if we get a single result, select it
    if (searchResults.size === 0) {
      setSelected(undefined);
    }
    if (searchResults.size === 1) {
      setSelected([...searchResults.values()][0]);
    }
  }, [searchResults]);
  useEffect(() => {
    if (onChange) {
      onChange?.(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const search = async (patient: Partial<Patient>) => {
    try {
      setSearching(true);
      const results = await axiosClient.post(API_ENDPOINTS.search_patient, makeQuery(patient));
      const patients = results.data.data as Patient[];
      setSearchResults(new Map(patients.map((pat) => [pat.MRN, pat])));
    } catch (err) {
      console.error(err);
      app.addError("Error while searching for patients");
    } finally {
      setSearching(false);
    }
  };

  return (
    <Grid container spacing={3} data-testid="patient-search">
      <Grid item xs={12} sm={6}>
        <Formik initialValues={initialValues} component={PatientSearchForm} onSubmit={search} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <List data-testid="patient-search-results" className={classes.searchResults}>
          {searching ? (
            <div className={classes.noResults}>
              <CircularProgress size={24} />
            </div>
          ) : undefined}
          {!searching && !searchResults.size ? (
            <div className={classes.noResults}>
              <Typography component="span" variant="body2">
                No Results
              </Typography>
            </div>
          ) : undefined}
          {[...searchResults.entries()].map(([mrn, patient]) => (
            <ListItem
              key={mrn}
              button
              selected={selected?.MRN === mrn}
              onClick={() => setSelected(patient)}
            >
              <ListItemText
                primary={`${patient.firstName} ${patient.lastName}`}
                secondary={patient.MRN}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default PatientSearch;
