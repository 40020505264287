/* eslint-disable */
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    backgroundColor: theme.palette.common.white,
    height: "100vh",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "50px",
    margin: "80px auto",
    minWidth: "500px",
    maxWidth: "900px",
    textAlign: "left",
    fontFamily: "Alegreya Sans",
    "& .formio-component-title": {
      marginBottom: "20px",
    },
    "& .form-group": {
      fontSize: "18px",
    },
    "& .btn-primary": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      fontWeight: 500,
    },
    "& .formio-component-content > h4": {
      fontWeight: 400,
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      minWidth: "95%",
      maxWidth: "95%",
      margin: "40px auto",
      "& .formio-component-title > h2": {
        fontSize: "24px",
      },
      "& .formio-component-content > h4": {
        fontSize: "20px",
      },
      "& .form-group": {
        fontSize: "16px",
      },
    },
    "& .radio-selected": {
      fontWeight: 600,
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
      minWidth: "95%",
      maxWidth: "95%",
      margin: "40px auto",
      "& .formio-component-title > h2": {
        fontSize: "24px",
      },
      "& .formio-component-content > h4": {
        fontSize: "20px",
      },
      "& .form-group": {
        fontSize: "16px",
      },
    },
  },
}));
