import React from "react";
import Theme from "Theme";

export default function AvatarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73"
      height="73"
      viewBox="0 0 73 73"
      data-testid="avatar-icon"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="36.5" cy="36.5" r="36.5" fill={Theme.palette.common.white} />
        <path
          fill="#282A2B"
          strokeLinejoin="round"
          d="M30.973 23.925c-3.162 3.794-3.162 9.886 0 13.68l.262.301c.266.293.548.564.842.812l.192.154v2.95l-6.71 2.397-.213.082c-2.03.834-3.346 2.793-3.346 4.97v2.766c0 .532.431.963.963.963h27.874c.531 0 .962-.431.962-.963V49.27l-.005-.228-.015-.233c-.187-2.092-1.562-3.883-3.539-4.59l-6.71-2.397v-2.95l.192-.154c.393-.33.762-.702 1.104-1.112 3.162-3.795 3.162-9.887 0-13.681-3.25-3.9-8.603-3.9-11.853 0zm10.374 1.232c2.567 3.08 2.567 8.135 0 11.216-.404.484-.85.894-1.328 1.226-.26.18-.414.476-.414.791v4.111c0 .407.255.77.639.907l7.348 2.625c1.314.47 2.213 1.687 2.278 3.077l.004.183v1.781h-25.95l.002-1.804c0-1.394.843-2.65 2.131-3.18l.172-.065 7.327-2.617c.383-.137.639-.5.639-.907v-4.11c0-.316-.155-.612-.415-.792-.478-.332-.924-.742-1.328-1.226-2.567-3.08-2.567-8.135 0-11.216 2.427-2.912 6.248-2.974 8.735-.186l.16.186z"
        />
      </g>
    </svg>
  );
}
