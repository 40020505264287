const SELECT_FAMILY_RECEIVE_CARE = "Select a family member to receive care";

export const getTitlesByRedirect = (redirect) => {
  switch (redirect) {
    case "find-a-doctor":
      return { title: SELECT_FAMILY_RECEIVE_CARE, subtitle: null };
    case null:
    case undefined:
      return { title: "Family Selection", subtitle: SELECT_FAMILY_RECEIVE_CARE };
    default:
      return {
        title: "Welcome to Virtual Urgent Care!",
        subtitle: SELECT_FAMILY_RECEIVE_CARE,
      };
  }
};
