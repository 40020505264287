import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Typography } from "@mui/material";
import { LocalVideoTrack } from "twilio-video";
import ToggleVideoButton from "components/Video/components/Buttons/ToggleVideoButton/ToggleVideoButton";
import ReloadButton from "components/Video/components/PreJoinScreens/AudioVideoTest/ReloadButton";
import VideoInputListPreview from "components/Video/components/PreJoinScreens/AudioVideoTest/VideoInputListPreview";
import { useCustomStyles } from "components/Video/components/PreJoinScreens/AudioVideoTest/useCustomStyles";
import { QuerySteps } from "components/Video/components/PreJoinScreens/Steps";
import VideoTrack from "components/Video/components/VideoTrack/VideoTrack";
import useVideoContext from "components/Video/hooks/useVideoContext/useVideoContext";
import AvatarIcon from "components/Video/icons/AvatarIcon";

interface VideoPermissionStepProps {
  setStep: (Steps: QuerySteps) => void;
}

const VideoPermissionStep = ({ setStep }: VideoPermissionStepProps) => {
  const { getLocalVideoTrack, localTracks, removeLocalVideoTrack } = useVideoContext();

  const [isPermissionAssigned, setIsPermissionAssigned] = useState<boolean>(false);
  const videoTrack = localTracks.find((track) => track.name.includes("camera")) as LocalVideoTrack;

  const { classes } = useCustomStyles();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    getLocalVideoTrack()
      .then(() => setIsPermissionAssigned(true))
      .catch(() => {
        setError("Video is disabled by your browser");
      });
  }, [getLocalVideoTrack]);

  useEffect(() => {
    return () => removeLocalVideoTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localTracks]);

  return (
    <>
      <h3 className={classes.topHeading}>Please confirm that your video is working below</h3>

      <div className={classes.container}>
        <div className={classes.localPreviewContainer}>
          <div className={classes.innerContainer}>
            {videoTrack ? (
              <div className={classes.avatarContainer}>
                <VideoTrack track={videoTrack} isLocal />
              </div>
            ) : (
              <div className={classes.avatarContainer}>
                <AvatarIcon />
              </div>
            )}
          </div>
        </div>

        {!error ? (
          <ToggleVideoButton className={classes.mobileButton} />
        ) : (
          <>
            <Typography className={classes.errorMessage}>
              Looks like your camera has been disabled. Please reload the app to enable camera
              access and continue your visit.
            </Typography>
            <ReloadButton />
          </>
        )}

        {!error && isPermissionAssigned && (
          <div className={classes.listSection}>
            <VideoInputListPreview />
          </div>
        )}
        <div className={classes.btnDiv}>
          <Button
            className={classes.testPreviewbutton}
            onClick={() => {
              removeLocalVideoTrack();
              setStep(QuerySteps.AUDIO_PERMISSION);
            }}
            variant="outlined"
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              removeLocalVideoTrack();
              setStep(QuerySteps.PREVIEW);
            }}
            disabled={!isPermissionAssigned}
            className={classes.testPreviewbutton}
            endIcon={<ArrowForwardIcon />}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default VideoPermissionStep;
