import React, { useCallback, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { SignaturePadProps } from "./types";
import { useStyles } from "./styles";

export const SignaturePad = ({
  onSave,
  width = 500,
  height = 200,
  isModal = false,
  modalTitle = "Signature",
  modalDescription = "Please provide your signature in the space below",
}: SignaturePadProps) => {
  const [open, setOpen] = useState(isModal);
  const { classes } = useStyles();
  const app = useApp();
  const signatureCanvasRef = useRef<SignatureCanvas>(null);

  const {
    web: {
      SignaturePad: { clear, save, emptySignatureError },
    },
  } = useTranslation();

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const clearSignature = useCallback(() => {
    signatureCanvasRef?.current?.clear();
  }, []);

  const saveSignature = useCallback(() => {
    if (!signatureCanvasRef?.current || signatureCanvasRef.current.isEmpty()) {
      app.addError(emptySignatureError);
      return;
    }
    const signatureImage = signatureCanvasRef.current.getTrimmedCanvas().toDataURL();
    onSave(signatureImage);
    clearSignature();
    if (isModal) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSave, isModal]);

  const SignaturePadContent = (
    <div>
      <SignatureCanvas
        ref={signatureCanvasRef}
        penColor="#043070"
        canvasProps={{
          width,
          height,
          className: classes.signatureCanvas,
          // @ts-ignore
          "data-cy": "signature-pad",
        }}
      />
      <div className={classes.actionButtonsContainer}>
        <Button
          data-testid="clear-signature"
          style={{ marginRight: "1em" }}
          onClick={clearSignature}
          variant="contained"
          color="secondary"
        >
          {clear}
        </Button>
        <Button
          data-testid="save-signature"
          variant="contained"
          color="primary"
          onClick={saveSignature}
        >
          {save}
        </Button>
      </div>
    </div>
  );

  if (!isModal) {
    return SignaturePadContent;
  }

  return (
    <Dialog open={open} data-testid="modal_signaturepad">
      <DialogTitle>
        {modalTitle}
        <IconButton
          data-testid="close_signaturepad_button"
          className={classes.closeIconButton}
          onClick={closeModal}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{modalDescription}</DialogContentText>
        {SignaturePadContent}
      </DialogContent>
    </Dialog>
  );
};
