import React from "react";
import { styled } from "@mui/material/styles";
import PreJoinScreens from "components/Video/components/PreJoinScreens/PreJoinScreens";
import PreJoinScreensPhysician from "components/Video/components/PreJoinScreensPhysician/PreJoinScreens";
import VideoRoom from "components/Video/components/VideoRoom";
import useRoomState from "components/Video/hooks/useRoomState/useRoomState";
import { TWILIO_ROOMSTATES } from "util/constants";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "util/AppContext";

const Container = styled("div")({
  height: "auto",
  width: "100%",
});

const VideoModule = () => {
  const roomState = useRoomState();
  const {
    vs_visits_state: { currentVirtualVisit },
  } = useApp();
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const { isPatient, isStaff } = useUserType();

  const redirectToVideoModule = () => {
    if (roomState === TWILIO_ROOMSTATES.DISCONNECTED) {
      if (isPatient) {
        return <PreJoinScreens />;
      }
      if (isStaff) {
        return <PreJoinScreensPhysician />;
      }
    }
    if (currentVirtualVisit?.id && isStaff) {
      return <PreJoinScreensPhysician />;
    }

    return <VideoRoom />;
  };

  return <Container>{redirectToVideoModule}</Container>;
};
export default VideoModule;
