import React, { useEffect } from "react";
import { Html5Qrcode, Html5QrcodeCameraScanConfig } from "html5-qrcode";
import { ConfigInterface, QRProps } from "./types";

const Html5QrcodePlugin = ({
  verbose,
  qrCodeSuccessCallback,
  qrCodeErrorCallback,
  fps,
  qrbox,
  aspectRatio,
  disableFlip,
  supportedScanTypes,
  supportedFormatsTypes,
}: QRProps) => {
  const qrcodeRegionId = "reader";

  const createConfig = () => {
    const config: ConfigInterface = {};
    if (fps) {
      config.fps = fps;
    }
    if (qrbox) {
      config.qrbox = qrbox;
    }
    if (aspectRatio) {
      config.aspectRatio = aspectRatio;
    }
    if (disableFlip !== undefined) {
      config.disableFlip = disableFlip;
    }
    if (supportedScanTypes !== undefined) {
      config.supportedScanTypes = supportedScanTypes;
    }
    return config;
  };

  useEffect(() => {
    // Creates the configuration object for Html5QrcodeScanner.
    const qrcodeRegionId = "reader";
    const config = createConfig();
    const html5QrCode = new Html5Qrcode(qrcodeRegionId, {
      formatsToSupport: supportedFormatsTypes,
      verbose: !!(verbose === false),
    });

    html5QrCode
      .start(
        { facingMode: "environment" },
        config as Html5QrcodeCameraScanConfig,
        qrCodeSuccessCallback,
        qrCodeErrorCallback,
      )
      .catch((error) => {
        console.log("failed to start html5Qrcode", error);
      });

    return () => {
      try {
        html5QrCode.clear();
      } catch (error) {
        console.error("Failed to clear html5Qrcode. ", error);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={qrcodeRegionId} style={{ width: "100%" }} />;
};

export default Html5QrcodePlugin;
