import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export const ahsZips = [
  { title: "AUC URGENT CARE COVID VACCINE CENTER 07046", zip: "07046" },
  { title: "UC CLARK URGENT CARE COVID VACCINE CENTER 07066", zip: "07066" },
  { title: "MMC 475 SOUTH ST COVID VACCINE CENTER 07960", zip: "07960" },
  { title: "CMC COVID VACCINE 242 WEST PARKWAY 07444", zip: "07444" },
  { title: "MMC COVID VACCINE BASKING RIDGE 07920", zip: "07920" },
  { title: "MMC Hospital 07960", zip: "07960" },
  { title: "OMC Hospital 07901", zip: "07901" },
  { title: "NMC Hospital 07860", zip: "07960" },
  { title: "CMC Hospital 07444", zip: "07444" },
  { title: "HMC Hospital 07840", zip: "07840" },
  { title: "Rockaway Mega-Site 07866", zip: "07866" },
  { title: "CMC LINCOLN PARK 7035", zip: "07035" },
  { title: "CMC BLOOMINGDALE 7035", zip: "07403" },
  { title: "CMC BUTLER 07405", zip: "07405" },
  { title: "CMC HASKELL 07420", zip: "07420" },
  { title: "CMC HEWITT 07421", zip: "07421" },
  { title: "CMC PEQUANNOCK 07440", zip: "07440" },
  { title: "CMC POMPTON LAKES 07035", zip: "07442" },
  { title: "CMC POMPTON PLAINS 07444", zip: "07444" },
  { title: "CMC RINGWOOD 07035", zip: "07456" },
  { title: "CMC RIVERDALE 07457", zip: "07457" },
  { title: "CMC WAYNE 07470", zip: "07470" },
  { title: "CMC WAYNE 07474", zip: "07474" },
  { title: "CMC WAYNE 07477", zip: "07477" },
  { title: "CMC WEST MILFORD 07480", zip: "07480" },
];

export default function AHSZip(props) {
  const { onChange } = props;
  return (
    <Autocomplete
      id="combo-box-ahs-zip"
      options={ahsZips}
      onChange={onChange}
      getOptionLabel={(option) => option.title}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="AHS ZIP Search" variant="outlined" />}
    />
  );
}
