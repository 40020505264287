import React from "react";
import { MobileNavigationLink } from "../MobileNavigationLink";
import { makeStyles } from "tss-react/mui";
import Hero from "../../../components/Mobile/Hero/Hero";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import SquareButton from "../../../components/Mobile/SquareButton/SquareButton";
import GiftShopIcon from "../../../static/svg/mobile/giftshop.svg";
import CONSTANTS from "../constants";
import useTranslation from "../../../hooks/useTranslation";
import DiningIcon from "../../../static/svg/mobile/dining.svg";
import ParkingIcon from "../../../static/svg/mobile/parking.svg";
import PharmacyIcon from "../../../static/svg/mobile/pharmacy.svg";
import SpiritualCareIcon from "../../../static/svg/mobile/spiritualcare.svg";
import VisitingHoursIcon from "../../../static/svg/mobile/visitinghours.svg";

const useStyles = makeStyles()(() => ({
  twoColumnContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "0.8em",
    padding: "0 1em",
    marginTop: "-74px",
  },
}));

const PatientAndVisitor = () => {
  const { classes } = useStyles();
  const { mobile } = useTranslation();
  const {
    VisitingHours,
    ParkingAccommodations,
    PharmacyServices,
    Dining,
    GiftShop,
    SpiritualCare,
    LocationsDesc,
  } = mobile;

  const patientAndVisitorLinks: Array<MobileNavigationLink> = [
    {
      destination: "https://www.atlantichealth.org/patients-visitors/visiting-hours.html",
      icon: VisitingHoursIcon,
      title: VisitingHours,
      shouldPushNavigation: false,
      testId: "patient-and-visitor-view-visiting-hours-button",
    },
    {
      destination: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.PARKING}`,
      icon: ParkingIcon,
      title: ParkingAccommodations,
      shouldPushNavigation: true,
      testId: "patient-and-visitor-view-parking-accommodations-button",
    },
    {
      destination:
        "https://www.atlantichealth.org/patients-visitors/hospital-stays-visits/pharmacy-services.html",
      icon: PharmacyIcon,
      title: PharmacyServices,
      shouldPushNavigation: false,
      testId: "patient-and-visitor-view-pharmacy-services-button",
    },
    {
      destination:
        "https://www.atlantichealth.org/patients-visitors/hospital-stays-visits/meals-dining.html",
      icon: DiningIcon,
      title: Dining,
      shouldPushNavigation: false,
      testId: "patient-and-visitor-view-dining-button",
    },
    {
      destination:
        "https://www.atlantichealth.org/patients-visitors/hospital-stays-visits/gift-shops-flowers.html",
      icon: GiftShopIcon,
      title: GiftShop,
      shouldPushNavigation: false,
      testId: "patient-and-visitor-view-gift-shop-button",
    },
    {
      destination:
        "https://www.atlantichealth.org/patients-visitors/hospital-stays-visits/pastoral-care.html",
      icon: SpiritualCareIcon,
      title: SpiritualCare,
      shouldPushNavigation: false,
      testId: "patient-and-visitor-view-spiritual-care-button",
    },
  ];

  return (
    <PageContainer>
      <Hero variant="onlyText" text={LocationsDesc} displaceText />
      <div className={classes.twoColumnContainer}>
        {patientAndVisitorLinks.map(
          ({ title, shouldPushNavigation, destination, icon, testId }) => (
            <SquareButton
              key={title}
              title={title}
              shouldPushNavigation={shouldPushNavigation}
              navigateTo={destination as string}
              navigateFrom={CONSTANTS.PATIENTVISITOR}
              icon={icon}
              testId={testId}
            />
          ),
        )}
      </div>
    </PageContainer>
  );
};
export default PatientAndVisitor;
