export const localeTranslationsEn = {
  mobile: {
    Home: "Home",
    GetCare: "Get Care",
    Locations: "Locations",
    Billing: "Billing",
    More: "More",
    Appointments: "Appointments",
    Services: "Services",
    GoBack: "Go Back",

    MyChart: "MyChart",
    MyChartDesc: "View your health records, manage\nappointments, message a doctor and more.",
    SignInMyChart: "Sign-in to MyChart®",

    FindDoctor: "Find a Doctor",
    FindDoctorDesc: "Search for a provider by specialty, name or affiliation.",
    FindDoctorAptDesc: "Search for a provider and schedule an appointment.",
    FindUrgentCare: "Find an Urgent Care",
    FindUrgentCareDesc: "Start a video visit from your smartphone, tablet or computer.",
    MakeAppointment: "Make an Appointment",
    MakeAppointmentDesc: "Schedule an appointment for a doctor, service or test.",
    ChatWithNurse: "Chat With a Nurse",
    ChatWithNurseDesc: "Chat with a nurse about your questions, Mon to Fri 8:00am to 4:00pm.",

    TypeAppointments: "What type of appointment do you need?",

    PayMyBill: "Pay My Bill",
    PayMyBillDesc: "Pay your bills online, see statements and get estimates.",
    Insurance: "Insurance",
    ClassesEvents: "Classes & Events",
    HeartRiskAssessment: "Heart Risk Assessment",
    ResearchClinicalTrials: "Research & Clinical Trials",
    AboutAtlanticHealth: "About Atlantic Health",
    ContactUs: "Contact Us",
    COVID19: "COVID-19",
    ViewOurFullWebsite: "View Our Full Website",

    PrimaryCare: "Primary Care",
    Vaccines: "Covid 19 & Flu Vaccines",
    LabWork: "Lab Work",
    Mammogram: "Mammogram",
    Radiology: "Radiology",
    PhysicalTherapy: "Physical Therapy",
    OtherAppointments: "All Other Appointments",

    BillingTitle: "What type of payment do you want to make?",
    HospitalOutpatient: "Hospital & Outpatient Services",
    PayAsGuest: "Pay as Guest ( Hospital Only )",
    PhysicianPractices: "Physician Practices",
    UrgentCareClark: "Urgent Care Clark, Sparta or Newton",
    UrgentCareSpartaNewtonOrPhillipsburg: "Urgent Care Sparta, Newton or Phillipsburg",
    AdvancedUrgentCare: "AdvancED Urgent Care Mountain Lakes, Paramus or Clark",
    ImmediateCare: "Immediate Care",
    VisitingNurse: "Visiting Nurse",
    LaboratoryServices: "Laboratory Services",
    Estimates: "Estimates",

    Walkins: "Walk-Ins & Urgent Care",
    WalkinsDesc: "Get immediate care for a minor illness or injury that isn't life threatening.",
    VirtualCare: "Virtual Urgent Care",
    VirtualCareDesc:
      "Start a live video visit with a physician from your smartphone, tablet or computer.",

    LocationsDesc:
      "Our team is dedicated to making sure that all of our patients and visitors feel safe, comfortable and secure throughout their stay with us.",
    FindHospital: "Find a Hospital",
    Hospitals: "Hospitals",
    PatientVisitorServices: "Hospital Patient & Visitor Services",
    PatientResources: "Patient Resources",
    VisitorInformation: "Visitor Information",
    AllLocations: "All Locations",
    SearchHospitals: "Search Hospitals",
    ByCityOrZip: "by city or zip",
    MakeQueryHospitals: "Type 3 or more characters to search for a hospital.",
    DrivingDirections: "Driving Directions",
    IndoorGPS: "Indoor Directions",
    UrgentCareMore: "Hours, Insurance & More",

    Careers: "Careers",
    MakeDonation: "Make a Donation",
    AboutApp: "About This App",
    PrivacyPolicy: "Privacy Policy",
    Feedback: "Feedback",
    AboutDesc:
      "Atlantic Health System has a long-standing tradition of providing exceptional patient outcomes and experiences. Our team members and physicians are dedicated to building healthier communities.",

    SearchUrgentCare: "Search Urgent Care",
    MakeQueryUrgentCare: "Type 3 or more characters to search for an urgent care.",

    VisitingHours: "Visiting Hours",
    ParkingAccommodations: "Parking & Accommodations",
    PharmacyServices: "Pharmacy Services",
    Dining: "Dining",
    GiftShop: "Gift Shop & Flowers",
    SpiritualCare: "Spiritual Care",
    SendMessage: "Send us a Messsage",

    ChooseHospital: "Choose Your Hospital",
    UrgentCare: "Urgent Care",
    AllServices: "All Services",
    UrgentCareBanner: "If a medical problem is life threatening, please call 911 immediately",

    FeedbackTitle: "Enjoying Atlantic Health app?",
    FeedbackSubtitle: "Tap a star to rate it on the App Store",
    Submit: "Submit",
    SendFeedback: "Send Feedback",
  },
  web: {
    common: {
      phoneNumber: "Phone number",
      email: "Email",
      location: "Location",
      errorMsg: "Something went wrong. Please, contact support.",
      requiredQuestionserrorMsg: "Please answer all the questions to continue.",
      yes: "Yes",
      no: "No",
      next: "Next",
      back: "Back",
      returnHome: "Return home",
      cancel: "Cancel",
      confirm: "Confirm",
      save: "Save",
      add: "Add",
      apply: "Apply",
      remove: "Remove",
      edit: "Edit",
      update: "Update",
      rotateDeviceToLandscape: "Please rotate your device to landscape mode",
      rotateDeviceToPortrait: "Please rotate your device to portrait mode",
      previous: "Previous",
      finish: "Finish",
      reset: "Reset",
      continueStep: "Continue",
      close: "Close",
      emailSuffix: "@atlantichealth.org",
      unsavedProgressWarning:
        "An error occurred while saving your progress. If this happens again, check your connection or try reloading the page.",
      missingMedicalExaminerWarning:
        "An error occurred while saving the form. You may need to set a medical examiner.",
      unsavedChangesWarning: "You have unsaved changes, you sure you want to leave?",
      progressSaved: "Your progress was saved.",
      warning: "Warning",
    },
    specimenTracking: {
      listTitle: "Specimen Tracking List",
      historyTitle: "Specimen Tracking History",
      noSpecimens: "No specimens found",
      inputPlaceholder: "Type to filter...",
      filtersTitle: "Select a date",
      mrnId: "MRN",
      orderId: "Order ID",
      numbersOfSpecimens: "Number of specimens",
      specimenId: "Specimen ID",
      patientName: "Patient Name",
      patientFirstName: "Patient First Name",
      patientLastName: "Patient Last Name",
      dob: "Date of Birth",
      patientDob: "Patient DOB",
      specimenType: "Specimen Type",
      specimenInfo: "Specimen Info",
      location: "Location",
      collectedBy: "Collected By",
      collectedAt: "Collected At",
      collectedOn: "Collected On",
      trackedAt: "Tracked at",
      trackedOn: "Tracked on",
      collectedDate: "Collected Date",
      addSpecimen: "Add Specimen",
      dropOffSpecimen: "Drop Off Specimen",
      pickUpSpecimen: "Pick Up Specimen",
      droppedOffBy: "Dropped off by",
      pickedUpby: "Picked up by",
      addedBy: "Added by",
      scanSuccessful: "Scan Successful",
      takePhotoOfSpecimen: "Take Photo of Specimen",
      replacePhotoOfSpecimen: "Replace Photo of Specimen",
      specimenPhoto: "Specimen Photo",
      specimenImage: "Specimen Image",
      labelImage: "Label Image",
      noImages: "The Specimen has no images",
      leadingZeroError: "Specimen ID can't start with zero",
      confirmDeleteSpecimenTitle: "Confirm Specimen Delete",
      confirmDeleteSpecimenMsg:
        "This will remove this specimen and all history. This action can not be undone.",
    },
    specimenTrackingReport: {
      exportCSV: "Export CSV",
      backToList: "Back to List",
      specimenTrackingReports: "Specimen Tracking Reports",
      reportBySpecimen: "Specimen Report",
      reportByOrderId: "Order ID Report",
    },
    specimenReportCards: {
      averageJourneyTimeHeader: "Average Journey Time",
      averageJourneyTimeFooter: "for samples to reach their final destination",
      completedDropOffsHeader: "Completed Drop Offs",
      currentlyInTransitHeader: "Currently In Transit",
    },
    specimenReportOrderId: {
      allSpecimens: "All Specimens",
      pickedUp: "Picked Up",
      droppedOff: "Dropped Off",
      added: "Added",
      onTime: "On Time",
      overdue: "Overdue",
      allLocations: "All Locations",
      allDepartments: "All Departments",
      mySpecimens: "My Specimens",
    },
    specimenTrackingAdd: {
      scanNew: "Scan new specimen codes",
      btnAddManually: "Add Manually",
      btnAddManuallyAdSpecimen: "Add Specimen",
      specimensList: "Specimens List",
      specimenId: "Specimen ID",
      orderId: "Order ID",
      code: "code",
      codes: "codes",
      removeSpecimen: "Remove specimen",
      addManuallyTitle: "Add Specimen Manually",
      emptyListAdd: "Scan specimens or Add Manually",
      emptyListDrop: "Drop off specimens",
      emptyListPick: "Pick up specimens",
      listTitleAdd: "Scanned codes:",
      listTitleDrop: "Specimens to drop off:",
      listTitlePick: "Specimens to pick up:",
      specimensTotal: "Specimens Total",
      patientLastName: "Patient Last Name",
      msgAddSpecimenSuccess: "Specimen has been added successfully.",
      msgAddSpecimenAlreadyScanned: "The specimen has already been scanned.",
      msgAddSpecimenError: "Error when trying to add the Specimen manually. Please try again.",
      msgAddSpecimenErrorScanned:
        "Could not read Specimen Id. Try again, make sure label text is in focus.",
      msgAddSpecimenManuallyError:
        "Could not add Specimen Manually. Try again, make sure the Specimen ID is correct.",
      msgAddSpecimenNotFound: "Could not find specimen",
      msgAddSpecimensSuccess: "Scanned Specimens have been saved successfully.",
      msgAddSpecimensUpdateSuccess: "Scanned Specimens have been updated successfully.",
      msgAddSpecimensConfirmError: "Error when trying to confirm Specimens.",
      msgReminder: "Reminder: ONLY SCAN LABELS ON CONTAINERS",
      scanLabel: "Scan Label",
    },
    specimenTrackingLocation: {
      scanNewLocation: "Scan the location code",
      scanned: "Scanned code:",
      locationFound: "Location found",
      locationNotFound: "Location not found",
    },
    specimenTrackingHelpCenter: {
      faq: [
        {
          title: "Label doesn’t scan",
          content: [
            "When adding, dropping off, or picking up, if the label doesn’t scan, select “add manually” and type in the require fields.  Click the save codes button to save the update.",
          ],
        },
        {
          title: "Edit scanned label",
          content: [
            "From the specimen tracking list, open the case you’re interested in editing. Click on the pencil icon to edit the specimen ID. Click the checkmark to save the changes.",
          ],
        },
        {
          title: "Edit specimen info after saving",
          content: [
            "When scanning specimen labels at the add stage, if you need to edit the specimen info after scanning the label and before saving the code, open the dropdown and click the edit button, you can now edit any of the fields.",
            "You can also edit the scanned label information by opening the dropdown and clicking the edit button.",
          ],
        },
        {
          title: "Delete a specimen",
          content: [
            "If you need to delete a specimen while you’re still scanning specimen labels at collection, open the specimen dropdown, and scroll to the delete button.",
            "If you need to delete a specimen after it has been saved, from the ST list, click on the specimen ID. On the specimen tracking history screen, scroll to the bottom and click the delete button.",
          ],
        },
      ],
      issueButton: {
        title: "Is your issue still unresolved?",
        buttonText: "Report An Issue",
      },
      reportAnIssue: {
        title: "Report An Issue",
        text: "Please type a message regarding your issue. Please include all relevant details. We will address this issue as soon as possible and send you an email once it has been resolved.",
        buttonText: "Submit issue",
        emailSubject: "Specimen Tracking App reported issue",
        successfulReportedText: `Thank you for reporting an issue!\nWe will contact you once it has been resolved.`,
        returnToSpecimenTracking: "Return to Specimen Tracking App",
        errorMsg: "Error sending email, please try again.",
      },
    },
    visitDetails: {
      tabRequestDetails: "Request Details",
      tabStaffNotes: "Staff Notes",
    },
    errors: {
      error400: {
        title: "400 Bad Request",
        description: "We’re sorry for the inconvenience.",
        button: "Return to home page",
      },
      error401: {
        title: "401 Unathorized",
        description:
          "We’re sorry for the inconvenience but you are not authorized to view this page.",
        description2: "Please contact the administrator if you think this is in error",
        button: "Return to home page",
      },
      error403: {
        title: "403 Access Forbidden",
        description:
          "We are sorry but you are not authorized. Please contact the administrator if you think this is in error",
        button: "Return to home page",
      },
      error404: {
        title: "404 Page Not Found",
        description: "The page you are trying to access it not available.",
        button: "Return to home page",
      },
      error500: {
        title: "Service unavailable",
        description: "This service is temporarily unavailable. We’re sorry for the inconvenience",
        description2: "We are doing our best to get back online.",
        button: "Return to home page",
      },
    },
    requestDetails: {
      requestedCallback: "Requested Callback",
      requestedLocation: "Requested locations",
      reasonForVisit: "Reason for visit",
      otherReasonForVisit: "Other reason for visit",
      reasonNote: "Reason note",
      startOn: "Start on",
      primaryCarePhysician: "Primary care physician",
      emergencyContactNameAndNumber: "Emergency contact name & number",
      insurancePlan: "Insurance plan",
      insuranceMember: "Insurance member",
      accidentRelated: "Accident related",
      workersCompensation: "Worker's compensation claim",
      encounterId: "Encounter id",
      firstConnectionDate: "First connection date",
      lastConnectionDate: "Last connection date",
      inSessionTime: "In session time",
      previousSurgery: "Previous Surgery",
      currentTreatment: "Current Treatment",
      clinicalTrials: "Clinical Trials",
      additionalInformation: "Additional Information",
      patientImages: "Patient images",
    },
    signinView: {
      existingUserMsg:
        "We found an existing user with this information and sent you a text message to continue.",
      emailSentMsg:
        "An email with the login link has been sent to your registered email. Please click the link in the email to login and continue",
      scheduleCall: "Schedule a Call with Our Team",
      description:
        "We'll discuss your individual care needs, then find the best time for your first appointment. You will not be charged for this call.",
      firstNameLabel: "First Name *",
      lastNameLabel: "Last Name *",
      emailLabel: "Email *",
      birthdateLabel: "Date of Birth *",
      nextButton: "next",
      existingPatient: "Are you an existing patient? ",
      singInLink: "Sign In ",
      phoneRequestAt: "You can also make your request by phone at",
      bannedEmailMsg: "Email is not vaild, please use a different email address",
      cellphoneLoginMsgSent:
        "If you are a existing user, a text message will be sent with a one time password and a link to log in, Please check your text messages to continue.",
    },
    screeningPT: {
      titleScreening: "Diagnosis Information",
      havePrescription: "Do you have a prescription for physical therapy ?",
      yesHavePrescription: "Yes, I have a prescription",
      noHavePrescription: "No, I do not have a prescription",
      popUpNoPrescription:
        "Please consult a physician to get a prescription with your diagnosis prior to scheduling physical therapy.",
      vehicleAccident: "Is your condition related to a motor vehicle accident?",
      workersCompensationClaim: "Is your condition part of a workers' compensation claim?",
      surgeryInformation: "Surgery Information",
      haveSurgery: "Have you had surgery for this condition before?",
      ifHaveSurgery:
        "If you answered “yes” to any of the above, please provide more information here.",
      yesHaveSurgery: "Yes, I had surgery",
    },
    location: {
      locationToContact: "Which location would you like to contact?",
      searchByZipCode: "Search by Zip Code",
      selectLocation: "Select a Location",
      noAvailableLocations: "There is no available locations",
      selectLocationSubtitle:
        "If several locations would work for you, please choose your preferred location below and indicate other options when you speak with our team.",
    },
    closeSesionModal: {
      visitCompleted: "Visit Completed",
      confirmationTitle: "Are you sure that you want to close session?",
      checkOrders: "Please check the orders that correspond to the patient’s visit.",
      otherNotes: "Other Notes",
      appointmentScheduled: "Appointment scheduled for: ",
      heardAboutUs: "Heard about us through: ",
      heardAboutUsSpeciality: "Select a speciality (optional)",
      physicianReferral: "Physician referral (optional): ",
      endSession: "End Session",
      loadingMoreData: "Loading more data",
    },
    Schedule: {
      submitText: "Schedule Call",
    },
    AppointmentScheduled: {
      talkSoon: "Talk to You Soon",
      scheduledConversation: "Your call request has been scheduled. We've sent a confirmation to",
      reachOut1: "Our team will reach out to you at",
      reachOut2: "during the scheduled timeframe.",
      reachOut3: "There is no charge for this call. Thank you.",
    },
    uploadReports: {
      title: "Upload Your Information (optional)",
      subtitle:
        "Upload any medical reports, CT scans, MRI’s or other medical images that you may have. If your imaging is on a CD, please skip this section for now and we will send you a secure link to upload after your call.",
      uploadLabel: "Attach Documents",
    },
    endocrinologySurveys: {
      phoneVerificationTitle: "Endocrinology patient phone verification",
      phoneVerificationDisclaimer:
        "By submitting your phone number, you are giving your consent to our privacy policy. We collect your phone number solely for the purpose of verifying the authenticity of responses. Rest assured that your phone number is consistently encrypted and not accessible to us in plain text.",
    },
    dotForm: {
      patient: {
        licenseScanner: {
          prelude: {
            1: "This form will prepare you for your DOT physical appointment.",
            2: "Your progress will be saved automatically at each step, so you can return to this form at any time.",
            3: "We’ll start by scanning your driver’s license. Click the button below to begin.",
          },
          pageTitle: "Commercial Driver's Medical Certification",
          prompt: "Scan Driver's License",
          upload: "Upload Driver's License Scan",
          confirmation: "Is this an acceptable image? Please ensure all text is clearly legible.",
          mediaError: "Couldn't get a feed from your camera. Did you grant permission?",
          errorModalDescription:
            "There was an error trying to read your driver's license. Try again or enter manually.",
          retakeButton: "Retake",
          uploadButton: "Upload",
          continueButton: "Enter Manually",
          cancelButton: "Try again",
        },
        personalInformation: {
          lastName: "Last Name",
          firstName: "First Name",
          middleInitial: "Middle Initial",
          dateOfBirth: "Date of Birth",
          streetAddress: "Street Address",
          city: "City",
          StateProvince: "State/Province",
          zip: "Zip Code",
          driversLicense: "Driver's License Number",
          issuingState: "Issuing State/Province",
          email: "Email",
          clpcdl: "CLP/CDL Applicant/Holder*",
          deniedMedicalCertificate:
            "Has your USDOT/FMCSA medical certificate ever been denied or issued for less that 2 years ?",
          relationship: "What is your relationship with the Driver / Applicant ?",
          relationshipToDriver: {
            title: "Relationship to Driver",
            selectRelationshipLabel: "Select Relationship",
            relationships: {
              driverApplicant: "DRIVER",
              spouseSignificantOther: "SPOUSE",
              child: "CHILD",
              parent: "PARENT",
              friend: "FRIEND",
              other: "OTHER",
            },
          },
        },
        addressVerification: {
          title: "Confirm Address",
          subtitle: "What is your current address?",
          faqLinkText: "For more information, please visit our Frequently Asked Questions.",
          faqText: "Need Help? View FAQ",
          mutedSubtitle:
            "The address we have on file doesn’t match your driver’s license.  Please select your current address",
          confirmation: {
            severity: "info",
            title: "Your Address has Changed",
            details:
              "At your appointment, please let the front desk know that your address has changed so that we can update your records.",
          },
          warning: {
            severity: "warning",
            title: "Inform the MVC of your Address Change",
            details:
              "If your address has changed, please make sure the NJ Motor Vehicle Commission has your latest address on file.",
          },
        },
        addressConfirmation: {
          title: "Address Confirmation",
          body: "You've successfully scanned your driver's license and personal information. Now, let's move on to your health history.",
        },
        healthHistory: {
          title: "Do you have or have you ever had:",
          concussionQuestion: "Head/brain injuries or illnesses (e.g., concussion)",
          seizuresQuestion: "Seizures/epilepsy",
          eyeProblemsQuestion: "Eye problems (except glasses or contacts)",
          earProblem: "Ear and/or hearing problems",
          heartDiseaseQuestion: "Heart disease, heart attack, bypass, or other heart problems",
          cholesterolQuestion: "High cholesterol",
          breathingProblemsQuestion:
            "Chronic (long-term) cough, shortness of breath, or other breathing problems",
          lungQuestion: "Lung disease (e.g., asthma)",
          kidneyQuestion: "Kidney problems, kidney stones, or pain/problems with urination",
          faintingQuestion: "Fainting or passing out",
          anxietyQuestion: "Anxiety, depression, nervousness, other mental health problems",
          dizzinessQuestion: "Dizziness, headaches, numbness, tingling, or memory loss",
          weightLossQuestion: "Unexplained weight loss",
          strokeQuestion: "Stroke, mini-stroke (TIA), paralysis, or weakness",
          drugsQuestion: "Have you used an illegal substance within the past two years?",
          boneQuestion: "Bone, muscle, joint, or nerve problems",
          bloodClothsQuestion: "Blood clots or bleeding problems",
          cancerQuestion: "Cancer",
          chronicDiseaseQuestion: "Chronic (long-term) infection or other chronic diseases",
          sleepDisordersQuestion:
            "Sleep disorders, pauses in breathing while asleep, daytime sleepiness, loud snoring",
          sleepTestQuestion: "Have you ever had a sleep test (e.g., sleep apnea)",
          nighAtHospitalQuestion: "Have you ever spent a night in the hospital?",
          brokenBoneQuestion: "Have you ever had a broken bone?",
          useTobaccoQuestion: "Have you ever used or do you now use tobacco?",
          pacemakerQuestion: "Pacemaker, stents, implantable devices, or other heart procedures",
          bloodPressureQuestion: "High blood pressure",
          drinkAlcoholQuestion: "Do you currently drink alcohol?",
          stomachQuestion: "Stomach, liver, or digestive problems",
          diabetesQuestion: "Diabetes or blood sugar problems",
          insulinQuestion: "Insulin used?",
          limbsQuestion: "Missing or limited use of arm, hand, finger, leg, foot, toe",
          neckQuestion: "Neck or back problems",
          failedDrugTestQuestion:
            "Have you ever failed a drug test or been dependent on an illegal substance?",
          hadSurgeryQuestion: "Have you ever had surgery?",
          medicationsQuestion:
            "Are you currently taking any medications, including prescription drugs, over-the-counter medicines, herbal remedies, or dietary supplements? Please list each one, separated by commas",
          otherConditionsQuestion: "Other Health Conditions",
          warningModalBodyText:
            "This appointment has already been completed and forms have been generated. Do you still want to edit this health history?",
        },
        confirmation: {
          historyReceived: "Your health history has been received!",
          thankYou: "Thank you for submitting your health history for review.",
          noAdditionalInformation:
            "If any additional information is required, we will reach out to you to let you know.",
          title: "You have succesfully completed all the steps, review the information below.",
          subtitle: "Please bring additional information to your appointment",
          legend:
            "Based on your health history, you will need to bring additional information to your DOT physical appointment for your certification. Please refer to the list below:",
          contactLocation:
            "If you have any questions regarding what is needed for your appointment, please contact the location of your physical.",
          saveOrPrint: "Save or Print this list",
        },
        signature: {
          title: "CMV Driver's Signature",
          body: {
            0: "By signing below, you certify that all health history information you provide in this form is accurate and complete.",
            1: "You understand that inaccurate, false, or missing information may invalidate the examination and your Medical Examiner's Certificate; that submission of fraudulent or intentionally false information is a violation of ",
            2: "49 CFR 390.35",
            3: "; and that submission of fraudulent or intentionally false information may subject you to civil or criminal penalties under ",
            4: "49 CFR 390.37",
            5: " and ",
            6: "49 CFR 386",
            7: " appendices A and B.",
          },
          savedSignature: "Saved Signature",
          signatureSavedCorrectly: "Signature saved correctly",
          errorOcurredSavingSignature:
            "An error occurred while saving the signature. Please try again.",
          noImageSaved: "No image saved",
          replaceSignature: "Replace Signature",
        },
      },
      staff: {
        submissions: {
          title: "DOT Form Submissions",
        },
        common: {
          markAsComplete: "Mark as Complete",
          next: "Next",
          reset: "Reset",
          edit: "Edit",
          saveForm: "Save",
          slider: "Slider",
          list: "List",
          warningModalTitle: "Appointment Warning",
          unauthorizedWarning: "You are not authorized to edit this form",
        },
        dashboard: {
          highlitedResponsesCard: {
            title: "Highlighted Responses",
          },
          medicationsCard: {
            title: "Medications",
            noMedications: "No medications mentioned",
          },
        },
        medicalExaminers: {
          title: "DOT Medical Examiners",
          autoFill: "This field will be filled automatically when you input an NPI",
          inputLabels: {
            email: "Email",
            NPI: "NPI",
            officeLocation: "Office Location",
            firstName: "First Name",
            lastName: "Last Name",
            providerSpecialty: "Provider Specialty",
            nationalRegistryNumber: "National Registry Number",
            certificationNumber: "State License Number",
            issuingState: "Issuing State",
          },
          npiNotFoundError: "Unrecognized NPI",
        },
        medicalDetermination: {
          title:
            "Please select one of the following (Federal or State) Medical Examiner Determination:",
          federalDetermination: "Federal Medical Examiner Determination",
          stateDetermination: "State Medical Examiner Determination",
          driverQualifedFor: "Driver qualified for",
          medicalExaminer: "Medical Examiner",
          medicalExaminerSelectHelper: "Please select a medical Examiner",
          driverQualifiedForoptions: {
            month3: "3 months",
            month6: "6 months",
            other: "Other",
            year1: "1 year",
          },
          warningModal: {
            title: "Appointment Warning",
            body: "This appointment has already been completed and forms have been generated. Do you still want to edit the determination?",
          },
          federal: {
            title: "Federal Medical Examiner Determination",
            questions: {
              question1: "Does not meet standards (specify reason)",
              question2: "Meets standards in 49 CFR 391.41; qualifies for 2 years certificate",
              question3: "Meets standards, but periodic monitoring required (specify reason)",
              question4: "Determination pending (specify reason)",
              question5: "Incomplete examination (specify reason)",
            },
            checkboxList: {
              correctiveLenses: "Wearing corrective lenses",
              hearingAid: "Wearing hearing aid",
              perfomanceEvaluation:
                "Accompanied by a Skill Performance Evaluation (SPE) Certificate",
              cfr39164: "Qualified by operation of 49 CFR 391.64(Federal)",
              intracityZone:
                "Driving within an exempt intracity zone (see 49 CFR 391.62) (Federal)",
              waiverException: "Accompanied by a waiver/exemption",
            },
            determinationPending: {
              returnMedicalExam:
                "Return to medical exam office for follow-up on (must be 45 days or less)",
              medicalExaminationReport: "Medical Examination Report amended (specify reason)",
            },
          },
          state: {
            title: "State Medical Examiner Determination",
            questions: {
              question1:
                "Does not meet standards (specify reason) in 49 CFR 391.41 with applicable state variances",
              question2: "Meets standards in 49 CFR 391.41 with any state variance",
              question3: "Meets standards, but periodic monitoring required (specify reason)",
            },
            checkboxList: {
              correctiveLenses: "Wearing corrective lenses",
              hearingAid: "Wearing hearing aid",
              perfomanceEvaluation:
                "Accompanied by a Skill Performance Evaluation (SPE) Certificate",
              grandFather: "Grandfather from state requirements",
              waiverException: "Accompanied by a waiver/exemption",
            },
          },
        },
        generateReport: {
          title: "Generate Report",
          determinationPdf: "Download PDF Determination Form",
          medicateExaminerPdf: "Download PDF Medical Examiner's Certificate",
        },
      },
      faq: {
        title: "DOT Physical Appointments Frequently Asked Questions",
        copy: [
          {
            heading: "Driver's License and Scanning your License",
            sectionId: "section0",
            content: [
              {
                summary: "My driver's license won't scan.",
                questionId: "section0-question0",
                details:
                  "The camera permissions for your mobile device or computer may not be enabled.  If for some reason you can’t scan your driver's license, you can also upload a picture of your driver's license.",
                links: [],
              },
              {
                summary: "Why do I need to upload my driver's license?",
                questionId: "section0-question1",
                details:
                  "In order to receive your DOT certification, the address on your driver's license must be up to date.  Please make sure the address on your driver's license is correct before coming in for your DOT physical appointment.",
                links: [],
              },
              {
                summary: "My address has changed since my last appointment. What should I do?",
                questionId: "section0-question2",
                details:
                  "If your address has changed, please make sure the Motor Vehicle Commission has your latest address on file before coming to your appointment.",
                links: [
                  {
                    href: "https://www.nj.gov/mvc/drivertopics/addchange.htm",
                    text: "Address Changes at NJ MVC",
                  },
                ],
              },
            ],
          },
          {
            heading: "Additional Forms",
            sectionId: "section1",
            content: [
              {
                summary: "How do I know what to bring to my DOT physical exam?",
                questionId: "section1-question0",
                details:
                  "Once you complete the DOT physical exam form online, you will see a screen letting you know your health history has been received. If you need to bring additional information to your appointment, it will show you a list of instructions regarding what you need to bring to your physical exam appointment. You can email this list to yourself and print it for your records.",
                links: [],
              },
              {
                summary: "Head/Brain Injuries or illnesses",
                questionId: "section1-question1",
                details:
                  "This condition requires a neurology clearance. Please make an appointment with your neurologist and ask them to provide written clearance regarding your condition.",
                links: [],
              },
              {
                summary: "Seizures/Epilepsy",
                questionId: "section1-question2",
                details:
                  "This condition requires a neurology clearance. Please make an appointment with your neurologist and ask them to provide written clearance regarding your condition.",
                links: [
                  {
                    href: "https://www.fmcsa.dot.gov/sites/fmcsa.dot.gov/files/2021-01/NEW%20Seizure%2001252021.pdf",
                    text: "Seizure Clearance Information",
                  },
                ],
              },
              {
                summary: "Ear or hearing problems",
                questionId: "section1-question3",
                details:
                  "If you use hearing aids, please make sure to wear them to your physical appointment.",
                links: [],
              },
              {
                summary:
                  "Heart disease, heart attack, bypass, pacemakers, stents, implantable devices or other heart conditions or heart problems",
                questionId: "section1-question4",
                details:
                  "This condition requires a cardiology clearance. Please make an appointment with your cardiologist and ask them to provide written clearance regarding your condition.",
                links: [],
              },
              {
                summary: "Diabetes or Blood sugar problems",
                questionId: "section1-question5",
                details:
                  "Please bring your HbA1c test results from within the past 3-6 months. Also, make an appointment with your ophthalmologist or optometrist for a vision evaluation, and with your endocrinologist if you use insulin, for an insulin-treated diabetes mellitus assessment.",
                links: [
                  {
                    href: "https://www.fmcsa.dot.gov/sites/fmcsa.dot.gov/files/2023-08/Vision%20Evaluation%20Report%20Form%20MCSA-5871_508.pdf",
                    text: "Vision Evaluation Report Form",
                  },
                  {
                    href: "https://www.fmcsa.dot.gov/sites/fmcsa.dot.gov/files/2023-08/Insulin-Treated%20Diabetes%20Mellitus%20Assessment%20Form%20MCSA-5870_508.pdf",
                    text: "Insulin-Treated Diabetes Mellitus Assessment Form",
                  },
                ],
              },
              {
                summary: "Missing or limited use of arm, hand, finger, leg, foot, or toe",
                questionId: "section1-question6",
                details:
                  "Drivers with physical impairments, or with missing limbs (e.g., a hand or finger, an arm, foot or leg), are required to obtain SPE certificates. Please review the application package.",
                links: [
                  {
                    href: "https://www.fmcsa.dot.gov/medical/driver-medical-requirements/skill-performance-evaluation-certificate-program",
                    text: "Skill Performance Evaluation Certificate Program",
                  },
                ],
              },
              {
                summary: "Sleep Apnea",
                questionId: "section1-question7",
                details:
                  "If you’ve been diagnosed with sleep apnea, please bring something to show C-pap compliancy for 1 year. This can be shown digitally on your mobile device, or with clearance from your pulmonologist.",
                links: [],
              },
            ],
          },
          {
            heading: "General Questions",
            sectionId: "section2",
            content: [
              {
                summary: "About the DOT Physical Exam form",
                questionId: "section2-question0",
                details:
                  "The purpose of the DOT physical exam form is to make sure you have everything you need for the physical exam appointment. By filling out your health history ahead of time, we will let you know if you need to bring additional information to your appointment.",
                links: [],
              },
              {
                summary: "I have another question",
                questionId: "section2-question1",
                details:
                  "If you still have questions regarding your DOT physical exam or the process, please contact the location administering your exam.",
                links: [],
              },
            ],
          },
        ],
      },
    },
    digitalImplants: {
      title: "Digital Implants",
      notFound: "No Digital Implants found.",
      deviceID: "Device ID",
      deviceModel: "Device Model",
      implantType: "Implant Type",
      implantDate: "Implant Date",
      implantLocation: "Implant Location",
      implantHospital: "Implant Hospital",
      implantPhysician: "Physician Information",
      deviceDescription: "Implant Description",
      deviceBrandName: "Device Brand Name",
      devicePublishDate: "Device Publish Date",
    },
    passwordlessLogin: {
      errorEmailLoginLink:
        "Failed to send email login request, please try again.  Your email may not be registered.",
      emailLoginLink: "If your email is registered we sent a login link to your inbox.",
      errorVerifyToken: "Failed to verify login token, please try again.",
      welcome: "Welcome to the AHS Utility App",
      logingYouIn: "Logging you in...",
      welcometoAHSPatientEngagement: "Welcome to the AHS Patient Engagement App",
      readyToLogin: "You are ready to securely go in!",
      SSOLogin: "AHS SSO Login",
      passwordlessLogin: "or passwordless login",
      emailOneTime: "We will email you a one-time magic link.",
      sendEmailoginLink: "Email me the login link",
      mustbeWitheListed: "You must be white listed to get access to this app.",
      emailContactSupport:
        "If you have not been invited or do not get an email contact support\ndigitalinnovation.support@atlantichealth.org.",
    },
    dataCuration: {
      AHSLocations: {
        title: "AHS Locations",
      },
      providerProfile: {
        generalInformation: {
          personalInformation: {
            firstName: "First Name",
            middleName: "Middle Name",
            lastName: "Last Name",
            suffix: "Suffix",
            gender: "Provider Gender",
            npi: "NPI",
            degrees: "Degrees",
            lang: "Languages",
            providerTitle: "Provider Title",
          },
          practice: {
            website: "Practice Website",
            affiliationsLabel: "Affiliations",
            practiceGroupsLabel: "Practice Groups",
            locations: "Practice Locations",
            networks: "Networks",
            affiliations: {
              title: "Credentialed Facilities",
              body: "Atlantic Medical Group, Chilton Medical Center, Newton Medical Center, Hackettstown Medical Center",
            },
            practiceType: {
              title: "Practice Type",
              careTypes: ["Primary Care", "Speciality Care"],
            },
            visitTypes: "Visit Types",
          },
          InsurancesAccepted: {
            title: "Copy 'Insurances Accepted' from another provider profile",
            goButton: "GO",
            insuranceAccepted: "Insurance Accepted",
            updatedInsurance: "UPDATE INSURANCES",
            modalConfirmationText:
              "The list of options above will be added to the list of insurance accepted for",
            modalHeadingText: "Are you sure you want to copy insurance from",
            cancelButton: "Cancel",
          },
          additionalInformation: {
            aboutLabel: "About (Bio)",
            professionalStatement: "Professional Statement",
            professionalInterests: "Professional Interests",
            videoUrl: "Video URL",
            years: "Years in Practice",
            areasOfFocus: "Areas of Focus",
          },
          schedulingInformation: {
            onlineSchProvider: "Online Scheduling Provider",
            onlinebookUrlNewPatient: "Online Book URL (New patient)",
            onlinebookUrlExistingPatient: "Online Book URL (Existing patient)",
            acceptingNewPatients: "Accepting New Patients",
            showInPmc: "Show on Find a Doctor",
            showCTAButton: "Show Call Button",
            ageGroupSeen: {
              title: "Age Group Seen",
              options: ["Pediatric", "Adult"],
            },
            externalId: "Epic ID",
            clinicalContactInfo: "Clinical Contact Information",
          },
          ehridentifiers: {
            systemProviderId: "System Provider ID",
            sourceSystem: "Source System",
            systemType: "System Type",
          },
        },
        experience: {
          education: {
            institution: "Institution",
            description: "Description",
            fieldOfStudy: "Field of Study",
            degree: "Degree",
            year: "Year",
          },
          certifications: {
            certifyingBody: "Certifying Body",
            certificateText: "Certificate",
            yearCertifiedLabelText: "Year Certified",
            issueDateText: "Issue Date",
          },
        },
        clinical: {
          clinical: {
            practiceTypeTitle: "Practice Type",
            providerTypeTitle: "Provider Type",
            primarySpecialityTitle: "Primary Speciality",
            primarySpecialityHelperText:
              "This will be the first specialty displayed in the provider's details.",
            secondarySpecialityTitle: "Secondary Speciality",
            specialtyServicesLabel: "Specialty Services",
            claimedSpecialityTitle: "Claimed Specialities",
            claimedSpecialityHelperText:
              "Claimed specialties are consumer-friendly terms, and reflect the provider’s preferred areas of expertise.",
            primarySpeciality: {
              options: [
                "Cardiology",
                "Dermatology",
                "Endocrinology",
                "Family Medicine",
                "Gastroenterology",
                "General Surgery",
                "Hematology",
                "Infectious Disease",
                "Internal Medicine",
                "Nephrology",
                "Neurology",
                "Obstetrics & Gynecology",
                "Oncology",
                "Ophthalmology",
                "Orthopedics",
                "Otolaryngology",
                "Pain Management",
                "Pediatrics",
                "Physical Medicine & Rehabilitation",
                "Plastic Surgery",
                "Podiatry",
                "Psychiatry",
                "Pulmonology",
                "Radiology",
                "Rheumatology",
                "Urology",
              ],
            },
            secondarySpeciality: {
              options: [
                "Cardiology",
                "Dermatology",
                "Endocrinology",
                "Family Medicine",
                "Gastroenterology",
                "General Surgery",
                "Hematology",
                "Infectious Disease",
                "Internal Medicine",
                "Nephrology",
                "Neurology",
                "Obstetrics & Gynecology",
                "Oncology",
                "Ophthalmology",
                "Orthopedics",
                "Otolaryngology",
                "Pain Management",
                "Pediatrics",
                "Physical Medicine & Rehabilitation",
                "Plastic Surgery",
                "Podiatry",
                "Psychiatry",
                "Pulmonology",
                "Radiology",
                "Rheumatology",
                "Urology",
              ],
            },
            conditionsAndSymptoms: {
              title: "Conditions & Symptoms",
              subitle: "Select up to 5 conditions or symptoms",
              options: [
                "Acne",
                "ADHD",
                "Allergies",
                "Anxiety",
                "Arthritis",
                "Asthma",
                "Back Pain",
                "Bipolar Disorder",
                "Birth Control",
                "Depression",
                "Diabetes",
                "Erectile Dysfunction",
                "Heartburn",
                "High Blood Pressure",
                "High Cholesterol",
                "Insomnia",
                "Migraines",
                "Obesity",
                "Osteoporosis",
                "Sinus Infections",
                "Skin Cancer",
                "Sleep Apnea",
                "Thyroid Disorders",
                "Ulcers",
                "UTIs",
                "Yeast Infections",
              ],
            },
          },
          conditionsAndSymptoms: {
            title: "Conditions & Symptoms",
            subtitle: "These keywords are used to help patients find this provider.",
            subtitle2:
              "Search to add conditions, symptoms, treatments and procedures that describe the provider's clinical focus.",
            options: [
              "Acne",
              "ADHD",
              "Allergies",
              "Anxiety",
              "Arthritis",
              "Asthma",
              "Back Pain",
              "Bipolar Disorder",
            ],
            autocompleteOptions: [
              "Birth Control",
              "Depression",
              "Diabetes",
              "Erectile Dysfunction",
              "Heartburn",
              "High Blood Pressure",
              "High Cholesterol",
              "Insomnia",
              "Migraines",
              "Obesity",
              "Osteoporosis",
              "Sinus Infections",
              "Skin Cancer",
              "Sleep Apnea",
              "Thyroid Disorders",
              "Ulcers",
              "UTIs",
              "Yeast Infections",
            ],
          },
        },
        notesAndAdditionalInfo: {
          notes: {
            listingNotes: "Listing Notes",
            internalNotes: "Internal Notes",
          },
          additionalInformationLabels: [
            { label: "Research URL", key: "researchUrl" },
            { label: "Featured Health News Link", key: "featuredHealthNews" },
          ],
          echoProviderIdLabel: "Echo Provider ID",
          epicIdLabel: "Epic ID",
        },
      },
      location: {
        newLocation: {
          title: "Create New Location",
          subtitle:
            "Lets start by adding the basic of this location, Please fill out the fields below",
          nameSection: "Name",
          addressSection: "Address",
          cancelBtn: "Cancel",
          saveLocationBtn: "Create Location",
          reviewLocation: "Review new Location",
        },
        generalInfo: {
          nameSection: {
            locationIdTitle: "Location Id",
            locationSection: {
              id: "00889 Prefred Location",
            },
            locationTypeTitle: "Location Type",
            parentLocationLabel: "Parent Location",
            shortName: {
              title: "Short Name",
              helperText: "Short name will be use for generating the Location Page Name (URL).",
            },
            locationName: {
              title: "Location Name",
              helperText: "Display name on location cards and search results.",
            },
            locationPageTitle: {
              title: "Location Page Title",
              helperText:
                "This should be unique for each location, and includes geographic information.",
            },
            locationPageName: {
              title: "Location Page Name",
              helperText: "Short description of what the location is.",
            },
            webUrlField: {
              title: "Location Page Name (URL)",
              helperText:
                "Please fill Short name field first, since is required for the location URL.",
            },
            phoneNumber: {
              title: "Phone",
              helperText: "Location phone.",
            },
          },
          addressSection: {
            buildingNameLabel: "Building Name",
            address1Label: "Street Address 1",
            address2Label: "Street Address 2",
            suiteLabel: "Suite",
            cityLabel: "City",
            stateLabel: "State",
            zipLabel: "Zip",
            latLabel: "Latitude",
            longLabel: "Longitude",
            googleMapUrlLabel: "Google Map URL",
          },
          contactInfoSection: {
            phoneLabel: "Phone",
            faxLabel: "Fax",
            emailLabel: "Email",
            websiteLabel: "Web Url",
            phoneErrorMsg: "Please enter a valid phone number.",
          },
          otherInfo: {
            commercialEntityLabel: "Commercial Entity",
            billingTinLabel: "Billing TIN",
            shortDescriptionLabel: "Short Description",
            factilityFeeLabel: "Facility Fee",
            insurancesAcceptedLabel: "Insurances Accepted",
            alertMessageLabel: "Alert Message",
            overrideMessageLabel: "Override Message",
          },
        },
        hoursAndSchedule: {
          disclaimerHeader: "All hours are in Eastern Standard Time",
          titleHeader: "Hours and Schedule",
        },
        providersAtThisLocation: {
          searchLabel: "Search Providers By LastName",
          enableAsPracticeLocation: "Enable as Practice Location",
          enableAsPracticeLocationTooltip:
            "When enabled, this location can be listed as a practice location.",
        },
        common: {
          save: "Save",
          cancel: "Cancel",
          enableOnLocationFinder: {
            title: "Enable on location finder?",
            options: [
              {
                title: "Enable on Location Finder",
                subtitle: "Location will be shown on the AHS website",
                value: true,
              },
              {
                title: "Disable on Location Finder",
                subtitle: "Location will NOT be shown on the AHS website",
                value: false,
              },
            ],
          },
          enableasPracticeLocationProvider: {
            title: "Enable as a practice location for providers?",
            options: [
              {
                title: "Enable as a practice Location",
                subtitle: "Providers can have this location shown as a practice location",
                value: true,
              },
              {
                title: "Disable as a practice Location",
                subtitle: "Providers can NOT have this location shown as a practice location",
                value: false,
              },
            ],
          },
        },
      },
    },
    SignaturePad: {
      clear: "Clear Signature",
      save: "Save Signature",
      emptySignatureError: "The signature is empty",
    },
  },
};
