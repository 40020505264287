import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return isMobile;
};

export const isMobile = (() => {
  if (typeof navigator === "undefined" || typeof navigator.userAgent !== "string") {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const isWebView = navigator?.userAgent.includes("MobileWebView");

function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}

// Recursively removes any object keys with a value of undefined

export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) {
    return obj;
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== "undefined") {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export async function getDeviceInfo() {
  if (
    navigator.mediaDevices?.enumerateDevices &&
    typeof navigator.mediaDevices.enumerateDevices === "function"
  ) {
    const devices = await navigator.mediaDevices.enumerateDevices();

    return {
      audioInputDevices: devices.filter((device) => device.kind === "audioinput"),
      videoInputDevices: devices.filter((device) => device.kind === "videoinput"),
      audioOutputDevices: devices.filter((device) => device.kind === "audiooutput"),
      hasAudioInputDevices: devices.some((device) => device.kind === "audioinput"),
      hasVideoInputDevices: devices.some((device) => device.kind === "videoinput"),
    };
  }
  return {
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: PermissionName) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name });
      return result.state === "denied";
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export const createCYidfromText = (text) => {
  return text?.replaceAll(/[^a-zA-Z]/g, "");
};
