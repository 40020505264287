export const states = [
  "AL",
  "АК",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "МА",
  "MI",
  "MN",
  "MS",
  "МО",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "ОН",
  "ОК",
  "OR",
  "РА",
  "RI",
  "SC",
  "SD",
  "TN",
  "ТХ",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const sexes = ["Female", "Male", "Non-binary", "Other", "Unknown", "X"];

export const races = [
  "American Indian or Alaska Native",
  "Asian",
  "Asian Indian",
  "Black or African American",
  "Chinese",
  "Filipino",
  "Guamian",
  "Japanese",
  "Korean",
  "Multi Black Indian",
  "Multi White Asian",
  "Multi White Black",
  "Multi White Indian",
  "Native Hawaiian or Other Pacific Islander",
  "Samoan",
  "Vietnamese",
  "White or Caucasian",
  "Other",
  "Unknown",
  "Prefer not to answer",
];

export const ethnicitis = [
  "Ashkenazi Jewish",
  "Central-S American",
  "Cuban",
  "Hispanic or Latino",
  "Mexican",
  "Not Hispanic or Latino",
  "Puerto Rican",
  "Unknown",
  "Prefer not to answer",
];
