import React, { FC } from "react";
import { Card, Grid, Typography } from "@mui/material";
import { RNWebView } from "util/RNWebView";
import useStyles from "./AppoitmentDetails.styles";

interface Props {
  instruction: string;
}

const AppoitmentLocation: FC<Props> = ({ instruction }) => {
  const { classes } = useStyles();

  return (
    <Card elevation={24} className={classes.card}>
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Typography align="left" className={classes.textLocation}>
            Location
          </Typography>
        </Grid>
        <Grid item>
          <iframe
            width="100%"
            height={RNWebView ? "160" : "450"}
            loading="lazy"
            title="appointment"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCoCkEl0L3FilimbHGywQerFAcUf1MKBwY
            &q=United States of America+NJ+140 Central Avenue"
          />
        </Grid>
        <Grid item>
          <Typography align="left" className={classes.textLocation}>
            Resources
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="left" className={classes.text}>
            {instruction}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AppoitmentLocation;
