import { makeStyles } from "tss-react/mui";

// =====================================================================================
// =====================================================================================
export const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    minWidth: 320,
    backgroundColor: "transparent",
  },
  box: {
    background:
      "linear-gradient(0deg, rgba(23,26,91,1) 0%, rgba(23,26,91,1) 60%, rgba(0,212,255,1) 100%)",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.primary,
  },
  media: {
    height: "100px",
    width: "100px",
    margin: "auto",
    border: `3px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
  },
  header: {
    color: theme.palette.secondary.main,
    textAlign: "center",
  },
  cardMain: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    margin: "auto",
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "16pt",
    textDecoration: "none",
  },
  backgroundSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
}));
// =====================================================================================
// =====================================================================================
export const useTimeLineStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
  },
  paper: {
    background: "#f6f6f6",
    boxShadow: "none",
    padding: 10,
    textAlign: "left",
    marginTop: -6,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
  },
  subtitle: {
    fontSize: 14,
    color: "#888",
  },
  subText: {
    fontSize: 14,
  },
  box: {
    fontSize: 12,
  },
  separ: {
    marginTop: 8,
  },
  opposite: {
    flex: "0 !important",
  },
  verticalLine: {
    backgroundColor: "blue !important",
    width: "1px !important",
  },
  checkCircle: {
    color: "blue",
  },
  timeLineItem: {
    margin: "10px 0px",
  },
}));
// =====================================================================================
// =====================================================================================
export const useHeaderStyles = makeStyles()((theme) => ({
  root: {
    height: 20,
    margin: "10px 0",
    padding: "15px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
  },
  verified: { fontSize: "10px", fontWeight: "bold", letterSpacing: "1.5px" },
  img: {
    width: "120px",
    background: "transparent",
    height: "auto",
    margin: "2px 10px 0px 10px",
  },
}));
// =====================================================================================
// =====================================================================================
export const useTimeLineHeaderStyles = makeStyles()(() => ({
  timeLineHeader: {
    fontSize: "10px",
    letterSpacing: "1.5px",
    fontWeight: "900",
    marginBottom: "5px",
    marginLeft: -22,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
  },
  subtitle: {
    textAlign: "left",
    fontSize: 10,
    marginBottom: 5,
    color: "#888",
  },
}));
// =====================================================================================
// =====================================================================================
export const usePassportStyles = makeStyles()((theme) => ({
  formRoot: {
    "& .MuiTextField-root": {
      fontSize: 10,
      [theme.breakpoints.down("lg")]: {
        width: 150,
      },
    },
    "& .MuiInputLabel-formControl": {
      fontSize: 12,
      top: 5,
    },
  },
  inputDiv: {
    left: -13,
  },
  btn: {
    top: 10,
    left: -13,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
  },
  subtitle: {
    fontSize: 12,
    fontWeight: "normal",
    textAlign: "left",
  },
}));
// =====================================================================================
// =====================================================================================
