/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Theme from "Theme";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DnsIcon from "@mui/icons-material/Dns";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useAppState } from "../../state";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import PatientProfile from "./WaitingRoom/PatientProfile";
import { preJoinStyles } from "./preJoinStyles";
import WaitingUsersList from "components/Video/components/PreJoinScreensPhysician/WaitingRoom";
import { WaitingRoomTab } from "util/constants";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { TOGGLE_QUEUE_TAB } from "../../hooks/useVirtualVisitReducer/Actions";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "../../../../util/AppContext";
import { useIsMobile } from "util/deviceUtils";

export enum Steps {
  roomNameStep,
  visitReason,
  visitPlace,
  deviceSelectionStep,
  waitingRoomStep,
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const app = useApp();
  const { classes } = preJoinStyles();

  const virtualSessionId = app.vs_visits_state.currentVirtualVisit?.id;
  const { currentTab } = app.vs_visits_state;
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName, virtual_session_id } = useParams<{
    URLRoomName: string;
    virtual_session_id: string;
  }>();
  const [step, setStep] = useState(Steps.waitingRoomStep);
  const params = new URLSearchParams(window.location.search);
  const viewReport = params.get("viewReport");
  const vsId = app?.vs_visits_state?.joinVirtualSessionId || virtual_session_id;
  const isMobile = useIsMobile();
  const name = user?.displayName || "";
  const [mediaError, setMediaError] = useState<Error>();
  const { isStaff } = useUserType();

  useEffect(() => {
    if (URLRoomName) {
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (vsId) {
      setStep(Steps.deviceSelectionStep);
    }
  }, [vsId]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        setMediaError(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, mediaError]);

  const handleTabChange = (event, newValue) => {
    app.dispatch({
      type: TOGGLE_QUEUE_TAB,
      payload: newValue,
    });
  };

  if (isMobile && step === Steps.deviceSelectionStep) {
    return (
      <>
        <MediaErrorSnackbar error={mediaError} />
        <DeviceSelectionScreen
          roomName={virtual_session_id || virtualSessionId}
          name={name}
          setStep={setStep}
        />
      </>
    );
  }

  if (isMobile) {
    return (
      <div className={classes.root}>
        {currentTab === WaitingRoomTab.QUEUE && <WaitingUsersList />}
        {currentTab === WaitingRoomTab.PATIENT && isStaff && <PatientProfile />}
        {viewReport !== "true" && (
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="fullWidth"
            scrollButtons
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
            style={{
              position: "fixed",
              bottom: 0,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              border: "1px solid rgba(0,0,0,0.11)",
              backgroundColor: Theme.palette.common.white,
            }}
            allowScrollButtonsMobile
          >
            <Tab label="Queue" icon={<DnsIcon />} {...a11yProps(0)} />
            <Tab label="Patient" icon={<AssignmentIndIcon />} {...a11yProps(4)} />
          </Tabs>
        )}
      </div>
    );
  }
  return (
    <>
      {mediaError && <MediaErrorSnackbar error={mediaError} />}
      {step === Steps.waitingRoomStep && (
        <div className={classes.physicianQueue}>
          <div className={classes.waitingRoomCont}>
            <WaitingUsersList />
          </div>
          <div className={classes.smScreenScroll}>{isStaff && <PatientProfile />}</div>
        </div>
      )}

      {!isMobile && step === Steps.deviceSelectionStep && (
        <>
          <MediaErrorSnackbar error={mediaError} />
          <DeviceSelectionScreen
            name={name}
            roomName={virtual_session_id || virtualSessionId}
            setStep={setStep}
          />
        </>
      )}
    </>
  );
}
