import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { MobileNavigationLink } from "../MobileNavigationLink";
import { makeStyles } from "tss-react/mui";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import CONSTANTS from "../constants";
import useTranslation from "../../../hooks/useTranslation";
import CareersIcon from "../../../static/svg/mobile/careers.svg";
import ContactUsIcon from "../../../static/svg/mobile/contactus.svg";
import CovidIcon from "../../../static/svg/mobile/covid.svg";
import MakeDonationIcon from "../../../static/svg/mobile/donation.svg";
import HeartRiskAssessmentIcon from "../../../static/svg/mobile/heartriskassesment.svg";
import InsuranceIcon from "../../../static/svg/mobile/insurance.svg";
import LabWorkIcon from "../../../static/svg/mobile/labwork.svg";
import MakeAppointmentIcon from "../../../static/svg/mobile/makeappointment.svg";
import MyChartIcon from "../../../static/svg/mobile/mychart.svg";
import PayMyBillIcon from "../../../static/svg/mobile/paymybill.svg";
import PrivacyPolicyIcon from "../../../static/svg/mobile/privacy.svg";
import WebsiteIcon from "../../../static/svg/mobile/website.svg";

const useStyles = makeStyles()(() => ({
  container: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2em",
    padding: "3em 2em",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    boxShadow: "none",
    border: "none",
    flex: "none",
    alignItems: "center",
    justifyContent: "start",
    padding: "0",
  },
  icon: {
    width: "1.5em",
    height: "1.5em",
    marginRight: "1em",
  },
  buttonText: {
    fontSize: "1.1em",
    color: "#171a5b",
    fontWeight: 500,
  },
}));

const More = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { mobile } = useTranslation();
  const {
    MyChart,
    PayMyBill,
    Insurance,
    ClassesEvents,
    HeartRiskAssessment,
    ResearchClinicalTrials,
    Careers,
    MakeDonation,
    PrivacyPolicy,
    ContactUs,
    COVID19,
    ViewOurFullWebsite,
  } = mobile;

  const moreLinks: Array<MobileNavigationLink> = [
    {
      destination: "myatlantic://openlogin",
      icon: MyChartIcon,
      title: MyChart,
      shouldPushNavigation: false,
      testId: "more-view-my-chart-button",
    },
    {
      destination: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.BILLING}`,
      icon: PayMyBillIcon,
      title: PayMyBill,
      shouldPushNavigation: true,
      testId: "more-view-pay-my-bill-button",
    },
    {
      destination:
        "https://www.atlantichealth.org/patients-visitors/financial-information/insurance.html",
      icon: InsuranceIcon,
      title: Insurance,
      shouldPushNavigation: false,
      testId: "more-view-insurance-button",
    },
    {
      destination: "https://www.atlantichealth.org/search-results.html#tab=events-tab",
      icon: MakeAppointmentIcon,
      title: ClassesEvents,
      shouldPushNavigation: false,
      testId: "more-view-classes-events-button",
    },
    {
      destination:
        "https://atlantichealth.org/conditions-treatments/heart-care/heart-risk-assessment.html",
      icon: HeartRiskAssessmentIcon,
      title: HeartRiskAssessment,
      shouldPushNavigation: false,
      testId: "more-view-health-risk-assessment-button",
    },
    {
      destination: "https://www.atlantichealth.org/about-us/research-clinical-trials.html",
      icon: LabWorkIcon,
      title: ResearchClinicalTrials,
      shouldPushNavigation: false,
      testId: "more-view-research-clinical-trials-button",
    },
    {
      destination: "https://jobs.atlantichealth.org/",
      icon: CareersIcon,
      title: Careers,
      shouldPushNavigation: false,
      testId: "about-view-careers-button",
    },
    {
      destination: "https://www.atlantichealth.org/patients-visitors/donate-volunteer.html",
      icon: MakeDonationIcon,
      title: MakeDonation,
      shouldPushNavigation: false,
      testId: "about-view-make-donation-button",
    },
    {
      destination: "https://www.atlantichealth.org/privacy-policy.html",
      icon: PrivacyPolicyIcon,
      title: PrivacyPolicy,
      shouldPushNavigation: false,
      testId: "about-view-privacy-policy-button",
    },
    {
      destination: "https://www.atlantichealth.org/contact.html",
      icon: ContactUsIcon,
      title: ContactUs,
      shouldPushNavigation: false,
      testId: "more-view-contact-us-button",
    },
    {
      destination: "https://www.atlantichealth.org/conditions-treatments/coronavirus-covid-19.html",
      icon: CovidIcon,
      title: COVID19,
      shouldPushNavigation: false,
      testId: "more-view-covid-button",
    },
    {
      destination: "https://www.atlantichealth.org/",
      icon: WebsiteIcon,
      title: ViewOurFullWebsite,
      shouldPushNavigation: false,
      testId: "more-view-view-full-website-button",
    },
  ];

  const handleOnClick = useCallback(
    (navigateTo, navigateFrom, shouldPushNavigation) => {
      if (shouldPushNavigation) {
        navigate(navigateTo, { state: { from: navigateFrom } });
        return;
      }
      window.open(navigateTo, "_blank");
    },
    [navigate],
  );

  return (
    <PageContainer>
      <div className={classes.container}>
        {moreLinks.map(({ destination, icon, title, shouldPushNavigation, testId }) => (
          <Button
            key={title}
            className={classes.buttonContainer}
            onClick={() => handleOnClick(destination, CONSTANTS.MORE, shouldPushNavigation)}
            data-cy={testId}
          >
            <img src={icon} className={classes.icon} alt="" />
            <Typography className={classes.buttonText}>{title}</Typography>
          </Button>
        ))}
      </div>
    </PageContainer>
  );
};
export default More;
