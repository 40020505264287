import { useContext } from "react";
import { GenericEnhancedTableContext } from "components/GenericEnhancedTableContext";

export const useGenericEnhancedTableContext = () => {
  const context = useContext(GenericEnhancedTableContext);
  if (!context) {
    throw new Error("useTableContext must be used within a TableProvider");
  }
  return context;
};
