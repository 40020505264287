/** @format */

export const tabs = [
  {
    label: "Summary",
    key: 0,
  },
  {
    label: "Review",
    key: 1,
  },
  {
    label: "Request",
    key: 2,
  },
  {
    label: "ID",
    key: 3,
  },
  {
    label: "Scores",
    key: 4,
  },
  {
    label: "crosscheck",
    key: 5,
  },
  {
    label: "aamva",
    key: 6,
  },
  {
    label: "watchlist",
    key: 7,
  },
  {
    label: "insights",
    key: 8,
  },
];
export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
export const mockData = {
  data: {
    user_id: 12,
    tracking_id: 234232,
    event_type: "test",
    event_name: "testing",
    content: "",
    context: "",
    created_at: "",
    updated_at: "",
    reviewed: "",
    status: "",
    valid_id: "",
    valid_selfie: "",
    face_match: "",
    expiration_date: "",
    cross_check: "",
    selfie_uri: "",
    driver_license_uri: "",
    ipaddress: "",
  },
};
