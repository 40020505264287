/* eslint-disable */
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { Theme } from "@mui/material";
import ChatWindow from "../ChatWindow/ChatWindow";
import MainParticipant from "../MainParticipant/MainParticipant";
import ParticipantList from "../ParticipantList/ParticipantList";
import WaitingMessageDialog from "./WaitingMessageDialog";
import { makeStyles } from "tss-react/mui";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { CONNECT_VIRTUAL_SESSION } from "../../hooks/useVirtualVisitReducer/Actions";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "../../../../util/AppContext";
import { calculatePathBase } from "../../../../util/util_functions";

const ADHOC_PROGRAM_TYPE = "adhoc_virtual";

const useStyles = makeStyles()((theme: Theme) => {
  const totalMobileSidebarHeight = `${90 + 8 * 2 + 2}px`;
  return {
    container: {
      position: "relative",
      display: "grid",
      gridTemplateColumns: `1fr 300px`,
      gridTemplateRows: "100%",
      height: "calc(100vh - 143px)",

      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
        height: "calc(100vh - 300px)",
      },
    },
    chatWindowOpen: { gridTemplateColumns: `1fr 300px 320px` },
    label: {
      color: theme.palette.common.white,
    },
  };
});

export default function Room() {
  const { classes, cx } = useStyles();
  const { isChatWindowOpen, messages } = useChatContext();
  const app = useApp();
  const navigate = useNavigate();
  const location = useLocation();
  const { room } = useVideoContext();
  const { isPatient } = useUserType();
  const intervalRef = useRef<any>();
  const currentProgramType = app.vs_visits_state.currentVirtualVisit?.program?.program_type;
  const isAdhoc = currentProgramType === ADHOC_PROGRAM_TYPE;

  const onBlurRoomDisconnect = function () {
    const pathBase = calculatePathBase(location.pathname);
    if (document.visibilityState === "hidden") {
      intervalRef.current = setTimeout(() => {
        const vsId = parseInt(room!.name);
        if (room) {
          room!.disconnect();
        }
        app.setVideoFinished(true);
        navigate(`${pathBase}/${vsId}?step=preview`);
        app.dispatch({
          type: CONNECT_VIRTUAL_SESSION,
          payload: null,
        });
        if (!isPatient) {
          window.location.href = "/virtual-session/end-call";
        } else {
          window.location.reload();
        }
      }, 120000);
    }
    if (document.visibilityState === "visible") {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (isPatient) {
      // disconnect user from virtual session on blur event
      window.addEventListener("visibilitychange", onBlurRoomDisconnect);
    }
    return () => {
      if (isPatient) {
        window.removeEventListener("visibilitychange", onBlurRoomDisconnect);
      }
    };
    // eslint-disable-next-line
  }, [isPatient]);

  return (
    <div
      className={cx(classes.container, {
        [classes.chatWindowOpen]: isChatWindowOpen,
      })}
    >
      <MainParticipant />
      <ParticipantList />
      {isPatient && !isAdhoc && <WaitingMessageDialog isChatWindowOpen={isChatWindowOpen} />}
      <ChatWindow messages={messages} />
    </div>
  );
}
