import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 80,
  },
  iconContainerOn: {
    borderRadius: "50%",
    backgroundColor: "#0B79D0",
    padding: 8,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainerOff: {
    borderRadius: "50%",
    backgroundColor: theme.palette.grey[300],
    padding: 8,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconOn: {
    fill: "white",
    width: "22px",
    height: "22px",
  },
  iconOff: {
    fill: theme.palette.grey[600],
    width: "22px",
    height: "22px",
  },
}));
