import React, { useState } from "react";
import { CheckBox, CheckBoxOutlined, MoreVert } from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Checkbox,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { TableHeadProps } from "./utils/types";

const icon = <CheckBoxOutlined fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

function TableHeadDropdownFilter({
  showMenu,
  options,
  classes,
  label,
  selectedOptions,
  onOptionChange,
}) {
  return (
    <div>
      {showMenu && (
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={options}
          disableCloseOnSelect
          value={selectedOptions}
          onChange={(event, newValue) => onOptionChange(newValue)}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </>
          )}
          style={{
            width: 280,
            position: "absolute",
            backgroundColor: "white",
            left: 0,
            top: "3.8em",
          }}
          size="small"
          ListboxProps={{ className: classes.listbox }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={label} size="small" />
          )}
        />
      )}
    </div>
  );
}

const TableHeader: React.FC<TableHeadProps> = (props: TableHeadProps) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rows,
    colDefs,
    allowSelection,
  } = props;
  const createSortHandler = (property) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});

  const getFilterDropdownOptions = (columnId) => {
    return [...new Set(rows.map((item) => item[columnId]))];
  };

  const handleMenuToggle = (id) => {
    setOpenDropdown((prevOpenDropdown) => (prevOpenDropdown === id ? null : id));
  };

  const handleOptionChange = (columnId, options) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [columnId]: options,
    }));
  };

  return (
    <TableHead>
      <TableRow>
        {allowSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              data-testid="select-all-checkbox"
            />
          </TableCell>
        )}
        {colDefs.map(
          ({ id, disablePadding, label, showDropdownFilter, searchByValue, isSorteable }) => (
            <TableCell
              key={id}
              align="left"
              padding={disablePadding ? "none" : "normal"}
              sortDirection={orderBy === id ? order : false}
              style={{ padding: "16px" }}
            >
              {isSorteable ? (
                <TableSortLabel
                  active={orderBy === searchByValue}
                  direction={orderBy === searchByValue ? order : "asc"}
                  onClick={createSortHandler(searchByValue)}
                >
                  {label}
                  {orderBy === id ? (
                    <span className={classes?.visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{label}</span>
              )}

              {showDropdownFilter && (
                <>
                  <IconButton
                    style={{ marginLeft: "16px" }}
                    size="small"
                    onClick={() => handleMenuToggle(id)}
                    data-testid="dropdown-filter-button"
                  >
                    <Badge color="secondary" badgeContent={selectedOptions[id]?.length || 0}>
                      <MoreVert />
                    </Badge>
                  </IconButton>
                  <TableHeadDropdownFilter
                    label={label}
                    showMenu={openDropdown === id}
                    options={getFilterDropdownOptions(id)}
                    classes={classes}
                    selectedOptions={selectedOptions[id] || []}
                    onOptionChange={(options) => handleOptionChange(id, options)}
                  />
                </>
              )}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
