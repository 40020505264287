import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import CreateAppointmentDialog from "./CreateAppointmentDialog";
import EditDriverPhoneNumberModal from "./EditDriverPhoneNumberModal";
import { colDefs } from "./colDefs";
import { makeStyles } from "tss-react/mui";
import { DotFormStatus } from "./constants";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import { CustomActionButton } from "components/GenericEnhancedTable/types";
import { permissions } from "components/shared/permissions";
import { Features } from "util/constants/config";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { hasFeature } from "util/hasFeature";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    paddingTop: "1rem",
  },
  title: {
    fontSize: "24px",
    fontWeight: 400,
  },
}));

const FORM_GENERATION_STEP_INDEX = 2;

const submissionButton: Omit<CustomActionButton, "onClick"> = {
  key: "view-submission-button",
  "data-cy": "view-submission-button",
  "data-testid": "view-submission-button",
  startIcon: <InsertDriveFile />,
  text: "Submission",
  color: "secondary",
};

const sendTexts = async (formIds) => {
  const promises = formIds.map((id) => axiosClient.get(`/api/s/console/dot-form/${id}/send`));
  const results = await Promise.all(promises);

  return results;
};

const FutureApptsOnlyToggle = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <FormControlLabel label="Future Appointments Only" control={<Switch {...props} />} />
    </div>
  );
};

const DOTFormSubmissions = () => {
  const { classes } = useStyles();
  const params = new URLSearchParams(window.location.search);
  const mrn = params.get("emr_mrn");
  const navigate = useNavigate();
  const app = useApp();
  const enableAddNew = hasFeature(Features.DOT_ADD_APPOINTMENT);
  const enableDownload = hasFeature(Features.DOT_DOWNLOAD_FORM);
  const {
    web: {
      dotForm: {
        staff: { submissions: copy },
      },
    },
  } = useTranslation();

  const [futureApptsOnly, setFutureApptsOnly] = useState(!mrn);
  const [addingNew, setAddingNew] = useState(false);
  const [modifyPhone, setModifyPhone] = useState(false);
  const [userData, setUserData] = useState<{
    userId: number;
    phoneNumber: string;
    driverFullName: string;
  }>({
    userId: 0,
    phoneNumber: "",
    driverFullName: "",
  });

  const urlParams = {
    futureApptsOnly,
    ...(mrn
      ? {
          search_by: "mrn",
          search_text: encodeURIComponent(mrn),
        }
      : {}),
  };

  const tableColDefs = mrn
    ? colDefs.map((col) => ({
        ...col,
        searchable: ["date", "formStatus", "locationName"].includes(col.id),
      }))
    : colDefs;

  const titleButtons = !enableAddNew
    ? []
    : [
        {
          name: "Add New",
          action: () => setAddingNew(true),
          icon: <AddIcon style={{ color: "#fff", width: 25, height: 25 }} />,
          dataCy: "add-dot-form",
        },
      ];

  const handleSendReminders = React.useCallback(
    (ids) => {
      const plural = ids.length > 1 ? "texts" : "text";
      const sending = app.addInfoMsg(`Sending ${ids.length} ${plural}...`);
      sendTexts(ids)
        .then(() => {
          app.closeSnackbar(sending);
          app.addInfoMsg(`Sent ${ids.length} ${plural}!`);
        })
        .catch((err) => {
          console.error(err);
          app.addError(`Error while sending ${plural}`);
        });
    },
    [app],
  );

  const handleEditPhone = React.useCallback((submission) => {
    const { userId, firstName, lastName, cellphone } = submission[0];
    setUserData({
      userId,
      driverFullName: `${firstName} ${lastName}`,
      phoneNumber: cellphone,
    });
    setModifyPhone(true);
  }, []);

  const makeCustomActions = (row) => {
    const actions: CustomActionButton[] = [];
    actions.push({
      ...submissionButton,
      onClick: () =>
        navigate(
          `/console/${permissions.dotForm}/dot-form-staff/submission/${row.formId}?userId=${row.userId}`,
        ),
    });
    if (
      enableDownload &&
      row.formStatus &&
      [DotFormStatus.SUBMITTED, DotFormStatus.COMPLETED].includes(DotFormStatus[row.formStatus])
    ) {
      actions.push({
        key: `form-redirect-button-${row.formId}`,
        "data-cy": `form-redirect-button-${row.formId}`,
        "data-testid": `form-redirect-button-${row.formId}`,
        onClick: () => {
          navigate(
            `/console/${permissions.dotForm}/dot-form-staff/submission/${row.formId}/${FORM_GENERATION_STEP_INDEX}`,
          );
        },
        startIcon: <OpenInNewIcon />,
        text: "Forms",
      });
    }
    return actions;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let timeoutId: ReturnType<typeof setTimeout>;
    const handlePopState = () => {
      timeoutId = setTimeout(() => window.scrollTo(0, 0), 0);
      return null;
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      clearTimeout(timeoutId);
      return window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      {modifyPhone && (
        <EditDriverPhoneNumberModal
          open={modifyPhone}
          onClose={() => setModifyPhone(false)}
          userData={userData}
          handleSaved={() => setModifyPhone(false)}
        />
      )}
      <div className={classes.root} data-testid="dot-form-staff">
        <Grid container className={classes.root} alignItems="center" justifyContent="center">
          <Typography variant="h2" component="h1" gutterBottom className={classes.title}>
            {copy.title}
          </Typography>
          <Grid item xs={12}>
            <GenericEnhancedTable
              gridConfig={{
                ...defaultGridConfig,
                allowDelete: false,
                allowEdit: false,
                showActionColumn: true,
                showActionColumnOnStart: true,
              }}
              /* @ts-ignore-next-line */
              gridType="Available"
              tableTitle=""
              colDefs={tableColDefs}
              url="api/s/console/dot-form/appointments"
              urlParams={urlParams}
              customFilter={
                <FutureApptsOnlyToggle
                  checked={futureApptsOnly}
                  onChange={() => setFutureApptsOnly((v) => !v)}
                  name="future-appts-only-toggle"
                  color="secondary"
                />
              }
              orderBy={mrn ? "date" : "status"}
              order="desc"
              refreshSourceData={false}
              toolbarButtons={[
                {
                  name: "Send SMS",
                  dataCy: "Send SMS",
                  /* I don't know why the definition doesn't reflect this,
                   * and I don't want to go digging around in the table's code,
                   * but it does pass the list of selected ids. */
                  // @ts-ignore-next-line
                  action: handleSendReminders,
                  disableHandler: (data) =>
                    data?.some(
                      (item) =>
                        item.formStatus &&
                        DotFormStatus[item.formStatus] === DotFormStatus.COMPLETED,
                    ),
                  type: "icon",
                },
                {
                  name: "Edit Phone",
                  dataCy: "Edit Phone",
                  isDisableMultipleCheck: true,
                  /* I don't know why the definition doesn't reflect this,
                   * and I don't want to go digging around in the table's code,
                   * but it does pass the list of selected ids. */
                  // @ts-ignore-next-line
                  actionData: handleEditPhone,
                  type: "button",
                },
              ]}
              makeCustomActions={makeCustomActions}
              titleButtons={titleButtons}
              rowToId={(row) => row.formId}
            />
          </Grid>
        </Grid>
      </div>
      <CreateAppointmentDialog open={addingNew} onClose={() => setAddingNew(false)} />
    </>
  );
};

export default DOTFormSubmissions;
