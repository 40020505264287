import React from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { QuerySteps } from "./Steps";
import { US_STATES } from "components/Video/components/PreJoinScreensPhysician/VisitPlace/utils";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { useConsultationStyles } from "./styles";

export type StateType = {
  state: { name?: string | undefined; value: unknown } | string;
};
interface ScreeningProps {
  setStep: (queryStep: QuerySteps) => void;
  diagnosis: boolean;
  setDiagnosis: (a: boolean) => void;
  diagnosisDetails: string;
  setDiagnosisDetails: (a: string) => void;
  receivingTreatment: string;
  setReceivingTreatment: (a: string) => void;
  clinicalTrials: string;
  setClinicalTrials: (a: string) => void;
  hadSurgery: string;
  setSurgery: (a: string) => void;
  state: StateType | string;
  setStateVal: (state: StateType) => void;
}

const Screening = ({
  setStep,
  diagnosis,
  setDiagnosis,
  diagnosisDetails,
  setDiagnosisDetails,
  receivingTreatment,
  setReceivingTreatment,
  clinicalTrials,
  setClinicalTrials,
  hadSurgery,
  setSurgery,
  state,
  setStateVal,
}: ScreeningProps) => {
  const { classes } = useConsultationStyles();
  const app = useApp();
  const { vsId } = useParams<{ vsId: string }>();

  const handleStateChange = (e) => {
    setStateVal(e?.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state) {
      app.addError("Please select state value. Thank you.");
    } else {
      const patientReason = diagnosis ? "2nd Opinion" : "Inquiry";
      try {
        const { data } = await axiosClient.patch(`/api/s/virtualvisit/sessions/${vsId}`, {
          patient_reason: patientReason,
          visit_place: state,
          surgery: hadSurgery,
          receiving_treatment: receivingTreatment,
          clinical_trials: clinicalTrials,
          ...(!!diagnosisDetails && { visit_details: diagnosisDetails }),
        });
        app.dispatch({
          type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
          payload: data?.data,
        });
        setStep(QuerySteps.UPLOAD);
      } catch (err) {
        app.addError("Something wend wrong. Please contact support.");
      }
    }
  };

  return (
    <>
      <h1 className={classes.heading}>Tell Us About Your Care Needs</h1>
      <FormControl variant="standard" className={classes.formControlContainer}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel className={classes.label} id="demo-simple-select-outlined-label">
            What state do you live in? *
          </InputLabel>
          <Select
            variant="standard"
            classes={{
              root: classes.reasonSelect,
            }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state}
            onChange={handleStateChange}
            label="What state do you live in? *"
            required
            data-testid="select"
          >
            <MenuItem value="" data-testid="option">
              <em>None</em>
            </MenuItem>

            {US_STATES.map((stateItem) => {
              return (
                <MenuItem value={stateItem} key={stateItem}>
                  {stateItem}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.radioFormControl}>
          <FormLabel className={classes.label} id="demo-row-radio-buttons-group-label">
            Do you have a diagnosis? *
          </FormLabel>
          <RadioGroup
            row
            aria-label="had_surgery"
            name="had_surgery"
            value={diagnosis}
            className={classes.radioGroup}
            onChange={(e) => setDiagnosis(JSON.parse(e.target.value))}
            data-testid="radioDiagnosis"
          >
            <FormControlLabel
              className={classes.label}
              value
              control={<Radio color="primary" />}
              label="Yes"
              data-testid="diagnosisTrue"
            />
            <FormControlLabel
              className={classes.label}
              value={false}
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
        <FormControl variant="outlined" className={classes.radioFormControl}>
          <FormLabel className={classes.label} id="demo-row-radio-buttons-group-label">
            Have you had surgery for this condition before? *
          </FormLabel>
          <RadioGroup
            row
            aria-label="had_surgery"
            name="had_surgery"
            value={hadSurgery}
            className={classes.radioGroup}
            onChange={(e) => setSurgery(e.target.value)}
            data-testid="radioSurgery"
          >
            <FormControlLabel
              className={classes.label}
              value="yes"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              className={classes.label}
              value="no"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
        <FormControl variant="outlined" className={classes.radioFormControl}>
          <FormLabel className={classes.label} id="demo-row-radio-buttons-group-label">
            Are you currently receiving any treatment for this condition? *
          </FormLabel>
          <RadioGroup
            row
            aria-label="had_surgery"
            name="had_surgery"
            value={receivingTreatment}
            className={classes.radioGroup}
            onChange={(e) => setReceivingTreatment(e.target.value)}
            data-testid="radioReceivingTreatment"
          >
            <FormControlLabel
              className={classes.label}
              value="yes"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              className={classes.label}
              value="no"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
        <FormControl variant="outlined" className={classes.radioFormControl}>
          <FormLabel className={classes.label} id="demo-row-radio-buttons-group-label">
            Are you interested in clinical trials? *
          </FormLabel>
          <RadioGroup
            row
            aria-label="had_surgery"
            name="had_surgery"
            className={classes.radioGroup}
            value={clinicalTrials}
            onChange={(e) => setClinicalTrials(e.target.value)}
            data-testid="radioClinicalTrials"
          >
            <FormControlLabel
              className={classes.label}
              value="yes"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              className={classes.label}
              value="no"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            id="outlined-multiline-static"
            label="If you answered “yes” to any of the above, please provide more
            information here."
            multiline
            fullWidth
            minRows={8}
            variant="outlined"
            value={diagnosisDetails}
            onChange={(e) => setDiagnosisDetails(e.target.value)}
            style={{ textAlign: "left" }}
            InputLabelProps={{
              classes: {
                root: classes.textLabel,
              },
            }}
            inputProps={{ "data-testid": "input" }}
          />
          {!diagnosisDetails && <FormHelperText>(Optional)</FormHelperText>}
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          data-cy="nextButton"
          data-testid="nextButton"
        >
          Next
        </Button>
      </FormControl>
    </>
  );
};

export default Screening;
