import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import Alert from "@mui/material/Alert";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { getLocalTime } from "util/util_functions";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
  },
  title: {
    marginBottom: "20px",
    fontSize: "26px",
    color: "rgb(40, 42, 43)",
    fontWeight: 500,
    lineHeight: "34px",
    textAlign: "center",
  },
  chatSection: {
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "6px",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

const VirtualSessionUsers = ({ profile }) => {
  const { classes } = useStyles();
  const { addError } = useApp();

  const [users, setUsers] = useState([]);

  const getVSUsers = async () => {
    const url = "/api/s/console/virtualvisit/vs_users";
    try {
      const { data } = await axiosClient.get(url, {
        params: { vv_id: profile.virtual_session_info?.id },
      });
      setUsers(data.data);
    } catch (e) {
      addError("Problem with loading joined users. Please contact support.");
    }
  };

  useEffect(() => {
    if (profile.virtual_session_info?.id) {
      getVSUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.virtual_session_info?.id]);

  return (
    <>
      <div className={classes.title}>User Engagement Audit</div>
      <div className={classes.chatSection}>
        {!users.length && <Alert severity="info">No joined users found in the system.</Alert>}
        {users.length > 0 && (
          <List className={classes.root}>
            {users.map((u) => {
              const userDisplay = u.last_name ? `${u.first_name} ${u.last_name}` : u.email;
              const userTime = u.last_viewed_dttm ? getLocalTime(u.last_viewed_dttm) : "";
              return (
                <ListItem key={`${userDisplay}-${u.email}`}>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={userDisplay}
                    secondary={`${u.user_type} | ${getLocalTime(userTime)}`}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </div>
    </>
  );
};

export default VirtualSessionUsers;
