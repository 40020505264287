import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import DigitalImplantCard from "./DigitalImplantCard";
import { useGetDigitalImplants } from "hooks/useGetDigitalImplants/useGetDigitalImplants";
import useTranslation from "hooks/useTranslation";
import { useIsMobile } from "util/deviceUtils";
import { useStyles } from "./styles";

const DigitalImplantView = () => {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const {
    web: {
      digitalImplants: { title, notFound },
    },
  } = useTranslation();
  const { digitalImplantsData, isLoading } = useGetDigitalImplants();

  return (
    <Grid className={classes.root} container direction="column" data-testid="digitalImplantsView">
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Grid container direction={isMobile ? "column" : "row"} className={classes.cardsContainer}>
        {isLoading ? (
          <CircularProgress style={{ marginTop: "5em" }} />
        ) : !digitalImplantsData?.length ? (
          <Typography>{notFound}</Typography>
        ) : (
          digitalImplantsData?.map((item, index) => {
            return <DigitalImplantCard key={item.DeviceID} item={item} index={index} />;
          })
        )}
      </Grid>
    </Grid>
  );
};

export default DigitalImplantView;
