import React, { useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { useLocationContext } from "../LocationContext";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

type Props = {
  onNormalizationError: () => void;
};

const AddressNormalizationModal: React.FC<Props> = ({ onNormalizationError }: Props) => {
  const [selected, setSelected] = useState("validated");
  const [loading, setLoading] = useState<boolean>(true);
  const [errorNormalizingAddress, setErrorNormalizingAddress] = useState<boolean>(false);
  const app = useApp();

  const {
    currentPayload,
    locationData: {
      generalInfo: { address, contactInfo },
    },
    setCurrentPayload,
  } = useLocationContext();

  const { generalInfo } = currentPayload || {};
  const { address: currentAddressInfo } = generalInfo || {};

  const [addresses, setAddresses] = useState({
    entered: {
      buildingName: currentAddressInfo?.buildingName ?? address.buildingName,
      streetAddress1: currentAddressInfo?.streetAddress1 ?? address.streetAddress1,
      streetAddress2: currentAddressInfo?.streetAddress2 ?? address.streetAddress2,
      city: currentAddressInfo?.city ?? address.city,
      state: currentAddressInfo?.state ?? address.state,
      zip: currentAddressInfo?.zip ?? address.zip,
    },
    validated: {
      buildingName: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axiosClient.post("/api/s/address", {
          street: currentAddressInfo?.streetAddress1 ?? address.streetAddress1,
          city: currentAddressInfo?.city ?? address.city,
          state: currentAddressInfo?.state ?? address.state,
          zip: currentAddressInfo?.zip ?? address.zip,
        });
        const {
          city,
          postal_code: zip,
          street_number: streetNumber,
          route: streetName,
          state,
        } = response.data?.normalized_address || {};

        setErrorNormalizingAddress(false);

        // @ts-ignore-next-line
        setAddresses((prev) => ({
          ...prev,
          validated: {
            buildingName: currentAddressInfo?.buildingName ?? address.buildingName,
            streetAddress1: `${streetNumber}, ${streetName}`,
            streetAddress2: currentAddressInfo?.streetAddress2 ?? address.streetAddress2,
            city,
            state,
            zip,
          },
        }));

        setCurrentPayload((prevPayload) => ({
          ...prevPayload,
          generalInfo: {
            ...(prevPayload?.generalInfo || {}),
            address: {
              buildingName: currentAddressInfo?.buildingName ?? address.buildingName,
              streetAddress1: currentAddressInfo?.streetAddress1 ?? address.streetAddress1,
              streetAddress2: currentAddressInfo?.streetAddress2 ?? address.streetAddress2,
              city: currentAddressInfo?.city ?? address.city,
              state: currentAddressInfo?.state ?? address.state,
              zip: currentAddressInfo?.zip ?? address.zip,
              longitude: currentAddressInfo?.longitude ?? address.longitude,
              latitude: currentAddressInfo?.latitude ?? address.latitude
            },
            contactInfo: {
              ...contactInfo
            }  
          },
        }));
      } catch (error: any) {
        setErrorNormalizingAddress(true);
        app.addError(error?.response?.data?.error || "Failed to normalize address");
        onNormalizationError();
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const onSelectAddress = (key: string) => {
    setSelected(key);
    setCurrentPayload((prevPayload) => ({
      ...prevPayload,
      generalInfo: {
        ...(prevPayload?.generalInfo || {}),
        address: {
          ...(prevPayload?.generalInfo?.address || {}),
          ...addresses[key],
          normalize: key === "validated",
        },
      },
    }));
  };

  if (errorNormalizingAddress) {
    return (
      <Typography
        variant="h5"
        textAlign="center"
        style={{ height: "182px", alignContent: "center" }}
      >
        Failed to normalize address, please try again.
      </Typography>
    );
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      data-testid="address-normalization-modal"
      style={{
        textAlign: "center",
        justifyContent: "center",
        gap: "24px",
        height: "auto",
        minHeight: "182px",
      }}
    >
      {loading ? (
        <CircularProgress style={{ margin: "auto", display: "block" }} />
      ) : (
        <>
          {Object.keys(addresses).map((key) => (
            <Card
              key={key}
              style={{
                padding: "20px 32px",
                width: "40%",
                cursor: "pointer",
                borderRadius: "4px",
                border: key === selected ? "3px solid #043070" : "2px solid gray",
              }}
              onClick={() => onSelectAddress(key)}
            >
              <Box>
                {key === "validated" ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginBottom="8px"
                    style={{ gap: "8px" }}
                  >
                    <CheckCircleIcon style={{ color: "green", fontSize: "32px" }} />
                    <Typography variant="h6" fontSize="18px">
                      Validated address:
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginBottom="8px"
                    style={{ gap: "8px" }}
                  >
                    <Cancel style={{ color: "red", fontSize: "32px" }} />
                    <Typography variant="h6" fontSize="18px">
                      Address entered:
                    </Typography>
                  </Box>
                )}
                <Typography variant="body1">{addresses[key].buildingName}</Typography>
                <Typography variant="body1">{addresses[key].streetAddress1}</Typography>
                <Typography variant="body1">{addresses[key].streetAddress2}</Typography>
                <Typography variant="body1">{addresses[key].city}</Typography>
                <Typography variant="body1">{addresses[key].state}</Typography>
                <Typography variant="body1">{addresses[key].zip}</Typography>
              </Box>
            </Card>
          ))}
        </>
      )}
    </Grid>
  );
};

export default AddressNormalizationModal;
