import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { MedicationsProps } from "views/DOTForm/types/constants";

const useStyles = makeStyles()({
  medicationsTable: {
    fontFamily: "Arial, sans-serif",
    borderCollapse: "separate",
    borderSpacing: "0",
    width: "100%",
    backgroundColor: "white",
    tableLayout: "fixed",
  },
  cell: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "white",
  },
  headerCell: {
    fontWeight: "bold",
    border: "1px solid #dddddd",
    padding: "8px",
    backgroundColor: "#F5F5F5",
  },
  rowEven: {
    backgroundColor: "#f9f9f9",
  },
  rowOdd: {
    backgroundColor: "white",
  },
  tableContainer: {
    padding: "10px",
    backgroundColor: "white",
  },
});

export const MedicationsTable: FC<MedicationsProps> = ({ medicalRecord, patientReported }) => {
  const { classes } = useStyles();

  const maxLength = Math.max(medicalRecord ? medicalRecord.size : 0, patientReported.length);

  return (
    <table className={classes.medicationsTable}>
      <thead>
        <tr>
          <th className={classes.headerCell}>Medical Record</th>
          <th className={classes.headerCell}>Patient Reported</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: maxLength }, (_, index) => (
          <tr key={index} className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}>
            <td className={classes.cell}>
              {medicalRecord?.size
                ? medicalRecord
                  ? Array.from(medicalRecord)[index]
                  : ""
                : index === 0
                ? "Data Not Available."
                : ""}
            </td>
            <td className={classes.cell}>{patientReported[index] || ""}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
