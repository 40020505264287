import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Button, Menu as MenuContainer, MenuItem, Typography, styled } from "@mui/material";
import FlipCameraIcon from "../../../icons/FlipCameraIcon";
import InfoIconOutlined from "../../../icons/InfoIconOutlined";
import SettingsIcon from "../../../icons/SettingsIcon";
import StartRecordingIcon from "../../../icons/StartRecordingIcon";
import StopRecordingIcon from "../../../icons/StopRecordingIcon";
import { useAppState } from "../../../state";
import AboutDialog from "../../AboutDialog/AboutDialog";
import DeviceSelectionDialog from "../../DeviceSelectionDialog/DeviceSelectionDialog";
import useFlipCameraToggle from "../../../hooks/useFlipCameraToggle/useFlipCameraToggle";
import useIsRecording from "../../../hooks/useIsRecording/useIsRecording";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useIsMobile } from "util/deviceUtils";

export const IconContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "1.5em",
  marginRight: "0.3em",
});

export default function Menu({ buttonClassName }: { buttonClassName?: string }) {
  const isMobile = useIsMobile();

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { isFetching, updateRecordingRules, roomType } = useAppState();
  const { room } = useVideoContext();
  const isRecording = useIsRecording();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  return (
    <>
      <Button
        onClick={() => setMenuOpen((isOpen) => !isOpen)}
        ref={anchorRef}
        className={buttonClassName}
        data-cy-more-button
      >
        {isMobile ? (
          <MoreIcon data-testid="more-icon" />
        ) : (
          <>
            More
            <ExpandMoreIcon data-testid="expand-icon" />
          </>
        )}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : "bottom",
          horizontal: "center",
        }}
      >
        {roomType !== "peer-to-peer" && roomType !== "go" && false && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: "exclude", all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: "include", all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>
              {isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}
            </IconContainer>
            <Typography variant="body1">{isRecording ? "Stop" : "Start"} Recording</Typography>
          </MenuItem>
        )}
        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1">Flip Camera</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>

        <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem>
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
