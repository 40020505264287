import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Theme from "Theme";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Button, TextField } from "@mui/material";
import AudioInputListPreview from "../AudioVideoTest/AudioInputListPreview";
import VideoInputListPreview from "../AudioVideoTest/VideoInputListPreview";
import MediaErrorSnackbar from "../MediaErrorSnackbar/MediaErrorSnackbar";
import { QuerySteps } from "../Steps";
import DeviceSelectionLoader from "./DeviceSelectionLoader";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import HelpModal from "components/HelpModal";
import ToggleAudioButton from "components/Video/components/Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "components/Video/components/Buttons/ToggleVideoButton/ToggleVideoButton";
import useChatContext from "components/Video/hooks/useChatContext/useChatContext";
import useVideoContext from "components/Video/hooks/useVideoContext/useVideoContext";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { useAppState } from "components/Video/state";
import { useJoinButtonText, useStepsReload } from "./hooks";
import { useCheckPermissions } from "./hooks/useCheckPermissions";
import { deviceSelectionStyles } from "./useDeviceSelection.styles";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useProgramType } from "util/programsHelpers";
import { calculatePathBase, isTokenExpired } from "util/util_functions";
import { DeviceSelectionScreenProps } from "./types";

export default function DeviceSelectionScreen({
  name,
  setStep,
  virtual_session_id,
  inSession,
  currentVsVisitQueue,
  virtualSession,
}: DeviceSelectionScreenProps) {
  const { classes } = deviceSelectionStyles();
  const { isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const app = useApp();
  const { currentVirtualVisit } = app.vs_visits_state;
  const { user, setUserDirectly } = useAuth();
  const { removeLocalVideoTrack } = useVideoContext();
  const { audioError, videoError, mediaError } = useCheckPermissions();
  const [joinBtnText, reloadCondition] = useJoinButtonText(virtualSession);
  const { isAdhoc } = useProgramType(virtualSession?.program);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [firstName, setFirstName] = useState(currentVirtualVisit?.first_name);
  const [lastName, setLastName] = useState(currentVirtualVisit?.last_name);

  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (virtualSession?.consent_id) {
      setIsConsentChecked(true);
    } else if (JSON.parse(localStorage.getItem("consent") || "false")) {
      setIsConsentChecked(true);
      localStorage.removeItem("consent");
    }
    return () => removeLocalVideoTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualSession]);

  useStepsReload({ virtual_session_id, inSession });

  useEffect(() => {
    if (!firstName) {
      setFirstName(currentVirtualVisit?.first_name);
    }
    if (!lastName) {
      setLastName(currentVirtualVisit?.last_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.first_name, currentVirtualVisit?.last_name]);

  const updateUser = async () => {
    try {
      const { data } = await axiosClient.post("api/s/user/update-user-info", {
        userId: Number(currentVirtualVisit?.patient_id),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
      });
      setUserDirectly({
        ...user,
        firstName: data.first_name,
        lastName: data.last_name,
      });
      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...currentVirtualVisit,
          first_name: firstName,
          last_name: lastName,
        },
      });
    } catch (e) {
      app.addError("Somtething went wrong. Please, contacy support.");
    }
  };

  const handleJoin = async () => {
    if (firstName || lastName) {
      updateUser();
    }
    const pathBase = calculatePathBase(location.pathname);
    const patientVideoToken = localStorage.getItem("patientVideoToken") || "";

    let isValidToken = false;
    const parsed: { token: string; id: number } | null = patientVideoToken
      ? JSON.parse(patientVideoToken)
      : null;
    if (parsed && parsed?.id === virtual_session_id) {
      if (parsed?.token && !isTokenExpired(parsed.token)) {
        isValidToken = true;
      }
    }
    if (!isValidToken) {
      setIsLoading(true);
      try {
        const { data } = await axiosClient.post(API_ENDPOINTS.Twilio_Video_start, {
          virtual_session_id,
          is_consent_checked: isConsentChecked,
          queue: currentVsVisitQueue,
        });
        setIsLoading(false);
        await videoConnect(data.token);
        localStorage.setItem(
          "patientVideoToken",
          JSON.stringify({ id: virtual_session_id, token: data.token }),
        );
        await chatConnect(data.token);
        const params = new URLSearchParams();
        if (virtual_session_id) {
          params.append("step", "in-session");
        } else {
          params.delete("step");
        }
        navigate({
          pathname: `${pathBase}/${virtual_session_id}`,
          search: params.toString(),
        });
      } catch (e) {
        console.log(`Error joining session, ${e}`);
        setIsLoading(false);
        app.addError("Problem joining session, please reload and try again or contact support.");
      }
    } else {
      await videoConnect(parsed?.token as string);
      await chatConnect(parsed?.token as string);
      const params = new URLSearchParams();
      if (virtual_session_id) {
        params.append("step", "in-session");
      } else {
        params.delete("step");
      }
      navigate({
        pathname: `${pathBase}/${virtual_session_id}`,
        search: params.toString(),
      });
    }
  };

  if (isFetching || isConnecting || isLoading) {
    return <DeviceSelectionLoader />;
  }

  const handlePageReload = () => {
    window.location.reload();
    return false;
  };

  const handleHelpModalOpen = () => {
    setHelpModalOpen(true);
  };

  const handleHelpModalClose = () => {
    setHelpModalOpen(false);
  };

  return (
    <>
      <MediaErrorSnackbar error={mediaError} />
      <div className={classes.titleVideoScreen}>
        <CheckCircle style={{ color: Theme.palette.success.dark }} />
        <div>You’re ready to begin your video visit!</div>
      </div>
      {reloadCondition && (
        <div className={classes.reloadBtnContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleJoin}
            data-cy-join-now
            disabled={!isConsentChecked || !!videoError || !!audioError || !firstName || !lastName}
            className={classes.button}
          >
            {joinBtnText}
          </Button>
        </div>
      )}
      {!currentVirtualVisit?.first_name && (
        <TextField
          label="First Name*"
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className={classes.textField}
          fullWidth
        />
      )}
      {!currentVirtualVisit?.last_name && (
        <TextField
          label="Last Name*"
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className={classes.textField}
          fullWidth
        />
      )}
      <div className={classes.localPreviewContainer}>
        <LocalVideoPreview identity={name} />
      </div>

      <div className={classes.actionContainer}>
        <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
        <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
        <SettingsMenu handleHelpModalOpen={handleHelpModalOpen} />
      </div>
      <div>
        {!videoError && <VideoInputListPreview />}
        {videoError && (
          <div className={classes.errorMessage}>
            Looks like your camera has been disabled. Please reload the app to enable camera access
            and continue your visit.
          </div>
        )}
      </div>
      <div>
        {!audioError && <AudioInputListPreview />}
        {audioError && (
          <div className={classes.errorMessage}>
            Looks like your microphone has been disabled. Please reload the app to enable microphone
            access and continue your visit.
          </div>
        )}
      </div>
      <div>
        <div className={classes.reloadPrompt}>
          Having issues with your audio or video? Try
          <Button color="primary" className={classes.linkBtn} onClick={handlePageReload}>
            reloading the page
          </Button>
          <span>or</span>
          <Button color="primary" className={classes.linkBtn} onClick={handleHelpModalOpen}>
            contact support
          </Button>
          .
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          onClick={() => {
            removeLocalVideoTrack();
            setStep(isAdhoc ? QuerySteps.CONSENT : QuerySteps.VIDEO_PERMISSION);
          }}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleJoin}
          data-cy-join-now
          disabled={!isConsentChecked || !!videoError || !!audioError || !firstName || !lastName}
          className={classes.button}
          data-testid="joinNow"
        >
          {joinBtnText}
        </Button>
      </div>
      <HelpModal isOpen={isHelpModalOpen} handleClose={handleHelpModalClose} />
    </>
  );
}
