import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, List } from "@mui/material";
import VirtualSessionReport from "views/Report/VirtualSessionReport";
import { UserTableTitles } from "./constants";
import CreateVirtualSessionModal from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/CreateVirtualSessionModal/CreateVirtualSessionModal";
import QueueViewItem from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/QueueViewItem";
import {
  FilterBy,
  OrderBy as OrderByComponent,
  ProgramOverrideStatus,
  QueueView,
  SortBy,
} from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/components";
import SelectListItem from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/components/SelectListItem";
import { useGetDefaultQueueView } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/hooks";
import "components/Video/components/PreJoinScreensPhysician/WaitingRoom/style.css";
import { waitingUsersTableStyles } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/styles";
import useChimeNotification from "components/Video/hooks/useChimeNotification/useChimeNotification";
import useWaitingVsVisits from "components/Video/hooks/useWaitingVsVisits/useWaitingVsVisits";
import { ALL_PATIENTS, ORDER, OrderBy, VirtualSessionQueue } from "util/constants";
import { useQuery } from "hooks/useQuery";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { axiosClient, getApiHost } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { useProgramTypeByPathname } from "util/programsHelpers";

export default function WaitingUsersTable() {
  const { classes, cx } = waitingUsersTableStyles();

  const navigate = useNavigate();
  const query = useQuery();

  const {
    addError,
    vs_visits_state: { virtualVisits },
  } = useApp();
  const { isAdmin, isRegStaff, isNurse, isPhysician } = useUserType();
  const { programId, departmentId, programList } = usePrograms();
  const isMobile = useIsMobile();

  const { isAdhoc, isConsultation, isOnDemand } = useProgramTypeByPathname();
  let title = UserTableTitles.newVirtualSession;
  if (isConsultation) {
    title = UserTableTitles.newConsultation;
  }
  if (isOnDemand) {
    title = UserTableTitles.newConsultRequest;
  }
  const defaultQueueView = useGetDefaultQueueView(isConsultation);

  const params = new URLSearchParams(window.location.search);
  const queueViewParam = params.get("queueView");
  const filterByParam = params.get("filterBy");
  const viewReport = params.get("viewReport");
  const vsId = params.get("vsId");
  const shouldDisplaySortInsideDropdown = false;

  const [filterBy, setFilterBy] = useState(filterByParam || ALL_PATIENTS);
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState("add");
  const [queueView, setQueueView] = useState(queueViewParam || defaultQueueView);
  const [order, setOrder] = useState(ORDER.asc);
  const [orderBy, setOrderBy] = useState(isConsultation ? OrderBy.requestedStartBeg : OrderBy.id);
  const [viewReportMode, setViewReportMode] = useState(viewReport === "true");
  const [roleConfig, setRoleConfig] = useState(null);
  const [selectedVirtualVisitIds, setSelectedVirtualVisitIds] = useState([]);

  const apiHost = getApiHost();
  const baseUrl = `${apiHost}/api/s/console/virtualvisit/awaiting_users`;
  const urlOptions = {
    programId,
    queueView: isConsultation ? VirtualSessionQueue.REGISTERING : queueView,
    filterBy,
    departmentId,
    order,
    orderBy,
    patientReason: queueView,
  };

  const { data, refetch, isLoading } = useWaitingVsVisits({
    url: baseUrl,
    ...urlOptions,
    enabled: false,
  });

  const { callNotification } = useChimeNotification(data, queueView);

  const getQueueViewParam = () => {
    if (isRegStaff) {
      return "all";
    }
    if (isPhysician) {
      return roleConfig?.primary_queue?.virtual_session_queue;
    }
    if (isNurse) {
      return roleConfig?.supporting_queue?.virtual_session_queue;
    }
    return "all";
  };

  const getConfigForQueueParam = async (configId) => {
    try {
      const url = `/api/s/console/config/${configId}`;
      const { data } = await axiosClient.get(url);
      const automatedCalls = data?.json?.virtual_sessions?.automated_calls;
      setRoleConfig(automatedCalls);
    } catch (e) {
      addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    callNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let queueValue;
    if (isOnDemand) {
      queueValue = getQueueViewParam();
    } else {
      queueValue = queueViewParam || defaultQueueView;
    }
    setQueueView(queueValue);
    params.set("queueView", queueValue);
    navigate({ search: params.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnDemand, roleConfig]);

  useEffect(() => {
    if (programId && programList?.config_id) {
      getConfigForQueueParam(programList.config_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  useEffect(() => {
    refetch({ url: baseUrl, ...urlOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, queueView, filterBy, departmentId, vsId, order, orderBy]);

  useEffect(() => {
    const cellPhone = query.get("cell_phone");
    if (isAdhoc && cellPhone && !showModal) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdhoc]);

  useEffect(() => {
    const queueValue = queueViewParam || defaultQueueView;
    setQueueView(queueValue);
    params.set("queueView", queueValue);
    navigate({ search: params.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewReports = (mode) => {
    if (isMobile) {
      setViewReportMode(mode);
      params.set("viewReport", mode);
      navigate({ search: params.toString() });
    } else {
      window.open(
        `/console/virtual-session-report?programId=${programId}&departmentId=${departmentId}`,
        "_blank",
        "noopener, noreferrer",
      );
    }
  };

  useEffect(() => {
    setSelectedVirtualVisitIds([]);
  }, [queueView]);

  if (viewReportMode) {
    return (
      <VirtualSessionReport viewReportMode={viewReportMode} setViewReportMode={handleViewReports} />
    );
  }

  return (
    <div className={classes.root}>
      <CreateVirtualSessionModal
        show={showModal}
        setShow={setShowModal}
        mode={mode}
        setMode={setMode}
        modalTitle={title}
        isConsultation={isConsultation}
      />
      <div
        className={`
          ${classes.topButtonsWrapper}
          ${isOnDemand ? classes.singleButton : classes.multipleButtons}
        `}
      >
        {!isOnDemand && (
          <>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.topQueueButtons} ${classes.newSessionBtn}`}
              onClick={() => setShowModal(true)}
            >
              {title}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleViewReports(true)}
              className={classes.topQueueButtons}
            >
              View Report
            </Button>
          </>
        )}
        {isOnDemand && (isRegStaff || isAdmin) && (
          <Button
            variant="contained"
            color="primary"
            className={`${classes.topQueueButtons} ${classes.newSessionBtn}`}
            onClick={() => setShowModal(true)}
            fullWidth
          >
            {title}
          </Button>
        )}
        {isOnDemand && isAdmin && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleViewReports(true)}
            className={classes.topQueueButtons}
          >
            View Report
          </Button>
        )}
      </div>

      {!isConsultation && isAdmin && !isAdhoc && !isOnDemand && (
        <div className={classes.overrideContainer}>
          <ProgramOverrideStatus />
        </div>
      )}
      {!isAdhoc && !isOnDemand && (
        <div className={classes.filtersContainer}>
          <div className={classes.filterContainer}>
            <QueueView queueView={queueView} setQueueView={setQueueView} />
          </div>
          {!isConsultation && (
            <div className={classes.filterContainer}>
              <FilterBy filterBy={filterBy} setFilterBy={setFilterBy} />
            </div>
          )}
          {isConsultation && (
            <div className={classes.filterContainer}>
              <SortBy
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                shouldDisplaySortInsideDropdown={shouldDisplaySortInsideDropdown}
              />
            </div>
          )}
          {isConsultation && !shouldDisplaySortInsideDropdown && (
            <div className={classes.filterContainer}>
              <OrderByComponent order={order} setOrder={setOrder} />
            </div>
          )}
        </div>
      )}
      {!!virtualVisits.length && (
        <SelectListItem
          selectedVirtualVisitIds={selectedVirtualVisitIds}
          setSelectedVirtualVisitIds={setSelectedVirtualVisitIds}
          amountOfVirtualVisits={virtualVisits.length}
          virtualSessoinsList={virtualVisits}
          queue={queueView}
        />
      )}
      {isLoading ? (
        <div className={classes.circularContainer}>
          <CircularProgress />
        </div>
      ) : (
        <List dense className={cx(classes.queueContainer, { [classes.removeBorder]: isOnDemand })}>
          {virtualVisits.length === 0 && "Empty List"}
          {virtualVisits.map((row) => (
            <QueueViewItem
              key={row?.id}
              virtual_visit={row}
              selectedVirtualVisitIds={selectedVirtualVisitIds}
              setSelectedVirtualVisitIds={setSelectedVirtualVisitIds}
            />
          ))}
        </List>
      )}
    </div>
  );
}
