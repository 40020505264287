import { useEffect, useState } from "react";
import { useAuth } from "../../util/Security";

type User = {
  userId: string | number;
  cellPhone: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  zip?: string;
  email?: string;
};

export const useUserInfo = () => {
  const [user, setUser] = useState<User | null>(null);
  const auth = useAuth();
  useEffect(() => {
    if (auth?.user) {
      setUser(auth.user);
    }
  }, [auth]);
  return { user };
};
