import React, { useCallback } from "react";
import { useQuery } from "react-query";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import { axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

const RefreshButton = () => (
  <Button
    onClick={() => {
      window.location.reload();
      closeSnackbar("new-version");
    }}
    endIcon={<RefreshIcon />}
    color="inherit"
    size="small"
    variant="outlined"
  >
    Reload
  </Button>
);

async function getVersion() {
  const res = await axiosClient.get("/dfd-version");
  return res.data["dfd-version"];
}

export const useGetDfdVersion = (options = {}) => useQuery(QUERY_KEYS.version, getVersion, {...options});

export function NewVersionNotifier() {
  const onNewVersion = useCallback(() => {
    enqueueSnackbar("A new version of the app is available.", {
      variant: "info",
      key: "new-version",
      persist: true,
      action: <RefreshButton />,
    });
  }, []);

  const options = {
    refetchInterval: 1000 * 60 * 15, // 15 minutes
    onSuccess: (remoteVersion) => {
      const ourVersion = process.env.REACT_APP_AHS_DFD_VERSION;
      if (!ourVersion) {
        console.debug("No local version information. Skipping update check.");
        return;
      }
      if (ourVersion !== remoteVersion) {
        console.info("New version available:", remoteVersion);
        onNewVersion();
      }
    },
    onError: (err) => {
      console.error("Error while checking for new version:", err);
    },
  }

  // don't need the data, just need the side effects.
  useGetDfdVersion(options);

  return null;
}
