import React, { ChangeEvent, FormEvent, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SPECIMEN_ID_PREFIX } from "../../consts";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useUpdateSpecimenParts } from "hooks/specimenTracking/useUpdateSpecimenParts/useUpdateSpecimenParts";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { HttpStatusCode } from "util/HttpStatusCode";
import { useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    margin: 0,
    padding: "16px",
  },
  title: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
  },
  textField: {
    marginTop: "24px",
  },
  error: {
    color: theme.palette.error.main,
    marginTop: "4px",
    fontSize: "12px",
  },
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
}));

type EditSpecimenModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSaved: () => void;
  specimen: { specimenSpecimenExternalId: string; specimenId: number };
};

const EditSpecimenModal: React.FC<EditSpecimenModalProps> = ({
  isOpen,
  handleClose,
  handleSaved,
  specimen,
}) => {
  const {
    web: {
      specimenTracking: { leadingZeroError },
    },
  } = useTranslation();
  const { classes } = useStyles();
  const { updateSpecimenParts } = useUpdateSpecimenParts();
  const isMobile = useIsMobile();
  const { addError, addInfoMsg } = useApp();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [specimenExternalId, setSpecimenExternalId] = useState<string>(
    specimen.specimenSpecimenExternalId,
  );
  const confirmationMessage = `Are you sure you want to change the Specimen ID from '${specimen.specimenSpecimenExternalId}' to '${specimenExternalId}'?`;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (specimenExternalId.replace(SPECIMEN_ID_PREFIX, "").startsWith("0")) {
      setError(leadingZeroError);
    } else {
      setError(undefined);
      setConfirmationDialogOpen(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSpecimenExternalId(`${SPECIMEN_ID_PREFIX}${newValue}`);
    setError(newValue.startsWith("0") ? leadingZeroError : undefined);
  };

  const handleSaveChanges = async () => {
    try {
      if (!specimenExternalId) {
        return;
      }

      await updateSpecimenParts(specimen.specimenId, { specimenExternalId });
      handleSaved();
      addInfoMsg("Specimen has been updated successfully.");

      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response?.status === HttpStatusCode.CONFLICT) {
        addError(`Specimen with ID : ${specimenExternalId} already exists`);
      } else {
        addError("Problem updating specimen, please contact support.");
        handleClose();
      }
    }
  };

  const handleActionConfirmationOk = () => {
    handleSaveChanges();
    setConfirmationDialogOpen(false);
  };

  const handleActionConfirmationCancel = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} fullScreen={isMobile} maxWidth="sm">
        <DialogTitle className={classes.titleContainer}>
          <Typography className={classes.title} variant="h6">
            Update Specimen
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            data-testid="close-button"
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form id="form" onSubmit={handleSubmit}>
            <div className={classes.textField}>
              <TextField
                type="number"
                data-testid="text-specimen-add-manually"
                style={{ flex: 1, marginBottom: "1rem" }}
                value={specimenExternalId.replace(SPECIMEN_ID_PREFIX, "")}
                autoFocus
                required
                id="outlined-basic"
                label="Specimen ID"
                variant="outlined"
                onChange={handleChange}
                error={!!error}
                helperText={error}
                inputProps={{ "data-testid": "specimenIdInput" }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>{SPECIMEN_ID_PREFIX}</span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="form"
            fullWidth
            disabled={!!error}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        classes={{ paper: classes.confirmationModal }}
        id="confirmation-specimen-id"
        title="Confirm Specimen ID"
        msg={confirmationMessage}
        open={confirmationDialogOpen}
        onCancel={handleActionConfirmationCancel}
        onOk={handleActionConfirmationOk}
      />
    </>
  );
};

export default EditSpecimenModal;
