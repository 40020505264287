import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import EditableComponent from "../../../EditableComponent";
import { handleEditProvider, onCancelSection } from "../../Common/SectionsEditHandler";
import { useProviderContext } from "../../ProviderContext";
import ClinicalSection from "./ClinicalSection";
import ConditionsAndSymptomsSection from "./ConditionsAndSymptomsSection";
import { useApp } from "util/AppContext";
import { EditableComponentProps } from "../../types";

const Clinical: React.FC = () => {
  const app = useApp();
  const { providerId } = useParams<{ providerId: string }>();
  const {
    currentPayload,
    setCurrentPayload,
    currentlyEditing,
    setCurrentlyEditing,
    selectedTab,
    refetch,
  } = useProviderContext();

  const [sections, setSections] = useState({
    clinical: true,
    conditionsAndSymptomsSection: true,
  });

  const [isEditing, setIsEditing] = useState({
    clinical: false,
    conditionsAndSymptomsSection: false,
  });

  const [isLoading, setIsLoading] = useState({
    clinical: false,
    conditionsAndSymptomsSection: false,
  });

  const clinicalConfig: EditableComponentProps[] = [
    {
      title: "Clinical",
      slug: "clinical",
      component: ClinicalSection,
      showActionButton: true,
    },
    {
      title: "Conditions & Symptoms",
      slug: "conditionsAndSymptomsSection",
      component: ConditionsAndSymptomsSection,
      disableSection: false,
      showActionButton: true,
    },
  ];

  const handleCancelSection = () => {
    onCancelSection({
      currentlyEditing,
      setIsEditing,
      setSections,
      setIsLoading,
      setCurrentPayload,
      setCurrentlyEditing,
    });
  };

  useEffect(() => {
    handleCancelSection();
  }, [selectedTab]);

  return (
    <Grid item xs={12} md={8} marginTop="1em">
      {clinicalConfig.map(({ title, component, slug, showActionButton, customAction }) => (
        <EditableComponent
          key={slug}
          title={title}
          onEdit={() =>
            handleEditProvider(
              slug,
              setIsEditing,
              setSections,
              setCurrentlyEditing,
              currentPayload,
              setIsLoading,
              providerId,
              refetch,
              app,
              handleCancelSection,
            )
          }
          currentPayload={currentPayload}
          onCancel={handleCancelSection}
          showActionButton={showActionButton}
          customAction={customAction}
          isEditing={isEditing[slug]}
          isLoading={isLoading[slug]}
          disableSection={currentlyEditing !== null && currentlyEditing !== slug}
        >
          {component({ shouldDisableForm: sections[slug] })}
        </EditableComponent>
      ))}
    </Grid>
  );
};

export default Clinical;
