import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();

declare module "@mui/material/styles" {
  interface Theme {
    headerHeight?: string;
  }
  interface ThemeOptions {
    headerHeight?: string;
  }
}

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          textTransform: "none",
          fontSize: "16px",
          transition: defaultTheme.transitions.create(
            ["background-color", "box-shadow", "border", "color"],
            {
              duration: defaultTheme.transitions.duration.short,
            },
          ),
        },
        text: {
          padding: "6px 14px",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        startIcon: {
          marginRight: "6px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: "revert",
        },
        body1: {
          color: "rgb(40, 42, 43)",
          fontSize: "16px",
          lineHeight: "150%",
          margin: 0,
        },
        body2: {
          color: "rgb(40, 42, 43)",
          fontSize: "14px",
          lineHeight: "143%",
          margin: 0,
        },
        h1: {
          color: "rgb(40, 42, 43)",
          fontSize: "96px",
          lineHeight: "112px",
          margin: 0,
        },
        h2: {
          color: "rgb(40, 42, 43)",
          fontSize: "60px",
          lineHeight: "120%",
          fontWeight: 200,
          margin: 0,
        },
        h3: {
          color: "rgb(40, 42, 43)",
          fontSize: "48px",
          lineHeight: "117%",
          margin: 0,
        },
        h4: {
          color: "rgb(40, 42, 43)",
          fontSize: "34px",
          lineHeight: "123.5%",
          margin: 0,
        },
        h5: {
          color: "rgb(40, 42, 43)",
          fontSize: "24px",
          lineHeight: "133.4%",
          margin: 0,
        },
        h6: {
          color: "rgb(40, 42, 43)",
          fontSize: "20px",
          lineHeight: "160%",
          fontWeight: 600,
          margin: 0,
        },
        subtitle1: {
          margin: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
        input: {
          fontSize: "16px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          flexGrow: 1,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(0, 0, 0, 0.4)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
        contained: {
          marginLeft: "0",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Alegreya Sans", "Roboto", "sans-serif"].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: "#043070",
      light: "#2F5CA8",
      dark: "#144186",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#EB8025",
      light: "#F59B2B",
      dark: "#DB591D",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      focus: "rgba(0, 0, 0, 0.12)",
    },
    error: {
      main: "#F44336",
      dark: "#E31B0C",
      light: "#F88078",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#2196F3",
      dark: "#0B79D0",
      light: "#64B6F7",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#ED6C02",
      dark: "#C77700",
      light: "#FFB547",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#4CAF50",
      dark: "#3B873E",
      light: "#7BC67E",
      contrastText: "#FFFFFF",
    },
    divider: "rgba(0, 0, 0, 0.12)",
  },
  headerHeight: "72px",
});

export default theme;
