import React, { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    // We need a hard reload for the 404 page to prevent loops in location paths that could crash the app.
    window.location.href = "/error/404";
  }, []);

  return null;
};

export default NotFound;
