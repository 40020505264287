import { useEffect, useState } from "react";
import { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";
import { TOGGLE_WAITING_DIALOGUE } from "../useVirtualVisitReducer/Actions";
import { useApp } from "../../../../util/AppContext";

export default function useTrack(
  publication: LocalTrackPublication | RemoteTrackPublication | undefined,
) {
  const [track, setTrack] = useState(publication && publication.track);
  const app = useApp();

  useEffect(() => {
    if (track && track.kind === "video") {
      app.dispatch({
        type: TOGGLE_WAITING_DIALOGUE,
        payload: true,
      });
    } else {
      app.dispatch({
        type: TOGGLE_WAITING_DIALOGUE,
        payload: false,
      });
    }
    // eslint-disable-next-line
  }, [track]);

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication && publication.track);

    if (publication) {
      const removeTrack = () => setTrack(null);

      publication.on("subscribed", setTrack);
      publication.on("unsubscribed", removeTrack);
      return () => {
        publication.off("subscribed", setTrack);
        publication.off("unsubscribed", removeTrack);
      };
    }
  }, [publication]);

  return track;
}
