import React, { createContext, useMemo } from "react";
import useCaptions from "components/Video/hooks/useCaptions/useCaptions";
import { Subtitle } from "components/Video/types";

type TCaptionsContext = {
  showCaptions: boolean;
  setShowCaptions: (bool: boolean) => void;
  subtitles: Subtitle[];
  isCaptionDisabled: boolean;
  onDisconnect: () => void;
};

export const CaptionsContext = createContext<TCaptionsContext>(null!);

const CaptionsProvider = ({ children }: { children: React.ReactNode }) => {
  const { showCaptions, setShowCaptions, subtitles, isCaptionDisabled, onDisconnect } =
    useCaptions();
  const providerValue = useMemo(
    () => ({ showCaptions, setShowCaptions, subtitles, isCaptionDisabled, onDisconnect }),
    [showCaptions, setShowCaptions, subtitles, isCaptionDisabled, onDisconnect],
  );

  return <CaptionsContext.Provider value={providerValue}>{children}</CaptionsContext.Provider>;
};

export default CaptionsProvider;
