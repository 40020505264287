import { DotForms } from "./staff/constants";
import { DOT_PATIENT_STEPS } from "./types/constants";
import { axiosClient } from "util/api_helper";

export const formatDate = (date: Date) => {
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  return formattedDate;
};

export function getSteps() {
  return DOT_PATIENT_STEPS;
}

export const getStepNumber = (step: string) => {
  return getSteps().indexOf(step);
};

export const dateFmt = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

export const getDeterminationPdf = async (
  formId: number,
  formName: DotForms,
  outputPdfName: string,
  setLoading: (isLoading: boolean) => void,
) => {
  try {
    setLoading(true); // Start loading
    const result = await axiosClient.get(
      `/api/s/console/dot-form/medical-determination-pdf/${formId}/${formName}`,
      { responseType: "blob" },
    );
    const pdfUrl = window.URL.createObjectURL(new Blob([result.data]));

    // Create a link element to download the generated pdf and trigger it.
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `${outputPdfName.toUpperCase()}.pdf`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link element
    document.body.removeChild(link);

    window.addEventListener("beforeunload", () => {
      window.URL.revokeObjectURL(pdfUrl);
    });
  } catch (error) {
    console.error("Error downloading PDF:", error);
  } finally {
    setLoading(false); // Stop loading
  }
};
