import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import useVideoContext from "../../../components/Video/hooks/useVideoContext/useVideoContext";
import { releaseStages } from "util/constants/config";
import { usePrograms } from "util/ProgramsContext";
import { useIsMobile } from "util/deviceUtils";
import { hasFeature } from "util/hasFeature";

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
    },
  },
  root: {
    width: "220px",
    margin: "8px 22px",
    [theme.breakpoints.down("md")]: {
      width: "320px",
      margin: "8px 0",
    },
  },
  select: {
    textAlign: "left",
    paddingLeft: 0,
  },
  outlinedSelect: {
    paddingLeft: "16px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  inputLabel: {
    opacity: 0.38,
    [theme.breakpoints.down("md")]: {
      opacity: 0.6,
    },
  },
  input: {
    fontSize: "16px",
    paddingTop: "4px",
    paddingBottom: "4px",
    [theme.breakpoints.down("md")]: {
      padding: "14px 32px 14px 16px",
    },
  },
}));

const CAMPAIGN_MGMT_URL = "/console/campaigns/mgmt";

export default function ProgramSelection({ toggleMenuDrawer, setCurrentDepartmentName }) {
  const { classes } = useStyles();
  const location = useLocation();
  const { room } = useVideoContext();
  const { setDepartmentId, setProgramId, programId, programList, departmentId, departmentList } =
    usePrograms();

  const isMobile = useIsMobile();

  const handleProgramChange = (event) => {
    room?.disconnect();
    setDepartmentId(0);
    setProgramId(event.target.value);
    setCurrentDepartmentName("");
  };

  const handleDepartmentChange = (event) => {
    room?.disconnect();
    setDepartmentId(event.target.value);
    setCurrentDepartmentName("");
  };

  const availablePrograms = useMemo(() => {
    const programs = programList?.filter((item) => {
      if (item?.short_name in releaseStages && hasFeature(item?.short_name)) {
        return item;
      }

      if (!(item?.short_name in releaseStages)) {
        return item;
      }

      return false;
    });
    return programs;
  }, [programList]);

  return (
    <div className={classes.container}>
      {availablePrograms.length && (
        <TextField
          classes={{ root: classes.root }}
          select
          label="Program"
          SelectProps={{
            classes: { select: classes.select, outlined: classes.outlinedSelect },
          }}
          InputLabelProps={{ classes: { root: classes.inputLabel }, shrink: true }}
          InputProps={{
            classes: {
              input: classes.input,
            },
            disableUnderline: true,
          }}
          variant={isMobile ? "outlined" : "standard"}
          fullWidth
          value={programId || 0}
          onChange={handleProgramChange}
        >
          <MenuItem value={0}>All</MenuItem>
          {availablePrograms?.map((program) =>
            location.pathname === CAMPAIGN_MGMT_URL ? (
              <MenuItem className={classes.link} key={program.id} value={program.id}>
                {program.name}
              </MenuItem>
            ) : (
              <MenuItem
                component={Link}
                to={program.url_path}
                className={classes.link}
                key={program.id}
                value={program.id}
              >
                {program.name}
              </MenuItem>
            ),
          )}
        </TextField>
      )}
      {!!programId && !!departmentList.length && (
        <TextField
          data-testid="department-select"
          classes={{ root: classes.root }}
          select
          label="Department"
          SelectProps={{
            classes: { select: classes.select, outlined: classes.outlinedSelect },
          }}
          InputLabelProps={{ classes: { root: classes.inputLabel }, shrink: true }}
          InputProps={{
            classes: {
              input: classes.input,
            },
            disableUnderline: true,
          }}
          variant={isMobile ? "outlined" : "standard"}
          fullWidth
          value={departmentId || 0}
          onChange={handleDepartmentChange}
          onClick={(e) => {
            e.stopPropagation();
            toggleMenuDrawer(false);
          }}
        >
          <MenuItem value={0}>All</MenuItem>
          {departmentList?.map((department) => (
            <MenuItem key={department.id} value={department.id}>
              {department.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </div>
  );
}
