import React, { useEffect, useState } from "react";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      overflow: "scroll",
    },
  },
  table: {
    tableLayout: "fixed",
  },
  modalTableRow: {
    textAlign: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "&:last-child": {
      borderBottom: "unset",
    },
  },
  modalTableCell: {
    boxSizing: "border-box",
    padding: "12px",
    textAlign: "center",
    borderBottom: "unset",
    width: "210px",
  },
  circularProgress: {
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "0px",
    bottom: "0px",
    margin: "auto",
  },
  errorText: {
    marginTop: "6px",
  },
}));

export const EditUserCampaignPermissions = ({ userId }) => {
  const { classes } = useStyles();
  const app = useApp();
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const [retrievingCampaigns, setRetrievingCampaigns] = useState(false);
  const [campaignsError, setCampaignsError] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsForDropdown, setCampaignsForDropdown] = useState([]);

  const [retrievingEntries, setRetrievingEntries] = useState(false);
  const [entriesError, setEntriesError] = useState(false);
  const [entries, setEntries] = useState([]);

  const handleOnChangeCampaignDropdown = (e) => {
    const campaignId = e.target.value;
    setSelectedCampaign(campaignId);
  };

  const addNewCampaignUserRecord = async () => {
    setSelectedCampaign("");
    try {
      const response = await axiosClient.post(API_ENDPOINTS.campaign_permissions, {
        campaignId: selectedCampaign,
        userId,
        roleId: null,
      });
      if (response.data && response.status === 201) {
        app.addInfoMsg("New permission record has been successfully saved.");
        const newEntries = [...entries, response.data];
        setEntries(newEntries);
      }
    } catch (error) {
      app.addError("Failed to add new permission record.");
    }
  };

  const removeCampaignUserRecord = async (entryId) => {
    try {
      const response = await axiosClient.delete(`${API_ENDPOINTS.campaign_permissions}/${entryId}`);
      if (response.status === 204) {
        app.addInfoMsg("Permission record has been removed successfully.");
        const newEntries = entries.filter((entry) => entry.id !== entryId);
        setEntries(newEntries);
      }
    } catch (error) {
      app.addError("Failed to remove permission record.");
    }
  };

  useEffect(() => {
    if (campaigns && entries) {
      const newCampaigns = campaigns.filter(
        (campaign) => !entries.some((entry) => entry.campaignId === campaign.id),
      );
      setCampaignsForDropdown(newCampaigns);
    }
  }, [campaigns, entries]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const url = API_ENDPOINTS.campaigns;
      setRetrievingCampaigns(true);
      try {
        const response = await axiosClient.get(url);
        if (response.status === 200) {
          setCampaigns(response.data?.data ?? []);
        }
      } catch (e) {
        app.addError("There was an error while fetching the campaigns. Try again later.");
        setCampaignsError(true);
      }
      setRetrievingCampaigns(false);
    };
    const fetchEntries = async () => {
      const url = `${API_ENDPOINTS.campaign_permissions}?userId=${userId}`;
      setRetrievingEntries(true);
      try {
        const response = await axiosClient.get(url);
        if (response.status === 200) {
          setEntries(response.data ?? []);
        }
      } catch (e) {
        app.addError(
          "There was an error while fetching the campaign permissions. Try again later.",
        );
        setEntriesError(true);
      }
      setRetrievingEntries(false);
    };
    fetchCampaigns();
    fetchEntries();
    // eslint-disable-next-line
  }, []);

  if (retrievingCampaigns || retrievingEntries) {
    return <CircularProgress className={classes.circularProgress} />;
  }

  return (
    <>
      <div className={classes.tableContainer}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead className={classes.modalTableRow}>
            <TableRow>
              <TableCell align="center" className={classes.modalTableCell}>
                Campaign
              </TableCell>
              <TableCell align="center" className={classes.modalTableCell}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="input-row" className={classes.modalTableRow}>
              <TableCell align="center" className={classes.modalTableCell}>
                <TextField
                  label="Campaign"
                  variant="outlined"
                  size="small"
                  value={selectedCampaign}
                  onChange={(e) => handleOnChangeCampaignDropdown(e)}
                  fullWidth
                  select
                  disabled={campaignsError || entriesError}
                >
                  {campaignsForDropdown?.map(({ id, name }) => (
                    <MenuItem value={id} key={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell align="center" className={classes.modalTableCell}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon>Add</AddIcon>}
                  onClick={() => addNewCampaignUserRecord()}
                  disabled={!selectedCampaign}
                  fullWidth
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            {entries?.map(({ id, campaignName }) => (
              <TableRow key={campaignName} id={campaignName} className={classes.modalTableRow}>
                <TableCell component="th" scope="row" className={classes.modalTableCell}>
                  {campaignName}
                </TableCell>
                <TableCell align="center" className={classes.modalTableCell}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<RemoveIcon>Remove</RemoveIcon>}
                    onClick={() => removeCampaignUserRecord(id)}
                    fullWidth
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {(campaignsError || entriesError) && (
        <Typography className={classes.errorText} color="error">
          An error has occured while fetching the information. Please try again later.
        </Typography>
      )}
    </>
  );
};
