import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmationDialog(props) {
  const { onCancel, onOk, okLabel, onAlt, altLabel, open, title, msg, ...other } = props;

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      data-testid="Dialog"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <p>{msg}</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onOk} color="primary" data-testid="onOk">
          {okLabel && okLabel.length > 0 && <span>{okLabel}</span>}
          {(!okLabel || okLabel.length < 1) && <span data-testid="Ok">Ok</span>}
        </Button>
        {onAlt && altLabel && altLabel.length > 0 && (
          <Button autoFocus onClick={onAlt} color="primary" data-testid="onAlt">
            {altLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
