/* eslint-disable */
import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PhoneIcon from "@mui/icons-material/Phone";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SmsIcon from "@mui/icons-material/Sms";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import StandbyListView from "./StandbyListView";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { makeStyles } from "tss-react/mui";
import { ahsZips } from "components/AHSZip";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useApp } from "util/AppContext";
import { axiosClient, getApiHost } from "util/api_helper";
import { getLocalTime } from "util/util_functions";
import "./UserSearch.css";

const useStyles = makeStyles()(() => ({
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
}));

export default function UserSearch() {
  const { classes } = useStyles();

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [openSlots, setOpenSlots] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [closestLocation, setClosestLocation] = useState(null);
  const [actionConfirmedModelOpen, setActionConfirmedModelOpen] = useState(false);

  const app = useApp();

  useEffect(() => {
    const loadApps = async () => {
      const providerSlots = await getOpenAppts("5");
      const deptSlots = [];
      if (providerSlots && providerSlots.length > 0) {
        // aggregate by dept for this view
        for (const indx in providerSlots) {
          const p = providerSlots[indx];
          let found = false;
          for (const i in deptSlots) {
            var d = deptSlots[i];
            if (d.DepartmentName === p.DepartmentName) {
              d.AvailableSlotCount += p.AvailableSlotCount;
              found = true;
              break;
            }
          }
          if (!found && p.ProviderName) {
            // eslint-disable-next-line
            var d = p;
            const lane = d.ProviderName.indexOf("LANE");
            if (lane > 0) {
              d.ProviderName = d.ProviderName.substring(0, lane);
            }
            deptSlots.push(d);
          }
        }
        setOpenSlots(deptSlots);
      }
    };
    loadApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOpenAppts = async (daysForward) => {
    try {
      const { data } = await axiosClient.get("/api/openapptslots", {
        params: {
          dept: "allcovidvaccine",
          vt: "allcovidvaccine",
          daysForward,
          includeDetails: false,
          useCache: true,
        },
      });
      return data.result;
    } catch {
      app.addError("Failed to load open scheduling slots.");
    }
  };

  const getSuggestions = async (query) => {
    try {
      const nameCapitalized = query.charAt(0).toUpperCase() + query.slice(1);
      const { data } = await axiosClient.get("/api/s/console/patient/lookupuser", {
        params: { q: nameCapitalized },
      });
      return data.matches;
    } catch {
      app.addError("Failed to search users.");
    }
  };

  const getSuggestionValue = (suggestion) => {
    setSelectedUser(suggestion);
    return `${suggestion.first_name} ${suggestion.last_name}  ${
      suggestion.dob ? suggestion.dob.substring(0, 10) : ""
    }`;
  };

  useEffect(() => {
    const lookupClosestLocations = async () => {
      if (selectedUser && selectedUser.zip > 0) {
        const cl = await getClosestLocationByZip(selectedUser.zip, "20");
        if (cl) {
          setClosestLocation(cl);
        } else {
          setClosestLocation(null);
        }
      } else {
        setClosestLocation(null);
      }
    };
    lookupClosestLocations();
  }, [selectedUser]);

  const getClosestLocationByZip = async (zip, distance) => {
    try {
      const { data } = await axiosClient.get(
        `https://www.zipcodeapi.com/rest/js-XLVAW0hLty54Cgmr9DGibi6UmjudxAKnk9E3nL6W3peY0pXeIQZMuTMlIGVobIa4/radius.json/${zip}/${distance}/mile`,
      );
      let cl = null;
      if (data.zip_codes && data.zip_codes.length > 0) {
        for (const indx in data.zip_codes) {
          const z = data.zip_codes[indx];
          for (const i in ahsZips) {
            const l = ahsZips[i];
            if (z.zip_code === l.zip) {
              if (!cl || (cl && z.distance < cl.distance)) {
                cl = { title: l.title, zip: l.zip, distance: z.distance };
                if (cl.distance < 5) {
                  return cl; // bail out right away, close enought
                }
              }
            }
          }
        }
      }
      return cl;
    } catch {
      console.log("error calling zip api");
    }
  };

  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.first_name} ${suggestion.last_name}  ${
      suggestion.dob ? suggestion.dob.substring(0, 10) : ""
    }`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span className={`suggestion-content ${suggestion.twitter}`}>
        <span className="name">
          {parts.map((part, index) => {
            const className = part.highlight ? "highlight" : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </span>
    );
  };

  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
  };

  const inputProps = {
    placeholder: "Last, First or Email or Cell",
    value,
    onChange,
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    let matches = await getSuggestions(value);
    if (!matches) {
      matches = [];
    }
    setSuggestions(matches);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleConfirmAction = () => {
    setActionConfirmedModelOpen(true);
  };

  const handleActionConfirmationCancel = () => {
    setActionConfirmedModelOpen(false);
  };

  const handleActionConfirmationOk = () => {
    setActionConfirmedModelOpen(false);
    handleFormAction("SendSms", "default");
  };

  const handleActionConfirmationAlt = () => {
    setActionConfirmedModelOpen(false);
    handleFormAction("SendSms", "alternative1");
  };

  const handleFormAction = async (action, param = "default") => {
    const apiHost = getApiHost();
    const emails = selectedUser.email;
    let ops_status = "needs_attention";
    if (action === "SendSms" || action === "SendEmail") {
      ops_status = "needs_atttention";
    } else if (action === "Assign In Transit") {
      ops_status = "in_transit";
    } else if (action === "Assign Phone Only") {
      ops_status = "phone_only";
    } else if (action === "Mark Has Vaccine") {
      ops_status = "has_vaccine";
    } else if (action === "ResetVerifyAttempts") {
      ops_status = "needs_attention";
    }
    try {
      const { data } = await axiosClient.post("/api/s/console/patient/forms/bulkupdate", {
        emails,
        action,
        ops_status,
        actionParam: param,
      });
      app.addInfoMsg(data.msg);
    } catch {
      app.addError("Failed to send user SMS message for invite.");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h1>Vaccine Pre-registration User Lookup</h1>
      </Grid>
      <Grid item xs={6}>
        <ConfirmationDialog
          classes={{
            paper: classes.confirmationModal,
          }}
          id="confirmation-menu-standbylist"
          title="Confirm SMS Send"
          msg="Please confirm you would like to send an SMS text message to this person inviting them to book an appointment for the COVID-19 vaccine."
          open={actionConfirmedModelOpen}
          onCancel={handleActionConfirmationCancel}
          onOk={handleActionConfirmationOk}
          okLabel="Invite to Community"
          onAlt={handleActionConfirmationAlt}
          altLabel="Invite to Rockaway"
        />
        <Box borderRight={1}>
          <h3>Department Open Vaccine Appt Slots:</h3>
          <List dense>
            {openSlots &&
              openSlots.length > 0 &&
              openSlots.map((slotContainer) => (
                <React.Fragment key={slotContainer.Key}>
                  {slotContainer.DepartmentName && (
                    <ListItem key={slotContainer.DepartmentName}>
                      <ListItemText
                        primary={`${slotContainer.DepartmentName}: ${slotContainer.AvailableSlotCount} open slots.`}
                        secondary={slotContainer.ProviderName}
                      />
                    </ListItem>
                  )}
                </React.Fragment>
              ))}
          </List>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <h3>Please search by LastName, FirstName or Email or Cell</h3>
          </Grid>
          <Grid item xs={12}>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
            />

            {!selectedUser && suggestions && suggestions.length < 1 && (
              <div className="no-suggestions">No matches</div>
            )}
            {selectedUser && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h4>Selected User:</h4>
                  <List dense>
                    <ListItem key={selectedUser.first_name}>
                      <ListItemText
                        primary={`${selectedUser.first_name} ${selectedUser.last_name}`}
                      />
                      {selectedUser.dob && (
                        <ListItemText
                          primary={selectedUser.dob ? selectedUser.dob.substring(0, 10) : ""}
                        />
                      )}
                    </ListItem>
                    <ListItem key={selectedUser.cell_phone}>
                      <ListItemText primary={`${selectedUser.cell_phone}, ${selectedUser.email}`} />
                    </ListItem>
                    <ListItem>
                      {selectedUser.addr_str_1 && (
                        <ListItemText
                          primary={`${selectedUser.addr_str_1}, ${selectedUser.addr_str_2}, ${selectedUser.city}, ${selectedUser.state}, ${selectedUser.zip}`}
                        />
                      )}
                      <ListItemText primary={selectedUser.zip} />
                      {closestLocation && (
                        <ListItemText primary={`Closest Location: ${closestLocation.title}`} />
                      )}
                    </ListItem>
                    <ListItem>
                      {selectedUser.ops_status && (
                        <ListItemText primary={`Form Status:${selectedUser.ops_status} `} />
                      )}
                      {selectedUser.form_submit_dttm && (
                        <ListItemText
                          primary={`Form Submit @ ${getLocalTime(selectedUser.form_submit_dttm)}`}
                        />
                      )}
                      {selectedUser.send_dttm && (
                        <ListItemText
                          primary={` Conms:${selectedUser.context} @ ${getLocalTime(
                            selectedUser.send_dttm,
                          )}`}
                        />
                      )}
                    </ListItem>
                    <ListItem>
                      {selectedUser.latest_vax_dttm && (
                        <ListItemText
                          primary={`Vaccinated Date:${getLocalTime(selectedUser.latest_vax_dttm)} `}
                        />
                      )}
                      {selectedUser.latest_vax_appt_dttm && (
                        <ListItemText
                          primary={`Future vaccine appt @ ${getLocalTime(
                            selectedUser.latest_vax_appt_dttm,
                          )}`}
                        />
                      )}
                    </ListItem>
                    <ListItem>
                      {selectedUser.auth_code_usage_count && (
                        <ListItemText
                          primary={` Successful DOB Verifies (Max 4 allowed): ${selectedUser.auth_code_usage_count}`}
                        />
                      )}
                      {!selectedUser.auth_code_usage_count && (
                        <ListItemText primary=" Successful DOB Verifies (Max 4 allowed): 0" />
                      )}
                    </ListItem>
                    <ListItem>
                      <Tooltip title="Assign In Transit">
                        <IconButton
                          aria-label="Assign In Transit"
                          onClick={() => {
                            handleFormAction("Assign In Transit");
                          }}
                          size="large"
                        >
                          <AssignmentIndIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Assign Phone Only">
                        <IconButton
                          aria-label="Assign Phone Only"
                          onClick={() => {
                            handleFormAction("Assign Phone Only");
                          }}
                          size="large"
                        >
                          <PhoneIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Mark Has Vaccine">
                        <IconButton
                          aria-label="Mark Has Vaccine"
                          onClick={() => {
                            handleFormAction("Mark Has Vaccine");
                          }}
                          size="large"
                        >
                          <LocalHospitalIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Reset Allowed Attempts">
                        <IconButton
                          aria-label="Reset Allowed Attempts"
                          onClick={() => {
                            handleFormAction("ResetVerifyAttempts");
                          }}
                          size="large"
                        >
                          <RotateLeftIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Send SMS">
                        <IconButton
                          aria-label="Send Sms"
                          onClick={() => {
                            handleConfirmAction("SendSms");
                          }}
                          size="large"
                        >
                          <SmsIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <br />
        <Box borderTop={1}>
          <StandbyListView />
        </Box>
      </Grid>
      <br />
      <br />
      <br />
    </Grid>
  );
}
