import React from "react";
import { makeStyles } from "tss-react/mui";
import useChatContext from "components/Video/hooks/useChatContext/useChatContext";
import CloseIcon from "components/Video/icons/CloseIcon";
import { useApp } from "util/AppContext";
import { useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()(() => ({
  container: {
    height: "56px",
    background: "#F4F4F6",
    borderBottom: "1px solid #E4E7E9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1em",
  },

  text: {
    fontWeight: "bold",
  },

  closeChatWindow: {
    cursor: "pointer",
    display: "flex",
    background: "transparent",
    border: "0",
    padding: "0.4em",
  },
}));

export default function ChatWindowHeader() {
  const {
    vs_visits_state: { onlyConversation },
  } = useApp();
  const isMobile = useIsMobile();
  const { classes } = useStyles();
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div className={classes.container}>
      {!onlyConversation && <div className={classes.text}>Chat</div>}
      {!onlyConversation && isMobile && (
        <button
          className={classes.closeChatWindow}
          type="button"
          onClick={() => setIsChatWindowOpen(false)}
          aria-label="Close chat window"
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
}
