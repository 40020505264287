import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  timePopper: {
    zIndex: 2,
    "& .react-datepicker__header": {
      backgroundColor: "transparent",
    },
    "& .react-datepicker__time-list-item, .react-datepicker__header": {
      fontFamily: "Alegreya Sans",
    },
    "& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected":
      {
        backgroundColor: theme.palette.primary.main,
      },
  },
  timeCalendar: {
    width: 173,
    "& .react-datepicker__time-container ": {
      width: "100%",
    },
    "& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box": {
      width: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: 85,
    },
  },
}));
