import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  title: {
    margin: 0,
    marginBottom: "8px",
    fontWeight: 500,
  },
  description: {
    marginBottom: "16px",
  },
  fileUploadForm: {
    maxWidth: "640px",
    margin: "0 auto",
    marginBottom: "80px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "60px",
    },
  },
  button: {
    marginTop: "24px",
  },
  autocomplete: {
    width: "100%",
    marginBottom: "16px",
  },
  dropzoneText: {
    fontSize: "18px",
    marginTop: "16px",
    marginBottom: 0,
  },
  helperText: {
    marginTop: "16px",
  },
  statusButton: {
    textTransform: "uppercase",
    letterSpacing: "0.46px",

    "&:hover": {
      borderRadius: "5%",
    },
  },
  completeStatusButton: {
    minWidth: "154px",
  },
  inProgressStatusButton: {
    minWidth: "172px",
  },
  newStatusButton: {
    minWidth: "154px",
  },
  filterButton: {
    fontSize: "16px!important",
    padding: "4px 12px",
  },
}));
