import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import GenericTabs, { TabConfig } from "../Tabs";
import FadSection from "../common/FadSection";
import { ProviderContextProvider } from "./ProviderContext";
import Clinical from "./TabPanes/Clinical";
import ExperienceTab from "./TabPanes/Experience";
import GeneralInfoAndLocation from "./TabPanes/GeneralInfoAndLocation";
import Notes from "./TabPanes/NotesAndAdditionalInfo";
import { makeStyles } from "tss-react/mui";
import BackButton from "views/console/components/BackButton";
import { useGetAffiliations } from "hooks/dataCuration/useGetAffiliations";
import { useGetProviderById } from "hooks/dataCuration/useGetProviderById";
import { useGetVisitTypes } from "hooks/dataCuration/useGetVisitTypes";
import { ProviderGeneralInfoAndLocation } from "./types";
import providerPlaceholder from "static/images/provider_placeholder.png";

const useStyles = makeStyles()(() => ({
  root: { display: "flex", marginTop: "16px", marginLeft: "18px" },
  name: { alignSelf: "center", paddingLeft: "10px" },
}));

const ProviderPage: React.FC = () => {
  const { classes } = useStyles();
  const { providerId } = useParams<{ providerId: string }>();

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("selectedTab");

  const [currentPayload, setCurrentPayload] = useState<ProviderGeneralInfoAndLocation | null>(null);
  const [currentlyEditing, setCurrentlyEditing] = useState<string | null>(null);

  const { visitTypesList } = useGetVisitTypes();
  const { affiliationsList } = useGetAffiliations();
  const {
    providerGeneralInfoAndLocation,
    providerExperience,
    providerClinical,
    providerNotes,
    providerData,
    isLoading,
    refetch,
  } = useGetProviderById(providerId!);

  const publishedPageUrl = `https://www.atlantichealth.org/find-a-doctor/${providerGeneralInfoAndLocation?.personalInformation.firstName}-${providerGeneralInfoAndLocation?.personalInformation.lastName}-${providerGeneralInfoAndLocation?.personalInformation.NPI}`;

  const tabsConfig: TabConfig = {
    label: ["General Info and Location", "Experience", "Clinical", "Notes and additional Links"],
    component: [GeneralInfoAndLocation, ExperienceTab, Clinical, Notes],
  };

  const providerName = `${providerData?.firstName || ""} ${providerData?.middleName || ""} ${
    providerData?.lastName || ""
  }${providerData?.title ? `, ${providerData.title}` : ""}`;

  const providerImg = providerData?.imageUrl;

  const pageParams = localStorage.getItem("providersTable-pageParams");

  if (isLoading) {
    return (
      <CircularProgress
        data-testid="providerInfo-loader"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );
  }

  return (
    <ProviderContextProvider
      value={{
        providerGeneralInfoAndLocation,
        providerExperience,
        providerClinical,
        providerNotes,
        visitTypesList,
        affiliationsList,
        currentPayload,
        setCurrentPayload,
        currentlyEditing,
        setCurrentlyEditing,
        selectedTab,
        refetch,
      }}
    >
      <>
        <div style={{ display: "flex" }}>
          <BackButton
            url={`/console/data-curation/?selectedTab=0&${pageParams}`}
            onClick={() => localStorage.removeItem("providersTable-pageParams")}
          />
          <FadSection
            title={
              <Box display="flex" alignItems="center" gap="8px">
                <span>Show on Location Finder</span>
                <Tooltip title="Use this to enable or disable this page on the website." arrow>
                  <IconButton
                    size="small"
                    aria-label="Show on Location Finder info"
                    sx={{ transform: "translateY(-10px)" }}
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            aemUrl="https://www.atlantichealth.org/specialties/primary-care"
            publishedPageUrl={publishedPageUrl}
            enable={providerData?.showInPMC}
            dataType="provider"
          />
        </div>
        <div className={classes.root}>
          {providerImg && (
            <img
              src={providerImg}
              alt="placeholder"
              style={{ maxWidth: "150px", borderRadius: "12px" }}
              onError={(e) => {
                e.currentTarget.src = providerPlaceholder;
              }}
            />
          )}
          <h2 className={classes.name}>{providerName}</h2>
        </div>
        <GenericTabs config={tabsConfig} />
      </>
    </ProviderContextProvider>
  );
};

export default ProviderPage;
