/** @format */

/* eslint-disable */
import React, { useState } from "react";
import Loader from "../../components/Loader";

const TestToken = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [form, setForm] = useState({
    code: "",
    client_secret: "",
    client_id: "",
    grant_type: "",
    scope: "",
  });
  console.log("Response:", response);

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidated =
      form.client_id && form.client_secret && form.grant_type && form.scope && form.code;
    if (!isValidated) {
      setError("Please Enter All Form Values");
      return;
    }
    setLoading(true);
    // fetch(`${apiHost}/api/s/oauth/token`, {
    fetch("https://app-api-dev.atlantichealth.org/api/s/oauth/token", {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
      }),
      body: JSON.stringify({ ...form }),
    })
      .then((res) => {
        if (res.status >= 400) {
          throw new Error("Un Authorized");
        }
        return res.json();
      })
      .then((res) => {
        setLoading(false);
        setResponse(res);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <div style={{ width: "50%", margin: "10px 10px" }}>
      {!isLoading ? (
        <form onSubmit={handleSubmit}>
          <div className="form-field-div">
            Client Id: <input value={form.client_id} onChange={handleChange} name="client_id" />
          </div>
          <div className="form-field-div">
            Client Secret:{" "}
            <input value={form.client_secret} onChange={handleChange} name="client_secret" />
          </div>
          <div className="form-field-div">
            Grant Type: <input value={form.grant_type} onChange={handleChange} name="grant_type" />
          </div>
          <div className="form-field-div">
            Scope: <input value={form.scope} onChange={handleChange} name="scope" />
          </div>
          <div className="form-field-div">
            Code: <input value={form.code} onChange={handleChange} name="code" />
          </div>
          <div className="form-field-div">
            <button>Submit</button>
          </div>
        </form>
      ) : (
        <Loader />
      )}
      {error ? <div>{error.message}</div> : null}
    </div>
  );
};

export default TestToken;
