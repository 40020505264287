import React, { useEffect, useState } from "react";
import { FormControl, Grid, TextField } from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import RichTextArea from "components/RichTextArea";
import useTranslation from "hooks/useTranslation";
import { TabPaneProps } from "views/DataCuration/ProviderProfile/types";

const OtherInfoSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
  onDisableEditButton,
}: TabPaneProps) => {
  const {
    web: {
      dataCuration: {
        location: {
          generalInfo: {
            otherInfo: { alertMessageLabel },
          },
        },
      },
    },
  } = useTranslation();

  const {
    locationData: {
      generalInfo: { otherInfo },
    },
    currentPayload,
    setCurrentPayload,
  } = useLocationContext();

  const { shortDescription, alertMessage } = otherInfo;

  const [formData, setFormData] = useState({
    shortDescription: shortDescription ?? "",
    alertMessage: alertMessage ?? "",
  });

  const handleChange = (key: string, newValue: any) => {
    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== otherInfo[key]) {
      setCurrentPayload((prevPayload) => ({
        ...prevPayload,
        generalInfo: {
          ...(prevPayload?.generalInfo || {}),
          otherInfo: {
            ...(prevPayload?.generalInfo?.otherInfo || {}),
            [key]: newValue,
          },
        },
      }));
    }
  };

  const updateFormData = () => {
    setFormData({
      shortDescription: shortDescription ?? "",
      alertMessage: alertMessage ?? "",
    });
  };

  useEffect(() => {
    if (currentPayload === null) {
      updateFormData();
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      data-testid="OtherInfoSection"
      padding="16px"
    >
      <Grid item xs={12} md={12} style={{ position: "relative" }}>
        <FormControl variant="outlined" fullWidth data-testid="short-description-field">
          <RichTextArea
            initialValue={formData.shortDescription}
            onChange={(content) => handleChange("shortDescription", content)}
            placeHolder="Short Description"
            readOnly={shouldDisableForm}
            onDisableEditButton={onDisableEditButton}
            showCharacterCount
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="alertMessage"
            name="alertMessage"
            size="medium"
            value={formData?.alertMessage}
            variant="outlined"
            label={alertMessageLabel}
            onChange={(e) => handleChange("alertMessage", e.target.value)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="Practice-alertMessageInput"
            inputProps={{
              "data-testid": "Practice-alertMessageInput",
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default OtherInfoSection;
