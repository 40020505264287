import React from "react";
import { InputAdornment } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";

type Props = {
  setEmail: (value: string) => void;
  helperText?: string;
  showFixedSuffix?: boolean;
} & TextFieldProps;

export default function AHSEmailInput({
  setEmail,
  helperText,
  showFixedSuffix = false,
  ...rest
}: Props) {
  const { value } = rest;

  return (
    <TextField
      id="email"
      variant="outlined"
      fullWidth
      value={value}
      helperText={helperText}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
      type="email"
      size="medium"
      {...rest}
      InputProps={{
        endAdornment: showFixedSuffix && (
          <InputAdornment position="end">@atlantichealth.org</InputAdornment>
        ),
      }}
    />
  );
}
