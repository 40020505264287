/* eslint-disable */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import theme from "../../../../Theme";
import CaptionsProvider from "../CaptionsProvider/CaptionsProvider";
import MenuBar from "../MenuBar/MenuBar";
import MobileTopMenuBar from "../MobileTopMenuBar/MobileTopMenuBar";
import ReconnectingNotification from "../ReconnectingNotification/ReconnectingNotification";
import RecordingNotifications from "../RecordingNotifications/RecordingNotifications";
import Room from "../Room/Room";
import { API_ENDPOINTS } from "../../../../util/Api_Endpoints";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "../../hooks/useVirtualVisitReducer/Actions";
import { useApp } from "../../../../util/AppContext";
import { axiosClient } from "../../../../util/api_helper";
import "./style.css";

const Main = styled("main")(({ theme }) => ({
  overflow: "hidden",
  height: "100%",
  width: "100%",
  background: theme.palette.common.black,
}));

const VideoRoom = () => {
  const app = useApp();
  const { virtual_session_id } = useParams<{ virtual_session_id: string }>();

  const loadVirtualSession = async () => {
    const url = `${API_ENDPOINTS.twilio_get_virtual_session}`;
    try {
      const resp = await axiosClient.post(url, { vs_id: virtual_session_id });

      app.dispatch({ type: UPDATE_AND_SELECT_VIRTUAL_VISIT, payload: resp?.data });
    } catch (e) {
      app.addError(`Something went wrong. Please contact support.`);
    }
  };

  useEffect(() => {
    if (virtual_session_id) {
      loadVirtualSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtual_session_id]);

  return (
    <CaptionsProvider>
      <Main theme={theme}>
        <ReconnectingNotification />
        <RecordingNotifications />
        <MobileTopMenuBar />
        <Room />
        <MenuBar />
      </Main>
    </CaptionsProvider>
  );
};

export default VideoRoom;
