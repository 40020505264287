import { makeStyles } from "tss-react/mui";

export const useStylesAddModal = makeStyles()(() => ({
  modalContainer: {
    position: "absolute",
    top: "80px",
    left: "50%",
    backgroundColor: "white",
    transform: "translate(-50%)",
    width: 300,
    padding: "1rem",
    paddingTop: "3rem",
    p: 4,
    dropShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  saveContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
  },
  responseContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  saveButton: {
    width: "100%",
    marginTop: "1rem",
  },
  labelText: {
    fontWeight: "bold",
    marginRight: "1rem",
    gap: "1rem",
  },
  valueText: {
    color: "#043070",
  },
  labelProgressBar: {
    color: "#043070",
    fontWeight: "bold",
    paddingRight: "1rem",
  },
  captureButton: {
    height: "3rem",
    width: "100%",
  },
}));
