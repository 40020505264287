import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  datePickerPopper: {
    zIndex: 2,
  },
  datePickerClearBtn: {
    padding: "0 16px 0 0",
    "&::after": {
      fontSize: 24,
      color: theme.palette.grey[500],
      backgroundColor: "transparent",
      width: 24,
      height: 24,
    },
    "&:hover::after": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  datePickerCalendar: {
    zIndex: 2,
    "& .react-datepicker__header": {
      backgroundColor: "transparent",
      borderBottom: "none",
    },
    "& .react-datepicker__day-names": {
      paddingTop: 12,
    },
    "& .react-datepicker__day-name": {
      color: theme.palette.grey[500],
    },
    "& .react-datepicker__current-month": {
      paddingTop: 5,
      fontSize: 18,
    },
    "& .react-datepicker__navigation": {
      top: 7,
    },
    "& .react-datepicker__day--keyboard-selected": {
      backgroundColor: "transparent",
      color: theme.palette.common.black,
    },
    "& .react-datepicker__day--in-selecting-range, .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__day--range-start, .react-datepicker__day--range-end":
      {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    "& .react-datepicker__day--in-selecting-range": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .react-datepicker__day--disabled.react-datepicker__day--keyboard-selected, .react-datepicker__day--disabled":
      {
        color: theme.palette.grey[300],
      },
    "& .react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range": {
      color: theme.palette.common.white,
    },
    "& .react-datepicker__triangle": {
      transform: "translate(25px, 0px) !important",
    },
    "& .react-datepicker__day--disabled.react-datepicker__day--in-range": {
      backgroundColor: "transparent",
      color: theme.palette.grey[300],
    },
  },
}));
