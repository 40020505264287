/* eslint-disable */
import { React, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { getLocalTime } from "util/util_functions";

const useStyles = makeStyles()({
  table: {
    minWidth: "650px",
  },
  alertContainer: {
    padding: 0,
  },
  title: {
    margin: 0,
    marginBottom: "8px",
  },
});

const UserSessions = ({ userId }) => {
  const { classes } = useStyles();
  const app = useApp();
  const [userSessions, setUserSessions] = useState([]);

  const getUserSessions = async () => {
    try {
      const url = `/api/s/console/patient/user_sessions?user_id=${userId}`;
      const res = await axiosClient.get(url);

      setUserSessions(res?.data?.data);
    } catch (e) {
      app.addError("Something went wrong, please contact support");
    }
  };

  useEffect(() => {
    if (userId) {
      getUserSessions();
    }
    // eslint-disable-next-line
  }, [userId]);

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        User Sessions
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Created At</TableCell>
              <TableCell>Context</TableCell>
              <TableCell>Start Datetime</TableCell>
              <TableCell>Last Engaged Datetime</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!userSessions.length && (
              <TableRow>
                <TableCell colSpan="100%" className={classes.alertContainer}>
                  <Alert severity="info">No userSessions found in the system.</Alert>
                </TableCell>
              </TableRow>
            )}
            {!!userSessions.length &&
              userSessions.map((userSession, index) => (
                <TableRow key={index}>
                  <TableCell>{getLocalTime(userSession.created_at)}</TableCell>
                  <TableCell>{userSession.context || <>&mdash;</>}</TableCell>
                  <TableCell>{getLocalTime(userSession.start_dttm)}</TableCell>
                  <TableCell>{getLocalTime(userSession.last_engaged_dttm)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UserSessions;
