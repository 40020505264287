import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { SpecimenAddResponse } from "views/SpecimenTracking/types";

export const useAddSpecimenPhoto = () => {
  const captureSpecimenPhoto = (specimen_id: string, photo: FormData) => {
    photo.append("id", specimen_id);

    const resp = axiosClient.patch<SpecimenAddResponse>(
      `${API_ENDPOINTS.specimenTrackingAddSpecimenPhoto}`,
      photo,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return resp;
  };

  return {
    captureSpecimenPhoto,
  };
};
