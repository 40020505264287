import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  heading: {
    color: theme.palette.primary.main,
  },
  formControl: {
    marginTop: "10px",
    minWidth: 260,
    width: "100%",
    display: "block",
  },
  calendarRoot: {
    margin: 0,
  },
  btnContainer: {
    width: "100%",
    marginTop: "20px",
    textTransform: "uppercase",
    height: "42px",
  },
  label: {
    backgroundColor: theme.palette.common.white,
  },
  text: {
    fontSize: "20px",
    color: theme.palette.primary.main,
    fontFamily: "Alegreya Sans",
    marginTop: "16px",
    marginBottom: "25px",
  },
  call: {
    fontWeight: "bold",
    fontSize: "20px",
    color: theme.palette.primary.main,
    fontFamily: "Alegreya Sans",
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  reasonSelect: {
    "&:focus": {
      backgroundColor: theme.palette.common.white,
    },
  },
  radioFormControl: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subTitle: {
    marginBottom: "16px",
  },
  link: {
    color: theme.palette.info.main,
    fontSize: "20px",
    lineHeight: "22px",
    textDecoration: "none",
    display: "inline-block",
    borderBottom: `solid 1px ${theme.palette.info.main}`,
    fontWeight: 500,
    marginLeft: "7px",
    "&:hover": {
      color: theme.palette.primary.main,
      borderBottom: `solid 1px ${theme.palette.primary.main}`,
    },
  },
}));
