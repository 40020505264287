import React, { useEffect, useState } from "react";
import { useQuery as RQUseQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { FilterList } from "@mui/icons-material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import EditModal from "./EditModal";
import NotificationsModal from "./NotificationsModal";
import { getCampaignDateFilters, getTableColDefs, gridConfig } from "./helpers";
import EnhancedTableDateFilters from "../../components/GenericEnhancedTable/components/EnhancedTableDateFilters";
import Dropzone from "components/Dropzone";
import GenericEnhancedTable from "components/GenericEnhancedTable";
import {
  TOOLBAR_BUTTON_NAMES,
  TOOLBAR_ITEM_TYPE,
} from "components/GenericEnhancedTable/components/EnhancedTableToolbar";
import { CampaignTypes } from "util/constants";
import { useGetCampaignTags } from "hooks/campaigns/useGetCampaignTags";
import { useGenericEnhancedTableContext } from "hooks/useGenericEnhanceTableContext";
import { useQuery } from "hooks/useQuery";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { exportCSV } from "util/util_functions";
import { useStyles } from "../../styles/CampaignsManagement/index.styles";

export default function CampaignListUpload() {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const app = useApp();
  const query = useQuery();
  const { programId } = usePrograms();
  const { sourceData, setSourceData, selected } = useGenericEnhancedTableContext();
  const { tags: allTags } = useGetCampaignTags();
  const [inviteFile, setInviteFile] = useState(null);
  const [campaign, setCampaign] = useState({});
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [openEditModal, setOpenEditModal] = useState();
  const [notifications, setNotifications] = useState([]);
  const [tableConfig, setTableConfig] = useState(gridConfig);
  const [isQuickSurvey, setIsQuickSurvey] = useState(false);
  const [tableOrderBy, setTableOrderBy] = useState("id");
  const [showDateFilters, setShowDateFilters] = useState(false);
  const [tableUrl, setTableUrl] = useState("");
  const [colDefs, setColDefs] = useState({});
  const campaignDateFilters = getCampaignDateFilters(campaign);
  const GetCampaignList = async () => {
    try {
      const url = programId
        ? `${API_ENDPOINTS.campaigns}?program_id=${programId}`
        : API_ENDPOINTS.campaigns;

      const response = await axiosClient.get(url);
      setCampaigns(response.data.data);
    } catch (error) {
      app.addError("Loading of campaign list failed, please contact support.");
    }
  };

  useEffect(() => {
    const campaignColDefs = getTableColDefs(campaign);
    if (campaign.campaign_type === CampaignTypes.programFormOutreach) {
      const tagsColumn = campaignColDefs.find((col) => col.id === "tags");
      tagsColumn.customInput.options = allTags?.data;
    }

    setColDefs(campaignColDefs);
  }, [allTags?.data, campaign]);

  const titleButtons = [
    {
      name: "Filter",
      action: () => setShowDateFilters(!showDateFilters),
      icon: <FilterList style={{ color: "#fff", width: 25, height: 25 }} />,
      dataCy: "filterCampaignsBtn",
      className: classes.filterButton,
    },
  ];

  useEffect(() => {
    GetCampaignList();
    // eslint-disable-next-line
  }, [programId]);

  useEffect(() => {
    const campaignIdParam = Number(query.get("campaign_id"));

    if (campaignIdParam && campaigns.length) {
      const selectedCampaign = campaigns.find((campaign) => campaign.id === campaignIdParam);
      setCampaign(selectedCampaign || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns]);

  useEffect(() => {
    if (campaign.id) {
      setTableUrl(`/api/s/console/patient/campaigns/${campaign.id}`);
    }
  }, [campaign]);

  const handleChangeAutocomplete = (_, newValue) => {
    const selectedCampaign = campaigns?.find((item) => item.name === newValue);

    if (selectedCampaign?.campaign_type === CampaignTypes.quickAccessSurvey) {
      setTableOrderBy("id");
      setTableConfig(() => ({
        ...gridConfig,
        showActionColumn: false,
      }));
      setIsQuickSurvey(true);
    } else if (selectedCampaign?.campaign_type === CampaignTypes.programFormOutreach) {
      setTableOrderBy("reference_dttm");
      setTableConfig(gridConfig);
      setIsQuickSurvey(false);
    } else {
      setTableOrderBy("id");
      setTableConfig(gridConfig);
      setIsQuickSurvey(false);
    }

    if (selectedCampaign?.id) {
      query.set("campaign_id", selectedCampaign?.id);
      setCampaign(selectedCampaign);
    } else {
      query.delete("campaign_id");
      setCampaign({});
    }
    navigate({ search: query.toString() });
  };

  const handleCampaignUploadFileChange = (files) => {
    if (files && files.length > 0) {
      setInviteFile(files[0]);
    }
  };

  const getNotifications = async () => {
    try {
      const { data } = await axiosClient.get(API_ENDPOINTS.campaignManagementNotifications);
      const source = data.data.map((notification) => {
        return {
          id: notification.notification_id,
          ...notification,
        };
      });
      setNotifications(source);
    } catch (error) {
      console.log(error);
    }
  };

  RQUseQuery({
    queryKey: ["fetch-campaings-notifications"],
    queryFn: getNotifications,
    enabled: notifications.length === 0,
    staleTime: Infinity,
    refetchInterval: 3000,
    onError: () => app.addError("Cannot fetch notifications"),
  });

  const onCloseNotificationModal = async () => {
    const notificationsIds = notifications.map(({ notification_id }) => notification_id);
    try {
      await axiosClient.put(API_ENDPOINTS.campaignManagementNotificationsMarkAsRead, {
        status: "READ",
        notification_ids: notificationsIds,
      });
    } catch (error) {
      console.log(error);
    }
    setNotifications([]);
  };

  const handleUploadCampaignList = async () => {
    if (inviteFile && campaign.name) {
      const formData = new FormData();
      formData.append("file_uploaded", inviteFile);
      try {
        await axiosClient.post("/api/s/console/patient/campaign_load", formData, {
          params: { campaign_name: campaign.name },
          headers: { "Content-Type": "multipart/form-data" },
        });
        setRefreshGrid(!refreshGrid);
        app.addInfoMsg("Successfully uploaded campaign file for processing.");
      } catch {
        app.addError("Failed to upload campaign file.");
      }
    } else {
      app.errMsg("Please select a campaign and select a file to upload");
    }
  };

  const handleSendBulkInvite = async (fsIds) => {
    const newFsIds = sourceData
      .filter(
        (item) =>
          fsIds.includes(item.id) && item.invited_dttm === null && item.completed_dttm === null,
      )
      .map((item) => item.id);

    if (newFsIds.length === 0) {
      app.addError("Failed to send sms, only items in new status can be sent");
    } else {
      try {
        const response = await axiosClient.post("/api/s/console/patient/forms/bulkinvite", {
          fsIds: newFsIds,
        });
        app.addInfoMsg(response.data.msg);
      } catch (e) {
        app.addError("Failed to send sms, please try again.");
      }
    }
  };

  const markUploadedToEpic = async (selectedIds, mark_as_uploaded) => {
    let validIdsSelected = true;
    if (mark_as_uploaded) {
      const allCompleted = sourceData
        .filter((item) => selectedIds.includes(item.id))
        .every((item) => item.completed_dttm !== null || item.submitted_dttm !== null);
      if (!allCompleted) {
        app.addError(
          "Failed to mark as uploaded to Epic: " +
            "Not all items are completed and only completed items can be mark as uploaded",
        );
        validIdsSelected = false;
      }
    }
    if (validIdsSelected) {
      try {
        const response = await axiosClient.post(
          `/api/s/console/patient/campaigns/${campaign.id}/forms/uploaded-to-epic`,
          {
            selectedIds,
            uploaded: mark_as_uploaded,
          },
        );
        setRefreshGrid(!refreshGrid);
        app.addInfoMsg(response.data.msg);
      } catch (error) {
        const description = error?.response?.data?.description || "Unknown error";
        app.addError(`Failed to mark as uploaded to Epic: ${description}. Please try again.`);
      }
    }
  };

  const changeStatus = async (selectedIds, status) => {
    /* eslint-disable @typescript-eslint/naming-convention */
    let completed_dttm = null;
    let invited_dttm = null;
    switch (status) {
      case "New":
        break;
      case "In Progress":
        invited_dttm = new Date().toISOString();
        break;
      case "Completed":
        completed_dttm = new Date().toISOString();
        invited_dttm = new Date().toISOString();
        break;
      default:
        app.addError(`Something went wrong. Unknown status ${status} Please, contact support.`);
    }
    try {
      const url = `/api/s/console/patient/campaigns/${campaign.id}/forms`;
      const updatedCampaigns = selectedIds.map((id) => ({
        id,
        status,
        completed_dttm,
        invited_dttm,
      }));
      const { data: response } = await axiosClient.patch(url, updatedCampaigns);
      const filteredRows = sourceData.map(
        (form) => response.data.find((item) => item.id === form.id) || form,
      );
      setSourceData(filteredRows);

      app.addInfoMsg("Status updated successfully.");
    } catch (error) {
      app.addError("Something went wrong. Please, contact support.");
    }
  };

  const toolbarButtons =
    campaign?.campaign_type === CampaignTypes.programFormOutreach
      ? [
          {
            name: "Mark as complete",
            action: (selectedIds) => changeStatus(selectedIds, "Completed"),
            type: TOOLBAR_ITEM_TYPE.button,
            className: cx(classes.statusButton, classes.completeStatusButton),
          },
          {
            name: "Mark as in progress",
            action: (selectedIds) => changeStatus(selectedIds, "In Progress"),
            type: TOOLBAR_ITEM_TYPE.button,
            className: cx(classes.statusButton, classes.inProgressStatusButton),
          },
          {
            name: "Mark as new",
            action: (selectedIds) => changeStatus(selectedIds, "New"),
            type: TOOLBAR_ITEM_TYPE.button,
            className: cx(classes.statusButton, classes.newStatusButton),
          },
          {
            name: TOOLBAR_BUTTON_NAMES.sendSMS,
            action: (selectedItems) => {
              handleSendBulkInvite(selectedItems);
            },
            type: TOOLBAR_ITEM_TYPE.icon,
          },
          {
            name: TOOLBAR_BUTTON_NAMES.uploadToEpic,
            action: (selectedItems) => markUploadedToEpic(selectedItems, true),
            type: TOOLBAR_ITEM_TYPE.icon,
            dataCy: "markUploadToEpic",
          },
          {
            name: TOOLBAR_BUTTON_NAMES.notUploadToEpic,
            action: (selectedItems) => markUploadedToEpic(selectedItems, false),
            type: TOOLBAR_ITEM_TYPE.icon,
            dataCy: "markNotUploadToEpic",
          },
        ]
      : [
          {
            name: TOOLBAR_BUTTON_NAMES.sendSMS,
            action: (selectedItems) => handleSendBulkInvite(selectedItems),
            type: TOOLBAR_ITEM_TYPE.icon,
          },
        ];

  const exportDataToCSV = async () => {
    try {
      const url = `/api/s/console/patient/campaigns/${campaign?.id}`;
      const { data } = await axiosClient.get(url, {
        headers: { Accept: "text/csv" },
        params: {
          ...(selected.length !== sourceData.length && { id: selected.toString() }),
        },
      });
      exportCSV(data, "campaign-list-report");
    } catch (e) {
      app.addError("Problem exporting csv file. Please, contact support.");
    }
  };

  return (
    <Box padding={["24px 24px 16px 24px", "40px 32px 16px 32px"]}>
      <Grid container direction="column" alignItems="center" className={classes.fileUploadForm}>
        <Typography variant="h3" className={classes.title}>
          Campaign File Upload
        </Typography>
        {!isQuickSurvey && (
          <Typography variant="body1" className={classes.description}>
            CSV file should have column headings 'email' or 'cell_phone' or 'phone' depending on
            type of campaign
          </Typography>
        )}
        <Autocomplete
          id="campaign-dropdown"
          className={classes.autocomplete}
          freeSolo
          value={campaign?.name || ""}
          onChange={handleChangeAutocomplete}
          options={campaigns.map((option) => option.name)}
          renderInput={(params) => (
            <TextField {...params} label="Campaign *" margin="normal" variant="outlined" />
          )}
        />
        {campaign?.id && campaign?.campaign_type !== "quick_access_survey" && (
          <>
            <Dropzone
              onDrop={handleCampaignUploadFileChange}
              accept={{ "text/csv": [".csv"] }}
              data-cy="campaign-file-upload"
            />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              fullWidth
              autoFocus
              onClick={handleUploadCampaignList}
              disabled={!(inviteFile && campaign.id)}
              data-cy="uploadCampaignListBtn"
            >
              Upload Campaign List
            </Button>
            <Typography variant="body1" className={classes.helperText}>
              Upload a .csv file with the following columns (HNE/MRN or Cell phone is required, not
              both, will pull from Epic from that unique value). <br />
              HNE, cell_phone, Procedure, Surgeon, reference_dttm, start_dttm, end_dttm
            </Typography>
          </>
        )}
      </Grid>
      {campaign?.id && (
        <>
          <GenericEnhancedTable
            key="campaign-list"
            gridConfig={tableConfig}
            gridType="Available"
            refreshSourceData={refreshGrid}
            tableTitle="Campaign List"
            colDefs={colDefs}
            url={tableUrl}
            toolbarButtons={toolbarButtons}
            onEditHandler={(user) => {
              setOpenEditModal(true);
              setSelectedUser(user);
            }}
            orderBy={tableOrderBy}
            order="desc"
            titleButtons={titleButtons}
            customFilter={
              <EnhancedTableDateFilters
                columnOptions={campaignDateFilters}
                setTableUrl={setTableUrl}
                apiEndpoint={`/api/s/console/patient/campaigns/${campaign.id}`}
                show={showDateFilters}
                handleClose={() => setShowDateFilters(false)}
              />
            }
          />
          {openEditModal && (
            <EditModal
              isOpen={openEditModal}
              handleClose={() => setOpenEditModal(false)}
              id={selectedUser?.id}
              userId={selectedUser?.user_id}
              campaignId={campaign.id}
              userTags={selectedUser?.tags || []}
              handleRefresh={() => setRefreshGrid(!refreshGrid)}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<SaveAltIcon />}
            onClick={exportDataToCSV}
            disabled={!selected.length}
            data-cy="exportCampaignListBtn"
          >
            Export (CSV)
          </Button>
        </>
      )}
      {notifications.length > 0 && (
        <NotificationsModal
          notifications={notifications}
          handleClose={() => onCloseNotificationModal()}
        />
      )}
    </Box>
  );
}
