import { useEffect, useState } from "react";
import { nav } from "./ConsoleHomeNav";
import { usePrograms } from "util/ProgramsContext";
import { useAuth } from "util/Security";

export const UseGetAvailableCards = () => {
  const auth = useAuth();
  const { programList } = usePrograms();
  const [availableCards, setAvailableCards] = useState([]);
  const [userPermissionsList, setUserPermissions] = useState();

  const getAvailableCards = (permissions, cards) =>
    cards.filter((card) => {
      if (card?.link === "/console/virtualvisit/waitingroom") {
        if (!programList?.some((program) => program?.url_path === card.link)) {
          return false;
        }
      }

      if (card?.link === "/console/adhoc-virtual") {
        if (!programList?.some((program) => program?.url_path === card.link)) {
          return false;
        }
      }

      if (card?.hidden) {
        return false;
      }

      return permissions?.some(
        (permission) =>
          card.permissions.includes(permission.permission_name) && permission.writable,
      );
    });

  useEffect(() => {
    if (userPermissionsList) {
      const cards = getAvailableCards(userPermissionsList, nav);
      setAvailableCards(cards);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissionsList, programList]);

  useEffect(() => {
    const getUserDetails = async () => {
      await auth.checkLogin((user) => {
        setUserPermissions(user?.permissions);
      }, true);
    };
    if (auth.user) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { availableCards };
};
