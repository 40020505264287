import React, { FC } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import useStyles from "./ErrorBoundary.style";

interface Props {
  isShown: boolean;
  goBack: () => void;
  text?: string;
}

const ErrorModal: FC<Props> = ({
  isShown,
  goBack,
  text = "Something went wrong. Please contact support.",
}) => {
  const { classes } = useStyles();
  return (
    <Dialog onClose={goBack} open={isShown}>
      <DialogTitle className={classes.red}>
        <CancelIcon className={classes.img} />
        <Typography align="center" variant="h4" className={classes.textError}>
          Error!
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.top}>
        <Typography align="center" variant="body1" className={classes.text}>
          {text}
        </Typography>
        <DialogActions className={classes.back}>
          <Button onClick={goBack} className={classes.button}>
            BACK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorModal;
