import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Container, Tab, Tabs, Typography } from "@mui/material";
import { ReportByOrderId } from "./byOrderId/reportByOrderId";
import { ReportBySpecimen } from "./bySpecimen/reportBySpecimen";
import useTranslation from "hooks/useTranslation";
import { useSpecimenTrackingReportsStyle } from "./styles";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const SpecimenTrackingReports = () => {
  const {
    web: {
      specimenTrackingReport: {
        backToList,
        specimenTrackingReports,
        reportBySpecimen,
        reportByOrderId,
      },
    },
  } = useTranslation();
  const [value, setValue] = React.useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const { classes } = useSpecimenTrackingReportsStyle();

  const navigate = useNavigate();
  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  return (
    <Container className={classes.rootContainer}>
      <Box className={classes.titleContainer} alignItems="center">
        <Button
          className={classes.backButton}
          variant="text"
          onClick={() => navigate("/console/specimen-tracking")}
          role="button"
        >
          <ChevronLeft />
          <Typography variant="body1">{backToList}</Typography>
        </Button>

        <Typography className={classes.reportTitle} color="inherit" variant="body1">
          {specimenTrackingReports}
        </Typography>
      </Box>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
        className={classes.tabsContainer}
      >
        <Tab label={reportBySpecimen} {...a11yProps(0)} />
        <Tab label={reportByOrderId} {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <ReportBySpecimen />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ReportByOrderId />
      </TabPanel>
    </Container>
  );
};
