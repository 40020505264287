import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import SearchAndMatchWidget from "../../../../../SearchAndMatchWidget";
import { getPreselectedEdapVisitType, getPreselectedVisitType } from "./helpers";
import { API_ENDPOINTS } from "../../../../../../util/Api_Endpoints";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "../../../../hooks/useVirtualVisitReducer/Actions";
import { useApp } from "../../../../../../util/AppContext";
import { useAuth } from "../../../../../../util/Security";
import { axiosClient } from "../../../../../../util/api_helper";
import { GetCurrentProgram, useProgramType } from "../../../../../../util/programsHelpers";
import { EdapVisitType, User, UserDepartment, VisitType } from "../../../../utils/types";
import { useIsMobile } from "util/deviceUtils";
import { useStyles } from "./CreateEncounterModal.styles";

type CreateEncounterModalProps = {
  open: boolean;
  onClose: () => void;
};

const VIRTUAL_FOLLOW_UP_VISIT = "VIRTUAL FOLLOW UP VISIT";
const ESTABLISHED = "ESTABLISHED";

const CreateEncounterModal: React.FC<CreateEncounterModalProps> = ({ open, onClose }) => {
  const { classes } = useStyles();
  const app = useApp();
  const { user } = useAuth();
  const { isAdhoc } = useProgramType();
  const currentProgram = GetCurrentProgram();

  const virtualSession = app.vs_visits_state.currentVirtualVisit;
  const [patient, setPatient] = useState<User>(
    app.vs_visits_state.currentVirtualVisit.patient_info,
  );

  const [userDepartments, setUserDepartments] = useState<UserDepartment[]>([]);
  const [visitTypes, setVisitTypes] = useState<VisitType[]>([]);
  const [edapVisitTypes, setEdapVisitTypes] = useState<EdapVisitType[]>([]);
  const [visitTypeId, setVisitTypeId] = useState<number | string>("");
  const [departmentId, setDepartmentId] = useState<number | string>("");
  const [visitTime, setVisitTime] = useState<Date | null>(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useIsMobile();

  const canCreateEncouner =
    !virtualSession.external_emr_encounter_id && patient?.emr_mrn && departmentId && visitTypeId;

  useEffect(() => {
    setPatient(app.vs_visits_state.currentVirtualVisit.patient_info);
  }, [app.vs_visits_state.currentVirtualVisit.patient_info]);

  const getDepartmnets = async () => {
    try {
      let url = `${API_ENDPOINTS.get_user_departments}`;
      if (isAdhoc) {
        url = `${API_ENDPOINTS.get_department_users_data}?user_id=${user.userId}&program_id=${currentProgram?.id}`;
      }
      const response = await axiosClient.get(url);
      setUserDepartments(response?.data?.data || response?.data);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    const presettedPrimaryDepartment: UserDepartment | undefined = userDepartments?.find(
      (department) => department.is_primary,
    );

    if (
      virtualSession?.department_id &&
      userDepartments?.some(
        (userDepartment) => userDepartment.department_id === virtualSession?.department_id,
      )
    ) {
      setDepartmentId(virtualSession?.department_id);
    } else if (presettedPrimaryDepartment?.is_primary) {
      setDepartmentId(presettedPrimaryDepartment.department_id);
    }
  }, [userDepartments, virtualSession?.department_id]);

  useEffect(() => {
    if (user.userId) {
      getDepartmnets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userId]);

  const getVisitTypes = async () => {
    try {
      const url = `/api/s/console/virtualvisit/visit-type?department_id=${departmentId}`;
      const response = await axiosClient.get(url);
      const { data } = response.data;
      setVisitTypes(data);

      if (data.length) {
        const visitType = getPreselectedVisitType([VIRTUAL_FOLLOW_UP_VISIT, ESTABLISHED], data);

        if (visitType) {
          setVisitTypeId(visitType.id);
        }
      }
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  const getEdapVisitTypes = async () => {
    try {
      const url = `/api/s/console/virtualvisit/visit-type?department_id=${departmentId}&program_id=${currentProgram?.id}`;
      const response = await axiosClient.get(url);
      const { data } = response.data;
      setEdapVisitTypes(data);

      if (data.length) {
        const visitType = getPreselectedEdapVisitType([VIRTUAL_FOLLOW_UP_VISIT, ESTABLISHED], data);

        if (visitType) {
          setVisitTypeId(visitType.visit_type_cd);
        }
      }
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    if (departmentId && !isAdhoc) {
      getVisitTypes();
    } else if (departmentId && isAdhoc) {
      getEdapVisitTypes();
    }
    setVisitTypes([]);
    setEdapVisitTypes([]);
    setVisitTypeId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const createEncounter = async () => {
    try {
      setIsLoading(true);
      const url = "/api/s/console/virtualvisit/encounter";
      const visitType = visitTypes.find((type) => type.id === visitTypeId);
      const edapVisitType = edapVisitTypes.find((type) => type.visit_type_cd === visitTypeId);

      const response = await axiosClient.post(url, {
        emrMrn: patient?.emr_mrn,
        departmentId,
        sourceSysId: !isAdhoc ? visitType?.source_sys_id : edapVisitType?.visit_type_cd,
        visitType: !isAdhoc ? visitType?.name : edapVisitType?.visit_type,
        visitId: virtualSession.id,
        date: visitTime?.toISOString(),
      });

      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...virtualSession,
          external_emr_encounter_id: response.data.externalEmrEncounterId,
        },
      });
      onClose();
      app.addInfoMsg("Encounter for this patient created successfully.");
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      classes={{ paperWidthSm: classes.dialog }}
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle id="title" className={classes.dialogTitle}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
          data-testid="close-button"
          size="large"
        >
          <Close />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          Create encounter
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" className={classes.subtitle}>
          Would you like to generate an EPIC encounter for this patient?
        </Typography>

        {!!virtualSession.id && (
          <Box marginBottom={!patient?.emr_mrn ? "50px" : 0}>
            <SearchAndMatchWidget
              patient={patient}
              setPatient={setPatient}
              virtualSession={virtualSession}
            />
          </Box>
        )}

        {!!patient?.emr_mrn && (
          <Grid justifyContent="space-between" container className={classes.selectContainer}>
            <TextField
              type="number"
              className={classes.select}
              label="Department"
              select
              value={departmentId}
              onChange={(e) => setDepartmentId(e.target.value)}
              variant="outlined"
              disabled={!userDepartments?.length}
            >
              {userDepartments?.map((department) => (
                <MenuItem key={department.department_id} value={department.department_id}>
                  {department.department_name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="number"
              className={classes.select}
              label="Visit Type"
              select
              value={visitTypeId}
              onChange={(e) => setVisitTypeId(e.target.value)}
              variant="outlined"
              disabled={!isAdhoc ? !visitTypes.length : !edapVisitTypes.length}
            >
              {!isAdhoc &&
                visitTypes.map((visitType) => (
                  <MenuItem key={visitType.id} value={visitType.id}>
                    {visitType.name}
                  </MenuItem>
                ))}
              {isAdhoc &&
                edapVisitTypes.map((visitType) => (
                  <MenuItem key={visitType.visit_type_cd} value={visitType.visit_type_cd}>
                    {visitType.visit_type}
                  </MenuItem>
                ))}
            </TextField>
            <DateTimePicker
              className={classes.select}
              label="Visit Date/Time"
              value={visitTime}
              onChange={(e) => setVisitTime(e)}
              maxDate={new Date()}
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={createEncounter}
          disabled={!canCreateEncouner || isLoading}
          startIcon={isLoading && <CircularProgress color="primary" size={25} />}
          fullWidth
        >
          Confirm create encounter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEncounterModal;
