import React, { useRef, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { AddManuallyModal } from "./addManuallyModal";
import { useGetPatientInfo } from "hooks/specimenTracking/useGetPatientInfo/useGetPatientInfo";
import useTranslation from "hooks/useTranslation";
import { API_ENDPOINTS } from "util/api_helper";

export const AddManuallyPickDropModal = ({ handleScanPickOrDrop }) => {
  const specimenIdRef = useRef<HTMLInputElement>(null);
  const [specimenExternalId, setSpecimenExternalId] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const {
    web: {
      specimenTrackingAdd: { specimenId },
    },
  } = useTranslation();

  const { patientInfo, handleSearchPatient } = useGetPatientInfo({
    endpoint: API_ENDPOINTS.specimenTrackingGetSpecimens,
    searchBy: "specimenExternalId",
    getErrorMessage: () => "No patient found with this Specimen ID.",
  });

  const setInputFocus = () => {
    if (specimenIdRef?.current) {
      specimenIdRef.current.focus();
    }
  };

  const cleanSpecimenId = () => {
    setSpecimenExternalId("");
    setError("");
  };

  const handleBlur = () => {
    if (specimenExternalId) {
      handleSearchPatient(specimenExternalId);
    }
  };

  const searchSpecimen = async () => {
    setIsDisabled(true);
    if (specimenExternalId.length > 0) {
      const resp = await handleScanPickOrDrop(`HSC-${specimenExternalId}`);
      if (resp?.data) {
        cleanSpecimenId();
        setIsDisabled(false);
      } else {
        setError(resp.msg);
        setIsDisabled(false);
      }
      setInputFocus();
    }
  };

  return (
    <AddManuallyModal
      searchSpecimen={searchSpecimen}
      patientInfo={patientInfo}
      error={error}
      disableSave={isDisabled || !specimenExternalId}
      isLoading={isDisabled}
      onCloseModal={() => setSpecimenExternalId("")}
    >
      <Box display="flex" flexDirection="row">
        <TextField
          type="number"
          data-testid="text-specimen-add-manually"
          style={{ flex: 1, marginBottom: "1rem" }}
          inputRef={specimenIdRef}
          autoFocus
          value={specimenExternalId}
          required
          id="outlined-basic"
          label={specimenId}
          variant="outlined"
          onChange={(val) => {
            setSpecimenExternalId(val.target.value);
            setError("");
          }}
          onBlur={handleBlur}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              searchSpecimen();
              event.preventDefault();
            }
          }}
          inputProps={{ "data-testid": "specimenIdInput" }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ display: "flex", alignItems: "center" }}>
                <span>HSC-</span>
              </InputAdornment>
            ),
          }}
          disabled={isDisabled}
        />
      </Box>
    </AddManuallyModal>
  );
};
