import React, { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "./NewAppointment.styles";

const ModalFilter = ({ isOpen, handleClose, handleCancel, filterData }) => {
  const { classes } = useStyles();
  const [inputLocation, setInputLocation] = useState("");
  const [location, setLocation] = useState();
  const [chosenInsurance, setChosenInsurance] = useState([""]);
  const [isOnline, setIsOnlain] = useState(false);
  const [inputInsuransPlans, setInputInsuransPlans] = useState("");
  const [gender, setGender] = useState("");

  useEffect(() => {
    if (!filterData.location) {
      setInputLocation("");
    }
    if (!filterData.gender) {
      setGender("");
    }
    if (!filterData.isOnlineAppointment) {
      setIsOnlain(false);
    }
    if (filterData.insurance.length !== chosenInsurance.length) {
      setChosenInsurance(filterData.insurance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const handleChangeAddres = (value) => {
    setInputLocation(value);
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const state = result[0].address_components.filter(
      (it) => it.types[0] === "administrative_area_level_1",
    )[0]?.short_name;
    const city = result[0].address_components.filter((it) => it.types[0] === "locality")[0]
      ?.long_name;
    const zipCode = result[0].address_components.filter((it) => it.types[0] === "postal_code")[0]
      ?.long_name;
    setInputLocation(value);
    setLocation({ state, city, zipCode });
  };

  const bookOnline = () => {
    setIsOnlain((state) => !state);
  };

  const filter = () => {
    const data = {
      location: inputLocation,
      city: location ? location.city : "",
      zip: location ? location.zipCode : "",
      state: location ? location.state : "",
      isOnlineAppointment: isOnline,
      insurance: chosenInsurance,
      gender,
    };
    handleClose(data);
  };

  return (
    <div>
      <Modal className={classes.modalContainer} open={isOpen}>
        <Grid className={classes.modal}>
          <Typography className={classes.modalTitle}>Location</Typography>

          <Grid container alignItems="center" className={classes.block}>
            <PlacesAutocomplete
              value={inputLocation}
              onChange={handleChangeAddres}
              onSelect={handleSelect}
            >
              {({ getInputProps, getSuggestionItemProps, suggestions }) => {
                return (
                  <div className={classes.formCountry}>
                    <TextField
                      {...getInputProps()}
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="City, State of ZIP"
                    />

                    {suggestions.length > 0 && (
                      <Grid className={classes.suggestionsContainer}>
                        {suggestions.map((suggestion) => {
                          return (
                            <div {...getSuggestionItemProps(suggestion)}>
                              <Typography>{suggestion.description}</Typography>
                            </div>
                          );
                        })}
                      </Grid>
                    )}
                  </div>
                );
              }}
            </PlacesAutocomplete>
          </Grid>
          <Grid item className={classes.block}>
            <Typography className={classes.modalTitle}> Book Appointment Online</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={bookOnline} checked={isOnline} />}
                label="Book online"
              />
            </FormGroup>
          </Grid>
          <Grid item className={classes.block}>
            <Typography className={classes.modalTitle}>Insurance Plans</Typography>
            <Autocomplete
              onChange={(event, value) => {
                if (inputInsuransPlans.length === 0) {
                  setChosenInsurance([]);
                }
                if (value) {
                  setChosenInsurance(value);
                }
              }}
              // eslint-disable-next-line
              options={insuranceArr}
              getOptionLabel={(option) => option}
              value={chosenInsurance}
              fullWidth
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Search one or more ..."
                  onChange={(e) => setInputInsuransPlans(e.target.value)}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item>
            <FormControl variant="standard">
              <FormLabel className={classes.modalTitle}>Gender</FormLabel>
              <RadioGroup onChange={(e) => setGender(e.target.value)} row value={gender}>
                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                <FormControlLabel value="Male" control={<Radio />} label="Male" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={filter} className={classes.buttonApply}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default ModalFilter;

const insuranceArr = [
  "Aetna",
  "Horizon NJ Health",
  "Intergroup",
  "Medicaid",
  "Oxford Health Plan",
  "Qualcare",
  "US Family Health Plan",
  "Amerigroup",
  "Amerihealth (Amerihealth PA - HMO, POS, PPO (accept members that live in NJ but employers are based in PA)",
  "Amerihealth Caritas",
  "Amerihealth NJ",
  "Atlantic Health System PPO",
  "Brighton Create",
  "Brighton Health Plan Solutions (FKA Magnacare)",
  "Centivo",
  "Cigna",
  "Consumer Health Network",
  "First MCO (workers comp plan)",
  "Horizon Blue Cross Blue Shield of NJ",
  "Horizon Blue Cross Blue Shield Omnia Tier 1",
  "Humana Medicare Advantage PPO",
  "Humana Military",
  "Medicare Railroad",
  "Oscar Health Insurance (accesses Qualcare’s HMO Network)",
  "United Healthcare Community Plan",
  "United Healthcare VA",
  "UPMC Health Plan for Life Dual (only)",
  "World Trade Center Health Program",
  "United Healthcare",
  "Wellcare",
  "Horizon Casualty",
  "Longevity Health Plan",
  "AHS PPO Employee Medical Plan",
  "Amerihealth",
  "Cigna HealthCare",
  "Consumer Health Network (CHN)",
  "Horizon BC/BS of NJ",
  "Multiplan",
  "United HealthCare",
  "Private Healthcare Systems-PHCS",
  "Beechsteet/Medichoice",
  "Community Care Network (CCN)",
  "Devon/Americare",
  "First Health Group Corp",
  "GHI",
  "Guardian",
  "Healthcare Payors Coalition",
  "Local 825-Operating Engineers",
  "One Health Plan",
  "United HealthCare (Medicare)",
  "Wellchoice",
  "NJ Manufacturers Insurance",
  "University Health Plan",
  "Anthem Health",
  "Empire BC/BS",
  "Family Choice Health Alliance",
  "Cigna (Medicare)",
  "Local 4-69 Health Fund",
  "Mastercare",
  "Cost Care (Unicare)",
  "Ethix",
  "United HealthCare(workerscomp)",
  "Medicare",
  "AARP",
  "NJ Medicaid",
  "Horizon Medicare Blue",
  "Great West",
  "Aetna Better Health",
  "Beechstreet",
  "Coventry Healthcare",
  "Devon",
  "Magnacare",
  "Medicare Rail Road",
  "OMNIA Health Alliance",
  "United Healthcare Community",
  "Veteran's Choice Program",
  "WellCare",
  "Delta Dental",
  "Cigna (POS)",
  "Cigna HMO",
  "Cigna PPO",
  "Amerihealth (HMO)",
  "Amerihealth (PPO)",
  "Oxford Freedom",
  "Oxford (Liberty)",
  "Amerihealth (POS)",
  "Health Net",
  "Tricare (Champus)",
  "Mastercare (PPO)",
  "Guardian (PHCS only)",
  "RR Medical",
  "Summit Medical Group",
  "Railroad Medicare",
  "Call for Insurance Plans",
  "Magnet (Magnacare)",
  "IDA",
  "Horizon Mercy",
  "Americhoice",
  "Horizon HMO Blue/Blue Choice",
  "Empire Medicare",
  "Humana",
  "Phys Health Services",
  "Local 863 Welfare Fund",
  "Definity",
  "Call Office",
  "First Health PPO",
  "Horizon HMO",
  "Local 734",
  "NJ Carpenters Fund",
  "Health Network America",
  "Mutual of Omaha",
  "Benesight",
  "GEHA",
  "IBEW 102 Welfare Fund",
  "Principle Financial",
  "Gallagher Bassett",
  "Unicare (Cost Care)",
  "Keystone",
  "Metlife",
  "Choice Care",
  "Better Health",
  "Metrowest",
  "Horizon MyWay HRA PPO",
  "Horizon MyWay HSA PPO",
  "Horizon PPO",
  "Horizon Managed Care Network",
  "Horizon Advantage EPO",
  "Horizon Direct Access",
  "Horizon EPO/EPO PLUS",
  "Horizon Medicare Blue (PPO)",
  "Horizon Medicare Blue Access (HMO-POS)",
  "Horizon Medicare Blue Advantage (HMO)",
  "Horizon Medicare Blue Choice w/Rx (HMO)",
  "Horizon Medicare Blue Group (PPO)",
  "Horizon Medicare Blue Group w/Rx (PPO)",
  "Horizon Medicare Blue Value (HMO)",
  "Horizon Medicare Blue Value w/Rx (HMO)",
  "Horizon MyWay HRA Direct Access",
  "Horizon MyWay HSA Direct Access",
  "Horizon POS",
  "Horizon Patient-Centered Adv EPO",
  "NJ DIRECT",
  "NJ DIRECT MA PPO",
  "OMNIA",
  "OMNIA Bronze",
  "OMNIA Gold",
  "OMNIA HSA",
  "OMNIA Platinum",
  "OMNIA Silver",
  "OMNIA Silver HSA",
  "Mail Handlers",
  "New England",
  "Vista",
  "NALC",
  "Liberty Mutual",
  "Travelers",
  "I.E. Schaffer",
  "Heavy & General Laborers",
  "Empire Health Choice",
  "Firemans Fund",
  "Corporate Health",
  "CHA",
  "VSP",
  "Oscar Health Insurance",
  "Horizon",
  "Health Care Payers Coalition",
  "Health Net (PPO)",
  "Champus/Tricare",
  "Horizon BC/BS (PPO)",
  "Phys HlthCare Associates of NJ",
  "Advanced Healthcare Systems",
  "HealthSouth",
  "Managed Health Network",
  "Atlantic Care",
  "Blue Shield",
  "Americaid",
  "Americare",
  "Blue Choice",
  "Allied Health Group",
  "Blue Cross/Blue Shield PPO",
  "Champus",
  "Guardian (PHS)",
  "American Imaging Management",
  "State Farm",
  "AIG",
  "Affordable Health Network",
  "Local 172",
  "Preferred Health Network",
  "Cigna Behavioral Health",
  "Local 408 Welfare Fund",
  "Local 472",
  "No Fault/PIP",
  "Ironworkers Health Fund",
  "Steamfitters Welfare Fund",
  "Corvel",
  "Courtesy Health",
  "NTCA",
  "One Call Medical",
  "Premier Healthcare",
  "United Payors/United Providers",
  "Tri-Care",
  "Qualcare Workers Compensation",
  "Cigna (Workers Comp)",
  "PHCS (PPO)",
  "Oxford (HMO & POS)",
  "Local 464A",
  "None",
  "University Health Care",
  "Mastercare (Workers Comp)",
  "Health Network Inc.",
  "HMO Blue",
  "Unicare",
  "Medview Services, Inc",
  "Health Republic Insurance NJ",
  "First Option",
  "AmeriHealth NJ Bronze EPO",
  "AmeriHealth NJ Platinum POS+",
  "AmeriHealth NJ Pref Bronze EPO",
  "AmeriHealth NJ Prem Gold POS+",
  "AmeriHealth NJ Prem SilverPOS+",
  "AmeriHealth NJPremium Gold EPO",
  "AmeriHealth PA - HMO, POS, PPO",
  "Consumer Health Network (PPO)",
  "Private Healthcare System",
  "United Healthcare (Compass)",
  "PECS",
  "All State",
  "Mediplan",
  "American Provider Network",
  "Fortis",
  "Excel",
  "Horizon Vista",
  "Mental Health Network",
  "Focus",
  "Evolutions Healthcare Systems",
  "Health Payors Organization",
  "USA Managed Care Organization",
  "United Behavioral",
  "Alta",
  "Healthcare Alliance",
  "State Mutual",
  "Benefit Concepts",
  "Eastern Benefits Systems",
  "Managed Care Strategies",
  "National Health Insurance Co",
  "First Trenton",
  "Health Payors Org - Medicare",
  "Magellan Behavioral Health",
  "Managed Health Care Alliance",
  "Concern Plus",
  "PHP Healthcare",
  "American Complimentary Care",
  "Coalition for Care",
  "American Health Plans",
  "Vision Care Advantage",
  "Vision Care Plan",
  "Concord Behavioral Health Care",
  "Block Vision-Plan Plus",
  "Comprehensive Rehab. Assoc.",
  "Delta Dental - Premier",
  "Eye Health Network",
  "HCO Network",
  "Health Vision Pln USA Eye Plan",
  "Healthcare Compare",
  "Healthcare Payers",
  "Healthstar, Inc",
  "Healthwise",
  "Mental Health Case Mgt",
  "Midatlantic Health Care",
  "MRSI/MCSI/Selectcare",
  "Mutually Preferred",
  "Phoenix Group Services",
  "Quality Rehab. Services",
  "Signature",
  "Strawberry Assoc",
  "Tristate",
  "USA Health",
  "CHN (PPO)",
  "Liberty Health Plan",
  "Anthem BC/BS",
  "Atlantis Health Plan",
  "Galaxy Health Care",
  "Mercy Health Plan (HMO Blue)",
  "Associate Master Trust",
  "TPA",
  "Mayan",
  "Nat'l. Preferred Prov. Network",
  "US Life Care",
  "Devon (PPO)",
  "Horizon BC/BS Advantage EPO",
  "Horizon BCBSNJ (HMO)",
  "Horizon BCBS-Indemnity & PPO",
  "United/Oxford",
  "Humana (Choice Care)",
  "Mercy",
  "Local 1115 (Health & Benefits)",
  "National Health Plan",
  "HealthTrak, Inc.",
  "Maxim Health Service",
  "Olsten Health Sys",
  "Options Unlimited",
  "Organized Hlthcare Strategies",
  "Pro Health of New Jersey",
  "ValueOptions",
  "Eucho",
  "Travelers/Medicare",
  "Pronet",
  "Plan Data",
  "US Life Idemnity",
  "McKinsey Indemnity",
  "Personal Choice",
  "Med Advantage",
  "UMDNJ University Behavioral",
  "Affordable Medical Network",
  "Local 102",
  "Medicare Blue",
  "Better Health Advantage",
  "Multiplan, Inc. (PPO)",
  "Premier",
  "NJ Dept of Labor",
  "Carpenters Fund",
  "Federal Employees",
  "Uniform Services",
  "Horizon BC of NJ",
  "Healthnet/PHS/Mastercare",
  "Phys HealthCare Plan of NJ",
  "Blair Mill",
  "Davis Vision Plan-GTE &Mutual",
  "NHP",
  "Healthcare Payors Coal. PPO",
  "Premiere Healthcare",
  "United Health Plan",
  "Grand Union Co.",
  "Empire Blue Cross",
  "Local 115",
  "Empire Health Care",
  "Delta Premier",
  "PHCS POS",
  "National Providers Network Inc",
  "Chubb Health",
  "First MCO",
  "BC/BS HMO",
  "Monmouth Behavioral Health",
  "Multiplan (HMO)",
  "Horizon Blue Shield",
  "Health Choice",
  "Medichoice-Beechstreet",
  "Costcare",
  "Local 475",
  "Health Net-formerly (PHS)",
  "Teamsters Local #408 Welf. Fun",
  "Blue Select",
  "Empire Blue Shield",
  "Great Western",
  "Wellchoice (PPO&HMO)",
  "Magellan PPO",
];
