import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    height: "100ch",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Alegreya Sans",
    margin: "0px auto",
    fontSize: "26px",
    lineHeight: "40px",
    color: "rgb(40, 42, 43)",
    fontWeight: 500,
    textAlign: "center",
    width: "70%",
    maxWidth: "800px",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  button: {
    width: "120px",
    height: "42px",
    textTransform: "uppercase",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    borderRadius: "4px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)",
    border: "none",
    fontWeight: 500,
    marginTop: "40px",
  },
}));

export default useStyles;
