import React, { useEffect, useState } from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { PhysicianNotesStyles } from "../styles";

function PhysicianNotes() {
  const { classes } = PhysicianNotesStyles();

  const app = useApp();
  const currentVirtualVisit = app?.vs_visits_state?.currentVirtualVisit;
  const notes = currentVirtualVisit?.checkout_note || "";

  const [selectedTags, setSelectedTags] = useState([]);

  const shouldComponentShow = selectedTags?.length || notes;
  let heading = "Physician Notes";

  useEffect(() => {
    const loadTags = async () => {
      try {
        const { data } = await axiosClient.get(
          `api/s/console/virtualvisit/sessions/${currentVirtualVisit.id}/tags`,
        );
        setSelectedTags(data);
      } catch (e) {
        app.addError("Something went wrong. Please contact support.");
      }
    };
    if (currentVirtualVisit?.id) {
      loadTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.id]);

  if (!notes) {
    heading = "Tags";
  }

  if (!shouldComponentShow) {
    return null;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container>
          <Grid item xs={1}>
            <ErrorOutlineOutlinedIcon className={classes.icon} />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.textField}>
              <b>{heading}</b>
            </Typography>
            <div className={classes.tagContainer}>
              {selectedTags?.map((tag) => (
                <Typography className={classes.tag} key={tag.id}>
                  {tag.name}
                </Typography>
              ))}
            </div>
            {notes && (
              <Typography className={classes.textFieldDesc}>
                <span>
                  <b>Notes: </b>
                </span>
                {notes}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default PhysicianNotes;
