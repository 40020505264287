import React, { useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useCustomStyles } from "components/Video/components/PreJoinScreens/AudioVideoTest/useCustomStyles";
import { QuerySteps } from "components/Video/components/PreJoinScreens/Steps";
import { useApp } from "util/AppContext";
import { isRNApp, postRNOpenMeetingRoom } from "util/RNWebView";

interface StartTestProps {
  setStep: (Steps: QuerySteps) => void;
  vsId: number | undefined;
}

const StartTest = ({ setStep, vsId }: StartTestProps) => {
  const { classes } = useCustomStyles();
  const app = useApp();

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    permissions
      .then(() => {})
      .catch(() => {
        app.addError("Can not get access to camera or mic!");
      });
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.parentDiv}>
      <div>
        <div className={classes.topHeading}>
          Before we get started, let’s check your audio and video.
        </div>
        <Typography component="p" className={classes.subHeading}>
          To make sure you’re ready to see a physician, we will walk through testing your audio and
          video.
        </Typography>
      </div>
      <Grid container justifyContent="center">
        <div className={classes.btnDiv}>
          <Button
            className={classes.testPreviewbutton}
            onClick={() => setStep(QuerySteps.CONSENT)}
            variant="outlined"
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isRNApp) {
                postRNOpenMeetingRoom(vsId);
              } else {
                setStep(QuerySteps.AUDIO_PERMISSION);
              }
            }}
            className={classes.testPreviewbutton}
          >
            start test
          </Button>
        </div>
      </Grid>
    </div>
  );
};

export default StartTest;
