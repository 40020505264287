import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../../../../util/Api_Endpoints";
import { axiosClient } from "../../../../util/api_helper";

const fetchMessages = (url) => axiosClient.get(url);
const useGetPatientMessages = ({ enabled, vsId }: { enabled?: boolean; vsId?: number | null }) => {
  return useQuery(
    "fetch-patient-messages",
    () => fetchMessages(`${API_ENDPOINTS.fetch_patient_messags}?vv_id=${vsId}`),
    { enabled },
  );
};

export default useGetPatientMessages;
