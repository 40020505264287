import React from "react";
import { DatePicker } from "@mui/x-date-pickers";

/**
 * @deprecated Use DatePicker from @mui/x-date-pickers instead
 */
const Calender = ({
  dateProp,
  setDateProp,
  label = "Date",
  isDisabled = false,
  isRequired,
  rootClass,
  labelClass,
  disableFuture = false,
  inputVariant = "outlined",
}) => {
  const handleChangeDate = (newDate) => {
    setDateProp(newDate);
  };

  return (
    <DatePicker
      format="MM/dd/yyyy"
      margin="normal"
      label={label}
      value={dateProp}
      onChange={handleChangeDate}
      disabled={isDisabled}
      required={isRequired}
      classes={{ root: rootClass }}
      slotProps={{
        textField: {
          "data-testid": "calendar",
          id: "sign-up-dob",
          labelClass,
          // @ts-ignore
          variant: inputVariant,
          fullWidth: true,
          helperText: "MM/DD/YYYY",
        },
        openPickerButton: {
          "aria-label": "change date",
        },
      }}
      disableFuture={disableFuture}
    />
  );
};

export default Calender;
