/** @format */

/* eslint-disable */
import React from "react";
import { Grid } from "@mui/material";
import ProofOfVaccination from "../ImprovedProofOfVaccinations";
import Loader from "../Loader";
import QrCode from "../VaccinationDetails";
import { makeStyles } from "tss-react/mui";
import { useFetch } from "../../util/useFetch";
import { GET_OPTIONS } from "../../util/api_helper";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {},
}));

export default function PatientData({ error: ParentError, clientName, showQRCode }) {
  const uri = "/api/s/fhir/immunizations";
  const { isLoading, response } = useFetch(uri, GET_OPTIONS);
  const vaccines = response?.immunizations || [];
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={11} md={8} lg={8}>
        {!isLoading ? (
          !ParentError?.message ? (
            <div className={classes.demo}>
              {vaccines && (
                <ProofOfVaccination vaccines={vaccines} size="sm" clientName={clientName} />
              )}
              {showQRCode && vaccines?.length ? <QrCode size="sm" /> : null}
            </div>
          ) : (
            <div>Error</div>
          )
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </Grid>
    </div>
  );
}
