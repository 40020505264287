export const additionalLinksInputs = [
  { label: "On my Way Link", name: "onMyWayLink", type: "text" },
  { label: "Schedule an Appointment Link", name: "scheduleMyAppointmentLink", type: "text" },
  { label: "Virtual Visits Link", name: "virtualVisitsLink", type: "text" },
  { label: "Request call back link", name: "requestCallbackLink", type: "text" },
  { label: "About our Services", name: "aboutOurServices", type: "text" },
];

export const identifierInfoInputs = [
  { label: "Location ID", name: "locationId", type: "text" },
  { label: "EHR Location ID", name: "ehrLocationId", type: "text" },
  { label: "Source System Name", name: "sourceSystemName", type: "text" },
  { label: "Time Zone", name: "timezone", type: "text" },
  { label: "Press Ganey ID", name: "pressGaneyId", type: "text" },
];

export const identifierInfoSelectOptions = {
  sourceSystemType: ["System Type 1", "System Type 2", "System Type 3"],
};

export const languages = [
  "English",
  "Mandarin Chinese",
  "Hindi",
  "Spanish",
  "French",
  "Arabic",
  "Bengali",
  "Russian",
  "Portuguese",
  "Indonesian",
  "Urdu",
  "German",
  "Japanese",
  "Swahili",
  "Marathi",
  "Telugu",
  "Turkish",
  "Tamil",
  "Vietnamese",
  "Italian",
  "Korean",
  "Persian",
  "Polish",
  "Dutch",
  "Thai",
];
