import React, { useEffect, useState } from "react";
import { RefetchOptions } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, Grid, Typography } from "@mui/material";
import ConversationList from "./ConversationList";
import Filters from "./Filters";
import useWaitingVsVisits from "../../components/Video/hooks/useWaitingVsVisits/useWaitingVsVisits";
import { API_ENDPOINTS } from "../../util/Api_Endpoints";
import { useConversationStyles } from "./useConversationStyles";
import { CONVERSATION_LINK } from "../../util/Links";
import { getApiHost } from "../../util/api_helper";
import { isRNApp } from "util/RNWebView";
import { useIsMobile } from "util/deviceUtils";
import { MessageFilters, TConversation } from "./types";

const PatientConversations = () => {
  const { classes } = useConversationStyles();
  const apiHost = getApiHost();
  const [messageFilter, setMessageFilter] = useState<string>(MessageFilters.ACTIVE);

  const { data, refetch } = useWaitingVsVisits({
    url: `${apiHost}/${API_ENDPOINTS.fetch_pateint_sessions}?status=${messageFilter}`,
    enabled: false,
  });

  useEffect(() => {
    refetch({
      url: `${apiHost}/${API_ENDPOINTS.fetch_pateint_sessions}?status=${messageFilter}`,
    } as RefetchOptions);
  }, [messageFilter, apiHost, refetch]);

  const location = useLocation();
  const navigate = useNavigate();
  const showList = location.pathname.includes("chatroom");
  const isMobile = useIsMobile();
  const showListCondition = !showList || !isMobile;
  const handleChangeFilter = (value) => {
    setMessageFilter(value);
    navigate(CONVERSATION_LINK);
  };

  return (
    <Grid container className={classes.container} spacing={0}>
      <Grid item xs={12} sm={3} className={classes.leftView}>
        {showListCondition && (
          <Box paddingBottom="10px" borderBottom="1px solid rgba(0, 0, 0, 0.12)">
            {!isRNApp && (
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.backBtn}
                  onClick={() => navigate("/")}
                >
                  <ArrowBackIosIcon />
                </Button>
                <Typography component="h5" className={classes.title}>
                  Messages
                </Typography>
              </div>
            )}
            <div className={classes.queueHeader}>
              <Filters handleChangeFilter={handleChangeFilter} filter={messageFilter} />
            </div>
          </Box>
        )}
        {showListCondition && <ConversationList conversations={data?.data as TConversation[]} />}
      </Grid>
      <Grid item xs={12} sm={9}>
        {!isMobile && <Typography variant="h6">Please select a Conversation</Typography>}
        <Outlet context={[messageFilter]} />
      </Grid>
    </Grid>
  );
};

export default PatientConversations;
