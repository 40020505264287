import React, { useState } from "react";
import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api";
import markerIcon from "views/NewAppointment/assets/marker.png";
import markerIconChoose from "views/NewAppointment/assets/markerChoose.png";

export type MarkerProp = { coordinates: Marker["props"]["position"]; id: number | string };

type Props = {
  containerStyle: GoogleMap["props"]["mapContainerStyle"];
  center?: GoogleMap["props"]["center"];
  zoom?: GoogleMap["props"]["zoom"];
  markers?: MarkerProp[];
  onMarkerClick?: (id: number | string) => void;
  chosenMarker?: MarkerProp;
  infoWindowProps?: Omit<InfoWindow["props"], "position">;
};

const Map: React.FC<Props> = ({
  containerStyle,
  center = { lat: 0, lng: 0 },
  zoom = 1,
  markers = [],
  onMarkerClick,
  chosenMarker,
  infoWindowProps,
}) => {
  const [map, setMap] = useState<google.maps.Map>();

  const fitBounds = (map) => {
    if (markers.length === 1) {
      map.setZoom(zoom || 10);
      map.setCenter(markers[0].coordinates);
    } else {
      const bounds = new google.maps.LatLngBounds();
      markers.forEach(({ coordinates }) => bounds.extend(coordinates));
      map?.fitBounds(bounds);
    }
  };

  if (process.env.REACT_APP_GOOGLE_MAP_API_KEY) {
    return (
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
        <GoogleMap
          onLoad={(mapProp) => {
            setMap(mapProp);
            if (markers?.length) {
              fitBounds(mapProp);
            } else {
              mapProp.setZoom(zoom);
              mapProp.setCenter(center);
            }
          }}
          mapContainerStyle={containerStyle}
        >
          {markers?.map(({ coordinates, id }, i) => (
            <Marker
              key={id}
              position={coordinates}
              icon={chosenMarker?.id === id ? markerIconChoose : markerIcon}
              onClick={() => onMarkerClick && onMarkerClick(id)}
              onLoad={() => {
                if (i === 0 && markers?.length !== 1) {
                  fitBounds(map);
                }
              }}
            >
              {chosenMarker?.id === id && (
                <InfoWindow position={chosenMarker.coordinates} {...infoWindowProps} />
              )}
            </Marker>
          ))}
        </GoogleMap>
      </LoadScript>
    );
  }

  return null;
};

export default React.memo(Map);
