/** @format */

/* eslint-disable */
import { useReducer } from "react";
import {
  ADD_IMAGE,
  CLEAR_IMAGES_LIST,
  REMOVE_IMAGE,
  SET_CONTEXT_DATA,
  SET_IS_IMAGES_LIST_SAVED,
} from "./Actions";

const initialState = {
  imagesList: [],
  contextType: "",
  contextID: "",
  isImageListSaved: false,
};
const patientStepsReducer = (state, { type, payload }) => {
  switch (type) {
    case ADD_IMAGE:
      return { ...state, imagesList: [...state.imagesList, { ...payload }] };

    case REMOVE_IMAGE:
      const tempImgList = state.imagesList;
      tempImgList.splice(payload, 1);
      return { ...state, imagesList: [...tempImgList] };

    case SET_CONTEXT_DATA:
      return { ...state, contextType: payload.contextType, contextID: payload.contextID };

    case CLEAR_IMAGES_LIST:
      return { ...state, imagesList: [] };

    case SET_IS_IMAGES_LIST_SAVED:
      return { ...state, isImageListSaved: payload };

    default:
      return { ...state };
  }
};
export const usePatientStepsReducer = () => {
  return useReducer(patientStepsReducer, initialState);
};
