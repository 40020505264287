import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSpecimenTrackingReportsStyle } from "views/SpecimenTracking/views/Reports/styles";
import useTranslation from "hooks/useTranslation";

interface Props {
  value: string;
  onChange: (event: React.MouseEvent<HTMLElement>, newSelection: string) => void;
  filterBy: string;
}

const ToggleButtonsFilter = ({ value, onChange, filterBy }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>();
  const {
    classes: { selectedToogleButton, toogleButton },
  } = useSpecimenTrackingReportsStyle();
  const {
    web: {
      specimenReportOrderId: { pickedUp, droppedOff, onTime, overdue, mySpecimens },
    },
  } = useTranslation();

  const buttonsMapping = {
    type: [
      { id: "ADD", label: "All" },
      { id: "PICK_UP", label: pickedUp },
      { id: "DROP_OFF", label: droppedOff },
    ],
    status: [
      { id: "ALL", label: "All" },
      { id: "ON_TIME", label: onTime },
      { id: "OVERDUE", label: overdue },
    ],
    collectedBy: [
      { id: "COLLECTED_BY", label: mySpecimens },
      { id: "ALL", label: "All Specimens" },
    ],
  };

  const toggleButtons = buttonsMapping[filterBy] || [];

  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    const target = event.target as HTMLElement;
    if (!target.innerText || selectedTab !== target.innerText) {
      onChange(event, newValue);
      setSelectedTab(target.innerText);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      data-testid="toggleGroup"
      style={{ height: "100%" }}
    >
      {toggleButtons.map(({ id, label }) => (
        <ToggleButton
          key={id}
          value={id}
          className={`${toogleButton} ${value === id && selectedToogleButton}`}
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButtonsFilter;
