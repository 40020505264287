import { makeStyles } from "tss-react/mui";

export const preJoinStyles = makeStyles()((theme) => ({
  rootGridEmbedded: {
    marginTop: "0px",
  },
  rootGrid: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  stepper: {
    display: "flex",
    width: "100%",
    margin: "40px auto",
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: "47%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  greyText: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  fileUploadBox: {
    width: 320,
    height: 150,
    backgroundColor: theme.palette.common.white,
    outline: "2px dashed black",
    outlineOffset: "-10px",
    marginTop: 15,
    padding: 15,
  },
  stepperHorizontalLineActive: {
    width: "98%",
    height: "6px",
    background: theme.palette.secondary.light,
    border: 0,
  },
  stepperHorizontalLineCompleted: {
    width: "98%",
    height: "6px",
    background: theme.palette.primary.main,
    border: 0,
  },
  tabItem: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    height: "40px",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  },
  gridContainer: {
    flexWrap: "nowrap",
    maxWidth: "608px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  additionalMargin: {
    marginTop: "40px",
  },
}));
