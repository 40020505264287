import React from "react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Box, Typography } from "@mui/material";
import { SET_SOUND_ENABLE_DISABLE } from "store/reducers/PlaySounds/actions";
import { useApp } from "util/AppContext";
import { useIsMobile } from "util/deviceUtils";
import { useStyles } from "./styles";

export const SoundToggleButton = () => {
  const { classes } = useStyles();
  const app = useApp();
  const { enabled: soundStatus } = app.playSounds_state;

  const isMobile = useIsMobile();
  const disableEnableSound = () => {
    app.playSounds_dispatch({ type: SET_SOUND_ENABLE_DISABLE, payload: !soundStatus });
  };

  return (
    <Box className={classes.container} onClick={disableEnableSound}>
      <Box className={soundStatus ? classes.iconContainerOn : classes.iconContainerOff}>
        {soundStatus ? (
          <VolumeUpIcon className={classes.iconOn} data-testid="enable-sound" />
        ) : (
          <VolumeOffIcon className={classes.iconOff} data-testid="mute-sound" />
        )}
      </Box>
      {!isMobile && <Typography variant="body1">{soundStatus ? "Sound On" : "Muted"} </Typography>}
    </Box>
  );
};
