import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SearchIcon from "@mui/icons-material/Search";
import { Button, CircularProgress, MenuItem, TextField } from "@mui/material";
import PatientCard from "../CreateVsVisit/PatientCard";
import CliniciansList from "./CliniciansList";
import { makeStyles } from "tss-react/mui";
import CellPhone from "components/shared/CellPhone";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { PHONE_MAX_LENGTH } from "util/constants";
import { axiosClient } from "util//api_helper";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";

const useStyles = makeStyles()(() => ({
  formContainer: {
    maxWidth: 800,
    margin: "0 auto",
    padding: "10px 20px 20px 20px",
    boxSizing: "border-box",
    "& h2": {
      width: "98%",
      textAlign: "left",
      margin: "20px auto",
    },
  },
  form: {
    "& .MuiTextField-root": {
      width: "98%",
      paddingBottom: 20,
      textAlign: "left",
    },
    "& .MuiPaper-root": {
      height: "100%",
      width: "98%",
    },
  },
  textField: {
    width: "100%",
    paddingBottom: 20,
    textAlign: "left",
  },
  formControl: {
    paddingBottom: 20,
  },
}));

const CreateSpeсialistSession = ({ patient }) => {
  const { classes } = useStyles();
  const { addError, addInfoMsg } = useApp();
  const { programId, departmentList, departmentId } = usePrograms();

  const [neuroServices, setNeuroServices] = useState([]);
  const [isSearchComplete, setIsSearchComplete] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const getNeuroServices = async () => {
    try {
      const url = `/api/s/console/config/programs/${programId}/visit-reasons`;
      const response = await axiosClient.get(url);
      const { data } = response.data;
      setNeuroServices(data);
    } catch (e) {
      addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    if (programId) {
      getNeuroServices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  const checkIsCellPhoneVerified = async (cellPhone) => {
    const isValid = true;
    if (cellPhone?.length === PHONE_MAX_LENGTH) {
      try {
        const url = API_ENDPOINTS.verify_cellphone;
        await axiosClient.post(url, {
          patient_id: patient.patientId,
          cell_phone: cellPhone,
        });
        return isValid;
      } catch (e) {
        return e.response.status === 409 ? !isValid : isValid;
      }
    }
  };

  const onSubmit = async ({ departmentId, neuroService, cellPhone }) => {
    const neuroForm = {
      ...(programId && { programId }),
      ...(departmentId && { departmentId }),
      patientMRN: patient.MRN,
      requestedService: neuroService,
      callbackPhone: cellPhone,
    };

    try {
      const url = `/api/s/console/specialist-on-demand/sessions`;
      const { data } = await axiosClient.post(url, neuroForm);
      addInfoMsg("Session successfully created");
      setSessionId(data?.id);
      setIsSearchComplete(true);
    } catch (e) {
      addError(e.response.data.description);
      setIsSearchComplete(false);
    }
  };

  const validationSchema = Yup.object({
    cellPhone: Yup.string().min(PHONE_MAX_LENGTH, `Phone number must be ${PHONE_MAX_LENGTH} digit`),
  });
  const inintialValues = {
    departmentId: departmentId || "",
    neuroService: "",
    cellPhone: "",
  };

  return (
    <div className={classes.formContainer}>
      <h2>Request A Neurosurgeon Consultation</h2>
      <Formik
        initialValues={inintialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleChange,
          setFieldValue,
          handleBlur,
          setFieldError,
          isSubmitting,
        }) => (
          <Form className={classes.form}>
            <PatientCard patient={patient} />
            <TextField
              label="Department"
              id="Department"
              variant="outlined"
              select
              fullWidth
              name="departmentId"
              value={values.departmentId}
              onChange={handleChange}
              className={classes.textField}
            >
              {departmentList?.map((department) => (
                <MenuItem key={department.id} value={department.id}>
                  {department.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              variant="outlined"
              label="Service"
              id="Service"
              name="neuroService"
              value={values.neuroService}
              onChange={handleChange}
              className={classes.textField}
            >
              {neuroServices?.map((neuroService) => (
                <MenuItem key={neuroService} value={neuroService}>
                  {neuroService}
                </MenuItem>
              ))}
            </TextField>
            <div className={classes.formControl}>
              <CellPhone
                error={Boolean(errors?.cellPhone)}
                helperText={errors?.cellPhone}
                cellPhone={values.cellPhone}
                setCellPhone={(value) => setFieldValue("cellPhone", value)}
                onBlur={async (e) => {
                  handleBlur(e);
                  const isValid = await checkIsCellPhoneVerified(values.cellPhone);
                  if (!isValid) {
                    setFieldError("cellPhone", "Someone else has the same number");
                  }
                }}
                CustomStartIcon={<PhoneAndroidIcon />}
              />
            </div>
            {!isSearchComplete && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={isSubmitting}
                startIcon={
                  isSubmitting ? <CircularProgress color="primary" size={25} /> : <SearchIcon />
                }
              >
                Search
              </Button>
            )}
          </Form>
        )}
      </Formik>
      {isSearchComplete && <CliniciansList sessionId={sessionId} />}
    </div>
  );
};

export default CreateSpeсialistSession;
