import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  cardMain: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "14pt",
    textDecoration: "none",
  },
  backgroundSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
  topGrid: {
    flexGrow: 1,
    margin: "auto",
    width: "80%",
  },
}));
