/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Radio,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { useCreateSpecialistSession } from "./styles";

const CliniciansList = ({ sessionId }) => {
  const { classes } = useCreateSpecialistSession();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { addError } = useApp();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const [selectedValue, setSelectedValue] = useState(0);
  const [clinicians, setClinicians] = useState([]);

  const getClinicians = async (sessionId) => {
    try {
      const url = `/api/s/console/specialist-on-demand/sessions/${sessionId}/available-specialists`;
      const response = await axiosClient.get(url);
      const { data } = response;
      setClinicians(data);
    } catch (e) {
      addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    if (!!sessionId) {
      getClinicians(sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  const Accordion = styled((props) => <MuiAccordion elevation={0} square {...props} />)(
    ({ theme }) => ({
      border: `1px solid ${theme.palette.divider}`,
      "&:before": {
        display: "none",
      },
    }),
  );

  const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
  }));

  const handleChangeRadio = (event) => setSelectedValue(Number(event.target.value));

  const selectSpecialist = async (sessionId, onCallReferenceNumber) => {
    try {
      const url = `/api/s/console/specialist-on-demand/sessions/${sessionId}/selected-specialist`;
      await axiosClient.put(url, { onCallReferenceNumber: onCallReferenceNumber });
    } catch (e) {
      addError("Something went wrong. Please contact support.");
    }
  };

  const StartConsultationButton = () => {
    const handleStartConsultation = () => {
      const selectedSpecialist = clinicians[selectedValue];
      const { primary_specialist } = selectedSpecialist;
      const { onCallReferenceNumber } = primary_specialist;
      selectSpecialist(sessionId, onCallReferenceNumber);
      if (isMobile) {
        navigate({
          pathname: `/video/${sessionId}`,
          search: params.toString(),
        });
      } else {
        window.open(`/video/${sessionId}?${params.toString()}`, "_blank", "noopener, noreferrer");
      }
    };

    return (
      <Button
        variant="contained"
        className={classes.startConsultationButton}
        color="primary"
        onClick={handleStartConsultation}
        disabled={!clinicians?.length}
      >
        START CONSULTATION
      </Button>
    );
  };

  return (
    <div className={classes.accordionContainer}>
      <p
        className={classes.availableDoctorsTitle}
      >{`${clinicians?.length} Available clinicians`}</p>
      {!!clinicians?.length &&
        clinicians.map((clinician, index) => {
          const { primary_specialist, supporting_queue, fallback_queue } = clinician;
          const { firstName, lastName } = primary_specialist;
          const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
          const specialists = concat(supporting_queue, [primary_specialist], fallback_queue);
          const key = `${firstName} ${lastName} ${index}`;

          return (
            <Accordion defaultExpanded key={key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.surgeonDescription}>
                  {firstName && lastName ? <span>{`${firstName} ${lastName}`}</span> : "Unknown"}
                </div>
                <Radio
                  checked={Number(index) === selectedValue}
                  onChange={handleChangeRadio}
                  value={index}
                  name="radio-button"
                />
              </AccordionSummary>
              <AccordionDetails>
                <Stepper nonLinear activeStep={specialists.length - 1} orientation="vertical">
                  {specialists.map((specialist) => (
                    <Step key={specialist.id} active={Boolean(specialist)}>
                      <StepLabel className={classes.stepLabel}>
                        {(specialist.firstName || specialist.lastName) && (
                          <Typography>
                            {[specialist.firstName, specialist.lastName].filter(Boolean).join(" ")}
                          </Typography>
                        )}
                        {specialist?.cellPhone && (
                          <Typography>{`${specialist.cellPhone.replace(
                            /(\d{3})(\d{3})(\d+)/,
                            "+1 ($1) $2-$3",
                          )}`}</Typography>
                        )}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </AccordionDetails>
            </Accordion>
          );
        })}

      <StartConsultationButton />
    </div>
  );
};

export default CliniciansList;
