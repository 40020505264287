import { defaultGridConfig } from "components/GenericEnhancedTable";

export const colDefs = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "dob",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "DOB",
    searchable: false,
    sortable: true,
  },
  {
    id: "cell_phone",
    numeric: false,
    disablePadding: false,
    label: "Cell No",
    searchable: true,
    sortable: true,
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
    searchable: false,
    sortable: true,
  },
  {
    id: "emr_mrn",
    numeric: false,
    disablePadding: false,
    label: "MRN",
    searchable: true,
    sortable: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    searchable: true,
    sortable: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Gender",
    searchable: false,
    sortable: true,
  },
  {
    id: "addr_str_1",
    numeric: false,
    disablePadding: false,
    label: "Address 1",
    searchable: false,
    sortable: true,
  },
  {
    id: "addr_str_2",
    numeric: false,
    disablePadding: false,
    label: "Address 2",
    searchable: false,
    sortable: true,
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    searchable: false,
    sortable: true,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
    searchable: false,
    sortable: true,
  },
  {
    id: "zip",
    numeric: false,
    disablePadding: false,
    label: "Zip",
    searchable: false,
    sortable: true,
  },
  {
    id: "user_type",
    numeric: false,
    disablePadding: false,
    label: "User Type",
    searchable: false,
    sortable: true,
  },
  {
    id: "email_verified_at",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Email Verified At",
    searchable: false,
    sortable: true,
  },
  {
    id: "cell_verified_at",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Cell Verified At",
    searchable: false,
    sortable: true,
  },
  {
    id: "user_status",
    numeric: false,
    disablePadding: false,
    label: "User Status",
    searchable: false,
    sortable: true,
  },
  {
    id: "passport_number",
    numeric: false,
    disablePadding: false,
    label: "Passport No",
    searchable: false,
    sortable: true,
  },
  {
    id: "emr_patient_id",
    numeric: false,
    disablePadding: false,
    label: "EMR Patient ID",
    searchable: false,
    sortable: true,
  },
  {
    id: "emr_fhir_id",
    numeric: false,
    disablePadding: false,
    label: "EMR FHIR ID",
    searchable: false,
    sortable: true,
  },
];

export const gridConfig = {
  ...defaultGridConfig,
  allowDelete: false,
  showTableToolbar: false,
};
