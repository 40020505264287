import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import CONSTANTS from "../constants";
import BannerButton from "components/Mobile/BannerButton/BannerButton";
import useTranslation from "../../../hooks/useTranslation";
import { Location } from "../LocationList/utils";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

const useStyles = makeStyles()(() => ({
  title: {
    margin: "1em",
    fontSize: "1.5em",
    textAlign: "left",
    fontWeight: 500,
  },
  circularProgressContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
  errorTextContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    marginTop: "12px",
    marginLeft: "40px",
    marginRight: "40px",
  },
}));

const ParkingAndAccommodations = () => {
  const { classes } = useStyles();
  const { mobile } = useTranslation();
  const [retrievingHospitals, setRetrievingHospitals] = useState(false);
  const [hospitalsError, setHospitalsError] = useState(false);
  const [hospitals, setHospitals] = useState<Array<Location>>([]);
  const { ChooseHospital } = mobile;

  useEffect(() => {
    const fetchLocations = async () => {
      const url = API_ENDPOINTS.mobile_hospitals;
      setRetrievingHospitals(true);
      try {
        const response = await axiosClient.get(url);
        if (response.status === 200) {
          setHospitals(response.data?.data);
        }
      } catch (e) {
        setHospitalsError(true);
      }
      setRetrievingHospitals(false);
    };
    fetchLocations();
    // eslint-disable-next-line
  }, []);

  if (retrievingHospitals) {
    return (
      <div className={classes.circularProgressContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (hospitalsError) {
    return (
      <div className={classes.errorTextContainer}>
        <Typography>
          An error occured while fetching the hospitals. Please try again later.
        </Typography>
      </div>
    );
  }

  return (
    <PageContainer>
      <Typography className={classes.title}>{ChooseHospital}</Typography>
      {hospitals.map(({ title, img, parkingUrl }) => (
        <BannerButton
          key={title}
          title={title}
          image={img}
          navigateTo={parkingUrl}
          navigateFrom={CONSTANTS.PARKING}
          showRightArrow
          shouldPushNavigation={false}
          border="none"
          testId={`hospital-${title}`}
        />
      ))}
    </PageContainer>
  );
};
export default ParkingAndAccommodations;
