import { Patient, PatientSearchQuery } from "./types";

export function makeQuery(input: Partial<Patient>): Partial<PatientSearchQuery> {
  return Object.fromEntries(
    [
      ["emr_mrn", input?.MRN || undefined],
      ["cell_phone", input?.cellPhone || undefined],
      ["dob", input?.dob || undefined],
      ["first_name", input?.firstName || undefined],
      ["last_name", input?.lastName || undefined],
    ].filter(([_k, v]) => !!v),
  );
}

export const emptyPatientQuery: Partial<Patient> = {
  MRN: "",
  cellPhone: "",
  dob: "",
  firstName: "",
  lastName: "",
};
