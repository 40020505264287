import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

interface SpecialtyListData {
  data: {
    data: string[];
    totalRecords: number;
  };
}
export const useGetSpecialtys = () => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchQuery = (): Promise<SpecialtyListData> => axiosClient.get(API_ENDPOINTS.get_specialty);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.specialtyList],
    queryFn: fetchQuery,
    staleTime: Infinity,
    enabled: false,
    onError: () => app.addError(errorMsg),
  });

  return {
    ...data,
    refetch,
    isLoading,
    isFetching,
  };
};
