import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  urgentCareMargin: {
    margin: "54px 0 0 0",
  },
  contentContainer: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "0.6em",
    marginTop: "-27px",
  },
  contentHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#ececec",
    zIndex: 2,
    padding: "1.5em",
    margin: "0 1em",
    borderRadius: "0.25em",
    boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.14)",
  },
  locationTitle: {
    color: "#171a5b",
    fontSize: "1.25em",
    fontWeight: 500,
  },
  locationSubtitle: {
    color: "#171a5b",
  },
  locationDescription: {
    display: "flex",
    alignSelf: "center",
    fontSize: "0.75em",
    color: "#5d5d5d",
    maxWidth: "45ch",
    textAlign: "left",
    padding: "1em 0 1.5em 0",
  },
}));
