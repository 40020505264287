import React, { useState } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useApp } from "util/AppContext";
import { ReportFilterProps } from "./types";

export const SpecimenReportFilters = ({
  handleDates,
  initialDate,
  finalDate,
}: ReportFilterProps) => {
  const [dateFrom, setDateFrom] = useState<Date>(initialDate);
  const [dateTo, setDateTo] = useState<Date>(finalDate);

  const app = useApp();

  const submitDates = () => {
    if (dateFrom > dateTo) {
      app.addError("Start date cannot be greater than end date");
      return;
    }
    handleDates(format(dateFrom, "MM/dd/yyyy"), format(dateTo, "MM/dd/yyyy"));
  };

  return (
    <Container style={{ maxWidth: "250px" }}>
      <Stack spacing={2} marginBottom={2}>
        <Typography>Select date Range</Typography>
        <DatePicker
          format="MM/dd/yyyy"
          label="Start Date"
          value={dateFrom}
          onChange={(v) => v && setDateFrom(v)}
        />
        <DatePicker
          format="MM/dd/yyyy"
          label="End Date"
          value={dateTo}
          onChange={(v) => v && setDateTo(v)}
          disableFuture
        />
        <Box display="flex" flexDirection="row-reverse">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={submitDates}
            style={{
              width: "6em",
              marginTop: ".3em",
              height: "3em",
            }}
          >
            Search
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};
