import React, { useCallback, useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import EditSurveyConfigurationModal from "./EditSurveyConfigurationModal";
import GenericEnhancedTable, { defaultGridConfig } from "../../components/GenericEnhancedTable";
import { API_ENDPOINTS } from "../../util/Api_Endpoints";
import { SurveyConfiguration, colDefs } from "./utils";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const SurveyConfigurationList = () => {
  const { addError, addInfoMsg } = useApp();
  const [showModal, setShowModal] = useState(false);
  const [recordToBeEdit, setRecordToBeEdit] = useState<SurveyConfiguration | null>(null);
  const [mode, setMode] = useState("add");
  const [sourceDataRefresher, setRefresher] = useState(false);

  const gridConfig = {
    ...defaultGridConfig,
    allowDelete: false,
    showTableToolbar: true,
    showSearchBar: false,
  };

  const openEditModal = useCallback((record) => {
    setMode("edit");
    setShowModal(true);
    setRecordToBeEdit(record);
  }, []);

  const openAddModal = useCallback(() => {
    setMode("add");
    setRecordToBeEdit({
      surveyName: "",
      formName: "",
      intervalMinutes: "",
      actionStartRunning: "",
      actionEndsRunning: "",
      sendAfterMinutes: "",
      departmentName: "",
      enabled: true,
    });
    setShowModal(true);
  }, []);

  const handleDeleteSurveyConfig = useCallback(
    async (record) => {
      // eslint-disable-next-line no-alert
      if (!window.confirm("Are you sure do you want to delete this record ?")) {
        return false;
      }
      try {
        await axiosClient.delete(`${API_ENDPOINTS.survey_config}/${record.id}`);
        addInfoMsg("Record has been deleted successfully.");
        setRefresher((prev) => !prev);
      } catch {
        addError("Problem deleting record, please contact support.");
      }
    },
    [addError, addInfoMsg],
  );

  useEffect(() => {
    setRefresher((prev) => !prev);
  }, [showModal]);

  return (
    <Box>
      <Grid container alignItems="flex-end" justifyContent="center">
        <Box paddingBottom={["40px", "20px"]}>
          <Button
            data-cy="AddSurveyConfigurationModalBtn"
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={openAddModal}
          >
            Add
          </Button>
        </Box>

        {/* @ts-ignore-next-line */}
        <GenericEnhancedTable
          gridConfig={gridConfig}
          tableTitle="Survey Configurations"
          colDefs={colDefs}
          url={API_ENDPOINTS.survey_config}
          onEditHandler={openEditModal}
          refreshSourceData={sourceDataRefresher}
          hideCheckboxes
          onDeleteHandler={handleDeleteSurveyConfig}
        />

        <EditSurveyConfigurationModal
          show={showModal}
          setShow={setShowModal}
          record={recordToBeEdit}
          mode={mode}
        />
      </Grid>
    </Box>
  );
};

export default SurveyConfigurationList;
