/* eslint-disable */
import { useEffect } from "react";
import axios from "axios";
import { SyncClient } from "twilio-sync";
import { useApp } from "../../util/AppContext";
import { API_ENDPOINTS, AXIOS_OPTIONS, getApiHost } from "../../util/api_helper";

const useTwilioSync = () => {
  const { syncClient, setSyncClient, vs_visits_state } = useApp();
  const currentVirtualVisit = vs_visits_state?.currentVirtualVisit;

  const getTwilioAccessToken = async () => {
    try {
      const apiHost = getApiHost();
      const url = `${apiHost}${API_ENDPOINTS.twilio_get_sync_token}`;
      const response = await axios.get(url, AXIOS_OPTIONS);
      const { token } = response?.data;
      const syncClient = new SyncClient(token);
      setSyncClient(syncClient);
    } catch (error) {
      console.error(`Error initializing twilio sync client: ${error.response}`);
    }
  };

  useEffect(() => {
    if (!syncClient) {
      getTwilioAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.id]);

  return { syncClient };
};

export default useTwilioSync;
