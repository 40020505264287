import React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";
import GenericEnhancedTable from "../../components/GenericEnhancedTable";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
}));

export default function OnCallSchedule() {
  const { classes } = useStyles();

  const colDefs = [
    {
      id: "onCallReferenceNumber",
      numeric: false,
      disablePadding: false,
      label: "On Call Reference Number",
      searchable: false,
    },
    {
      id: "departmentName",
      numeric: false,
      disablePadding: false,
      label: "Department Name",
      searchable: false,
    },
    {
      id: "dirReferenceNumber",
      numeric: false,
      disablePadding: false,
      label: "Dir Reference Number",
      searchable: false,
    },
    {
      id: "onCallForDepartment",
      numeric: false,
      disablePadding: false,
      label: "On Call For Department",
      searchable: false,
    },
    { id: "ocLevel", numeric: false, disablePadding: false, label: "Oc Level", searchable: false },
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
      searchable: false,
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      searchable: false,
    },
    {
      id: "startTime",
      numeric: false,
      isDate: true,
      disablePadding: false,
      label: "Start Time",
      searchable: false,
    },
    {
      id: "endTime",
      numeric: false,
      isDate: true,
      disablePadding: false,
      label: "End Time",
      searchable: false,
    },
    {
      id: "onCallDescription",
      numeric: false,
      disablePadding: false,
      label: "Description",
      searchable: false,
    },
    { id: "notes", numeric: false, disablePadding: false, label: "Notes", searchable: false },
    {
      id: "pagerNumber",
      numeric: false,
      disablePadding: false,
      label: "Pager Number",
      searchable: false,
    },
    { id: "provider", numeric: false, disablePadding: false, label: "Provider", searchable: false },
    {
      id: "beeperReferenceNumber",
      numeric: false,
      disablePadding: false,
      label: "Beeper Reference Number",
      searchable: false,
      isCustomContent: true,
      func(val) {
        return Number(val) === 0 ? null : val;
      },
    },
    {
      id: "duration",
      numeric: false,
      disablePadding: false,
      label: "Duration",
      searchable: false,
      isCustomContent: true,
      func(val) {
        return Number(val) === 0 ? null : val;
      },
    },
  ];

  const gridConfig = {
    showActionColumn: false,
    showActionColumnOnStart: false,
    allowDelete: false,
    allowEdit: false,
    showTableToolbar: true,
    showCustomButton: false,
    showSearchBar: true,
  };

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justifyContent="center"
      data-testid="on-call-schedule-table"
    >
      <Grid item xs={12}>
        <GenericEnhancedTable
          gridConfig={gridConfig}
          gridType="Available"
          tableTitle="On Call Schedule"
          colDefs={colDefs}
          url="/api/s/console/config/on-call-employees"
          orderBy="id"
          order="desc"
          hideCheckboxes
        />
      </Grid>
    </Grid>
  );
}
