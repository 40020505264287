import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Box,
  Card,
  CardActionArea,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { AppointmentInfo, LocationsProvider, SlotAvailability } from "components/FindADoctor/types";
import { API_HOST } from "util/constants/config";
import markerIcon from "views/NewAppointment/assets/marker.png";
import { useApp } from "util/AppContext";
import { RNWebView } from "util/RNWebView";
import { getTime } from "util/util_functions";
import useStyles from "./BookingOnline.styles";

interface Props {
  providerId: number | unknown;
  patientRelationship: string;
  purpose: string;
  number: string;
  handleNext: () => void;
  setApponitment: Dispatch<SetStateAction<AppointmentInfo | undefined>>;
}

const DateAndTime: FC<Props> = ({
  providerId,
  patientRelationship,
  purpose,
  number,
  handleNext,
  setApponitment,
}) => {
  const [filteredAvailability, setFilteredAvailability] = useState<Array<SlotAvailability>>([]);
  const [locations, setLocations] = useState<Array<LocationsProvider>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkBox, setCheckBox] = useState<Array<LocationsProvider>>([]);
  const [availability, setAvailability] = useState<Array<SlotAvailability>>([]);
  const app = useApp();
  const { classes } = useStyles();

  const getProvider = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("dfd.kyruus.token");
      const url = `${API_HOST.kyruusApi}v9/atlantic/providers/${providerId}?&slots_filter=patient_relationship:${patientRelationship}&slots_filter=purpose:${purpose}`;
      const params = {
        per_page: 100,
        access_token: accessToken,
      };
      const { data } = await axios.get(url, { params });
      setCheckBox(data.locations);
      setAvailability(data.availability.slots);
      setLocations(data.locations);
      setFilteredAvailability(data.availability.slots);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dates: Array<string> = [...new Set(filteredAvailability?.map((item) => item.date))];

  const checkBoxHandel = (e, locationId) => {
    const { checked } = e.target;
    if (!checked) {
      const listOfLocations = locations?.filter((item) => item.ehr_location_id !== locationId);
      const filterAvailebel = filteredAvailability?.filter(
        (item) => item.ehr_location_id !== locationId,
      );
      setLocations(listOfLocations);
      setFilteredAvailability(filterAvailebel);
    }
    if (checked) {
      const addLocation = checkBox?.filter((item) => item.ehr_location_id === locationId);
      const listOfLocations = [...(locations as Array<LocationsProvider>), addLocation];
      const addAvailable = listOfLocations
        .flat()
        .map((item) =>
          availability?.filter((locations) => locations.ehr_location_id === item?.ehr_location_id),
        );
      const sortAvailable = addAvailable
        .flat()
        .sort((prev, cur) => Number(new Date(prev!.date)) - Number(new Date(cur!.date)));
      setLocations(listOfLocations.flat() as Array<LocationsProvider>);
      setFilteredAvailability(sortAvailable as Array<SlotAvailability>);
    }
  };

  const handelNextStep = (appointment) => {
    const location = locations?.filter(
      (item) => item.ehr_location_id === appointment.ehr_location_id,
    );
    const appointmentInfo: AppointmentInfo = {
      appointment,
      location,
    };
    setApponitment(appointmentInfo);
    handleNext();
  };

  return (
    <Container maxWidth={RNWebView ? "lg" : "md"}>
      {isLoading ? (
        <Box marginTop="24px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!!locations && process.env.REACT_APP_GOOGLE_MAP_API_KEY && (
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "250px" }}
                center={{ lat: locations[0]?.coordinates.lat, lng: locations[0]?.coordinates.lon }}
                zoom={9}
              >
                {locations?.map((location) => (
                  <Marker
                    key={location.coordinates.lat}
                    position={{ lat: location.coordinates.lat, lng: location.coordinates.lon }}
                    icon={markerIcon}
                  />
                ))}
              </GoogleMap>
            </LoadScript>
          )}
          <FormGroup>
            {checkBox?.map((item) => {
              const label = `${item.street1}, ${item.suite}, ${item.city},
               ${item.state}, ${item.zip}`;

              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      disabled={
                        locations?.length === 1 &&
                        item.ehr_location_id === locations[0].ehr_location_id
                      }
                      onChange={(e) => checkBoxHandel(e, item.ehr_location_id)}
                    />
                  }
                  label={label}
                />
              );
            })}
          </FormGroup>

          {filteredAvailability?.length > 1 ? (
            dates.map((date) => (
              <div key={`section-${date}`}>
                <Typography align="left" className={classes.day}>
                  {getTime(date)}
                </Typography>
                {filteredAvailability.map((item) => (
                  <div key={`${item.date}+${item.start}`}>
                    {item.date === date && (
                      <Card className={classes.timeCard} data-testid="time">
                        <CardActionArea
                          className={classes.actionCard}
                          onClick={() => handelNextStep(item)}
                        >
                          <Typography align="left" className={classes.time}>
                            {item.start}
                          </Typography>
                          {locations?.map(
                            (location) =>
                              item.ehr_location_id === location.ehr_location_id && (
                                <>
                                  <Typography align="left" className={classes.hospital}>
                                    {location.name}
                                  </Typography>
                                  <Typography align="left">
                                    {location.street1}, {location.suite}, {location.city},
                                    {location.state}, {location.zip}
                                  </Typography>
                                </>
                              ),
                          )}
                        </CardActionArea>
                      </Card>
                    )}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div className={classes.attentionContainer}>
              <WarningIcon />
              <Typography>
                Online scheduling not available at this location. Call {number}
              </Typography>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default DateAndTime;
