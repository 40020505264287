import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  button: {
    textTransform: "uppercase",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "12px",
  },
  noteDesc: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "Alegreya Sans",
  },
  noteDate: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(130, 130, 130, 1)",
    fontFamily: "Alegreya Sans",
    width: "100%",
  },
  notesContainer: {
    marginBottom: "38px",
    maxHeight: "400px",
    overflowY: "auto",
  },
  noteContainer: {
    marginBottom: "15px",
  },
});

export default useStyles;
