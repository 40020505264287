export const specialtiesShowCallButtonDefaultNo = [
  "Anatomic Pathology",
  "Blood Banking and Transfusion Medicine",
  "Cardiac Imaging",
  "Critical Care Medicine",
  "Cytopathology",
  "Dermatopathology",
  "Emergency Medicine",
  "Hospital Medicine",
  "Medical Toxicology",
  "Neurohospitalist",
  "Neuropathology",
  "Neuroradiology",
  "Nuclear Medicine",
  "Obstetric and Gynecologic Hospitalist",
  "Obstetric Hospitalist",
  "Pathology",
  "Pediatric Anesthesiology",
  "Pediatric Critical Care Medicine",
  "Pediatric Emergency Medicine",
  "Pediatric Hospice and Palliative Care",
  "Pediatric Hospital Medicine",
  "Pediatric Radiology",
  "Surgical Critical Care",
  "Trauma Surgery",
];
