import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import Loader from "components/Loader";
import { DotForms } from "views/DOTForm/staff/constants";
import { StepsProps } from "views/DOTForm/types/constants";
import useTranslation from "hooks/useTranslation";
import { useIsMobile } from "util/deviceUtils";
import { getDeterminationPdf } from "views/DOTForm/utils";
import useStyles from "../../styles/forms.styles";

interface GenerateReportStepProps extends StepsProps {
  formData: any;
}

export const GenerateReportStep: FC<GenerateReportStepProps> = ({ formData }) => {
  const { classes } = useStyles({
    alignLeft: false,
  });

  const {
    web: {
      dotForm: {
        staff: { generateReport },
      },
    },
  } = useTranslation();

  const [isPDFLoading, setIsPDFLoading] = useState(false);

  const { formId } = useParams();

  const { title, determinationPdf, medicateExaminerPdf } = generateReport;

  const isMobile = useIsMobile();

  const determination =
    formData?.medicalDetermination?.federalDetermination ||
    formData?.medicalDetermination?.stateDetermination;
  const certificateAvailable =
    determination === "MEET" || determination === "MEET_WITH_OBSERVATIONS";

  if (isPDFLoading) {
    return (
      <Box
        style={{
          height: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader text="" />
      </Box>
    );
  }

  return (
    <Grid container justifyContent="center" data-testid="generate-reports-step">
      {isMobile && (
        <Typography align="center" className={classes.centeredTitle} data-testid="mobile-title">
          {title}
        </Typography>
      )}
      <Box width="100%" />
      <Grid item xs={12} md={4} container direction="column" style={{ display: "flex" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            getDeterminationPdf(
              Number(formId),
              DotForms.ExaminationReportForm,
              "mcsa-5785",
              setIsPDFLoading,
            )
          }
          data-testid="download-determination-button"
          data-cy="download-determination-button"
          style={{ marginBottom: "10px" }}
        >
          {determinationPdf}
        </Button>
        {certificateAvailable && (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              getDeterminationPdf(
                Number(formId),
                DotForms.ExaminerCertificate,
                "mcsa-5786",
                setIsPDFLoading,
              )
            }
            data-testid="download-medical-examiner-button"
            data-cy="download-medical-examiner-button"
          >
            {medicateExaminerPdf}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default GenerateReportStep;
