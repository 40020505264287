import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CellPhone from "components/shared/CellPhone";
import ZipCode from "components/shared/ZipCode/ZipCode";
import { PHONE_MAX_LENGTH } from "util/constants";
import { usePatientSearchStyles } from "./styles";

const PatientSearchForm = ({
  firstName,
  lastName,
  dob,
  zip,
  cellPhone,
  setZip,
  setDob,
  setFirstName,
  setLastName,
  setCellPhone,
  MRN,
  setMRN,
  isFormDisabled,
  headerExists,
  searchTitle = "Patient Search",
  searchDescription,
}) => {
  const { classes } = usePatientSearchStyles();
  const [cellPhoneError, setCellPhoneError] = useState("");

  useEffect(() => {
    if (cellPhone && cellPhone.length !== PHONE_MAX_LENGTH) {
      setCellPhoneError("Phone number must be 10 digit");
    } else {
      setCellPhoneError("");
    }
  }, [cellPhone]);

  return (
    <>
      <div
        className={headerExists ? `${classes.headerTitle}` : `${classes.title}`}
        data-testid="patientSearchForm"
      >
        {searchTitle}
        {searchDescription && (
          <span className={classes.searchDescription}>{searchDescription}</span>
        )}
      </div>
      <TextField
        InputLabelProps={{ classes: { root: classes.searchTextFieldLabel } }}
        InputProps={{
          classes: { notchedOutline: classes.searchTextFieldOutline },
        }}
        label="MRN"
        id="mrn"
        value={MRN}
        fullWidth
        variant="outlined"
        onChange={(e) => setMRN(e.target.value.trim())}
      />
      <Typography className={classes.dividerFullWidth} color="textSecondary">
        OR
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{
              classes: { root: classes.searchTextFieldLabel },
            }}
            InputProps={{
              classes: { notchedOutline: classes.searchTextFieldOutline },
            }}
            label="First Name"
            id="firstName"
            value={firstName}
            fullWidth
            variant="outlined"
            onChange={(e) => setFirstName(e.target.value)}
            disabled={isFormDisabled}
            classes={{ root: classes.textFieldRoot }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{
              classes: { root: classes.searchTextFieldLabel },
            }}
            InputProps={{
              classes: { notchedOutline: classes.searchTextFieldOutline },
            }}
            required
            label="Last Name"
            id="lastName"
            fullWidth
            value={lastName}
            variant="outlined"
            onChange={(e) => setLastName(e.target.value)}
            disabled={isFormDisabled}
            classes={{ root: classes.textFieldRoot }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <DatePicker
            data-testid="calendar-dob-patient-search"
            format="MM/dd/yyyy"
            label="Date of Birth"
            value={dob}
            onChange={setDob}
            disableFuture
            slotProps={{
              textField: {
                helperText: "MM/DD/YYYY",
                fullWidth: true,
                inputVariant: "outlined",
                error: false,
              },
            }}
            rootClass={classes.calendarRoot}
            labelClass={classes.calendarLabel}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <ZipCode
            setZip={setZip}
            zip={zip}
            InputLabelProps={{
              classes: { root: classes.searchTextFieldLabel },
            }}
            InputProps={{
              classes: { notchedOutline: classes.searchTextFieldOutline },
            }}
            classes={{ root: classes.textFieldRoot }}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <CellPhone
            label="Cell Phone"
            id="cellPhone"
            InputProps={{
              classes: { notchedOutline: classes.searchTextFieldOutline },
            }}
            InputLabelProps={{
              classes: { root: classes.searchTextFieldLabel },
            }}
            classes={{ root: classes.textFieldRoot }}
            setCellPhone={setCellPhone}
            cellPhone={cellPhone}
            error={Boolean(cellPhoneError)}
            helperText="Phone number must be 10 digit"
            isShrink
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientSearchForm;
