import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { QuerySteps } from "../Steps";
import VisitReasonForm from "./form";
import { makeStyles } from "tss-react/mui";
import OffHoursCheck from "views/OffHoursCheck";
import { Program } from "components/Video/utils/types";
import { axiosClient } from "util/api_helper";

const useStyles = makeStyles()(() => ({
  mainTitle: {
    fontWeight: 500,
    fontSize: "28px",
    textAlign: "center",
    letterSpacing: "0.25px",
    marginBottom: "20px",
  },
}));

interface VisitReasonProps {
  setStep: (Step: QuerySteps) => void;
  vsId: number;
}

export default function VisitReason({ setStep, vsId }: VisitReasonProps) {
  const { classes } = useStyles();
  const location = useLocation();

  const [showOffHoursMsg, setShowOffHoursMsg] = useState(false);
  const [program, setProgram] = useState<Program>();
  const isForceInvite = !!location.pathname.includes("videoinvite");

  const getProgram = async () => {
    const url = `/api/s/config/program_basics?program_name=${encodeURIComponent(
      "Virtual Urgent Care",
    )}`;
    try {
      const { data } = await axiosClient.get(url);
      const p = data?.data || [];
      setProgram(p);
    } catch (error) {
      console.error(`Program load error: ${error}`);
    }
  };

  useEffect(() => {
    // Check current hours of operation
    const date = new Date();
    const currentHour = date.getHours();
    const currentDay = date.getDay();
    const openingWeekDays = [1, 2, 3, 4, 5];
    const openingWeekendDays = [6, 0];
    const startingHour = 8;
    const endingHour = openingWeekDays.includes(currentDay) ? 19 : 15; // give a buffer 1 hour for any new visits to ensure wrapup by close
    if (
      (!openingWeekDays.includes(currentDay) && !openingWeekendDays.includes(currentDay)) ||
      currentHour < startingHour ||
      currentHour > endingHour
    ) {
      setShowOffHoursMsg(true);
    }
    getProgram();
    // eslint-disable-next-line
  }, []);

  if (!isForceInvite && showOffHoursMsg) {
    return <OffHoursCheck />;
  }

  if (
    !isForceInvite &&
    !showOffHoursMsg &&
    program &&
    program.program_override_status === "At Max Capacity"
  ) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <p className={classes.mainTitle} style={{ textAlign: "left", padding: "10px;" }}>
            The online urgent care clinic is currently at maximum capacity and not accepting any new
            visits.
            <br />
            <br />
            Please check back later.
            <br />
            <br />
          </p>
        </Grid>
      </Grid>
    );
  }

  return (
    <VisitReasonForm
      vsId={vsId}
      program={program}
      showOffHoursMsg={showOffHoursMsg}
      setStep={setStep}
    />
  );
}
