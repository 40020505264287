import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  fullWidth: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  saveButton: {
    marginTop: "16px",
  },
  timeWindowTitle: {
    fontSize: "20px",
    color: "#043070",
    marginTop: "8px",
    marginBottom: "8px",
  },
  timeWindowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "65%",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  timeWindowDropdown: {
    flex: 1,
  },
  circularProgress: {
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "0px",
    bottom: "0px",
    margin: "auto",
  },
}));
