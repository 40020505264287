import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useApp } from "util/AppContext";
import { useIsMobile } from "util/deviceUtils";
import { useStyles } from "../../styles/verificationViews/verificationViews.styles";

export const DOTSmsPhoneVerification = () => {
  const { classes } = useStyles();

  const [lastFour, setLastFour] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const app = useApp();
  const params = new URLSearchParams(location.search);
  const hash = params.get("s");
  console.log(hash);
  const isMobile = useIsMobile();

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    try {
      navigate(`/dot-form/license-verification`);
    } catch (error) {
      console.log(error);
      app.addError("Oops, your last 4 digits of your phone are not correct, please verify them");
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      wrap="nowrap"
      className={classes.root}
    >
      <Paper elevation={!isMobile ? 24 : 0} className={classes.paper}>
        <Typography
          variant="h2"
          className={classes.title}
          data-testid="dot-sms-phone-verification-title"
        >
          Comercial Driver's Medical Certification
        </Typography>
        <Typography variant="h3" className={classes.subtitle}>
          Please enter the last four digits of your cell phone number
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleLoginSubmit}>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            className={classes.dobContainer}
          >
            <div data-testid="dot-sms-phone-verification-input-container" data-cy="smsCodeInput">
              <ReactCodeInput
                fields={4}
                className={classes.codeInput}
                autoFocus
                fieldHeight={!isMobile ? 70 : 60}
                fieldWidth={!isMobile ? 70 : 50}
                values={lastFour.split("") || ""}
                onChange={setLastFour}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={!lastFour || lastFour.length < 4}
              data-testid="dot-sms-phone-verification-verify"
              data-cy="dot-sms-phone-verification-verify"
            >
              Verify
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default DOTSmsPhoneVerification;
