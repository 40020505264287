import React, { Dispatch, SetStateAction } from "react";
import { Button, Card, Chip, Typography } from "@mui/material";
import useStyles from "./DoctorInfo.styles";
import telehealthIconSource from "static/images/ahsIcons/telehealth.png";

interface Props {
  picture: string;
  fullName: string;
  specialty: string;
  clinic: string;
  setIsShown: Dispatch<SetStateAction<boolean>>;
}

const DoctorInfo: React.FC<Props> = ({ picture, fullName, specialty, clinic, setIsShown }) => {
  const { classes } = useStyles();

  return (
    <Card className={classes.appCard} elevation={8}>
      <img src={picture} height="30%" width="30%" className={classes.img} alt={fullName} />
      <Typography className={classes.title} align="center">
        {fullName}
      </Typography>
      <Typography className={classes.desc} align="center">
        {specialty}
      </Typography>
      <Typography className={classes.desc} align="center">
        {clinic}
      </Typography>
      <div className={classes.buttonContainer}>
        <Chip
          icon={<img src={telehealthIconSource} alt="Telehealth visit" />}
          label="Telehealth visit"
          clickable
          className={classes.telehealth}
        />
        <Button className={classes.buttonRequest} onClick={() => setIsShown(true)}>
          Request Appointment
        </Button>
      </div>
    </Card>
  );
};

export default DoctorInfo;
