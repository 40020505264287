import * as React from "react";
import { Box, Card, Typography } from "@mui/material";
import useStyles from "./DoctorInfo.styles";

interface Props {
  acceptedInsurance: Array<string>;
}

const Insurance: React.FC<Props> = ({ acceptedInsurance }) => {
  const { classes } = useStyles();
  return (
    <Card className={classes.appCard} elevation={8}>
      <Typography className={classes.title} align="left">
        In-network insurances
      </Typography>
      <Box mt={2}>
        {acceptedInsurance.map((it) => (
          <Typography className={classes.text} align="left" key={it}>
            {it}
          </Typography>
        ))}
      </Box>
    </Card>
  );
};

export default Insurance;
