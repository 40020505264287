import React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SpecimenSelectOptionsProps } from "./types";

const useStyles = makeStyles()(() => ({
  selectsContainer: {
    width: "20em",
  },
}));

const IconComponent = () => {
  return (
    <Box bgcolor="gray" marginRight={1} borderRadius="4px">
      <ArrowDropDown style={{ color: "white" }} />
    </Box>
  );
};

export const SpecimenSelectOptions = ({
  id,
  labelId,
  placeholder,
  value,
  data,
  showCheckbox,
  checkboxData,
  checkboxValue,
  handleCheckboxChange,
  onChange,
}: SpecimenSelectOptionsProps) => {
  const { classes } = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "20em",
        marginTop: "4em",
      },
    },
  };

  const mapIdsToNames = (selectedIds) => {
    return selectedIds.map((id) => {
      const selectedItem = data?.find((item) => item.id === id);
      return selectedItem ? selectedItem.name : "";
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <FormControl
        variant="standard"
        data-testid="selectOptions-specimenTracking"
        className={classes.selectsContainer}
      >
        <Box minWidth={250}>
          <InputLabel id={labelId} style={{ paddingLeft: 10 }}>
            {placeholder}
          </InputLabel>
          <Select
            variant="standard"
            placeholder={placeholder}
            fullWidth
            labelId={labelId}
            id={id}
            multiple
            value={value}
            onChange={onChange}
            input={<OutlinedInput label={placeholder} />}
            renderValue={(selected) => mapIdsToNames(selected).join(", ")}
            MenuProps={MenuProps}
            IconComponent={IconComponent}
          >
            {data?.map(({ id, name }) => {
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={(value as number[]).indexOf(id) > -1}
                    color="primary"
                    data-testid={name}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        {showCheckbox && (
          <Box style={{ width: "47em" }}>
            {checkboxData?.map((name, i) => (
              <FormControlLabel
                style={{ marginBottom: 0 }}
                key={name}
                control={
                  <Checkbox
                    checked={i === checkboxValue}
                    onChange={() => handleCheckboxChange(i)}
                    name={name}
                    color="primary"
                    data-testid="locationCheckbox"
                  />
                }
                label={name}
              />
            ))}
          </Box>
        )}
      </FormControl>
    </Box>
  );
};
