/** @format */

/* eslint-disable */
import React from "react";
import { Timeline, TimelineContent, TimelineItem, TimelineOppositeContent } from "@mui/lab";
import QrCode from "../VaccinationDetails";
import TimeLineHeader from "./TimeLineHeader";
import TimeLineItem from "./TimeLineItem";
import { useTimeLineStyles } from "./styles";

export default function CustomizedTimeline({ vaccines, showQR, altQRCodeURL }) {
  const classes = useTimeLineStyles();
  return (
    <Timeline className={classes.root}>
      <TimelineItem>
        <TimelineOppositeContent className={classes.opposite} />
        <TimelineContent>
          <TimeLineHeader
            productName={
              vaccines[0].manufacturer?.name
                ? vaccines[0].manufacturer?.name
                : vaccines[0].manufacturer?.display
            }
            providerName={vaccines[0]?.location.name}
          />
        </TimelineContent>
      </TimelineItem>
      {vaccines?.map((v, i) => (
        <TimeLineItem vaccine={v} i={i} key={i} length={vaccines.length} />
      ))}
      {showQR && <QrCode altQRCodeURL={altQRCodeURL} />}
    </Timeline>
  );
}
