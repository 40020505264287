import React, { useState } from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { waitingUsersTableStyles } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/styles";
import { ORDER, OrderBy } from "util/constants";

export type SortByOptions = {
  requestedStartBeg: { text: string; orderBy: OrderBy };
  startDttm: { text: string; orderBy: OrderBy };
};

export const SORT_BY_OPTIONS: SortByOptions = {
  requestedStartBeg: {
    text: "Call request",
    orderBy: OrderBy.requestedStartBeg,
  },
  startDttm: { text: "Request Submitted", orderBy: OrderBy.startDttm },
};

type SortByProps = {
  order: ORDER;
  setOrder: (order: ORDER) => void;
  orderBy: OrderBy;
  setOrderBy: (orderBy: OrderBy) => void;
  shouldDisplaySortInsideDropdown?: boolean;
};

const SortBy: React.FC<SortByProps> = ({
  order,
  setOrder,
  orderBy,
  setOrderBy,
  shouldDisplaySortInsideDropdown = true,
}) => {
  const { classes } = waitingUsersTableStyles();
  const [open, setOpen] = useState(false);

  const handleClick = (value: OrderBy) => {
    setOpen(true);

    setOrderBy(value);
    if (orderBy === value) {
      setOrder(order === ORDER.asc ? ORDER.desc : ORDER.asc);
    } else if (orderBy !== value) {
      setOrder(ORDER.asc);
    }
  };

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel className={classes.label}>SortBy</InputLabel>
      <Select
        variant="standard"
        className={(classes.select, "select-form-filter")}
        classes={{ select: classes.selectInput }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={orderBy}
        data-cy="sortBy"
      >
        {[SORT_BY_OPTIONS.requestedStartBeg, SORT_BY_OPTIONS.startDttm].map((option) => (
          <MenuItem
            onClick={() => handleClick(option.orderBy)}
            data-cy={option.orderBy}
            className={classes.selectInput}
            value={option.orderBy}
            key={option.orderBy}
          >
            <Typography variant="body1">{option.text}</Typography>
            {shouldDisplaySortInsideDropdown && (
              <>
                {option.orderBy === orderBy && order === ORDER.asc && (
                  <ArrowUpward fontSize="small" className={classes.arrow} />
                )}
                {option.orderBy === orderBy && order === ORDER.desc && (
                  <ArrowDownward fontSize="small" className={classes.arrow} />
                )}
              </>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortBy;
