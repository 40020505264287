import axios from "axios";
import { API_HOST } from "util/constants/config";
import { axiosClient } from "../../util/api_helper";

export const getKyruusToken = async () => {
  try {
    const kyruusToken = await axiosClient("/api/s/scheduling/providersearch/gettoken");
    localStorage.setItem("dfd.kyruus.token", kyruusToken.data.data);
  } catch (e) {
    console.log(e);
  }
};

export const loadTypeahead = async (inputTypeahead, setTypeahead, app) => {
  try {
    const accessToken = localStorage.getItem("dfd.kyruus.token");
    const url = `${API_HOST.kyruusApi}v9/atlantic/typeahead?access_token=${accessToken}&terms=${inputTypeahead}`;
    const { data } = await axios(url);
    const arrOfResult = [];
    if (data.conditions_symptoms) {
      const conditions = data.conditions_symptoms.map((condition) => ({
        title: "Conditions",
        ...condition,
      }));
      arrOfResult.push(conditions);
    }
    if (data.provider_name) {
      const providers = data.provider_name.map((provider) => ({
        title: "Provider",
        ...provider,
      }));
      arrOfResult.push(providers);
    }
    if (data.specialties) {
      const specialties = data.specialties.map((specialties) => ({
        title: "Specialties",
        ...specialties,
      }));
      arrOfResult.push(specialties);
    }
    if (data.treatments_procedures) {
      const treatments = data.treatments_procedures.map((treatment) => ({
        title: "Treatment",
        ...treatment,
      }));
      arrOfResult.push(treatments);
    }
    setTypeahead(arrOfResult.flat());
  } catch (e) {
    app.addError("Something went wrong. Please contact support.");
    console.log(e);
  }
};

export const loadProviders = async (
  setIsLoading,
  page,
  isUrgentCare,
  search,
  filterData,
  sort,
  setTotalPage,
  setProviders,
  firstUpdate,
  app,
) => {
  setIsLoading(true);
  try {
    const accessToken = localStorage.getItem("dfd.kyruus.token");
    const searching = isUrgentCare ? "urgent care" : search;
    const city = filterData?.city ? `&filter=locations.city:${filterData?.city}` : "";
    const state = filterData?.state ? `&filter=locations.state:${filterData?.state}` : "";
    const zip = filterData?.zip ? `&filter=locations.zip:${filterData?.zip}` : "";
    const gender = filterData?.gender ? `&filter=provider.gender:${filterData?.gender}` : "";
    const booking = filterData?.isOnlineAppointment
      ? `&filter=provider.direct_book_capable:${filterData?.isOnlineAppointment}`
      : "";
    const insurances =
      filterData.insurance.length > 0
        ? `&filter=provider.insurance_accepted:${filterData.insurance.join(
            "&filter=provider.insurance_accepted:",
          )}`
        : "";
    const pageToken = "7726f6839-6e92-4d7f-a534-abd13d8a654b";
    const params = {
      unified: searching,
      ...(!!sort && { sort: `relevance,networks,availability_ density_best&` }),
      per_page: 10,
      page,
      shuffle_seed: pageToken,
      access_token: accessToken,
    };
    const api = `${API_HOST.kyruusApi}v9/atlantic/providers`;
    const url = `${api}?${city}${state}${zip}${gender}${booking}${insurances}`;
    const { data } = await axios(url, { params });
    const numberOfPages = Math.ceil(
      // eslint-disable-next-line
      data._metadata.total_providers / 10,
    );
    if (isUrgentCare) {
      setTotalPage(numberOfPages);
      // eslint-disable-next-line
      let urgentHospitals = data._result.filter((item) => item.locations[0]?.name !== null);
      urgentHospitals = urgentHospitals.filter((item) => item.provider.contacts[0].value !== null);
      setProviders(urgentHospitals);
    } else {
      setTotalPage(numberOfPages);
      // eslint-disable-next-line
      setProviders(data._result);
    }
    // eslint-disable-next-line
    firstUpdate.current = false;
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      getKyruusToken();
      loadProviders();
    } else {
      app.addError("Something went wrong. Please contact support.");
      console.log(e);
    }
  } finally {
    setIsLoading(false);
  }
};

export const loadProvidersRN = async (
  providers,
  pageMobile,
  isUrgentCare,
  search,
  filterData,
  sort,
  setProviders,
  firstUpdate,
  setPageMobile,
  app,
) => {
  try {
    const accessToken = localStorage.getItem("dfd.kyruus.token");
    const searching = isUrgentCare ? "urgent care" : search;
    const city = filterData?.city ? `&filter=locations.city:${filterData?.city}` : "";
    const state = filterData?.state ? `&filter=locations.state:${filterData?.state}` : "";
    const zip = filterData?.zip ? `&filter=locations.zip:${filterData?.zip}` : "";
    const gender = filterData?.gender ? `&filter=provider.gender:${filterData?.gender}` : "";
    const booking = filterData?.isOnlineAppointment
      ? `&filter=provider.direct_book_capable:${filterData?.isOnlineAppointment}`
      : "";
    const insurances =
      filterData.insurance.length > 0
        ? `&filter=provider.insurance_accepted:${filterData.insurance.join(
            "&filter=provider.insurance_accepted:",
          )}`
        : "";
    const pageToken = "7726f6839-6e92-4d7f-a534-abd13d8a654b";
    const params = {
      unified: searching,
      ...(!!sort && { sort: `relevance,networks,availability_ density_best&` }),
      per_page: 10,
      page: pageMobile,
      shuffle_seed: pageToken,
    };
    const api = `${API_HOST.kyruusApi}v9/atlantic/providers`;
    const url = `${api}?access_token=${accessToken}${city}${state}${zip}${gender}${booking}${insurances}`;
    const { data } = await axios(url, { params });

    setPageMobile((state) => state + 1);
    // eslint-disable-next-line
    setProviders([...providers, ...data._result]);
    // eslint-disable-next-line no-param-reassign
    firstUpdate.current = false;
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      getKyruusToken();
      loadProviders();
    } else {
      app.addError("Something went wrong. Please contact support.");
      console.log(e);
    }
  }
};
