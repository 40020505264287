import React from "react";
import Button from "@mui/material/Button";
import useCaptionsContext from "components/Video/hooks/useCaptionsContext/useCaptionsContext";
import ClosedCaptionOffIcon from "components/Video/icons/ClosedCaptionOffIcon";
import ClosedCaptionOnIcon from "components/Video/icons/ClosedCaptionOnIcon";

const ToggleCaptionButton = ({ textMobile }: { textMobile?: string }) => {
  const { setShowCaptions, showCaptions, isCaptionDisabled } = useCaptionsContext();
  return (
    <Button
      onClick={() => setShowCaptions(!showCaptions)}
      startIcon={showCaptions ? <ClosedCaptionOnIcon /> : <ClosedCaptionOffIcon />}
      disabled={isCaptionDisabled}
      data-testid="ToggleCaptionButton"
    >
      <span className={textMobile}>Subtitles</span>
    </Button>
  );
};

export default ToggleCaptionButton;
