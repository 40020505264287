import React, { useState } from "react";
import { Button, FormControl, MenuItem, TextField } from "@mui/material";
import useStyles from "./TeamMembers.styles";

const TeamMembersForm = ({ createTeamMember }) => {
  const { classes } = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [error, setError] = useState({
    errorStatus: false,
    errorText: "",
  });

  const roles = [
    { role: "Tier 1", id: 1 },
    { role: "Backup", id: 2 },
  ];

  const formatPhoneField = (value) => Math.max(0, parseInt(value, 10)).toString().slice(0, 10);
  const submitForm = (event) => {
    event.preventDefault();
    const formData = {
      ...(!!firstName && { firstName }),
      ...(!!lastName && { lastName }),
      role,
      cellPhone,
    };

    if (formData.cellPhone.length < 10) {
      setError({
        errorStatus: true,
        errorText: "Phone number is required and must be 10 digit",
      });
    } else {
      createTeamMember(formData);
    }
  };

  return (
    <FormControl
      variant="standard"
      className={classes.formControl}
      component="form"
      onSubmit={submitForm}
    >
      <TextField
        label="First Name"
        type="text"
        onChange={(e) => setFirstName(e.target.value)}
        value={firstName}
        variant="outlined"
        id="firstName"
      />
      <TextField
        label="Last Name"
        type="text"
        onChange={(e) => setLastName(e.target.value)}
        value={lastName}
        variant="outlined"
        id="lastName"
      />
      <TextField
        label="Role"
        type="text"
        onChange={(e) => setRole(e.target.value)}
        value={role}
        variant="outlined"
        id="role"
        select
        required
      >
        {roles.map((item) => (
          <MenuItem value={item.role} key={item.id}>
            {item.role}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Phone Number"
        type="number"
        error={error.errorStatus}
        helperText={error.errorText}
        inputProps={{ maxLength: 10 }}
        placeholder="###-###-####"
        onChange={(e) => setCellPhone(formatPhoneField(e.target.value))}
        value={cellPhone}
        variant="outlined"
        id="phoneNumber"
        required
      />
      <Button type="submit" variant="contained" color="primary">
        LINK TEAM MEMBER
      </Button>
    </FormControl>
  );
};

export default TeamMembersForm;
