import { useEffect } from "react";
import { useApp } from "../../../../util/AppContext";

const useJoinedParticipantNotify = () => {
  const app = useApp();
  const joinedParticipant = app.vs_visits_state?.joinedParticipant;

  useEffect(() => {
    if (joinedParticipant && joinedParticipant?.identity) {
      app.addInfoMsg(`${joinedParticipant?.identity} joined the room`);
    }
    // eslint-disable-next-line
  }, [joinedParticipant]);

  return null;
};

export default useJoinedParticipantNotify;
