import { useEffect, useState } from "react";
import { getDeviceInfo } from "../../../../util/deviceUtils";

// This returns the type of the value that is returned by a promise resolution
type ThenArg<T> = T extends PromiseLike<infer U> ? U : never;

export default function useDevices() {
  const [deviceInfo, setDeviceInfo] = useState<ThenArg<ReturnType<typeof getDeviceInfo>>>({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
  });

  useEffect(() => {
    const getDevices = () => getDeviceInfo().then((devices) => setDeviceInfo(devices));
    if (
      navigator?.mediaDevices &&
      typeof navigator?.mediaDevices?.addEventListener === "function"
    ) {
      navigator?.mediaDevices?.addEventListener("devicechange", getDevices);
    }
    getDevices();

    // ===========================================================
    // THE BELOW CODE IS WRITTEN BY BEN
    // I AM COMMENTING IT BECAUSE IT GENERATING AN ERROR,
    // IT ALWAYS ASKS FOR BOTH PERMISSIONS "CAMERA" + "MICROPHONE" ON AUDIO STEPS, WHERE WE ONLY NEED MICROPHONE.
    // IT DOES THE SAME ON VIDEO STEPS
    // ===========================================================
    // navigator.getUserMedia({ audio: true }, function (e) {
    //   // http://stackoverflow.com/q/15993581/1008999
    //   //
    //   // In chrome, If your app is running from SSL (https://),
    //   // this permission will be persistent.
    //   // That is, users won't have to grant/deny access every time.
    //   localStorage.setItem("voice_access", "granted");
    //   getDevices();

    // }, function (err) {
    //   if (err.name === 'PermissionDismissedError') {
    //     localStorage.setItem('voice_access', 'prompt')
    //   }
    //   if (err.name === 'PermissionDeniedError') {
    //     localStorage.setItem('voice_access', 'denied')
    //   }
    // })
    // navigator.getUserMedia({ video: true }, function (e) {
    //   // http://stackoverflow.com/q/15993581/1008999
    //   //
    //   // In chrome, If your app is running from SSL (https://),
    //   // this permission will be persistent.
    //   // That is, users won't have to grant/deny access every time.
    //   localStorage.setItem("video_access", "granted");
    //   getDevices();

    // }, function (err) {
    //   if (err.name === 'PermissionDismissedError') {
    //     localStorage.setItem('video_access', 'prompt')
    //   }
    //   if (err.name === 'PermissionDeniedError') {
    //     localStorage.setItem('video_access', 'denied')
    //   }
    // })
    return () => {
      navigator?.mediaDevices?.removeEventListener("devicechange", getDevices);
    };
  }, []);

  return deviceInfo;
}
