import { makeStyles } from "tss-react/mui";

export const makeNewReferalStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.common.black,
  },
  media: {
    height: 140,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  backgroundSecondary: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: "22pt",
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  formLabel: {
    color: theme.palette.common.black,
  },
  textField: {
    width: "500px",
  },
  formControl: {
    minWidth: "100%",
  },
  modalDialog: {
    width: "840px",
  },
  modalDialogTitle: {
    padding: "16px 0 0",
  },
  modalTextField: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  formContainer: {
    padding: theme.spacing(4),
    paddingBottom: "6em",
    height: "551px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
  },
  modalTableCell: {
    boxSizing: "border-box",
    padding: "12px",
    textAlign: "center",
    borderBottom: "unset",
    width: "210px",

    "&:nth-child(4n)": {
      width: "120px",
    },
  },
  modalTableRow: {
    textAlign: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "&:last-child": {
      borderBottom: "unset",
    },
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  },
  tableContainer: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      overflow: "scroll",
    },
  },
  tableCellBody: {
    verticalAlign: "top",
  },
  handleLock: {
    marginBottom: "15px",
  },
  selectPrimaryText: {
    fontSize: "20px",
  },
  selectPrimaryContainer: {
    marginTop: "8px",
    gap: "8px",
  },
  helperText: {
    marginTop: "8px",
  },
  saveButtonBox: {
    padding: "16px 32px",
    background: "white",
    borderTop: "1px solid lightgrey",
    position: "absolute",
    bottom: "0",
    left: "0",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    zIndex: 10,
  },
  saveButton: {
    marginTop: "16px",
  },
  table: {
    tableLayout: "fixed",
  },
  gridAutocomplete: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridUrgency: {
    display: "grid",
    justifyContent: "center",
  },
}));
