import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardMedia, TextField } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useApp } from "../util/AppContext";
import { useAuth } from "../util/Security";
import RoutesHelper from "util/routesHelper";
import DriverLicenseImg from "../static/images/driverLicense.png";
import MyChartImg from "../static/images/mychart.png";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  cardsContainer: {
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "auto auto",
    gap: "44px",
    marginTop: "60px",
    "@media(max-width: 780px)": {
      gridTemplateColumns: "auto",
      gap: "24px",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 15px",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  chatCard: {
    maxWidth: "330px",
    marginBottom: "20px",
  },
  header: {
    color: theme.palette.secondary.main,
    fontFamily: "Alegreya Sans",
    fontSize: "36px",
    lineHeight: "42px",
    fontWeight: 500,
    margin: "30px 0px 20px 0px",
  },
  subHeader: {
    fontFamily: "Alegreya Sans",
    fontSize: "24px",
    lineHeight: "36px",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      margin: "auto",
    },
  },
  cardMain: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    height: 150,
    width: "330px",
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "14px",
  },
  textField: {
    marginBottom: "15px",
  },
  button: {
    textTransform: "uppercase",
  },
  cardMsg: {
    fontFamily: "Alegreya Sans",
    fontSize: "22px",
    lineHeight: "36px",
    margin: "30px 0px 40px 0px",
  },
}));

export default function IDVerifyStart() {
  const [mychartUsername, setMychartUsername] = useState("");
  const [mychartPwd, setMychartPwd] = useState("");
  const auth = useAuth();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const app = useApp();
  const { redirectHelper } = RoutesHelper();
  const params = new URLSearchParams(location.search);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    await auth.myChartLogin(mychartUsername, mychartPwd, (user, error) => {
      if (!user || error) {
        app.addError("Error: Signin attempt with mychart credentials failed, please try again");
        return;
      }
      redirectHelper(params);
    });
  };

  const handleIdVerify = (event) => {
    event.preventDefault();

    navigate({ pathname: "/id/verify", search: params.toString() });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>Identity Verification</div>
      <div className={classes.subHeader}>
        Please choose an option below to verify and link your account.
      </div>
      <div className={classes.cardsContainer}>
        <Card className={classes.chatCard} elevation={24}>
          <CardMedia
            className={classes.cardMain}
            onClick={handleIdVerify}
            image={MyChartImg}
            title="MyChart"
          />
          <div className={classes.content}>
            <TextField
              id="mychartUsername"
              label="Username"
              variant="outlined"
              className={classes.textField}
              value={mychartUsername}
              onInput={(e) => setMychartUsername(e.target.value)}
            />
            <TextField
              id="mychartPwd"
              label="Password"
              type="password"
              className={classes.textField}
              value={mychartPwd}
              onInput={(e) => setMychartPwd(e.target.value)}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleLoginSubmit}
              className={classes.button}
            >
              Log-In
            </Button>
          </div>
        </Card>
        <Card className={classes.chatCard} elevation={24}>
          <CardMedia className={classes.cardMain} image={DriverLicenseImg} title="Driver License" />
          <div className={classes.content}>
            <div className={classes.cardMsg}>Upload your driver's license and take a selfie</div>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleIdVerify}
              className={classes.button}
            >
              Start
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
