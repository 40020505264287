import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { useStylesAddModal } from "./styles";

interface Props {
  searchSpecimen: () => void;
  patientInfo?: {
    mrn: string;
    patientLastName: string | null;
  };
  error: string;
  children: React.ReactNode;
  disableSave: boolean;
  isLoading: boolean;
  onCloseModal?: () => void;
}

export const AddManuallyModal = ({
  searchSpecimen,
  patientInfo,
  error,
  children,
  disableSave,
  isLoading,
  onCloseModal = () => {},
}: Props) => {
  const { classes } = useStylesAddModal();
  const specimenIdRef = useRef<HTMLInputElement>(null);
  const [openModalAddManually, setOpenModalAddManually] = useState(false);

  const {
    web: {
      common: { close },
      specimenTracking: { mrnId },
      specimenTrackingAdd: { btnAddManually, patientLastName, btnAddManuallyAdSpecimen },
    },
  } = useTranslation();

  return (
    <>
      <Button
        className={classes.captureButton}
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenModalAddManually(true);
          if (specimenIdRef.current) {
            specimenIdRef.current.focus();
          }
        }}
        data-cy="btn-specimen-add-manually"
        data-testid="btn-specimen-add-manually"
      >
        {btnAddManually}
      </Button>
      <Modal open={openModalAddManually} data-testid="add-manually-modal">
        <Box className={classes.modalContainer}>
          <IconButton
            style={{ position: "absolute", right: 0, top: 0 }}
            onClick={() => {
              onCloseModal();
              setOpenModalAddManually(false);
            }}
            data-testid="closeModalIcon"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Box>
            {children}

            {error !== "" && <Typography style={{ color: "red" }}>{error}</Typography>}
            <Box className={classes.responseContainer}>
              <Typography className={classes.labelText}> {`${mrnId}:`}</Typography>
              <Typography className={classes.valueText}> {patientInfo?.mrn}</Typography>
            </Box>
            <Box className={classes.responseContainer}>
              <Typography className={classes.labelText}>{`${patientLastName}:`}</Typography>
              <Typography className={classes.valueText}>{patientInfo?.patientLastName}</Typography>
            </Box>
          </Box>
          <Box className={classes.saveContainer}>
            <Button
              className={classes.saveButton}
              variant="contained"
              color="primary"
              data-cy="btn-specimen-add-other-manually"
              data-testid="btn-specimen-add-other-manually"
              onClick={searchSpecimen}
              disabled={disableSave}
            >
              {isLoading ? (
                <CircularProgress style={{ width: "29px", height: "29px", padding: "4px" }} />
              ) : (
                btnAddManuallyAdSpecimen
              )}
            </Button>
            <Button
              className={classes.saveButton}
              variant="contained"
              color="primary"
              data-cy="btn-specimen-add-manually-close"
              data-testid="btn-specimen-add-manually-close"
              onClick={() => {
                onCloseModal();
                setOpenModalAddManually(false);
              }}
            >
              {close}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
