import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    maxWidth: "640px",
  },
  dialogContent: {
    padding: "0 32px",
    display: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    padding: "32px",
    paddingBottom: "16px",
  },
  title: {
    margin: 0,
  },
  subtitle: {
    marginTop: 0,
    marginBottom: "24px",
    textAlign: "left",

    [theme.breakpoints.down("sm")]: {
      marginBottom: "8px",
    },
  },
  selectContainer: {
    marginTop: "24px",
    marginBottom: "60px",
  },
  select: {
    width: "calc(50% - 5px)",
    marginBottom: "12px",
    flexGrow: "unset",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexGrow: 0,
      flexBasis: "auto",
    },
  },
  dialogActions: {
    padding: "16px 32px 32px",
  },
  actionButton: {
    textTransform: "uppercase",
  },
}));
