import React from "react";
import DatePicker from "react-datepicker";
import { TextField } from "@mui/material";
import { addDays, getDay, subDays } from "date-fns";
import { DateRangePickerProps } from "./types";
import { useStyles } from "./DateRangePicker.styles";

const DateRangePicker = ({ startDate, endDate, onDateRangeChange }: DateRangePickerProps) => {
  const { classes } = useStyles();

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <DatePicker
      calendarClassName={classes.datePickerCalendar}
      popperClassName={classes.datePickerPopper}
      clearButtonClassName={classes.datePickerClearBtn}
      onChange={(date) => {
        if (date) {
          onDateRangeChange(date);
        }
      }}
      customInput={<TextField label="Date Range" variant="outlined" fullWidth />}
      includeDateIntervals={[{ start: subDays(new Date(), 1), end: addDays(new Date(), 28) }]}
      filterDate={isWeekday}
      calendarStartDay={1}
      startDate={startDate}
      endDate={endDate}
      showPopperArrow={false}
      selectsRange
      isClearable
    />
  );
};

export default DateRangePicker;
