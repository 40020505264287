import React from "react";
import { Grid, Theme } from "@mui/material";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import Menu from "../MenuBar/Menu/Menu";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    paddingLeft: "1em",
    display: "none",
    padding: "1rem 0",
    borderTop: "1px solid rgba(0,0,0,0.1)",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  endCallButton: {
    height: "28px",
    fontSize: "0.85rem",
    padding: "0 0.6em",
  },
  settingsButton: {
    [theme.breakpoints.down("md")]: {
      height: "28px",
      minWidth: "28px",
      border: "1px solid rgb(136, 140, 142)",
      padding: 0,
      margin: "0 1em",
    },
  },
}));

export default function MobileTopMenuBar() {
  const { classes } = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.container}
    >
      <EndCallButton className={classes.endCallButton} />
      <Menu buttonClassName={classes.settingsButton} />
    </Grid>
  );
}
