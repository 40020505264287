import React, { FC } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Container, Grid, Typography } from "@mui/material";
import { LocationsProvider, SlotAvailability } from "components/FindADoctor/types";
import { getDate } from "util/util_functions";
import useStyles from "./BookingOnline.styles";

interface Props {
  providerImg: string;
  fullName: string;
  specialty: string;
  number: string;
  patientRelationship?: string;
  purpose: string;
  appointment: { appointment: SlotAvailability; location: Array<LocationsProvider> } | undefined;
}

const AppointmentDetails: FC<Props> = ({
  providerImg,
  fullName,
  specialty,
  number,
  patientRelationship,
  purpose,
  appointment,
}) => {
  const { classes } = useStyles();

  const street = appointment?.location[0]?.street1;
  const suite = appointment?.location[0].suite;
  const city = appointment?.location[0].city;
  const state = appointment?.location[0].state;
  const zip = appointment?.location[0].zip;
  const fullAdress = `${street}, ${suite}, ${city}, ${state}, ${zip}`;

  return (
    <Container maxWidth="md">
      <Grid container className={classes.detailsAttention} justifyContent="center">
        <ErrorOutlineIcon />
        <Typography className={classes.sheduling}>
          Need help scheduling an appointment? We're here to help. Call us at {number}
        </Typography>
      </Grid>
      <Grid container className={classes.detailsContainer}>
        <img src={providerImg} alt="Provider" className={classes.img} />
        <Grid item className={classes.detailsInfo}>
          <Typography align="left" className={classes.detailsTitle}>
            {fullName}
          </Typography>
          <Typography align="left" className={classes.detailsText}>
            {specialty}
          </Typography>
        </Grid>

        <Grid item className={classes.detailsInfo}>
          {!!patientRelationship && (
            <Typography align="left" className={classes.detailsTitle}>
              {patientRelationship} Patient
            </Typography>
          )}
          {!!purpose && (
            <Typography align="left" className={classes.detailsText}>
              {purpose}
            </Typography>
          )}
        </Grid>

        {appointment?.location && (
          <Grid item className={classes.detailsInfo}>
            <Typography align="left" className={classes.detailsTitle}>
              {fullAdress}
            </Typography>
            <Typography align="left" className={classes.detailsText}>
              {getDate(appointment.appointment.date)}
            </Typography>
            <Typography align="left" className={classes.detailsText}>
              {appointment.appointment.start}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default AppointmentDetails;
