import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { Close } from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import PatientProfileCard from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/PatientProfileCard";
import { User } from "components/Video/utils/types";
import CellPhone from "components/shared/CellPhone";
import { useGetCampaignTags } from "hooks/campaigns/useGetCampaignTags";
import { useSaveCampaignTags } from "hooks/campaigns/useSaveCampaignTags";
import { useGenericEnhancedTableContext } from "hooks/useGenericEnhanceTableContext";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { useModalStyles } from "./styles";

type EditModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  id: number;
  userId: number;
  campaignId: number;
  handleRefresh: () => void;
};

const EditModal: React.FC<EditModalProps> = ({
  id,
  isOpen,
  handleClose,
  userId,
  campaignId: _campaignId,
  handleRefresh,
}) => {
  const { classes } = useModalStyles();
  const isMobile = useIsMobile();
  const app = useApp();
  const { sourceData, setSourceData } = useGenericEnhancedTableContext();
  const { tags: campaignTags } = useGetCampaignTags(id);
  const { tags: allTags } = useGetCampaignTags();
  const { saveCampaignTags } = useSaveCampaignTags(id);
  const [user, setUser] = useState<User>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const initialValues = { cellPhone: user?.cell_phone || "", email: user?.email || "" };

  useEffect(() => {
    setSelectedTags(campaignTags?.data || []);
  }, [campaignTags?.data]);

  const handleTagsChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedTags(event.target.value as string[]);
  };

  const getUser = async () => {
    try {
      const url = `/api/s/console/user/${userId}`;
      const { data } = await axiosClient.get(url);
      setUser(data?.data);
      // eslint-disable-next-line
      setFieldValue("cellPhone", data?.data?.cell_phone);
      // eslint-disable-next-line
      setFieldValue("email", data?.data?.email);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  const onSubmit = async ({ cellPhone, email }, { setSubmitting }) => {
    try {
      const url = `api/s/console/user/${userId}`;
      const body = { cellPhone, email: email?.toLowerCase() };
      if (!body.email) {
        delete body.email;
      }
      const response = await axiosClient.patch(url, body);

      const updatedCellPhone = response?.data?.data.cell_phone;
      const updatedUsers = sourceData.map((user) => {
        if (user.user_id === userId) {
          const newUser = { ...user, cell_phone: updatedCellPhone };
          if (body.email) {
            newUser.email = body.email;
          }
          return newUser;
        }
        return user;
      });
      setSourceData(updatedUsers);
      app.addInfoMsg("User updated successfully.");
    } catch (e) {
      // @ts-ignore
      const error = e.response;
      if (error.status === 409) {
        if (error?.data?.error?.toLowerCase()?.includes("email already exists")) {
          app.addError("Email already exists");
          // eslint-disable-next-line
          setFieldError("email", error?.data?.error);
        } else {
          app.addError("Cellphone already exists");
          // eslint-disable-next-line
          setFieldError("cellPhone", error?.data?.error);
        }
      } else {
        app.addError("Something went wrong. Please contact support.");
      }
    }
    setSubmitting(false);
  };

  const {
    values,
    handleBlur,
    setFieldValue,
    setFieldError,
    errors,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object({
      cellPhone: Yup.string()
        .nullable()
        .min(10, "Phone number must be 10 digit")
        .required("Phone number is required"),
      email: Yup.string()
        .nullable()
        .email("Email is not valid")
        .matches(
          /[A-Za-z0-9._%+-]+@(?!atlantichealth.org)[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
          "Emails from @atlantichealth.org domain are not allowed",
        ),
    }),
  });

  const areNewValuesEntered =
    initialValues.cellPhone !== values.cellPhone ||
    (values.email && initialValues.email !== values.email);
  const areNewTagsEntered = !isEqual(campaignTags?.data, selectedTags);

  const onSaveTags = async () => {
    try {
      const payload = {
        tags: selectedTags,
      };

      await saveCampaignTags(payload);
      return app.addInfoMsg("Tags updated successfully.");
    } catch (e) {
      app.addError("Error when trying to update tags.");
    }
  };

  const handleSave = async () => {
    try {
      if (areNewValuesEntered && !isSubmitting && isValid) {
        handleSubmit();
      }
      if (areNewTagsEntered) {
        await onSaveTags();
        handleRefresh();
      }
      handleClose();
    } catch (error) {
      console.error("Error occurred while saving tags or submitting form:", error);
      handleClose();
    }
  };

  useEffect(() => {
    if (userId) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={isMobile} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} variant="h6">
          Update Patient
        </Typography>
        <IconButton
          className={classes.closeButton}
          onClick={handleClose}
          data-testid="close-button"
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {user?.id && <PatientProfileCard profile={user} />}
        <form id="form">
          <div className={classes.textField}>
            <CellPhone
              cellPhone={values.cellPhone}
              setCellPhone={(value) => setFieldValue("cellPhone", value)}
              onBlur={handleBlur}
              error={Boolean(errors?.cellPhone)}
              helperText={errors?.cellPhone}
            />

            <TextField
              id="emailAddress"
              label="Email Address"
              variant="outlined"
              className={classes.textField}
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
              type="email"
              error={Boolean(errors?.email)}
              helperText={errors?.email}
              fullWidth
            />
          </div>

          <FormControl fullWidth variant="outlined" className={classes.tagsSelect}>
            <InputLabel id="tags-input-label">Campaign Tags</InputLabel>
            <Select
              variant="standard"
              labelId="tags-input-label"
              id="tags-input-label"
              multiple
              value={selectedTags}
              onChange={handleTagsChange}
              fullWidth
              inputProps={{ "data-testid": "tagsSelect" }}
              renderValue={(selectedTag) => (
                <div className={classes.chipsWrapper}>
                  {selectedTag.map((tag) => (
                    <Chip key={tag} label={tag} className={classes.tagChip} />
                  ))}
                </div>
              )}
            >
              {allTags?.data?.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="submit-button"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSubmitting || !isValid || (!areNewValuesEntered && !areNewTagsEntered)}
          onClick={() => {
            handleSave();
          }}
        >
          Save Tags and Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
