import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { SPECIMEN_ID_PREFIX } from "../../consts";
import ConfirmationDialog from "components/ConfirmationDialog";
import { permissions } from "components/shared/permissions";
import { useUpdateSpecimenParts } from "hooks/specimenTracking/useUpdateSpecimenParts/useUpdateSpecimenParts";
import useTranslation from "hooks/useTranslation";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "util/AppContext";
import { HttpStatusCode } from "util/HttpStatusCode";
import { getFullNameFromParts } from "util/util_functions";
import { useSpecimenMobileListStyles } from "../../styles";

const formatDate = (date: string) => {
  return moment(date).format("MM/DD/YYYY");
};

export const SpecimenCardMobile = ({
  specimenId,
  collectedDatetime,
  collectedByFirstName,
  collectedByLastName,
  collectedAtName,
  type,
  specimenSpecimenExternalId,
  handleSaved,
}) => {
  const {
    web: {
      specimenTracking: { leadingZeroError },
    },
  } = useTranslation();
  const { addInfoMsg, addError } = useApp();
  const { isAdmin, isManager, isSpecimenTech } = useUserType();
  const { classes } = useSpecimenMobileListStyles();
  const navigate = useNavigate();
  const [editingSpecimenId, setEditingSpecimenId] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [specimenExternalId, setSpecimenExternalId] = useState(specimenSpecimenExternalId);
  const { updateSpecimenParts } = useUpdateSpecimenParts();
  const {
    web: { specimenTracking, specimenReportOrderId },
  } = useTranslation();
  const confirmationMessage = `Are you sure you want to change the Specimen ID from '${specimenSpecimenExternalId}' to '${specimenExternalId}'?`;

  const getSpecimenProcessType = (type: string) => {
    switch (type) {
      case "DROP_OFF":
        return specimenReportOrderId.droppedOff;
      case "PICK_UP":
        return specimenReportOrderId.pickedUp;
      default:
        return specimenReportOrderId.added;
    }
  };

  const navigateToHistory = (specimenId: string) => {
    navigate(`/console/${permissions.specimenTracking}/history/${specimenId}`);
  };

  const handleConfirmSpecimenId = async () => {
    try {
      if (!specimenExternalId) {
        addError(`Specimen ID is empty`);
        return;
      }

      await updateSpecimenParts(specimenId, { specimenExternalId });
      addInfoMsg("Specimen has been updated successfully.");
      handleSaved();

      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response?.status === HttpStatusCode.CONFLICT) {
        addError(`Specimen ID already exists`);
      } else {
        addError("Problem updating specimen, please contact support.");
      }
      setSpecimenExternalId(specimenSpecimenExternalId);
    }
    setEditingSpecimenId(false);
  };

  const handleCancelSpecimenId = () => {
    setEditingSpecimenId(false);
    setError(undefined);
    setSpecimenExternalId(specimenSpecimenExternalId);
  };

  const handleEditSpecimenId = (event) => {
    event.stopPropagation();
    setEditingSpecimenId(true);
    setError(undefined);
  };

  const handleSpecimenExternalIdChange = (event) => {
    const newValue = event.target.value;
    setSpecimenExternalId(`${SPECIMEN_ID_PREFIX}${newValue}`);
    setError(newValue.startsWith("0") ? leadingZeroError : undefined);
  };

  const handleNavigateToHistory = () => {
    navigateToHistory(specimenId);
  };

  const handleActionConfirmationOk = () => {
    handleConfirmSpecimenId();
    setConfirmationDialogOpen(false);
  };

  const handleActionConfirmationCancel = () => {
    setConfirmationDialogOpen(false);
  };

  const handleSaveChanges = () => {
    if (specimenExternalId.replace(SPECIMEN_ID_PREFIX, "").startsWith("0")) {
      setError(leadingZeroError);
    } else {
      setError(undefined);
      setConfirmationDialogOpen(true);
    }
  };

  return (
    <Grid
      key={specimenId}
      container
      style={{ width: "100%" }}
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <div className={classes.specimenSection} style={{ width: "100%" }}>
        <Typography
          className={classes.labelBig}
          color="primary"
          style={{ cursor: "pointer", width: "100%" }}
          data-cy="specimenIDBtn"
        >
          <Box component="span" onClick={handleNavigateToHistory}>
            {specimenTracking.specimenId}:{" "}
          </Box>
          {editingSpecimenId ? (
            <>
              <br />
              <TextField
                type="number"
                data-testid="text-specimen-add-manually"
                style={{ width: "70%", maxWidth: "20rem" }}
                value={specimenExternalId.replace(SPECIMEN_ID_PREFIX, "")}
                autoFocus
                required
                id="outlined-basic"
                label="Specimen ID"
                variant="outlined"
                onChange={handleSpecimenExternalIdChange}
                error={!!error}
                helperText={error}
                inputProps={{ "data-testid": "specimenIdInput" }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>{SPECIMEN_ID_PREFIX}</span>
                    </InputAdornment>
                  ),
                }}
              />
              {!error && (
                <CheckCircleIcon onClick={handleSaveChanges} className={classes.externalIdIcon} />
              )}
              <CancelIcon onClick={handleCancelSpecimenId} className={classes.externalIdIcon} />
            </>
          ) : (
            <>
              <Box component="span" onClick={handleNavigateToHistory}>
                <strong>{specimenExternalId}</strong>
                <KeyboardArrowRightRounded />
              </Box>
              {(isAdmin || isManager || isSpecimenTech) && (
                <Box component="span">
                  <EditIcon onClick={handleEditSpecimenId} />
                </Box>
              )}
            </>
          )}
        </Typography>
        <Typography className={classes.labelMedium} style={{ fontWeight: "600" }}>
          Status: {getSpecimenProcessType(type)}
        </Typography>
        <Typography className={classes.labelSmall}>
          {specimenTracking.location}: {collectedAtName}
        </Typography>
        <Typography className={classes.labelSmall}>
          {specimenTracking.collectedBy}:
          {getFullNameFromParts(collectedByFirstName, collectedByLastName)}
        </Typography>
        <Typography className={classes.labelSmall}>
          {specimenTracking.collectedDate}: {formatDate(collectedDatetime)}
        </Typography>
        <ConfirmationDialog
          classes={{ paper: classes.confirmationModal }}
          id="confirmation-specimen-id"
          title="Confirm Specimen ID"
          msg={confirmationMessage}
          open={confirmationDialogOpen}
          onCancel={handleActionConfirmationCancel}
          onOk={handleActionConfirmationOk}
        />
      </div>
    </Grid>
  );
};
