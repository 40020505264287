import * as Yup from "yup";
import { Tag } from "../components/OrderTags";

export type FormValues = {
  tags: Tag[];
  notes: string;
  assignedDepartmentId: string;
  patientReferenceSource: string;
  patientReferenceSpeciality?: string;
  patientReferencePhysicianId?: string;
};

export const initialValues: FormValues = {
  tags: [],
  notes: "",
  assignedDepartmentId: "",
  patientReferenceSource: "",
};

export const getValidationSchema = (isConsultation: boolean) => {
  return Yup.object({
    ...(isConsultation && {
      assignedDepartmentId: Yup.string().required("This field is required"),
      patientReferenceSource: Yup.string().required("This field is required"),
      tags: Yup.array().required("This field is required").min(1),
    }),
  });
};
