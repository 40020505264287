import React from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

interface InputProps {
  label: string;
  name: string;
  type: string;
}

interface Props {
  disabled: boolean;
  inputsValues: Record<any, any>;
  handleChange: any;
  inputs: InputProps[];
  selectOptions?: Record<string, string[]>;
  className?: string;
}

const RenderInputs: React.FC<Props> = ({
  disabled,
  inputsValues,
  handleChange,
  inputs,
  selectOptions,
  className,
}) => {
  const renderInput = (input: InputProps) => {
    if (input.type === "select") {
      return (
        <FormControl variant="outlined" className={className} key={input.name}>
          <InputLabel id={`${input.name}-label`} htmlFor={input.name} disabled={disabled}>
            {input.label}
          </InputLabel>
          <Select
            labelId={`${input.name}-label`}
            id={input.name}
            name={input.name}
            value={inputsValues[input.name]}
            onChange={handleChange}
            variant="outlined"
            label={input.label}
            disabled={disabled}
            aria-label={input.label}
          >
            {(selectOptions?.[input.name] ?? []).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <TextField
        key={input.name}
        fullWidth
        label={input.label}
        id={input.name}
        name={input.name}
        value={inputsValues[input.name] ?? ""}
        onChange={handleChange}
        variant="outlined"
        disabled={disabled}
        className={className}
        aria-label={input.label}
      />
    );
  };

  return (
    <div style={{ marginTop: "32px", display: "flex", flexWrap: "wrap", gap: "16px" }}>
      {inputs.map((input) => renderInput(input))}
    </div>
  );
};

export default RenderInputs;
