import React, { useEffect } from "react";
import InputMask from "react-input-mask";
import { FormikHelpers, useFormik } from "formik";
import { Button, FormControl, MenuItem, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { QuerySteps } from "../Steps";
import PCPName from "./PCPName";
import { FormValues, initialValues, validationSchema } from "./formModel";
import { makeStyles } from "tss-react/mui";
import UploadImages from "components/FileUploadWidget/upload-images.component";
import { cellPhoneMask } from "util/constants";
import { useUploadImages } from "../useUploadImages";
import useGetInsuranceInfo from "./useGetInsuranceInfo";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { isAlphabetsOnly, isDigitsOnly } from "util/util_functions";

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "47%",
    height: "42px",
    textTransform: "uppercase",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  formControl: {
    display: "block",
    margin: "12px 0px",
    minWidth: 260,
    textAlign: "left",
  },
  healthInfoTitle: {
    marginBottom: "16px",
  },
  insuranceInfoTitle: {
    margin: "16px 0",
  },
}));

interface InsuranceDetailsProps {
  setStep: (Steps: QuerySteps) => void;
  vsId: number;
}

export default function InsuranceDetails({ setStep, vsId }: InsuranceDetailsProps) {
  const app = useApp();
  const { classes } = useStyles();
  const { isLoading: isLoadingImages, uploadImages } = useUploadImages();

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const isUploadSuccessful = await uploadImages();
    if (!isUploadSuccessful) {
      return;
    }

    try {
      await axiosClient.post(API_ENDPOINTS.twilio_set_visit_insurance_details, {
        virtual_session_id: vsId,
        emergency_contact_name: values.emergencyContactName,
        emergency_contact_phone: values.emergencyContactPhone,
        pcp_name: values.primaryCarePhysicianName,
        pcp_npi: values.primaryCarePhysicianNpi,
        insurance_company_id: values.insuranceCompanyId,
        ...(values.memberIdNumber && { member_id_number: values.memberIdNumber }),
      });
      setStep(QuerySteps.CONSENT);
    } catch (e) {
      app.addError("Something went wrong!");
    }
    actions.setSubmitting(false);
  };

  const formik = useFormik<FormValues>({ initialValues, onSubmit, validationSchema });

  const { insuranceCompaniesRefList } = useGetInsuranceInfo(formik.setFieldValue);

  const getVirtualSession = async () => {
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.twilio_get_virtual_session, {
        vs_id: vsId,
      });

      formik.setFieldValue("emergencyContactName", data.emergency_contact_name);
      formik.setFieldValue("emergencyContactPhone", data.emergency_contact_phone);
      formik.setFieldValue("primaryCarePhysicianName", data.pcp_name);
      formik.setFieldValue("primaryCarePhysicianNpi", data.pcp_npi);
      formik.setFieldValue("insuranceCompanyId", data.insurance_company_id);
      formik.setFieldValue("memberIdNumber", data.member_id_number);
    } catch (e) {
      app.addError("Something went wrong. Please, contact support.");
    }
  };

  useEffect(() => {
    if (vsId && insuranceCompaniesRefList?.length) {
      getVirtualSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId, insuranceCompaniesRefList]);

  const validatePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const regEx = /[^\d]/g;
    formik.setFieldValue("emergencyContactPhone", value.substring(2).replace(regEx, ""));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h5" className={classes.healthInfoTitle}>
        Next, tell us more information about you and your health
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          id="emergencyContactName"
          label="Emergency Contact Name *"
          variant="outlined"
          fullWidth
          name="emergencyContactName"
          value={formik.values.emergencyContactName}
          onChange={(e) =>
            isAlphabetsOnly(e.target.value) &&
            formik.setFieldValue("emergencyContactName", e.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.emergencyContactName && !!formik.errors.emergencyContactName}
          helperText={formik.touched.emergencyContactName && formik.errors.emergencyContactName}
          data-cy="emergencyContactName"
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputMask
          mask={cellPhoneMask}
          name="emergencyContactPhone"
          value={formik.values.emergencyContactPhone}
          onChange={validatePhone}
          onBlur={formik.handleBlur}
          data-cy="emergencyContactPhone"
        >
          {/* @ts-ignore */}
          {(inputProps) => (
            <TextField
              id="emergencyContactPhone"
              label="Emergency Contact Phone *"
              variant="outlined"
              fullWidth
              error={formik.touched.emergencyContactPhone && !!formik.errors.emergencyContactPhone}
              helperText={
                formik.touched.emergencyContactPhone && formik.errors.emergencyContactPhone
              }
              {...inputProps}
            />
          )}
        </InputMask>
      </FormControl>
      <PCPName
        pcpName={formik.values.primaryCarePhysicianName}
        setFieldValue={formik.setFieldValue}
        defaultValue={{
          id: formik.values.primaryCarePhysicianNpi,
          name: formik.values.primaryCarePhysicianName,
        }}
      />
      <Typography variant="h5" className={classes.insuranceInfoTitle}>
        Health Insurance Information
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          id="insuranceCompanyId"
          label="Insurance Company *"
          variant="outlined"
          fullWidth
          select
          name="insuranceCompanyId"
          value={formik.values.insuranceCompanyId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.insuranceCompanyId && !!formik.errors.insuranceCompanyId}
          helperText={formik.touched.insuranceCompanyId && formik.errors.insuranceCompanyId}
        >
          <MenuItem key="" value="">
            <em>Select One</em>
          </MenuItem>
          {insuranceCompaniesRefList.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          id="memberIdNumber"
          label="Member ID Number"
          variant="outlined"
          fullWidth
          name="memberIdNumber"
          value={formik.values.memberIdNumber}
          onChange={(e) =>
            isDigitsOnly(e.target.value) && formik.setFieldValue("memberIdNumber", e.target.value)
          }
        />
      </FormControl>
      <div className="container">
        <UploadImages
          contextType="VirtualSession"
          contextID={vsId}
          upload_msg="Take a picture of the front and back of your insurance card"
          imageType="Insurance Card"
        />
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          onClick={() => setStep(QuerySteps.VISIT_REASON)}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting || isLoadingImages}
          className={classes.button}
          startIcon={isLoadingImages ? <CircularProgress /> : ""}
        >
          {isLoadingImages ? "Uploading images..." : "Next"}
        </Button>
      </div>
    </form>
  );
}
