import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/stylees.css";
import { useVisitDetails } from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/styles";
import { useAuth } from "util/Security";
import { getAddress, getAge } from "util/util_functions";
import ProfileDefault from "static/images/sample_image_2.png";

const urgencies = {
  urgent: "URGENT",
  routine: "ROUTINE",
  low_priority: "LOW PRIORITY",
};

const PatientProfileCard = ({ profile }) => {
  const address = getAddress(profile.addr_str_1, profile.addr_str_2, profile.city);
  const { classes } = useVisitDetails();
  const auth = useAuth();
  const { urgency } = profile?.virtual_session_info || {};
  const isAssignedToMe =
    parseInt(profile?.virtual_session_info?.currently_assigned_staff_user_id, 10) ===
    parseInt(auth?.user?.userId, 10);
  const age = getAge(profile?.dob);
  const fullName = [profile?.first_name, profile?.last_name].filter(Boolean).join(" ");

  return (
    <Card className={classes.root} data-testid="patientProfileCard">
      <Grid container className={classes.cardContainer}>
        <Grid item xs={4} className={classes.imgContainer}>
          {profile.patientPhoto && (
            <CardMedia
              component="img"
              alt="Mychart Profile Picture"
              className={classes.img}
              title="Mychart  Profile Picture"
              src={`data:image/jpeg;base64,${profile.patientPhoto}`}
            />
          )}
          {!profile.patientPhoto && (
            <CardMedia
              component="img"
              alt="Default Profile Picture"
              className={classes.img}
              title="Default Profile Picture"
              image={ProfileDefault}
            />
          )}
        </Grid>
        <Grid item xs={8} className={classes.descriptionContainer}>
          <div className={classes.cardTop}>
            <Typography variant="h5" color="textSecondary" className={classes.topHeading}>
              {fullName || <>&mdash;</>}
            </Typography>
            <div className={classes.tagsContainer}>
              {profile?.dob && age < 18 && <div className={classes.tag}>Minor</div>}
              {urgency && <div className={classes.tag}>{urgencies[urgency]}</div>}
              {isAssignedToMe && <div className={classes.tag}>ASSIGNED TO ME</div>}
            </div>
          </div>

          <div className={classes.media}>
            <Typography className={classes.subHeading}>
              <b>MRN: &nbsp;</b>{" "}
              <span className={classes.subText}>{profile.emr_mrn || <>&nbsp; &mdash;</>}</span>
            </Typography>
          </div>
          <div className={classes.cardContentDiv}>
            <div className={classes.insideCards}>
              <Typography component="small" className={classes.subHeading}>
                <b>STREET ADDRESS</b>
              </Typography>
              <Typography variant="body1" className={classes.descriptionText}>
                {address || <>&mdash;</>}
              </Typography>
              <Typography component="small" className={classes.subHeading}>
                <b>PHONE NUMBER</b>
              </Typography>
              <Typography variant="body1" className={classes.descriptionText}>
                {profile.cell_phone || <>&mdash;</>}
              </Typography>
              <Typography component="small" className={classes.subHeading}>
                <b>ZIP CODE</b>
              </Typography>
              <Typography variant="body1" className={classes.descriptionText}>
                {profile.zip || <>&mdash;</>}
              </Typography>
            </div>
            <div className={classes.insideCards}>
              <Typography component="small" className={classes.subHeading}>
                <b>DATE OF BIRTH</b>
              </Typography>
              <Typography variant="body1" className={classes.descriptionText}>
                {profile.dob || <>&mdash;</>}
              </Typography>
              <Typography component="small" className={classes.subHeading}>
                <b>LEGAL SEX</b>
              </Typography>
              <Typography variant="body1" className={classes.descriptionText}>
                {profile.gender || <>&mdash;</>}
              </Typography>
              {profile?.proxy_owner_user && (
                <>
                  <Typography component="small" className={classes.subHeading}>
                    <b>{profile.proxy_owner_user.rel_type}</b>
                  </Typography>

                  <Typography variant="body1" className={classes.descriptionText}>
                    {`${profile?.proxy_owner_user.first_name} ${profile?.proxy_owner_user.last_name}`}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PatientProfileCard;
