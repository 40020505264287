import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  iconArea: {
    width: 24,
    height: 24,
    marginRight: 32,
  },
  container: {
    padding: 16,
  },
  card: {
    marginTop: 16,
  },
  textUpcoming: {
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 10,
    fontFamily: "Alegreya Sans",
  },
  text: {
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 24,
    fontFamily: "Alegreya Sans",
  },
  title: {
    fontWeight: 400,
    fontSize: 24,
    marginBottom: 10,
    fontFamily: "Alegreya Sans",
  },
  button: {
    width: "100%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  textLocation: {
    fontWeight: 500,
    fontSize: 20,
    marginBottom: 10,
    fontFamily: "Alegreya Sans",
  },
}));

export default useStyles;
