import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";
import { useApp } from "../util/AppContext";
import { axiosClient } from "util/api_helper";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
}));

export default function OpenSchBooking() {
  const { classes } = useStyles();
  const [slots, setSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const app = useApp();

  useEffect(() => {
    const getOpenSlots = async () => {
      try {
        const uri = "/api/openapptslots";
        const { data } = await axiosClient.get(uri, {
          params: {
            includeDetails: true,
            dept: "201443001,201445001,201438001,201442001,201441001,201440001",
            vt: 10002926,
            daysForward: 5,
          },
        });
        setSlots(data.result);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
        app.addError("Failed to load appointment information, please try again.");
      }
    };
    getOpenSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      {isLoading === true && (
        <>
          <br />
          <h2>Loading appointment information...</h2>
        </>
      )}
      {isLoading === false && slots && (
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid xs={12}>
            <h2>Atlantic Health Covid-19 Vaccination Appointment Availability</h2>
          </Grid>
          <Grid xs={12}>
            <h3>Please select a location and time that works for you:</h3>
          </Grid>

          {slots.map((slotContainer) => (
            <React.Fragment key={slotContainer.DepartmentName}>
              <Grid xs={12}>
                <h3>{slotContainer.DepartmentName}</h3>
              </Grid>
              <Grid xs={8} md={6} lg={4}>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                  <Grid xs={4}>
                    <Chip label="9:00am" clickable color="primary" />
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
      {isLoading === false && !slots && (
        <Grid xs={12}>
          <h2>Failed to load appointment information, please contact support. </h2>
        </Grid>
      )}
    </div>
  );
}
