import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ isLoading: boolean }>()((theme, { isLoading }) => ({
  dialogPaper: {
    height: "80%",
    zIndex: isLoading ? -2 : 5,
  },
  title: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    padding: theme.spacing(3),
    overflowX: "hidden",
  },
  actions: {
    margin: 0,
    padding: theme.spacing(3),
  },
  container: {
    minWidth: 540,
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
    },
  },
  textField: {
    width: "100%",
  },
  datePickerContainer: {
    position: "relative",
  },
  spinner: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));
