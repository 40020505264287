import React, { useCallback, useEffect } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { MeetStandardWithMonitoring, NotMeetStandard } from "./Common";
import { getDefaultCheckboxList, getDeterminationQuestions } from "../../constants";
import { DeterminationQuestions, DeterminationTypesProps } from "views/DOTForm/types/constants";
import useTranslation from "hooks/useTranslation";
import { useIsMobile } from "util/deviceUtils";
import useStyles from "../../../styles/forms.styles";

const defaultStateValue = {
  notMeetStandard: false,
  meetStandardInResolution: false,
  meetStandardWithMonitoring: false,
  determinationPending: false,
  incompleteExamination: false,
};

const FederalMedicalExaminerDeterminationStep: React.FC<DeterminationTypesProps> = ({
  formData,
  handleChange,
  isDisabled,
}) => {
  const { classes } = useStyles({
    alignLeft: false,
  });

  const isMobile = useIsMobile();

  const [federalOptions, setFederalOptions] = React.useState(defaultStateValue);

  const {
    web: {
      dotForm: {
        staff: {
          medicalDetermination: { federal },
        },
      },
    },
  } = useTranslation();

  const medicalDeterminationData = formData;

  const {
    title,
    checkboxList: { cfr39164, intracityZone, waiverException },
    determinationPending: { returnMedicalExam, medicalExaminationReport },
  } = federal;

  const getDetermination = useCallback(
    (determination: string): boolean => {
      return medicalDeterminationData?.determination === determination;
    },
    [medicalDeterminationData?.determination],
  );

  const federalDeterminationQuestions: DeterminationQuestions[] = getDeterminationQuestions(
    "federal",
    federal.questions,
    getDetermination,
  );

  useEffect(() => {
    if (formData) {
      const currentDetermination = federalDeterminationQuestions.find(
        (item) => item.determination === formData?.federalDetermination,
      );
      if (currentDetermination?.textAreaName) {
        setFederalOptions((prev) => ({
          ...prev,
          ...defaultStateValue,
          [currentDetermination.textAreaName]: true,
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const checkboxesList = getDefaultCheckboxList(federal.checkboxList, medicalDeterminationData);

  const federalCheckboxList = [
    {
      id: 4,
      name: "qualifiedByOp",
      label: cfr39164,
      value: medicalDeterminationData?.data?.monitoring?.qualifiedByOp,
      cyName: "FED-MedicalDet-QualifiedByOp",
    },
    {
      id: 5,
      name: "intracityZone",
      label: intracityZone,
      value: medicalDeterminationData?.data?.monitoring?.intracityZone,
      cyName: "FED-MedicalDet-IntrancityZone",
    },
    {
      id: 6,
      name: "exemption",
      label: waiverException,
      value: medicalDeterminationData?.data?.monitoring?.exemption,
      cyName: "FED-MedicalDet-Exepmtion",
    },
  ];

  checkboxesList.push(...federalCheckboxList);

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    responseType: string,
  ) => {
    const { value, name, checked } = event.target;
    const getNameIndex = federalDeterminationQuestions.find((item) => item.textAreaName === name);

    // if the user selects a different determination, clear the previous response
    if (medicalDeterminationData?.stateDetermination) {
      handleChange(`medicalDetermination.stateDetermination`, undefined);
    }

    // if the user changes to a different determination, clear the previous data
    if (getNameIndex && value) {
      handleChange(`medicalDetermination.data`, "");
    }

    if (value || checked !== undefined) {
      handleChange(`medicalDetermination.${responseType}`, value || checked);
      handleChange(`medicalDetermination.determinationType`, "FEDERAL");
    }

    // delete the response if the user unchecks the box
    if (checked !== undefined && checked === false && !value) {
      handleChange(`medicalDetermination.${responseType}`, undefined);
    }

    // keeps track of the current determination
    if (getNameIndex) {
      setFederalOptions({
        ...federalOptions,
        ...defaultStateValue,
        [name]: !!value,
      });
    }
  };

  const [pendingStateCheckboxList, setPendingStateCheckboxList] = React.useState({
    "data.pending.mustReturn": !!medicalDeterminationData?.data?.pending?.mustReturn,
    "data.pending.amended": !!medicalDeterminationData?.data?.pending?.amended,
  });

  const handlePendingState = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    property: string,
  ) => {
    const { value } = event.target;
    handleChange(`medicalDetermination.${property}`, value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
    const { checked } = event.target;
    handleChange(`medicalDetermination.${property}`, checked ? "" : undefined);

    setPendingStateCheckboxList({
      ...pendingStateCheckboxList,
      [property]: checked,
    });
  };

  return (
    <Grid container data-testid="federal-determination">
      {/* Section 1 Start */}
      <Typography className={classes.timeWindowTitle} style={{ fontWeight: 700 }}>
        {title}
      </Typography>
      <Grid item xs={12} sm={12} container>
        <Grid item xs={12} md={12} container>
          <FormGroup style={{ width: "100%" }}>
            {federalDeterminationQuestions.map(
              ({ question, determination, textAreaName, cyName }, index) => (
                <div key={determination}>
                  <Grid item xs={12} md className={classes.checkboxListLeftAlign}>
                    <RadioGroup
                      data-testid={`federalDetermination-${index}`}
                      name={question}
                      data-cy={cyName}
                      value={
                        medicalDeterminationData?.federalDetermination
                          ? medicalDeterminationData?.federalDetermination
                          : ""
                      }
                      onChange={(ev) => handleQuestionChange(ev, "federalDetermination")}
                      row
                    >
                      <FormControlLabel
                        value={determination}
                        name={textAreaName}
                        control={<Radio />}
                        label={question}
                        disabled={isDisabled}
                      />
                    </RadioGroup>
                    <Grid item xs={12}>
                      {federalOptions.notMeetStandard &&
                        medicalDeterminationData?.federalDetermination === determination && (
                          <NotMeetStandard
                            medicalDeterminationData={medicalDeterminationData}
                            handleQuestionChange={handleQuestionChange}
                            classes={classes}
                            disabled={isDisabled}
                          />
                        )}
                      {federalOptions.meetStandardWithMonitoring &&
                        medicalDeterminationData?.federalDetermination === determination && (
                          <MeetStandardWithMonitoring
                            medicalDeterminationData={medicalDeterminationData}
                            handleQuestionChange={handleQuestionChange}
                            classes={classes}
                            checkboxesList={checkboxesList}
                            disabled={isDisabled}
                          />
                        )}
                      {federalOptions.determinationPending &&
                        medicalDeterminationData?.federalDetermination === determination && (
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            container
                            className={classes.examinerInnerQuestionsMargin}
                          >
                            <Grid item xs={12} sm container>
                              <FormControl variant="outlined" fullWidth>
                                <div style={{ display: "flex" }}>
                                  <Grid xs={12} sm item style={{ display: "flex" }}>
                                    <TextField
                                      id="determinationPending"
                                      name="determinationPending"
                                      data-testid="determinationPendingInput"
                                      size="small"
                                      value={medicalDeterminationData?.data?.comment}
                                      variant="outlined"
                                      onChange={(ev) =>
                                        handleQuestionChange(
                                          ev as React.ChangeEvent<HTMLInputElement>,
                                          "data.comment",
                                        )
                                      }
                                      error={false}
                                      helperText={false}
                                      data-cy="FED-MedicalDet-DeterminationPendingInput"
                                      disabled={isDisabled}
                                    />
                                  </Grid>
                                </div>
                              </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} sm container style={{ marginTop: "10px" }}>
                              <FormControl variant="outlined" fullWidth>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                  }}
                                >
                                  <Grid item xs={12} sm={8}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          data-cy="FED-MedicalDet-MustReturn"
                                          checked={
                                            pendingStateCheckboxList["data.pending.mustReturn"]
                                          }
                                          onChange={(ev) =>
                                            handleCheckboxChange(ev, "data.pending.mustReturn")
                                          }
                                          name="returnMedicalExamOffice"
                                          data-testid="returnMedicalExamOffice"
                                          disabled={isDisabled}
                                        />
                                      }
                                      label={returnMedicalExam}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm>
                                    <TextField
                                      id="returnMedicalExamOfficeReason"
                                      name="returnMedicalExamOfficeReason"
                                      size="small"
                                      defaultValue={
                                        medicalDeterminationData?.data?.pending?.mustReturn || ""
                                      }
                                      variant="outlined"
                                      onChange={(ev) =>
                                        handlePendingState(ev, "data.pending.mustReturn")
                                      }
                                      disabled={
                                        !pendingStateCheckboxList["data.pending.mustReturn"] ||
                                        isDisabled
                                      }
                                      required={pendingStateCheckboxList["data.pending.mustReturn"]}
                                      error={
                                        pendingStateCheckboxList["data.pending.mustReturn"] &&
                                        (medicalDeterminationData?.data?.pending?.mustReturn ===
                                          "" ||
                                          medicalDeterminationData?.data?.pending?.mustReturn ===
                                            undefined)
                                      }
                                      helperText={false}
                                      data-cy="FED-MedicalDet-MustReturnInput"
                                      style={{ marginBottom: "1em" }}
                                    />
                                  </Grid>
                                </div>
                              </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={12} sm container>
                              <FormControl variant="outlined" fullWidth>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                  }}
                                >
                                  <Grid item xs={12} sm={5}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          data-cy="FED-MedicalDet-Amended"
                                          checked={pendingStateCheckboxList["data.pending.amended"]}
                                          onChange={(ev) =>
                                            handleCheckboxChange(ev, "data.pending.amended")
                                          }
                                          name="medicalExaminationAmended"
                                          data-testid="medicalExaminationAmended"
                                          disabled={isDisabled}
                                        />
                                      }
                                      label={medicalExaminationReport}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm>
                                    <TextField
                                      id="medicalExaminationAmendedReason"
                                      name="medicalExaminationAmendedReason"
                                      size="small"
                                      defaultValue={
                                        medicalDeterminationData?.data?.pending?.amended || ""
                                      }
                                      variant="outlined"
                                      onChange={(ev) =>
                                        handlePendingState(ev, "data.pending.amended")
                                      }
                                      disabled={
                                        !pendingStateCheckboxList["data.pending.amended"] ||
                                        isDisabled
                                      }
                                      required={pendingStateCheckboxList["data.pending.amended"]}
                                      error={
                                        pendingStateCheckboxList["data.pending.amended"] &&
                                        (medicalDeterminationData?.data?.pending?.amended === "" ||
                                          medicalDeterminationData?.data?.pending?.amended ===
                                            undefined)
                                      }
                                      helperText={false}
                                      data-cy="FED-MedicalDet-AmendedInput"
                                    />
                                  </Grid>
                                </div>
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                      {federalOptions.incompleteExamination &&
                        medicalDeterminationData?.federalDetermination === determination && (
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            className={classes.examinerInnerQuestionsMargin}
                          >
                            <Grid item xs={12} sm container>
                              <FormControl variant="outlined" fullWidth>
                                <div style={{ display: "flex" }}>
                                  <Grid item xs={12} sm style={{ display: "flex" }}>
                                    <TextField
                                      id="incompleteExamaminationReasons"
                                      name="incompleteExamaminationReasons"
                                      size="small"
                                      defaultValue={medicalDeterminationData?.data?.comment}
                                      variant="outlined"
                                      onChange={(ev) =>
                                        handleQuestionChange(
                                          ev as React.ChangeEvent<HTMLInputElement>,
                                          "data.comment",
                                        )
                                      }
                                      error={false}
                                      helperText={false}
                                      data-testid="incompleteExamaminationReasons"
                                      data-cy="FED-MedicalDet-IncompleteExaminationInput"
                                      disabled={isDisabled}
                                    />
                                  </Grid>
                                </div>
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                  <Box width="100%" />
                </div>
              ),
            )}
          </FormGroup>
        </Grid>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default FederalMedicalExaminerDeterminationStep;
