import { useState } from "react";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

interface SpecimentReportListData {
  data: DataSpecimen[];
  report: SpecimentReportListDataReport;
  totalRecords: number;
}

interface DataSpecimen {
  collectedAt: number;
  collectedAtName: string;
  collectedBy: number;
  collectedByFirstName: string;
  collectedByLastName: string;
  collectedDatetime: Date;
  id: number;
  inFinalDestination: boolean;
  specimenAuthorizingUser: null;
  specimenCollectedDatetime: Date;
  specimenCurrent_location: string;
  specimenDescription: null;
  specimenId: number;
  specimenImage: null;
  specimenOrderDob: null;
  specimenOrderGender: string;
  specimenOrderId: number;
  specimenOrderMrn: string;
  specimenOrderOrderExternalId: string;
  specimenOrderPatientFirstName: null;
  specimenOrderPatientLastName: null;
  specimenSpecimenExternalId: string;
  specimenSpecimenSource: null;
  specimenSpecimenType: string;
  specimenStatus: string;
  type: string;
  firstTrackedDate: string;
  lastTrackedDate: string;
  journeyTime: string;
}

export interface SpecimentReportListDataReport {
  averageJourneyTime: number;
  countCompletedDropOffs: number;
  countCurrentlyInTransit: number;
}
export const useGetSpecimenReports = () => {
  const [data, setData] = useState<SpecimentReportListData>();
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const getSpecimenReport = (initialDate: string, finalDate: string) => {
    const params = {
      start_date: initialDate,
      end_date: finalDate,
    };
    axiosClient
      .get<SpecimentReportListData>(API_ENDPOINTS.specimenTrackingDashboardList, {
        params,
      })
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        console.log(err);
        app.addError(errorMsg);
      });
  };

  return {
    getSpecimenReport,
    data,
  };
};
