import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Dialog, TextField } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { UserEvents, UserSessions } from "./components";
import PatientProfileCard from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/PatientProfileCard";
import CellPhone from "components/shared/CellPhone";
import { DialogContent, DialogTitle } from "views/Report/UIComponents";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()(() => ({
  dialog: {
    maxWidth: "685px",
  },
  textField: {
    marginBottom: "12px",
  },
  saveBtn: {
    marginBottom: "24px",
  },
  formControl: {
    marginBottom: "12px",
  },
}));

const PatientDetailsModal = ({ show, setShow, currentPatientRecord }) => {
  const { classes } = useStyles();
  const app = useApp();
  const isMobile = useIsMobile();

  const onSubmit = async ({ cellPhone, email, username }, { setSubmitting }) => {
    try {
      await axiosClient.patch(`/api/s/console/user/${currentPatientRecord?.id}`, {
        ...((currentPatientRecord.username === null && username === "") || {
          username: username?.trim() ? username?.trim() : null,
        }),
        email: email?.trim() ? email?.trim() : null,
        cellPhone: cellPhone?.trim() ? cellPhone?.trim() : null,
      });
      setShow(false);
      setSubmitting(false);
    } catch (e) {
      app.addError(e.response?.data?.error || e.response?.data?.description);
    }
  };

  const validationSchema = Yup.object({
    cellPhone: Yup.string().min(10, "Phone number must be 10 digit"),
    email: Yup.string()
      .email("Email is not valid")
      .matches(
        /[A-Za-z0-9._%+-]+@(?!atlantichealth.org)[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Emails from @atlantichealth.org domain are not allowed",
      ),
  });

  return (
    <Dialog
      classes={{ paperWidthSm: classes.dialog }}
      onClose={() => setShow(false)}
      open={show}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogTitle onClose={() => setShow(false)}>Patient details</DialogTitle>
      <DialogContent dividers>
        <Box marginBottom="24px">
          <PatientProfileCard profile={currentPatientRecord} />
        </Box>
        <Formik
          initialValues={{
            cellPhone: currentPatientRecord?.cell_phone || "",
            email: currentPatientRecord?.email || "",
            username: currentPatientRecord?.username || "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, handleBlur, isSubmitting, isValid, errors }) => (
            <Form>
              <div className={classes.formControl}>
                <CellPhone
                  cellPhone={values.cellPhone}
                  setCellPhone={(value) => setFieldValue("cellPhone", value)}
                  onBlur={handleBlur}
                  error={Boolean(errors?.cellPhone)}
                  helperText={errors?.cellPhone}
                />
              </div>
              <TextField
                label="Email"
                className={classes.textField}
                variant="outlined"
                fullWidth
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              <TextField
                label="Username"
                className={classes.textField}
                variant="outlined"
                fullWidth
                name="username"
                value={values.username}
                onChange={handleChange}
              />
              <Button
                className={classes.saveBtn}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                Save
              </Button>
            </Form>
          )}
        </Formik>
        <UserEvents userId={currentPatientRecord?.id} />
        <UserSessions userId={currentPatientRecord?.id} />
      </DialogContent>
    </Dialog>
  );
};

export default PatientDetailsModal;
