import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Button, Typography } from "@mui/material";
import CONSTANTS, {
  HOSPITAL_HEADER_TITTLE,
  LOGIN_URL,
  URGENT_CARE_HEADER_TITTLE,
} from "../../../views/Mobile/constants";
import useTranslation from "../../../hooks/useTranslation";
import { useStyles } from "../../../styles/Mobile/header.styles";
import logo from "../../../static/images/mobile/mobile_logo.png";
import allLocations from "../../../static/svg/mobile/alllocations.svg";

interface HeaderProps {
  location;
}

const Header = ({ location }: HeaderProps) => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const locate = useLocation();
  const { mobile } = useTranslation();
  const {
    GetCare,
    Locations,
    More,
    Appointments,
    Billing,
    FindHospital,
    FindUrgentCare,
    PatientVisitorServices,
    ParkingAccommodations,
    GoBack,
    AboutAtlanticHealth,
  } = mobile;

  const getHeaderTitle = () => {
    switch (location) {
      case CONSTANTS.CARE:
        return GetCare;
      case CONSTANTS.LOCATIONS:
        return Locations;
      case CONSTANTS.MORE:
        return More;
      case CONSTANTS.APPOINTMENTS:
        return Appointments;
      case CONSTANTS.BILLING:
        return Billing;
      case CONSTANTS.FINDHOSPITAL:
        return FindHospital;
      case CONSTANTS.HOSPITAL:
        return HOSPITAL_HEADER_TITTLE;
      case CONSTANTS.FINDURGENTCARE:
        return FindUrgentCare;
      case CONSTANTS.URGENTCARE:
        return URGENT_CARE_HEADER_TITTLE;
      case CONSTANTS.PATIENTVISITOR:
        return PatientVisitorServices;
      case CONSTANTS.PARKING:
        return ParkingAccommodations;
      case CONSTANTS.ABOUT:
        return AboutAtlanticHealth;
      default:
        return GoBack;
    }
  };

  const headerTitle = getHeaderTitle();

  const handleBack = useCallback(() => {
    if (locate.state?.from) {
      navigate(-1);
      return;
    }
    navigate("/mobile");
  }, [navigate, locate]);

  const handleHomeLogoClick = useCallback(() => {
    window.open(LOGIN_URL, "_blank");
  }, []);

  const handleMapClick = () => {
    navigate(`${CONSTANTS.mobileBaseUrl}${CONSTANTS.MAP}`, {
      state: { from: location },
    });
  };

  return (
    <div
      className={cx(
        classes.headerContainer,
        location !== CONSTANTS.HOME && classes.containerBackgroundBlue,
      )}
      data-cy="header-mobile"
    >
      {location !== CONSTANTS.HOME && (
        <>
          <button
            className={classes.backButton}
            onClick={handleBack}
            type="button"
            data-cy="header-back-button"
            aria-label="Go back"
          >
            <ArrowBack />
          </button>
          <Typography noWrap className={classes.title}>
            {headerTitle}
          </Typography>
        </>
      )}
      {location === CONSTANTS.HOME && (
        <button
          className={classes.homeLogoButton}
          type="button"
          onClick={handleHomeLogoClick}
          data-cy="header-logo-button"
        >
          <img
            title="Atlantic Health System"
            className={classes.logo}
            src={logo}
            alt="Atlantic Health System"
          />
        </button>
      )}
      {location === CONSTANTS.FINDHOSPITAL || location === CONSTANTS.FINDURGENTCARE ? (
        <Button
          onClick={handleMapClick}
          endIcon={<img className={classes.mapButtonIcon} src={allLocations} alt="" />}
          className={classes.mapButtonContainer}
          data-cy="header-map-button"
        >
          <Typography className={classes.mapButtonText}>Map</Typography>
        </Button>
      ) : (
        <div className={classes.viewForSpacing} />
      )}
    </div>
  );
};

export default Header;
