import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "50%",
    marginTop: 20,
  },
  containerNative: {
    width: "50%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginTop: 0,
  },
  tabListNative: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    zIndex: 10,
  },
  CardContainer: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 16,
    paddingRight: 16,
  },
  card: {
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  avatar: {
    // paddingBottom: "0px !important",
    borderRadius: 100,
  },

  descContainer: {
    padding: "0 16px",
  },

  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Roboto Sans, sans-serif",
  },
  root: {
    padding: 16,
    maxWidth: 600,
    margin: "auto",
  },
  rootNative: {
    paddingTop: 55,
  },
  title: {
    fontWeight: 400,
    fontSize: 16,
    LineHeight: 24,
    marginLeft: 16,
    textAlign: "start",
  },
  text: {
    fontWeight: 400,
    fontSize: 14,
    LineHeight: 24,
    marginLeft: 16,
    color: "rgba(0, 0, 0, 0.54)",
    textAlign: "start",
  },
  arrow: {
    marginLeft: "auto",
    color: theme.palette.primary.main,
  },
  arrowLeft: {
    marginRight: 5,
    width: 12,
    height: 12,
    color: "rgba(0, 0, 0, 0.38)",
  },
  arrowRight: {
    marginLeft: 5,
    width: 12,
    height: 12,
    color: "rgba(0, 0, 0, 0.38)",
  },
  button: {
    color: "rgba(0, 0, 0, 0.38)",
  },
}));

export default useStyles;
