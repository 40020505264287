import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DateFns from "@date-io/date-fns";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers";
import PatientSearch, { Patient } from "components/PatientSearch";
import { permissions } from "components/shared/permissions";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const dateFns = new DateFns();
const displayFormatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "short",
  timeStyle: "short",
});

enum CreateAppointmentDialogStates {
  Start,
  PatientSelected,
  DateSelected,
  AppointmentExists,
}
const sts = CreateAppointmentDialogStates;

const CreateAppointmentDialog = (allProps) => {
  const { open, onClose, ...props } = allProps;

  const app = useApp();
  const navigate = useNavigate();

  // explicit state
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>();
  const [showPicker, setShowPicker] = useState(false);
  const [newAppointmentDate, setNewAppointmentDate] = useState<Date | null>(null);
  const [existingAppointment, setExistingAppointment] = useState<{ date: Date; id: number } | null>(
    null,
  );
  const [waiting, setWaiting] = useState(false);

  // state-derived values
  const myState: CreateAppointmentDialogStates = (() => {
    // use this to figure out what instructions to show, and how to move back and forth between states correctly
    if (!!selectedPatient && !!newAppointmentDate && !!existingAppointment) {
      return sts.AppointmentExists;
    }
    if (!!selectedPatient && !!newAppointmentDate && !existingAppointment) {
      return sts.DateSelected;
    }
    if (!!selectedPatient && !newAppointmentDate && !existingAppointment) {
      return sts.PatientSelected;
    }
    return sts.Start;
  })();
  const legendText = (() => {
    if (myState === sts.AppointmentExists) {
      const schDate = displayFormatter.format(existingAppointment!.date);
      const newDate = displayFormatter.format(newAppointmentDate!);
      return (
        <Alert variant="outlined" severity="warning">
          <AlertTitle>Reschedule?</AlertTitle>
          This patient has an appointment scheduled for <strong>{schDate}</strong>. Click again to
          reschedule it for <strong>{newDate}</strong>.
        </Alert>
      );
    }
    if (myState === sts.DateSelected) {
      return "Now hit that big beautiful button ⬇️ ";
    }
    if (myState === sts.PatientSelected) {
      return "⬅️ Pick a time to schedule the appointment";
    }
    return "↖️ First, search for a patient by MRN";
  })();
  const buttonText = (() => {
    if (waiting) {
      return "Please wait...";
    }
    if (myState === sts.AppointmentExists) {
      return "Update Existing Appointment";
    }
    return "Add New Appointment";
  })();
  const readyToSubmit =
    myState === sts.DateSelected || (myState === sts.AppointmentExists && !waiting);

  // handlers
  const handleNewPatient = (newPatient) => {
    setSelectedPatient(newPatient);
    if (myState > sts.PatientSelected) {
      setNewAppointmentDate(null);
      setExistingAppointment(null);
    }
  };
  const handleNewAppointmentDate = (newDate) => {
    setNewAppointmentDate(newDate);
    if (myState > sts.DateSelected) {
      setExistingAppointment(null);
    }
  };
  const handleAddNew = () => {
    if (!newAppointmentDate) {
      return;
    }
    axiosClient
      .post(`/api/s/console/dot-form/check-appointment`, {
        type: "DOT_PHYSICAL",
        locationCode: "Morristown MC",
        locationName: "Atlantic Health Morristown Medical Center",
        date: dateFns.format(newAppointmentDate, "yyyyMMddHHmmss"),
        mrn: selectedPatient!.MRN,
      })
      .then((res) => {
        if (res.data.created) {
          // new appointment created
          navigate(
            `/console/${permissions.dotForm}/dot-form-staff/submission/${res.data.appointment.formId}`,
          );
        } else if (res.data.appointment) {
          // appointment already exists
          const exappt = new Date(res.data.appointment.date);
          setExistingAppointment({ date: exappt, id: res.data.appointment.appointmentId });
          setWaiting(true);
          setTimeout(() => setWaiting(false), 3000);
        }
      })
      .catch((err) => {
        console.error(err);
        app.addError(
          `Error while creating appointment for patient with MRN ${selectedPatient?.MRN}`,
        );
      });
  };
  const handleUpdateExisting = () => {
    axiosClient
      .post(`/api/s/console/dot-form/update-appointment/${existingAppointment!.id}`, {
        type: "DOT_PHYSICAL",
        locationCode: "Morristown MC",
        locationName: "Atlantic Health Morristown Medical Center",
        date: dateFns.format(newAppointmentDate!, "yyyyMMddHHmmss"),
        status: "Sch",
      })
      .then((res) => {
        if (res.data.formId) {
          navigate(`/console/${permissions.dotForm}/dot-form-staff/submission/${res.data.formId}`);
        }
      })
      .catch((err) => {
        console.error(err);
        app.addError(
          `Error while updating appointment for patient with MRN ${selectedPatient?.MRN}`,
        );
      });
  };
  const handleSubmit = (() => {
    if (myState === sts.AppointmentExists) {
      return handleUpdateExisting;
    }
    return handleAddNew;
  })();
  const handleClose = (...params) => {
    setSelectedPatient(undefined);
    setNewAppointmentDate(null);
    setExistingAppointment(null);
    setShowPicker(false);
    setWaiting(false);
    onClose(...params);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" {...props}>
      <DialogTitle>Add New Appointment for DOT Certification</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PatientSearch onChange={handleNewPatient} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} id="new-appointment-date-picker">
            <DateTimePicker
              format="MM/dd/yyyy hh:mm"
              views={["day", "hours", "minutes"]}
              openTo="day"
              disablePast
              open={showPicker}
              disabled={!selectedPatient}
              onOpen={() => setShowPicker(true)}
              onClose={() => setShowPicker(false)}
              onChange={(d) => handleNewAppointmentDate(d) /* keyboard input */}
              onAccept={(d) => handleNewAppointmentDate(d) /* popup picker input */}
              value={newAppointmentDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  helperText: "MM/DD/YYYY 11:59",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">{legendText}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!readyToSubmit}
          data-cy="createNewAppointmentBtn"
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAppointmentDialog;
