import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "3em",
    width: "100%",
  },
  wrapper: {
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
    padding: "1em",
  },
  tabsBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "#043070",
  },
  tabPanel: {
    height: "auto !important",
    overflow: "auto !important",

    "& > div": {
      padding: 0,
    },
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    background: "transparent",
  },
  table: {
    minWidth: 750,
  },
  tableBody: {
    "& > :last-child": {
      display: "none",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  listbox: {
    padding: 0,
    margin: 0,

    "& > li": {
      padding: 0,
    },
  },

  /* TableToolbar */

  rootTableToolbar: {
    padding: "1.5em 0 0 0 !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
    minHeight: "194px",
  },
  title: {
    flex: "1 1 100%",
  },
  searchBar_wrapper: {
    display: "flex",
    alignItems: "end",
    flexDirection: "row",
    width: "100%",
  },
  searchBy_wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  searchBy_select: {
    textAlign: "left",
    backgroundColor: "#e0e0e06e",
    borderRadius: "4px 0 0 4px",
    borderRight: "none",
    "& fieldset": { borderRight: "none" },
    "&.Mui-focused": {
      outline: "none",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      outline: "none",
      border: "1px solid gray !important",
      borderRight: "none !important",
      borderBottom: "2px solid #043070 !important",
    },
  },
  textField_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    width: "100%",
    maxWidth: "400px",
    position: "relative",
  },
  textField_searchText: {
    borderRadius: "0 4px 4px 0",
    borderLeft: "none",
    "& fieldset": { borderLeft: "none", borderRadius: "0 4px 4px 0" },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid gray",
          borderLeft: "none",
          borderBottom: "2px solid #043070",
        },
      },
    },
  },
  clearIcon: {
    position: "absolute",
    right: "3.5em",
  },
  actionButtons_wrapper: {
    width: "100%",
    background: "rgba(4, 48, 112, 0.08)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    padding: "8px 16px",
    height: "56px",
  },
}));
