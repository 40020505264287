/* eslint-disable */
import React from "react";
import VideoDecode from "../VideoDecode/VideoDecode";
import "../dbr";
// import side effects. The license, engineResourcePath, so on.
import { BarcodeReader } from "dynamsoft-javascript-barcode";
import "./DynamoScanner.css";

interface isState {
  bShowScanner: boolean;
  bShowImgDecode: boolean;
}

type MyProps = {
  // using `interface` is also ok
  onSuccessRead: (text: string) => void;
};

class DynamoScanner extends React.Component<MyProps, isState> {
  _isLoaded: boolean;
  state = {
    bShowScanner: true,
    bShowImgDecode: false,
  };

  constructor(props) {
    super(props);
    this.onSuccessRead = this.onSuccessRead.bind(this);
    this._isLoaded = false;
  }

  async componentDidMount() {
    try {
      await BarcodeReader.loadWasm();
      this._isLoaded = true;
    } catch (ex: any) {
      if (ex.message.indexOf("network connection error")) {
        let customMsg =
          "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
        console.log(customMsg);
        if (this._isLoaded) {
          alert(customMsg);
        }
      }
      throw ex;
    }
  }

  async componentWillUnmount() {
    this._isLoaded = false;
  }

  showScanner = () => {
    this.setState({
      bShowScanner: true,
      bShowImgDecode: false,
    });
  };

  showImgDecode = () => {
    this.setState({
      bShowScanner: false,
      bShowImgDecode: true,
    });
  };

  onSuccessRead = (text: string) => {
    this.props.onSuccessRead(text);
  };

  render() {
    return (
      <div className="container" data-testid="DynamoScannerDiv">
        <VideoDecode onSuccessRead={this.onSuccessRead}></VideoDecode>
      </div>
    );
  }
}
export default DynamoScanner;
