import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { permissions } from "components/shared/permissions";
import { getStepNumber } from "./utils";
import { axiosClient } from "util/api_helper";

const DOTInitializerProvider = () => {
  const { formId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getDriverData = async () => {
      try {
        const {
          data: { demographicInfo, currentStep },
        } = await axiosClient.get(`api/s/dot-form/${formId}`);

        if (!demographicInfo.driverLicense) {
          navigate(`/dot-form/license-verification/${formId}`, { replace: true });
          return;
        }

        let stepsUrl = `/${permissions.patient}/dot-form/form/${formId}`;
        if (currentStep) {
          // if the user finished the first step, when the user user comes back to the form, it will redirect to the next step
          const getStepIndex = getStepNumber(currentStep);
          stepsUrl = `${stepsUrl}/${getStepIndex}`;
        }

        navigate(stepsUrl, { replace: true });
      } catch (error) {
        console.log(error);
        return null;
      }
    };
    getDriverData();
  }, [formId, navigate]);

  return null;
};

export default DOTInitializerProvider;
