import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useApp } from "../../../../util/AppContext";
import { API_ENDPOINTS, POST_OPTIONS, getApiHost } from "../../../../util/api_helper";

type TEndStaffMessageProps = {
  virtual_session_id: number;
  msg: string;
  url: string;
};
const sendStaffMessage = ({ virtual_session_id, msg, url }: TEndStaffMessageProps) => {
  return fetch(url, {
    ...(POST_OPTIONS as RequestInit),
    body: JSON.stringify({
      virtual_session_id,
      msg,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Failed to send staff message");
    }
    return response;
  });
};
export const useSendStaffMessage = () => {
  const apiHost = getApiHost();
  const app = useApp();
  const url = `${apiHost}${API_ENDPOINTS.send_staff_message}`;
  let { vsId } = useParams<{ vsId: string }>();
  const currentVirtualVisit = app?.vs_visits_state?.currentVirtualVisit;
  if (!vsId && currentVirtualVisit) {
    vsId = currentVirtualVisit?.id;
  }

  return useMutation(
    ({ msg }: { msg: string }) =>
      sendStaffMessage({
        virtual_session_id: Number(vsId),
        url,
        msg,
      }),
    {
      onError: (error: Error) => {
        app.addError(error.message);
      },
      onSuccess: async (response) => {
        try {
          const resp = await response.json();
          if (resp?.message?.includes("error")) {
            throw new Error(resp.message);
          }
        } catch (error) {
          throw new Error("Failed to send staff message");
        }
      },
    },
  );
};
