import React from "react";
import Theme from "Theme";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import VaccineIconImg from "../../static/images/ahs-flag-symbol.jpg";
import Header from "./Header";
import TimeLine from "./Timeline";
import "../../App.css";
import { useStyles } from "./styles";

export default function ProofOfVaccination({ vaccines, hideImage, showQR, altQRCodeURL }) {
  const { classes } = useStyles();
  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Box boxShadow={4} bgcolor="transparent" m={1} p={1} className={classes.box}>
          <Card className={classes.root} elevation={0}>
            <CardActionArea>
              <Header />
              {!hideImage && (
                <CardMedia
                  className={`${classes.cardMain} ${classes.media} ${"small-image"}`}
                  image={VaccineIconImg}
                  title="Vaccines Administered"
                />
              )}
              {vaccines && vaccines.length ? (
                <CardContent>
                  <Typography style={{ color: Theme.palette.common.white, fontSize: "18pt" }}>
                    {`${vaccines[0].patient.name[0].given[0]} ${vaccines[0].patient.name[0].family}`}
                    <br />
                  </Typography>
                  <TimeLine vaccines={vaccines} showQR={showQR} altQRCodeURL={altQRCodeURL} />
                </CardContent>
              ) : (
                <Typography variant="h6" style={{ color: Theme.palette.common.white }}>
                  You have no existing vaccine records we could find from Atlantic Health's system
                  or the New Jersey Immunization Registry.
                </Typography>
              )}
            </CardActionArea>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <CardContent>
          <Typography className="flex-center">
            <Link
              href="/patient-selection?r=vaccinecard"
              target="_blank"
              style={{ margin: 0, fontSize: "12pt" }}
              underline="hover"
            >
              View Digital Vaccine Card for other linked Family Members
            </Link>
          </Typography>
        </CardContent>
      </Grid>
    </Grid>
  );
}
