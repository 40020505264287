import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

export const useChatWindowStyles = makeStyles()((theme: Theme) => ({
  chatWindowContainer: {
    background: theme.palette.common.white,
    zIndex: 9,
    display: "flex",
    flexDirection: "column",
    borderLeft: "1px solid #E4E7E9",
    height: `calc(100vh - ${theme.headerHeight})`,
    boxSizing: "border-box",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      top: 67,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 100,
    },
  },
  chatWindowContainerRoom: {
    height: "calc(100vh - 143px)",
  },
  chatWindowContainerNativeApp: {
    height: "100vh",
    top: 0,
    left: "-3px",
  },
  hide: {
    display: "none",
  },
  labelRoot: { marginLeft: 15 },
  label: {
    fontSize: 13,
    fontFamily: "Roboto",
  },
  loaderDiv: {
    display: "flex",
    height: "80vh",
    justifyContent: "center",
    alignItems: "center",
  },
}));
