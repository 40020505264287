import React, { useState } from "react";
import { FilterList } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import EnhancedTableDateFilters from "components/GenericEnhancedTable/components/EnhancedTableDateFilters";
import {
  TOOLBAR_BUTTON_NAMES,
  TOOLBAR_ITEM_TYPE,
} from "components/GenericEnhancedTable/components/EnhancedTableToolbar";
import { useGenericEnhancedTableContext } from "hooks/useGenericEnhanceTableContext";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  filterButton: {
    position: "absolute",
    left: "4px",
    fontSize: "16px!important",
    padding: "4px 12px",
  },
}));

export const SendSurveyList = () => {
  const { addError, addInfoMsg } = useApp();
  const { classes, cx } = useStyles();
  const { sourceData } = useGenericEnhancedTableContext();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [tableUrl, setTableUrl] = useState("api/s/console/provider-survey");
  const [showDateFilters, setShowDateFilters] = useState(false);

  const colDefs = [
    {
      id: "NPI",
      numeric: false,
      disablePadding: true,
      label: "NPI",
      searchable: true,
      sortable: true,
    },
    {
      id: "firstName",
      numeric: false,
      disablePadding: true,
      label: "First Name",
      searchable: true,
      sortable: true,
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: true,
      label: "Last Name",
      searchable: true,
      sortable: true,
    },
    {
      id: "cellphone",
      numeric: false,
      disablePadding: true,
      label: "Phone",
      searchable: true,
      sortable: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      searchable: true,
      sortable: true,
      customInput: {
        type: "chipDropdown",
        options: ["New", "Sent", "Engaged", "Completed", "Error", "Expired"],
      },
      showTooltipOnError: true,
    },
    {
      id: "departmentName",
      isString: true,
      numeric: false,
      disablePadding: true,
      label: "Departments",
      searchable: false,
      sortable: true,
    },
    {
      id: "providerSpecialty",
      numeric: false,
      disablePadding: true,
      label: "Specialty",
      searchable: false,
      sortable: true,
    },
    {
      id: "onCallStartTime",
      numeric: false,
      isDate: true,
      disablePadding: true,
      label: "On Call start",
      searchable: false,
      sortable: true,
    },
    {
      id: "onCallEndTime",
      numeric: false,
      isDate: true,
      disablePadding: true,
      label: "On Call end",
      searchable: false,
      sortable: true,
    },
    {
      id: "outreachedAt",
      numeric: false,
      isDate: true,
      disablePadding: true,
      label: "Out Reached At",
      searchable: false,
      sortable: true,
    },
    {
      id: "engagedAt",
      isDate: true,
      numeric: false,
      disablePadding: true,
      label: "Engaged At",
      searchable: false,
      sortable: true,
    },
    {
      id: "submittedAt",
      numeric: false,
      isDate: true,
      disablePadding: true,
      label: "Submitted At",
      searchable: false,
      sortable: true,
    },
    {
      id: "unsubscribed",
      numeric: false,
      disablePadding: true,
      label: "Unsubscribed",
      searchable: false,
      sortable: true,
    },
    {
      id: "declined",
      numeric: false,
      disablePadding: true,
      label: "Declined",
      searchable: false,
      sortable: true,
    },
    {
      id: "viewurl",
      numeric: false,
      disablePadding: false,
      label: "Form",
      isDoctorFormSubmission: true,
      linkText: "View Form",
      searchable: false,
    },
  ];

  const columnFilterOptions = [
    {
      name: "On Call start",
      value: "onCallStartTime",
    },
    {
      name: "On Call end",
      value: "onCallEndTime",
    },
    {
      name: "Out Reached At",
      value: "outreachedAt",
    },
    {
      name: "Engaged At",
      value: "engagedAt",
    },
    {
      name: "Submitted At",
      value: "submittedAt",
    },
  ];

  const titleButtons = [
    {
      name: "Filter",
      action: () => setShowDateFilters(!showDateFilters),
      icon: <FilterList style={{ color: "#fff", width: 25, height: 25 }} />,
      dataCy: "filterCampaignsBtn",
      className: classes.filterButton,
    },
  ];

  const handleSendBulkInvite = async (fsIds) => {
    const selectedPhones = sourceData
      .filter((item) => fsIds.includes(item.id))
      .map(({ id, providerCellphoneId }) => ({ id, providerCellphoneId }));

    try {
      await axiosClient.post("api/s/console/provider-survey/send-sms", selectedPhones);
      addInfoMsg("Success sending sms to all recipients");
    } catch (e) {
      addError("Failed to send sms, please try again.");
    }
  };

  const changeStatus = async (selectedIds, status) => {
    const selectedPhones = sourceData
      .filter((item) => selectedIds.includes(item.id))
      .map(({ id }) => ({ id, status }));

    try {
      await axiosClient.post("api/s/console/provider-survey/change-status", selectedPhones);
      setShouldRefresh(!shouldRefresh);
      addInfoMsg("Success updating the selected providers");
    } catch (e) {
      addError("Failed to update the selected providers, please try again.");
    }
  };

  const toolbarButtons = [
    {
      name: "Mark as complete",
      action: (selectedIds) => changeStatus(selectedIds, "Completed"),
      type: TOOLBAR_ITEM_TYPE.button,
      className: cx(classes.statusButton, classes.completeStatusButton),
      dataCy: "SendSurveysMarkAsComplete",
    },
    {
      name: "Mark as in progress",
      action: (selectedIds) => changeStatus(selectedIds, "Sent"),
      type: TOOLBAR_ITEM_TYPE.button,
      className: cx(classes.statusButton, classes.inProgressStatusButton),
      dataCy: "SendSurveysMarkAsInProgress",
    },
    {
      name: "Mark as new",
      action: (selectedIds) => changeStatus(selectedIds, "New"),
      type: TOOLBAR_ITEM_TYPE.button,
      className: cx(classes.statusButton, classes.newStatusButton),
      dataCy: "SendSurveysMarkAsNew",
    },
    {
      name: TOOLBAR_BUTTON_NAMES.sendSMS,
      action: (selectedItems) => handleSendBulkInvite(selectedItems),
      type: TOOLBAR_ITEM_TYPE.icon,
      dataCy: "SendSurveysSendSMS",
    },
  ];

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container className={classes.root} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <GenericEnhancedTable
            gridConfig={{ ...defaultGridConfig, allowOverwrite: true, showActionColumn: false }}
            gridType="Available"
            tableTitle="Send Survey List"
            colDefs={colDefs}
            url={tableUrl}
            orderBy="NPI"
            order="desc"
            toolbarButtons={toolbarButtons}
            refreshSourceData={shouldRefresh}
            titleButtons={titleButtons}
            showRowLengthId="departmentName"
            customFilter={
              <EnhancedTableDateFilters
                columnOptions={columnFilterOptions}
                setTableUrl={setTableUrl}
                apiEndpoint="api/s/console/provider-survey"
                show={showDateFilters}
                handleClose={() => setShowDateFilters(false)}
              />
            }
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default SendSurveyList;
