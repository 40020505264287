import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { ProviderData } from "views/DataCuration/ProviderProfile/types";

interface ProviderSearchResponse {
  data: ProviderData[];
  status?: "error" | "success";
}

function useDebouncedValue(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

async function searchProviders(searchText: string): Promise<ProviderSearchResponse> {
  const response = await axiosClient.get(API_ENDPOINTS.dataCurationProviders, {
    params: {
      search_by: "lastName",
      search_text: searchText,
    },
  });
  if (response?.data?.status === "error") {
    throw new Error("Error fetching providers");
  }
  return response?.data;
}

const useSearchProviders = (searchText: string) => {
  const debouncedText = useDebouncedValue(searchText, 250);
  return useQuery({
    queryKey: ["searchProviders", debouncedText],
    queryFn: () => searchProviders(debouncedText),
    enabled: searchText.length >= 2,
  });
};

export { useSearchProviders };
