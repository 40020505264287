import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ isAdhoc: boolean }>()((_theme, props) => ({
  button: {
    width: props.isAdhoc ? "100%" : "47%",
    height: "42px",
    textTransform: "uppercase",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  checkBoxText: {
    fontSize: "16px",
    textAlign: "left",
  },
  bottomNav: {
    position: "sticky",
    bottom: "0px",
    backgroundColor: "#fff",
    paddingTop: "12px",
  },
}));
