/** @format */
import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  rootDiv: {
    padding: "5px 30px",
    fontFamily: "'Times New Roman', Times, serif",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  root: {
    textDecoration: "underline",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "5px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  para: {
    fontSize: "14px",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      textAlign: "justify",
      "& ul": {
        paddingLeft: "30px",
      },
    },
  },
}));

const ClosedCaptionAck = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.rootDiv}>
      <Typography variant="h6" className={classes.root}>
        DOWNTIME DISCLOSURE FOR THE USE OF CLOSED CAPTIONING DURING YOUR TELEHEALTH VISIT
      </Typography>
      <Typography component="p" className={classes.para}>
        <br />
        In addition to other auxiliary aids and services already available to accommodate its deaf
        and hard of hearing patients, Atlantic Health System and its affiliated and related entities
        (“AHS”) have enabled automated closed captioning (also known as live transcription) for
        telehealth visits conducted via video conferencing. The video conferencing platform has
        advised that the accuracy of live transcription cannot be guaranteed and depends on several
        variables, including:
        <br />
        - Background noise
        <br />
        - The volume and clarity of the speaker’s voice
        <br />
        - The speaker’s proficiency with the English language
        <br />
        - Lexicons and dialects specific to a geography or community
        <br />
        <br />
        Accordingly, AHS makes no representations, and cannot guarantee that the information
        conveyed via automated closed captioning is error-free, accurate and complete. However, AHS
        has established guidelines for the use of automated closed captioning function during video
        telehealth visits to help promote effective communication between health care providers and
        their patients.
        <br />
      </Typography>
      <Typography component="p" className={classes.para}>
        <br />
        <b>Prior to Your Visit</b>
        <br />
        You should advise your health care provider prior to the video telehealth visit that closed
        captioning is needed for the visit so that AHS can ensure that closed captioning is enabled
        and that the health care provider and/or the health care provider’s staff are prepared for
        your visit. Of course, if you have experience with an alternative auxiliary aid or service
        that you believe will help to achieve effective communication, you should inform your health
        care provider prior to your visit.
        <br />
        <br />
        Before your visit, your health care provider will provide you with a link and instructions
        on how to access the platform for your visit. Be sure to find a quiet, private place to
        conduct your visit to minimize background and other noise.
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <br />
        <b>During the visit</b>
        <br />
        As long as notice has been provided, closed captioning will be enabled by your health care
        provider for your video telehealth visit. During your visit:
        <br />
        All participants should speak slowly and clearly.
        <br />
        <br />
        Your health care provider or a staff member will monitor the live transcription for
        accuracy, particularly withrespect to medical terminology, and provide clarification or
        correction when necessary. AHS asks for your patience in the event your visit takes longer.
        <br />
        <br />
        The Chat feature in the platform will be available to you and your health care provider
        during the telehealth visitas a further means of communication, if needed. For example, your
        health care provider may use the Chat feature to spell out a medical term or to provide
        additional information. Likewise, you can use the Chat feature to ask questions or get
        clarification.
        <br />
        <br />
        You should advise your health care provider if any information provided during your visit is
        not clear.
        <br />
        <br />
        You can access your after-visit summary (AVS) in MyChart which will contain all notes
        pertaining to the visit Above all, you should follow the written instructions, orders and
        prescriptions provided by your health careprovider and consult with your health care
        provider if you have any questions concerning your care or treatment.
        <br />
        <br />
        Information concerning your telehealth visit will be available in AHS’s MyChart Patient
        Portal. For information on MyChart, visit
        https://www.atlantichealth.org/mychart-patient-portal.html.
        <br />
        <br />
        To access your MyChart account, visit:
        https://mychart.atlantichealth.org/MyChart/Authentication/Login
        <br />
        <br />
        By signing below, you acknowledge that you have received, read, and understand this
        “Downtime Disclosure for the Use of Closed Captioning During Your Telehealth Visit.”
        Questions concerning the availability and use of closed captioning, or any other auxiliary
        aids or services, should be directed your health care provider’s office.
        <br />
      </Typography>
    </div>
  );
};

export default ClosedCaptionAck;
