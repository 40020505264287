import React from "react";
import AudioTrack from "../AudioTrack/AudioTrack";
import VideoTrack from "../VideoTrack/VideoTrack";
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  RemoteTrackPublication,
  Track,
} from "twilio-video";
import useTrack from "../../hooks/useTrack/useTrack";
import { IVideoTrack } from "../../types";

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  isLocalParticipant?: boolean;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
}

export default function Publication({
  publication,
  isLocalParticipant,
  videoOnly,
  videoPriority,
}: PublicationProps) {
  const track = useTrack(publication);

  if (!track) {
    return null;
  }

  switch (track.kind) {
    case "video":
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes("camera") && isLocalParticipant}
        />
      );
    case "audio":
      return videoOnly ? null : <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
}
