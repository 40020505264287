import { EdapVisitType, VisitType } from "components/Video/utils/types";

export const getPreselectedVisitType = (names: string[], visitTypes: VisitType[]) => {
  const filteredVisitTypes: VisitType[] = [];

  names.forEach((name) => {
    const visitType = visitTypes.find((item) =>
      item.name.toLowerCase().includes(name.toLowerCase()),
    );
    if (visitType) {
      filteredVisitTypes.push(visitType);
    }
  });

  const preselectedVisitType = filteredVisitTypes?.length ? filteredVisitTypes[0] : undefined;

  return preselectedVisitType;
};

export const getPreselectedEdapVisitType = (
  visitTypes: string[],
  edapVisitTypes: EdapVisitType[],
) => {
  const filteredEdapVisitTypes: EdapVisitType[] = [];

  visitTypes.forEach((name) => {
    const edapVisitType = edapVisitTypes.find((item) =>
      item.visit_type.toLowerCase().includes(name.toLowerCase()),
    );
    if (edapVisitType) {
      filteredEdapVisitTypes.push(edapVisitType);
    }
  });

  const preselectedVisitType = filteredEdapVisitTypes?.length
    ? filteredEdapVisitTypes[0]
    : undefined;

  return preselectedVisitType;
};
