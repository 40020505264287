import { useState } from "react";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

export const useGetSpecimenHistory = () => {
  const app = useApp();

  const [isLoading, setIsLoading] = useState(false);

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const getSpecimenHistory = async (param?: string) => {
    setIsLoading(true);
    try {
      const response = await axiosClient.get(
        param
          ? `${API_ENDPOINTS.specimenTrackingGetHistory}?search_by=specimenId&search_text=${param}`
          : API_ENDPOINTS.specimenTrackingGetHistory,
      );

      return response.data || null;
    } catch (e) {
      app.addError(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getSpecimenHistory,
    isLoading,
  };
};
