/* eslint-disable */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import Message from "./Message";
import useChatContext from "../../components/Video/hooks/useChatContext/useChatContext";
import useVideoContext from "../../components/Video/hooks/useVideoContext/useVideoContext";
import { CONNECT_VIRTUAL_SESSION } from "../../components/Video/hooks/useVirtualVisitReducer/Actions";
import { useConversationStyles } from "./useConversationStyles";
import { useApp } from "../../util/AppContext";
import { CONVERSATION_LINK } from "../../util/Links";
import { getLocalTime } from "../../util/util_functions";

const PatientChatHeader = ({ disabled = false }: { disabled: boolean }) => {
  const { classes } = useConversationStyles();
  const { setIsChatWindowOpen } = useChatContext();
  const navigate = useNavigate();
  const { room } = useVideoContext();
  const {
    dispatch,
    vs_visits_state: { patientVirtualSession },
  } = useApp();
  const message = patientVirtualSession?.vs_message?.message;
  const time = patientVirtualSession?.vs_message?.event_dttm;
  const staff_user = patientVirtualSession?.staff_user;
  const author_name = staff_user?.first_name + staff_user?.last_name;
  const authorStr = staff_user?.first_name ? `${author_name} - ` : "";
  const program = patientVirtualSession?.program || "";
  const sentByString = [`${authorStr}${program?.name}`, getLocalTime(time)]
    .filter(Boolean)
    .join(" • ");

  const handleClick = () => {
    setIsChatWindowOpen(false);
    room?.disconnect();
    dispatch({
      type: CONNECT_VIRTUAL_SESSION,
      payload: null,
    });
    navigate(CONVERSATION_LINK);
  };

  useEffect(() => {
    if (room) {
      setIsChatWindowOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.header}>
        <IconButton
          aria-label="Go back to chats"
          className={classes.arrow}
          onClick={handleClick}
          size="large"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <span className={classes.title}>Chat with our Care Team</span>
      </div>
      {!disabled && message && <Message sentByString={sentByString} message={message} />}
    </>
  );
};

export default PatientChatHeader;
