import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import { useStyles } from "../../../utils/styles";

const EducationSection: React.FC = () => {
  const { classes } = useStyles();

  const {
    providerExperience: { education },
  } = useProviderContext();

  return (
    <Grid padding="24px 0">
      {education?.length ? (
        <>
          {education.map(({ id, institution, educationType, otherEducationDesc, details }) => {
            return (
              <Card
                key={id}
                className={classes.practiceLocation_cardRoot}
                data-testid="practiceLocation-card"
              >
                <CardContent>
                  <Typography textAlign="left" paddingBottom="8px" style={{ fontWeight: "700" }}>
                    {institution?.name}
                  </Typography>
                  {/* If the educationType is "Other", we want to show whatever's on the `otherEducationDesc` field (which is null otherwise) */}
                  <Typography textAlign="left">{otherEducationDesc || educationType}</Typography>
                  <Typography textAlign="left">{details?.fieldOfStudy}</Typography>
                  <Typography textAlign="left">{details?.degree}</Typography>
                  <Typography textAlign="left">{details?.year}</Typography>
                </CardContent>
              </Card>
            );
          })}
        </>
      ) : (
        <Typography textAlign="left" color="textSecondary">
          No education found
        </Typography>
      )}
    </Grid>
  );
};

export default EducationSection;
