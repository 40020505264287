import React from "react";
import { Info, OpenInNew } from "@mui/icons-material";
import { Button, Switch, TableCell, Tooltip } from "@mui/material";
import { getFormattedDateTableCell, getFormattedTableCell } from "../helpers";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { useStyles } from "../styles";

const MILLISECONDS_IN_A_MINUTE = 60000;

interface Props {
  colDefs;
  row;
  showRowLengthId?: string;
  rowToId: (row) => number;
}

const EnhancedTableColDefs = ({ colDefs, row, showRowLengthId, rowToId }: Props) => {
  /* @ts-ignore-next-line */
  const { classes } = useStyles({});

  return (
    <>
      {colDefs.map((column) => {
        if (column.id === "img") {
          return (
            <TableCell key={column.id} style={{ cursor: "default" }}>
              <a href={row.img} target="_blank" rel="noreferrer">
                <Button variant="contained" color="primary" size="small" endIcon={<OpenInNew />}>
                  Image
                </Button>
              </a>
            </TableCell>
          );
        }
        if (column.id === "phone") {
          return (
            <TableCell key={column.id} style={{ cursor: "default", minWidth: "9em" }}>
              {row.phone ? row.phone : "–"}
            </TableCell>
          );
        }
        if (column.id === "parkingUrl") {
          return (
            <TableCell key={column.id} style={{ cursor: "default" }}>
              <a href={row.parkingUrl} target="_blank" rel="noreferrer">
                <Button variant="contained" color="primary" size="small" endIcon={<OpenInNew />}>
                  Parking
                </Button>
              </a>
            </TableCell>
          );
        }
        if (column.id === "directions") {
          return (
            <TableCell key={column.id} style={{ cursor: "default" }}>
              <a href={row.directions} target="_blank" rel="noreferrer">
                <Button variant="contained" color="primary" size="small" endIcon={<OpenInNew />}>
                  Direction
                </Button>
              </a>
            </TableCell>
          );
        }
        if (column.id === "conditions") {
          const condition = JSON.parse(row.conditions);
          return (
            <TableCell key={column.id} style={{ cursor: "default", minWidth: "20em" }}>
              {condition
                ? condition.map(({ title }) => {
                    return <li key={title}>{title}</li>;
                  })
                : "–"}
            </TableCell>
          );
        }
        if (column.id === "specimenStatus") {
          return (
            <TableCell key={column.id} style={{ cursor: "default" }}>
              <span
                style={{
                  color: row[column.id] === "OVERDUE" ? "red" : "black",
                  fontWeight: "bold",
                }}
              >
                {row[column.id] === "ON_TIME" ? "On Time" : row[column.id]}
              </span>
            </TableCell>
          );
        }
        if (column.id === "lastCommsSent") {
          if (row.lastCommsSent) {
            const utcDate = new Date(row.lastCommsSent);
            const localDate = new Date(
              utcDate.getTime() - utcDate.getTimezoneOffset() * MILLISECONDS_IN_A_MINUTE,
            );
            const lastCommns = formatDistanceToNow(localDate, { addSuffix: true });

            return (
              <TableCell
                key={column.id}
                style={{ cursor: "default", minWidth: "11em", position: "relative" }}
              >
                {row.lastCommsSent ? (
                  <div className={classes.commnsSentNotification}>
                    {lastCommns}
                    <span
                      style={{
                        background: row.lastCommsStatus === "failed" ? "#dc3545" : "#043070",
                      }}
                    >
                      {row.commsHistory.length}
                    </span>
                    <div className={classes.commsHistoryWrapper}>
                      {row.commsHistory.slice(0, 5).map((message: string) => (
                        <p key={message}>{format(parseISO(message), "MMM d, yyyy")}</p>
                      ))}
                    </div>
                  </div>
                ) : (
                  "–"
                )}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={column.id}
              style={{ cursor: "default", minWidth: "11em", position: "relative" }}
            >
              –
            </TableCell>
          );
        }
        if (column.id === showRowLengthId) {
          const rowData = row[showRowLengthId!];
          const showRowLength = rowData?.length > 1;
          const getRowName = () => {
            if (showRowLength) {
              return rowData?.length;
            }
            return rowData;
          };

          return (
            <Tooltip
              title={rowData.join(", ")}
              classes={{
                tooltip: showRowLength ? classes.customTooltip : classes.hideCustomTooltip,
              }}
            >
              <TableCell key={column.id} style={{ cursor: "default" }}>
                <span>{getRowName()}</span>
              </TableCell>
            </Tooltip>
          );
        }
        if (column.isLink) {
          return (
            <TableCell key={column.id}>
              <a
                href={column.href + column.func(row[column.id], row)}
                target={column.target ?? "_blank"}
                rel="noreferrer"
              >
                {column.linkText ?? row[column.id]}
              </a>
            </TableCell>
          );
        }
        if (column.isFormSubmission) {
          if (row?.status === "New") {
            return (
              <TableCell key={column.id}>
                <span>NA</span>
              </TableCell>
            );
          }
          return row?.short_link_status === "expired" && row?.status !== "Completed" ? (
            <TableCell key={column.id}>
              <Tooltip title="The survey link has expired,  Update the status to New and re-send the survey">
                <span style={{ color: "#EB8025", fontWeight: 400 }}>Campaign Link Expired</span>
              </Tooltip>
            </TableCell>
          ) : (
            <TableCell key={column.id}>
              {row?.submitted_dttm ? (
                <a
                  href={
                    row?.url ||
                    `${window.location.origin}/forms/${
                      row.form_external_tracking_id
                    }/submissions/${rowToId(row)}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {column?.linkText}
                </a>
              ) : (
                "NA"
              )}
            </TableCell>
          );
        }
        if (column.isDoctorFormSubmission) {
          if (row?.status === "New") {
            return (
              <TableCell key={column.id}>
                <span>NA</span>
              </TableCell>
            );
          }
          return row?.shortLinkStatus === "expired" && row?.status !== "Completed" ? (
            <TableCell key={column.id}>
              <Tooltip title="The survey link has expired,  Update the status to New and re-send the survey">
                <span style={{ color: "#EB8025", fontWeight: 400 }}>Survey Link Expired</span>
              </Tooltip>
            </TableCell>
          ) : (
            <TableCell key={column.id}>
              {row?.formExternalId && row?.submissionId && row?.submittedAt ? (
                <a
                  href={
                    row?.url ||
                    `${window.location.origin}/forms/${row.formExternalId}/submissions/${row.submissionId}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {column?.linkText}
                </a>
              ) : (
                "NA"
              )}
            </TableCell>
          );
        }
        if (column.id === "status") {
          if (column.showTooltipOnError && row.errors) {
            return (
              <TableCell key={column.id}>
                <Tooltip title={row.errors}>
                  <span style={{ color: "red" }}>{getFormattedTableCell(row[column.id])}</span>
                </Tooltip>
              </TableCell>
            );
          }
          if (row[column.id] === "Completed") {
            return <TableCell key={column.id}>Completed</TableCell>;
          }
          if (
            row?.shortLinkStatus === "expired" ||
            row?.short_link_status === "expired" ||
            row[column.id] === "Expired"
          ) {
            return (
              <TableCell key={column.id}>
                <span style={{ color: "#EB8025" }}>Expired</span>
              </TableCell>
            );
          }
        }
        if (
          column.id === "formStatus" &&
          column.showTooltipOnError &&
          (row.smsErrorMsg || row.emailErrorMsg)
        ) {
          // when smsErrorMsg and emailErrorMsg are present, show both in the tooltip
          // when smsErrorMsg has HTTP error, show only the HTTP error
          const text = (
            <div>
              {row.smsErrorMsg && (
                <div>
                  {row.smsErrorMsg.indexOf("Error: HTTP") > 0
                    ? row.smsErrorMsg.slice(row.smsErrorMsg.indexOf("Error: HTTP"))
                    : row.smsErrorMsg}
                </div>
              )}
              {row.emailErrorMsg && (
                <>
                  <br /> <div>{row.emailErrorMsg}</div>
                </>
              )}
            </div>
          );
          return (
            <TableCell key={column.id}>
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    color: row.smsErrorMsg && row.emailErrorMsg ? "red" : "#EB8025",
                    fontWeight: "bold",
                  }}
                >
                  {getFormattedTableCell(row[column.id])}
                </span>
                <Tooltip title={text}>
                  <Info
                    style={{
                      color: row.smsErrorMsg && row.emailErrorMsg ? "red" : "#EB8025",
                      fontSize: 15,
                      marginTop: "2px",
                    }}
                  />
                </Tooltip>
              </div>
            </TableCell>
          );
        }
        if (column.isBoolean) {
          return (
            <TableCell key={column.id}>
              <Switch checked={row[column.id]} disabled={!row[column.id]} disableRipple />
            </TableCell>
          );
        }
        if (column.isString) {
          return <TableCell key={column.id}>{row[column.id]}</TableCell>;
        }

        if (column?.isDate) {
          return <TableCell key={column.id}>{getFormattedDateTableCell(row[column.id])}</TableCell>;
        }

        return (
          <TableCell
            key={column.id}
            style={{ minWidth: column.minWidth ? column.minWidth : "auto" }}
          >
            {column.isCustomContent
              ? column.func(row[column.id], row)
              : getFormattedTableCell(row[column.id])}
          </TableCell>
        );
      })}
    </>
  );
};

export default EnhancedTableColDefs;
