import React from "react";
import { Typography } from "@mui/material";
import { useConversationStyles } from "./useConversationStyles";

const NoConversations = () => {
  const { classes } = useConversationStyles();
  return (
    <div className={classes.noConversation}>
      <Typography className={classes.noConversationTitle}>You don’t have any messages!</Typography>
      <Typography className={classes.noConversationText}>
        There are no messages to display at the moment. Messages include appointment requests, visit
        notes, and various care team chats.{" "}
      </Typography>
    </div>
  );
};

export default NoConversations;
