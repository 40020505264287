import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  mainTitle: {
    fontWeight: 500,
    fontSize: "28px",
    textAlign: "center",
    letterSpacing: "0.25px",
    marginBottom: "20px",
  },
  mainSubtitle: {
    fontSize: "22px",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "10px",
  },
  formControl: {
    marginTop: "10px",
    minWidth: 260,
    textAlign: "left",
  },
  label: {
    backgroundColor: "white",
  },
  reasonSelect: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
  radioFormControl: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  inputRoot: {
    borderRadius: "4px",
    height: "56px",
  },
  helperText: {
    margin: "5px 0px 10px 0px",
  },
  button: {
    width: "100%",
    textTransform: "uppercase",
    margin: "20px 0",
    height: "46px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));
