import React, { useState } from "react";
import { Grid } from "@mui/material";
import EditableComponent from "../../../EditableComponent";
import CertificationSection from "./CertificationSection";
import EducationEditModal from "./EducationEditModal";
import EducationSection from "./EducationSection";
import GenericModal from "views/DataCuration/common/GenericModal";
import { ModalComponent } from "views/DataCuration/utils/commontTypes";
import { EditableComponentProps } from "../../types";

const ExperienceTab: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [sectionModalComponentBody, setSectionModalComponentBody] =
    useState<ModalComponent | null>();
  const experienceConfiguration: EditableComponentProps[] = [
    {
      title: "Education",
      slug: "education",
      component: EducationSection,
      data: [
        {
          institution: "University of Colorado",
          description: "General Doctor",
          fieldOfStudy: "General Doctor",
          degree: "General Doctor",
          year: "1990",
        },
        {
          institution: "University of Michigan",
          description: "Physical Therapy Speciality",
          fieldOfStudy: "Physical Therapy",
          degree: "Physical Therapist",
          year: "2000",
        },
      ],
      showActionButton: true,
      modalComponent: {
        title: "Edit Education",
        subtitle: "Modal for editing Education",
        component: <EducationEditModal />,
      },
    },
    {
      title: "Certifications",
      slug: "certifications",
      component: CertificationSection,
      showActionButton: false,
    },
  ];

  const handleEdit = (slug: string) => {
    setOpenModal(true);
    const getModalComponent = experienceConfiguration.find(
      (config) => config.slug === slug,
    )?.modalComponent;
    if (getModalComponent) {
      setSectionModalComponentBody(getModalComponent);
    }
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        {experienceConfiguration.map(({ title, component, slug, showActionButton, data }) => (
          <EditableComponent
            key={slug}
            title={title}
            showActionButton={showActionButton}
            onEdit={() => handleEdit(slug)}
          >
            {component({ shouldDisableForm: false, data })}
          </EditableComponent>
        ))}
      </Grid>
      {sectionModalComponentBody && (
        <GenericModal
          show={openModal}
          title={sectionModalComponentBody.title}
          subtitle={sectionModalComponentBody.subtitle}
          onClose={() => setOpenModal(false)}
          onSave={() => {}}
        >
          {sectionModalComponentBody.component}
        </GenericModal>
      )}
    </>
  );
};

export default ExperienceTab;
