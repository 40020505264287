import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import qString from "query-string";
import useVideoContext from "../components/Video/hooks/useVideoContext/useVideoContext";
import { SESSION_KEYS } from "util/constants/sessionsKeys";
import { useApp } from "../util/AppContext";
import { useAuth } from "../util/Security";

export default function LogoutView() {
  const auth = useAuth();
  const app = useApp();
  const location = useLocation();
  const { removeLocalVideoTrack, removeLocalAudioTrack } = useVideoContext();
  const params = qString.parse(location.search);

  useEffect(() => {
    auth.logout((error) => {
      if (error) {
        app.addError(`Error: Logout attempt failed: ${error}`);
        return;
      }
      // In case the user is in a video call and he logs out it will release the media
      removeLocalVideoTrack();
      removeLocalAudioTrack();

      // Keep msal token in session storage
      const msalToken = sessionStorage.getItem(SESSION_KEYS.MSAL_TOKEN);
      sessionStorage.clear();
      if (msalToken) {
        sessionStorage.setItem(SESSION_KEYS.MSAL_TOKEN, msalToken);
      }

      let redirect = `/login?redirect=${params.redirect}&embedded=${app.isEmbedded()}`;
      const { host } = window.location;
      if (host.startsWith("app")) {
        redirect = "/console/login"; // TODO make more dynamic based on user type/role
      }

      window.location.href = redirect;
    });
    // eslint-disable-next-line
  }, []);

  return <div />;
}
