import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useListStyles } from "./styles";

export default function CheckboxList({ data }) {
  // const data = mockData.data;
  const { classes } = useListStyles();

  return (
    <List className={classes.root}>
      <ListItem className={classes.listItem} key="data.id" role={undefined} dense>
        <ListItemText id={data.id} primary="ID" />
        <ListItemText id={data.user_id} primary={data.user_id} />
      </ListItem>
      {Object.keys(data)
        .map((k) =>
          k
            .split("_")
            .map((a) => a.substr(0, 1).toUpperCase() + a.substr(1, a.length - 1))
            .join(" "),
        )
        .map((keyy) =>
          keyy !== "User Id" ? (
            <ListItem className={classes.listItem} key={keyy} role={undefined} dense>
              <ListItemText id={keyy} primary={keyy} />
              <ListItemIcon>
                {Math.floor(Math.random() * 3) ? (
                  <Checkbox
                    edge="start"
                    checked
                    tabIndex={-1}
                    color="primary"
                    disableRipple
                    checkedIcon={<CheckCircleIcon style={{ color: "green" }} />}
                    inputProps={{ "aria-labelledby": data[keyy] }}
                  />
                ) : null}
              </ListItemIcon>
            </ListItem>
          ) : null,
        )}
    </List>
  );
}
