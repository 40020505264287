import React from "react";
import { Typography } from "@mui/material";
import { useConversationStyles } from "./useConversationStyles";
import { IMessageProps } from "./types";

const Message = ({ message, sentByString }: IMessageProps) => {
  const { classes } = useConversationStyles();
  return (
    <div className={classes.messageContainer}>
      <Typography className={classes.message}>{message}</Typography>
      <Typography className={classes.messageTime}>{sentByString}</Typography>
    </div>
  );
};

export default Message;
