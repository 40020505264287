import { useNavigate } from "react-router-dom";

const RoutesHelper = () => {
  const navigate = useNavigate();

  const removeDuplicateQueryParams = (params: string) => {
    try {
      return params
        .split("&")
        .filter((e, i, a) => a.indexOf(e) === i) // filter duplicates
        .join("&");
    } catch (e) {
      return params;
    }
  };

  const replaceRedirect = (param: string | null | undefined) => {
    const redirect = param?.includes("null")
      ? null
      : param?.includes("undefined")
      ? undefined
      : param;

    return redirect;
  };

  const redirectHelper = (params: URLSearchParams, search?: string, from?: string) => {
    const param = params.get("r") ?? params.get("redirect");
    let redirect = replaceRedirect(param);
    if (redirect && redirect.length > 0) {
      if (!redirect.startsWith("/")) {
        redirect = `/${redirect}`;
      }
      navigate(`${redirect}${search ?? ""}`);
    } else if (from) {
      navigate(from);
    } else {
      navigate("/home");
    }
  };

  return { redirectHelper, replaceRedirect, removeDuplicateQueryParams };
};

export default RoutesHelper;
