import { ButtonProps } from "@mui/material";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SpecimenLocationInfo {
  barcode: string;
  description: string;
  id: number;
  name: string;
  finalDestination: boolean;
  departmentId: string;
  departmentName: string;
}

export interface SpecimenDepartmentInfo {
  department_id: number;
  department_name: string;
  deptType: string;
  shortName: string;
  phoneNumber: string;
  address: string;
  lat?: string;
  lng?: string;
}

export interface SpecimenButtonInterface extends ButtonProps {
  "data-cy": string;
  content: string;
}

export interface FieldInputsInterface {
  id: string;
  label: string;
  disabled: boolean;
  dataCy: string;
  required: boolean;
  rightIcon?: any;
  onClick: () => void;
  autoFocus?: boolean;
  type?: string;
  prefix?: string;
  isDOB?: boolean;
}

export interface SpecimenEmrResponse {
  data: {
    MRN: string;
    OrderNumber: string;
    PatientDOB: string;
    PatientFirstName: string;
    PatientLastName: string;
    SpecimenCount: number;
    id: number;
  };
}
export interface SpecimenAddResponse {
  data: {
    id: number;
    authorizingUser: string;
    collectedDatetime: string;
    description: string;
    orderDob: string;
    orderGender: string;
    orderId: number;
    orderMrn: string;
    orderOrderExternalId: string;
    orderPatientFirstName: string;
    orderPatientLastName: string;
    specimenExternalId: string;
    specimenImage: null | string;
    specimenSource: string;
    specimenType: string;
    status: string;
    patientMatch: boolean;
  };
  error: string;
  message: string;
}

export enum SpecimenTrackAction {
  ADD = "ADD",
  PICK_UP = "PICK_UP",
  DROP_OFF = "DROP_OFF",
}

export enum SpecimenTrackingStatus {
  MANUAL = "MANUAL",
  CONFIRMED = "CONFIRMED",
}

export interface SpecimenConfirmPostData {
  collectedSpecimens: number[];
  collectedAt: number;
  type: SpecimenTrackAction;
}
export interface SpecimenConfirmResponse {
  data: {
    collectedBy: number;
    collectedDatetime: string;
    id: number;
    specimenAuthorizingUser: string;
    specimenCollectedDatetime: string;
    specimenDescription: string;
    specimenId: number;
    specimenOrderDob: string;
    specimenOrderGender: string;
    specimenOrderId: number;
    specimenOrderMrn: string;
    specimenOrderOrderExternalId: string;
    specimenOrderPatientFirstName: string;
    specimenOrderPatientLastName: string;
    specimenSpecimenExternalId: string;
    specimenSpecimenSource: string;
    specimenSpecimenType: string;
    specimenStatus: string;
  };
  error: string;
  message: string;
}

export interface SpecimenInfoScanningPostData {
  specimenExternalId: string;
  collectedDatetime: string;
  collectedAt: number;
  photo: string;
}
export interface SpecimenInfoManuallyPostData {
  order: Order;
  specimenExternalId: string;
  authorizingUser?: string;
  collectedDatetime?: string;
  specimenSource?: string;
  specimenType?: string;
  description?: string;
}

export interface SpecimenInfoManuallyPatchData
  extends Omit<SpecimenInfoManuallyPostData, "order" | "specimenExternalId"> {
  order?: Order;
  specimenExternalId?: string;
}

export interface SpecimenInfoAddNewManuallyData {
  orderExternalId: string;
  specimenExternalId: string;
}
interface Order {
  orderExternalId: string;
  patientFirstName?: string;
  patientLastName?: string;
  dob?: string;
  gender?: string;
  mrn: string;
}

export interface SpecimenTransformedForMobile {
  specimenAuthorizingUser: string;
  collectedDatetime: string;
  specimenDescription: string;
  specimenId: string;
  specimenOrderDob: string;
  specimenOrderGender: string;
  specimenOrderOrderExternalId: string;
  specimenOrderPatientFirstName: string;
  specimenOrderPatientLastName: string;
  specimenSpecimenExternalId: string;
  specimenSpecimenSource: string;
  specimenSpecimenType: string;
  specimenStatus: string;
  collectedAt: number;
  collectedBy: number;
  collectedByFirstName: string;
  collectedByLastName: string;
  type: string;
  collectedAtName: string;
}

export interface SpecimenTransformedDataForMobile {
  collectedDatetime: string;
  specimenOrderId: string;
  specimenOrderExternalId: string;
  specimenOrderMrn: string;
  specimen: SpecimenTransformedForMobile;
  specimenData: SpecimenTransformedForMobile[];
}

export interface TimelineDataSpecimenHistory {
  collectedAt: number;
  collectedBy: number;
  collectedByFirstName: string;
  collectedByLastName: string;
  collectedDatetime: string;
  id: number;
  specimenAuthorizingUser: string;
  specimenCollectedDatetime: string;
  specimenDescription: string;
  specimenId: number;
  specimenOrderDob: string;
  specimenOrderGender: string;
  specimenOrderId: number;
  specimenOrderMrn: string;
  specimenOrderOrderExternalId: string;
  specimenOrderPatientFirstName: string;
  specimenOrderPatientLastName: string;
  specimenSpecimenExternalId: string;
  specimenSpecimenSource: string;
  specimenSpecimenType: string;
  specimenStatus: string;
  type: string;
  specimenImage: string;
  specimenSpecimenImage: string;
  inFinalDestination: boolean;
}

export interface SpecimenAddInitialValues {
  specimenExternalId: string;
  orderOrderExternalId: string;
  orderMrn: string;
  orderPatientFirstName: string;
  orderPatientLastName: string;
  orderDob: null | object | string;
  specimenType: string;
  description: string;
}
export interface UrlParamType {
  department_ids?: string;
  location_ids?: string;
  type?: string;
  status?: string;
  collected_by?: number;
  search_by?: string;
  search_text?: string;
}

export interface SpecimenDetails {
  authorizingUser: string;
  collectedDatetime: string | null;
  collectionsCount?: number;
  current_location?: string;
  description: string;
  id: number;
  image?: string;
  orderDob: string;
  orderGender: string;
  orderId: number;
  orderMrn: string;
  orderOrderExternalId: string;
  orderPatientFirstName: string;
  orderPatientLastName: string;
  orderSpecimenCount?: number;
  specimenExternalId: string;
  specimenSource: string;
  specimenType: string;
  status: string;
}
