import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Typography } from "@mui/material";
import { Props } from "./types";
import { useStyles } from "./style";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  "header",
  "size",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

const RichTextArea = ({
  initialValue = "",
  onChange,
  placeHolder = "",
  readOnly = false,
  onDisableEditButton,
  showCharacterCount = false,
}: Props) => {
  const { classes } = useStyles({ readOnly });

  const quillRef = useRef<ReactQuill | null>(null);
  const [value, setValue] = useState(initialValue);
  const [characterLength, setCharacterLength] = useState(0);

  const handleChange = (content: string) => {
    setValue(content);

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const length = editor.getLength();
      setCharacterLength(length);

      if (onChange) {
        onChange(content);
      }
      if (onDisableEditButton) {
        onDisableEditButton(length > 161);
      }
    }
  };

  useEffect(() => {
    setValue(initialValue);
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      setCharacterLength(editor.getLength());
    }
  }, [initialValue]);

  useEffect(() => {
    if (characterLength === 1) {
      setValue("");
    }
  }, [characterLength]);

  return (
    <>
      <ReactQuill
        ref={quillRef}
        className={classes.app}
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={placeHolder}
        readOnly={readOnly}
      />
      {showCharacterCount && (
        <Typography
          color={characterLength > 161 ? "red" : "gray"}
          className={classes.characterCount}
        >
          {characterLength - 1}/160
        </Typography>
      )}
    </>
  );
};

export default RichTextArea;
