/** @format */

let navTemp = [
  {
    link: "https://findadoctor.atlantichealth.org/search",
    text: "Find a Doctor",
    key: "Find a Doctor",
    global: true,
  },
  {
    link: "https://www.atlantichealth.org/locations.html",
    text: "Locations",
    key: "Locations",
    global: true,
  },
  {
    link: "https://www.atlantichealth.org/contact.html#section2",
    text: "Book an Appointment - Call 1-800-247-9580",
    key: "Contact",
    global: true,
  },
  {
    link: "/conciergechat",
    text: "Chat with Us",
    key: "Chat",
    global: false,
  },
  {
    link: "/patient-selection?r=proofofvaccination",
    text: "Proof of Vaccination",
    key: "proofofvaccination",
    global: false,
  },
  {
    link: "/inquiryrequest",
    text: "Speak with a specialist",
    key: "inquiryrequest",
    global: false,
  },
  {
    link: "/oauth/authtorize/vaccine/CLEAR",
    text: "Authorize Vaccine Share",
    key: "authvaxshare",
    global: false,
  },
  {
    link: "/patient-selection?r=appointments",
    text: "Appointments",
    key: "Appointments",
    global: false,
  },
  {
    link: "/id/start",
    text: "Identity Verification",
    key: "ID Verify",
    global: false,
  },

  {
    link: "/patient-selection?r=video",
    text: "Start Virtual Urgent Care",
    key: "Video",
    global: false,
  },
];

navTemp = navTemp.concat([
  {
    link: "https://www.atlantichealth.org/terms-use.html",
    text: "Terms of Use",
    key: "Terms of Use",
    global: true,
  },
  {
    link: "https://mychart.atlantichealth.org/MyChart/publicforms.asp?mode=showform&formname=CustomerService",
    text: "Help?",
    key: "Tech Support",
    global: true,
    isButton: true,
  },
]);

export const nav = navTemp;
