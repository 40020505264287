import React, { useEffect, useState } from "react";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { useGetSpecimenDepartments } from "hooks/specimenTracking/useGetSpecimenDepartments/useGetSpecimenDepartments";
import ToggleButtonsFilter from "views/SpecimenTracking/components/ReportFilter/ToggleButtonsFilter";
import { SpecimenSelectOptions } from "views/SpecimenTracking/components/SelectOptions";
import { useGetSpecimenLocations } from "hooks/specimenTracking/useGetSpecimenLocations/useGetSpecimenLocations";
import useTranslation from "hooks/useTranslation";
import { SpecimenLocationInfo, UrlParamType } from "views/SpecimenTracking/types";

interface Props {
  handleLocations: (locationId: string) => void;
  handleToggle: (value: string, paramName: keyof UrlParamType) => void;
  handleDepartments: (departmentId: string) => void;
  exportToCSV: () => void;
}

export const ReportByOrderIdFilters = ({
  handleLocations,
  handleToggle,
  handleDepartments,
  exportToCSV,
}: Props) => {
  const [type, setType] = useState("ADD");
  const [status, setStatus] = useState("ALL");
  const [locations, setLocations] = useState<number[]>([]);
  const [formattedLocations, setFormattedLocations] = useState<SpecimenLocationInfo[]>([]);
  const [selectAllLocations, setSelectAllLocations] = useState<number | null>();
  const [departments, setDepartments] = useState<string[]>([]);

  const {
    web: {
      specimenTrackingReport: { exportCSV },
      specimenReportOrderId: { allLocations, allDepartments },
    },
  } = useTranslation();
  const { specimenLocationList: dataLocations } = useGetSpecimenLocations();
  const { data: dataDepartments } = useGetSpecimenDepartments();
  const MMCPathologyGrossRoom = "MMC Pathology Gross Room/Pathology Drop Off";
  const MMCORSpecimenRoomDropOff = "MMC OR Specimen Room Drop Off";
  const AllMMCORsLocations = "All MMC ORs";

  useEffect(() => {
    if (dataLocations) {
      const filteredLocations = dataLocations.filter((item) => {
        return item.name !== MMCPathologyGrossRoom && item.name !== MMCORSpecimenRoomDropOff;
      });
      setFormattedLocations(filteredLocations);
    }
  }, [dataLocations]);

  const toggleLocations = [AllMMCORsLocations, MMCPathologyGrossRoom, MMCORSpecimenRoomDropOff];

  const findLocationByName = (locationName: string) => {
    return dataLocations?.find((item) => item.name === locationName);
  };

  const handleChangeType = (event: React.MouseEvent<HTMLElement>, newSelection: string) => {
    setType(newSelection);
    handleToggle(newSelection, "type");
  };

  const handleChangeStatus = (event: React.MouseEvent<HTMLElement>, newSelection: string) => {
    setStatus(newSelection);
    handleToggle(newSelection, "status");
  };

  const handleChangeSelectLocations = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === "string" ? value.split(",") : value;
    setLocations(newValue);
    setSelectAllLocations(null);
    handleLocations([...newValue].join(","));
  };

  const handleChangeSelectDepartments = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = value;
    setDepartments(newValue);

    const departmentsId = dataDepartments?.data
      .filter((item) => newValue.includes(item.department_name))
      .map((item) => item.department_id);

    if (departmentsId) {
      handleDepartments(departmentsId.join(","));
    }
  };

  const handleCheckboxChange = (selectedChecbox: number) => {
    if (selectedChecbox === selectAllLocations) {
      setSelectAllLocations(null);
      setLocations([]);
      handleLocations([].join(","));
    } else {
      setSelectAllLocations(selectedChecbox);
      if (selectedChecbox === 0) {
        const allMMCORsLocations = formattedLocations.filter((item) => /\bOR\b/.test(item.name));
        const allMMCLocationsIds = allMMCORsLocations.map((item) => item.id) || [];
        setLocations(allMMCLocationsIds);
        handleLocations(allMMCLocationsIds.join(","));
      } else if (selectedChecbox === 1) {
        setLocations([]);
        handleLocations([findLocationByName(MMCPathologyGrossRoom)?.id].join(","));
      } else if (selectedChecbox === 2) {
        setLocations([]);
        handleLocations([findLocationByName(MMCORSpecimenRoomDropOff)?.id].join(","));
      }
    }
  };

  const selectItems = [
    {
      id: "multiple-checkbox-departments",
      labelId: "multiple-checkbox-label-departments",
      placeholder: allDepartments,
      value: departments,
      data: dataDepartments?.data.map(({ department_id, department_name }) => {
        return { id: department_id, name: department_name };
      }),
      onChange: handleChangeSelectDepartments,
      showCheckbox: false,
    },
    {
      id: "multiple-checkbox-locations",
      labelId: "multiple-checkbox-label-locations",
      placeholder: allLocations,
      value: locations,
      data: formattedLocations,
      onChange: handleChangeSelectLocations,
      showCheckbox: true,
    },
  ];

  return (
    <Box display="flex" alignItems="center" flexDirection="column" marginBottom="20px">
      <Grid container style={{ flex: 1, justifyContent: "flex-start", gap: "0.5em" }}>
        {selectItems.map(({ id, labelId, placeholder, value, data, onChange, showCheckbox }) => {
          return (
            <SpecimenSelectOptions
              key={id}
              id={id}
              labelId={labelId}
              placeholder={placeholder}
              value={value}
              data={data}
              onChange={onChange}
              showCheckbox={showCheckbox}
              checkboxData={toggleLocations}
              checkboxValue={selectAllLocations}
              handleCheckboxChange={handleCheckboxChange}
            />
          );
        })}
      </Grid>
      <Grid container justifyContent="space-between">
        <Box display="flex" style={{ gap: "16px", marginTop: "16px", height: "2.5em" }}>
          <ToggleButtonsFilter onChange={handleChangeType} value={type} filterBy="type" />
          <ToggleButtonsFilter onChange={handleChangeStatus} value={status} filterBy="status" />
        </Box>

        <Button
          onClick={() => exportToCSV()}
          data-cy="reportsBtnExportOrderCSV"
          color="primary"
          variant="contained"
        >
          <SaveAltIcon style={{ color: "#fff", width: 25, height: 25, marginRight: "4px" }} />
          {exportCSV}
        </Button>
      </Grid>
    </Box>
  );
};
