import { makeStyles } from "tss-react/mui";

const subHeadingSize = "16px";
export const useCustomStyles = makeStyles()((theme) => ({
  containerDiv: {
    textAlign: "left",
  },
  parentContainer: {
    maxWidth: "608px",
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  topHeading: {
    fontSize: "28px",
    lineHeight: "34px",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      fontSize: "24px",
    },
  },
  subHeading: {
    fontSize: subHeadingSize,
    textAlign: "center",
  },
  subTitle: {
    fontSize: subHeadingSize,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  errorMessage: {
    color: "red",
    fontSize: subHeadingSize,
    border: "1px solid red",
    [theme.breakpoints.down("md")]: {
      margin: "0px 5px",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: "47%",
    height: "42px",
    textTransform: "uppercase",
  },
  testPreviewbutton: {
    marginTop: theme.spacing(2),
    width: "49%",
    height: "42px",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  reloadBtn: {
    marginTop: theme.spacing(1),
    padding: "5px",
    width: "100%",
    textTransform: "uppercase",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  mainTitle: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "21px",
    fontStyle: "normal",
    textAlign: "center",
    letterSpacing: "0.25px",
  },
  parentDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  identityContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  identity: {
    background: "transparent",
    color: theme.palette.common.white,
    padding: "0.18em 0.3em",
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  audioPreviewRoom: {
    "& svg": {
      width: "100px",
      height: "100px",
    },
  },
  innerContainer: {
    width: "100%",
  },
  videoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.common.black,
    width: "100%",
    height: 230,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      height: 140,
      "& svg": {
        transform: "scale(0.7)",
      },
    },
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.common.black,
    width: "100%",
    height: 230,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      height: 180,
      "& svg": {
        transform: "scale(0.7)",
      },
    },
  },
  localPreviewContainer: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0 5px",
    },
  },
  container: {
    width: "100%",
    position: "relative",
    flex: "1",
    marginTop: "20px",
  },
  mobileButton: {
    padding: "2px 4px",
    margin: 0,
  },
  listSection: {},
  headline: {
    marginBottom: "1.3em",
    fontSize: "1.1rem",
  },
  listSelect: {
    width: "100%",
  },
  labelTitle: {
    fontWeight: 600,
    fontSize: "18px",
    margin: "25px 0px 10px 0px",
    textAlign: "left",
  },
  btnDiv: {
    display: "flex",
    width: "100%",
    marginTop: "12px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));
