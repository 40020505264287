/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { SET_LOCATION } from "store/reducers/SpecimenTracking/actions";
import { useProgramsLoading } from "views/console/components/useProgramsLoading";
import { useGetSpecimenLocations } from "hooks/specimenTracking/useGetSpecimenLocations/useGetSpecimenLocations";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import useTranslation from "hooks/useTranslation";
import { LockOrientation } from "util/useLockOrientation";
import { useApp } from "util/AppContext";
import { useIsMobile } from "util/deviceUtils";
import { SpecimenButtonInterface, SpecimenLocationInfo } from "../../types";
import { useSpecimenStyles } from "../../styles";

const QROverlay = (scannedCode, isMobile) => {
  return (
    <figure
      style={{
        border: !scannedCode ? "3px dashed white" : "3px dashed lightgreen",
        width: isMobile ? "190px" : "290px",
        height: isMobile ? "190px" : "290px",
        position: "absolute",
        zIndex: "1",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
};

export const SpecimenTrackingLocation = () => {
  const app = useApp();
  const navigate = useNavigate();
  const { classes } = useSpecimenStyles();
  const isMobile = useIsMobile();
  const isDocumentVisible = useDocumentVisibility();
  const { specimenLocationList } = useGetSpecimenLocations();
  const { isProgramsLoading } = useProgramsLoading();
  const {
    web: {
      common: { cancel, next },
      specimenTrackingLocation: { scanNewLocation, locationFound, locationNotFound },
    },
  } = useTranslation();

  const [isScanning, setIsScanning] = useState(true);
  const [scannedCode, setScannedCode] = useState<string>();
  const [location, setLocation] = useState<SpecimenLocationInfo | null>(null);

  const WAITING_TIME_MESSAGES = 3000;

  const close = () => {
    setIsScanning(false);
    navigate("/console/specimen-tracking");
  };

  const saveData = () => {
    setIsScanning(false);
    app.specimen_tracking_dispatch({ type: SET_LOCATION, payload: location });
    navigate("/console/specimen-tracking/pick-flow", { replace: true });
  };

  const handleQrScan = (result, error) => {
    if (result) {
      setScannedCode(result?.text);
    }
    return error;
  };

  const footerButtons: SpecimenButtonInterface[] = [
    {
      key: "footer-button-0",
      content: cancel,
      onClick: () => close(),
      variant: "text",
      disabled: isProgramsLoading,
      "data-cy": "btn-specimen-cancel",
    },
    {
      key: "footer-button-1",
      content: next,
      onClick: () => saveData(),
      variant: "contained",
      color: "primary",
      disabled: !location || isProgramsLoading,
      "data-cy": "btn-specimen-next",
    },
  ];

  useEffect(() => {
    const item = specimenLocationList?.find((item) => item.barcode === scannedCode);
    if (item) {
      setLocation(item);
      app.addInfoMsg(locationFound);
      setTimeout(() => app.closeSnackbar(), WAITING_TIME_MESSAGES);
    } else if (scannedCode) {
      setLocation(null);
      app.addError(locationNotFound);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedCode]);

  useEffect(() => {
    if (!isDocumentVisible && isMobile) {
      navigate("/console/specimen-tracking");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentVisible]);

  return (
    <LockOrientation isMobile={isMobile} orientation="portrait">
      <Grid
        item
        xs={12}
        className={classes.locationWrapper}
        style={{ maxWidth: isMobile ? "100%" : "60%", margin: "0 auto", paddingTop: "1em" }}
      >
        <Box className={classes.locationSelector}>
          <Typography className={classes.scanLocationTitle} color="inherit" variant="body1">
            {scanNewLocation}
          </Typography>
          {isScanning && (
            <QrReader
              onResult={handleQrScan}
              scanDelay={100}
              containerStyle={{
                margin: ".5em 0 1em 0",
                maxWidth: 470,
                width: "100%",
                height: isMobile ? 230 : 355,
              }}
              videoContainerStyle={{
                padding: 0,
                maxWidth: 470,
                width: "100%",
                height: isMobile ? 230 : 355,
              }}
              videoStyle={{
                position: "inherit",
                maxWidth: 470,
                width: "100%",
                height: isMobile ? 230 : 355,
              }}
              videoId="qrScannerLcations"
              constraints={{
                facingMode: "environment",
              }}
              ViewFinder={() => QROverlay(scannedCode, isMobile)}
            />
          )}
          <Box className={classes.locationInput}>
            <Autocomplete
              id="specimenLocationId"
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Locations"
                  inputProps={{
                    ...(params?.inputProps ?? {}),
                    "data-cy": "specimen-location-selector",
                  }}
                />
              )}
              options={specimenLocationList || []}
              getOptionLabel={(option: SpecimenLocationInfo) => option.name}
              value={location}
              onChange={(event, newValue) => {
                setLocation(newValue || null);
              }}
              defaultValue={location}
              isOptionEqualToValue={(option, value) => option.barcode === value.barcode}
              classes={{ listbox: classes.autocompleteList }}
              style={{ width: "100%" }}
            />
          </Box>
        </Box>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          {footerButtons.map(({ content, ...buttonProps }) => (
            <Button
              style={{ width: "10em", maxWidth: "15em", marginTop: ".3em", height: "3em" }}
              {...buttonProps}
            >
              {content}
            </Button>
          ))}
        </Grid>
      </Grid>
    </LockOrientation>
  );
};

export default SpecimenTrackingLocation;
