/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import AppoitmentLocation from "./AppoitmentLocation";
import UpcomingVisit from "./UpcomingVisit";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const AppoitmentsDetail = () => {
  const params = useParams();
  const app = useApp();
  const [appointment, setAppointments] = useState();

  useEffect(() => {
    getAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppointment = async () => {
    try {
      const uri = "/api/s/fhir/futureappointments";
      const { data } = await axiosClient.get(uri);
      const appoitment = data.Appointments.filter((it, i) => i === params?.id);
      setAppointments(appoitment);
    } catch (error) {
      app.addError(
        "Error: Failed to load future appointment. Please try again or contact support.",
      );
      console.error(error);
    }
  };
  return (
    <Container maxWidth="sm">
      <UpcomingVisit
        name={appointment?.ProviderDepartments[0].Provider.Name}
        date={appointment?.Date}
        time={appointment?.Time}
        department={appointment?.ProviderDepartments[0].Department.Name}
        phone={appointment?.ProviderDepartments[0].Department.Phones[0].Number}
      />
      <AppoitmentLocation instruction={appointment?.PatientInstructions[0]} />
    </Container>
  );
};

export default AppoitmentsDetail;
