import { makeStyles } from "tss-react/mui";
import HomeBackgroundImage from "../../static/images/mobile/mobilehomebg.png";

export const useStyles = makeStyles()(() => ({
  homeHeroContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.75em",
    background: "linear-gradient(284.98deg, #002262 0%, rgba(0, 34, 98, 0) 100%), #000c36",
    width: "100%",
    padding: "2em 1em",
    textAlign: "start",
    backgroundImage: `url(${HomeBackgroundImage})`,
    backgroundSize: "cover",
    "-webkit-box-shadow": "0px 0px 3em 0.5em rgba(0, 0, 0, 0.15)",
    boxShadow: "0px 0px 3em 0.5em rgba(0, 0, 0, 0.15)",
  },
  homeImage: {
    position: "absolute",
    right: "0",
    bottom: "0",
    zoom: "1.05",
  },
  imageVariant: {
    width: "100%",
    maxHeight: "216px",
    objectFit: "cover",
  },
  title: {
    fontSize: "2.5em",
    fontWeight: 700,
    color: "white",
    lineHeight: "1",
    zIndex: 1,
  },
  description: {
    color: "white",
    fontSize: "0.8em",
    maxWidth: "37ch",
    whiteSpace: "pre-line",
    zIndex: 1,
  },
  button: {
    display: "flex",
    flexDirection: "row",
    gap: "0.5em",
    backgroundColor: "#2c60ad",
    border: "1px solid #90c2f0",
    color: "white",
    borderRadius: "0.4em",
    padding: "0.5em 1em",
    zIndex: 1,
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  buttonText: {
    color: "white",
  },
  onlyTextContainer: {
    minHeight: "225px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(284.98deg, #002262 0%, rgba(0, 34, 98, 0) 100%), #000c36",
    width: "100%",
    padding: "2em 1.5em",
    backgroundImage: `url(${HomeBackgroundImage})`,
    backgroundSize: "cover",
  },
  text: {
    color: "white",
    fontSize: "1em",
    lineHeight: "20px",
    zIndex: 1,
  },
  displacedText: {
    marginTop: "-74px",
  },
  alignLeftContainer: {
    alignItems: "flex-start",
    textAlign: "left",
    gap: "1em",
  },
  ahsLogo: {
    width: "12em",
  },
  myChartLogo: {
    width: "45%",
  },
  myChartFolderIcon: {
    width: "1.8rem",
  },
}));
