import soundError from "static/sounds/alert_error.wav";
import soundSuccess from "static/sounds/alert_success.wav";
import { useApp } from "util/AppContext";

interface PlaySoundOptions {
  type: "base64" | "file" | "success" | "error";
  source?: string;
  volume?: number; // value between 0 and 1
}

export const usePlaySound = () => {
  const app = useApp();
  const { enabled } = app.playSounds_state;

  const playSound = ({ type, source, volume = 1.0 }: PlaySoundOptions) => {
    if (!enabled) {
      return;
    }

    let audio: HTMLAudioElement | null = null;

    if (type === "base64") {
      audio = new Audio(`data:audio/mp3;base64,${source}`);
    } else if (type === "file") {
      audio = new Audio(source);
    } else if (type === "success") {
      audio = new Audio(soundSuccess);
    } else if (type === "error") {
      audio = new Audio(soundError);
    }

    if (audio) {
      audio.volume = volume;
      const playPromise = audio.play();
      if (Promise.resolve(playPromise) === playPromise) {
        playPromise.catch((error) => {
          console.error("Error playing sound:", error);
        });
      }
    }
  };

  return {
    playSound,
  };
};
