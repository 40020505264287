import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import { ProviderFinderToggle } from "./ProviderFinderToggle";
import { useSearchProviders } from "hooks/dataCuration/useSearchProviders";
import useTranslation from "hooks/useTranslation";
import { useStyles } from "../../utils/styles";
import { LocationProviders } from "../../utils/types";
import { Specialty, TabPaneProps } from "views/DataCuration/ProviderProfile/types";
import doctorImg from "static/images/ahsIcons/fad.png";
import providerPlaceholder from "static/images/provider_placeholder.png";

const formatProviderOption = (provider) => {
  if (!provider) {
    return "";
  }
  const primarySpecialty: Specialty | undefined = provider.specialties?.find(
    (s) => s.isPrimarySpecialty,
  )?.name;
  const details = [provider?.title, primarySpecialty, provider.NPI].filter(Boolean).join(" - ");
  return `${provider.lastName}, ${provider.firstName} ${details}`;
};

const RemoveProviderDialog = (props) => {
  const { open, onClose, onConfirm, providerToDelete: p, locationName = "" } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove {formatProviderOption(p)} from {locationName}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" data-testid="confirm-delete-provider">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ProvidersAtThisLocationSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}: TabPaneProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const {
    web: {
      dataCuration: {
        location: {
          providersAtThisLocation: { searchLabel },
        },
      },
    },
  } = useTranslation();

  const {
    locationData: {
      generalInfo: {
        locationProviders,
        name: { nameShort = "", nameFull = "" },
      },
    },
    currentPayload,
    setCurrentPayload,
    refetch,
  } = useLocationContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState<LocationProviders | null>(null);

  const [selectedProviders, setSelectedProviders] = useState<LocationProviders[]>([]);
  const [deletedProviders, setDeletedProviders] = useState<LocationProviders[]>([]);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  // Debounce effect waiting for user to stop typing
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const {
    data: providerSearchResults,
    isLoading,
    isError,
  } = useSearchProviders(debouncedSearchText);

  const providerSelectOptions = React.useMemo(
    () =>
      (providerSearchResults?.data || []).filter(
        ({ id }) => !selectedProviders.some((p) => p.id === id),
      ),
    [providerSearchResults, selectedProviders],
  );

  const shownProviders = React.useMemo(() => {
    const notDeleted = (locationProviders || []).filter(
      (p) => !deletedProviders.some(({ id }) => id === p.id),
    );
    const shownMap = new Map<number, LocationProviders>();
    notDeleted.forEach((provider) => {
      shownMap.set(provider.id, provider);
    });
    selectedProviders.forEach((provider) => {
      shownMap.set(provider.id, provider);
    });
    return [...shownMap.values()].sort((a, b) => a.id - b.id);
  }, [locationProviders, selectedProviders, deletedProviders]);

  const updatePayload = React.useMemo(
    () =>
      [
        ...selectedProviders.map(({ id }) => ({ id })),
        ...deletedProviders.map(({ id }) => ({ id, deleted: true })),
      ].sort((a, b) => a.id - b.id),
    [selectedProviders, deletedProviders],
  );

  React.useEffect(() => {
    if (!updatePayload.length) {
      return;
    }
    setCurrentPayload({
      ...(currentPayload ?? {}),
      generalInfo: {
        ...(currentPayload?.generalInfo ?? {}),
        locationProviders: updatePayload,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePayload]);

  const selectProvider = (provider) => {
    const inDeleted = deletedProviders.findIndex((p) => p.id === provider.id);
    if (inDeleted !== -1) {
      setDeletedProviders((prev) => {
        const copy = [...prev];
        copy.splice(inDeleted, 1);
        return copy;
      });
    } else {
      setSelectedProviders((prev) => [...prev, provider]);
    }
  };

  const deleteProvider = (provider) => {
    const inSelected = selectedProviders.findIndex((p) => p.id === provider.id);
    if (inSelected !== -1) {
      setSelectedProviders((prev) => {
        const copy = [...prev];
        copy.splice(inSelected, 1);
        return copy;
      });
    } else {
      setDeletedProviders((prev) => [...prev, provider]);
    }
  };

  const navigateToProvider = (providerId) => {
    if (providerId) {
      navigate(`/console/data-curation/provider/${providerId}`);
    }
  };

  useEffect(() => {
    setSelectedProviders([]);
    setDeletedProviders([]);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisableForm]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      data-testid="ProvidersAtThisLocationSection"
    >
      <Grid item xs={12} md={12}>
        <ProviderFinderToggle {...{ shouldDisableForm }} />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth sx={{ marginBlock: "16px" }} error={isError}>
          <Autocomplete
            id="find-provider"
            options={providerSelectOptions || []}
            getOptionLabel={formatProviderOption}
            renderInput={(params) => <TextField {...params} label={searchLabel} />}
            inputValue={searchText}
            onInputChange={(e, value) => setSearchText(value)}
            value={null}
            loading={isLoading}
            onChange={(e, value) => {
              if (!value) {
                return;
              }
              selectProvider(value);
              setSearchText("");
            }}
            disabled={shouldDisableForm}
            data-cy="NameSection-searchProvidersInput"
            data-testid="NameSection-searchProvidersInput"
          />
        </FormControl>
        <Box
          sx={{
            maxHeight: "110vh",
            overflowY: "auto",
            borderRadius: 1,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.23)",
            padding: 1,
          }}
        >
          {!shownProviders.length ? (
            <Typography sx={{ margin: "24px 16px" }} color="gray" textAlign="left">
              No providers found.
            </Typography>
          ) : (
            shownProviders.map((provider) => {
              const { id, specialties = [], imageUrl = doctorImg } = provider;
              return (
                <Paper
                  key={id}
                  elevation={2}
                  className={classes.providerCard}
                  sx={{ position: "relative", padding: 1 }}
                >
                  <img
                    src={imageUrl}
                    alt="providerImg"
                    data-testid="providerImage"
                    onClick={() => {
                      navigateToProvider(id);
                    }}
                    onError={(e) => {
                      e.currentTarget.src = providerPlaceholder;
                    }}
                  />
                  <Box onClick={() => navigateToProvider(id)}>
                    <Typography
                      align="left"
                      variant="h6"
                      marginBottom="8px"
                      data-testid="providerName"
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {formatProviderOption(provider)}
                    </Typography>
                    <div>
                      {specialties
                        ?.sort((a, b) => (b?.isPrimarySpecialty ? 1 : -1))
                        .map((specialty, i) => {
                          return (
                            <Typography
                              key={`${specialty.specialty.name}-${id}`}
                              align="left"
                              data-testid={`providerSpecialty-${i}`}
                            >
                              {specialty.specialty.name}
                            </Typography>
                          );
                        })}
                    </div>
                  </Box>
                  {!shouldDisableForm && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setProviderToDelete(provider);
                        setDialogOpen(true);
                      }}
                      sx={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Paper>
              );
            })
          )}
        </Box>
      </Grid>
      <RemoveProviderDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setProviderToDelete(null);
        }}
        onConfirm={() => {
          setDialogOpen(false);
          setProviderToDelete(null);
          deleteProvider(providerToDelete);
        }}
        providerToDelete={providerToDelete}
        locationName={nameFull || nameShort || ""}
      />
    </Grid>
  );
};

export { ProvidersAtThisLocationSection };
