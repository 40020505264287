import AddIcon from "@mui/icons-material/Add";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import useTranslation from "hooks/useTranslation";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import ExaminerModal from "./ExaminerModal";
import colDefs from "./colDefs";

const useStyles = makeStyles()({
  root: {
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  title: {
    fontSize: "24px",
    fontWeight: 400,
  },
});

const gridConfig = {
  ...defaultGridConfig,
  allowEdit: true,
  allowDelete: false,
};

const emptyExaminer = {
  email: "",
  NPI: "",
  locId: "loc-797ecc31d3",
  firstName: "",
  lastName: "",
  providerSpecialty: "",
  nationalRegistryNumber: "",
  certificationNumber: "",
  issuingState: "NJ",
};

const MedicalExaminers = () => {
  const { classes } = useStyles();
  const {
    web: {
      dotForm: {
        staff: { medicalExaminers: copy },
      },
    },
  } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [examiner, setExaminer] = useState(emptyExaminer);
  const [editing, setEditing] = useState(false);
  const [refreshSignal, setRefreshSignal] = useState(false);
  const triggerRefresh = () => setRefreshSignal((x) => !x);


  useEffect(() => {
    window.scrollTo(0, 0);
    let timeoutId: ReturnType<typeof setTimeout>;
    const handlePopState = () => { timeoutId = setTimeout(() => window.scrollTo(0, 0), 0); return null; }
    window.addEventListener("popstate", handlePopState);
    return () => { clearTimeout(timeoutId); return window.removeEventListener("popstate", handlePopState) };
  }, []);

  return (
    <Container className={classes.root} data-testid="dot-medical-examiners">
      <Typography variant="h2" component="h1" gutterBottom className={classes.title}>
        {copy.title}
      </Typography>
      <GenericEnhancedTable
        gridConfig={gridConfig}
        tableTitle=""
        colDefs={colDefs}
        url={API_ENDPOINTS.dotMedicalExaminers}
        orderBy="lastName"
        order="desc"
        hideCheckboxes
        titleButtons={[
          {
            name: "New",
            action: () => setModalOpen(true),
            icon: <AddIcon />,
          },
        ]}
        onEditHandler={(ex) => {
          setExaminer(ex);
          setEditing(true);
          setModalOpen(true);
        }}
        refreshSourceData={refreshSignal}
      />
      <ExaminerModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setEditing(false);
          setExaminer(emptyExaminer);
        }}
        onAfterSubmit={triggerRefresh}
        initialValues={examiner}
        editing={editing}
      />
    </Container>
  );
};

export default MedicalExaminers;
