/** @format */
import React from "react";
import FamilyMemberInviteLayout from "../components/FamilyMessagingSystem/FamilyMemberInviteLayout";
import AppStateProvider from "../components/Video/state";

export default function FamilyMemberInvite() {
  return (
    <div style={{ display: "grid", gridTemplateRows: "1fr auto" }}>
      <AppStateProvider>
        <FamilyMemberInviteLayout />
      </AppStateProvider>
    </div>
  );
}
