import { FieldInputsInterface } from "../types";

export const addSpecimenDataInputs = (isEdit: boolean): FieldInputsInterface[] => {
  return [
    {
      id: "orderMrn",
      label: "MRN",
      disabled: !isEdit,
      dataCy: "InputMrn",
      required: true,
      onClick: () => {},
      autoFocus: true,
      type: "string",
    },
    {
      id: "orderOrderExternalId",
      label: "Order Id",
      disabled: !isEdit,
      dataCy: "InputOrderId",
      required: true,
      onClick: () => {},
      type: "string",
    },
    {
      id: "specimenExternalId",
      label: "Specimen Id",
      disabled: !isEdit,
      dataCy: "InputSpecimenId",
      required: true,
      onClick: () => {},
      type: "string",
    },
    {
      id: "orderPatientFirstName",
      label: "Patient First Name",
      disabled: !isEdit,
      dataCy: "InputPatientFirstName",
      required: false,
      onClick: () => {},
      type: "text",
    },
    {
      id: "orderPatientLastName",
      label: "Patient Last Name",
      disabled: !isEdit,
      dataCy: "InputPatientLastName",
      required: false,
      onClick: () => {},
      type: "text",
    },
    {
      id: "orderDob",
      label: "Date of Birth",
      disabled: !isEdit,
      dataCy: "InputDateOfBirth",
      required: false,
      onClick: () => {},
      isDOB: true,
    },
    {
      id: "specimenType",
      label: "Specimen Type",
      disabled: !isEdit,
      dataCy: "InputSpecimenType",
      required: false,
      onClick: () => {},
      type: "text",
    },
    {
      id: "description",
      label: "Specimen Info",
      disabled: !isEdit,
      dataCy: "InputSpecimenInfo",
      required: false,
      onClick: () => {},
      type: "text",
    },
  ];
};
