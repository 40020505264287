import axios, { AxiosInstance } from "axios";
import { API_HOST, API_HOST_FUNCTION, HOST_DOMAIN } from "./constants/config";

export function getApiHost() {
  if (process.env.REACT_APP_USE_SUBDOMAIN_API_ENDPOINT === "true") {
    return API_HOST_FUNCTION();
  }

  const hostDomain = window.location.hostname;

  if (hostDomain === HOST_DOMAIN.myatlantic) {
    return API_HOST.myatlanticApi;
  }
  if (hostDomain === HOST_DOMAIN.myatlanticTest) {
    return API_HOST.myatlanticApiTest;
  }
  if (hostDomain === HOST_DOMAIN.myatlanticDev) {
    return API_HOST.myatlanticDevApi; // TODO fix someday, need new sert, -api shoudl be before -dev for convention
  }
  if (hostDomain === HOST_DOMAIN.app) {
    return API_HOST.appApi;
  }
  if (hostDomain === HOST_DOMAIN.appTest) {
    return API_HOST.appApiTest;
  }
  if (hostDomain === HOST_DOMAIN.appLocalInt) {
    return API_HOST.appApiInt;
  }
  if (hostDomain === HOST_DOMAIN.appDev) {
    return API_HOST.appApiDev;
  }
  if (hostDomain === HOST_DOMAIN.localhost) {
    return API_HOST.localhost;
  }
  return hostDomain;
}
const tzFormatter = new Intl.DateTimeFormat("en-US");
const timezone = tzFormatter.resolvedOptions().timeZone;

let axiosInstance: AxiosInstance | null = null;

function getAxiosSingleton() {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: getApiHost(),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-TIMEZONE": timezone,
      },
      withCredentials: true,
    });
  }

  return axiosInstance;
}

export const axiosClient = getAxiosSingleton();

export const UserTypes = {
  REGISTRATION_STAFF: "REGISTRATION_STAFF",
  NURSE: "NURSE",
  PHYSICIAN: "PHYSICIAN",
  STAFF: "STAFF",
  PATIENT: "PATIENT",
  CONSUMER: "consumer",
  ADMIN: "ADMIN",
  IN_PATIENT_MGR: "IN_PATIENT_MGR",
  CARE_NAVIGATOR: "CARE_NAVIGATOR",
  PATIENT_SUPPORT: "PATIENT_SUPPORT",
  SPECIMEN_TECH: "SPECIMEN_TECH",
  MANAGER: "MANAGER",
};

export * from "./Api_Endpoints";

export const SIMPLE_GET_OPTIONS = {
  method: "GET",
  headers: new Headers({
    Accept: "application/json",
  }),
};

export const GET_OPTIONS = {
  method: "GET",
  credentials: "include",
  headers: new Headers({
    Accept: "application/json",
  }),
};

export const POST_OPTIONS = {
  method: "POST",
  credentials: "include",
  headers: new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  }),
};

export const DELETE_OPTIONS = {
  method: "DELETE",
  credentials: "include",
  headers: new Headers({
    Accept: "application/json",
  }),
};

export const PUT_OPTIONS = {
  method: "PUT",
  credentials: "include",
  headers: new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  }),
};

export const SIMPLE_POST_OPTIONS = {
  method: "POST",
  headers: new Headers({
    Accept: "application/json",
  }),
};

export const AXIOS_OPTIONS = {
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
