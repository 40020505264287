import React, { FC, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { FETCH_NEW_VIRTUAL_VISITS } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import SendInviteDialog from "components/shared/SendInviteDialog/SendInviteDialog";
import { VirtualSessionQueue } from "util/constants";
import { useQuery } from "hooks/useQuery";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

interface Patient {
  MRN: string;
  patientId: string;
}

type Props = {
  handleClose: () => void;
  cellPhone: string;
  patient: Patient;
};

const CreateAdhocSession: FC<Props> = ({ handleClose, cellPhone = "", patient }) => {
  const app = useApp();
  const query = useQuery();
  const navigate = useNavigate();
  const { programId } = usePrograms();
  const queryClient = useQueryClient();
  const [showInviteDialog, setShowInviteDialog] = useState(false);

  const createAdhocSession = async () => {
    const virtualSession = {
      cellPhone,
      MRN: patient.MRN,
      send_invite: false,
      queue: VirtualSessionQueue.REGISTERING,
      patientUserId: Number(patient.patientId),
      programId,
    };

    try {
      const url = API_ENDPOINTS.create_vs_by_staff;
      const response = await axiosClient.post(url, virtualSession);
      query.set("vsId", response?.data?.data?.id);
      navigate({ search: query.toString() });
      await queryClient.invalidateQueries("fetch-awaiting-vs-visit", programId);
      app.dispatch({ type: FETCH_NEW_VIRTUAL_VISITS });
      app.addInfoMsg("Virtual Session successfully created");
      setShowInviteDialog(true);
    } catch (e) {
      // @ts-ignore
      app.addError(e.response.data.description);
      setShowInviteDialog(false);
    }
  };

  useEffect(() => {
    createAdhocSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SendInviteDialog
      open={showInviteDialog}
      handleClose={handleClose}
      defaultCellPhone={cellPhone}
      isVirtualVisitCreated
      isAdhocSession
    />
  );
};

export default CreateAdhocSession;
