/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Theme from "Theme";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import { makeStyles } from "tss-react/mui";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, UserTypes, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { getMessageFromTime } from "util/util_functions";

const useStyles = makeStyles()((theme, props) => {
  return {
    label: {
      color: theme.palette.common.white,
    },
    waitingBox: {
      width: "353px",
      height: "270px",
      background: "rgba(30, 30, 30, 0.85)",
      borderRadius: "14px",
      padding: "16px",
      textAlign: "center",
    },
    mobileWaitingBox: {
      width: "300px",
      height: "250px",
      background: "rgba(30, 30, 30, 0.85)",
      borderRadius: "14px",
      padding: "16px",
      textAlign: "center",
      fontSize: "14px",
    },
    waitingPara: {
      color: theme.palette.common.white,
      textAlign: "center",
    },
    mobileWaitingPara: {
      color: theme.palette.common.white,
      textAlign: "center",
    },
    dialogContainer: {
      position: "absolute",
      top: props?.isMobile ? "12vh" : "33%",
      right: 0,
      left: 0,
      display: props?.isChatWindowOpen && props?.isMobile ? "none" : "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

export function WaitingMessageDialog({ isChatWindowOpen }) {
  const participants = useParticipants();
  const isMobile = useIsMobile();
  const { classes } = useStyles({ isMobile, isChatWindowOpen });
  const [notificationsOn, setNotificationsOn] = React.useState(true);
  const { virtual_session_id } = useParams();
  const [hasStaffJoined, setHasStaffJoined] = useState(false);
  const vId = virtual_session_id ? parseInt(virtual_session_id) : null;
  const app = useApp();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchTime = async () => {
      try {
        const { data } = await axiosClient.get(API_ENDPOINTS.twilio_get_estimated_time, {
          params: { virtual_session_id: vId },
        });
        const tmessage = getMessageFromTime(data?.data, data?.virtual_session_info?.queue);
        setMessage(tmessage);
      } catch (error) {
        setMessage(0);
        app.addError("Something Went Wrong");
      }
    };
    const interval = setInterval(() => {
      fetchTime();
    }, 10000);
    fetchTime();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line
  }, []);

  const userLookup = async (identity) => {
    const [, , userId] = identity.split(":");
    const url = "/api/s/usertypelookup";
    try {
      const { data } = await axiosClient.get(url, { params: { user_type_lookup_id: userId } });
      return data;
    } catch (error) {
      app.addError("Something Went Wrong");
    }
  };
  const evalParticipantsWaitingRoom = async () => {
    // Note participants seems to only be connected and remote participants
    if (participants) {
      if (!participants.length) {
        setHasStaffJoined(false);
        return;
      }
      let counter = 0;
      let hasStaff = false;
      participants.forEach(async (participant) => {
        counter++;
        if (participant.state === "connected") {
          const userData = await userLookup(participant.identity);
          if (userData && !(userData.userType in [UserTypes.PATIENT, UserTypes.CONSUMER])) {
            console.log("vv found staff");
            hasStaff = true;
          }
        }

        if (counter === participants.length) {
          if (!hasStaff) {
            setHasStaffJoined(false);
          } else {
            setHasStaffJoined(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    evalParticipantsWaitingRoom();
    // eslint-disable-next-line
  }, [participants]);

  const onCheckboxClick = async (e) => {
    try {
      const value = e.target.checked;
      const post_url = "/api/s/virtualvisit/sms-notifications";
      await axiosClient.post(post_url, {
        virtual_session_id: vId,
        notifications_on: value,
      });
      setNotificationsOn(value);
    } catch (error) {
      app.addError("Problem updating notify me field.");
    }
  };

  return (
    <>
      {!hasStaffJoined && (
        <div className={classes.dialogContainer}>
          <div className={isMobile ? classes.mobileWaitingBox : classes.waitingBox}>
            <p className={isMobile ? classes.mobileWaitingPara : classes.waitingPara}>
              {message.greetings}
            </p>
            {message.availability && (
              <>
                <p style={{ color: Theme.palette.common.white }}>
                  <b>Our clinic at the moment is:</b>
                </p>
                <p style={{ color: message.color }}>
                  <b>
                    <div>{message.availability}</div>
                  </b>
                </p>
              </>
            )}
            <FormGroup style={{ display: "block" }}>
              <FormControlLabel
                classes={{ label: classes.label }}
                control={
                  <Checkbox
                    defaultChecked
                    color="white"
                    checked={notificationsOn}
                    onClick={onCheckboxClick}
                    style={{ color: Theme.palette.common.white }}
                  />
                }
                label="Text me when it's my turn!"
              />
            </FormGroup>
          </div>
        </div>
      )}
    </>
  );
}

export default WaitingMessageDialog;
