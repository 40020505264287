import React, { useEffect, useRef } from "react";
import { Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import useCaptionsContext from "components/Video/hooks/useCaptionsContext/useCaptionsContext";
import { useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    captionContainer: {
      position: "absolute",
      marginLeft: "auto",
      marginRight: "auto",
      left: 0,
      right: 0,
      bottom: 10,
      width: "90%",
      height: 130,
      borderRadius: 2,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      padding: "10px 10px 5px",
      overflowY: "auto",
      zIndex: 2,
      boxSizing: "border-box",
      [theme.breakpoints.down("md")]: {
        height: 90,
      },
      "&::-webkit-scrollbar": {
        width: 6,
      },
      "&::-webkit-scrollbar-track": {
        background: theme.palette.grey[800],
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey[700],
        borderRadius: 20,
        border: `3px solid ${theme.palette.grey[900]}`,
      },
    },
    overflowContainer: {
      bottom: 0,
      minHeight: 115,
      [theme.breakpoints.down("md")]: {
        minHeight: 75,
      },
    },
    subtitle: {
      color: theme.palette.common.white,
      fontSize: 16,
      margin: 0,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
  };
});

const Captions = () => {
  const { classes } = useStyles();
  const { subtitles } = useCaptionsContext();
  const bottomRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const scrollDiff = isMobile ? 130 : 180;

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const { scrollHeight, scrollTop } = containerRef.current || {};
    if (
      scrollHeight !== undefined &&
      scrollTop !== undefined &&
      scrollHeight - scrollTop <= scrollDiff
    ) {
      scrollToBottom();
    }
  }, [subtitles, scrollDiff]);

  const subtitlesSet = new Set(subtitles);
  return (
    <div ref={containerRef} className={classes.captionContainer}>
      <div className={classes.overflowContainer}>
        {Array.from(subtitlesSet)
          .filter((t) => t.message)
          .map((subtitle) => (
            <Typography
              align="left"
              key={subtitle.id}
              className={classes.subtitle}
            >{`${subtitle.speakerName}: ${subtitle.message}`}</Typography>
          ))}
        <div ref={bottomRef} className="list-bottom" />
      </div>
    </div>
  );
};

export default Captions;
