import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  modalDialog: {
    width: "840px",
  },
  modalDialogTitle: {
    padding: "16px 0 0",
  },
  formContainer: {
    padding: theme.spacing(4),
    height: "580px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
  },
}));
