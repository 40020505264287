import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import useStyles from "./Card.styles";

interface Props {
  title: string;
  imgUrl: string;
  desc: string;
  actionUrl?: string;
  msg?: string;
  handleCardClick: (value: string) => void;
  isActive?: boolean;
  hospital?: string;
  number?: string;
}

const AhsCard = ({
  title,
  imgUrl,
  desc,
  actionUrl,
  msg = "",
  handleCardClick,
  isActive = false,
  hospital,
  number,
}: Props) => {
  const { classes } = useStyles({ isActive });
  const navigate = useNavigate();

  return (
    <Card elevation={6} className={classes.appCard}>
      <CardActionArea
        onClick={() => {
          if (actionUrl) {
            if (actionUrl.startsWith("/")) {
              navigate(actionUrl);
            } else {
              window.open(actionUrl, "_blank");
            }
          }
          if (handleCardClick) {
            handleCardClick(msg);
          } else {
            console.log(actionUrl + msg);
          }
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <CardContent>
              <img src={imgUrl} height={40} width={40} alt={title} />
            </CardContent>
          </Grid>
          <Grid item xs={10}>
            <CardContent>
              <Typography variant="h5" className={classes.title} align="left">
                {title}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.cardDesc}
                align="left"
              >
                {desc}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.cardDesc}
                align="left"
              >
                {hospital}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.cardDesc}
                align="left"
              >
                {number}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

AhsCard.defaultProps = {
  actionUrl: "",
};

export default AhsCard;
