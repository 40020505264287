import MuiDialogContent from "@mui/material/DialogContent";
import { withStyles } from "tss-react/mui";

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    width: "600px",
    padding: "0px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100vh",
      overflowY: "auto",
    },
  },
}));

export default DialogContent;
