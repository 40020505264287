import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  top: {
    padding: "0px",
    margin: 0,
  },
  red: {
    margin: 0,
    padding: 0,
    height: "146px",
    backgroundColor: theme.palette.error.main,
    borderRadius: "4px",
  },
  img: {
    width: "60px",
    height: "60px",
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: theme.palette.common.white,
  },
  textError: {
    marginTop: "80px",
    fontSize: "34px",
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  text: {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: 400,
    margin: "15px",
  },
  back: {
    marginTop: "25px",
    borderTop: `2px solid ${theme.palette.divider}`,
    padding: "14px",
  },
  button: {
    border: "none",
    outline: 0,
    backgroundColor: theme.palette.common.white,
    fontSize: "16px",
    fontWeight: 400,
    width: "100%",
    margin: 0,
    color: theme.palette.primary.dark,
  },
}));

export default useStyles;
