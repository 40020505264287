import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ChatIcon from "../../../icons/ChatIcon";
import { makeStyles, withStyles } from "tss-react/mui";
import useChatContext from "../../../hooks/useChatContext/useChatContext";

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles()((theme) => ({
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
  },
  iconContainer: {
    position: "relative",
    display: "flex",
  },
  circle: {
    width: "16px",
    height: "16px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "50%",
    position: "absolute",
    top: "-10px",
    left: "50px",
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
  },
  hasUnreadMessages: {
    opacity: 1,
  },
  ring: {
    border: `3px solid ${theme.palette.success.main}`,
    borderRadius: "30px",
    height: "14px",
    width: "14px",
    position: "absolute",
    left: "11px",
    top: "-5px",
    opacity: 0,
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1,
  },
  "@keyframes expand": {
    "0%": {
      transform: "scale(0.1, 0.1)",
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      transform: "scale(1.4, 1.4)",
      opacity: 0,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const HtmlTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function ToggleChatButton({ textMobile }: { textMobile?: string }) {
  const { classes, cx } = useStyles();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { isChatWindowOpen, setIsChatWindowOpen, conversation, hasUnreadMessages } =
    useChatContext();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      const handleNewMessage = () => {
        setShouldAnimate(true);
      };
      conversation.on("messageAdded", handleNewMessage);
      return () => {
        conversation.off("messageAdded", handleNewMessage);
      };
    }
  }, [conversation, isChatWindowOpen]);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(hasUnreadMessages);
  }, [hasUnreadMessages]);

  return (
    <HtmlTooltip
      open={open}
      title={
        <div>
          <Typography color="inherit" onClick={toggleChatWindow} style={{ display: "inline-flex" }}>
            New Message!
          </Typography>
          <IconButton
            aria-label="delete"
            className={classes.margin}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      }
      arrow
    >
      <div className={classes.buttonWrapper}>
        <Button
          data-cy-chat-button
          onClick={toggleChatWindow}
          disabled={!conversation}
          data-testid="ToggleChatButton"
          startIcon={
            <div className={classes.iconContainer}>
              <ChatIcon />
              <div className={cx(classes.ring, { [classes.animateRing]: shouldAnimate })} />
              <div
                className={cx(classes.circle, { [classes.hasUnreadMessages]: hasUnreadMessages })}
              />
            </div>
          }
        >
          <span className={textMobile}>Chat</span>
        </Button>
      </div>
    </HtmlTooltip>
  );
}
