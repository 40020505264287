// the backend expects an integer to choose between the available forms
export enum DotForms {
  ExaminationReportForm = 1,
  ExaminerCertificate = 2,
}

export enum DotFormStatus {
  NEW = "New",
  SENT = "Sent",
  IN_PROGRESS = "In Progress",
  SUBMITTED = "Submitted",
  COMPLETED = "Completed",
  ERROR = "Error",
}
