import React, { useState } from "react";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import useTranslation from "hooks/useTranslation";

const CertificationSection: React.FC = () => {
  const {
    web: {
      dataCuration: { providerProfile },
    },
  } = useTranslation();

  const {
    experience: {
      certifications: { certifyingBody, certificateText, issueDateText },
    },
  } = providerProfile;

  const {
    providerExperience: { certifications },
  } = useProviderContext();

  const initialFormData = {
    certifyingBody: "University of Colorado",
    certificate: "My University of Colorado Cert",
    issueDate: "1990",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (key: string, value: any) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  if (!certifications.length) {
    return (
      <Typography textAlign="left" color="textSecondary" paddingTop="24px">
        No certifications found
      </Typography>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      paddingTop="24px"
      data-testid="certification-section"
    >
      {certifications?.map(({ id, boardCertification, issueDate, certificate }) => {
        return (
          <Grid key={id} item xs={12}>
            <Grid item xs={12} md={12} marginBottom="16px">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  error={false}
                  id="certifyingBody"
                  name="certifyingBody"
                  size="medium"
                  value={boardCertification.name}
                  variant="outlined"
                  label={certifyingBody}
                  onChange={(e) => handleChange("certifyingBody", e.target.value)}
                  disabled
                  helperText={false}
                  data-cy="CertificationSection-CertifyingBodyInput"
                  data-testid="CertificationSection-CertifyingBodyInput"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} marginBottom="16px">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  error={false}
                  id="certificate"
                  name="certificate"
                  size="medium"
                  value={certificate}
                  variant="outlined"
                  label={certificateText}
                  onChange={(e) => handleChange("certificate", e.target.value)}
                  disabled
                  helperText={false}
                  data-cy="CertificationSection-CertificateInput"
                  data-testid="CertificationSection-CertificateInput"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} marginBottom="16px">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  error={false}
                  id="issueDate"
                  name="issueDate"
                  size="medium"
                  value={issueDate}
                  variant="outlined"
                  label={issueDateText}
                  onChange={(e) => handleChange("issueDate", e.target.value)}
                  disabled
                  helperText={false}
                  data-cy="CertificationSection-YearCertifiedInput"
                  data-testid="CertificationSection-YearCertifiedInput"
                />
              </FormControl>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CertificationSection;
