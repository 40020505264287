import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeSessionButton: {
    backgroundColor: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
    },
    "&:disabled": {
      border: "unset",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  textField: {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));
