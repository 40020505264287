import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const VUCRedirectContext = createContext();
VUCRedirectContext.displayName = "VUCRedirectContext";

export const VUCRedirectProvider = ({ children }) => {
  const navigate = useNavigate();

  const redirectHome = () => {
    navigate("/home");
  };

  // eslint-disable-next-line
  const contextValue = useMemo(() => ({ redirectHome }), []);

  return <VUCRedirectContext.Provider value={contextValue}>{children}</VUCRedirectContext.Provider>;
};

export const useVUCRedirect = () => {
  return useContext(VUCRedirectContext);
};
