import { makeStyles } from "tss-react/mui";

export const useHeaderBarStyles = makeStyles()((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  logo: {
    flexGrow: 1,
    height: "40px",
  },
  appBar: {
    padding: "4px 0",
    backgroundColor: theme.palette.common.white,
  },
  toolBar: {
    display: "flex",
    justifyContent: "center",
  },
  mobileProgramContainer: {
    color: theme.palette.primary.main,
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    gap: "10px",
  },
  mobileProgramTextContainer: {
    padding: 0,
  },
  mobileProgramText: {
    margin: 0,
  },
  logoButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  userName: {
    color: theme.palette.primary.main,
  },
  menuPapper: {
    padding: "10px 0px",
  },
  menuItem: {
    padding: "12px 16px",
    fontFamily: "Roboto, sans-serif",
  },
  logoutItem: {
    marginTop: "10px",
    fontWeight: 600,
  },
  userIconButton: {
    marginLeft: "auto",
    marginTop: "5px",
  },
  loginButton: {
    marginLeft: "auto",
  },
  enviromentLabel: {
    color: "#B00020",
    fontWeight: 600,
    paddingTop: "12px",
    textTransform: "capitalize",
  },
  programShown: {
    margin: "auto",
  },
  noProgramShown: {
    marginLeft: "20px",
  },
  banner: {
    display: "flex",
    backgroundColor: "#B00020",
    height: "100px",
    position: "relative",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    color: "#fff",
    "& a": {
      color: "white",
      textDecoration: "underline",
    },
  },
}));
