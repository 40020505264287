import { makeStyles } from "tss-react/mui";

export const useVsReportStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  cardMain: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "14pt",
    textDecoration: "none",
  },
  backgroundSecondary: {
    backgroundColor: theme.palette.primary.main,
  },
  secondaryColor: {
    color: theme.palette.primary.main,
  },
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
  topGrid: {
    flexGrow: 1,
    margin: "auto",
    width: "80%",
  },
  topBtnContainer: {
    width: "100%",
    boxSizing: "border-box",
    paddingRight: "32px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      paddingRight: "12px",
      marginBottom: "10px",
    },
  },
  closeBtnWrapper: {
    alignSelf: "flex-start",
    marginRight: "auto",
    marginLeft: "20px",
    "& .MuiButton-root": {
      minWidth: "20px",
      padding: "0px",
    },
  },
  tableContainer: {
    padding: "32px",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      padding: "12px 12px 50px 12px",
    },
  },
}));

export const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
