import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { axiosClient, getApiHost } from "./api_helper";
import { nav } from "../views/console/components/ConsoleHomeNav";
import ErrorBoundaryAxios from "components/ErrorBoundaryAxios";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();
authContext.displayName = "authContext";

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [checkedLogin, setCheckedLogin] = useState(false);

  const sendEmailLoginLink = async (email, cb) => {
    try {
      const { data } = await axiosClient.post("/api/emaillogin/send", { email });
      return cb(data, null);
    } catch (err) {
      console.log("Failed to send email login request, please try again.");
      return cb(null, err);
    }
  };

  const verifyEmailLoginLink = async (loginToken, cb) => {
    try {
      const { data } = await axiosClient.post("/api/emaillogin/verify", {
        login_token: loginToken,
      });
      setUser(data);
      return cb(data.userId, null);
    } catch {
      console.log("Failed to verify email login token, please try again.");
      return cb(null, null);
    }
  };

  const verifySmsLoginLink = async (loginToken, cb, ctxId) => {
    try {
      const { data } = await axiosClient.post("/api/sms/verify", {
        loginToken,
        ...(ctxId && { ctxId }),
      });
      setUser(data);

      return cb(data.userId, null);
    } catch (e) {
      console.log("Failed to verify sms login token, please try again.");
      return cb(null, e);
    }
  };

  const setUserPwd = async (username, pwd, cb) => {
    try {
      const { data } = await axiosClient.post("/api/s/setuserpwd", { username, pwd });
      setUser(data);
      return cb(data.userId, null);
    } catch {
      console.log("Failed to set username and password, please try again.");
      return cb(null, null);
    }
  };

  const directSignup = async (
    email,
    cell,
    firstName,
    lastName,
    dob,
    street1 = "",
    street2 = "",
    gender = "",
    zipCode = "",
    password = "",
    cb = () => {},
  ) => {
    try {
      const { data } = await axiosClient.post("/api/signup", {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        dob,
        zip: zipCode,
        street1,
        street2,
        gender,
        cell_phone: cell,
      });
      setUser(data);
      return cb(data.userId, null);
    } catch {
      console.log("Failed to create new user, please try again.");
      return cb(null, null);
    }
  };

  const directLogin = async (email, pwd, cb) => {
    try {
      const { data } = await axiosClient.post("/api/login", {
        email_or_username: email,
        password: pwd,
      });
      setUser(data);
      return cb(data, null);
    } catch (e) {
      return cb(null, e);
    }
  };

  const myChartLogin = async (username, pwd, cb) => {
    try {
      const { data } = await axiosClient.post("/api/mychartlogin", { username, password: pwd });
      setUser(data);
      return cb(data.userId, null);
    } catch {
      console.log("Failed to login user with mychart creds, please try again.");
      return cb(null, null);
    }
  };

  const tokenDobLogin = async (loginToken, authCode, cellPhone, dob, cb) => {
    try {
      const apiHost = getApiHost();
      const { data } = await axiosClient.post(`${apiHost}/api/dob/verify`, {
        dob,
        ...(loginToken ? { loginToken } : { authCode, cellPhone }),
      });
      setUser(data);

      return cb(data, null);
    } catch (e) {
      console.log("Failed to login user with token and dob, please try again.");
      return cb(null, null);
    }
  };

  const oauthLogin = async (idp_domain, access_token, cb) => {
    try {
      const { data } = await axiosClient.post("/api/oauth/login", {
        idp_domain,
        access_token,
      });
      setUser(data);
      return cb(data.userId, null);
    } catch {
      console.log("Failed to login user with OAuth, please try again.");
      return cb(null, null);
    }
  };

  const checkLogin = async (cb, force) => {
    if (!force && (user || checkedLogin)) {
      return;
    }
    setCheckedLogin(true);
    // if ahs_session cookie is present this will refresh the user
    try {
      const { data } = await axiosClient.get("/api/s/current-user", { params: { details: true } });
      setUser(data);
      cb(data);
    } catch (e) {
      console.log(`Problem checking login, ${e}`);
      cb(null);
    }
  };

  const logout = async (cb) => {
    try {
      await axiosClient.get("/api/logout");
      localStorage.removeItem("queueFilter");
      setUser(null);
      cb(null);
    } catch (error) {
      console.log("warning, failed to logout");
      cb(error.response);
      // no cookie, silently ignore
    }
  };

  const setUserDirectly = (user) => {
    setUser(user);
  };

  return {
    user,
    setUserDirectly,
    sendEmailLoginLink,
    verifyEmailLoginLink,
    verifySmsLoginLink,
    tokenDobLogin,
    setUserPwd,
    checkLogin,
    logout,
    directLogin,
    myChartLogin,
    directSignup,
    oauthLogin,
  };
}

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute() {
  const auth = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState(null);

  React.useEffect(() => {
    const isConsole =
      window.location.host.startsWith("app") ||
      (window.location.pathname.startsWith("/console") &&
        !window.location.pathname.includes("/login"));

    if (auth?.user?.userId) {
      if (
        isConsole &&
        (!auth.user.userType ||
          (auth.user.userType === "PATIENT" && auth.user.userType === "consumer"))
      ) {
        setRedirectPath(
          process.env.REACT_APP_USE_LOGIN_PATH
            ? process.env.REACT_APP_USE_LOGIN_PATH
            : "/console/login",
        );
      }
    } else if (isConsole) {
      setRedirectPath(
        process.env.REACT_APP_USE_LOGIN_PATH
          ? process.env.REACT_APP_USE_LOGIN_PATH
          : "/console/login",
      );
    } else {
      setRedirectPath(
        process.env.REACT_APP_USE_LOGIN_PATH ? process.env.REACT_APP_USE_LOGIN_PATH : "/login",
      );
    }

    // // TODO, figure out why this causes endless loop and enable someday
    // if (!redirectPath && false) {
    //   const fromPS = new URLSearchParams(location.search).get("from-ps");
    //   if (patientSelect && (!fromPS || fromPS !== "true")) {
    //     const ps = "/patient-selection"; // ?r=" + window.location.pathname;
    //     setPatientSelectRedirect(ps);
    //   }
    // }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const hasPermission = (permissions) => {
    if (!(permissions || nav)) {
      return false;
    }
    const doesPathUsePedirect = nav.some(({ link }) => link === location.pathname);

    const hasPermissionToPath =
      doesPathUsePedirect &&
      permissions?.some(({ permission_name }) => location.pathname.includes(permission_name));

    return !doesPathUsePedirect || hasPermissionToPath;
  };

  useEffect(() => {
    if (!hasPermission(auth?.user?.permissions)) {
      setRedirectPath(`/`);
    }
    // eslint-disable-next-line
  }, [auth?.user?.permissions]);

  return (
    !isLoading &&
    (auth && auth.user && auth.user.userId && !redirectPath ? (
      <ErrorBoundaryAxios axiosClient={axiosClient}>
        <Outlet />
      </ErrorBoundaryAxios>
    ) : (
      // ) : auth && auth.user && auth.user.userId && !redirectPath ? (
      //   <Navigation
      //     to={{
      //       pathname: patientSelectRedirect,
      //       search: `r=${path}`,
      //       state: { from: location },
      //     }}
      //   />
      <Navigate
        to={{
          pathname: redirectPath,
          search: location.search,
          state: { from: location },
        }}
      />
    ))
  );
}

export { PrivateRoute, ProvideAuth, useAuth };
