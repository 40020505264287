import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import { useStyles } from "../../../utils/styles";
import { useGetProviderById } from "hooks/dataCuration/useGetProviderById";
import { TabPaneProps } from "../../types";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import InsuranceAcceptedSectionModal from "./InsuranceAcceptedSectionModal";
import { localeTranslationsEn } from "locale/en";

type InsuranceType = {
  id: number;
  name: string;
};

const InsuranceAcceptedSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}) => {
  const app = useApp();
  const { classes } = useStyles();
  const pathnameArray = window.location.pathname.split("/");
  const id = pathnameArray[pathnameArray.length - 1];
  const {
    providerGeneralInfoAndLocation: { insuranceAccepted },
    currentPayload,
    setCurrentPayload,
    refetch,
  } = useProviderContext();

  const { providerData } = useGetProviderById(id);

  const [insurancesList, setInsurancesList] = useState<InsuranceType[]>(
    insuranceAccepted || []
  );
  const [autocompleteValue, setAutocompleteValue] = useState<InsuranceType | null>(null);
  const [insurances, setInsurances] = useState<InsuranceType[]>(
    insuranceAccepted || []
  );
  const [providersList, setProvidersList] = useState([]);
  const [copyInsuranceModal, setCopyInsuranceModal] = useState(false);
  const [copiedProviderInsurance, setCopiedProviderInsurance] = useState([]);
  const [checkedInsurances, setCheckedInsurances] = useState<
    Record<number, boolean>
  >({});
  const [copyProviderFullName, setCopyProviderFullName] = useState("");

  const defaultProviderData = {
    firstName: "",
    middleName: "",
    lastName: "",
    title: "",
  };
  
  const { firstName, middleName, lastName, title } = providerData || defaultProviderData;
  
  const providerName = [firstName, middleName, lastName, title].filter(Boolean).join(" ");  

  const handleChangeInsurances = (
    event: React.SyntheticEvent,
    value: InsuranceType | null
  ) => {
    if (value && !insurances.some((condition) => condition.id === value.id)) {
      setInsurances([...insurances, value]);

      setCurrentPayload((prev) => ({
        insuranceAccepted: [
          ...(prev?.insuranceAccepted || []),
          {
            ...value,
          },
        ],
      }));
    }

    setAutocompleteValue(value);
  };

  useEffect(() => {
    const initialChecked = copiedProviderInsurance.reduce(
      (acc, insurance: InsuranceType) => {
        acc[insurance.id] = false;
        return acc;
      },
      {} as Record<number, boolean>
    );
    setCheckedInsurances(initialChecked);
  }, [copiedProviderInsurance]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedState = Object.fromEntries(
      Object.keys(checkedInsurances).map((id) => [Number(id), event.target.checked])
    );
    setCheckedInsurances(newCheckedState);
  };

  const handleToggleInsurance = (id: number) => {
    setCheckedInsurances((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleRemoveInsurance = (id: number) => {
    const getInsurance = insurances.find((insurance) => insurance.id === id);
    setInsurances(insurances.filter((insurance) => insurance.id !== id));
    setCurrentPayload((prev) => ({
      insuranceAccepted: [
        ...(prev?.insuranceAccepted || []),
        {
          ...getInsurance,
          deleted: true,
        },
      ],
    }));
    setAutocompleteValue(null);
  };

  useEffect(() => {
    if (currentPayload === null) {
      setInsurancesList(insuranceAccepted || []);
    }
  }, [currentPayload]);

  const fetchData = async (searchText) => {
    const dataUrl = API_ENDPOINTS.dataCurationProviders;
    const searchQuery = `search_by=lastName&search_text=${encodeURIComponent(
      searchText
    )}&page_no=${0}&page_size=${20}&order=asc&order_by=lastName`;
    const finalUrl = `${dataUrl}?${searchQuery}`;
    try {
      const { data } = await axiosClient.get(finalUrl);
      setProvidersList(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProvidersList([]);
    }
  };

  const getProviderData = async (providerId) => {
    const dataUrl = API_ENDPOINTS.dataCurationProviders;
    const searchQuery = `${providerId}`;
    const finalUrl = `${dataUrl}${searchQuery}`;
    try {
      const { data } = await axiosClient.get(finalUrl);
      setCopiedProviderInsurance(data.insuranceAccepted);
      const fullNameForProvider = `${data?.firstName || ""} ${
        data?.middleName || ""
      } ${data?.lastName || ""}`;
      setCopyProviderFullName(fullNameForProvider);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProvidersList([]);
    }
  };

  const onChangeSearchProviderList = (e) => {
    fetchData(e.target.value);
  };

  const updateInsurances = async () => {
    const id = pathnameArray[pathnameArray.length - 1];
    const selectedInsuranceIds = Object.entries(checkedInsurances)
      .filter(([_, isChecked]) => isChecked)
      .map(([insuranceId]) => Number(insuranceId));

    const selectedInsurances = copiedProviderInsurance.filter(
      (insurance: InsuranceType) =>
        selectedInsuranceIds.includes(insurance.id)
    );
    try {
      await axiosClient.patch(
        `${API_ENDPOINTS.dataCurationProviders}${id}`,
        {
          insuranceAccepted: selectedInsurances,
        }
      );
      app.addInfoMsg("Insurances updated successfully");
      setInsurances((prevInsurances) => [
        ...prevInsurances.filter(
          (insurance) => !selectedInsuranceIds.includes(insurance.id)
        ),
        ...selectedInsurances,
      ]);
      setCopyInsuranceModal(false);
    } catch (error: any) {
      if (error.response) {
        app.addError(error.response.data.description);
        refetch();
        setCopyInsuranceModal(false);
      } else {
        app.addError("Error updating location. Please try again.");
        refetch();
        setCopyInsuranceModal(false);
      }
    }
  };

  const onSelectProviderToCopyInsurance = (event: any, selectedOption: any) => {
    const selectedInsuranceProvider: any =
      providersList.filter((providerList: any) => providerList.NPI === selectedOption.NPI)[0];
    const providerId = selectedInsuranceProvider.id;
    getProviderData(providerId);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
      padding="24px 16px"
      data-testid="insuranceAcceptedSection"
    >
      <Typography>
        {
          localeTranslationsEn.web.dataCuration.providerProfile.generalInformation
            .InsurancesAccepted.title
        }
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        gap="16px"
        width="100%"
        maxWidth="600px"
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo-provider"
          options={providersList || []}
          value={autocompleteValue}
          getOptionLabel={(option: any) =>
            `${option.lastName}, ${option.firstName}`
          }
          renderInput={(params) => (
            <TextField {...params} label="Search Providers" />
          )}
          onChange={(event, selectedOption) =>
            onSelectProviderToCopyInsurance(event, selectedOption) 
          }
          onInputChange={(e) => onChangeSearchProviderList(e)}
          disabled={shouldDisableForm}
          classes={{ paper: classes.autocompletePaper }}
          fullWidth
          style={{
            margin: "24px 0 16px 0",
            width: "100%",
            maxWidth: "416px",
          }}
        />
        <Button
          onClick={() => setCopyInsuranceModal(true)}
          variant="contained"
          color="primary"
          style={{ whiteSpace: "nowrap" }}
        >
          {
            localeTranslationsEn.web.dataCuration.providerProfile.generalInformation
              .InsurancesAccepted.goButton
          }
        </Button>
      </Box>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={insurancesList || []}
        value={autocompleteValue}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Search Insurances" />
        )}
        onChange={handleChangeInsurances}
        disabled={shouldDisableForm}
        classes={{ paper: classes.autocompletePaper }}
        fullWidth
        style={{
          margin: "24px 0 16px 0",
          width: "100%",
          maxWidth: "416px",
        }}
      />
      <Box className={classes.insurancesList_wrapper}>
        {insurances.length === 0 ? (
          <Typography color="gray">No insurance selected</Typography>
        ) : (
          insurances.map(({ id, name }) => (
            <Chip
              key={id}
              label={name}
              onDelete={() => handleRemoveInsurance(id)}
              disabled={shouldDisableForm}
              style={{ padding: "8px 0" }}
            />
          ))
        )}
      </Box>
      <InsuranceAcceptedSectionModal
        open={copyInsuranceModal}
        onClose={() => setCopyInsuranceModal(false)}
        onUpdateInsurances={updateInsurances}
        copiedProviderInsurance={copiedProviderInsurance}
        checkedInsurances={checkedInsurances}
        handleSelectAll={handleSelectAll}
        handleToggleInsurance={handleToggleInsurance}
        providerName={providerName}
        copyProviderFullName={copyProviderFullName}
      />
    </Grid>
  )
};

export default InsuranceAcceptedSection;
