import { DeterminationQuestions } from "../types/constants";

export const getDefaultCheckboxList = (copy, medicalDeterminationData) => {
  const { correctiveLenses, hearingAid, perfomanceEvaluation } = copy;
  return [
    {
      id: 1,
      name: "lenses",
      label: correctiveLenses,
      value: medicalDeterminationData?.data?.monitoring?.lenses,
      cyName: "FED-MedicalDet-CorrectiveLenses",
    },
    {
      id: 2,
      name: "hearingAid",
      label: hearingAid,
      value: medicalDeterminationData?.data?.monitoring?.hearingAid,
      cyName: "FED-MedicalDet-HearingAid",
    },
    {
      id: 3,
      name: "speCertificate",
      label: perfomanceEvaluation,
      value: medicalDeterminationData?.data?.monitoring?.speCertificate,
      cyName: "FED-MedicalDet-PerformanceEvaluation",
    },
  ];
};

export const getDeterminationQuestions = (
  mode: string,
  copy,
  getDetermination,
): DeterminationQuestions[] => {
  const { question1, question2, question3, question4, question5 } = copy;

  if (mode === "state") {
    return [
      {
        id: 1,
        question: question1,
        determination: "NOT_MEET",
        value: getDetermination(question1),
        textAreaName: "notMeetStandard",
        cyName: "ST_NotMeetStandard",
      },
      {
        id: 2,
        question: question2,
        determination: "MEET",
        value: getDetermination(question2),
        textAreaName: "meetStandardInResolution",
        cyName: "ST_MeetStandardInResolution",
      },
      {
        id: 3,
        question: question3,
        determination: "MEET_WITH_OBSERVATIONS",
        value: getDetermination(question3),
        textAreaName: "meetStandardWithMonitoring",
        cyName: "ST_MeetStandardWithMonitoring",
      },
    ];
  }

  return [
    {
      id: 1,
      question: question1,
      determination: "NOT_MEET",
      value: getDetermination(question1),
      textAreaName: "notMeetStandard",
      cyName: "FED-MedicalDet-NotMeetStandardInput",
    },
    {
      id: 2,
      question: question2,
      determination: "MEET",
      value: getDetermination(question2),
      textAreaName: "meetStandardInResolution",
      cyName: "FED-MedicalDet-MeetStandardInput",
    },
    {
      id: 3,
      question: question3,
      determination: "MEET_WITH_OBSERVATIONS",
      value: getDetermination(question3),
      textAreaName: "meetStandardWithMonitoring",
      cyName: "FED-MedicalDet-MeetStandardWithMonitoringInput",
    },
    {
      id: 4,
      question: question4,
      determination: "PENDING",
      value: getDetermination(question4),
      textAreaName: "determinationPending",
      cyName: "FED-MedicalDet-DeterminationPendingInput",
    },
    {
      id: 5,
      question: question5,
      determination: "INCOMPLETE",
      value: getDetermination(question5),
      textAreaName: "incompleteExamination",
      cyName: "FED-MedicalDet-IncompleteExaminationInput",
    },
  ];
};
