import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Theme from "Theme";
import { isEmpty } from "lodash";
import {
  Close as CloseIcon,
  SwapHoriz as SwapHorizIcon,
  Textsms as TextsmsIcon,
  Today as TodayIcon,
  Videocam as VideocamIcon,
} from "@mui/icons-material";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import VirtualVisitDetails from "../../VirtualVisitDetails";
import CreateEncounterModal from "../CreateEncounterModal";
import CloseSessionModal from "./Modals/CloseSessionModal";
import TransferModal from "./Modals/TransferModal";
import PatientChat from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/PatientChat";
import useChatContext from "components/Video/hooks/useChatContext/useChatContext";
import useCheckOnlineStatus from "components/Video/hooks/useCheckOnlineStatus/useCheckOnlineStatus";
import useVideoContext from "components/Video/hooks/useVideoContext/useVideoContext";
import {
  CLEAR_VIRTUAL_VISIT,
  CONNECT_VIRTUAL_VISIT,
  FETCH_NEW_VIRTUAL_VISITS,
  TOGGLE_QUEUE_TAB,
  UPDATE_AND_SELECT_VIRTUAL_VISIT,
} from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { VirtualSessionQueue, WaitingRoomTab } from "util/constants";
import { releaseStages } from "util/constants/config";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { isRNApp, postRNOpenMeetingRoom } from "util/RNWebView";
import { useAuth } from "util/Security";
import { UserTypes, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { hasFeature } from "util/hasFeature";
import { GetCurrentProgram, useProgramType } from "util/programsHelpers";
import { usePatientProfileStyles } from "../styles";

const ProfileStatus = () => {
  const { classes } = usePatientProfileStyles();
  const { syncMap, isOnline } = useCheckOnlineStatus();

  useEffect(() => {
    return () => syncMap?.close();
  }, [syncMap]);

  return (
    <div className={classes.profileStatusContainer}>
      <div
        className={`${classes.statusCircle} ${
          isOnline && syncMap ? classes.online : classes.offline
        }`}
      />
      {syncMap && <div>{isOnline ? "Waiting in the room" : "Not in the room"}</div>}
      {!syncMap && <div>Unknown</div>}
    </div>
  );
};

const PatientProfile = () => {
  const { classes, cx } = usePatientProfileStyles();
  const navigate = useNavigate();

  const auth = useAuth();
  const app = useApp();
  const { programId, departmentList } = usePrograms();
  const currentProgram = GetCurrentProgram();
  const { room } = useVideoContext();
  const queryClient = useQueryClient();
  const { isPhysician, isNurse } = useUserType();
  const isMobile = useIsMobile();

  const [profile, setProfile] = useState(null);
  const [urgency, setUrgency] = useState("");
  const { setIsChatWindowOpen, isChatWindowOpen } = useChatContext();
  const [openEncounterModal, setOpenEncouneterModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openCloseSessionModal, setOpenCloseSessionModal] = useState(false);
  const [assignUsersList, setAssignUsersList] = useState([]);

  const params = new URLSearchParams(window.location.search);
  const currentVirtualVisit = app?.vs_visits_state?.currentVirtualVisit ?? params.get("vsId");
  const currentUserId = Number(auth?.user.userId);

  const isConsultation = currentVirtualVisit?.type === "consultation";
  const { isAdhoc, isOnDemand, isVUC } = useProgramType();

  const isDepartmentAssigned = Boolean(currentVirtualVisit?.department_id);
  const isAssignedToMe =
    Number(currentVirtualVisit?.currently_assigned_staff_user_id) === Number(auth?.user?.userId);

  const invalidateQuery = () => {
    queryClient.invalidateQueries("fetch-awaiting-vs-visit", programId);
  };

  const getPatientProfile = async (vvId) => {
    try {
      const reqUrl = "/api/s/console/virtualvisitconsole/patient-profile-info";
      const { data } = await axiosClient.get(reqUrl, { params: { virtual_session_id: vvId } });
      setUrgency(data?.data?.virtual_session_info?.urgency || "");
      setProfile(data?.data);
      const assignedDep = departmentList.find(
        (dep) => dep.id === data.data.virtual_session_info.department_id,
      );
      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...currentVirtualVisit,
          department_id: assignedDep?.id || "",
          currently_assigned_staff_user_id:
            data?.data?.virtual_session_info?.currently_assigned_staff_user_id || "",
        },
      });
    } catch (error) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    if (isMobile) {
      setIsChatWindowOpen(false);
    }

    if (!isMobile) {
      setIsChatWindowOpen(true);
    }
  }, [isMobile, setIsChatWindowOpen]);

  useEffect(() => {
    if (currentVirtualVisit?.id) {
      getPatientProfile(currentVirtualVisit.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.id]);

  const assignDepartment = async (departmentId) => {
    try {
      await axiosClient
        .post("/api/s/console/virtualvisitconsole/assigned-department", {
          virtual_session_id: currentVirtualVisit?.id,
          assigned_department_id: departmentId,
        })
        .then(() => {
          app.dispatch({ type: FETCH_NEW_VIRTUAL_VISITS });
          invalidateQuery();
        });

      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: { ...currentVirtualVisit, department_id: departmentId },
      });
      setProfile({
        ...profile,
        virtual_session_info: {
          ...profile?.virtual_session_info,
          department_id: departmentId,
        },
      });
      app.addInfoMsg("Department has been assigned successfully.");
    } catch (e) {
      app.addError("Problem assigning department.");
    }
  };

  const getAssignUsersList = async () => {
    try {
      const queue = currentVirtualVisit?.queue;
      let roleFilter = UserTypes.STAFF;
      if (queue === VirtualSessionQueue.REGISTERING) {
        roleFilter = UserTypes.REGISTRATION_STAFF;
      } else if (queue === VirtualSessionQueue.NURSE_TRIAGE) {
        roleFilter = UserTypes.NURSE;
      } else if (queue === VirtualSessionQueue.PHYSICIAN_VISIT) {
        roleFilter = UserTypes.PHYSICIAN;
      } else if (queue === VirtualSessionQueue.CHECKOUT) {
        roleFilter = UserTypes.REGISTRATION_STAFF;
      }

      let url = `/api/s/console/virtualvisitconsole/get-users?programId=${programId}`;

      if (isVUC === true) {
        url += `&user_type=${roleFilter}`;
      }

      const {
        data: { data },
      } = await axiosClient.get(url);
      setAssignUsersList(data);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    if (currentVirtualVisit?.queue) {
      getAssignUsersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.queue]);

  const assignStaff = (userId) => {
    try {
      const url = "api/s/console/virtualvisitconsole/currently-assigned-staff-user";

      axiosClient
        .post(url, {
          virtual_session_id: currentVirtualVisit?.id,
          currently_assigned_staff_user_id: userId,
        })
        .then(() => {
          app.dispatch({ type: FETCH_NEW_VIRTUAL_VISITS });
          invalidateQuery();
        });

      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...currentVirtualVisit,
          currently_assigned_staff_user_id: userId,
        },
      });
      setProfile({
        ...profile,
        virtual_session_info: {
          ...profile?.virtual_session_info,
          currently_assigned_staff_user_id: userId,
        },
      });
      app.addInfoMsg("Staff has been assigned successfully.");
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  const updateUrgency = async (urgency) => {
    try {
      setUrgency(urgency);

      const url = "/api/s/console/virtualvisitconsole/update-urgency";
      await axiosClient
        .post(url, {
          virtual_session_id: currentVirtualVisit?.id,
          urgency,
        })
        .then(() => {
          app.dispatch({ type: FETCH_NEW_VIRTUAL_VISITS });
          invalidateQuery();
        });

      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...currentVirtualVisit,
          urgency,
        },
      });
      setProfile({
        ...profile,
        virtual_session_info: {
          ...profile?.virtual_session_info,
          urgency,
        },
      });
      app.addInfoMsg("Urgency updated successfully.");
    } catch (error) {
      app.addError("Problem updating urgency.");
    }
  };

  const onConfirmMatchSuccess = (vsId) => {
    // SHOW DEFAULT VIEW
    if (!isEmpty(room)) {
      room.disconnect();
    }
    app.dispatch({
      type: CLEAR_VIRTUAL_VISIT,
    });
    app.dispatch({
      type: CONNECT_VIRTUAL_VISIT,
      payload: vsId,
    });
    app.dispatch({
      type: TOGGLE_QUEUE_TAB,
      payload: WaitingRoomTab.PATIENT,
    });
  };

  useEffect(() => {
    if (
      currentProgram?.short_name &&
      currentProgram?.short_name in releaseStages &&
      !hasFeature(currentProgram?.short_name)
    ) {
      window.open(`${window.location.origin}/console`, "_self");
    }
  }, [currentProgram]);

  if (!currentVirtualVisit?.id) {
    return <div className={classes.selectVisitTitle}>Please select a virtual visit...</div>;
  }

  return (
    <>
      <Grid className={classes.patientDemographics} item xs={12} md={8}>
        <Grid container direction="column">
          <Typography variant="h5" className={classes.profileTitle}>
            Patient profile
          </Typography>
          <ProfileStatus />
          <Grid container alignItems="flex-start" className={classes.optionsContainer}>
            {!!departmentList?.length && (
              <TextField
                data-cy="assignToBtnDepartmentVUC"
                label="Assign Department"
                id="AssignDepartment"
                variant="outlined"
                className={classes.select}
                InputProps={{ classes: { input: classes.selectInput } }}
                InputLabelProps={{ classes: { root: classes.selectLabel } }}
                size="small"
                value={currentVirtualVisit.department_id}
                onChange={(e) => assignDepartment(e.target.value)}
                select
                disabled={isOnDemand && (isPhysician || isNurse)}
              >
                {departmentList.map((department, index) => (
                  <MenuItem
                    data-cy={`VUCDep-${index.toString()}`}
                    key={department.id}
                    value={department.id}
                  >
                    {department.name}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {!isConsultation && (isDepartmentAssigned || departmentList.length < 1) && (
              <TextField
                label="Urgency"
                id="Urgency"
                variant="outlined"
                className={classes.select}
                InputProps={{ classes: { input: classes.selectInput } }}
                InputLabelProps={{ classes: { root: classes.selectLabel } }}
                size="small"
                value={urgency}
                onChange={(e) => updateUrgency(e.target.value)}
                select
              >
                <MenuItem value="routine">Routine</MenuItem>
                <MenuItem value="urgent">Urgent</MenuItem>
                <MenuItem value="low_priority">Low Priority</MenuItem>
              </TextField>
            )}
            {(isDepartmentAssigned || departmentList.length < 1 || isConsultation) && (
              <TextField
                data-cy="assignBtn"
                label="Assign to"
                id="AssignTo"
                variant="outlined"
                className={classes.select}
                InputProps={{ classes: { input: classes.selectInput } }}
                InputLabelProps={{ classes: { root: classes.selectLabel } }}
                size="small"
                value={currentVirtualVisit.currently_assigned_staff_user_id}
                onChange={(e) => assignStaff(e.target.value)}
                select
              >
                <MenuItem value={currentUserId} data-cy="Me">
                  Me
                </MenuItem>
                {assignUsersList?.map((user) => {
                  const name = [user.first_name, user.last_name].filter(Boolean).join(" ");

                  return (
                    user.id !== currentUserId && (
                      <MenuItem value={user.id}>{name || user.email}</MenuItem>
                    )
                  );
                })}
              </TextField>
            )}

            <Grid container className={classes.ctaContainer}>
              {isMobile && isAssignedToMe && (
                <Button
                  variant="contained"
                  classes={{
                    root: `${classes.cta} ${classes.chatIconBtn}`,
                  }}
                  onClick={() => {
                    setIsChatWindowOpen(true);
                  }}
                >
                  <TextsmsIcon
                    fontSize="small"
                    classes={{ fontSizeSmall: classes.fontSizeSmall }}
                  />
                </Button>
              )}
              {!currentVirtualVisit.external_emr_encounter_id && (isAdhoc || isVUC) && (
                <Button
                  variant="contained"
                  onClick={() => setOpenEncouneterModal(true)}
                  className={cx(classes.cta, classes.createEncounterBtn)}
                >
                  <TodayIcon fontSize="small" classes={{ fontSizeSmall: classes.fontSizeSmall }} />
                  {!isMobile && "Create encounter"}
                </Button>
              )}
              {openEncounterModal && (
                <CreateEncounterModal
                  open={openEncounterModal}
                  onClose={() => setOpenEncouneterModal(false)}
                />
              )}
              <Button
                variant="contained"
                className={`${classes.cta} ${classes.closeBtn}`}
                onClick={() => setOpenCloseSessionModal(true)}
                data-cy="closeSessionButton"
              >
                <CloseIcon fontSize="small" classes={{ fontSizeSmall: classes.fontSizeSmall }} />
                <span className={classes.buttonLabel}>Close Session</span>
              </Button>
              {openCloseSessionModal && (
                <CloseSessionModal
                  isOpen={openCloseSessionModal}
                  handleClose={() => setOpenCloseSessionModal(false)}
                />
              )}

              {!(isConsultation || isAdhoc) && (
                <Button
                  variant="contained"
                  onClick={() => setOpenTransferModal(true)}
                  className={`${classes.cta} ${classes.transferBtn}`}
                  data-cy="transferButton"
                >
                  <SwapHorizIcon
                    fontSize="small"
                    classes={{ fontSizeSmall: classes.fontSizeSmall }}
                  />
                  <span className={classes.buttonLabel}>Transfer</span>
                </Button>
              )}
              {openTransferModal && (
                <TransferModal
                  isOpen={openTransferModal}
                  handleClose={() => setOpenTransferModal(false)}
                />
              )}

              <Button
                variant="contained"
                classes={{
                  root: `${classes.cta} ${classes.launchVideoBtn}`,
                  disabled: classes.launchVideoBtnDisabled,
                }}
                onClick={() => {
                  if (isAssignedToMe) {
                    room?.disconnect();
                    params.delete("isConferenceCall");

                    if (isRNApp) {
                      postRNOpenMeetingRoom(currentVirtualVisit?.id);
                    } else if (isMobile) {
                      app.dispatch({
                        type: CLEAR_VIRTUAL_VISIT,
                      });
                      navigate({
                        pathname: `/video/${currentVirtualVisit.id}`,
                        search: params.toString(),
                      });
                    } else {
                      window.open(
                        `/video/${currentVirtualVisit.id}?${params.toString()}`,
                        "_blank",
                        "noopener, noreferrer",
                      );
                    }
                  }
                }}
                disabled={!isAssignedToMe}
              >
                <VideocamIcon fontSize="small" classes={{ fontSizeSmall: classes.fontSizeSmall }} />
                <span className={classes.buttonLabel}>Join Session</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {profile && (
          <VirtualVisitDetails
            profile={profile}
            showUserDetails
            currentVirtualVisit={currentVirtualVisit}
            getPatientProfile={getPatientProfile}
            onConfirmMatchSuccess={onConfirmMatchSuccess}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        style={{
          boxShadow: "-1px 0px 0px rgba(0, 0, 0, 0.14)",
          position: "relative",
          background: Theme.palette.common.white,
          zIndex: 1,
        }}
      >
        {profile && isChatWindowOpen && (
          <PatientChat vsId={profile?.virtual_session_info?.id} isReadOnly={!isAssignedToMe} />
        )}
      </Grid>
    </>
  );
};

export default PatientProfile;
