import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import useTranslation from "hooks/useTranslation";
import urgentCareBellIcon from "../../../static/svg/mobile/urgentcarebell.svg";

const useStyles = makeStyles()(() => ({
  urgentCareBannerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.25em",
    height: "54px",
    backgroundColor: "#fff",
    padding: "8px 50px",
    position: "fixed",
  },
  urgentCareText: {
    fontSize: "1em",
    color: "#5d5d5d",
    lineHeight: "17px",
    textAlign: "left",
    fontWeight: 300,
  },
}));

const UrgentCareBanner = () => {
  const { classes } = useStyles();
  const { mobile } = useTranslation();
  const { UrgentCareBanner } = mobile;

  return (
    <div className={classes.urgentCareBannerContainer}>
      <img src={urgentCareBellIcon} alt="" />
      <Typography className={classes.urgentCareText}>{UrgentCareBanner}</Typography>
    </div>
  );
};

export default UrgentCareBanner;
