/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { useProgramsLoading } from "views/console/components/useProgramsLoading";
import useTranslation from "hooks/useTranslation";
import { LockOrientation } from "util/useLockOrientation";
import { useIsMobile } from "util/deviceUtils";
import { SpecimenTrackAction } from "../../types";
import { useSpecimenStyles } from "../../styles";

export const SpecimenTrackingPickFlow = () => {
  const navigate = useNavigate();
  const { classes } = useSpecimenStyles();
  const isMobile = useIsMobile();
  const {
    web: {
      specimenTracking: { addSpecimen, dropOffSpecimen, pickUpSpecimen },
    },
  } = useTranslation();

  const { isProgramsLoading } = useProgramsLoading();

  const processSpecimen = (actionType: SpecimenTrackAction) => {
    navigate(`/console/specimen-tracking/add-specimen?actionType=${actionType}`, {
      replace: true,
    });
  };

  return (
    <LockOrientation isMobile={isMobile} orientation="portrait">
      <Grid
        item
        xs={12}
        className={classes.locationWrapper}
        style={{ maxWidth: isMobile ? "100%" : "60%", margin: "0 auto", paddingTop: "1em" }}
      >
        <Box className={classes.pickFlowButtonWrapper}>
          <Button
            data-test-id="specimenTracking-pick-flow-add"
            data-cy="specimenTracking-pick-flow-add"
            variant="contained"
            color="primary"
            onClick={() => processSpecimen(SpecimenTrackAction.ADD)}
            className={classes.pickFlowButton}
            disabled={isProgramsLoading}
          >
            {addSpecimen}
          </Button>

          <Button
            data-test-id="specimenTracking-pick-flow-collect"
            data-cy="specimenTracking-pick-flow-collect"
            variant="contained"
            color="primary"
            onClick={() => processSpecimen(SpecimenTrackAction.DROP_OFF)}
            className={classes.pickFlowButton}
            disabled={isProgramsLoading}
          >
            {dropOffSpecimen}
          </Button>

          <Button
            data-test-id="specimenTracking-pick-flow-collect"
            data-cy="specimenTracking-pick-flow-collect"
            variant="contained"
            color="primary"
            onClick={() => processSpecimen(SpecimenTrackAction.PICK_UP)}
            className={classes.pickFlowButton}
            disabled={isProgramsLoading}
          >
            {pickUpSpecimen}
          </Button>
        </Box>
      </Grid>
    </LockOrientation>
  );
};

export default SpecimenTrackingPickFlow;
