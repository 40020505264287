type Permissions = {
  comms: string;
  familyJournal: string;
  patient: string;
  referral: string;
  virtualvisit: string;
  virtualvisitconsole: string;
  config: string;
  user: string;
  admin: string;
  consultation: string;
  campaigns: string;
  adhocVirtual: string;
  specialistOnDemand: string;
  immediatecare: string;
  providerCellphone: string;
  providerSurvey: string;
  surveyConfig: string;
  specimenTracking: string;
  dotForm: string;
  location: string;
  dataGovernance: string;
};

export const permissions: Permissions = {
  comms: "comms",
  familyJournal: "family-journal",
  patient: "patient",
  referral: "referral",
  virtualvisit: "virtualvisit",
  immediatecare: "immediate-care",
  virtualvisitconsole: "virtualvisitconsole",
  config: "config",
  user: "user",
  admin: "admin",
  consultation: "consultation",
  campaigns: "campaigns",
  adhocVirtual: "adhoc-virtual",
  specialistOnDemand: "neuroondemand",
  providerCellphone: "provider-cellphone",
  providerSurvey: "provider-survey",
  surveyConfig: "survey-config",
  specimenTracking: "specimen-tracking",
  dotForm: "dot-form",
  location: "location",
  dataGovernance: "data-governance",
};
