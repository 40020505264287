import React from "react";

const ClosedCaptionOnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22px"
      height="22px"
      viewBox="0 0 256 256"
      data-testid="closed-caption-on-icon"
    >
      <path
        fill="black"
        d="M216,44H40A12.01312,12.01312,0,0,0,28,56V200a12.01312,12.01312,0,0,0,12,12H216a12.01312,12.01312,0,0,0,12-12V56A12.01312,12.01312,0,0,0,216,44Zm4,156a4.004,4.004,0,0,1-4,4H40a4.004,4.004,0,0,1-4-4V56a4.004,4.004,0,0,1,4-4H216a4.004,4.004,0,0,1,4,4ZM72,128a23.99939,23.99939,0,0,0,41.14258,16.7959,4.00047,4.00047,0,1,1,5.71484,5.59961,32,32,0,1,1,0-44.791,4.00047,4.00047,0,1,1-5.71484,5.59961A23.99939,23.99939,0,0,0,72,128Zm72,0a23.99939,23.99939,0,0,0,41.14258,16.7959,4.00047,4.00047,0,1,1,5.71484,5.59961,32,32,0,1,1,0-44.791,4.00047,4.00047,0,1,1-5.71484,5.59961A23.99939,23.99939,0,0,0,144,128Z"
      />
    </svg>
  );
};

export default ClosedCaptionOnIcon;
