const colDefs = [
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "locationName",
    numeric: false,
    disablePadding: true,
    label: "Location",
    searchable: true,
    sortable: true,
  },
  {
    id: "NPI",
    numeric: false,
    disablePadding: false,
    label: "NPI",
    searchable: true,
    sortable: true,
  },
  {
    id: "nationalRegistryNumber",
    numeric: false,
    disablePadding: false,
    label: "NRN",
    searchable: true,
    sortable: false,
    isCustomContent: true,
    func: (row) => row,
  },
  {
    id: "certificationNumber",
    numeric: false,
    disablePadding: false,
    label: "State Certificate Number",
    searchable: true,
    sortable: false,
  },
  {
    id: "cellphone",
    numeric: false,
    disablePadding: false,
    label: "Cell Phone",
    searchable: true,
    sortable: false,
  },
  {
    id: "providerSpecialty",
    numeric: false,
    disablePadding: false,
    label: "Specialty",
    searchable: true,
    sortable: true,
  },
];

export default colDefs;
