import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

export interface DigitalImplantResponse {
  ImplantType: string;
  ImplantDate: string;
  ImplantLocation: string;
  ImplantHospital: string;
  ImplantPhysician: string;
  DeviceDescription: string;
  DeviceBrandName: string;
  DeviceModel: string;
  DevicePublishDate: string;
  DeviceID: string;
}

export const useGetDigitalImplants = () => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchQuery = async () => {
    const response = await axiosClient.get(API_ENDPOINTS.digitalImplants);
    return response.data as DigitalImplantResponse[];
  };

  const {
    data: digitalImplantsData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.digitalImplantGet],
    queryFn: fetchQuery,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  return {
    digitalImplantsData,
    refetch,
    isLoading,
    isFetching,
  };
};
