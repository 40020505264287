import React from "react";
import { Grid, Switch, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { HolidaysEditProps } from "components/HolidaysEdit/types";

const useStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: 20,
  },
  holidayDate: {
    color: theme.palette.grey[400],
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const HolidaysEdit = ({ holidays, setHolidays }: HolidaysEditProps) => {
  const { classes } = useStyles();

  const handleChangeHoliday = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedHolidays = [...holidays];
    updatedHolidays[index].enabled = event.target.checked;
    setHolidays(updatedHolidays);
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="h4" align="left" className={classes.title}>
          Holidays
        </Typography>
      </Grid>
      {holidays.map((holiday, index) => (
        <Grid
          item
          container
          key={holiday.date}
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Typography>
              {holiday.name} <span className={classes.holidayDate}>({holiday.date})</span>
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={holiday.enabled}
              onChange={(event) => handleChangeHoliday(event, index)}
              name={holiday.name}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default HolidaysEdit;
