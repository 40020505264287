import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import SpecimenPhotoModal from "./modals/SpecimenPhotoModal";
import useTranslation from "hooks/useTranslation";
import { SpecimenAddResponse, SpecimenDetails } from "views/SpecimenTracking/types";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";

interface Props {
  showDetailsModal: boolean;
  setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  detailsData: SpecimenDetails;
  setScannedCodes: React.Dispatch<React.SetStateAction<SpecimenAddResponse[]>>;
}

const ScannedSpecimenDetailsModal = ({
  showDetailsModal,
  setShowDetailsModal,
  detailsData,
  setScannedCodes,
}: Props) => {
  const { classes } = useSpecimenStyles();
  const {
    web: {
      specimenTracking: {
        mrnId,
        orderId,
        patientFirstName,
        patientLastName,
        patientDob,
        specimenType,
        specimenInfo,
        scanSuccessful,
        takePhotoOfSpecimen,
      },
    },
  } = useTranslation();

  const [showSpecimenPhotoModal, setShowSpecimenPhotoModal] = useState<boolean>(false);

  const updateSpecimenImage = (updatedImage, specimenExternalId) => {
    setScannedCodes((prevCodes) => {
      return prevCodes.map((code) => {
        if (code.data.specimenExternalId === specimenExternalId) {
          return { ...code, data: { ...code.data, specimenImage: updatedImage } };
        }
        return code;
      });
    });
  };

  return (
    <div>
      {showDetailsModal && (
        <div className={classes.scannedDetailBackdrop}>
          <Box className={classes.scannedDetailModal}>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography variant="h5" style={{ fontWeight: "800" }}>
                {scanSuccessful}
              </Typography>
              <IconButton
                onClick={() => {
                  setShowDetailsModal(false);
                }}
                style={{ padding: 0 }}
                data-testid="closeIcon"
                size="large"
              >
                <Close />
              </IconButton>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ gap: "8px" }}
            >
              <Typography style={{ fontSize: "18px", fontWeight: "800" }}>
                {detailsData.specimenExternalId}
              </Typography>
              <Typography className={classes.scannedDetailText}>
                <span>{mrnId}: </span>
                {detailsData.orderMrn}
              </Typography>
              <Typography className={classes.scannedDetailText}>
                <span>{orderId}: </span>
                {detailsData.orderOrderExternalId}
              </Typography>
              <Typography className={classes.scannedDetailText}>
                <span>{patientFirstName}: </span>
                {detailsData.orderPatientFirstName}
              </Typography>
              <Typography className={classes.scannedDetailText}>
                <span>{patientLastName}: </span>
                {detailsData.orderPatientLastName}
              </Typography>
              <Typography className={classes.scannedDetailText}>
                <span> {patientDob}: </span>
                {detailsData.orderDob}
              </Typography>
              <Typography className={classes.scannedDetailText}>
                <span>{specimenType}: </span>
                {detailsData.specimenType}
              </Typography>
              <Typography className={classes.scannedDetailText}>
                <span>{specimenInfo}: </span>
                {detailsData.description}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              paddingX="16px"
              style={{ gap: "8px", width: "100%" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ width: "100%" }}
                onClick={() => {
                  setShowDetailsModal(false);
                }}
              >
                OK
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                style={{ width: "100%" }}
                onClick={() => {
                  setShowDetailsModal(false);
                  setShowSpecimenPhotoModal(true);
                }}
              >
                {takePhotoOfSpecimen}
              </Button>
            </Box>
          </Box>
        </div>
      )}
      <SpecimenPhotoModal
        open={showSpecimenPhotoModal}
        onClose={() => setShowSpecimenPhotoModal(false)}
        specimenDetail={detailsData}
        replacePhoto
        updateSpecimenImage={updateSpecimenImage}
      />
    </div>
  );
};

export default ScannedSpecimenDetailsModal;
