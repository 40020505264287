import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Paper, TextField } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import HelpModal from "../components/HelpModal";
import { useApp } from "../util/AppContext";
import { useAuth } from "../util/Security";
import RoutesHelper from "util/routesHelper";

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: "60px",
    maxWidth: "640px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  rootForm: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  inputRoot: {
    fontSize: 22,
    height: "56px",
  },
  labelRoot: {
    fontSize: 22,
  },
  links: {
    fontSize: 20,
    padding: "5px",
    textTransform: "Capitalize",
    border: "none",
    textDecoration: "underline",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginBottom: "40px",
  },
  textField: {
    marginBottom: "15px",
    width: "100%",
  },
  completeBtn: {
    marginTop: "20px",
    fontWeight: 600,
  },
}));

export default function SetUserPwdView() {
  const { classes } = useStyles();
  const auth = useAuth();
  const [email, setEmail] = useState(auth?.user.email);
  const [password, setPassword] = useState("");
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const { redirectHelper } = RoutesHelper();

  const navigate = useNavigate();
  const location = useLocation();
  const app = useApp();
  const submitButtonRef = useRef(null);
  const params = new URLSearchParams(location.search);

  const handleSetUserPwd = async () => {
    await auth.setUserPwd(email, password, (userId, error, response) => {
      if (!userId || userId.length < 1 || error) {
        if (response.status === 409) {
          app.addError(
            "MyChart account is already active or username already taken.  Please try to login or use forgot username or password.",
          );
        } else if (response.status === 422) {
          app.addError(
            "Failed to set username and password, please check password policy and try again.",
          );
        } else {
          app.addError(
            "Failed to activate your account, it may not be in a status eligible for self-service activation.  Please contact support or try an alternative mychart activation process.",
          );
        }

        return;
      }

      redirectHelper(params);
    });
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (submitButtonRef && submitButtonRef.current) {
          submitButtonRef.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const handleHelpModalOpen = () => {
    setHelpModalOpen(true);
  };

  const handleHelpModalClose = () => {
    setHelpModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={24}>
        <form
          className={classes.rootForm}
          noValidate
          autoComplete="off"
          onSubmit={handleSetUserPwd}
        >
          <h1>Final Step</h1>
          <h2 className={classes.subtitle}>
            Please set your email and a password to complete online account activation:
          </h2>
          <TextField
            id="email"
            label="Email or Username"
            inputProps={{
              classes: { root: classes.inputRoot },
              maxLength: 30,
            }}
            helperText="Must be between 6 and 30 characters"
            variant="outlined"
            className={classes.textField}
            value={email}
            onInput={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            inputProps={{ classes: { root: classes.inputRoot } }}
            helperText="Password must be at least 8 characters and contain an Uppercase, lowercase, number, and special character: '$', '@', '#', '%', '!', '#', '*', '&amp;'"
            className={classes.textField}
            value={password}
            onInput={(e) => setPassword(e.target.value)}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            type="button"
            ref={submitButtonRef}
            onClick={handleSetUserPwd}
            className={classes.completeBtn}
          >
            Complete Registration
          </Button>
        </form>
      </Paper>
      <button className={classes.links} onClick={handleHelpModalOpen} type="button">
        Help?
      </button>
      <span> or </span>
      <button
        className={classes.links}
        onClick={() => {
          navigate("/login");
        }}
        type="button"
      >
        Sign In if already active
      </button>
      <HelpModal isOpen={isHelpModalOpen} handleClose={handleHelpModalClose} />
    </div>
  );
}
