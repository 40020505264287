import React from "react";
import { formatDistanceStrict } from "date-fns";
import UrgencyCircle from "../../components/Video/components/PreJoinScreensPhysician/WaitingRoom/UrgencyCircle";
import { circle } from "util/constants";
import { getTimeAgo } from "../../util/util_functions";
import useStyles from "./MobileReportView.styles";
import timer from "../../static/svg/timer.svg";

const MobileReportView = ({ sourceData, onClickedRowHandler }) => {
  const { classes } = useStyles();

  return (
    <div>
      {sourceData.map((item, index) => (
        <div
          onClick={(e) => onClickedRowHandler(e, item, index)}
          className={classes.itemContainer}
          key={item.id}
        >
          <UrgencyCircle color={circle[item?.urgency]} />
          <div>
            <div className={classes.userName}>
              {`${item.currently_assigned_staff_user_first_name} ${item.currently_assigned_staff_user_last_name}`}
            </div>
            {item.callback_phone && <div className={classes.userName}>{item.callback_phone}</div>}
            {item.patient_reason && <div className={classes.userInfo}>{item.patient_reason}</div>}
            <div className={classes.userInfo}>
              {item?.user_dob && (
                <span>
                  {formatDistanceStrict(new Date(), new Date(item?.user_dob), {
                    unit: "year",
                    roundingMethod: "floor",
                  })}{" "}
                  old
                </span>
              )}
              {item?.user_dob && item?.user_gender && <span>,</span>}
              {item?.user_gender && <span> {item?.user_gender}</span>}
            </div>
            <div className={classes.userInfo}>
              <img src={timer} alt="" className={classes.imageIcon} />
              <span>Joined {getTimeAgo(item.start_dttm)}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileReportView;
