import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { PhotoCamera } from "@mui/icons-material";
import { Box, Button, Container } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AddManuallyAddNewSpecimenModal } from "views/SpecimenTracking/views/AddSpecimen/components/modals/addManuallyAddNewSpecimenModal";
import useTranslation from "hooks/useTranslation";
import { FacingModesTypes, Props } from "./types";

const useStyles = makeStyles()((theme) => ({
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    marginBottom: "8px",
  },
  webCamContainer: {
    objectFit: "cover",
  },
  closeBtn: {
    color: theme.palette.grey[500],
  },
  captureButton: {
    height: "3rem",
    width: "100%",
  },
}));

export const WebcamPhoto = ({ webcamRef, capture, skeleton, handleScanNewSpecimen }: Props) => {
  const videoConstraints = {
    facingMode: FacingModesTypes.ENVIRONMENT,
  };

  const {
    web: {
      specimenTrackingAdd: { scanLabel },
    },
  } = useTranslation();

  const [enableButton, setEnableButton] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEnableButton(true);
    }, 1000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <Container style={{ padding: "0" }}>
      {!enableButton && <span>Please wait</span>}
      <Box style={{ position: "relative" }}>
        <Webcam
          audio={false}
          ref={webcamRef}
          height={250}
          width="100%"
          videoConstraints={videoConstraints}
          screenshotFormat="image/png"
          className={classes.webCamContainer}
          screenshotQuality={1}
        />

        {skeleton?.()}
      </Box>

      <Box className={classes.btnContainer}>
        <AddManuallyAddNewSpecimenModal handleScanNewSpecimen={handleScanNewSpecimen} />
        <Button
          variant="contained"
          color="primary"
          startIcon={<PhotoCamera />}
          onClick={(e) => {
            capture(e);
          }}
          disabled={!enableButton}
          data-cy="capturePhoto"
          className={classes.captureButton}
        >
          {scanLabel}
        </Button>
      </Box>
    </Container>
  );
};
