import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SESSION_KEYS } from "util/constants/sessionsKeys";
import { useAppEvent } from "hooks/useAppEvent/useAppEvent";
import { UseMsal } from "hooks/useMsal/useMsal";
import { useAuth } from "../util/Security";
import { UserTypes } from "../util/api_helper";

export default function HomePageRouter() {
  useAppEvent();
  const { login } = UseMsal();

  const tokenMsal = useMemo(() => sessionStorage.getItem(SESSION_KEYS.MSAL_TOKEN), []);

  const auth = useAuth();
  const navigate = useNavigate();
  const { host } = window.location;
  // TODO make configurable by role

  const handleMsal = async () => {
    try {
      const redirectTo = await login(tokenMsal);
      if (redirectTo.data.redirect) {
        window.location.href = redirectTo.data.redirect;
      }
    } catch (error) {
      console.log(`[MSAL] Error logging in with MSAL HomePageRouter: ${error}`);
    }
  };

  useEffect(() => {
    const { search } = window.location;
    const isAppHost = host?.startsWith("app");
    const defaultLoginPath = isAppHost ? `/console/login${search}` : `/login${search}`;
    const defaultHomePath = isAppHost ? `/console${search}` : `/home${search}`;

    const loginPath = process.env.REACT_APP_USE_LOGIN_PATH || defaultLoginPath;
    let homePath = defaultHomePath;

    const checkIfLoggedIn = () => {
      if (auth.user && auth.user.userId) {
        // eslint-disable-next-line
        if (auth.user.userType !== UserTypes.PATIENT && auth.user.userType !== UserTypes.CONSUMER) {
          homePath = `/console${window.location.search}`;
        }
        console.log(`home router pushing to home: ${homePath}`);
        navigate(homePath);
      } else {
        if (tokenMsal) {
          handleMsal();
        }
        console.log(`home router pushing to login: ${loginPath}`);
        navigate(loginPath);
      }
    };
    checkIfLoggedIn();
    // eslint-disable-next-line
  }, []);

  return <div>Redirecting...</div>;
}
