import { useContext } from "react";
import { CaptionsContext } from "components/Video/components/CaptionsProvider/CaptionsProvider";

export default function useCaptionsContext() {
  const context = useContext(CaptionsContext);
  if (!context) {
    throw new Error("useCaptionsContext must be used within a CaptionsProvider");
  }
  return context;
}
