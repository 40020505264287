import React from "react";
import { times } from "lodash";
import { Box, Typography } from "@mui/material";
import TimeSlotPickerChip from "./TimeSlotPickerChip";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gridContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 6,
    flex: 1,
    marginRight: 44,
    marginTop: 24,
    marginBottom: 24,

    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      marginTop: 0,
      marginBottom: 7,

      "& > *:not(:first-child)": {
        display: "none",
      },
    },
  },
  timeSlotColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
  },
  availabilityTitle: {
    alignSelf: "start",
    marginLeft: 7,
    marginBottom: 7,
    fontSize: 12,
    fontWeight: 500,

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  viewAllAvailability: {
    alignSelf: "start",
    marginLeft: 7,
    marginBottom: 16,
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.primary.main,
    textDecoration: "underline",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const TimeSlotPickerGrid = ({ timeSlotInfo, amountOfTimeSlotsToShow, handleTimeSlotClick }) => {
  const { classes } = useStyles();

  return (
    <>
      <Typography className={classes.availabilityTitle}>Today's availability</Typography>
      <Box className={classes.gridContainer}>
        {timeSlotInfo.days.map((dayInfo) => (
          <Box className={classes.timeSlotColumn}>
            {times(amountOfTimeSlotsToShow, (iteration) => (
              <TimeSlotPickerChip
                timeLabel={dayInfo.timeSlots[iteration]}
                onClick={handleTimeSlotClick}
              />
            ))}
          </Box>
        ))}
      </Box>
      <Typography className={classes.viewAllAvailability}>View all availability</Typography>
    </>
  );
};

export default TimeSlotPickerGrid;
