import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  stepperDesctop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  stepperMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%",
      height: "70px",
      marginBottom: "10px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#E7EBF4",
    },
  },
  backButton: {
    position: "absolute",
    left: "15px",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: "150px",
    margin: "10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  buttonActive: {
    color: theme.palette.primary.main,
    width: "150px",
    margin: "10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: "22px",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      fontWeight: 500,
    },
  },
  option: {
    width: "40%",
    borderWidth: "1px",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    marginBottom: "10px",
    marginTop: "10px",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  optionActive: {
    width: "40%",
    backgroundColor: theme.palette.primary.main,
    marginBottom: "10px",
    marginTop: "10px",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  optionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  optionTitle: {
    fontSize: "20px",
    fontWeight: 500,
  },
  optionType: {
    fontSize: "16px",
    color: theme.palette.grey[600],
  },
  optionTitleActive: {
    fontSize: "20px",
    fontWeight: 500,
    color: theme.palette.common.white,
  },
  optionTypeActive: {
    fontSize: "16px",
    color: theme.palette.common.white,
  },
  attentionContainer: {
    backgroundColor: "#FFE7CE",
    padding: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    marginBottom: "30px",
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: "30px",
    marginTop: "30px",
  },
  detailsContainer: {
    padding: "15px",
  },
  detailsInfo: {
    margin: "15px",
  },
  img: {
    width: "80px",
    height: "100px",
    [theme.breakpoints.down("md")]: {
      width: "60px",
      height: "75px",
    },
  },
  detailsAttention: {
    backgroundColor: "#E1F3FA",
    padding: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.info.light,
  },
  sheduling: {
    marginLeft: "15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  detailsTitle: {
    fontSize: "18px",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  detailsText: {
    fontSize: "16px",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  timeCard: {
    margin: "30px",
  },
  actionCard: {
    padding: "20px",
  },
  time: {
    fontSize: "26px",
    fontWeight: 500,
  },
  hospital: {
    fontSize: "20px",
    fontWeight: 500,
  },
  day: {
    fontSize: "22px",
    fontWeight: 500,
  },
  error: {
    fontSize: "14px",
    color: "red",
    marginLeft: "5px",
  },
  titleReview: {
    fontSize: "22px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  textReview: {
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "20px",
  },
  listTitle: {
    fontSize: "20px",
    fontWeight: 500,
    width: "300px",
  },
  listText: {
    fontSize: "16px",
    fontWeight: 400,
  },
  infoContainer: {
    marginTop: "20px",
  },
  arrowBack: {
    width: "15px",
    height: "15px",
  },
}));

export default useStyles;
