/* eslint-disable */
import axios from "axios";
import { getApiHost } from "../../util/api_helper";

class UploadFilesService {
  upload(file, contextType, contextID, photo_type, user_id) {
    const formData = new FormData();
    const host = getApiHost();
    formData.append("file", file);
    formData.append("contextType", contextType);
    formData.append("contextID", contextID);
    formData.append("photo_type", photo_type);
    formData.append("userId", user_id);
    let post_url = `${host}/api/s/virtualvisit/photos`;
    if (window.location.pathname.startsWith("/console")) {
      post_url = `${host}/api/s/console/virtualvisit/photos`;
    }
    return axios.post(post_url, formData, {
      withCredentials: true,
      headers: new Headers({
        "Content-Type": "multipart/form-data",
      }),
    });
  }
}

export default new UploadFilesService();
