import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Grid, Pagination, Typography } from "@mui/material";
import ProviderCard from "../ProviderCard";
import { RNWebView } from "util/RNWebView";
import useStyles from "../NewAppointment.styles";

const VIEWS = {
  map: 0,
  list: 1,
};

const ProvidersList = ({
  isUrgentCare,
  currentView,
  providers,
  DoctorRefs,
  activeDoctorId,
  totalPage,
  page,
  setPage,
  getProviders,
  showTimeSlots,
}) => {
  const navigate = useNavigate();
  const { classes, cx } = useStyles();

  const handleCardClick = (id) => {
    navigate(`/new-appointment/${id}`);
  };

  const handleTimeSlotClick = (id) => {
    navigate("/booking-online", { state: id });
  };

  return (
    <Box marginTop="24px">
      {!isUrgentCare &&
        !RNWebView &&
        providers?.map(({ provider, provider_id, locations }) => (
          <div
            key={provider_id}
            // eslint-disable-next-line
            ref={(element) => (DoctorRefs.current[provider_id] = element)}
          >
            <ProviderCard
              key={provider_id}
              title={provider.name.full}
              desc={provider.specialties[0]?.name}
              hospital={locations[0]?.name}
              number={provider.contacts[0]?.value}
              imgUrl={provider.image_url}
              msg="Previous Visit"
              handleCardClick={() => handleCardClick(provider_id)}
              isActive={provider_id === activeDoctorId}
              showTimeSlots={showTimeSlots}
              handleTimeSlotClick={() => handleTimeSlotClick(provider_id)}
            />
          </div>
        ))}

      {!isUrgentCare &&
        RNWebView &&
        currentView === VIEWS.list &&
        providers?.map(({ provider, provider_id, locations }) => (
          <div
            key={provider_id}
            // eslint-disable-next-line
            ref={(element) => (DoctorRefs.current[provider_id] = element)}
          >
            <ProviderCard
              key={provider_id}
              title={provider.name.full}
              desc={provider.specialties[0]?.name}
              hospital={locations[0]?.name}
              number={provider.contacts[0]?.value}
              imgUrl={provider.image_url}
              msg="Previous Visit"
              handleCardClick={() => handleCardClick(provider_id)}
              isActive={provider_id === activeDoctorId}
            />
          </div>
        ))}

      {isUrgentCare &&
        providers?.map(({ provider, provider_id, locations }) => (
          <Card
            key={provider_id}
            elevation={8}
            className={cx(classes.appCard, {
              [classes.appCardRN]: RNWebView,
            })}
          >
            <Grid container alignItems="flex-start">
              <Grid item xs={10}>
                <CardContent>
                  <Typography
                    className={cx(classes.titleCard, {
                      [classes.titleCardRN]: RNWebView,
                    })}
                    align="left"
                  >
                    {locations[0]?.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={cx(classes.cardDesc, {
                      [classes.cardDescRN]: RNWebView,
                    })}
                    align="left"
                  >
                    {provider.contacts[0]?.value}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        ))}

      {!providers?.length && <Typography variant="body1">No doctors found.</Typography>}
      {providers && !RNWebView && (
        <Pagination count={totalPage} page={page} onChange={(event, value) => setPage(value)} />
      )}
      {providers && RNWebView && currentView === VIEWS.list && (
        <Pagination count={totalPage} page={page} onChange={(event, value) => setPage(value)} />
      )}

      {RNWebView && currentView === VIEWS.map && (
        <div className={classes.list}>
          {providers?.map(({ provider, provider_id, locations }) => (
            <div
              className={classes.item}
              key={provider_id}
              // eslint-disable-next-line
              ref={(element) => (DoctorRefs.current[provider_id] = element)}
            >
              <ProviderCard
                key={provider_id}
                title={provider.name.full}
                desc={provider.specialties[0]?.name}
                hospital={locations[0]?.name}
                number={provider.contacts[0]?.value}
                imgUrl={provider.image_url}
                msg="Previous Visit"
                handleCardClick={() => handleCardClick(provider_id)}
                isActive={provider_id === activeDoctorId}
                currentView={currentView}
              />
            </div>
          ))}
          <Button onClick={getProviders}>Show More</Button>
        </div>
      )}
    </Box>
  );
};

export default ProvidersList;
