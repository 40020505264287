import React from "react";
import { MobileNavigationLink } from "../MobileNavigationLink";
import { makeStyles } from "tss-react/mui";
import BannerButton from "../../../components/Mobile/BannerButton/BannerButton";
import Hero from "../../../components/Mobile/Hero/Hero";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import CONSTANTS from "../constants";
import useTranslation from "../../../hooks/useTranslation";

const useStyles = makeStyles()(() => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.6em",
    marginTop: "-27px",
    zIndex: 2,
  },
}));

const AboutAtlanticHealth = () => {
  const { classes } = useStyles();
  const { mobile } = useTranslation();
  const { Careers, MakeDonation, PrivacyPolicy, AboutDesc } = mobile;

  const aboutLinks: Array<MobileNavigationLink> = [
    {
      destination: "https://jobs.atlantichealth.org/",
      title: Careers,
      shouldPushNavigation: false,
      testId: "about-view-careers-button",
    },
    {
      destination: "https://www.atlantichealth.org/patients-visitors/donate-volunteer.html",
      title: MakeDonation,
      shouldPushNavigation: false,
      testId: "about-view-make-donation-button",
    },
    {
      destination: "https://www.atlantichealth.org/notice-privacy-practices.html",
      title: PrivacyPolicy,
      shouldPushNavigation: false,
      testId: "about-view-privacy-policy-button",
    },
  ];

  return (
    <PageContainer>
      <Hero variant="textAndLogo" text={AboutDesc} />
      <div className={classes.buttonContainer}>
        {aboutLinks.map(({ title, destination, shouldPushNavigation, testId }) => (
          <BannerButton
            key={title}
            title={title}
            border="orange"
            showRightArrow
            shouldPushNavigation={shouldPushNavigation}
            navigateTo={destination as string}
            navigateFrom={CONSTANTS.ABOUT}
            testId={testId}
          />
        ))}
      </div>
    </PageContainer>
  );
};
export default AboutAtlanticHealth;
