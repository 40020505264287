import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    maxWidth: "1195px",
    textAlign: "center",
    paddingBottom: "2em",
  },
  title: {
    margin: "24px 0 16px 0",
  },
  cardsContainer: {
    justifyContent: "center",
    padding: "0 16px",
    gap: "16px",
    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
    },
  },
  implantCard: {
    borderTop: "4px solid #043070",
    padding: "24px",
    maxWidth: "450px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  implantText: {
    fontSize: "18px",
    textAlign: "left",
  },
  appleWalletButton: {
    background: "none",
    paddingTop: "12px",
    border: "none",
    margin: "0 auto",
    width: "170px",
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
  },
  expandIcon: {
    width: "50px",
    height: "50px",
    transition: ".2s",
  },
}));
