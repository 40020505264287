import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: "640px",
    paddingBottom: "1.5em",
  },
  paper: {
    boxSizing: "border-box",
    padding: "32px",
    marginTop: "60px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "28px 22px",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "16px",
  },
  inputRoot: {
    fontSize: "22px",
  },
  labelRoot: {
    fontSize: "22px",
  },
  links: {
    fontSize: "16px",
    padding: "5px",
    textTransform: "capitalize",
    color: theme.palette.primary.main,
  },
  normalText: {
    fontWeight: "normal",
  },
  noLoginText: {
    margin: 0,
    marginBottom: "24px",
    fontSize: "16px",
  },
  noLoginContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    marginTop: "24px",
  },
  loginButton: {
    textTransform: "uppercase",
    marginBottom: "24px",
    fontWeight: 600,
  },
  amazonLoginBtn: {
    margin: "0 0 16px 0",
    padding: 0,
  },
  textMessageLoginButton: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    fontWeight: 600,
    marginTop: "24px",
  },
  verificationPaper: {
    boxSizing: "border-box",
    maxWidth: "640px",
    width: "100%",
    padding: "32px 40px",

    [theme.breakpoints.down("md")]: {
      padding: "28px 22px",
      maxWidth: "560px",
    },
  },
  title: {
    margin: 0,
    marginBottom: "8px",
    fontSize: "32px",
    fontFamily: "unset",
    lineHeight: "100%",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
  },
  subtitle: {
    margin: 0,
    marginBottom: "24px",
    fontSize: "18px",
  },
  codeInput: {
    margin: "0 auto",
    marginBottom: "24px",
    "& input": {
      border: `solid 1px ${theme.palette.primary.main} !important`,
      color: theme.palette.primary.main,
      fontSize: "28px !important",
      fontFamily: "'Alegreya Sans',Roboto,sans-serif !important",
      fontWeight: 600,
      caretColor: `${theme.palette.primary.main} !important`,
    },
  },
  resendBtn: {
    margin: 0,
    fontSize: "18px",
    "& span": {
      color: "var(--danger)",
    },
  },
  verifyBtn: {
    marginTop: "24px",
  },
}));

export default useStyles;
