/* eslint-disable */
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { isRNApp } from "util/RNWebView";
import { useAuth } from "util/Security";

const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.info.main,
    fontSize: "20px",
    lineHeight: "22px",
    textDecoration: "none",
    display: "inline-block",
    borderBottom: `solid 1px ${theme.palette.info.main}`,
    fontWeight: 500,
    "&:hover": {
      color: theme.palette.primary.main,
      borderBottom: `solid 1px ${theme.palette.primary.main}`,
    },
  },
  linkContainer: {
    paddingBottom: "40px",
  },
}));

export default function Footer() {
  // TODO remove redirect default as vaccine card and make it home page.
  const location = useLocation();
  const auth = useAuth();
  const app = useApp();
  const { classes } = useStyles();

  return (
    <>
      {app.isEmbedded() && location.pathname !== "/login" && auth.user && !isRNApp && (
        <div className={classes.linkContainer}>
          <RouterLink
            to="/logout?redirect=proofofvaccination&embedded=true"
            style={{ margin: 0 }}
            className={classes.link}
          >
            Log Out
          </RouterLink>
        </div>
      )}
    </>
  );
}
