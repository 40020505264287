import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface SquareButtonProps {
  icon?: string;
  title: string;
  subtitle?: string;

  navigateTo: string;
  navigateFrom: string;
  // indicates wheter the navigation should be treated as history.push or not
  shouldPushNavigation?: boolean;

  testId: string;
}

const useStyles = makeStyles()(() => ({
  squareBtnContainer: {
    "-webkit-box-shadow": "0.1em 0.1em 0.75em 0 rgba(0, 0, 0, 0.1)",
    boxShadow: "0.1em 0.1em 0.75em 0 rgba(0, 0, 0, 0.1)",
    minHeight: "136px",
    zIndex: 1,
  },
  buttonContainer: {
    backgroundColor: "white",
    width: "100%",
    border: "0",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: 1,
    padding: "1em",
    color: "#a5a5a5",
    textAlign: "left",
    lineHeight: 1.2,

    "&:focus": {
      outline: "none",
    },
  },
  icon: {
    width: "2.5em",
    height: "2.5em",
  },
  title: {
    color: "#171a5b",
    fontWeight: 500,
    marginTop: "16px",
  },
  subtitle: {
    fontSize: "15px",
    color: "#5d5d5d",
    marginTop: "4px",
    lineHeight: "18px",
  },
}));

const SquareButton = ({
  icon,
  title,
  subtitle,
  navigateTo,
  navigateFrom,
  shouldPushNavigation = true,
  testId,
}: SquareButtonProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    if (shouldPushNavigation) {
      navigate(navigateTo, { state: { from: navigateFrom } });
      return;
    }
    window.open(navigateTo, "_blank");
  }, [navigateTo, navigateFrom, shouldPushNavigation, navigate]);

  return (
    <Paper className={classes.squareBtnContainer}>
      <button
        type="button"
        onClick={handleOnClick}
        className={classes.buttonContainer}
        data-cy={testId}
      >
        <img src={icon} alt="" className={classes.icon} />
        <Typography className={classes.title}>{title}</Typography>
        {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
      </button>
    </Paper>
  );
};

export default SquareButton;
