import React, { createContext, useContext } from "react";
import { UseQueryResult } from "react-query";
import { LocationData } from "./utils/types";

interface LocationContextValue {
  locationData: LocationData;
  currentPayload: LocationData | null;
  setCurrentPayload: (currentFormInfo: any) => void;
  currentlyEditing: string | null;
  setCurrentlyEditing: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTab: string | null;
  refetch: () => Promise<UseQueryResult>;
}

const LocationContext = createContext<LocationContextValue | undefined>(undefined);

export const LocationContextProvider: React.FC<{
  value: LocationContextValue;
  children;
}> = ({ value, children }) => {
  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
};

export const useLocationContext = (): LocationContextValue => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error("useLocationContext must be used within a LocationContextProvider");
  }
  return context;
};
