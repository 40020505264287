import React from "react";
import Typography from "@mui/material/Typography";
import { Theme, alpha } from "@mui/material/styles";
import AvatarIcon from "../../icons/AvatarIcon";
import ScreenShareIcon from "../../icons/ScreenShareIcon";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import PinIcon from "./PinIcon/PinIcon";
import { makeStyles } from "tss-react/mui";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";

// @ts-ignore
const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: 0,
    overflow: "hidden",
    marginBottom: "0.5em",
    // This is the offending line. tss-react does not like it. That's stupid,
    // because it's a valid selector. I'm going to ignore it.
    "& video": {
      filter: "none",
      objectFit: "contain !important",
    },
    borderRadius: "4px",
    border: `2px solid rgb(245, 248, 255)`,
    paddingTop: `calc(${(9 / 16) * 100}% - 2px)`,
    background: theme.palette.common.black,
    [theme.breakpoints.down("md")]: {
      height: 90,
      width: `${(90 * 16) / 9}px`,
      marginRight: "8px",
      marginBottom: "0",
      fontSize: "10px",
      paddingTop: `${90 - 2}px`,
    },
  },

  innerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  infoContainer: {
    position: "absolute",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    background: "transparent",
    top: 0,
  },

  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.common.black,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      "& svg": {
        transform: "scale(0.7)",
      },
    },
  },

  reconnectingContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(40, 42, 43, 0.75)",
    zIndex: 1,
  },

  screenShareIconContainer: {
    background: "rgba(0, 0, 0, 0.5)",
    padding: "0.18em 0.3em",
    marginRight: "0.3em",
    display: "flex",
    "& path": {
      fill: theme.palette.common.white,
    },
  },

  identity: {
    color: theme.palette.common.white,
    margin: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
    paddingRight: "8px",
    fontSize: "14px",
    fontWeight: 500,
  },

  infoRowBottom: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 0,
  },

  typeography: {
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
  },

  hideParticipant: {
    display: "none",
  },

  cursorPointer: {
    cursor: "pointer",
  },

  networkContainer: {
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
    width: "fit-content",
    padding: "4px",
  },
}));

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const videoPublication = publications.find(
    (p) => p.trackName.includes("camera") || p.kind.includes("video"),
  );

  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find((p) => p.trackName.includes("screen"));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  );

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.cursorPointer]: Boolean(onClick),
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
      data-testid="hideParticipant"
    >
      <div className={classes.infoContainer}>
        <div className={classes.networkContainer}>
          <NetworkQualityLevel participant={participant} />
        </div>
        <div className={classes.infoRowBottom}>
          {isScreenShareEnabled && (
            <span className={classes.screenShareIconContainer}>
              <ScreenShareIcon />
            </span>
          )}
          <span className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <span className={classes.typeography}>
              {participant.identity}
              {isLocalParticipant && " (You)"}
            </span>
          </span>
        </div>
        {isSelected && (
          <div data-testid="PinIcon">
            <PinIcon />
          </div>
        )}
      </div>
      <div className={classes.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div className={classes.avatarContainer} data-testid="AvatarIcon">
            <AvatarIcon />
          </div>
        )}
        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typeography} data-testid="Reconnecting">
              Reconnecting...
            </Typography>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
