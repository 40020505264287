import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { ProgramNames } from "util/constants";
import { APP_ENVIRONMENT, Environment } from "util/constants/config";
import { useUserInfo } from "../../hooks/useUserInfo/useUserInfo";
import useTranslation from "hooks/useTranslation";
import { formatPhoneWithHyphens } from "util/util_functions";
import { useConsultationStyles } from "./styles";

const AppointmentScheduled = () => {
  const {
    web: {
      common: { returnHome },
      AppointmentScheduled: { talkSoon, scheduledConversation, reachOut1, reachOut2, reachOut3 },
    },
  } = useTranslation();

  const { user } = useUserInfo();
  const { classes } = useConsultationStyles();
  const formattedCellPhone = formatPhoneWithHyphens(user?.cellPhone);
  const { programName } = useParams<{ programName: string }>();
  const decodedProgramName = decodeURIComponent(programName || "");

  const getRedirectLink = () => {
    const isProd = APP_ENVIRONMENT === Environment.PRODUCTION;
    const isPT = decodedProgramName === ProgramNames.PHYSICAL_THERAPY;
    const envLink = isProd ? "care" : "test";

    if (isPT) {
      window.location.href = isProd
        ? `https://www.atlantichealth.org/conditions-treatments/rehabilitation-physical-therapy.html`
        : `https://www.atlantichealth.org/${envLink}/rehabilitation-physical-therapy.html`;
    } else {
      window.location.href = isProd
        ? "https://www.atlantichealth.org/care/brain-tumor-center.html"
        : "https://www.atlantichealth.org/test/brain-tumors.html";
    }
  };

  return (
    <>
      <div className={classes.emailAlert}>{talkSoon}</div>
      <div className={classes.innerContainer}>
        <div className={classes.scheduledTitle}>
          {scheduledConversation}
          <br />
          <span className={classes.emailText}>
            {user?.cellPhone ? formattedCellPhone : user?.email}
          </span>
          .
        </div>
        <div className={classes.scheduledSubTitle}>
          {`${reachOut1} ${user?.cellPhone ? formattedCellPhone : user?.email} ${reachOut2}`}
          <br />
          {reachOut3}
        </div>
        <Button
          variant="contained"
          className={classes.homeButton}
          color="primary"
          onClick={() => getRedirectLink()}
          data-cy="returnHomeBtn"
          data-testid="returnHome-Button"
        >
          {returnHome}
        </Button>
      </div>
    </>
  );
};

export default AppointmentScheduled;
