import { useEffect, useState } from "react";
import { getApiHost } from "./api_helper";

export const useFetch = (uri, options) => {
  const apiHost = getApiHost();
  const url = `${apiHost}${uri}`;
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(url, options);
        if (res.status !== 200) {
          const errmsg = `API call non 200 error error for url ${url} error ${res.status}`;
          throw new Error(errmsg);
        }
        const json = await res.json();
        if (json?.message?.includes("rror")) {
          throw new Error(json.message);
        }
        setResponse(json);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return { response, error, isLoading };
};

export const useMockFetch = (time = 5000) => {
  const [response, setResponse] = useState(null);
  const error = null;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setResponse({
        jwt: "sample jwt",
      });
      setIsLoading(false);
    }, time);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line
  }, []);
  return { response, error, isLoading };
};

export const useTimeOut = ({ responsee }) => {
  const error = null;
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setResponse({
        ...responsee,
      });
      setIsLoading(false);
    }, 3000);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line
  }, []);
  return { response, error, isLoading };
};
