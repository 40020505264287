/** @format */
import React from "react";
import { useHeaderStyles } from "./styles";
import ClearLogo from "../../static/images/ahs-outline-white.png";

const Header = () => {
  const { classes } = useHeaderStyles();
  return (
    <div className={classes.root}>
      <div className={classes.verified}>ISSUED BY</div>
      <div>
        <img src={ClearLogo} className={classes.img} alt="" />
      </div>
    </div>
  );
};

export default Header;
