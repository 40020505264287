import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import Stepper from "./Stepper";
import { QuerySteps, Steps, StepsMapping } from "./Steps";
import { preJoinStyles } from "./prejoinStyles";
import qString, { ParsedQuery } from "query-string";
import {
  AudioPermissionStep,
  Consent,
  DeviceSelectionScreen,
  InsuranceDetails,
  StartTest,
  VideoPermissionStep,
  VisitReason,
} from "./StepsComponents";
import { VUCDisclaimer } from "components/VUCDisclaimer/VUCDisclaimer";
import {
  START_PATIENT_SESSION,
  UPDATE_AND_SELECT_VIRTUAL_VISIT,
} from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { useAppState } from "components/Video/state";
import { VirtualSessionQueue } from "util/constants";
import { useQuery } from "hooks/useQuery";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

export default function PreJoinScreens() {
  const location = useLocation();
  const navigate = useNavigate();

  const app = useApp();
  const { user } = useAppState();
  const vsId = Number(useParams<{ virtual_session_id: string }>().virtual_session_id);
  const hideStepperString = sessionStorage.getItem("hideStepper");
  const hideStepper = hideStepperString && JSON.parse(hideStepperString);
  const query = useQuery();
  const parsedQuery: ParsedQuery<string> = qString.parse(location.search);

  const programCode = query.get("program");

  const [step, setStep] = useState<Steps>(Steps.visitReason);
  const { classes } = preJoinStyles();
  const inSession = parsedQuery?.step === QuerySteps.IN_SESSION;
  const name = user?.displayName || "";

  const [currentVsVisitQueue, setCurrentVsVisitQueue] = useState();
  const [virtualSession, setVirtualSession] = useState();
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const handleHideDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const handleStep = (queryStep: QuerySteps) => {
    setStep(StepsMapping[queryStep]);
    query.set("step", queryStep);
    navigate({ search: query.toString() });
  };

  const handleCreateVS = async () => {
    try {
      const { data } = await axiosClient.get("api/config/programs");
      const program = data?.data?.find((item) => item?.program_code === programCode);
      const response = await axiosClient.post(API_ENDPOINTS.twilio_start_virtual_session, {
        program: program?.name || "Virtual Urgent Care",
      });
      if (response?.data?.virtual_session_id) {
        navigate({
          pathname: `${location.pathname}/${response?.data?.virtual_session_id}`,
          search: location.search,
        });
      }
    } catch (err) {
      app.addError("Could not create virtual session");
    }
  };

  useEffect(() => {
    if (!vsId) {
      handleCreateVS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  useEffect(() => {
    const getVirtualSession = async (vsId) => {
      try {
        const { data } = await axiosClient.post(API_ENDPOINTS.twilio_get_virtual_session, {
          vs_id: vsId,
        });
        setCurrentVsVisitQueue(data.queue || VirtualSessionQueue.REGISTERING);
        setVirtualSession(data);
        app.dispatch({ type: UPDATE_AND_SELECT_VIRTUAL_VISIT, payload: data });
      } catch (error) {
        app.addError("Internal Server Error on getting virtual session");
      }
    };
    if (vsId) {
      getVirtualSession(vsId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  useEffect(() => {
    if (vsId) {
      app.dispatch({ type: START_PATIENT_SESSION });
      if (typeof parsedQuery.step === "string") {
        setStep(StepsMapping[parsedQuery.step]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  if (showDisclaimer) {
    return <VUCDisclaimer onPositiveOptionPressed={handleHideDisclaimer} />;
  }

  return (
    <div
      className={hideStepper || step === Steps.deviceSelectionStep ? classes.additionalMargin : ""}
    >
      <Grid container direction="column" className={classes.gridContainer}>
        {!hideStepper && step !== Steps.deviceSelectionStep && <Stepper step={step} />}
        {vsId && step === Steps.visitReason && <VisitReason setStep={handleStep} vsId={vsId} />}
        {vsId && step === Steps.insuranceDetails && (
          <InsuranceDetails setStep={handleStep} vsId={vsId} />
        )}
        {step === Steps.Consent && <Consent vsId={vsId} setStep={handleStep} />}
        {step === Steps.StartTestStep && <StartTest vsId={vsId} setStep={handleStep} />}
        {step === Steps.AudioPermissionStep && <AudioPermissionStep setStep={handleStep} />}
        {step === Steps.VideoPermissionStep && <VideoPermissionStep setStep={handleStep} />}
        {currentVsVisitQueue && vsId && step === Steps.deviceSelectionStep && (
          <DeviceSelectionScreen
            currentVsVisitQueue={currentVsVisitQueue || ""}
            name={name}
            setStep={handleStep}
            virtual_session_id={vsId}
            inSession={inSession}
            virtualSession={virtualSession}
          />
        )}
      </Grid>
    </div>
  );
}
