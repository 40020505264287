import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import SurveyConfigurationForm from "./SurveyConfigurationForm";
import { useIsMobile } from "util/deviceUtils";
import { useStyles } from "../../styles/Modal/AddModal.styles";

const EditSurveyConfigurationModal = ({ show, setShow, record, mode }) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  const emptyRecord = {
    surveyName: "",
    formName: "",
    intervalMinutes: "",
    actionStartRunning: "",
    actionEndsRunning: "",
    sendAfterMinutes: "",
    departmentName: "",
    enabled: true,
  };
  const [surveyConfigRecord, setSurveyConfigRecord] = useState(emptyRecord);

  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
    setShow(false);
    setSurveyConfigRecord(emptyRecord);
  };

  const handleModalVisibility = () => {
    if (show) {
      setSurveyConfigRecord({ ...record });
      setOpen(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    handleModalVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      classes={{ paperWidthMd: classes.modalDialog }}
      fullScreen={isMobile}
    >
      <MuiDialogTitle id="customized-dialog-title" className={classes.modalDialogTitle}>
        <Tabs value={0} aria-label="createVirtualSessionOptions">
          <Tab label={`${mode === "add" ? "Add" : "Edit"} Survey Configuration`} />
        </Tabs>
        <IconButton
          data-cy="closeModalButton"
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers classes={{ root: classes.formContainer }}>
        <SurveyConfigurationForm
          handleClose={handleClose}
          mode={mode}
          record={surveyConfigRecord}
        />
      </MuiDialogContent>
    </Dialog>
  );
};

export default EditSurveyConfigurationModal;
