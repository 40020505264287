import * as Yup from "yup";
import { OTHER_VISIT_REASON } from "util/constants";

export type FormValues = {
  visitReason: string | undefined;
  visitReasonDetails: string;
  otherVisitReason: string;
  firstName: string;
  lastName: string;
  dob: Date | null;
  zip: string;
  cellPhone: string;
  accidentRelated: string;
};

export const initialValues: FormValues = {
  visitReason: "",
  visitReasonDetails: "",
  otherVisitReason: "",
  firstName: "",
  lastName: "",
  dob: null,
  zip: "",
  cellPhone: "",
  accidentRelated: "no",
};

export const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  dob: Yup.mixed().required("Date of birth is required"),
  cellPhone: Yup.string()
    .required("Phone number is required")
    .min(10, "Phone number must be 10 digit"),
  zip: Yup.string().required("Zip code is required").min(5, "Zip code must be 5 digit"),
  visitReason: Yup.string().nullable().required("Visit reason is required"),
  otherVisitReason: Yup.string().when("visitReason", {
    is: OTHER_VISIT_REASON,
    then: Yup.string().required("Visit reason is required"),
  }),
});
