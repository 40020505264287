import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { List, Map } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Grid, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ModalFilter from "./ModalFilter";
import { getDoctorsCoordinates } from "./helpers";
import { loadProviders, loadProvidersRN } from "./KyruusRequests";
import AutocompleteSearch from "./components/AutocompleteSearch";
import FilterOptions from "./components/FilterOptions";
import ProvidersList from "./components/ProvidersList";
import TimeSlotPickerHeader from "components/TimeSlotPicker/TimeSlotPickerHeader";
import GoogleMap from "components/shared/Map";
import { Features } from "util/constants/config";
import { useQuery } from "hooks/useQuery";
import { useApp } from "util/AppContext";
import { RNWebView } from "util/RNWebView";
import { useAuth } from "util/Security";
import { hasFeature } from "util/hasFeature";
import useStyles from "./NewAppointment.styles";

const VIEWS = {
  map: "map",
  list: "list",
};

const MapInfoWindowContent = ({ activeProvider }) => {
  if (activeProvider) {
    return (
      <>
        <Typography>{activeProvider.provider.name.full}</Typography>
        <Typography>{activeProvider.provider.specialties[0]?.name}</Typography>
        <Typography>{activeProvider.locations[0]?.name}</Typography>
        <Typography>{activeProvider.provider.contacts[0].value}</Typography>
      </>
    );
  }

  return <div />;
};

const NewAppointment = () => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const app = useApp();
  const query = useQuery();

  const enableDoctorTimeSlots = hasFeature(Features.DOCTOR_TIME_SLOTS);

  const [search, setSearch] = useState("");
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeDoctor, setActiveDoctor] = useState();
  const [markerConfig, setMarkerConfig] = useState([]);
  const [page, setPage] = useState(1);
  const [pageMobile, setPageMobile] = useState(2);
  const [totalPage, setTotalPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [sort, setSort] = useState(true);
  const [chosenMarker, setChosenMarker] = useState();
  const [isUrgentCare, setIsUrgentCare] = useState(false);
  const [filterData, setFilterData] = useState({
    city: "",
    zip: "",
    state: "",
    gender: "",
    isOnlineAppointment: "",
    insurance: [],
  });
  const DoctorRefs = useRef({});
  const firstUpdate = useRef(true);

  const currentView = query.get("view") || VIEWS.list;

  if (location.action === "POP") {
    firstUpdate.current = false;
  }

  useEffect(() => {
    setPage(1);
  }, [totalPage]);

  useEffect(() => {
    if (!firstUpdate.current) {
      loadProviders(
        setIsLoading,
        page,
        isUrgentCare,
        search,
        filterData,
        sort,
        setTotalPage,
        setProviders,
        firstUpdate,
        app,
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, search, sort, page, isUrgentCare]);

  useEffect(() => {
    firstUpdate.current = false;
    if (location.state && location.action === "PUSH") {
      const data = location.state;
      const insuranceArray = data.insurance_company.split(",");
      setFilterData((state) => ({
        ...state,
        zip: data.zip_code,
        location: `zip-code: ${data?.zip_code}`,
        insurance: insuranceArray,
      }));
    }
    if (!location.state && location.action === "PUSH") {
      setFilterData((state) => ({
        ...state,
        zip: auth.user.zip,
        location: `zip-code: ${auth.user.zip}`,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickMarker = (id) => {
    const marker = markerConfig.find((marker) => marker.id === id);
    const activeProvider = providers.find((it) => it.provider_id === marker.providerId);
    setChosenMarker(marker);
    setActiveDoctor(activeProvider);
    if (chosenMarker?.id === id) {
      DoctorRefs.current[id].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    if (providers?.length) {
      const coordinates = getDoctorsCoordinates(providers);
      setMarkerConfig(coordinates);
    } else {
      setMarkerConfig([]);
    }
  }, [providers]);

  const closeModal = (filter) => {
    setFilterData(filter);
    setIsOpen(false);
  };

  const changeView = () => {
    const current = query.get("view");
    query.set("view", current === VIEWS.list ? VIEWS.map : VIEWS.list);
    navigate({ search: query.toString() });
  };

  const getProviders = () => {
    loadProvidersRN(
      providers,
      pageMobile,
      isUrgentCare,
      search,
      filterData,
      sort,
      setTotalPage,
      setProviders,
      firstUpdate,
      setPageMobile,
      app,
    );
  };

  return (
    <>
      <Container
        maxWidth={RNWebView ? "lg" : "md"}
        className={cx({ [classes.headerRN]: RNWebView })}
      >
        <Grid container alignItems="flex-start">
          <Typography
            variant="h3"
            align="left"
            className={cx(classes.title, { [classes.titleRN]: RNWebView })}
          >
            New Appointment
          </Typography>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Button
            onClick={() => setIsUrgentCare((state) => !state)}
            color={isUrgentCare ? "secondary" : "default"}
            className={cx(
              isUrgentCare
                ? { [classes.buttonUrgentCareActive]: RNWebView }
                : { [classes.buttonUrgentCare]: RNWebView },
            )}
          >
            Urgent Care
          </Button>
          {RNWebView && (
            <Button onClick={changeView}>
              {currentView === VIEWS.map ? (
                <List fontSize="small" className={cx({ [classes.iconRN]: RNWebView })} />
              ) : (
                <Map fontSize="small" className={cx({ [classes.iconRN]: RNWebView })} />
              )}
            </Button>
          )}
        </Grid>
        <AutocompleteSearch setSearch={setSearch} />
        <FilterOptions
          gender={filterData.gender}
          location={filterData.location}
          isOnlineBooking={filterData.isOnlineAppointment}
          insurance={filterData.insurance}
          setFilterData={setFilterData}
        />
        <Grid container justifyContent="flex-end">
          {RNWebView && (
            <Button
              className={{ [classes.buttonUrgentCare]: RNWebView }}
              onClick={() => setIsOpen(true)}
            >
              Filter
            </Button>
          )}
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Grid container justifyContent="flex-end">
          {!RNWebView && <Button onClick={() => setIsOpen(true)}>Filter</Button>}

          {!RNWebView && (
            <ToggleButtonGroup
              orientation="horizontal"
              value={currentView}
              exclusive
              onChange={(event, nextView) => {
                query.set("view", nextView);
                navigate({ search: query.toString() });
              }}
            >
              <ToggleButton value={VIEWS.map} aria-label="list">
                <Map fontSize="small" />
              </ToggleButton>
              <ToggleButton value={VIEWS.list} aria-label="module">
                <List fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Grid>
        <ModalFilter
          isOpen={isOpen}
          handleClose={closeModal}
          handleCancel={() => setIsOpen(false)}
          filterData={filterData}
        />

        <Grid container justifyContent="flex-end">
          <Typography
            className={sort ? classes.sort : classes.sortChoose}
            onClick={() => setSort(false)}
          >
            Relevance Sort
          </Typography>
          <Typography className={classes.divider}>|</Typography>
          <Typography
            className={sort ? classes.sortChoose : classes.sort}
            onClick={() => setSort(true)}
          >
            Availability
          </Typography>
        </Grid>
        {currentView === VIEWS.map && (
          <GoogleMap
            containerStyle={{
              width: "100%",
              height: "500px",
            }}
            markers={markerConfig}
            chosenMarker={chosenMarker}
            infoWindowProps={{
              children: <MapInfoWindowContent activeProvider={activeDoctor} />,
              onCloseClick: () => setChosenMarker(null),
            }}
            onMarkerClick={handleClickMarker}
          />
        )}
        {isLoading ? (
          <Box marginTop="24px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            {enableDoctorTimeSlots && <TimeSlotPickerHeader amountOfDaysToShow={5} />}
            <ProvidersList
              isUrgentCare={isUrgentCare}
              currentView={currentView}
              providers={providers}
              DoctorRefs={DoctorRefs}
              activeDoctorId={activeDoctor}
              totalPage={totalPage}
              page={page}
              setPage={setPage}
              getProviders={getProviders}
              showTimeSlots={enableDoctorTimeSlots}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default NewAppointment;
