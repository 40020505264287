import React, { useEffect, useState } from "react";
import { Box, Chip, FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  formControl: {
    display: "flex",
    justifyContent: "center",
  },
  selectContainer: {
    "& > div": {
      minHeight: "32px",
      padding: "12px 24px 12px 14px",
      width: "536px",
    },
  },
  chipContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
  },
}));

export const ChipDropdown = ({ options, onChangeSelectedOptions }) => {
  const { classes } = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    onChangeSelectedOptions(selectedOptions.join(","));
    return () => {
      onChangeSelectedOptions("");
    };
  }, [onChangeSelectedOptions, selectedOptions]);

  return (
    <div>
      <FormControl variant="standard" fullWidth className={classes.formControl}>
        <Select
          variant="standard"
          id="select-chip-dropdown"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          className={classes.selectContainer}
          input={<OutlinedInput id="select-chip-dropdown-input" />}
          // eslint-disable-next-line
          renderValue={(options: any) => (
            <Box className={classes.chipContainer}>
              {options.map((option) => (
                <Chip key={option} label={option} color="primary" />
              ))}
            </Box>
          )}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
