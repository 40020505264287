import { defaultGridConfig } from "components/GenericEnhancedTable";
import { Campaign } from "components/Video/utils/types";
import { CampaignTypes } from "util/constants";

export const gridConfig = {
  ...defaultGridConfig,
  allowDelete: false,
};

export const colDefsAutoOutreach = [
  { id: "id", numeric: true, disablePadding: true, label: "ID", searchable: false, sortable: true },
  {
    id: "cell",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    searchable: true,
    sortable: true,
  },
  {
    id: "comm_name",
    numeric: false,
    disablePadding: true,
    label: "Comm Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "comm_type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    searchable: false,
    sortable: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    searchable: false,
    sortable: true,
  },
  {
    id: "context",
    numeric: false,
    disablePadding: false,
    label: "Campaign",
    searchable: false,
    sortable: true,
  },
  {
    id: "send_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Sent On",
    searchable: false,
    sortable: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    searchable: true,
    sortable: true,
    customInput: {
      type: "chipDropdown",
      options: ["New", "In Progress", "Completed", "Expired", "Error"],
    },
  },
  {
    id: "direction",
    numeric: false,
    disablePadding: false,
    label: "Direction",
    searchable: false,
    sortable: true,
  },
  {
    id: "tracking_id",
    numeric: false,
    disablePadding: false,
    label: "Tracking Id",
    searchable: true,
    sortable: true,
  },
];

export const colDefsProgramForm = [
  {
    id: "mrn",
    numeric: false,
    disablePadding: false,
    label: "MRN",
    searchable: true,
    sortable: true,
  },
  {
    id: "tags",
    numeric: false,
    disablePadding: false,
    label: "Tags",
    searchable: true,
    sortable: false,
    isCustomContent: true,
    func(tags) {
      return tags?.length ? tags.join(", ") : "–";
    },
    cellMinWidth: "10em",
    customInput: {
      type: "chipDropdown",
      options: [],
    },
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "dob",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Date Of Birth",
    searchable: false,
    sortable: true,
  },
  {
    id: "comms_sent",
    numeric: true,
    disablePadding: false,
    label: "Comms Sent",
    searchable: false,
    sortable: true,
  },
  {
    id: "cell_phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    searchable: true,
    sortable: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    searchable: false,
    sortable: true,
  },
  {
    id: "clinical_provider",
    numeric: false,
    disablePadding: false,
    label: "Surgeon",
    searchable: true,
    sortable: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    searchable: true,
    sortable: true,
    customInput: {
      type: "chipDropdown",
      options: ["New", "In Progress", "Completed", "Expired", "Error"],
    },
  },
  {
    id: "reference_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Surgery Date",
    searchable: false,
    sortable: true,
  },
  {
    id: "start_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Start Date",
    searchable: false,
    sortable: true,
  },
  {
    id: "end_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "End Date",
    searchable: false,
    sortable: true,
  },
  {
    id: "invited_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Outreached At",
    searchable: false,
    sortable: true,
  },
  {
    id: "engaged_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Engaged At",
    searchable: false,
    sortable: true,
  },
  {
    id: "submitted_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Submitted At",
    searchable: false,
    sortable: true,
  },
  {
    id: "emr_status",
    numeric: false,
    disablePadding: false,
    label: "EMR Status",
    searchable: false,
    sortable: true,
    isBoolean: true,
  },
  {
    id: "unsubscribed",
    numeric: false,
    disablePadding: false,
    label: "unsubscribed",
    searchable: false,
  },
  {
    id: "viewurl",
    numeric: false,
    disablePadding: false,
    label: "Form",
    isFormSubmission: true,
    linkText: "View Form",
    searchable: false,
  },
];

export const colDefsCampaignForm = [
  {
    id: "campaign",
    numeric: false,
    disablePadding: false,
    label: "Campaign",
    searchable: true,
    sortable: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    searchable: true,
    sortable: true,
    customInput: {
      type: "chipDropdown",
      options: ["New", "In Progress", "Completed", "Expired", "Error"],
    },
  },
  {
    id: "engaged_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Scan At",
    searchable: false,
    sortable: true,
  },
  {
    id: "submitted_dttm",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Submitted At",
    searchable: false,
    sortable: true,
  },
  {
    id: "viewurl",
    numeric: false,
    disablePadding: false,
    label: "Form",
    isFormSubmission: true,
    linkText: "View Form",
    searchable: false,
  },
];

export const getTableColDefs = (campaign: Campaign | undefined) => {
  switch (campaign?.campaign_type) {
    case CampaignTypes.autoPhoneOutreach:
      return colDefsAutoOutreach;
    case CampaignTypes.programFormOutreach:
      return colDefsProgramForm;
    case CampaignTypes.quickAccessSurvey:
      return colDefsCampaignForm;
    default:
      return [];
  }
};

const autoPhoneOutreachDateFilters = [
  {
    name: "Sent On",
    value: "send_dttm",
  },
];

const programFormOutreachDateFilters = [
  {
    name: "Surgery Date",
    value: "reference_dttm",
  },
  {
    name: "Start Date",
    value: "start_dttm",
  },
  {
    name: "End Date",
    value: "end_dttm",
  },
  {
    name: "Outreached At",
    value: "invited_dttm",
  },
  {
    name: "Engaged At",
    value: "engaged_dttm",
  },
  {
    name: "Submitted At",
    value: "submitted_dttm",
  },
];

const quickAccessSurveyDateFilters = [
  {
    name: "Scan At",
    value: "engaged_dttm",
  },
  {
    name: "Submitted At",
    value: "submitted_dttm",
  },
];

export const getCampaignDateFilters = (campaign: Campaign | undefined) => {
  switch (campaign?.campaign_type) {
    case CampaignTypes.autoPhoneOutreach:
      return autoPhoneOutreachDateFilters;
    case CampaignTypes.programFormOutreach:
      return programFormOutreachDateFilters;
    case CampaignTypes.quickAccessSurvey:
      return quickAccessSurveyDateFilters;
    default:
      return [];
  }
};

export const OPERARTORS = [
  {
    value: "is",
    name: "Is",
  },
  {
    value: "is_not",
    name: "Is not",
  },
  {
    value: "is_after",
    name: "Is after",
  },
  {
    value: "is_on_or_after",
    name: "Is on or after",
  },
  {
    value: "is_between",
    name: "Is between",
  },
  {
    value: "is_not_between",
    name: "Is not between",
  },
  {
    value: "is_before",
    name: "Is before",
  },
  {
    value: "is_on_or_before",
    name: "Is on or before",
  },
  {
    value: "is_empty",
    name: "Is empty",
  },
  {
    value: "is_not_empty",
    name: "Is not empty",
  },
];
