import React from "react";
import ProofOfVaccination from "../../components/ImprovedProofOfVaccinations";
import Loader from "../../components/Loader";
import { useFetch } from "../../util/useFetch";
import { GET_OPTIONS } from "../../util/api_helper";
import "../../App.css";
import { useStyles } from "./styles";

export default function VaccineVerificationView() {
  const { classes } = useStyles();
  let uri = "/api/s/fhir/immunizations";
  const url = new URL(window.location.href);
  const forceiis = url.searchParams.get("forceiis");

  if (forceiis && forceiis === "true") {
    uri += "?forceiis=true";
  }
  const { isLoading, response } = useFetch(uri, GET_OPTIONS);

  const vaccines = response?.immunizations || [];
  //   const vaccines = mockedImmunizations;
  return (
    <div className={classes.root}>
      <h2 className={classes.header}>Digital Vaccine Card</h2>
      {!isLoading ? (
        <ProofOfVaccination vaccines={vaccines} showQR />
      ) : (
        <div>
          <Loader />
        </div>
      )}
      <br />
      <br />
    </div>
  );
}
