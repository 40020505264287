import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "tss-react/mui";
import AHSZip from "components/AHSZip";
import ConfirmationDialog from "components/ConfirmationDialog";
import EnhancedTable from "components/EnhancedTable";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import "../App.css";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  cardMain: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "14pt",
    textDecoration: "none",
  },
  backgroundSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
}));

export default function StandbyListView() {
  const { classes } = useStyles();

  const [zip, setZip] = useState("07960");
  const [distanceThreshold, setDistanceThreshold] = useState(15);
  const [standbyList, setStandbyList] = useState([]);
  const [hc, setHc] = useState(true);
  const [oneB, setOneB] = useState(true);
  const [affil, setAffil] = useState(false);
  const [filterInvited, setFilterInvited] = useState(true);
  const [patient, setPatient] = useState(false);
  const [ageStart, setAgeStart] = useState("");
  const [ageEnd, setAgeEnd] = useState("");
  const [formSubmit, setFormSubmit] = useState(null);
  const [listSelection, setListSelection] = useState("Available");
  const [actionConfirmMsg, setActionConfirmMsg] = useState("");
  const [actionConfirmLabel, setActionConfirmLabel] = useState("Ok");
  const [actionConfirmAltLabel, setActionConfirmAtlLabel] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [action, setAction] = useState(null);

  const [actionConfirmedModelOpen, setActionConfirmedModelOpen] = useState(false);
  const app = useApp();

  useEffect(() => {
    const loadStandByList = async () => {
      try {
        const params = {
          zip,
          radius: distanceThreshold,
          oneB,
          hc,
          affil,
          patient,
          listSelection,
          ageStart,
          ageEnd,
          formSubmit,
          filterInvited,
        };
        const uri = "/api/s/console/patient/load_standby_list";
        const { data } = await axiosClient.get(uri, { params });
        setStandbyList(data.matches);
      } catch {
        app.addError("Failed to load standby list.");
      }
    };
    if (zip.length > 4) {
      loadStandByList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    zip,
    distanceThreshold,
    hc,
    oneB,
    affil,
    patient,
    listSelection,
    ageStart,
    ageEnd,
    formSubmit,
    filterInvited,
  ]);

  const handleHcChange = (event) => {
    setHc(event.target.checked);
  };

  const handleOneBChange = (event) => {
    setOneB(event.target.checked);
  };

  const handleAffilChange = (event) => {
    setAffil(event.target.checked);
  };
  const handleFilterInvitedChange = (event) => {
    setFilterInvited(event.target.checked);
  };

  const handlePatientChange = (event) => {
    setPatient(event.target.checked);
  };

  const handleAhsZipSelected = (event, newValue) => {
    if (newValue && newValue.zip) {
      setZip(newValue.zip);
    }
  };

  const handleConfirmAction = (selectedItems, action) => {
    if (action === "AssignInTransit") {
      setActionConfirmMsg(
        `Are you sure you want to update ${selectedItems.length} form's status to 'In Transit' ?`,
      );
      setActionConfirmLabel("Ok");
      setActionConfirmAtlLabel(null);
    } else if (action === "AssignPhoneOnly") {
      setActionConfirmMsg(
        `Are you sure you want to update ${selectedItems.length} form's status to 'Phone Only' ?`,
      );
      setActionConfirmLabel("Ok");
      setActionConfirmAtlLabel(null);
    } else if (action === "MarkHasVaccine") {
      setActionConfirmMsg(
        `Are you sure you want to update ${selectedItems.length} form's status to 'Has Vaccine' ?`,
      );
      setActionConfirmLabel("Ok");
      setActionConfirmAtlLabel(null);
    } else if (action === "SendSms") {
      setActionConfirmMsg(
        `Are you sure you want to send ${selectedItems.length} people a text message to book a COVID-19 vaccine appointment?`,
      );
      setActionConfirmLabel("Invite to AHS Community");
      setActionConfirmAtlLabel("Invite to Rockaway");
    } else if (action === "SendEmail") {
      setActionConfirmMsg(
        `Are you sure you want to send ${selectedItems.length} people an email to book a COVID-19 vaccine appointment?`,
      );
      setActionConfirmLabel("Invite to AHS Community");
      setActionConfirmAtlLabel("Invite to Rockaway");
    }
    setSelectedItems(selectedItems);
    setAction(action);
    setActionConfirmedModelOpen(true);
  };

  const handleActionConfirmationCancel = () => {
    setActionConfirmedModelOpen(false);
  };

  const handleBulkStatusUpdate = async (selectedItems, status, action, param = "default") => {
    let emails = "";
    selectedItems.forEach((s, i) => {
      emails += s; // Items are emails directly
      if (i + 1 < selectedItems.length) {
        emails += ",";
      }
    });
    try {
      const { data } = await axiosClient.post("/api/s/console/patient/forms/bulkupdate", {
        emails,
        action,
        ops_status: status,
        actionParam: param,
      });
      app.addInfoMsg(data.msg);
    } catch {
      app.addError("Failed to bulk update standby list.");
    }
  };

  const handleBulkAction = async (selectedItems, action, param = "default") => {
    if (selectedItems && selectedItems.length > 0) {
      if (action === "AssignInTransit") {
        await handleBulkStatusUpdate(selectedItems, "in_transit", action, param);
      } else if (action === "AssignPhoneOnly") {
        await handleBulkStatusUpdate(selectedItems, "phone_only", action, param);
      } else if (action === "MarkHasVaccine") {
        await handleBulkStatusUpdate(selectedItems, "has_vaccine", action, param);
      } else if (action === "SendEmail" || action === "SendSms") {
        // TODO update to complete
        await handleBulkStatusUpdate(selectedItems, "needs_attention", action, param);
      }
    }
  };

  const handleActionConfirmationOk = () => {
    setActionConfirmedModelOpen(false);
    handleBulkAction(selectedItems, action, "default");
  };

  const handleActionConfirmationAlt = () => {
    setActionConfirmedModelOpen(false);
    handleBulkAction(selectedItems, action, "alternative1");
  };

  const [listMenuAnchorEl, setlistMenuAnchorEl] = React.useState(null);
  const handleChangeList = (event) => {
    setlistMenuAnchorEl(event.currentTarget);
  };
  const handleListSelectionClose = (event) => {
    setListSelection(event.target.getAttribute("value"));
    setlistMenuAnchorEl(null);
  };
  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Names" },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    { id: "cell", numeric: false, disablePadding: false, label: "Cell" },
    { id: "dob", numeric: false, isDate: true, disablePadding: false, label: "DOB" },
    {
      id: "signupDate",
      numeric: false,
      isDate: true,
      disablePadding: false,
      label: "Signup Date",
    },
    { id: "address", numeric: false, disablePadding: false, label: "Address" },
    { id: "context", numeric: false, disablePadding: false, label: "Context" },
  ];

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        classes={{
          paper: classes.confirmationModal,
        }}
        id="confirmation-menu-bulk-standbylist"
        title="Confirm Bulk Action"
        msg={actionConfirmMsg}
        open={actionConfirmedModelOpen}
        onCancel={handleActionConfirmationCancel}
        onOk={handleActionConfirmationOk}
        okLabel={actionConfirmLabel}
        onAlt={handleActionConfirmationAlt}
        altLabel={actionConfirmAltLabel}
      />
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <h2 className={classes.header}>
              Standby List: {listSelection}{" "}
              <Button aria-controls="list-menu" aria-haspopup="true" onClick={handleChangeList}>
                Change List
              </Button>
            </h2>
            <Menu
              id="list-menu"
              anchorEl={listMenuAnchorEl}
              keepMounted
              open={Boolean(listMenuAnchorEl)}
              onClose={handleListSelectionClose}
            >
              <MenuItem onClick={handleListSelectionClose} value="Available">
                Available
              </MenuItem>
              <MenuItem onClick={handleListSelectionClose} value="In Transit">
                In Transit
              </MenuItem>
              <MenuItem onClick={handleListSelectionClose} value="Phone Only">
                Phone Only
              </MenuItem>
              <MenuItem onClick={handleListSelectionClose} value="Has Vaccine">
                Has Vaccine
              </MenuItem>
            </Menu>
          </Grid>
          {listSelection === "Available" && (
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={1}>
                  <span>Filters:</span>
                </Grid>
                <Grid item xs={3}>
                  <AHSZip onChange={handleAhsZipSelected} />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="code"
                    label="Zip Custom"
                    variant="outlined"
                    value={zip}
                    onInput={(e) => setZip(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="code"
                    label="Miles Radius"
                    variant="outlined"
                    value={distanceThreshold}
                    onInput={(e) => setDistanceThreshold(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        id="code"
                        label="Age Start"
                        variant="outlined"
                        value={ageStart}
                        onInput={(e) => setAgeStart(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="code"
                        label="Age End"
                        variant="outlined"
                        value={ageEnd}
                        onInput={(e) => setAgeEnd(e.target.value)}
                      />
                      <br />
                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        disableToolbar
                        format="MM/dd/yyyy"
                        id="stand-by-list-dob"
                        label="Date of Birth"
                        value={formSubmit}
                        onChange={(date) => {
                          setFormSubmit(date);
                        }}
                        sx={{ margin: 1 }}
                        slotProps={{
                          textField: {
                            helperText: "MM/DD/YYYY",
                            variant: "outlined",
                          },
                          openPickerButton: {
                            "aria-label": "change date",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hc}
                            onChange={handleHcChange}
                            name="hc"
                            color="primary"
                          />
                        }
                        label="Healthcare"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={oneB}
                            onChange={handleOneBChange}
                            name="oneB"
                            color="primary"
                          />
                        }
                        label="1b eligible"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={patient}
                            onChange={handlePatientChange}
                            name="patient"
                            color="primary"
                          />
                        }
                        label="AHS Patient"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={affil}
                            onChange={handleAffilChange}
                            name="affil"
                            color="primary"
                          />
                        }
                        label="AHS Affiliation"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterInvited}
                            onChange={handleFilterInvitedChange}
                            name="filterInvited"
                            color="primary"
                          />
                        }
                        label="Filter Invited"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
      {standbyList.length > 0 && (
        <form className={classes.root} noValidate autoComplete="off">
          <Grid
            container
            spacing={8}
            className={classes.root}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <EnhancedTable
                colDefs={headCells}
                gridType={listSelection}
                handleSelectedAction={handleConfirmAction}
                dataItems={standbyList}
                tableTitle="Pre-registered list matching criteria"
              />
            </Grid>
          </Grid>
          <br />
        </form>
      )}
      {zip.length < 1 && standbyList.length < 1 && (
        <Paper className={classes.paper}>Enter zip above</Paper>
      )}
      {zip.length > 4 && standbyList.length < 1 && (
        <Paper className={classes.paper}>
          Sorry no records found in a {distanceThreshold} mile radius of this zip.
        </Paper>
      )}
      <br />
    </div>
  );
}
