import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Typography, useTheme } from "@mui/material";
import { LocalAudioTrack } from "twilio-video";
import AudioLevelIndicator from "components/Video/components/AudioLevelIndicator/AudioLevelIndicator";
import ToggleAudioButton from "components/Video/components/Buttons/ToggleAudioButton/ToggleAudioButton";
import AudioInputListPreview from "components/Video/components/PreJoinScreens/AudioVideoTest/AudioInputListPreview";
import ReloadButton from "components/Video/components/PreJoinScreens/AudioVideoTest/ReloadButton";
import { useCustomStyles } from "components/Video/components/PreJoinScreens/AudioVideoTest/useCustomStyles";
import { QuerySteps } from "components/Video/components/PreJoinScreens/Steps";
import useVideoContext from "components/Video/hooks/useVideoContext/useVideoContext";

interface AudioPermissionStepProps {
  setStep: (Steps: QuerySteps) => void;
}

const AudioPermissionStep = ({ setStep }: AudioPermissionStepProps) => {
  const theme = useTheme();
  const { classes } = useCustomStyles();
  const { localTracks, getLocalAudioTrack, removeLocalAudioTrack, removeLocalVideoTrack } =
    useVideoContext();

  const audioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;

  const [error, setError] = useState<string>("");
  const [isPermissionAssigned, setIsPermissionAssigned] = useState<boolean>(false);

  useEffect(() => {
    getLocalAudioTrack()
      .then(() => setIsPermissionAssigned(true))
      .catch(() => {
        setError(
          "Audio is disabled by your browser please give access to microphone and reload the app",
        );
        setIsPermissionAssigned(false);
      });
  }, [getLocalAudioTrack]);

  const removeAudio = () => {
    removeLocalAudioTrack();
  };

  return (
    <>
      <h3 className={classes.topHeading}>Please speak out loud to test your microphone.</h3>
      <div className={classes.identityContainer}>
        <div className={classes.identity}>
          <div className={classes.audioPreviewRoom}>
            <AudioLevelIndicator audioTrack={audioTrack} color={theme.palette.common.black} />
          </div>
        </div>
      </div>
      <div>
        <ToggleAudioButton className={classes.mobileButton} />
      </div>
      <Typography className={classes.subTitle}>
        The green audio indicator above should move if your microphone is connected.
      </Typography>
      {error && (
        <>
          <Typography className={classes.errorMessage}>
            Looks like your microphone has been disabled. Please reload the app to enable microphone
            access and continue your visit.
          </Typography>
          <ReloadButton />
        </>
      )}
      {!error && isPermissionAssigned && (
        <div className={classes.listSection}>
          <AudioInputListPreview />
        </div>
      )}
      <div className={classes.btnDiv}>
        <Button
          className={classes.testPreviewbutton}
          onClick={() => {
            removeAudio();
            removeLocalVideoTrack();
            setStep(QuerySteps.START_TEST);
          }}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            removeAudio();
            setStep(QuerySteps.VIDEO_PERMISSION);
          }}
          disabled={!isPermissionAssigned}
          className={classes.testPreviewbutton}
          endIcon={<ArrowForwardIcon />}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default AudioPermissionStep;
