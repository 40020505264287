import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Theme from "Theme";
import { makeStyles } from "tss-react/mui";
import { API_HOST } from "util/constants/config";
import { useAuth } from "util/Security";
import { axiosClient, getApiHost } from "util/api_helper";
import "./AuthVerification.css";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    backgroundColor: theme.palette.common.white,
    paddingBottom: "50px",
    "& .vouched_buttonText": {
      marginTop: "30px",
    },
    "& > #vouched-container": {
      flexGrow: 1,
    },
  },
  title: {
    fontSize: "34px",
    fontFamily: "Alegreya Sans",
    lineHeight: "40px",
    fontWeight: 500,
    marginTop: "30px",
    marginBottom: "25px",
  },
  subtitle: {
    fontSize: "24px",
    fontFamily: "Alegreya Sans",
    marginBottom: "25px",
  },
}));

export default function AuthVerification() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const initVouched = () => {
      let userId = null;
      const jobProps = [];
      if (auth.user) {
        userId = auth.user.userId;
        const jp = { name: "userId", value: String(userId) };

        jobProps.push(jp);
      }

      let apiHost = getApiHost();
      // TODO need to change api host to ngrok for local testing
      // ex: https://6c249f19442e.ngrok.io -> http://localhost:5000
      let vouchedAppId = "-xbZJ48Aj_sRqHWhoiZc@v_oLLn!mO";
      if (apiHost === API_HOST.myatlanticDevApi) {
        apiHost = "https://f82c-98-109-137-96.ngrok.io";
      }

      if (apiHost === API_HOST.myatlanticApi) {
        vouchedAppId = "DP#f!OEx*NMc9Tx@zHkvCct13mZQ@s";
      }
      const vouched = window.Vouched({
        // Optional verification properties.
        // verification: {
        //    // verify the user's information
        //    firstName: 'Gladys',
        //    lastName: 'West',
        // used for the crosscheck feature
        //    email: 'test@test.id',
        //    phone: '000-111-2222'
        // },
        appId: vouchedAppId,
        properties: jobProps,
        crossDevice: true,
        crossDeviceQRCode: true,
        face: "camera",
        callbackURL: `${apiHost}/api/vouched/callback`,
        verification: { enableIPAddress: true, enablePhysicalAddress: true },
        theme: {
          name: "avant",
          baseColor: Theme.palette.secondary.main,
          logo: {
            src: "https://myatlantic.atlantichealth.org/ahs_logo.jpeg",
            style: {
              maxWidth: 150,
              marginBottom: 30,
            },
          },
          font: "Arial, Helvetica, sans-serif",
        },
        manualCaptureTimeout: 20000,
        liveness: "mouth",
        onInit: async ({ token: _token, job }) => {
          // If crossDevice is true, a web token is created during initialization
          // Your backend will save and associate the token the user.
          // fetch(`/yourapi/idv?job_token=${job.token}&&user=${user.id}`);
          // /api/s/patient/createuserevent

          try {
            const postUrl = "/api/s/patient/createuserevent";
            const newUserEvent = {
              tracking_id: job.id,
              event_type: "IDVerification",
              event_name: `IDVerify-${job.id}`,
            };
            await axiosClient.post(postUrl, { ...newUserEvent });
          } catch (error) {
            console.error(`Error creating user event ${error}`);
          }
        },

        onDone: (job) => {
          // token used to query jobs
          // give vouched.id webhook time to send and save before we look for it
          setTimeout(() => {
            navigate({ pathname: `/idverifyconfirm/${job.id}`, search: params.toString() });
          }, 1100);
        },
      });
      vouched.mount("#vouched-container");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    if (!window.Vouched) {
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("src", "https://static.vouched.id/plugin/releases/v3/index.js");
      scriptTag.setAttribute("async", false);
      scriptTag.setAttribute("id", "vouched-script");
      scriptTag.addEventListener("load", initVouched);
      document.querySelector("head").appendChild(scriptTag);
      console.log("Initializing Vouched");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.title}>ID Verification</div>
      <div className={classes.subtitle}>
        Please have a form of ID ready, like a driver's license.
      </div>
      <div id="vouched-container" />
    </div>
  );
}
