import React from "react";

const UrgencyCircle = ({ color = "#DEEBFC", marginTop = "6px", marginLeft = "0px" }) => {
  return (
    <div
      style={{
        marginTop,
        marginLeft,
        marginRight: "10px",
        minWidth: 19,
        maxWidth: 19,
        height: 19,
        maxHeight: "19px",
        backgroundColor: color,
        borderRadius: "50%",
      }}
    />
  );
};

export default UrgencyCircle;
