import { React, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import { withStyles } from "tss-react/mui";
import { useApp } from "../../util/AppContext";
import { makeNewReferalStyles } from "../NewReferral/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const DialogActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs(props) {
  const { classes } = makeNewReferalStyles();
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [quickPhraseID, setQuickPhraseID] = useState("");
  const [label, setLabel] = useState("");
  const [phrase, setPhrase] = useState("");
  const [category, setCategory] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [context, setContext] = useState("");

  const { show, setShow, record, onSave, mode } = props;

  const handleClose = () => {
    setOpen(false);
    setShow(false);
    setQuickPhraseID("");
    setLabel("");
    setPhrase("");
    setCategory("");
    setSpeciality("");
    setContext("");
  };
  const showModal = () => {
    if (show) {
      setQuickPhraseID(record.id);
      setLabel(record.label);
      setPhrase(record.phrase);
      setCategory(record.category);
      setSpeciality(record.speciality);
      setContext(record.context);
      setOpen(true);
    } else {
      handleClose();
    }
  };

  const onSaveClicked = () => {
    if (label && phrase && category) {
      onSave({
        id: quickPhraseID,
        label,
        phrase,
        category,
        speciality,
        context,
      });
    } else {
      console.error("Some fields value are empty.");
      app.addError("Required fields are missing");
    }
  };

  useEffect(() => {
    showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {mode === "add" ? "Add New Quick Phrase Record" : "Edit Quick Phrase Record"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid item xs={12} md={8} lg={6}>
            <FormControl variant="standard" component="fieldset">
              <Grid
                container
                spacing={4}
                className={classes.root}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <TextField
                    style={{ width: 500 }}
                    id="label"
                    value={label}
                    name="label"
                    variant="outlined"
                    label="Label"
                    helperText="This field is Required"
                    onChange={(e) => setLabel(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    style={{ width: 500 }}
                    id="phrase"
                    value={phrase}
                    name="phrase"
                    error={false}
                    label="Phrase"
                    helperText="This field is Required"
                    variant="outlined"
                    onChange={(e) => setPhrase(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    style={{ width: 500 }}
                    id="category"
                    className={classes.textField}
                    variant="outlined"
                    error={false}
                    label="Category"
                    helperText="This field is Required"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    style={{ width: 500 }}
                    id="context"
                    className={classes.textField}
                    variant="outlined"
                    error={false}
                    label="Context"
                    value={context}
                    onChange={(e) => setContext(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    style={{ width: 500 }}
                    id="speciality"
                    className={classes.textField}
                    variant="outlined"
                    error={false}
                    label="speciality"
                    type="number"
                    value={speciality}
                    onChange={(e) => setSpeciality(e.target.value)}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => onSaveClicked()} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
