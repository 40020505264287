/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { Button, Grid, Hidden, Theme, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleAudioButton from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import IntroContainer from "../../IntroContainer/IntroContainer";
import { Steps } from "../PreJoinScreens";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import { makeStyles } from "tss-react/mui";
import HelpModal from "components/HelpModal";
import useChatContext from "components/Video/hooks/useChatContext/useChatContext";
import useVideoContext from "components/Video/hooks/useVideoContext/useVideoContext";
import {
  ADD_VIRTUAL_VISIT_TOKEN,
  CONNECT_VIRTUAL_SESSION,
} from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { useAppState } from "components/Video/state";
import { useGetIsConferenceCall } from "hooks/useGetIsConferenceCall";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { GetCurrentProgram } from "util/programsHelpers";
import { isTokenExpired } from "util/util_functions";

const useStyles = makeStyles()((theme: Theme) => ({
  linkBtn: {
    padding: 0,
    margin: "0px 8px",
  },
  gutterBottom: {
    marginBottom: "1em",
  },
  marginTop: {
    marginTop: "1em",
  },
  deviceButton: {
    width: "100%",
    border: "2px solid #aaa",
    margin: "1em 0",
  },
  localPreviewContainer: {
    paddingRight: "2em",
    [theme.breakpoints.down("md")]: {
      padding: "0 2.5em",
    },
  },
  joinButtons: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: "12px",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1.5em 0 1em",
    },
  },
  mobileButton: {
    padding: "0.8em 0",
    margin: 0,
  },
  conferenceCallScreenPrejoin: {
    height: "100ch",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  },
  loader: {
    color: theme.palette.common.white,
  },
}));

const ADHOC_PROGRAM_TYPE = "adhoc_virtual";

interface DeviceSelectionScreenProps {
  name: string;
  roomName?: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({
  name,
  setStep,
  roomName = "",
}: DeviceSelectionScreenProps) {
  const app = useApp();

  const { removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();
  const currentProgram = GetCurrentProgram();
  const { isConferenceCall } = useGetIsConferenceCall();
  const params = new URLSearchParams(window.location.search);
  const cell_phone = params.get("cell_phone");
  const isAdhoc = currentProgram?.program_type === ADHOC_PROGRAM_TYPE;
  const navigate = useNavigate();

  const { classes } = useStyles();
  const { isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, room } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const getNewToken = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosClient.post(API_ENDPOINTS.Twilio_Physician_Video_Join, {
        session_id: roomName.toString(),
      });
      app.dispatch({
        type: ADD_VIRTUAL_VISIT_TOKEN,
        payload: { id: roomName, token: data.token },
      });
      videoConnect(data.token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" && chatConnect(data.token);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      app.addError("Problem Joining Virtual Session.");
      return null;
    }
  };

  const handleCreateConferenceCall = async () => {
    try {
      const url = "/api/s/console/virtualvisit/invitebycall";
      await axiosClient.post(url, {
        cell_phone,
        virtual_session_id: roomName,
        program: isAdhoc ? "Adhoc Virtual Visits" : "Virtual Urgent Care",
      });
    } catch (err) {
      app.addError(
        "Failed to conference call user in for virtual session, please contact support.",
      );
      room?.disconnect();
      app.setVideoFinished(true);
      app.dispatch({
        type: CONNECT_VIRTUAL_SESSION,
        payload: null,
      });

      navigate(`/video/${roomName}?step=preview`);
    }
  };

  const handleJoin = async (roomName: any) => {
    const vs_visit = app.vs_visits_state.virtualVisits.find(
      (vs: any) => Number(vs.id) === Number(roomName),
    );
    const vs_visitHasToken = !isEmpty(vs_visit) && vs_visit.token;
    if (vs_visitHasToken) {
      if (isTokenExpired(vs_visit.token)) {
        await getNewToken();
      } else {
        videoConnect(vs_visit.token);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" &&
          chatConnect(vs_visit.token);
      }
    } else {
      await getNewToken();
    }
    if (isConferenceCall) {
      handleCreateConferenceCall();
    }
  };

  const handlePageReload = () => {
    window.location.reload();
    return false;
  };

  const handleHelpModalOpen = () => {
    setHelpModalOpen(true);
  };

  const handleHelpModalClose = () => {
    setHelpModalOpen(false);
  };

  if ((isFetching || isConnecting || isLoading) && isConferenceCall) {
    return (
      <IntroContainer hideContent>
        <div className={classes.conferenceCallScreenPrejoin}>
          <CircularProgress variant="indeterminate" className={classes.loader} />
        </div>
      </IntroContainer>
    );
  }

  if ((isFetching || isConnecting || isLoading) && !isConferenceCall) {
    return (
      <IntroContainer>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ height: "100%" }}
        >
          <div>
            <CircularProgress variant="indeterminate" />
          </div>
          <div>
            <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "16px" }}>
              Joining Meeting
            </Typography>
          </div>
        </Grid>
      </IntroContainer>
    );
  }

  return (
    <IntroContainer>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join {roomName}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
            <SettingsMenu
              mobileButtonClass={classes.mobileButton}
              handleHelpModalOpen={handleHelpModalOpen}
            />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ height: "100%" }}
          >
            <div>
              <Hidden mdDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
            <div>
              <div>Having issues with your audio or video?</div>
              <span>Try</span>
              <Button color="primary" className={classes.linkBtn} onClick={handlePageReload}>
                reloading the page
              </Button>
              <span>or</span>
              <Button color="primary" className={classes.linkBtn} onClick={handleHelpModalOpen}>
                contact support
              </Button>
              <span>.</span>
            </div>
            <div className={classes.joinButtons}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  removeLocalAudioTrack();
                  removeLocalVideoTrack();
                  if (currentProgram) {
                    window.open(
                      `${currentProgram?.url_path}?${params.toString()}&vsId=${roomName}`,
                      "_self",
                      "noopener, noreferrer",
                    );
                  } else {
                    window.open("/console", "_self", "noopener, noreferrer");
                  }
                }}
                disabled={!currentProgram}
                data-cy="closeSession"
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={() => handleJoin(roomName)}
              >
                Join Now
              </Button>
            </div>
          </Grid>
        </Grid>
        <HelpModal isOpen={isHelpModalOpen} handleClose={handleHelpModalClose} />
      </Grid>
    </IntroContainer>
  );
}
