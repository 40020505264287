/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationCache, QueryCache, QueryClient } from "react-query";

const handleError = (error: any) => {
  const errors = [400, 401, 403, 404, 500];
  const statusCode = error.request ? error.request.status : null;
  const errorUrl = `/error/${statusCode}`;

  if (errors.includes(statusCode)) {
    window.location.assign(errorUrl);
  }
};

const queryClientSingleton = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => handleError(error),
  }),
  mutationCache: new MutationCache({
    onError: (error) => handleError(error),
  }),
});

export default queryClientSingleton;
