export const getDoctorsCoordinates = (doctors) => {
  const config = [];
  doctors.forEach((doctor) =>
    doctor.locations.forEach((location) =>
      config.push({
        coordinates: {
          lat: location.coordinates.lat,
          lng: location.coordinates.lon,
        },
        id: location.id,
        providerId: doctor.provider_id,
      }),
    ),
  );
  return config;
};
