import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, List, ListItem, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { parse } from "date-fns";
import { makeStyles } from "tss-react/mui";
import { epicUrl } from "util/constants/config";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import RoutesHelper from "util/routesHelper";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100ch",
    alignItems: "center",
  },
  inputRoot: {
    borderRadius: "4px",
    height: "56px",
  },
  loadingRoot: {
    justifyContent: "center",
  },
  paper: {
    flexGrow: 1,
    margin: "auto",
    width: "80%",
  },
  textField: {
    width: "100%",
    marginBottom: "15px",
  },
  title: {
    fontSize: "34px",
    fontFamily: "Alegreya Sans",
    lineHeight: "40px",
    fontWeight: 500,
    marginTop: "80px",
    marginBottom: "25px",
  },
  subtitle: {
    fontSize: "22px",
    lineHeight: "32px",
    fontFamily: "Alegreya Sans",
    marginBottom: "0px auto 45px auto",
    width: "90%",
    maxWidth: "608px",
  },
  btnlinks: {
    border: "none",
    cursor: "pointer",
    outline: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
    padding: 0,
    fontSize: "20px",
    color: theme.palette.primary.main,
    fontFamily: "Alegreya Sans",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  links: {
    fontSize: "20px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  listContainer: {
    marginTop: "60px",
    fontFamily: "Alegreya Sans",
  },
  optionTitle: {
    marginRight: "10px",
    fontWeight: 500,
    minWidth: "fit-content",
    fontFamily: "Alegreya Sans",
    fontSize: "22px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  rootForm: {
    width: "95%",
    maxWidth: "608px",
    marginTop: "40px",
  },
  button: {
    textTransform: "uppercase",
    width: "100%",
    marginTop: "20px",
    height: "42px",
    marginBottom: "40px",
  },
  imagesContainer: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "auto auto",
    "@media(max-width: 660px)": {
      gridTemplateColumns: "auto",
      justifyContent: "center",
      gap: "12px",
    },
  },
}));

export default function AuthVerifyConfirmView() {
  const { classes } = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [stateCd, setStateCd] = useState("");
  const [streetLine1, setStreetLine1] = useState("");
  const [streetLine2, setStreetLine2] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [gender, setGender] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [idGood, setIdGood] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const app = useApp();
  const { redirectHelper } = RoutesHelper();

  const { idverifyjobid } = useParams();
  const params = new URLSearchParams(location.search);

  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const parseVouchedResponse = (respJson) => {
    console.log(`Got vouched payload: ${JSON.stringify(respJson)}`);
    if (respJson && respJson.user_event && respJson.user_event.content) {
      if (respJson.cell_phone) {
        setCellPhone(respJson.cell_phone);
      }
      if (respJson.email) {
        setEmail(respJson.email);
      }
      const c = respJson.user_event.content;
      let js = c.replace(/'/g, '"');
      js = js.replace(/None/g, "null");
      js = js.replace(/True/g, "true");
      js = js.replace(/False/g, "false");
      const idVerifyPayload = JSON.parse(js);

      if (idVerifyPayload && idVerifyPayload.request && idVerifyPayload.result) {
        console.log(`idVerifyPayload: ${JSON.stringify(idVerifyPayload)}`);
        if (
          idVerifyPayload.status === "completed" &&
          (idVerifyPayload.result.success.toString().toLowerCase() === "true" ||
            idVerifyPayload.result.successWithSuggestion.toString().toLowerCase() === "true") &&
          idVerifyPayload.result.ipAddress &&
          idVerifyPayload.result.ipAddress.country === "US"
        ) {
          setIdGood(true);
          if (idVerifyPayload.result.firstName) {
            const fnParts = idVerifyPayload.result.firstName.split(" ");
            setFirstName(fnParts[0]);
          }

          setLastName(idVerifyPayload.result.lastName);

          const dobTemp = idVerifyPayload.result.dob;
          if (dobTemp && dobTemp.length > 6) {
            const dobParts = dobTemp.split("/");
            if (dobParts.length === 3) {
              const dobBuilt = `${dobParts[2]}-${dobParts[0]}-${dobParts[1]}`;
              setDob(dobBuilt);
              console.log(`testting DOB to ${dobBuilt}`);
            }
          }

          if (idVerifyPayload.result.idAddress) {
            setStreetLine1(
              `${idVerifyPayload.result.idAddress.streetNumber}  ${idVerifyPayload.result.idAddress.street}`,
            );
            if (idVerifyPayload.result.idAddress.unit) {
              setStreetLine2(idVerifyPayload.result.idAddress.unit);
            }
            setZipCode(idVerifyPayload.result.idAddress.postalCode);
            setCity(idVerifyPayload.result.idAddress.city);
            setStateCd(idVerifyPayload.result.idAddress.state);
          } else if (
            idVerifyPayload.result.unverifiedIdAddress &&
            idVerifyPayload.result.unverifiedIdAddress.length > 1
          ) {
            setStreetLine1(idVerifyPayload.result.unverifiedIdAddress[0]);
            const addressLine2Parts = idVerifyPayload.result.unverifiedIdAddress[2].split(",");
            if (addressLine2Parts.length > 1) {
              setCity(addressLine2Parts[0].trim());
              const stateZip = addressLine2Parts[1].split(" ");
              if (stateZip.length > 1) {
                setStateCd(stateZip[0].trim());
                setZipCode(stateZip[1].trim());
              }
            }
          }
          if (idVerifyPayload.request.parameters) {
            console.log(idVerifyPayload.request.parameters);
          }
        } else {
          app.addError(
            "Sorry!  Your ID Document and Selfie verification did not pass.  Please follow instructions to try again or move on.",
          );
          setIdGood(false);
        }
      } else {
        app.addError("Failed to lookup identity verificaton details, please try again.");
      }
    }
  };

  useEffect(() => {
    const lookupJobDetails = async () => {
      if (!idverifyjobid) {
        setIsLoading(false);
        app.addError("Failed to load ID Verification result, no ID Job ID.");
        return;
      }

      let numTries = 0;
      // Vouched may take time sending results, so try a few times to load it
      while (numTries < 5) {
        await sleep(500);
        numTries += 1;
        try {
          const { data } = await axiosClient.get("/api/s/patient/usereventlookup", {
            params: { tracking_id: idverifyjobid, event_type: "IDVerification" },
          });
          parseVouchedResponse(data);
        } catch {
          setIsLoading(false);
          app.addError(
            "Wariing: Did not find an valid identity verificaton record, please try again or follow instructions for other options.",
          );
          return;
        }
      }
      setIsLoading(false);
    };

    lookupJobDetails();
    // eslint-disable-next-line
  }, []);

  const handleSaveBtn = async (event) => {
    event.preventDefault();
    if (
      email.length < 1 ||
      gender.length < 1 ||
      streetLine1.length < 1 ||
      firstName.length < 1 ||
      lastName.length < 1 ||
      dob.length < 1 ||
      zipCode.length < 1
    ) {
      app.addError("Please enter values for all fields to be able to register, thank you.");
      return;
    }
    try {
      const postUrl = "/api/s/patient/create";
      const newPatient = {
        firstName,
        lastName,
        email,
        streetAddress: streetLine1,
        streetAddress2: streetLine2,
        dob,
        zipCode,
        cellPhone,
        gender,
      };

      const { data } = await axiosClient.post(postUrl, {
        ...newPatient,
        id_verify_tracking_id: idverifyjobid,
      });
      if (data && data?.data && data?.data?.pat_id) {
        app.addInfoMsg(
          `New Patient Record has been created successfully with patient MRN ID ${data.data.pat_mrn_id}`,
        );
        navigate({ pathname: "/setuserpwd/", search: params.toString() });
      }
    } catch (error) {
      if (error.response.status === 409) {
        app.addInfoMsg("Existing Patient Record was successfully matched with patient");
        redirectHelper(params);
      }
      if (error.response.status !== 409) {
        app.addError(
          "Error: Failed to create new patient record. Please try again or contact support.",
        );
      }
    }
  };

  return (
    <div className={`${classes.root} ${isLoading && classes.loadingRoot}`}>
      {isLoading && <CircularProgress />}

      {!isLoading && idGood && (
        <>
          <div className={classes.title}>Identity Verification Confirmation</div>
          <div className={classes.subtitle}>
            Please review the information extracted from your Identity Document below and update it
            to the most current information if necessary.
          </div>
          <form className={classes.rootForm} noValidate autoComplete="off">
            <TextField
              id="firstName"
              label="Legal First Name"
              variant="outlined"
              className={classes.textField}
              InputProps={{
                className: classes.inputRoot,
              }}
              value={firstName}
              fullWidth
              onInput={(e) => setFirstName(e.target.value)}
            />
            <TextField
              id="lastName"
              disabled={lastName && lastName.length > 1}
              label="Legal Last Name"
              variant="outlined"
              className={classes.textField}
              InputProps={{
                className: classes.inputRoot,
              }}
              value={lastName}
              onInput={(e) => setLastName(e.target.value)}
            />
            <DatePicker
              format="MM/dd/yyyy"
              margin="normal"
              label="Date of Birth"
              // eslint-disable-next-line
              value={dob ? parse(dob, "MM-dd-yyyy", new Date()) : null}
              onChange={(date) => setDob(date)}
              slotProps={{
                textField: {
                  className: classes.textField,
                  variant: "outlined",
                  helperText: "MM/DD/YYYY",
                  id: "auth-verify-dob",
                },
                openPickerButton: {
                  "aria-label": "change date",
                },
              }}
            />
            <TextField
              id="street1"
              label="Address Line 1"
              variant="outlined"
              className={classes.textField}
              InputProps={{
                className: classes.inputRoot,
              }}
              value={streetLine1}
              onInput={(e) => setStreetLine1(e.target.value)}
            />
            <TextField
              id="street2"
              label="Address Line 2"
              variant="outlined"
              className={classes.textField}
              InputProps={{
                className: classes.inputRoot,
              }}
              value={streetLine2}
              onInput={(e) => setStreetLine2(e.target.value)}
            />
            <TextField
              id="zipCode"
              label="Zip Code"
              variant="outlined"
              className={classes.textField}
              InputProps={{
                className: classes.inputRoot,
              }}
              value={zipCode}
              onInput={(e) => setZipCode(e.target.value)}
            />
            <TextField
              id="city"
              label="City"
              className={classes.textField}
              InputProps={{
                className: classes.inputRoot,
              }}
              value={city}
              onInput={(e) => setCity(e.target.value)}
              variant="outlined"
            />
            <TextField
              id="state"
              label="State"
              className={classes.textField}
              InputProps={{
                className: classes.inputRoot,
              }}
              value={stateCd}
              onInput={(e) => setStateCd(e.target.value)}
              variant="outlined"
            />
            <TextField
              id="email_address"
              label="Email Address"
              value={email}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputProps={{
                className: classes.inputRoot,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              id="gender"
              label="Medical Gender"
              select
              value={gender}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputProps={{
                className: classes.inputRoot,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem key="male" value="male">
                Male
              </MenuItem>
              <MenuItem key="female" value="female">
                Female
              </MenuItem>
            </TextField>

            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSaveBtn}
              className={classes.button}
            >
              Complete Registration
            </Button>
          </form>
        </>
      )}

      {!isLoading && !idGood && (
        <>
          <div className={classes.title}>Identity Verification Failed</div>
          <div className={classes.subtitle}>
            Sorry. We were unable to verify your identity by the ID Document and Selfie taken. This
            may be caused by poor quality of the images uploaded. Please see your options below.
          </div>
          <List
            component="nav"
            aria-label="secondary mailbox folders"
            className={classes.listContainer}
          >
            <ListItem>
              <span className={classes.optionTitle}>Option 1:</span>
              <button
                className={classes.btnlinks}
                type="button"
                onClick={() => {
                  navigate("/id/verify");
                }}
              >
                Retry the ID Document &amp; selfie process again.
              </button>
            </ListItem>
            <ListItem>
              <span className={classes.optionTitle}>Option 2:</span>
              <a
                className={classes.links}
                href={`${epicUrl}/signup`}
                target="_blank"
                rel="noreferrer"
              >
                Try the MyChart Sign Up process that will ask you challenge questions to prove your
                identity.
              </a>
            </ListItem>
            <ListItem>
              <span className={classes.optionTitle}>Option 3:</span>
              <a
                className={classes.links}
                href={`${epicUrl}/publicforms.asp?mode=showform&formname=CustomerService`}
                target="_blank"
                rel="noreferrer"
              >
                Contact the Technical Support team{" "}
              </a>
            </ListItem>
          </List>
        </>
      )}
    </div>
  );
}
