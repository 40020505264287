import React from "react";
import Theme from "Theme";
import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Participant } from "twilio-video";
import useParticipantNetworkQualityLevel from "../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel";

const useStyles = makeStyles()({
  innerContainer: {
    display: "flex",
    alignItems: "flex-end",
    "& div": {
      width: "2px",
      marginRight: "1px",
      "&:not(:last-child)": {
        borderRight: "none",
      },
    },
  },
});

const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];

export default function NetworkQualityLevel({ participant }: { participant: Participant }) {
  const { classes } = useStyles();
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  if (networkQualityLevel === null) {
    return null;
  }

  return (
    <div className={classes.innerContainer}>
      {BARS_ARRAY.map((level) => (
        <div
          key={level}
          style={{
            height: `${STEP * (level + 1)}px`,
            background:
              networkQualityLevel > level
                ? Theme.palette.common.white
                : alpha(Theme.palette.common.white, 0.2),
          }}
        />
      ))}
    </div>
  );
}
