import React from "react";

export default function AdjustedHoursListContent({ adjustedHoursList, addNewAdjustedHours }) {
  if (adjustedHoursList.length > 0) {
    return null;
  }
  if (!addNewAdjustedHours) {
    return <div>"No Data Available"</div>;
  }
  return null;
}
