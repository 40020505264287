/* eslint-disable */
import { useEffect, useState } from "react";
import useTwilioSync from "../../../../hooks/useTwilioSync/useTwilioSync";
import { useApp } from "../../../../util/AppContext";

const useCheckOnlineStatus = () => {
  const { vs_visits_state } = useApp();
  const { syncClient } = useTwilioSync();
  const currentVirtualVisit = vs_visits_state?.currentVirtualVisit;
  const [isOnline, setIsOnline] = useState(false);
  const [syncMap, setSyncMap] = useState(null);

  const checkOnlineStatus = (syncItems) => {
    syncItems.items.forEach((item) => {
      if (Number(item.key) === Number(currentVirtualVisit?.patient_id)) {
        setIsOnline(item.data.is_online);
      }
    });
  };

  const subscribe = (args) => {
    if (Number(args.item.key) === Number(currentVirtualVisit?.patient_id)) {
      setIsOnline(args.item.data.is_online);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (syncClient && currentVirtualVisit?.external_sync_map_id) {
          const SyncMap = await syncClient.map(currentVirtualVisit?.external_sync_map_id);
          setSyncMap(SyncMap);
        } else {
          setSyncMap(null);
        }
      } catch (error) {
        console.error(error);
        setSyncMap(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.id]);

  useEffect(() => {
    if (syncMap) {
      syncMap.getItems().then(checkOnlineStatus);
      syncMap.on("itemAdded", subscribe);
      syncMap.on("itemUpdated", subscribe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncMap]);

  return {
    syncClient,
    syncMap,
    isOnline,
  };
};

export default useCheckOnlineStatus;
