import * as Yup from "yup";

export type FormValues = {
  emergencyContactName: string;
  emergencyContactPhone: string;
  primaryCarePhysicianName: string;
  primaryCarePhysicianNpi: string;
  insuranceCompanyId: number | "";
  memberIdNumber: number | "";
};

export const initialValues: FormValues = {
  emergencyContactName: "",
  emergencyContactPhone: "",
  primaryCarePhysicianName: "",
  primaryCarePhysicianNpi: "",
  insuranceCompanyId: "",
  memberIdNumber: "",
};

export const validationSchema = Yup.object({
  emergencyContactName: Yup.string().required("Emergency contact name is required"),
  emergencyContactPhone: Yup.string()
    .required("Emergency contact phone is required")
    .min(10, "Phone number must be 10 digit"),
  insuranceCompanyId: Yup.string().required("Insurance company is required"),
});
