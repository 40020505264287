import { API_ENDPOINTS, axiosClient } from "util/api_helper";

export const handleFetchProgram = async ({ programName, handleSuccess, app }) => {
  try {
    const url = `${API_ENDPOINTS.program_basics}?program_name=${programName}`;
    const { data } = await axiosClient.get(url);
    handleSuccess(data?.data);
  } catch (err) {
    // @ts-ignore
    app.addError(err?.message || "Problem Fetching Programs");
  }
};
