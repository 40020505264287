import React from "react";
import { Delete as DeleteIcon, PictureAsPdf as PictureAsPdfIcon } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";

export default function ImageInformation({ imageInfo, index, removeImage }) {
  return (
    <ListItem alignItems="center" data-cy={`photo${index}`} divider>
      <ListItemAvatar>
        {imageInfo?.type === "application/pdf" ? (
          <PictureAsPdfIcon data-testid="pdf-icon" />
        ) : (
          <img
            id={`previewImage_${index}`}
            width="40"
            height="40"
            src={imageInfo.previewImage}
            alt="previewImage"
          />
        )}
      </ListItemAvatar>
      <ListItemText primary={imageInfo.name} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={() => removeImage(index)} size="large">
          <DeleteIcon data-testid="delete-icon" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
