import React from "react";
import useTranslation from "hooks/useTranslation";

export type QuestionsData = {
  id: string;
  question: string;
  questionGroup: number;
  allowRadioButtons: boolean;
  allowNotSureOption: boolean;
  showTextField: boolean;
  centerContent?: boolean;
  additionalInformationId?: number;
  isCommentRequired?: boolean;
  placeholder?: string;
};

export type PersonalInformation = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: Date;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  cellphone?: string;
  email?: string;
  clpCdlHolder?: boolean;
  denialMedicalCertificate?: "YES" | "NO" | "NOT_SURE";
  driverLicense?: DriverLicense;
};

type DriverLicense = {
  driverLicenseNumber: string;
  driverLicenseState: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  city: string;
  zipCode: string;
  state: string;
  issuingState: string;
  expirationDate: Date;
  dob: Date;
  dateOfIssue: Date;
  address: string;
  street: string;
};

export type StepChangeProps = {
  shouldDisableContinueButton?: boolean;
  saveProgress?: boolean;
  isMedicalDetermination?: boolean;
  shouldReloadFormData?: boolean;
  shouldMoveToTheNextStep?: boolean;
};

export type StepsProps = {
  handleChange: (
    name: string,
    e: React.ChangeEvent<any> | string | boolean | number | undefined,
  ) => void;
  formData?: any; // change for types
  medicalRecords?: Set<string> | undefined;
  isFullPage?: boolean;
  isFormDisabled?: boolean;
  showTitle?: boolean; // this prop will be used to show/hide the title in the steps, in default title will only be shown in mobile but we need it for staff side dashboard
  handleStepChange?: (stepInfo: StepChangeProps) => void;
  isStaff?: boolean;
  formRef?: React.RefObject<HTMLFormElement>; // this prop will pass the form ref to the steps, we might want to explore using forwardRef
  isLoading?: boolean;
  formId?: string;
  setShouldDisableContinueButton?: (shouldDisableContinueButton: boolean) => void;
};

export type MedicationsProps = {
  medicalRecord: Set<string> | undefined;
  patientReported: string[];
};

export interface DeterminationTypesProps extends StepsProps {
  isDisabled?: boolean;
}

export type DeterminationQuestions = {
  id: number;
  question: string;
  determination: string;
  value: boolean;
  textAreaName: string;
  cyName: string;
};

export interface HealthHistory {
  healthHistory: Record<string, unknown>;
  surgeries: Record<string, unknown>;
  medications: Record<string, unknown>;
}

export interface HealthHistoryQuestions {
  [key: string]: {
    answer?: string;
    text?: string;
  };
}

export interface IData {
  personalInformation: PersonalInformation;
  healthHistory: HealthHistory;
  medicalDetermination: string | null;
  driverRelation: string;
}

const {
  web: {
    dotForm: {
      patient: { healthHistory },
    },
  },
  // eslint-disable-next-line
} = useTranslation();

export const DOT_PATIENT_STEPS = [
  "Personal Information",
  "Address Verification",
  "Relationship to Driver",
  "Driver's Health History",
  "CMV Driver's Signature",
  "Confirmation",
];

export const DOT_MEDICALEXAMINER_STEPS = [
  "Driver's Health Review",
  "Medical Examiner Determination",
  "Generate Forms",
];

export type AdditionalInformation = {
  id: number;
  title: string;
  information: string[] | React.ReactNode[];
  showAsAnOrderedList?: boolean;
};

export const DEMOGRAPHIC_INITIAL_STATE: PersonalInformation = {
  firstName: "",
  middleName: "",
  lastName: "",
  dob: new Date(),
  street: "",
  city: "",
  state: "",
  zip: "",
  cellphone: "",
  email: "",
  clpCdlHolder: false,
  denialMedicalCertificate: "NOT_SURE",
  driverLicense: {
    driverLicenseNumber: "",
    driverLicenseState: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    city: "",
    zipCode: "",
    state: "",
    issuingState: "",
    expirationDate: new Date(),
    dob: new Date(),
    dateOfIssue: new Date(),
    address: "",
    street: "",
  },
};

export const HEALTH_HISTORY_INITIAL_STATE = {
  healthHistory: {},
  surgeries: {},
  medications: {},
};

export const ADDITIONAL_INFORMATION: AdditionalInformation[] = [
  {
    id: 1,
    title: "Diabetes",
    information: [
      "Please bring your HBA1c test results from within the past 3-6 months",
      React.createElement(
        "div",
        null,
        "Please make an appointment with your ophthalmologist or optometrist and ",
        React.createElement(
          "a",
          {
            href: "https://www.fmcsa.dot.gov/sites/fmcsa.dot.gov/files/2023-08/Vision%20Evaluation%20Report%20Form%20MCSA-5871_508.pdf",
          },
          React.createElement("b", null, "ask them to fill out the evaluation form"),
        ),
      ),
      React.createElement(
        "div",
        null,
        "If you use insulin please make an appointment with your endocrinologist and ",
        React.createElement(
          "a",
          {
            href: "https://www.fmcsa.dot.gov/regulations/medical/insulin-treated-diabetes-mellitus-assessment-form-mcsa-5870",
          },
          React.createElement(
            "b",
            null,
            "ask them to fill out the Insulin-Treated Diabetes Mellitus Assessment Form",
          ),
        ),
      ),
    ],
    showAsAnOrderedList: true,
  },
  {
    id: 2,
    title: "Head/Brain Injuries or Illnesses",
    information: [
      React.createElement(
        "div",
        null,
        "This condition requires a neurology clearance. ",
        React.createElement(
          "b",
          null,
          "Please make an appointment with your neurologist and ask them to provide written clearance",
        ),
        " regarding your condition.",
      ),
    ],
  },
  {
    id: 3,
    title: "Seizures / Epilepsy",
    information: [
      React.createElement(
        "div",
        null,
        "Applicants with an epilepsy/seizure disorder, should be seizure-free for 8 years.  If an applicant has had a single unprovoked seizure, they should be seizure-free for 4 years. ",
        React.createElement(
          "b",
          null,
          "Please make an appointment with your neurologist and ask them to provide written clearance regarding your condition. ",
        ),
        React.createElement(
          "a",
          {
            href: "https://www.fmcsa.dot.gov/sites/fmcsa.dot.gov/files/2021-01/NEW%20Seizure%2001252021.pdf",
          },
          "Please review additional information on seizure/epilepsy exemption requirements.",
        ),
      ),
    ],
  },
  {
    id: 4,
    title: "Ear or Hearing Problems",
    information: [
      "If you use hearing aids, please make sure to wear them to your physical appointment.",
    ],
  },
  {
    id: 5,
    title: "Heart Problems, implantable devices or heart procedures",
    information: [
      React.createElement(
        "div",
        null,
        "This condition requires a cardiology clearance. ",
        React.createElement(
          "b",
          null,
          "Please make an appointment with your cardiologist and ask them to provide written clearance",
        ),
        " regarding your condition.",
      ),
    ],
  },
  {
    id: 6,
    title: "Pacemaker, stents, implantable devices, or other heart procedures",
    information: [
      React.createElement(
        "div",
        null,
        "This condition requires a cardiology clearance. ",
        React.createElement(
          "b",
          null,
          "Please make an appointment with your cardiologist and ask them to provide written clearance",
        ),
        " regarding your condition.",
      ),
    ],
  },
  {
    id: 7,
    title: "SPE Certification",
    information: [
      React.createElement(
        "div",
        null,
        "Drivers with physical impairments, or with missing limbs (e.g., a hand or finger, an arm, foot or leg), are required to obtain SPE certificates. ",
        React.createElement(
          "a",
          {
            href: "https://www.fmcsa.dot.gov/medical/driver-medical-requirements/skill-performance-evaluation-certificate-program",
          },
          React.createElement("b", null, "Please review the application package here"),
        ),
      ),
    ],
  },
  {
    id: 8,
    title: "Sleep Apnea",
    information: [
      React.createElement(
        "div",
        null,
        "If you’ve been diagnosed with sleep apnea, please bring something to show",
        React.createElement("b", null, " C-pap "),
        "compliancy for 1 year.  This can be shown digitally on your mobile device, or with clearance from your pulmonologist.",
      ),
    ],
  },
];

export const QUESTIONS_DATA: QuestionsData[] = [
  {
    id: "Q_SURGERY",
    question: healthHistory.hadSurgeryQuestion,
    questionGroup: 1,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_CURRENT_MEDICATIONS",
    question: healthHistory.medicationsQuestion,
    questionGroup: 1,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    placeholder: "Please list, separated by commas",
  },
  {
    id: "Q_HEAD_BRAIN_INJ",
    question: healthHistory.concussionQuestion,
    questionGroup: 2,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    additionalInformationId: 2,
  },
  {
    id: "Q_SEIZURES_EPILEPSY",
    question: healthHistory.seizuresQuestion,
    questionGroup: 2,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    additionalInformationId: 3,
  },
  {
    id: "Q_EYE_PROBLEMS",
    question: healthHistory.eyeProblemsQuestion,
    questionGroup: 2,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_EAR_PROBLEMS",
    question: healthHistory.earProblem,
    questionGroup: 2,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    additionalInformationId: 4,
  },
  {
    id: "Q_HEART_PROBLEMS",
    question: healthHistory.heartDiseaseQuestion,
    questionGroup: 2,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    additionalInformationId: 5,
  },
  {
    id: "Q_PACEMAKER",
    question: healthHistory.pacemakerQuestion,
    questionGroup: 3,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    additionalInformationId: 6,
  },
  {
    id: "Q_HIGH_BLOOD_PRESSURE",
    question: healthHistory.bloodPressureQuestion,
    questionGroup: 3,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_CHOLESTEROL",
    question: healthHistory.cholesterolQuestion,
    questionGroup: 3,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_CHRONIC_LUNGS_PROBLEMS",
    question: healthHistory.breathingProblemsQuestion,
    questionGroup: 3,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_LUNG_DISEASE",
    question: healthHistory.lungQuestion,
    questionGroup: 3,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_KIDNEY_PROBLEMS",
    question: healthHistory.kidneyQuestion,
    questionGroup: 4,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_STOMACH_ISSUES",
    question: healthHistory.stomachQuestion,
    questionGroup: 4,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_DIABETES",
    question: healthHistory.diabetesQuestion,
    questionGroup: 4,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    additionalInformationId: 1,
  },
  {
    id: "Q_INSULIN",
    question: healthHistory.insulinQuestion,
    questionGroup: 4,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    isCommentRequired: false,
  },
  {
    id: "Q_MENTAL_HEALTH",
    question: healthHistory.anxietyQuestion,
    questionGroup: 5,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_FAINTING",
    question: healthHistory.faintingQuestion,
    questionGroup: 5,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_DIZZINESS_MEM_LOSS",
    question: healthHistory.dizzinessQuestion,
    questionGroup: 5,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_UNEXPLAINED_WEIGHT_LOSS",
    question: healthHistory.weightLossQuestion,
    questionGroup: 5,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_STROKE_PARALYSIS",
    question: healthHistory.strokeQuestion,
    questionGroup: 6,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_LIMBS_LIMITED_USE",
    question: healthHistory.limbsQuestion,
    questionGroup: 6,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    additionalInformationId: 7,
  },
  {
    id: "Q_NECK_BACK_PROBLEMS",
    question: healthHistory.neckQuestion,
    questionGroup: 6,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_JOINT_NERVE_PROBLEM",
    question: healthHistory.boneQuestion,
    questionGroup: 6,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_BLOOD_CLOTS",
    question: healthHistory.bloodClothsQuestion,
    questionGroup: 7,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_CANCER",
    question: healthHistory.cancerQuestion,
    questionGroup: 7,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_CHRONIC_DISEASE",
    question: healthHistory.chronicDiseaseQuestion,
    questionGroup: 7,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_SLEEP_SNORING_ISSUES",
    question: healthHistory.sleepDisordersQuestion,
    questionGroup: 7,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_SLEEP_APNEA",
    question: healthHistory.sleepTestQuestion,
    questionGroup: 8,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
    additionalInformationId: 8,
  },
  {
    id: "Q_NIGHT_HOSPITAL",
    question: healthHistory.nighAtHospitalQuestion,
    questionGroup: 8,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_BROKEN_BONE",
    question: healthHistory.brokenBoneQuestion,
    questionGroup: 8,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_USE_TOBACCO",
    question: healthHistory.useTobaccoQuestion,
    questionGroup: 8,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_DRINK_ALCOHOL",
    question: healthHistory.drinkAlcoholQuestion,
    questionGroup: 8,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_ILLEGAL_SUBSTANCE",
    question: healthHistory.drugsQuestion,
    questionGroup: 9,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_FAILED_DRUG_TEST",
    question: healthHistory.failedDrugTestQuestion,
    questionGroup: 9,
    allowRadioButtons: true,
    allowNotSureOption: true,
    showTextField: false,
  },
  {
    id: "Q_OTHER_HEALTH_CONDITIONS",
    question: healthHistory.otherConditionsQuestion,
    questionGroup: 10,
    allowRadioButtons: false,
    allowNotSureOption: false,
    showTextField: true,
    centerContent: true,
  },
];
