import { getDate, getLocalTime, isDateFormat, isDateStringValid } from "util/util_functions";

export const getFormattedTableCell = (tableCell) => {
  if (!tableCell && tableCell !== 0) {
    return "\u2013";
  }
  return tableCell;
};

export const getFormattedDateTableCell = (tableCell) => {
  if (!tableCell && tableCell !== 0) {
    return "\u2013";
  }
  if (isDateFormat(tableCell)) {
    return getDate(tableCell);
  }
  if (isDateStringValid(tableCell)) {
    return getLocalTime(tableCell);
  }
  return tableCell;
};
