import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import AHSEmailInput from "components/shared/AHSEmailInput/Index";
import { EditUserProps } from "../types";
import { makeNewReferalStyles } from "../../NewReferral/styles";

export const EditUser: React.FC<EditUserProps> = ({
  record,
  userRecord,
  setUserRecord,
  isLockedUser,
  setIsLockedUser,
  userTypeRefList,
  onSaveClicked,
  error,
}) => {
  const { classes } = makeNewReferalStyles();
  const errorText = "This field is required";
  const [emailIncorrect, setEmailIncorrect] = React.useState(false);
  const handleChangeExternalId = (e) => {
    const idValue = e.target.value;

    if (idValue?.match(/^\d+$/)) {
      setUserRecord({ ...userRecord, externalId: idValue });
    } else if (idValue === "" || idValue === null) {
      setUserRecord({ ...userRecord, externalId: null });
    }
  };

  const handleCellPhoneOnChange = (e) => {
    const { value } = e.target;
    if ((value && value.match(/^\d+$/) == null) || value.toString().length > 10) {
      return;
    }

    setUserRecord({ ...userRecord, cellPhone: e.target.value });
  };

  const handleEmailChange = (email: string) => {
    if (email) {
      const checkEmail = email.includes("@");
      setEmailIncorrect(checkEmail);
      setUserRecord({ ...userRecord, email });
    }
  };

  return (
    <>
      {parseInt(String(record?.failed_login_attempts) || "0", 10) >= 10 && (
        <Grid
          component="label"
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          className={classes.handleLock}
        >
          <Grid item>Unlocked</Grid>
          <Grid item>
            <Switch
              checked={isLockedUser}
              onChange={() => setIsLockedUser(!isLockedUser)}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>
          <Grid item>Locked</Grid>
        </Grid>
      )}
      <TextField
        classes={{ root: classes.modalTextField }}
        id="firstName"
        size="small"
        value={userRecord.firstName}
        variant="outlined"
        label="First Name"
        onChange={(e) => setUserRecord({ ...userRecord, firstName: e.target.value })}
      />
      <TextField
        classes={{ root: classes.modalTextField }}
        id="lastName"
        size="small"
        value={userRecord.lastName}
        variant="outlined"
        label="Last Name"
        onChange={(e) => setUserRecord({ ...userRecord, lastName: e.target.value })}
      />
      <TextField
        classes={{ root: classes.modalTextField }}
        id="username"
        size="small"
        value={userRecord.username}
        name="username"
        variant="outlined"
        label="Username *"
        onChange={(e) => setUserRecord({ ...userRecord, username: e.target.value })}
        error={error.username}
        helperText={error.username && errorText}
        inputProps={{
          "data-testid": "usernameInput",
        }}
      />
      <TextField
        classes={{ root: classes.modalTextField }}
        id="cellPhone"
        size="small"
        variant="outlined"
        label="Cell Phone"
        value={userRecord.cellPhone}
        placeholder="##########"
        helperText="Phone number must be 10 digit"
        onChange={(e) => handleCellPhoneOnChange(e)}
      />
      <FormControl variant="outlined" size="small" style={{ width: "100%", marginBottom: "16px" }}>
        <InputLabel>User Type *</InputLabel>
        <Select
          variant="standard"
          value={userRecord.userType}
          onChange={(e) => setUserRecord({ ...userRecord, userType: e.target.value as string })}
          label="User Type *"
          error={error.userType}
          data-testid="userType"
        >
          {userTypeRefList.map((role) => {
            return (
              <MenuItem key={role.id} value={role.role_name}>
                {role.role_name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText error>{error.userStatus && errorText}</FormHelperText>
      </FormControl>
      <FormControl variant="outlined" size="small" style={{ width: "100%", marginBottom: "16px" }}>
        <InputLabel>User Status *</InputLabel>
        <Select
          variant="standard"
          label="User Status *"
          value={userRecord.userStatus}
          onChange={(e) => setUserRecord({ ...userRecord, userStatus: e.target.value as string })}
          error={error.userType}
          data-testid="userStatus"
        >
          <MenuItem value="initiated_registration">Initiated Registration</MenuItem>
          <MenuItem value="fully_registered">Fully Registered</MenuItem>
          <MenuItem value="user_lite">User Lite</MenuItem>
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="disabled">Disabled</MenuItem>
        </Select>
        <FormHelperText error>{error.userType && errorText}</FormHelperText>
      </FormControl>
      <AHSEmailInput
        classes={{ root: classes.modalTextField }}
        id="email"
        size="small"
        value={userRecord.email}
        label="Email Address"
        helperText="Please enter the email without the dominan name. Ex: john.doe"
        setEmail={(email) => handleEmailChange(email)}
        showFixedSuffix
        required
        error={emailIncorrect}
      />
      <TextField
        classes={{ root: classes.modalTextField }}
        id="externalID"
        size="small"
        label="External ID"
        variant="outlined"
        value={userRecord.externalId}
        onChange={(e) => handleChangeExternalId(e)}
      />
      <TextField
        classes={{ root: classes.modalTextField }}
        id="emrStaffId"
        size="small"
        value={userRecord.emrStaffId}
        label="Emr Staff Id"
        variant="outlined"
        onChange={(e) => setUserRecord({ ...userRecord, emrStaffId: e.target.value })}
      />
      <TextField
        classes={{ root: classes.modalTextField }}
        id="emrStaffUsername"
        size="small"
        value={userRecord.emrStaffUsername}
        label="Emr Staff Username"
        variant="outlined"
        onChange={(e) => setUserRecord({ ...userRecord, emrStaffUsername: e.target.value })}
      />
      <Typography variant="body2">* This fields are reqiured</Typography>
      <Box className={classes.saveButtonBox}>
        <Button
          autoFocus
          onClick={() => onSaveClicked(true)}
          color="primary"
          variant="contained"
          disabled={emailIncorrect}
        >
          Save
        </Button>
      </Box>
    </>
  );
};
