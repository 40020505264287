/** @format */
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import qString from "query-string";
import { axiosClient } from "util/api_helper";

export const useClientAuthorize = () => {
  const location = useLocation();
  const { name } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [error, setError] = useState(null);
  const query = qString.parse(location.search);

  useEffect(() => {
    if (!query.client_id && !name) {
      setError({
        message:
          "Unauthorized Request, partner app did not pass required informattion.  Please return to original app and try launching again.",
      });
    } else {
      if (query.clientId && query.clientId.length > 0) {
        setClientId(query.client_id);
      }
      if (name && name.length > 0) {
        setClientName(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadClient = async () => {
      try {
        setisLoading(true);
        const { data } = await axiosClient.get("/api/s/start/oauth/authorize", {
          params: { client_id: clientId, name: clientName },
        });
        if (data?.message?.includes("Un-authorize")) {
          throw new Error(data.message);
        }
        setResponse(data);
        setisLoading(false);
      } catch (err) {
        setisLoading(false);
        console.log(`oauth errror ${err}`);
        setError({
          message:
            "Unauthorized Request, problem processing app login.  Please return to partner app and try launching again.",
        });
      }
    };
    if ((clientId && clientId.length > 0) || (clientName && clientName.length > 0)) {
      loadClient();
    }
  }, [clientId, clientName]);
  return { error, isLoading, response, clientId };
};
