import React, { createContext, useMemo, useState } from "react";

type GenericEnhancedTableContextType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSourceData: React.Dispatch<any[]>;
  selected: number[];
  setSelected: React.Dispatch<number[]>;
};

export const GenericEnhancedTableContext = createContext<GenericEnhancedTableContextType>(null!);

export const GenericEnhancedTableProvider = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sourceData, setSourceData] = useState<any[]>([]);
  const [selected, setSelected] = useState<number[]>([]);

  const value = useMemo(
    () => ({
      sourceData,
      setSourceData,
      selected,
      setSelected,
    }),
    [sourceData, selected],
  );

  return (
    <GenericEnhancedTableContext.Provider value={value}>
      {children}
    </GenericEnhancedTableContext.Provider>
  );
};
