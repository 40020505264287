import React from "react";
import { Box } from "@mui/material";
import VideoVisitInvite from "components/shared/SendInviteDialog/VideoVisitInvite";
import { postRNGoBack } from "util/RNWebView";

const VirtualVisitInvite = () => {
  const params = new URLSearchParams(window.location.search);
  const cellPhone = params.get("cell_phone") || undefined;

  return (
    <Box padding="16px">
      <VideoVisitInvite
        defaultCellPhone={cellPhone}
        handleClose={postRNGoBack}
        isVirtualVisitCreated={!cellPhone}
      />
    </Box>
  );
};

export default VirtualVisitInvite;
