import React from "react";
import {
  AssignmentInd,
  CloudDone,
  CloudOff,
  LocalHospital,
  MailOutline,
  Phone,
  Sms,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  lighten,
} from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { SpecimenSelectOptions } from "views/SpecimenTracking/components/SelectOptions";

const useToolbarStyles = makeStyles()((theme) => ({
  root: {
    padding: 0,
    margin: "24px 0 8px 0",
    maxHeight: "46px",
  },
  highlight: {
    color:
      theme.palette.mode === "light" ? theme.palette.secondary.main : theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === "light"
        ? lighten(theme.palette.secondary.light, 0.85)
        : theme.palette.secondary.dark,
  },
  title: {
    flex: "1 1 100%",
  },
  titleButtons: {
    gap: ".5em",
  },
  titleSelects: {
    gap: ".5em",
  },
  titleButtons_wrapper: {
    display: "flex",
    gap: "8px",
  },
}));

export const TOOLBAR_BUTTON_NAMES = {
  assignInTransit: "Assign In Transit",
  assignPhoneOnly: "Assign Phone Only",
  markHasVaccine: "Mark Has Vaccine",
  sendSMS: "Send SMS",
  sendEmail: "Send Email",
  uploadToEpic: "Upload to Epic",
  notUploadToEpic: "Not Uploaded to Epic",
};

export const TOOLBAR_ITEM_TYPE = {
  icon: "icon",
  button: "button",
};

const defaultButtons = Object.values(TOOLBAR_BUTTON_NAMES).map((name) => ({
  name,
  type: TOOLBAR_ITEM_TYPE.icon,
}));

export const EnhancedTableToolbar = ({
  tableTitle,
  selected,
  selectedData,
  setSelected,
  setSelectedData,
  buttons = defaultButtons,
  titleButtons,
  titleSelects,
  dynamicContent,
}) => {
  const { classes, cx } = useToolbarStyles();
  const icons = {
    [TOOLBAR_BUTTON_NAMES.assignInTransit]: <AssignmentInd />,
    [TOOLBAR_BUTTON_NAMES.assignPhoneOnly]: <Phone />,
    [TOOLBAR_BUTTON_NAMES.markHasVaccine]: <LocalHospital />,
    [TOOLBAR_BUTTON_NAMES.sendSMS]: <Sms />,
    [TOOLBAR_BUTTON_NAMES.sendEmail]: <MailOutline />,
    [TOOLBAR_BUTTON_NAMES.uploadToEpic]: <CloudDone />,
    [TOOLBAR_BUTTON_NAMES.notUploadToEpic]: <CloudOff />,
  };

  const isMultipleSelected = selected.length > 1;

  const clearSelectedCheckBoxes = () => {
    setSelected([]);
    setSelectedData([]);
  };

  return (
    <Toolbar
      className={cx(classes.root, {
        [classes.highlight]: !!selected.length,
      })}
    >
      {selected.length ? (
        <Typography className={classes.title} color="inherit" variant="body1" align="left">
          {selected.length} items selected
        </Typography>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: "100%", gap: "16px" }}
        >
          {titleSelects && (
            <Box>
              {titleSelects.map(({ id, labelId, placeholder, value, data, onChange }) => (
                <SpecimenSelectOptions
                  key={id}
                  id={id}
                  labelId={labelId}
                  placeholder={placeholder}
                  value={value}
                  data={data}
                  onChange={onChange}
                />
              ))}
            </Box>
          )}
          {dynamicContent}
          <Box style={{ width: "100%", marginLeft: "auto" }}>
            {tableTitle && (
              <Grid item xs={12} sm={titleButtons ? 11 : 12}>
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                  {tableTitle}
                </Typography>
              </Grid>
            )}
            <Box display="flex" justifyContent="flex-end" style={{ gap: "8px" }}>
              {titleButtons?.map(({ name, icon, dataCy, action, className }) => (
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={icon ?? ""}
                  data-cy={dataCy}
                  onClick={() => {
                    action();
                  }}
                  key={name}
                  size="small"
                  className={className}
                  style={{ bottom: "0px" }}
                >
                  {name}
                </Button>
              ))}
            </Box>
          </Box>
        </Box>
      )}

      {!!selected.length &&
        buttons.map(
          ({
            name,
            action,
            type,
            className,
            dataCy,
            actionData,
            disableHandler,
            isDisableMultipleCheck = false,
          }) => (
            <React.Fragment key={name}>
              {type === TOOLBAR_ITEM_TYPE.icon && (
                <Tooltip title={name}>
                  <IconButton
                    disabled={
                      (isDisableMultipleCheck && isMultipleSelected) ||
                      disableHandler?.(selectedData)
                    }
                    aria-label={name}
                    color="secondary"
                    data-cy={dataCy}
                    onClick={() => {
                      if (action) {
                        action(selected);
                        clearSelectedCheckBoxes();
                      }
                      if (actionData) {
                        actionData(selectedData, clearSelectedCheckBoxes);
                      }
                    }}
                    size="large"
                  >
                    {icons[name]}
                  </IconButton>
                </Tooltip>
              )}
              {type === TOOLBAR_ITEM_TYPE.button && (
                <Button
                  disabled={
                    (isDisableMultipleCheck && isMultipleSelected) || disableHandler?.(selectedData)
                  }
                  aria-label={name}
                  color="secondary"
                  size="large"
                  data-cy={dataCy}
                  onClick={() => {
                    if (action) {
                      action(selected);
                      clearSelectedCheckBoxes();
                    }
                    if (actionData) {
                      actionData(selectedData, clearSelectedCheckBoxes);
                    }
                  }}
                  className={className}
                >
                  {name}
                </Button>
              )}
            </React.Fragment>
          ),
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  tableTitle: PropTypes.string,
  selected: PropTypes.array,
  selectedData: PropTypes.array,
  buttons: PropTypes.array,
  titleButtons: PropTypes.array,
  titleSelects: PropTypes.array,
  setSelected: PropTypes.func,
  setSelectedData: PropTypes.func,
};
