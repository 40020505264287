import React, { useEffect, useState } from "react";
import { camelCase, upperFirst } from "lodash";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

export type Tag = {
  id: number;
  name: string;
};

type Props = {
  checkedTags: Tag[];
  setCheckedTags: (tags: Tag[]) => void;
};

const Tags: React.FC<Props> = ({ checkedTags, setCheckedTags }) => {
  const app = useApp();
  const currentVirtualVisit = app?.vs_visits_state?.currentVirtualVisit;
  const vsId = currentVirtualVisit?.id;

  const [possibleTags, setPossibleTags] = useState<Tag[]>([]);

  useEffect(() => {
    const loadPossibleTags = async () => {
      try {
        const { data } = await axiosClient.get(
          `/api/s/console/virtualvisit/sessions/${vsId}/possible-tags`,
        );
        setPossibleTags(data);
      } catch (e) {
        app.addError("Problem Fetching Tags");
      }
    };
    if (vsId) {
      loadPossibleTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  useEffect(() => {
    const loadTags = async () => {
      try {
        const { data } = await axiosClient.get(`/api/s/console/virtualvisit/sessions/${vsId}/tags`);
        setCheckedTags(data);
      } catch (e) {
        app.addError("Problem Fetching Tags");
      }
    };
    if (vsId) {
      loadTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checkedTag: Tag) => {
    const { checked } = e.target;
    if (checked) {
      setCheckedTags([...checkedTags, checkedTag]);
    } else {
      setCheckedTags(checkedTags.filter(({ id }) => id !== checkedTag.id));
    }
  };

  return (
    <Grid container>
      {possibleTags.map((tag) => (
        <Grid item xs={12} md={6} key={tag.name}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedTags.some(({ id }) => id === tag.id)}
                  onChange={(e) => handleChange(e, tag)}
                  color="primary"
                  name="tags"
                  data-cy={`tagCheckbox${upperFirst(camelCase(tag.name))}`}
                />
              }
              label={tag.name}
            />
          </FormGroup>
        </Grid>
      ))}
    </Grid>
  );
};

export default Tags;
