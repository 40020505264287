import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  appCard: {
    marginTop: "30px",
    marginBottom: "16px",
    borderRadius: "10px",
    padding: "20px",
  },
  img: {
    borderRadius: "5px",
  },
  title: {
    marginBottom: "25px",
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  desc: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  descNotes: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "200px",
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  telehealth: {
    marginTop: "10px",
    backgroundColor: "rgba(224, 224, 224, 1)",
  },
  healthButtonText: {
    fontSize: "13px",
    lineHeight: "18px",
    color: "rgba(0, 0, 0, 0.54)",
    marginLeft: 8,
  },
  dateText: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
  },

  datePicker: {
    backgroundColor: "rgba(236, 236, 236, 1)",
    padding: "15px",
    marginBottom: "10px",
  },
  timeText: {
    fontSize: "13px",
    lineHeight: "18px",
    fontWeight: 400,
    color: theme.palette.common.white,
  },
  timeButton: {
    backgroundColor: theme.palette.primary.main,
    marginRight: "5px",
    marginLeft: "0px",
    marginTop: "10px",
    width: "70px",
  },
  text: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    marginBottom: "10px",
    marginLeft: "16px",
  },
  titleDescription: {
    marginBottom: "15px",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 600,
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  education: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  educationType: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 300,
    color: theme.palette.grey[600],
    marginBottom: "15px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonRequest: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 100,
    marginTop: "15px",
  },
  modalContainer: {
    marginRight: "30%",
    marginLeft: "30%",
    margin: "120px",
    overflow: "auto",
    borderRadius: "10px",
    border: "none",
    [theme.breakpoints.down("md")]: {
      marginRight: "5%",
      marginLeft: "5%",
      margin: "10px",
      overflow: "auto",
    },
  },
  modal: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.common.black}`,
    padding: "20px",
  },
  card: {
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "15px",
    },
  },
  cardContainer: {
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  bookingButtonOnline: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginTop: "10px",
  },
  bookingButtonPhone: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderWidth: "2px",
    borderColor: theme.palette.common.black,
    marginTop: "10px",
  },
  icon: {
    width: "40px",
    height: "40px",
    marginBottom: "10px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  modalTitle: {
    fontSize: "22px",
    width: "90%",
  },
}));

export default useStyles;
