import React, { Component } from "react";
import ErrorModal from "./ErrorModal";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    window.history.back();
    return { hasError: true };
  }

  handleClose() {
    this.setState({ hasError: false });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <ErrorModal isShown={hasError} goBack={this.handleClose} />;
    }
    return children;
  }
}

export default ErrorBoundary;
