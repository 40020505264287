import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import useStyles from "./TeamMembers.styles";

const ConfirmationsDialog = ({ isOpen, handleClose, removeTeamMember, id }) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(isOpen);

  useEffect(() => setOpen(isOpen), [isOpen]);

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialogContainer}>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          Remove this team member?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => removeTeamMember(id)} color="primary" variant="contained">
          YES
        </Button>
        <Button onClick={() => handleClose()} variant="contained">
          NO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationsDialog;
