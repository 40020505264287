/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Hidden, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import SendInviteDialog from "../../../shared/SendInviteDialog/SendInviteDialog";
import AddUser from "../../icons/AddUser";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleChatButton from "../Buttons/ToggleChatButton/ToggleChatButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import Menu from "./Menu/Menu";
import { makeStyles } from "tss-react/mui";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import ToggleCaptionButton from "components/Video/components/Buttons/ToggleCaptionButton/ToggleCaptionButton";
import { Features } from "util/constants/config";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useGetIsConferenceCall } from "hooks/useGetIsConferenceCall";
import { useAuth } from "../../../../util/Security";
import { UserTypes, axiosClient } from "../../../../util/api_helper";
import { useIsMobile } from "../../../../util/deviceUtils";
import { GetCurrentProgram } from "../../../../util/programsHelpers";
import { useApp } from "util/AppContext";
import { hasFeature } from "util/hasFeature";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    left: 0,
    right: 0,
    height: "72px",
    maxHeight: "72px",
    position: "fixed",
    padding: "0 1.43em",
    zIndex: 10,
    borderTop: "1px solid rgba(0,0,0,0.12)",
    [theme.breakpoints.down("md")]: {
      height: "72px",
      padding: 0,
      paddingLeft: "16px",
    },
  },
  screenShareBanner: {
    position: "fixed",
    zIndex: 8,
    bottom: "72px",
    left: 0,
    right: 0,
    height: "104px",
    background: "rgba(0, 0, 0, 0.5)",
    "& h6": {
      color: theme.palette.common.white,
    },
    "& button": {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: "2px solid",
      margin: "0 2em",
      "&:hover": {
        color: "#600101",
        border: "2px solid #600101",
        background: "#FFE9E7",
      },
    },
  },
  hideMobile: {
    display: "initial",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  disconnectButton: {
    padding: "0px",
    float: "right",
  },
  videoButtonsContainer: {
    display: "flex",
  },
  menuBtn: {
    maxWidth: "40px",
  },
  textMobile: {
    display: "initial",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const ADHOC_PROGRAM_TYPE = "adhoc_virtual";

export default function MenuBar() {
  const auth = useAuth();
  const { vs_visits_state } = useApp();
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const { virtual_session_id } = useParams<{ virtual_session_id: string }>();
  const userId = vs_visits_state.currentVirtualVisit?.patient_id;

  const currentProgram = GetCurrentProgram();

  const participants = useParticipants();
  const roomState = useRoomState();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();

  const isReconnecting = roomState === "reconnecting";

  const { isConferenceCall } = useGetIsConferenceCall();
  const isPatient =
    auth?.user.userType === UserTypes.PATIENT || auth?.user.userType === UserTypes.CONSUMER;

  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const isClosedCaptionsEnabled = hasFeature(Features.CLOSED_CAPTIONS);

  const handleCheckIfInvited = async () => {
    try {
      const { data } = await axiosClient.get(
        `/api/s/virtualvisit/sessions/${virtual_session_id}/users/${userId}/invite-status`,
      );
      if (data.invited) {
        setShowInviteModal(false);
        return;
      }
      const shouldInviteModalPopUp =
        !participants.length &&
        !isConferenceCall &&
        currentProgram?.program_type === ADHOC_PROGRAM_TYPE &&
        !isPatient;
      setShowInviteModal(shouldInviteModalPopUp);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (virtual_session_id && userId) {
      handleCheckIfInvited();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtual_session_id, userId]);

  return (
    <>
      <SendInviteDialog
        open={showInviteModal}
        handleClose={() => setShowInviteModal(false)}
        isVirtualVisitCreated
      />
      {isSharingScreen && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.screenShareBanner}
        >
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <div className={classes.videoButtonsContainer}>
          <ToggleAudioButton
            disabled={isReconnecting}
            className={classes.menuBtn}
            textMobile={classes.textMobile}
          />
          <ToggleVideoButton disabled={isReconnecting} textMobile={classes.textMobile} />

          {isClosedCaptionsEnabled && <ToggleCaptionButton textMobile={classes.textMobile} />}

          {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
          {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" && (
            <ToggleChatButton textMobile={classes.textMobile} />
          )}

          <Button
            className={classes.disconnectButton}
            startIcon={<AddUser />}
            onClick={() => setShowInviteModal(true)}
          >
            <span className={classes.textMobile}>Invite</span>
          </Button>
        </div>

        <Hidden mdDown>
          <div>
            <Menu />
            <EndCallButton />
          </div>
        </Hidden>
      </footer>
    </>
  );
}
