import React from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import UploadIcon from "@mui/icons-material/CloudUpload";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Dropzone = (props: DropzoneOptions) => {
  const { acceptedFiles, fileRejections, getInputProps, getRootProps, isDragActive } =
    useDropzone(props);

  const copy = (() => {
    switch (true) {
      case acceptedFiles.length > 0 && isDragActive:
        return "Drop files here to replace";
      case isDragActive:
        return "Drop files here";
      case acceptedFiles.length > 1:
        return `Click or drag-and-drop to replace ${acceptedFiles.length} selected files`;
      case acceptedFiles.length > 0:
        return `Click or drag-and-drop to replace 1 selected file`;
      default:
        return "Drag-and-drop files here, or click to select files";
    }
  })();

  return (
    <Paper
      {...getRootProps({
        elevation: isDragActive ? 0 : 3,
        sx: {
          padding: 3,
          width: "100%",
          minHeight: "4rem",
          ...(isDragActive
            ? { outline: "0.125rem dashed lightgray", outlineOffset: "-0.125rem" }
            : {}),
        },
        "data-cy": props?.["data-cy"] ?? "dropzone",
      })}
    >
      <input {...getInputProps()} />
      <Stack spacing={2} alignItems="center">
        <Typography variant="body1" gutterBottom>
          {copy}
        </Typography>
        {acceptedFiles.length > 0 ? (
          <>
            <Divider sx={{ width: "100%" }} />
            <Box component="div" sx={{ position: "relative" }}>
              <List sx={{ minWidth: "100%" }}>
                {acceptedFiles.map((file: File) => (
                  <ListItem key={file.size}>
                    <ListItemAvatar>
                      <Avatar>
                        <FileIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <Typography variant="subtitle1">{file.name}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        ) : (
          <UploadIcon color="primary" sx={{ fontSize: "2rem" }} />
        )}
        {fileRejections.length > 0 && (
          <Typography variant="body2" color="error">
            {fileRejections.length > 1
              ? `${fileRejections.length} files were rejected`
              : "1 file was rejected"}
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default Dropzone;
