import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import CustomizedDialogs from "./EditConfigModal";
import { makeStyles } from "tss-react/mui";
import OverwriteConfigModal from "views/Config/OverwriteConfigModal/OverwriteConfigModal";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
}));

export default function ConfigListView() {
  const { addError, addInfoMsg } = useApp();
  const { classes } = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [showOverwriteConfigModal, setShowOverwriteConfigModal] = useState(false);
  const [recordToBeEdit, setRecordToBeEdit] = useState(null);

  const colDefs = [
    {
      id: "orgId",
      numeric: false,
      disablePadding: true,
      label: "Org ID",
      searchable: false,
      sortable: true,
    },
    {
      id: "orgKey",
      numeric: false,
      disablePadding: true,
      label: "Org Key",
      searchable: false,
      sortable: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Config Name",
      searchable: true,
      sortable: true,
    },
  ];

  const handleEditRecord = (record) => {
    setShowModal(true);
    setRecordToBeEdit(record);
  };
  const closeModal = () => setShowModal(false);
  const onCloseEditRecordModal = () => setRecordToBeEdit(null);
  const onSaveEditRecordModal = async (updatedRecord) => {
    const postUrl = `/api/s/console/config/${updatedRecord.id}`;
    const updatedConfig = {
      orgId: updatedRecord.orgId,
      orgKey: updatedRecord.orgKey,
      name: updatedRecord.name,
      json: updatedRecord.json,
    };

    try {
      await axiosClient.patch(postUrl, updatedConfig);
      addInfoMsg("Record has been updated successfully.");
      closeModal();
    } catch (error) {
      console.log(error);
      if (error.response?.status === 400) {
        addError("Problem updating record, please check your config.");
      } else {
        addError("Problem updating record, please contact support.");
      }
    }
  };

  const handleOverwriteRecord = (record) => {
    setShowOverwriteConfigModal(true);
    setRecordToBeEdit(record);
  };

  const handleDeleteRecord = async (record) => {
    // eslint-disable-next-line no-alert
    if (!window.confirm("Are you sure do you want to delete this record ?")) {
      return false;
    }
    try {
      await axiosClient.delete(`/api/s/console/config/${record.id}`);
      addInfoMsg("Record has been deleted successfully.");
      closeModal();
    } catch {
      addError("Problem deleting record, please contact support.");
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        container
        className={classes.root}
        alignItems="center"
        justifyContent="center"
        data-testid="admin-config-table"
      >
        <Grid item xs={12}>
          <GenericEnhancedTable
            gridConfig={{ ...defaultGridConfig, allowOverwrite: true }}
            gridType="Available"
            tableTitle="Admin Config"
            colDefs={colDefs}
            url="/api/s/console/config"
            orderBy="id"
            order="desc"
            onEditHandler={handleEditRecord}
            onDeleteHandler={handleDeleteRecord}
            onOverwriteHandler={handleOverwriteRecord}
            refreshSourceData={!showModal}
          />
        </Grid>
        <CustomizedDialogs
          show={showModal}
          setShow={setShowModal}
          record={recordToBeEdit}
          onClose={onCloseEditRecordModal}
          onSave={onSaveEditRecordModal}
        />
        {!!recordToBeEdit && (
          <OverwriteConfigModal
            record={recordToBeEdit}
            open={showOverwriteConfigModal}
            setOpen={setShowOverwriteConfigModal}
          />
        )}
      </Grid>
    </form>
  );
}
