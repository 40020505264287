import { makeStyles } from "tss-react/mui";

export const useCreateSpecialistSession = makeStyles()(() => ({
  accordionContainer: {
    "& .MuiAccordion-root": {
      margin: "10px 0",
    },
  },
  surgeonDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline",
    paddingLeft: 10,
  },
  availableDoctorsTitle: {
    margin: "0 auto",
    textAlign: "left",
    fontWeight: 700,
    fontSize: 20,
    padding: "20px 0",
  },
  startConsultationButton: {
    width: "100%",
    margin: "15px 0",
  },
  stepLabel: {
    textAlign: "left",
  },
}));
