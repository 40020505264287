export const ADD_VIRTUAL_VISIT_TOKEN = "ADD_VIRTUAL_VISIT_TOKEN";
export const FETCH_VIRTUAL_VISITS = "FETCH_VIRTUAL_VISITS";
export const FETCH_VIRTUAL_VISITS_CONVERSATIONS = "FETCH_VIRTUAL_VISITS_CONVERSATIONS";
export const FETCH_NEW_VIRTUAL_VISITS = "FETCH_NEW_VIRTUAL_VISITS";
export const UPDATE_VIRTUAL_VISITS = "UPDATE_VIRTUAL_VISITS";
export const CONNECT_VIRTUAL_VISIT = "CONNECT_VIRTUAL_VISIT";
export const CLEAR_VIRTUAL_VISIT = "CLEAR_VIRTUAL_VISIT";
export const CONNECT_VIRTUAL_SESSION = "CONNECT_VIRTUAL_SESSION";
export const DISCONNECT_VIRTUAL_VISIT = "DISCONNECT_VIRTUAL_VISIT";
export const TOGGLE_WAITING_LIST = "TOGGLE_WAITING_LIST";
export const TOGGLE_PATIENT_PROFILE = "TOGGLE_PATIENT_PROFILE";
export const JOIN_PATIENT_SESSION = "JOIN_PATIENT_SESSION";
export const START_PATIENT_SESSION = "START_PATIENT_SESSION";
export const SELECT_CURRENT_PROFILE = "SELECT_CURRENT_PROFILE";
export const TOGGLE_QUEUE_TAB = "TOGGLE_QUEUE_TAB";
export const TOGGLE_WAITING_DIALOGUE = "TOGGLE_WAITING_DIALOGUE";
export const PARTICIPANT_JOINED = "PARTICIPANT_JOINED";
export const PARTICIPANT_LEFT = "PARTICIPANT_LEFT";
export const FETCH_PATIENT_VIRTUAL_SESSION = "FETCH_PATIENT_VIRTUAL_SESSION";
export const UPDATE_AND_SELECT_VIRTUAL_VISIT = "UPDATE_AND_SELECT_VIRTUAL_VISIT";
