import axios from "axios";
import { API_HOST } from "util/constants";

export const useAuthAppstoreLogin = () => {
  const tzFormatter = new Intl.DateTimeFormat("en-US");
  const timezone = tzFormatter.resolvedOptions().timeZone;

  const axiosClient = axios.create({
    baseURL: API_HOST.appApiTest,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-TIMEZONE": timezone,
    },
    withCredentials: true,
  });

  const directLogin = async (email, pwd, cb) => {
    try {
      const { data } = await axiosClient.post("/api/login", {
        email_or_username: email,
        password: pwd,
      });
      return cb(data, null);
    } catch (e) {
      return cb(null, e);
    }
  };

  return {
    directLogin,
  };
};
