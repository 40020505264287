import { DotFormStatus } from "./constants";

const dotFormInputOptions = [...Object.values(DotFormStatus)];

export const colDefs = [
  {
    id: "date",
    numeric: false,
    label: "Scheduled Appt. Date",
    searchable: true,
    sortable: true,
    isDate: true,
    customInput: {
      type: "date",
      disableFuture: false,
    },
  },
  {
    id: "formStatus",
    numeric: false,
    label: "Status",
    searchable: true,
    sortable: true,
    customInput: {
      type: "chipDropdown",
      options: dotFormInputOptions,
    },
    isCustomContent: true,
    func: (status = "") => status?.toLowerCase().replace(/^(.)/, (first) => first?.toUpperCase()),
    showTooltipOnError: true,
  },
  {
    id: "lastCommsSent",
    numeric: false,
    disablePadding: false,
    label: "Comms Sent",
    searchable: false,
    sortable: true,
  },
  {
    id: "mrn",
    numeric: false,
    label: "MRN",
    searchable: true,
    sortable: false,
  },
  {
    id: "lastName",
    numeric: false,
    label: "Last Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "firstName",
    numeric: false,
    label: "First Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "dob",
    numeric: false,
    isDate: true,
    label: "Date of Birth",
    searchable: true,
    sortable: true,
    customInput: {
      type: "date",
    },
  },
  {
    id: "locationName",
    numeric: false,
    label: "Location Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "cellphone",
    numeric: false,
    label: "Phone",
    searchable: true,
    sortable: false,
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
    searchable: true,
    sortable: true,
  },
];
