/* eslint-disable */
import React, { useState } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { LocalVideoTrack } from "twilio-video";
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from "util/constants";
import useDevices from "../../../hooks/useDevices/useDevices";
import useMediaStreamTrack from "../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useCustomStyles } from "./useCustomStyles";

export default function VideoInputListPreview() {
  const { classes } = useCustomStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find((track) => track.kind === "video") as
    | LocalVideoTrack
    | undefined;

  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    localStorage.getItem(SELECTED_VIDEO_INPUT_KEY),
  );
  const localVideoInputDeviceId =
    mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <div className={classes.containerDiv}>
      {videoInputDevices.length > 0 ? (
        <FormControl variant="standard" fullWidth>
          <div className={classes.labelTitle}>Video Input</div>
          <Select
            onChange={(e) => replaceTrack(e.target.value as string)}
            value={localVideoInputDeviceId || ""}
            variant="outlined"
          >
            {videoInputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <div className={classes.labelTitle}>Audio Input</div>
          <Typography>{localVideoTrack?.mediaStreamTrack.label || "No Local Video"}</Typography>
        </>
      )}
    </div>
  );
}
