/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Calender from "../Calender/Calender";
import { MessagingProvider } from "./context/messagingContext";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const useStyles = makeStyles()((theme) => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - ${theme.headerHeight})`,
    flexWrap: "nowrap",
    boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.14)",
    borderTop: "1px solid rgba(0,0,0,0.01)",
    justifyContent: "flex-start",
    paddingTop: 20,
  },
  successMessage: {
    color: "#1E4620",
    backgroundColor: "#EDF7ED",
    fontSize: "0.875rem",
    padding: 16,
  },
  errorMessage: {
    color: "#5f2120",
    backgroundColor: "#FDEDED",
    fontSize: "0.875rem",
    padding: 16,
  },
  formContainer: {
    maxWidth: 370,
    minWidth: 370,
    margin: "0 auto",
  },
  textField: {
    minWidth: 370,
    marginTop: 16,
    marginBottom: 16,
  },
}));

const FamilyMemberInviteLayout = () => {
  const { classes } = useStyles();
  const { addError } = useApp();
  const { search } = useLocation();
  // const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [dob, setDob] = useState(null);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [isShowForm, setIsShowForm] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const fn = params.get("fn");
    setFirstName(fn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dob !== null || lastName !== "") {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [dob, lastName]);

  const sendToken = async (search) => {
    const params = new URLSearchParams(search);
    const token = params.get("token");
    const uri = `/api/family-journal/accept-invite`;

    if (!token) {
      // setIsLoading(false);
      return addError(`Invalid token.`);
    }

    try {
      const { data } = await axiosClient.post(uri, {
        token,
        ...(dob && { dateOfBirth: moment(dob).format(moment.HTML5_FMT.DATE) }),
        ...(lastName && { lastName }),
      });
      // setIsLoading(false);
      if (data?.code === 400) {
        if (data?.message === "Attempt limit reached") {
          setIsSuccess(false);
          setIsShowForm(false);
        } else {
          addError(data?.message);
        }
      }
      if (data?.code === 500) {
        addError("Internal server error.");
      }
      setIsSuccess(true);
      setIsShowForm(false);
    } catch (error) {
      // setIsLoading(false);
      console.error(error);
    }
  };

  const SuccessMessage = () => {
    return (
      <div>
        <CheckIcon style={{ fill: "#1E4620", fontSize: "4rem" }} />
        <p className={classes.successMessage}>Thanks for enrolling.</p>
      </div>
    );
  };

  const ErrorMessage = () => {
    return (
      <div>
        <ErrorIcon style={{ fill: "#5f2120", fontSize: "4rem" }} />
        <p className={classes.errorMessage}>
          You have failed to correctly identify the individual who wished to include you in the
          session.
        </p>
      </div>
    );
  };

  return (
    <MessagingProvider>
      <Grid container spacing={0} className={classes.dashboard}>
        <Grid className={classes.formContainer} item xs={12}>
          {isShowForm ? (
            <>
              <h2>Family Journal Invite {firstName ? `for ${firstName}` : ""}</h2>
              <span>
                To confirm enrollment for updates please enter the patient's last name or date of
                birth.
                <br />
                You have 3 attempts.
              </span>
              <TextField
                className={classes.textField}
                label="Last Name"
                variant="outlined"
                color="primary"
                value={lastName}
                onInput={(e) => setLastName(e.target.value)}
                disabled={dob !== null}
              />
              <span>or</span>
              <Calender
                dateProp={dob}
                setDateProp={setDob}
                label="Date of Birth"
                isDisabled={lastName !== ""}
              />
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => sendToken(search)}
                className={classes.backgroundSecondary}
                disabled={!isReady}
              >
                Submit
              </Button>
            </>
          ) : (
            <>{isSuccess ? <SuccessMessage /> : <ErrorMessage />}</>
          )}
        </Grid>
      </Grid>
    </MessagingProvider>
  );
};

export default FamilyMemberInviteLayout;
