import React, { useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { defaultGridConfig } from "components/GenericEnhancedTable";
import { EnhancedTableHead } from "components/GenericEnhancedTable/components/EnhancedTableHead";
import { useStyles as useTableStyles } from "components/GenericEnhancedTable/styles";
import { useIsMobile } from "util/deviceUtils";
import { useModalStyles } from "./styles";

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const colDefs = [
  {
    id: "message",
    numeric: false,
    disablePadding: true,
    label: "Message",
    searchable: false,
    sortable: false,
  },
];

const DialogTitle = ({ id, children, onClose, ...other }) => {
  const { classes } = useStyles();
  return (
    <MuiDialogTitle id={id} disableTypography className={classes.root} {...other}>
      <b>{children}</b>
      {onClose && (
        <IconButton
          data-cy="closeModalButton"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export default function NotificationsModal({ notifications, handleClose }) {
  const { classes } = useModalStyles();
  const [open] = useState(true);
  const [page, setPage] = useState(0);
  const isMobile = useIsMobile();
  const [pageSize, setPageSize] = useState(isMobile ? 10 : 50);
  const { classes: tableClasses, cx } = useTableStyles();

  const closeModal = () => {
    handleClose();
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Did not use GenericEnhancedTable because of there is an issue when we use 2 tables in the same page
  // Also in the future we are removing this modal in favor of push notifications
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      classes={{ paperWidthMd: classes.modalDialog }}
      fullScreen={isMobile}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeModal}>
        Campaign Management Notifications
      </DialogTitle>
      <MuiDialogContent dividers classes={{ root: classes.formContainer }}>
        <div
          className={cx(tableClasses.root, {
            [tableClasses.rootMobile]: isMobile,
          })}
        >
          <TableContainer className={classes.tableContainerHeight}>
            <Table
              stickyHeader
              className={tableClasses.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="notifications table"
            >
              <EnhancedTableHead
                numSelected="0"
                order="id"
                orderBy="asc"
                rowCount={notifications.length}
                colDefs={colDefs}
                gridConfig={{
                  ...defaultGridConfig,
                  showActionColumn: false,
                  showSearchBar: false,
                  showTableToolbar: false,
                }}
                hideCheckboxes
              />
              <TableBody>
                {notifications.map(({ id, message }) => (
                  <TableRow key={id}>
                    <TableCell colSpan={colDefs.length}>
                      <span>{message}</span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {notifications.length > 10 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={notifications.length}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </MuiDialogContent>
    </Dialog>
  );
}
