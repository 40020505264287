/** @format */
import React, { useState } from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import CreateClient from "./CreateClient";
import LoginWithHealthCare from "./LoginWithHealthCare";

function Topic() {
  const [client, setClient] = useState({});
  const { topicId } = useParams();
  const comp = () => {
    if (topicId === "login") {
      return <LoginWithHealthCare client={client} />;
    }
    if (topicId === "create-client") {
      return <CreateClient client={client} setClient={setClient} />;
    }
  };
  return comp();
}

const Home = () => {
  const { pathname, url } = useLocation();
  return (
    <div style={{ margin: "20px", textAlign: "left" }}>
      <div style={{ display: "flex" }}>
        <p style={{ margin: "5px" }}>
          <Link to={`${url}/create-client`}>Create API Client</Link>
        </p>
      </div>
      <Routes>
        <Route path={`${pathname}/:topicId`} element={<Topic />} />
      </Routes>
    </div>
  );
};
export default Home;
