import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    backgroundColor: "#FFF",
    color: "white",
    padding: "0.5em 0.5em",
    gap: "0.5em",
    minHeight: "78px",
  },
  containerBackgroundBlue: {
    backgroundColor: "#171A5B",
    zIndex: 3,
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    background: "transparent",
    outline: "none",
    border: "none",
    color: "inherit",
    flex: "1",
    marginRight: "auto",

    "&:focus": {
      outline: "none",
    },
  },
  title: {
    flex: "2",
    color: "white",
    fontSize: "1.25em",
    fontWeight: 500,
  },
  viewForSpacing: {
    flex: "1",
    marginLeft: "auto",
    marginRight: "18px",
  },
  logo: {
    height: "2em",
  },
  mapButtonContainer: {
    flex: "1",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 0",
    margin: "0 18px 0 auto",
  },
  mapButtonText: {
    color: "white",
  },
  mapButtonIcon: {
    height: "1em",
  },
  homeLogoButton: {
    margin: "0",
    padding: "12px 16px",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    "&:focus": {
      outline: "none",
    },
  },
}));
