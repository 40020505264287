import { useEffect } from "react";
import { RECEIVE_MESSAGE } from "store/reducers/MobileDFD/actions";
import { SESSION_KEYS } from "util/constants/sessionsKeys";
import { useApp } from "util/AppContext";

export const useAppEvent = () => {
  const app = useApp();
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.type === "messageFromMobileDFD") {
        console.log(`event detail: ${JSON.stringify(event.detail)}`);
        try {
          if (event.detail?.msalToken) {
            sessionStorage.setItem(SESSION_KEYS.MSAL_TOKEN, event.detail.msalToken);
          }

          app.mobileDFD_data_dispatch({ type: RECEIVE_MESSAGE, payload: event.detail });
        } catch (error) {
          console.log(`error dispatching event detail: ${error}`);
        }
      }
    };
    window.addEventListener("messageFromMobileDFD", handleMessage);
    return () => {
      window.removeEventListener("messageFromMobileDFD", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
