import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format, isValid, parse } from "date-fns";
import { filterOptions } from "../../utils/filterOptions";
import { useIsMobile } from "util/deviceUtils";
import { useSpecimenMobileListStyles } from "../../styles";

type FilterProps = {
  searchBy: string;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void;
  clearFilters: () => void;
};

const SpecimenTrackingFilters = ({
  searchBy,
  searchValue,
  setSearchValue,
  setSearchBy,
  handleSearch,
  clearFilters,
}: FilterProps) => {
  const { classes } = useSpecimenMobileListStyles();
  const isMobile = useIsMobile();
  const searchInput = useRef<HTMLInputElement>(null);

  const currentDate = format(new Date(), "MM/dd/yyyy");
  const initialSearchValue = sessionStorage.getItem("searchValue") ?? currentDate;
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    parse(initialSearchValue, "MM/dd/yyyy", new Date()),
  );

  const setInputFocus = () => {
    if (searchInput?.current) {
      searchInput.current.focus();
    }
  };

  const handleChangeSearchBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBy(event.target.value);
    setSearchValue("");
    setInputFocus();
    sessionStorage.setItem("searchValue", "");
  };

  const handleClearFilters = () => {
    if (searchBy === "collectedDatetime") {
      setSelectedDate(null);
    }
    clearFilters();
  };

  const isDisabled = () => {
    if (searchBy === "collectedDatetime") {
      return !selectedDate || !searchBy || !searchValue || !isValid(selectedDate);
    }
    return !searchValue || !searchBy;
  };

  useEffect(() => {
    if (searchBy === "collectedDatetime" && selectedDate && isValid(selectedDate)) {
      const formattedDate = format(selectedDate, "MM/dd/yyyy");
      setSearchValue(formattedDate);
    }
  }, [selectedDate, searchBy, setSearchValue]);

  useEffect(() => {
    if (searchBy === "collectedDatetime" && !searchValue) {
      setSelectedDate(null);
    }
  }, [searchBy, searchValue]);

  return (
    <Grid container alignItems="center" className={classes.search} data-testid="specimenFilters">
      <FormControl variant="standard" component="fieldset">
        <FormLabel component="legend">Search By:</FormLabel>
        <RadioGroup row value={searchBy} onChange={handleChangeSearchBy}>
          {filterOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              data-cy={option.dataCy}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Grid
        item
        style={{ width: "100%", display: "flex", alignItems: "center", position: "relative" }}
      >
        {searchBy === "collectedDatetime" ? (
          <DatePicker
            label="Collected Date"
            format="MM/dd/yyyy"
            value={selectedDate}
            // @ts-ignore-next-line
            onChange={(date) => setSelectedDate(date)}
            data-testid="filterSpecimensDateInput"
            data-cy="filterSpecimensDateInput"
            sx={{ marginRight: "8px" }}
            disableFuture
            slotProps={{
              textField: {
                variant: "outlined",
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSearch();
                  }
                },
              },
              inputAdornment: {
                className: classes.datePickerAdornment,
              },
            }}
          />
        ) : (
          <TextField
            inputRef={searchInput}
            label="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            variant="outlined"
            size={isMobile ? "small" : "medium"}
            data-testid="filterSpecimensInput"
            data-cy="filterSpecimensInput"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
            style={{ marginRight: "8px" }}
            disabled={!searchBy}
          />
        )}
        <IconButton onClick={handleClearFilters} data-testid="clearFiltersBtn" size="large">
          <CancelIcon />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={isDisabled()}
          data-testid="submitSpecimenButton"
          style={{ marginRight: "4px", height: isMobile ? "40px" : "56px" }}
          size="medium"
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default SpecimenTrackingFilters;
