/** @format */
import React from "react";
import { useLocation } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import QrProofofVaccinations from "./QrProofofVaccinations";
import QString from "query-string";
import { makeStyles } from "tss-react/mui";
import Loader from "../../components/Loader";
import { useFetch } from "../../util/useFetch";
import { API_ENDPOINTS, SIMPLE_POST_OPTIONS } from "../../util/api_helper";
import Tick from "../../static/images/tick.png";

const useStykes = makeStyles()({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "99%",
  },
  verifyPaper: {
    minWidth: 350,
    maxWidth: 350,
    height: 200,
    margin: "0 auto",
    marginTop: 10,
  },
  error: {
    color: "red",
  },
  pov: {
    marginTop: 40,
  },
});

const QRVerification = () => {
  const { classes } = useStykes();
  const location = useLocation();
  const QueryString = QString.parse(location.search);
  const { error, response, isLoading } = useFetch(`/${API_ENDPOINTS.QR_code_Verificaiton}`, {
    ...SIMPLE_POST_OPTIONS,
    body: JSON.stringify({ credential: QueryString.credential }),
  });
  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      alignItems="center"
      justifyContent="center"
    >
      {!isLoading ? (
        !error ? (
          response && (
            <>
              <Grid item xs={11} md={8} lg={8}>
                <Paper className={classes.verifyPaper}>
                  <Typography variant="h5">{response.message}</Typography>
                  <img src={Tick} alt="tocl" />
                </Paper>
              </Grid>
              <Grid item xs={11} md={8} lg={8}>
                <QrProofofVaccinations vaccines={response.data?.immunizations} />
              </Grid>
            </>
          )
        ) : (
          <Grid item xs={11} md={8} lg={8}>
            <Typography variant="h5" className={classes.error}>
              {error?.message.includes("Unexpected") ? "Something Went Wrong" : error?.message}
            </Typography>
          </Grid>
        )
      ) : (
        <Loader />
      )}
    </Grid>
  );
};

export default QRVerification;
