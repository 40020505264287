import { useReducer } from "react";
import { REMOVE_LOCATION, SET_LOCATION } from "./actions";
import { SpecimenLocationInfo } from "views/SpecimenTracking/types";

function getFromStorage() {
  const location = localStorage.getItem("specimen-tracking-location");
  if (!location) {
    return undefined;
  }
  try {
    const parsed = JSON.parse(location);
    if (!parsed) {
      return undefined;
    }
    return parsed;
  } catch (e) {
    return undefined;
  }
}

interface InitialStateInterface {
  location: SpecimenLocationInfo | undefined;
}
const initialState: InitialStateInterface = {
  location: getFromStorage(),
};

const specimenTrackerReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_LOCATION:
      localStorage.setItem("specimen-tracking-location", JSON.stringify(payload));
      return { ...state, location: payload };

    case REMOVE_LOCATION:
      localStorage.removeItem("specimen-tracking-location");
      return { ...state, location: undefined };

    default:
      return { ...state };
  }
};

export const useSpecimenTrackingReducer = () => {
  return useReducer(specimenTrackerReducer, initialState);
};
