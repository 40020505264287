import moment from "moment";

const apiMockJson = {
  hrs_per_slot: 3,
  days_of_week: [1, 2, 3, 4, 5],
  start_hour: 9,
  end_hour: 18,
  bookings_per_slot: 2,
};

export const generateSechedule = ({ start_date }) => [
  moment(start_date).format(),
  moment(start_date).add(apiMockJson.hrs_per_slot, "hours").format(),
];
