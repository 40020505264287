import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";
import faqImage1 from "../../../../static/images/specimenTrackinghelpCenter/faq-1.png";
import faqImage2 from "../../../../static/images/specimenTrackinghelpCenter/faq-2.png";
import faqImage3 from "../../../../static/images/specimenTrackinghelpCenter/faq-3.png";
import faqImage4 from "../../../../static/images/specimenTrackinghelpCenter/faq-4.png";
import faqImage5 from "../../../../static/images/specimenTrackinghelpCenter/faq-5.png";
import faqImage6 from "../../../../static/images/specimenTrackinghelpCenter/faq-6.png";

const SpecimenTrackingHelpCenterView = () => {
  const navigate = useNavigate();
  const { classes } = useSpecimenStyles();
  const {
    web: { specimenTrackingHelpCenter },
  } = useTranslation();
  const {
    faq,
    issueButton: { title, buttonText },
  } = specimenTrackingHelpCenter;

  const faqData = [
    {
      title: faq[0].title,
      content: faq[0].content,
      images: [faqImage1],
    },
    {
      title: faq[1].title,
      content: faq[1].content,
      images: [faqImage2],
    },
    {
      title: faq[2].title,
      content: faq[2].content,
      images: [faqImage3, faqImage4],
    },
    {
      title: faq[3].title,
      content: faq[3].content,
      images: [faqImage5, faqImage6],
    },
  ];

  return (
    <main className={classes.helpCenter_wrapper}>
      <header>
        <IconButton onClick={() => navigate("/console/specimen-tracking")} data-testid="backButton">
          <ArrowBack />
        </IconButton>
        <Typography fontWeight={600} fontSize="18px" position="relative" top="6px">
          Help Center
        </Typography>
      </header>
      <div className={classes.helpCenter_accordionWrapper}>
        {faqData.map((item, index) => (
          <Accordion
            elevation={3}
            key={item.title}
            className={classes.helpCenter_accordion}
            data-testid={`accordion-${index}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <p style={{ margin: 0 }}>{item.title}</p>
            </AccordionSummary>
            <AccordionDetails
              className={classes.helpCenter_accordionDetails}
              data-testid={`accordionDetails-${index}`}
            >
              <p>{item.content[0]}</p>
              <img src={item.images[0]} alt="Please reload the page." />

              <p>{item.content[1]}</p>
              {item.images[1] && <img src={item.images[1]} alt="Please reload the page." />}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <Box>
        <Typography fontSize="16px" fontWeight="500" marginBottom="8px">
          {title}
        </Typography>
        <Button
          onClick={() => navigate("/console/specimen-tracking/report-an-issue")}
          variant="contained"
          fullWidth
          style={{ padding: "8px 0", fontSize: "16px", maxWidth: "450px" }}
          data-testid="reportIssueBtn"
        >
          {buttonText}
        </Button>
      </Box>
    </main>
  );
};

export default SpecimenTrackingHelpCenterView;
