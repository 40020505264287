import React, { useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { StepTitleToolbar } from "./Common";
import { StepsProps } from "../types/constants";
import useTranslation from "hooks/useTranslation";
import { useIsMobile } from "util/deviceUtils";
import useStyles from "../styles/forms.styles";

const RelationshipToDriverStep: React.FC<StepsProps> = ({
  handleChange,
  formData,
  showTitle = false,
  isFormDisabled = false,
  handleStepChange,
}) => {
  const { classes } = useStyles({
    alignLeft: showTitle,
  });

  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(isFormDisabled);
  const [isSaveMode, setIsSaveMode] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const {
    web: {
      dotForm: {
        patient: {
          personalInformation: {
            relationshipToDriver: { title, selectRelationshipLabel, relationships },
          },
        },
      },
    },
  } = useTranslation();

  const { driverRelation: data } = formData;

  const actionClickHandler = (isSaveMode: boolean) => {
    if (!isSaveMode) {
      setShouldDisableForm(false);
      setIsSaveMode(true);
    } else {
      setShouldDisableForm(true);
      setIsSaveMode(false);
      if (handleStepChange) {
        handleStepChange({ saveProgress: true });
      }
    }
  };

  const showStepTitle = () => {
    if (showTitle || isMobile) {
      return (
        <Typography align="center" className={classes.formTitle}>
          {title}
        </Typography>
      );
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      className={showTitle ? classes.relationship_wrapper_dashboard : classes.relationship_wrapper}
      spacing={3}
    >
      <Grid item container xs={12} md={12} style={{ padding: 0 }}>
        <Grid item xs={12} md={6}>
          {showStepTitle()}
        </Grid>
        {(shouldDisableForm || isSaveMode) && (
          <StepTitleToolbar
            classes={classes}
            actionClickHandler={actionClickHandler}
            isSaveMode={isSaveMode}
          />
        )}
      </Grid>
      <FormControl variant="outlined" style={{ width: "100%", marginTop: "20px" }}>
        <InputLabel id="relationship-label">{selectRelationshipLabel}</InputLabel>
        <Select
          variant="standard"
          data-testid="relationshipToDriver"
          labelId="relationship-label"
          label={selectRelationshipLabel}
          value={data ? (data as string).toUpperCase() : ""}
          disabled={shouldDisableForm}
          onChange={(e) => handleChange("driverRelation", e.target.value as string)}
        >
          {Object.entries(relationships).map(([key, value]) => (
            <MenuItem key={key} value={value} className={classes.capitalizeText}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default RelationshipToDriverStep;
