/* eslint-disable */
import React, { useEffect, useRef } from "react";
import Theme from "Theme";
import { Call } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import AvatarIcon from "../../icons/AvatarIcon";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import dialingSound from "./dialingSound/dialing-sound.wav";
import ahsBackground from "./simple-ahs-bg.jpg";
import { makeStyles } from "tss-react/mui";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";
import useIsRecording from "../../hooks/useIsRecording/useIsRecording";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import usePublications from "../../hooks/usePublications/usePublications";
import useScreenShareParticipant from "../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useTrack from "../../hooks/useTrack/useTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useGetIsConferenceCall } from "hooks/useGetIsConferenceCall";

const useStyles = makeStyles()((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  identity: {
    color: theme.palette.common.white,
    display: "inline-flex",
    "& svg": {
      marginLeft: "0.3em",
    },
    marginRight: "0.4em",
    alignItems: "center",
  },
  infoContainer: {
    position: "absolute",
    zIndex: 2,
    height: "100%",
    width: "100%",
  },
  reconnectingContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(40, 42, 43, 0.75)",
    zIndex: 1,
  },
  fullWidth: {
    gridArea: "1 / 1 / 2 / 3",
    [theme.breakpoints.down("md")]: {
      gridArea: "1 / 1 / 3 / 3",
    },
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `url(${ahsBackground}) top left/cover`,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    "& svg": {
      transform: "scale(2)",
    },
  },
  recordingIndicator: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.5)",
    color: theme.palette.common.white,
    padding: "0.1em 0.3em 0.1em 0",
    fontSize: "1.2rem",
    height: "28px",
    [theme.breakpoints.down("md")]: {
      bottom: "auto",
      right: 0,
      top: 0,
    },
  },
  identityContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
    padding: "12px 12px 12px 5px",
    fontSize: "18px",
    fontWeight: 500,
  },
  circle: {
    height: "12px",
    width: "12px",
    background: "red",
    borderRadius: "100%",
    margin: "0 0.6em",
    animation: `1.25s $pulsate ease-out infinite`,
  },
  "@keyframes pulsate": {
    "0%": {
      background: `#A90000`,
    },
    "50%": {
      background: "#f00",
    },
    "100%": {
      background: "#A90000",
    },
  },
  dialingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#2B2B2B",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    color: theme.palette.common.white,
  },
  dialingIcon: {
    fontSize: "36px",
  },
  dialingIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#5D5D5D",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  },
  dialingText: {
    fontSize: "20px",
    color: theme.palette.common.white,
  },
  dialingDot: {
    fontSize: "28px",
    opacity: 0,
    animation: "$showHideDot 2s ease-in-out infinite",

    "&:nth-child(1)": { animationDelay: "0.2s" },
    "&:nth-child(2)": { animationDelay: "0.4s" },
    "&:nth-child(3)": { animationDelay: "0.6s" },
  },
  "@keyframes showHideDot": {
    "0%": { opacity: 0 },
    "30%": { opacity: 1 },
    "60%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
  dialingIdentityBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
}));

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
  const { classes, cx } = useStyles();
  const { room } = useVideoContext();
  const { localParticipant } = room!;
  const isLocal = localParticipant === participant;
  const participants = room ? Array.from<Participant>(room?.participants.values()) : [];
  const audioRef = useRef<any>(null);

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);
  const videoPublication = publications.find((p) => p.trackName.includes("camera"));
  const screenSharePublication = publications.find((p) => p.trackName.includes("screen"));

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  );
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const isRecording = useIsRecording();

  const { isConferenceCall } = useGetIsConferenceCall();

  useEffect(() => {
    if (!participants.length) {
      audioRef?.current?.play();
    } else {
      audioRef?.current?.pause();
    }
  }, [participants.length]);

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={cx(classes.container, {
        [classes.fullWidth]: !isRemoteParticipantScreenSharing,
      })}
    >
      <div className={classes.infoContainer}>
        <div
          className={cx(classes.identityContainer, {
            [classes.dialingIdentityBackground]: isConferenceCall,
          })}
        >
          <div className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <div>
              {participant.identity}
              {isLocal && " (You)"}
              {screenSharePublication && " - Screen"}
            </div>
          </div>
          <NetworkQualityLevel participant={localParticipant} />
        </div>
        {isRecording && (
          <Tooltip
            title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
            placement="top"
          >
            <div className={classes.recordingIndicator}>
              <div className={classes.circle} />
              <Typography variant="body1" color="inherit" data-cy-recording-indicator>
                Recording
              </Typography>
            </div>
          </Tooltip>
        )}
      </div>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <>
          {isConferenceCall && !participants.length ? (
            <div className={classes.dialingContainer}>
              <div className={classes.dialingIconContainer}>
                <Call classes={{ root: classes.dialingIcon }} height="36px" />
              </div>
              <Typography variant="body1" classes={{ root: classes.dialingText }}>
                Dialing<span className={classes.dialingDot}>.</span>
                <span className={classes.dialingDot}>.</span>
                <span className={classes.dialingDot}>.</span>
              </Typography>
              <audio loop ref={audioRef}>
                <source src={dialingSound} type="audio/wav" />
              </audio>
            </div>
          ) : (
            <div className={classes.avatarContainer}>
              <AvatarIcon />
            </div>
          )}
        </>
      )}
      {isParticipantReconnecting && (
        <div className={classes.reconnectingContainer}>
          <Typography variant="body1" style={{ color: Theme.palette.common.white }}>
            Reconnecting...
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
}
