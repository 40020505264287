import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";
import { useApp } from "../util/AppContext";
import { useAuth } from "../util/Security";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function ChangePasswordView() {
  const { classes } = useStyles();
  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();
  const app = useApp();

  const handleChangePwdSubmit = async (event) => {
    event.preventDefault();
    await auth.changePwd(oldPwd, newPwd, (error) => {
      if (error) {
        app.addError(`Error: Signin attempt failed: ${error.message}`);
        return;
      }
      navigate("/", { replace: true });
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>Change Password:</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="oldPwd"
                label="Old Password"
                variant="outlined"
                value={oldPwd}
                type="password"
                onInput={(e) => setOldPwd(e.target.value)}
              />
              <br />
              <TextField
                id="newPwd"
                label="New Password"
                type="password"
                value={newPwd}
                onInput={(e) => setNewPwd(e.target.value)}
                variant="outlined"
              />
              <br />
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleChangePwdSubmit}
              >
                Change Password
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
