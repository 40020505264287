import { parse } from "date-fns";

export const days = [
  "weekdays",
  "weekends",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
export const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
export const weekends = ["saturday", "sunday"];

export const timePickers = [
  { name: "openTime", label: "Start Time" },
  { name: "closeTime", label: "End Time" },
  { name: "lunchStartTime", label: "Break Start" },
  { name: "lunchEndTime", label: "Break End" },
];

export const adjustedHours = [
  {
    adjustedHoursEffectiveStart: "2024-09-27",
    adjustedHoursMessaging: "Testing adjusted hours 1",
    adjustStart: "13:00",
    adjustEnd: "16:00",
    adjustClosed: true,
  },
];

export const parseTime = (timeString) => {
  if (!timeString) {
    return null;
  }
  return parse(timeString, "HH:mm:ss", new Date());
};
