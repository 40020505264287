import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "@formio/react";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { HttpStatusCode } from "util/HttpStatusCode";
import { axiosClient } from "util/api_helper";
import "../../Forms/FormEmbeddedView.css";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    backgroundColor: theme.palette.common.white,
    height: "100vh",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "50px",
    margin: "80px auto",
    minWidth: "500px",
    maxWidth: "900px",
    textAlign: "left",
    fontFamily: "Alegreya Sans",
    "& .formio-component-title": {
      marginBottom: "20px",
    },
    "& .form-group": {
      fontSize: "18px",
    },
    "& .btn-primary": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      fontWeight: 500,
      display: "block",
      "&:disabled": {
        display: "none",
      },
    },
    "& .radio-selected": {
      fontWeight: 600,
    },
    "& .formio-component-content > h4": {
      fontWeight: 400,
    },
    "& .formio-form button.btn.btn-primary.btn-wizard-nav-previous": {
      color: "#043070",
      backgroundColor: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
      minWidth: "95%",
      maxWidth: "95%",
      margin: "40px auto",
      "& .formio-component-title > h2": {
        fontSize: "24px",
      },
      "& .formio-component-content > h4": {
        fontSize: "20px",
      },
      "& .form-group": {
        fontSize: "16px",
      },
    },
  },
  patientInfoContainer: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    width: "100%",
    marginBottom: 30,
  },
  title: {
    fontSize: "24px",
    fontFamily: "unset",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
    lineHeight: "inherit",
  },
  noInfoTitle: {
    fontSize: "20px",
    fontFamily: "unset",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
    lineHeight: "inherit",
    marginBottom: 20,
  },
  info: {
    display: "flex",
    marginTop: 10,
    fontSize: "20px",
    marginBottom: 20,
  },
  bold: {
    fontWeight: 700,
    paddingRight: 5,
  },
}));

export default function WebsiteFormSubmissionView() {
  const { classes } = useStyles();
  const { id, token } = useParams();
  const [formData, setFormData] = useState(null);
  const [formContext, setFormContext] = useState(null);
  const app = useApp();
  const navigate = useNavigate();

  const loadForm = async () => {
    try {
      const getUrl = `/api/quick-access-survey/website-survey/${id}`;
      const body = { token };
      const { data } = await axiosClient.post(getUrl, body);
      setFormData(data?.form);
      setFormContext({ ...data?.context });
    } catch (error) {
      if (error.response.status === HttpStatusCode.CONFLICT) {
        app.addInfoMsg("Survey was already submitted");
        navigate(`/website-survey/form-submitted`);
      }
    }
  };

  const handleFormioSubmissionError = (error) => {
    if (error.response.status === HttpStatusCode.CONFLICT) {
      app.addInfoMsg("Survey was already submitted");
      navigate(`/website-survey/form-submitted`);
    } else {
      const errorDesc = error?.response?.data?.description;
      if (errorDesc) {
        app.addError(`Could not submit form: ${errorDesc}`);
      } else {
        console.log(error);
        app.addError("Could not submit form!");
      }
    }
  };

  const handleFormioSubmission = async (submission) => {
    try {
      const submitUrl = `/api/quick-access-survey/website-survey/${id}/submit`;
      const body = {
        token,
        submission: submission.data,
      };
      await axiosClient.post(submitUrl, body);
      app.addInfoMsg("Form was successfully submitted!");
      navigate("/website-survey/thankyou");
    } catch (error) {
      handleFormioSubmissionError(error);
    }
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    loadForm();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      {formData && (
        <Form
          disabled={false}
          onSubmit={handleFormioSubmission}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
          form={{
            display: "wizard",
            components: formData.components,
          }}
          options={{
            buttonSettings: {
              showCancel: false,
            },
            readOnly: false,
            evalContext: {
              token,
              context: formContext,
            },
          }}
        />
      )}
    </div>
  );
}
