import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { SpecimenConfirmPostData, SpecimenConfirmResponse } from "views/SpecimenTracking/types";

export const useConfirmSpecimen = () => {
  const confirmSpecimen = (data: SpecimenConfirmPostData) => {
    const resp = axiosClient.post<SpecimenConfirmResponse>(
      API_ENDPOINTS.specimenTrackingSpecimenConfirm,
      {
        ...data,
      },
    );
    return resp;
  };

  return {
    confirmSpecimen,
  };
};
