import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import CellPhoneDialoge from "./CellPhoneAdd";
import ReferralImg from "static/images/referralImg.jpg";
import { makeStyles } from "tss-react/mui";
import MediaCard from "components/shared/MediaCard";
import { Features, HOST_DOMAIN, epicUrl, virtualUrgentCareUrl } from "util/constants/config";
import { useQuery } from "../hooks/useQuery";
import { useAuth } from "../util/Security";
import { API_ENDPOINTS, axiosClient, getApiHost } from "../util/api_helper";
import { hasFeature } from "../util/hasFeature";
import ChatForumIcon from "static/images/ahs-chat.svg";
import DVC from "static/images/ahsIcons/dvc.svg";
import FINDADOC from "static/images/ahsIcons/find_doctor.svg";
import HEALTH from "static/images/ahsIcons/health-records.svg";
import REQUEST from "static/images/ahsIcons/request.svg";
import VUC from "static/images/ahsIcons/vuc.svg";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    maxWidth: "960px",
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  title: {
    padding: "0 16px",
    margin: "45px 0",
  },
}));

export default function ConciergeChat() {
  const auth = useAuth();
  const query = useQuery();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const enableFindADoctor = hasFeature(Features.FIND_A_DOCTOR);
  const enableDotForm = hasFeature(Features.ENABLE_DOT_FORM);
  const enableDigitalImplants = hasFeature(Features.ENABLE_DIGITAL_IMPLANTS);

  const [showheader, setShowheader] = useState(true);
  const [showOffer, setShowOffer] = useState(true);
  const [showVideo, setShowVideo] = useState(true);

  const [latestReferral, setLatestReferral] = useState(null);
  const [healthRecordSSOUrl, setHealthRecordSSOUrl] = useState(epicUrl);
  const [userPrograms, setUserPrograms] = useState(null);
  const [userForms, setUserForms] = useState(null);
  const [userSessions, setUserSessions] = useState(null);
  const [haveDigitalImplants, setHaveDigitalImplants] = useState(false);
  const [currentDotFormId, setCurrentDotFormId] = useState(null);

  const handleClick = () => {
    navigate("/inquiryrequest");
  };

  let findADocActionUrl = "/patient-selection?r=find-a-doctor";
  if (window.location.hostname === HOST_DOMAIN.myatlantic) {
    findADocActionUrl = "https://findadoctor.atlantichealth.org";
  }

  const loadPrograms = async () => {
    if (auth.user && auth.user.userId) {
      try {
        const { data } = await axiosClient.get("/api/s/programs");
        setUserPrograms(data.data);
      } catch (error) {
        console.log(`Could not load programs, error${error}`);
      }
    }
  };

  const loadVirtualSessions = async () => {
    if (auth.user && auth.user.userId) {
      try {
        const { data } = await axiosClient.get("/api/s/virtualvisit/load_sessions");
        setUserSessions(data.data);
      } catch (error) {
        console.log(`Could not load virtual sessions, error${error}`);
      }
    }
  };

  const loadformSubmissions = async () => {
    if (auth.user && auth.user.userId) {
      try {
        const { data } = await axiosClient.get("/api/s/form-submissions");
        setUserForms(data.data.records);
      } catch (error) {
        console.log(`Could not load user forms, error${error}`);
      }
    }
  };

  const loadReferrals = async () => {
    if (auth.user && auth.user.userId) {
      try {
        const { data } = await axiosClient.get("/api/s/referrals");
        if (data && data.data && data.data.length > 0) {
          setLatestReferral(data.data[0]);
        }
      } catch (error) {
        console.log(`Could not load referrals, error${error}`);
      }
    }
  };

  const loadDigitalImplants = async () => {
    if (auth.user && auth.user.userId) {
      try {
        const { data } = await axiosClient.get("/api/s/digital-implant");
        if (data && data.length > 0) {
          setHaveDigitalImplants(data.some((d) => d.DeviceID));
        }
      } catch (error) {
        console.log(`Could not load Digital Implants, error${error}`);
      }
    }
  };

  const loadLastestDotForm = async () => {
    if (auth?.user?.userId) {
      try {
        const { data } = await axiosClient.get(API_ENDPOINTS.dotGetPatientFormId);
        if (data?.dotFormId) {
          setCurrentDotFormId(data.dotFormId);
        }
      } catch (error) {
        console.log(`Could not load Digital Implants, error${error}`);
      }
    }
  };

  useEffect(() => {
    const sh = query.get("showheader");
    if (sh === "false") {
      setShowheader(false);
    }
    const so = query.get("showoffer");
    if (so === "false") {
      setShowOffer(false);
    }

    const sv = query.get("showvideo");
    if (sv === "false") {
      setShowVideo(false);
    }

    //  let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (auth.user?.mrn) {
      const apiHost = getApiHost();
      setHealthRecordSSOUrl(`${apiHost}/samlsso/idp/init-unsolicited-response`);
    }

    loadPrograms();
    loadVirtualSessions();
    loadReferrals();
    loadformSubmissions();
    loadDigitalImplants();
    if (enableDotForm) {
      loadLastestDotForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCardClick = (intent) => {
    if (intent === "Digital Vaccine Card") {
      navigate("/patient-selection?r=vaccinecard");
      return;
    }
    if (intent === "Virtual Urgent Care") {
      window.location.href = virtualUrgentCareUrl;
      return;
    }
    if (intent === "Find A Doctor") {
      navigate("/patient-selection?r=find-a-doctor");
    }
  };

  return (
    <div className={classes.root} data-testid="ConciergeChat">
      <CellPhoneDialoge />
      {showheader && (
        <Typography
          variant="h4"
          align="left"
          className={classes.title}
          data-testid="Header"
          data-cy="consumer-menu-title"
        >
          Get extraordinary care today
        </Typography>
      )}
      <Grid container spacing={4} className={classes.root}>
        {userForms &&
          userForms.map((formSubmission) => {
            const actinoUrl = `/form/${formSubmission.id}`;

            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                key={`form${formSubmission.id}`}
                data-testid="Survey"
              >
                <MediaCard
                  title={formSubmission.program_stage_name}
                  imgUrl={formSubmission.image_url}
                  desc="Survey"
                  msg="Survey"
                  actionUrl={actinoUrl}
                />
              </Grid>
            );
          })}
        {userSessions && userSessions.length > 0 && (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            key={`vs${userSessions[0].vs.id}`}
            data-testid="Chat"
          >
            <MediaCard
              title={
                userSessions.length > 1
                  ? "Continue your Conversation"
                  : userSessions[0].program.name
              }
              imgUrl={ChatForumIcon}
              desc="Chat with our care teams."
              msg={userSessions[0].program.name}
              actionUrl="/conversation"
            />
          </Grid>
        )}

        {userPrograms &&
          userPrograms.map((program) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                key={`program${program.name}`}
                data-testid="Programs"
              >
                <MediaCard
                  title={program.name}
                  imgUrl={program.image_url}
                  desc={program.consumer_desc}
                  actionUrl={
                    program.app_url && program.app_url.length > 0
                      ? program.app_url
                      : `/carejourney/${program.id}`
                  }
                />
              </Grid>
            );
          })}
        {showVideo && (
          <Grid item xs={12} sm={6} md={6} lg={4} data-testid="VirtualUrgentCare">
            <MediaCard
              title="Virtual Urgent Care"
              imgUrl={VUC}
              desc="On demand video visit with a physician."
              msg="Virtual Urgent Care"
              handleCardClick={handleCardClick}
            />
          </Grid>
        )}
        {latestReferral && latestReferral.action_url && (
          <Grid item xs={12} sm={6} md={6} lg={4} data-testid="Referral">
            <MediaCard
              title="Referral"
              imgUrl={ReferralImg}
              desc={`You have an outstanding referral for ${latestReferral.specialist}`}
              msg="Referral"
              actionUrl={latestReferral.action_url}
              handleCardClick={handleCardClick}
            />
          </Grid>
        )}

        {enableFindADoctor && (
          <Grid item xs={12} sm={6} md={6} lg={4} data-testid="FindADoctor">
            <MediaCard
              title="Find A Doctor"
              imgUrl={FINDADOC}
              desc="Find specific care for your needs"
              msg="Find A Doctor"
              actionUrl={findADocActionUrl}
              handleCardClick={handleCardClick}
            />
          </Grid>
        )}

        {showOffer && (
          <Grid item xs={12} sm={6} md={6} lg={4} data-testid="DigitalVaccineCard">
            <MediaCard
              title="Digital Vaccine Card"
              imgUrl={DVC}
              desc="Access your COVID-19 vaccine records and easily share them."
              msg="Digital Vaccine Card"
              handleCardClick={handleCardClick}
            />
          </Grid>
        )}
        {auth.user && auth.user.userId && (
          <Grid item xs={12} sm={6} md={6} lg={4} data-testid="HealthRecord">
            <MediaCard
              title="Health Record"
              imgUrl={HEALTH}
              desc="View your MyChart Health Record"
              msg="Health Record"
              actionUrl={healthRecordSSOUrl}
              handleCardClick={handleCardClick}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={6} lg={4} data-testid="CareTeam">
          <MediaCard
            title="Speak with a Care Team"
            imgUrl={REQUEST}
            data-cy="SpeakWithCareTeam"
            desc="Schedule a time to discuss your options with one of our specialized care teams"
            msg="Consultation Request"
            handleCardClick={handleClick}
          />
        </Grid>
        {enableDotForm && currentDotFormId && (
          <Grid item xs={12} sm={6} md={6} lg={4} data-testid="dotForm">
            <MediaCard
              title="Dot Form"
              imgUrl={DVC}
              desc="Starts the process of filling the DOT form."
              msg="Dot Form"
              handleCardClick={() => navigate(`/dot-form/initialize/${currentDotFormId}`)}
            />
          </Grid>
        )}
        {enableDigitalImplants && haveDigitalImplants && (
          <Grid item xs={12} sm={6} md={6} lg={4} data-testid="DigitalImplant">
            <MediaCard
              title="Digital Implant Card"
              imgUrl={DVC}
              data-cy="DigitalImplantCard"
              desc="For patients that have implants"
              msg="Consultation Request"
              handleCardClick={() => navigate("/digitalimplant")}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
