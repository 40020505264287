import React from "react";
import { makeStyles } from "tss-react/mui";

interface PageContainerProps {
  children;
  noGap?: boolean;
}

const useStyles = makeStyles()(() => ({
  pageContainer: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "0.6em",
  },
  pageContainerNoGap: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const PageContainer = ({ children, noGap = false }: PageContainerProps) => {
  const { classes } = useStyles();
  return (
    <div className={noGap ? classes.pageContainerNoGap : classes.pageContainer}>{children}</div>
  );
};

export default PageContainer;
