import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";
import { useApp } from "../util/AppContext";
import { useAuth } from "../util/Security";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function ForgotPasswordView() {
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();
  const app = useApp();

  const handleStartForgotPassword = async (event) => {
    event.preventDefault();
    await auth.triggerForgotPwdToken(email, (error) => {
      if (error) {
        app.addError(`Error: Forgot Password attempt failed: ${error.message}`);
        return;
      }
      setCodeSent(true);
    });
  };

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    await auth.forgotPasswordSubmit(email, code, newPwd, (error) => {
      if (error) {
        app.addError(`Error: Forgot Password submit attempt failed: ${error.message}`);
        return;
      }
      navigate("/login");
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>Forgott Password:</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {codeSent === false && (
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onInput={(e) => setEmail(e.target.value)}
                />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleStartForgotPassword}
                >
                  Request Password Reset
                </Button>
              </form>
            )}

            {codeSent === true && (
              <form>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    We emailed you a code to be able to reset your password, please enter it below:
                  </Paper>
                </Grid>
                <br />
                <TextField
                  id="code"
                  label="Code"
                  variant="outlined"
                  value={code}
                  onInput={(e) => setCode(e.target.value)}
                />
                <br />
                <br />
                <TextField
                  id="newPwd"
                  label="New Password"
                  type="password"
                  value={newPwd}
                  onInput={(e) => setNewPwd(e.target.value)}
                  variant="outlined"
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleForgotPasswordSubmit}
                >
                  Set New Password
                </Button>
              </form>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
