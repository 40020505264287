import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    p: 4,
    padding: "16px",
  },
}));

export default useStyles;
