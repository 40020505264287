/** @format */
import React, { useEffect } from "react";
import { pdfjs } from "react-pdf";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import HippaDocument from "./HippaDocument";
import { makeStyles } from "tss-react/mui";
import VirtualVisitConsent from "../consents/VirtualVisitConsent";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "8px 24px 8px 8px",
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },
  label: {
    textAlign: "left",
  },
  checkboxRoot: {
    margin: "20px 0px",
    alignItems: "flex-start",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

export default function ConsentCheckbox({
  startingState = false,
  setChecked,
  error,
  setError,
  textBeforeAnchor = "To continue please agree to Atlantic Health System's treatment, payment, and healthcare operations outlined on",
  anchorText = "this form",
  textAfterAnchor = ".",
  consentPath,
}) {
  const { classes } = useStyles();
  const [state, setState] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (startingState) {
      setState(true);
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingState]);

  const handleChange = (event) => {
    if (event.target.checked) {
      setOpen(true);
    } else {
      setState(event.target.checked);
      setChecked(event.target.checked);
      setError(null);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { hostname } = window.location;

  return (
    <>
      <FormControlLabel
        classes={{
          root: classes.checkboxRoot,
          label: classes.label,
        }}
        control={
          <Checkbox
            checked={state}
            onChange={handleChange}
            name="consentBox"
            data-cy="consentBox"
          />
        }
        label={
          <small>
            {textBeforeAnchor}{" "}
            <a
              href={`https://${hostname}${consentPath}`}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {anchorText}
            </a>{" "}
            {textAfterAnchor}
          </small>
        }
        fontSize={2}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogContent className={classes.root}>
          {consentPath.includes("virtual") && <VirtualVisitConsent />}
          {!consentPath.includes("virtual") && <HippaDocument />}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setState(false);
              setChecked(false);
              setOpen(false);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setState(true);
              setChecked(true);
              setOpen(false);
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            I Consent
          </Button>
        </DialogActions>
      </Dialog>
      <div>{error && <small style={{ color: "red" }}>**{error}</small>}</div>
    </>
  );
}
