import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

interface SpecialtyServicesList {
  data: {
    data: [
      {
        id: number;
        name: string;
      },
    ];
  };
}

export const useGetSpecialtyServices = () => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchQuery = (): Promise<SpecialtyServicesList> =>
    axiosClient.get(API_ENDPOINTS.dataCurationSpecialtyServices);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.specialtyServices],
    queryFn: fetchQuery,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  const specialtyServicesList = data?.data?.data;

  return {
    specialtyServicesList,
    refetch,
    isLoading,
    isFetching,
  };
};
