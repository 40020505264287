/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChatIcon from "@mui/icons-material/Chat";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Button, Drawer, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import logoMobile from "../static/images/mobile-logo.jpg";
import SideDrawerView from "./SideDrawerView";
import HelpModal from "../components/HelpModal";
import ConsoleNav from "./console/components/ConsoleNav";
import { useGetProgramAndDepartment } from "./console/components/GetProgramAndDepartment";
import ProgramSelection from "./console/components/ProgramSelection";
import { APP_ENVIRONMENT, Environment, HOST_DOMAIN, epicUrl } from "util/constants/config";
import { useHeaderBarStyles } from "./console/useHeaderBarStyles";
import { CONVERSATION_LINK } from "../util/Links";
import { usePrograms } from "../util/ProgramsContext";
import { useAuth } from "../util/Security";
import { UserTypes } from "../util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import logo from "../static/svg/logo_header.svg";

export default function MenuAppBar() {
  const { classes, cx } = useHeaderBarStyles();
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);
  const [isHelpModalOpen, setHelpModalOpen] = React.useState(false);
  const [showProgramSelection, setshowProgramSelection] = useState(false);
  const [currentProgramName, setCurrentProgramName] = useState("");
  const [currentDepartmentName, setCurrentDepartmentName] = useState("");
  const { host, href } = window.location;
  const exludedRoutes = [
    "/endocrinology/f/",
    "/website-survey-form/f/",
    "/endocrinology/thankyou",
    "/website-survey/thankyou",
  ];
  const shouldHideHeader = exludedRoutes.map((route) => href.includes(route)).some((t) => t);
  const [userDisplay, setUserDisplay] = React.useState("Guest");
  const isMobile = useIsMobile();
  const isPatient =
    auth?.user?.userType === UserTypes.PATIENT || auth?.user?.userType === UserTypes.CONSUMER;
  const { programId, programList, departmentId, departmentList, setProgramId, setDepartmentId } =
    usePrograms();
  useGetProgramAndDepartment();

  const setSelectedProgram = () => {
    const currentProgramIdMatch = programList.find((program) => program.id === programId);
    const currentProgramPathMatch = programList.find((program) => program.url_path === pathname);
    if (currentProgramPathMatch?.name) {
      setCurrentProgramName(currentProgramPathMatch.name);
    } else if (currentProgramIdMatch?.name) {
      setCurrentProgramName(currentProgramIdMatch.name);
    }
  };

  const setSelectedDepartment = () => {
    const currentDepartment = departmentList.find((department) => department.id === departmentId);
    if (currentDepartment?.name) {
      setCurrentDepartmentName(currentDepartment.name);
    }
  };

  let isConsole = false;
  if (host?.startsWith("app")) {
    isConsole = true;
  }

  useEffect(() => {
    setSelectedProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, programList]);

  useEffect(() => {
    setSelectedDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, departmentId, departmentList]);

  useEffect(() => {
    const getUserDetails = async () => {
      await auth.checkLogin((user) => {
        if (user && user.userId) {
          if (user.email) {
            setUserDisplay(user.email);
          } else if (user.cellPhone) {
            setUserDisplay(user.cellPhone);
          } else if (user.firstName) {
            setUserDisplay(user.firstName);
          }
        }
      }, true);
    };
    if (auth.user && auth.user.userId) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileMenuOpen]);

  useEffect(() => {
    if (auth.user && auth.user.userId && isConsole) {
      setshowProgramSelection(true);
    }
    if (!auth?.user) {
      setshowProgramSelection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  // TODO make configurable by role

  const handleMenu = () => {
    setProfileMenuOpen(true);
  };

  const handleClose = () => {
    setProfileMenuOpen(false);
  };

  const toggleMenuDrawer = (isOpen) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setMenuDrawerOpen(isOpen);
  };

  const redirect = (path) => {
    window.location.href = `https://${path}`;
  };

  if (shouldHideHeader) {
    return null;
  }

  return (
    <div id="topappbar">
      <div>
        <AppBar position="fixed" className={classes.appBar} elevation={1}>
          <Toolbar classes={{ root: classes.toolBar }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={toggleMenuDrawer(true)}
              edge="start"
              className={cx(classes.menuButton, profileMenuOpen && classes.hide, "hide-on-print")}
              size="large"
            >
              <MenuIcon />
            </IconButton>

            <a href="/" className={classes.logoButton}>
              {!isMobile && (
                <img
                  title="Atlantic Health System"
                  className={classes.logo}
                  src={logo}
                  alt="Atlantic Health System"
                  onClick={() => {
                    setProgramId(0);
                    setDepartmentId(0);
                  }}
                />
              )}
              {isMobile && (
                <div
                  className={classes.mobileProgramContainer}
                  onClick={() => {
                    setProgramId(0);
                    setCurrentProgramName("");
                    setDepartmentId(0);
                    setCurrentDepartmentName("");
                  }}
                >
                  <img
                    title="Atlantic Health System"
                    className={classes.logo}
                    src={logoMobile}
                    alt="Atlantic Health System"
                  />
                  <div className={classes.mobileProgramTextContainer}>
                    <p className={classes.mobileProgramText}>{currentProgramName}</p>
                    <p className={classes.mobileProgramText}>
                      <u>{currentDepartmentName}</u>
                    </p>
                  </div>
                </div>
              )}
            </a>
            {showProgramSelection && !isMobile && (
              <ProgramSelection
                toggleMenuDrawer={toggleMenuDrawer}
                setCurrentDepartmentName={setCurrentDepartmentName}
              />
            )}
            {APP_ENVIRONMENT !== Environment.PRODUCTION && (
              <span
                className={cx(
                  classes.enviromentLabel,
                  showProgramSelection ? classes.programShown : classes.noProgramShown,
                )}
              >
                {APP_ENVIRONMENT}
              </span>
            )}
            {auth.user ? (
              <>
                {isPatient && (
                  <IconButton
                    classes={{ root: cx(classes.userIconButton, "hide-on-print") }}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    data-cy="patientConversationsBtn"
                    color="primary"
                    onClick={() => navigate(CONVERSATION_LINK)}
                    size="large"
                  >
                    <ChatIcon fontSize="large" />
                  </IconButton>
                )}
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  data-cy="userAccountBtn"
                  classes={{ root: cx(!isPatient && classes.userIconButton, "hide-on-print") }}
                  onClick={handleMenu}
                  color="primary"
                  size="large"
                  id="userAccountBtn"
                >
                  <AccountCircle fontSize="large" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  classes={{
                    paper: classes.menuPapper,
                  }}
                  anchorEl={() => document.getElementById("userAccountBtn")}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={profileMenuOpen}
                  onClose={handleClose}
                  elevation={24}
                >
                  <div className={cx(classes.menuItem, classes.userName)}>{userDisplay}</div>
                  <MenuItem onClick={handleClose} className={classes.menuItem}>
                    Profile
                  </MenuItem>
                  {isPatient && (
                    <MenuItem
                      onClick={() => {
                        navigate("/patient-selection");
                        handleClose();
                      }}
                      className={classes.menuItem}
                    >
                      Family Selection
                    </MenuItem>
                  )}
                  {isPatient || (
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        navigate("/team-members");
                        handleClose();
                      }}
                    >
                      Link Team Member
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      window.open(`${epicUrl}/passwordreset.asp`, "_blank");
                      handleClose();
                    }}
                    className={classes.menuItem}
                  >
                    Change Password
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/logout");
                      auth.setUserDirectly(null);
                      handleClose();
                    }}
                    className={cx(classes.menuItem, classes.logoutItem)}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                classes={{ root: classes.loginButton }}
                size="small"
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                  navigate("/logout");
                }}
              >
                Log In
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={menuDrawerOpen}
          onClose={toggleMenuDrawer(false)}
          elevation={24}
        >
          {isConsole && (
            <ConsoleNav
              toggleMenuDrawer={toggleMenuDrawer}
              setCurrentDepartmentName={setCurrentDepartmentName}
            />
          )}
          {!isConsole && (
            <SideDrawerView
              toggleMenuDrawer={toggleMenuDrawer}
              handleHelpModalOpen={() => setHelpModalOpen(true)}
            />
          )}
        </Drawer>
        <HelpModal isOpen={isHelpModalOpen} handleClose={() => setHelpModalOpen(false)} />
      </div>
      {auth.user
        ? null
        : APP_ENVIRONMENT !== Environment.PRODUCTION && (
            <div className={classes.banner}>
              <div>This website is for TESTING ONLY</div>
              <div>
                <a href="#" onClick={() => redirect(HOST_DOMAIN.myatlantic)}>
                  If you are a patient trying to login, please click here
                </a>
              </div>
            </div>
          )}
    </div>
  );
}
