import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { VUCRedirectContext } from "util/VUCRedirectContext";

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: "640px",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "40px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    justifyContent: "center",
    alignSelf: "center",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    width: "50%",
    height: "42px",
    textTransform: "uppercase",
  },
  middleText: {
    marginTop: theme.spacing(2),
  },
}));

export const VUCDisclaimer = ({ onPositiveOptionPressed }) => {
  const { redirectHome } = useContext(VUCRedirectContext);
  const { classes } = useStyles();

  const onNegativeOptionPressed = () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm("Are you sure you want to exit this session?")) {
      return false;
    }
    redirectHome();
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5">
        Virtual Urgent Care is monitored by the Clark Urgent Care office, any testing or in office
        visits that come from today's session may be completed at Clark or our other Urgent care
        locations.
      </Typography>
      <Typography className={classes.middleText} variant="h6">
        Do you still wish to continue with the visit?
      </Typography>
      <div className={classes.buttonContainer}>
        <Button className={classes.button} variant="outlined" onClick={onNegativeOptionPressed}>
          No
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onPositiveOptionPressed}
          data-cy="positive-vucdisclaimer-option"
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
