import React, { useEffect, useState } from "react";
import { Close, DeleteForever } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { OPERARTORS } from "../../../views/CampaignsManagement/helpers";
import { format } from "date-fns";
import Calender from "components/Calender/Calender";
import { useStyles } from "../../../styles/GenericEnhancedTable/dateFilters.styles";

type ColumnOptions = {
  name: string;
  value: string;
};

interface DateFiltersProps {
  show: boolean;
  handleClose: () => void;
  columnOptions: ColumnOptions[];
  setTableUrl: React.Dispatch<React.SetStateAction<string>>;
  apiEndpoint: string;
}

const EnhancedTableDateFilters: React.FC<DateFiltersProps> = ({
  columnOptions,
  setTableUrl,
  apiEndpoint,
  show,
  handleClose,
}) => {
  const { classes } = useStyles();

  const initialDate = new Date();
  initialDate.setDate(initialDate.getDate() - 7);

  const [columnValue, setColumnValue] = useState("");
  const [operatorValue, setOperatorValue] = useState("");
  const [dateFrom, setDateFrom] = useState(format(initialDate, "MM/dd/yyyy"));
  const [dateTo, setDateTo] = useState(format(new Date(), "MM/dd/yyyy"));

  const handleChangeColumn = (event) => {
    const {
      target: { value },
    } = event;
    setColumnValue(value);
  };

  const handleChangeOperator = (event) => {
    const {
      target: { value },
    } = event;
    setOperatorValue(value);
  };

  const buildFilterQueryString = () => {
    const baseQuery = `${apiEndpoint}?filter_by=${columnValue}&filter_operation=${operatorValue}&initial_value=${format(
      new Date(dateFrom),
      "MM/dd/yyyy",
    )}`;

    return operatorValue === "is_between" || operatorValue === "is_not_between"
      ? `${baseQuery}&final_value=${format(new Date(dateTo), "MM/dd/yyyy")}`
      : baseQuery;
  };

  const handleFilterQueryParams = () => {
    if (columnValue && operatorValue && dateFrom) {
      const filterQueryString = buildFilterQueryString();
      setTableUrl(filterQueryString);
    }
  };

  const handleResetFilters = () => {
    setColumnValue("");
    setOperatorValue("");
    setDateFrom(format(initialDate, "MM/dd/yyyy"));
    setDateTo(format(new Date(), "MM/dd/yyyy"));
    setTableUrl(apiEndpoint);
  };

  useEffect(() => {
    handleFilterQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnValue, operatorValue, dateFrom, dateTo]);

  return (
    <div>
      {show && (
        <Paper className={classes.filtersWrapper} elevation={3} data-testid="DateFilters">
          <Box className={classes.inputsContainer}>
            <IconButton
              data-testid="CloseDateFiltersBtn"
              aria-label="Close"
              onClick={handleClose}
              style={{ marginRight: "16px" }}
              size="large"
            >
              <Close />
            </IconButton>
            <FormControl variant="standard" className={classes.input}>
              <InputLabel id="demo-simple-select-label">Column</InputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                data-testid="inputColumnOption"
                value={columnValue}
                onChange={handleChangeColumn}
              >
                {columnOptions.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" className={classes.input}>
              <InputLabel id="demo-simple-select-label">Operator</InputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                data-testid="inputOperator"
                value={operatorValue}
                onChange={handleChangeOperator}
              >
                {OPERARTORS.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Calender
              dateProp={dateFrom}
              setDateProp={setDateFrom}
              disableFuture
              isRequired={false}
              rootClass={classes.calender}
              labelClass={undefined}
              label="From"
              inputVariant="standard"
              isDisabled={operatorValue === "is_empty" || operatorValue === "is_not_empty"}
              data-testid="inputDateFrom"
            />
            <Calender
              dateProp={dateTo}
              setDateProp={setDateTo}
              disableFuture
              isRequired={false}
              rootClass={classes.calender}
              labelClass={undefined}
              label="To"
              isDisabled={operatorValue !== "is_between" && operatorValue !== "is_not_between"}
              inputVariant="standard"
            />
          </Box>
          <Button onClick={handleResetFilters} className={classes.clearFilterBtn}>
            <DeleteForever />
            CLEAR FILTER
          </Button>
        </Paper>
      )}
    </div>
  );
};

export default EnhancedTableDateFilters;
