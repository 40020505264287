import React, { useState } from "react";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { Box, Collapse, Grid, IconButton, Paper, Typography } from "@mui/material";
import { DigitalImplantResponse } from "hooks/useGetDigitalImplants/useGetDigitalImplants";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { useStyles } from "./styles";
import AppleWalletButton from "static/svg/apple_wallet_button.svg";

type Props = {
  item: DigitalImplantResponse;
  index: number;
};

const DigitalImplantCard = ({ item, index }: Props) => {
  const app = useApp();
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const {
    DeviceID,
    DeviceModel,
    ImplantDate,
    ImplantLocation,
    ImplantHospital,
    ImplantPhysician,
    ImplantType,
    DeviceDescription,
    DeviceBrandName,
    DevicePublishDate,
  } = item;
  const {
    web: {
      digitalImplants: {
        deviceID,
        deviceModel,
        implantType,
        implantDate,
        implantLocation,
        implantHospital,
        implantPhysician,
        deviceDescription,
        deviceBrandName,
        devicePublishDate,
      },
    },
  } = useTranslation();
  const [expanded, setExpanded] = useState<boolean[]>([]);

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const isiOSUser = () => {
    const { userAgent } = navigator;
    return userAgent.match(/iPhone/i) || userAgent.match(/iPad/i);
  };

  const isMacOSUser = () => {
    const { userAgent } = navigator;
    return userAgent.match(/Macintosh/i);
  };

  const shouldShowAppleWalletButton = isiOSUser() || isMacOSUser();

  const downloadAppleWallet = async () => {
    try {
      const response = await axiosClient.post(API_ENDPOINTS.digitalImplantAppleWallet, {
        ImplantDate,
        ImplantLocation,
        ImplantHospital,
        ImplantPhysician,
        ImplantType,
        DeviceDescription,
        DeviceBrandName,
        DeviceModel,
        DevicePublishDate,
        DeviceID,
      });

      if (response.data && response.data.url) {
        const downloadUrl = response.data.url;
        window.location.assign(downloadUrl);
      } else {
        app.addError("Error trying to download the link.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Paper elevation={2} className={classes.implantCard} data-testid="digitalImplantCard">
      <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="nowrap">
        <Grid>
          <Typography className={classes.implantText}>
            <strong>{deviceID}:</strong> {DeviceID}
          </Typography>
          <Typography className={classes.implantText}>
            <strong>{deviceModel}:</strong> {DeviceModel}
          </Typography>
        </Grid>
        {isMobile && (
          <IconButton
            onClick={() => handleExpandClick(index)}
            aria-expanded={expanded[index]}
            aria-label="show more"
            className={classes.expandIcon}
            style={{ transform: expanded[index] ? "rotate(180deg)" : "rotate(0)" }}
            size="large"
          >
            <KeyboardArrowDownRounded />
          </IconButton>
        )}
      </Box>

      <Collapse in={isMobile ? expanded[index] : true} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ height: "234px", overflow: "auto" }}
        >
          <Box>
            <Typography className={classes.implantText}>
              <strong>{implantDate}:</strong> {ImplantDate}
            </Typography>
            <Typography className={classes.implantText}>
              <strong>{implantLocation}:</strong> {ImplantLocation}
            </Typography>
            <Typography className={classes.implantText}>
              <strong>{implantHospital}:</strong> {ImplantHospital}
            </Typography>
            <Typography className={classes.implantText}>
              <strong>{implantPhysician}:</strong> {ImplantPhysician}
            </Typography>
            <Typography className={classes.implantText}>
              <strong>{implantType}:</strong> {ImplantType}
            </Typography>
            <Typography className={classes.implantText}>
              <strong>{deviceDescription}:</strong> {DeviceDescription}
            </Typography>
            <Typography className={classes.implantText}>
              <strong>{deviceBrandName}:</strong> {DeviceBrandName}
            </Typography>
            <Typography className={classes.implantText}>
              <strong>{devicePublishDate}:</strong> {DevicePublishDate}
            </Typography>
          </Box>
          {shouldShowAppleWalletButton && (
            <button
              type="button"
              onClick={() => downloadAppleWallet()}
              className={classes.appleWalletButton}
              data-testid="appleWalletButton"
            >
              <img src={AppleWalletButton} alt="Add to Apple Wallet" style={{ width: "100%" }} />
            </button>
          )}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default DigitalImplantCard;
