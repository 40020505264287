import moment from "moment";
import * as Yup from "yup";

const dayRule = Yup.object({
  start_time: Yup.date().required("Required"),
  end_time: Yup.date()
    .min(Yup.ref("start_time"), "Must be greater")
    .test({
      name: "same",
      exclusive: false,
      params: {},
      message: "Must be greater",
      test(value) {
        const startDate = moment(this.parent.start_time);
        const endDate = moment(value);
        return !moment(startDate).isSame(moment(endDate));
      },
    }),
});

export const overwriteConfigSchema = Yup.object().shape({
  time_slots: Yup.object().shape({
    capacity: Yup.number()
      .required("Required")
      .min(0, "Min capacity value 0")
      .max(99, "Max capacity value 99"),
    duration: Yup.number()
      .required("Required")
      .min(1, "Min duration value 1")
      .max(900, "Max duration value 900"),
    working_hours: Yup.object({
      monday: dayRule,
      tuesday: dayRule,
      wednesday: dayRule,
      thursday: dayRule,
      friday: dayRule,
    }),
  }),
});
