import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { InputAdornment, TextField } from "@mui/material";
import { cellPhoneMask } from "util/constants";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  cellPhone: string;
  setCellPhone: (value: string) => void;
  startAdornmentIcon?: string;
  onBlur?: (e) => void;
  CustomStartIcon?: JSX.Element;
  error?: boolean;
  helperText?: string;
  isShrink?: boolean;
  showMask?: boolean;
  showRequiredInLabel?: boolean;
  label?: string;
}

export default function CellPhone({
  cellPhone,
  setCellPhone,
  startAdornmentIcon,
  onBlur,
  CustomStartIcon,
  error,
  helperText,
  isShrink = false,
  showMask = true,
  showRequiredInLabel = false,
  label,
  ...props
}: Props) {
  const [shrink, setShrink] = useState<boolean>(!isShrink);

  const labelText = showRequiredInLabel ? "Cell Phone*" : "Cell Phone";

  const validatePhone = (e) => {
    const { value } = e.target;
    const regEx = /[^\d]/g;
    setCellPhone(value.substring(2).replace(regEx, ""));
  };

  const onFocusHandler = () => {
    setShrink(true);
  };

  const onBlurHandler = (e: React.FocusEvent) => {
    if (!cellPhone && isShrink) {
      setShrink(false);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    if (cellPhone) {
      setShrink(true);
    }
  }, [cellPhone]);

  return (
    <InputMask
      alwaysShowMask={showMask}
      mask={cellPhoneMask}
      onChange={validatePhone}
      value={cellPhone}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      {...props}
    >
      {(inputProps) => (
        <TextField
          id="cellPhone"
          label={label ?? labelText}
          variant="outlined"
          fullWidth
          value={cellPhone}
          onChange={validatePhone}
          {...props}
          {...inputProps}
          type="tel"
          InputLabelProps={{ ...inputProps.InputLabelProps, shrink }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...inputProps.InputProps,
            startAdornment: (
              <>
                {startAdornmentIcon && (
                  <InputAdornment position="start">
                    <img src={startAdornmentIcon} alt="" />
                  </InputAdornment>
                )}
                {CustomStartIcon}
              </>
            ),
          }}
        />
      )}
    </InputMask>
  );
}

CellPhone.defaultProps = {
  onBlur: null,
  startAdornmentIcon: "",
  CustomStartIcon: null,
  error: "",
  helperText: "",
};
