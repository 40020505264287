import { RefObject } from "react";
import Webcam from "react-webcam";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import { dataURLtoFile } from "util/urlToFile";

export const capturePhoto = (
  e: Event | File,
  addSpecimenPhoto: (image: File) => void,
  setLoadingPhoto: (loading: boolean) => void,
  webcamRef: RefObject<Webcam>,
): void => {
  if (e instanceof File) {
    addSpecimenPhoto(e);
  } else {
    e.preventDefault();
    setLoadingPhoto(true);
    const imageSrc = webcamRef.current?.getScreenshot();
    if (!imageSrc) {
      return;
    }

    const fileName = `${uuidv4().toString()}.png`;
    const file = dataURLtoFile(imageSrc, fileName);
    addSpecimenPhoto(file);
  }
};
