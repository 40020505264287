/* eslint-disable */
import React, { useState } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import ConfigListView from "../Config/ConfigList";
import OnCallShedule from "../Config/OnCallSchedule";
import QuickPhraseList from "../QuickPhrases/QuickPhraseList";
import SpecimenTrackingLocations from "../SpecimenTrackingLocation/SpecimenTrackingLocations";
import { makeStyles } from "tss-react/mui";
import SurveyConfigurationList from "views/SurveyConfigurationList/SurveyConfigurationList";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  tabsNav: {
    marginTop: "18px",
  },
}));

export default function AdminConfigView() {
  const { classes } = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ height: "100%", overflowY: "auto", width: "100%" }}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Tabs value={value} onChange={handleChange} className={classes.tabsNav}>
        <Tab label="Admin Config" />
        <Tab label="On Call Schedule" />
        <Tab label="Quick Phrases" />
        <Tab label="Survey Config" data-cy="SurveyConfigTab" />
        <Tab label="Specimen Locations" data-cy="SpecimenLocationsTab" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ConfigListView />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OnCallShedule />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <QuickPhraseList />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SurveyConfigurationList />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SpecimenTrackingLocations />
      </TabPanel>
    </Grid>
  );
}
