import React, { useCallback, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import VideoOffIcon from "../../../icons/VideoOffIcon";
import VideoOnIcon from "../../../icons/VideoOnIcon";
import useDevices from "../../../hooks/useDevices/useDevices";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import { useGetIsConferenceCall } from "hooks/useGetIsConferenceCall";

export default function ToggleVideoButton({
  disabled,
  className,
  textMobile,
}: {
  disabled?: boolean;
  className?: string;
  textMobile?: string;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const { isConferenceCall } = useGetIsConferenceCall();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  useEffect(() => {
    if (isConferenceCall && isVideoEnabled) {
      toggleVideoEnabled();
    }
    // eslint-disable-next-line
  }, [isConferenceCall, isVideoEnabled]);

  return (
    <Button
      className={className}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || disabled}
      startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
      data-testid="ToggleVideoButton"
    >
      <span className={textMobile}>
        {!hasVideoInputDevices ? "No Video" : isVideoEnabled ? "Stop Video" : "Start Video"}
      </span>
    </Button>
  );
}
