import React, { useState } from "react";
import BadgeIcon from "@mui/icons-material/AssignmentTurnedIn";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { StepsProps } from "../types/constants";
import useTranslation from "hooks/useTranslation";
import useStyles from "../styles/forms.styles";

const AddressConfirmation: React.FC = () => {
  const { classes } = useStyles({});

  const {
    web: {
      dotForm: { patient },
    },
  } = useTranslation();

  const { title, body } = patient.addressConfirmation;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.successStep_container}
    >
      <Typography align="center" variant="h4" className={classes.centeredTitle}>
        {title}
      </Typography>
      <Typography align="center" variant="body1">
        {body}
      </Typography>
      <Container>
        <BadgeIcon fontSize="large" />
      </Container>
    </Grid>
  );
};

const AddressVerificationStep: React.FC<StepsProps> = ({
  formData,
  handleStepChange = () => {},
}) => {
  const { classes } = useStyles({});
  /* eslint-disable @typescript-eslint/naming-convention */
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const {
    web: {
      dotForm: { patient },
    },
  } = useTranslation();

  const { title, subtitle, mutedSubtitle, faqLinkText } = patient.addressVerification;

  const {
    personalInformation: data,
    personalInformation: { addressesMatch, driverLicense: lic },
  } = formData;

  // one of these is the address we have on file in Epic; the other is the
  // address we got from OCRing the patient's license. I will assume the first
  // one is the address on file, and the second is the mismatched OCR reading.

  const formatAddress = (addressParts: (string | undefined | null)[]) => {
    return addressParts.filter((part) => part).join(", ");
  };

  const rawCardOptions = [
    {
      id: "1",
      value: formatAddress([lic?.address, lic?.city, lic?.state, lic?.zipCode?.slice(0, 5)]),
    },
    {
      id: "2",
      value: formatAddress([data?.street, data?.city, data?.state, data?.zip]),
    },
  ];

  const cardOptions = rawCardOptions.filter((option) => option.value);

  const [alertContent, alertLink] = (() => {
    switch (selectedAddress) {
      case "1":
        handleStepChange({ shouldDisableContinueButton: false });
        return [patient.addressVerification.confirmation, undefined];
      case "2":
        handleStepChange({ shouldDisableContinueButton: false });
        return [patient.addressVerification.warning, "/patient/dot-form/faq#section0-question2"];
      default:
        return [undefined, undefined];
    }
  })();

  if (addressesMatch) {
    return <AddressConfirmation />;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.successStep_container}
      data-testid="relationshipToDriver"
    >
      <Typography align="center" variant="h4" className={classes.centeredTitle}>
        {title}
      </Typography>
      <Typography align="center" variant="h5">
        {subtitle}
      </Typography>
      <Typography align="center" variant="h6" className={classes.mutedText}>
        {mutedSubtitle}
      </Typography>
      <FormControl variant="outlined">
        <RadioGroup
          aria-label="street"
          name="street"
          value={selectedAddress}
          onChange={(e) => setSelectedAddress(e.target.value)}
        >
          {cardOptions.map(({ id, value }) => (
            <FormControlLabel key={id} value={id} control={<Radio />} label={value} />
          ))}
        </RadioGroup>
      </FormControl>
      {!!alertContent && (
        <Alert severity={alertContent.severity as AlertColor} className={classes.alert}>
          <AlertTitle>{alertContent.title}</AlertTitle>
          {alertContent.details}
          <br />
          {!!alertLink && (
            <span>
              <a href={alertLink} target="_blank" rel="noreferrer">
                {faqLinkText} <LaunchIcon fontSize="inherit" />
              </a>
            </span>
          )}
        </Alert>
      )}
    </Grid>
  );
};

export default AddressVerificationStep;
