import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "@formio/react";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import "../../Forms/FormEmbeddedView.css";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    backgroundColor: theme.palette.common.white,
    height: "100vh",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "50px",
    margin: "80px auto",
    minWidth: "500px",
    maxWidth: "900px",
    textAlign: "left",
    fontFamily: "Alegreya Sans",
    "& .formio-component-title": {
      marginBottom: "20px",
    },
    "& .form-group": {
      fontSize: "18px",
    },
    "& .btn-primary": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      fontWeight: 500,
      display: "block",
      "&:disabled": {
        display: "none",
      },
    },
    "& .radio-selected": {
      fontWeight: 600,
    },
    "& .formio-component-content > h4": {
      fontWeight: 400,
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
      minWidth: "95%",
      maxWidth: "95%",
      margin: "40px auto",
      "& .formio-component-title > h2": {
        fontSize: "24px",
      },
      "& .formio-component-content > h4": {
        fontSize: "20px",
      },
      "& .form-group": {
        fontSize: "16px",
      },
    },
  },
  patientInfoContainer: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    width: "100%",
    marginBottom: 30,
  },
  title: {
    fontSize: "24px",
    fontFamily: "unset",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
    lineHeight: "inherit",
  },
  noInfoTitle: {
    fontSize: "20px",
    fontFamily: "unset",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
    lineHeight: "inherit",
    marginBottom: 20,
  },
  info: {
    display: "flex",
    marginTop: 10,
    fontSize: "20px",
    marginBottom: 20,
  },
  bold: {
    fontWeight: 700,
    paddingRight: 5,
  },
}));

export default function SurveyFormSubmissionView() {
  const { classes } = useStyles();
  const { onCallReferenceNumber, phone, hash } = useParams();
  const [formData, setFormData] = useState(null);
  const [startOnCallDate, setStartOnCallDate] = useState(null);
  const [onCallForDept, setOnCallForDept] = useState(null);
  const app = useApp();
  const navigate = useNavigate();

  const loadForm = async () => {
    try {
      const getUrl = `/api/provider-survey/${onCallReferenceNumber}`;
      const body = {
        secretHash: hash,
        lastFour: phone,
      };
      const { data } = await axiosClient.post(getUrl, body);
      setFormData(data.form);
      setStartOnCallDate(data.startOnCallDate);
      setOnCallForDept(data.onCallForDept);
    } catch (error) {
      console.error(`Error loading form: ${error?.response?.data?.description}`);
    }
  };

  const handleFormioSubmissionError = (error) => {
    if (error.response.status === 409) {
      app.addInfoMsg("Form already submitted");
      navigate("/surveysubmitted");
    } else {
      const errorDesc = error?.response?.data?.description;
      if (errorDesc) {
        app.addError(`Could not submit form: ${errorDesc}`);
      } else {
        app.addError("Could not submit form!");
      }
    }
  };

  const handleFormioSubmission = async (submission) => {
    try {
      const submitUrl = `/api/provider-survey/${onCallReferenceNumber}/submit`;
      const body = {
        secretHash: hash,
        lastFour: phone,
        submission: { data: submission.data },
      };
      await axiosClient.post(submitUrl, body);
      app.addInfoMsg("Form was successfully submitted!");
      navigate("/doctors-survery/sent");
    } catch (error) {
      handleFormioSubmissionError(error);
    }
  };

  useEffect(() => {
    loadForm();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      {formData && (
        <Form
          disabled={false}
          onSubmit={handleFormioSubmission}
          form={{ components: formData.components }}
          options={{
            readOnly: false,
            evalContext: {
              data: { token: hash },
              context: { startOnCallDate, onCallForDept },
            },
          }}
        />
      )}
    </div>
  );
}
