/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Grid, SvgIcon, Tab } from "@mui/material";
import AppointmentsCard from "./AppointmentsCard";
import { useApp } from "util/AppContext";
import { RNWebView } from "util/RNWebView";
import { axiosClient } from "util/api_helper";
import useStyles from "./Appoitment.styles";

const Appointments = () => {
  const { classes, cx } = useStyles();
  const [value, setValue] = React.useState("1");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [futureAppointments, setFutureAppointments] = useState();
  const [past, setPast] = useState(null);
  const app = useApp();
  const navigate = useNavigate();
  const filteredPastAppointments = past
    ? past?.entry?.filter(
        (item) =>
          new Date(item.resource.period.end).getMonth() === currentDate.getMonth() &&
          new Date(item.resource.period.end).getFullYear() === currentDate.getFullYear(),
      )
    : [];

  const loadFutureAppointments = useCallback(async () => {
    try {
      const uri = "/api/s/fhir/futureappointments";
      const { data } = await axiosClient.get(uri);
      setFutureAppointments(data.Appointments);
    } catch {
      app.addError(
        "Error: Failed to load future appointments. Please try again or contact support.",
      );
    }
  }, [app]);

  const loadPastAppointments = useCallback(async () => {
    try {
      const uri = "/api/s/fhir/futureappointments";
      const { data } = await axiosClient.get(uri);
      setPast(data);
    } catch (error) {
      app.addError(
        "Error: Failed to load future appointments. Please try again or contact support.",
      );
    }
  }, [app]);

  useEffect(() => {
    loadFutureAppointments();
    loadPastAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadFutureAppointments]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const next =
    currentDate.getMonth() === 11
      ? moment.monthsShort(0)
      : moment.monthsShort(currentDate.getMonth() + 1);
  const prev =
    currentDate.getMonth() === 0
      ? moment.monthsShort(11)
      : moment.monthsShort(currentDate.getMonth() - 1);

  const nextMonth = () => {
    const nextMonth = currentDate.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(new Date(nextMonth));
  };

  const prevMonth = () => {
    const nextMonth = currentDate.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(new Date(nextMonth));
  };

  const handleCardClick = (id) => {
    navigate(`/appointments/${id}`);
  };

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleChange}
        centered
        className={cx({ [classes.tabListNative]: RNWebView })}
      >
        <Tab
          label="Upcoming"
          value="1"
          className={cx(classes.container, {
            [classes.containerNative]: RNWebView,
          })}
        />
        <Tab
          label="Past"
          value="2"
          className={cx(classes.container, {
            [classes.containerNative]: RNWebView,
          })}
        />
      </TabList>

      <TabPanel value="1" className={cx(classes.root, { [classes.rootNative]: RNWebView })}>
        {futureAppointments &&
          futureAppointments.map((item, i) => (
            <AppointmentsCard
              key={i}
              id={i}
              name={item.ProviderDepartments[0].Provider.Name}
              specialty={item.ProviderDepartments[0].Department.Specialty.Title}
              time={item.Date}
              avatar={item.ProviderDepartments[0].Provider.PhotoURL}
              handleCardClick={handleCardClick}
            />
          ))}
      </TabPanel>
      <TabPanel value="2" className={cx(classes.root, { [classes.rootNative]: RNWebView })}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Button onClick={prevMonth} className={classes.button}>
              <SvgIcon component={ArrowBackIcon} className={classes.arrowLeft} />
              {prev}
            </Button>
          </Grid>
          <Grid item>{`${moment.months(currentDate.getMonth())}
           ${currentDate.getFullYear()}`}</Grid>
          <Grid item>
            <Button onClick={nextMonth} className={classes.button}>
              {next}
              <SvgIcon component={ArrowForwardIcon} className={classes.arrowRight} />
            </Button>
          </Grid>
        </Grid>
        {filteredPastAppointments &&
          filteredPastAppointments.map((item) => (
            <AppointmentsCard
              key={item.resource.id}
              name={item.resource.subject.display}
              specialty={item.resource.participant[0].individual.display}
              time={item.resource.period.end}
              avatar=""
              handleCardClick={() => {}}
            />
          ))}
      </TabPanel>
    </TabContext>
  );
};

export default Appointments;
