import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type InputErrorMsgProps = {
  error?: string;
  placeholder?: string;
};

const useStyles = makeStyles()({
  placeholder: {
    visibility: "hidden",
  },
  errorText: {
    margin: 0,
  },
});

const InputErrorMsg = ({ error, placeholder, ...props }: InputErrorMsgProps) => {
  const { classes, cx } = useStyles();

  if (!error) {
    return (
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Typography
            className={cx({ [classes.placeholder]: !placeholder }, classes.errorText)}
            variant="body2"
            component="p"
          >
            {placeholder || "placeholder"}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Typography
          variant="body2"
          color="error"
          component="p"
          className={classes.errorText}
          {...props}
        >
          {error}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InputErrorMsg;
