import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    margin: "60px auto 0px auto",
    fontFamily: "Alegreya Sans, sans-serif",
    maxWidth: "608px",
    "& .MuiSelect-selectMenu": {
      textAlign: "left",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
      maxWidth: "608px",
      margin: "40px auto 0px auto",
    },
  },
  btn: {
    width: "100%",
    borderRadius: "4px",
    textTransform: "uppercase",
    marginBottom: "10px",
    color: theme.palette.common.white,
    height: "42px",
  },
  title: {
    fontWeight: "normal",
    textAlign: "center",
    fontSize: 34,
    marginBottom: "32px",
  },
  inputRoot: {
    borderRadius: "4px",
    height: "56px",
  },
  uploadBtn: {
    width: "100%",
    borderRadius: 0,
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 0",
    cursor: "pointer",
  },
  uploadBtnDisable: {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
}));

export default useStyles;
