import React from "react";
import { Check as CheckIcon } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { Steps } from "../Steps";
import { preJoinStyles } from "../prejoinStyles";

type Props = {
  step: Steps;
};

const Stepper: React.FC<Props> = ({ step }) => {
  const { classes } = preJoinStyles();

  return (
    <div className={classes.stepper} data-testid="stepper">
      <Grid item xs>
        <div className={classes.tabItem}>
          {step > Steps.visitReason && (
            <Box height="24px">
              <CheckIcon />
            </Box>
          )}
          <div>Reason For Visit</div>
        </div>
        <hr
          className={
            step > Steps.visitReason
              ? classes.stepperHorizontalLineCompleted
              : classes.stepperHorizontalLineActive
          }
        />
      </Grid>

      <Grid item xs>
        <div className={classes.tabItem}>
          {step > Steps.insuranceDetails && (
            <Box height="24px">
              <CheckIcon />
            </Box>
          )}
          <div>Health Info</div>
        </div>
        <hr
          className={
            step > Steps.insuranceDetails
              ? classes.stepperHorizontalLineCompleted
              : classes.stepperHorizontalLineActive
          }
        />
      </Grid>

      <Grid item xs>
        <div className={classes.tabItem}>
          {step > Steps.deviceSelectionStep && (
            <Box height="24px">
              <CheckIcon />
            </Box>
          )}
          <div>Start My Visit</div>
        </div>
        <hr
          className={
            step > Steps.deviceSelectionStep
              ? classes.stepperHorizontalLineCompleted
              : classes.stepperHorizontalLineActive
          }
        />
      </Grid>
    </div>
  );
};

export default Stepper;
