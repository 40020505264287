import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AppStoreLogin } from "components/AppstoreLogin";
import { SESSION_KEYS } from "util/constants/sessionsKeys";
import { useAppEvent } from "hooks/useAppEvent/useAppEvent";
import { UseMsal } from "hooks/useMsal/useMsal";
import useTranslation from "hooks/useTranslation";
import { useApp } from "../util/AppContext";
import { useAuth } from "../util/Security";
import { getApiHost } from "../util/api_helper";
import { useGetDfdVersion } from "components/NewVersionNotifier";

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: "60px",
    maxWidth: "640px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  ssoTitle: {
    margin: 0,
    opacity: 0.85,
  },
  ssoButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    color: "white",
    fontWeight: "bold",
  },
  passwordlessLoginTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
    opacity: 0.9,
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  passwordlessLoginText: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
  },
  emailInputContainer: {
    margin: "0 auto",
    width: "80%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  emailButton: {
    marginTop: theme.spacing(3),
    fontWeight: "bold",
  },
  emailSuffix: {
    fontSize: "20px",
    margin: 0,
    paddingLeft: "3px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  outerText: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    opacity: 0.7,
  },
  version: {
    position:"fixed",
    bottom:10,
    right:10
  }
}));

export default function PasswordlessLogin() {
  useAppEvent();
  const { classes } = useStyles();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const app = useApp();
  const { login } = UseMsal();
  const { message } = app.mobileDFD_data_state;
  const tokenMsalFromMobile = message.msalToken;

  const tokenMsal = useMemo(() => sessionStorage.getItem(SESSION_KEYS.MSAL_TOKEN), []);

  const {
    web: {
      common: { emailSuffix },
      passwordlessLogin: {
        errorEmailLoginLink,
        emailLoginLink,
        errorVerifyToken,
        welcome,
        logingYouIn,
        welcometoAHSPatientEngagement,
        readyToLogin,
        SSOLogin,
        passwordlessLogin,
        emailOneTime,
        sendEmailoginLink,
        mustbeWitheListed,
        emailContactSupport,
      },
    },
  } = useTranslation();

  const {data:version, isLoading: isVersionLoading} = useGetDfdVersion();

  const [emailPrefix, setEmailPrefix] = useState("");
  const [showAppstoreLogin, setShowAppstoreLogin] = useState(false);
  const loginToken = new URLSearchParams(location.search).get("login_token");
  const redirect = new URLSearchParams(location.search).toString();

  const handleSso = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }
    const ssoUrl = `${getApiHost()}/samlsso/sp/init`;
    window.location.href = `${ssoUrl}?${decodeURIComponent(redirect)}`;
  };

  const handleMsal = async (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }
    try {
      if (!tokenMsal) {
        handleSso();
        return;
      }
      const redirectTo = await login(tokenMsal);

      if (redirectTo.data.redirect) {
        window.location.href = redirectTo.data.redirect;
      } else {
        handleSso(event);
      }
    } catch (error) {
      handleSso(event);
    }
  };

  const handleRequestEmailLink = async (event) => {
    event.preventDefault();
    const emailPrefixCleaned = emailPrefix.split("@")[0].trim();

    let email = `${emailPrefixCleaned}@atlantichealth.org`;
    if (email === "appstore@atlantichealth.org") {
      setShowAppstoreLogin(true);
      return;
    }
    if (emailPrefixCleaned.startsWith("test=")) {
      email = emailPrefixCleaned.substring(5);
    }

    await auth.sendEmailLoginLink(email, (respJson, error) => {
      if (!respJson || error) {
        app.addError(error?.response?.data?.error || errorEmailLoginLink);
      } else {
        app.addInfoMsg(emailLoginLink);
      }
    });
  };

  const handleVerifyToken = async (loginToken) => {
    await auth.verifyEmailLoginLink(loginToken, (userId, error) => {
      if (!userId || error) {
        app.addError(errorVerifyToken);
      }
      navigate("/");
    });
  };

  useEffect(() => {
    if (tokenMsal && tokenMsalFromMobile) {
      handleMsal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenMsal, tokenMsalFromMobile]);

  useEffect(() => {
    if (loginToken) {
      handleVerifyToken(loginToken);
    } else {
      const checkIfLoggedIn = async () => {
        await auth.checkLogin((user) => {
          if (user?.userId) {
            navigate("/");
          }
        });
      };
      checkIfLoggedIn();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      {loginToken && (
        <>
          <h1>{welcome}</h1>
          <p>{logingYouIn}</p>
        </>
      )}
      {!loginToken && (
        <>
          <Paper elevation={24} classes={{ root: classes.paper }}>
            <h1 className={classes.ssoTitle}>{welcometoAHSPatientEngagement}</h1>
            {tokenMsal && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                paddingTop={3}
              >
                <Typography variant="subtitle1">{readyToLogin}</Typography>
                <CheckIcon style={{ color: "#EB8025", height: 64, width: 64 }} />
              </Box>
            )}
            <Button
              variant="contained"
              color="secondary"
              classes={{ root: classes.ssoButton }}
              onClick={tokenMsal ? handleMsal : handleSso}
            >
              {SSOLogin}
            </Button>

            <h3 className={classes.passwordlessLoginTitle}>{passwordlessLogin}</h3>
            <p className={classes.passwordlessLoginText}>{emailOneTime}</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleRequestEmailLink(e);
              }}
              noValidate
            >
              <div className={classes.emailInputContainer}>
                <TextField
                  id="emailPrefix"
                  label="Email Prefix"
                  size="small"
                  variant="outlined"
                  data-cy="emailPrefix"
                  value={emailPrefix}
                  onChange={(e) => setEmailPrefix(e.target.value)}
                />
                <p className={classes.emailSuffix}>{emailSuffix}</p>
              </div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                data-cy="loginBtn"
                classes={{ root: classes.emailButton }}
              >
                {sendEmailoginLink}
              </Button>
            </form>
          </Paper>

          <p className={classes.outerText}>{mustbeWitheListed}</p>
          <p className={classes.outerText}>{emailContactSupport}</p>

          <AppStoreLogin
            showAppstoreLogin={showAppstoreLogin}
            setShowAppstoreLogin={setShowAppstoreLogin}
          />
        </>
      )}
      {!isVersionLoading && <div className={classes.version}>version: {version}</div>}
    </div>
  );
}
