import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "components/Video/icons/CloseIcon";
import useTranslation from "hooks/useTranslation";

const SpecimenImagesDesktopModal = ({ selectedSpecimenData, onClose, classes }) => {
  const {
    web: { specimenTracking },
  } = useTranslation();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={!!selectedSpecimenData}
      className={classes.specimenImagesModal}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {selectedSpecimenData?.specimenImage || selectedSpecimenData?.specimenSpecimenImage ? (
          <Box display="flex" flexDirection="column" style={{ paddingBottom: "1em" }}>
            <Typography variant="h6" style={{ marginBottom: "8px" }}>
              {selectedSpecimenData?.specimenImage
                ? specimenTracking.labelImage
                : specimenTracking.specimenImage}
              :
            </Typography>
            <Box display="flex" marginBottom="24px">
              <img
                src={
                  selectedSpecimenData?.specimenImage
                    ? selectedSpecimenData?.specimenImage
                    : selectedSpecimenData?.specimenSpecimenImage
                }
                alt="Specimen"
                style={{
                  width: "100%",
                  maxWidth: selectedSpecimenData?.specimenImage ? "300px" : "450px",
                }}
              />
              {selectedSpecimenData && (
                <ul className={classes.listStyles}>
                  {Object.keys(selectedSpecimenData)
                    .filter((t) => t !== "specimenImage" && t !== "specimenSpecimenImage")
                    .map((key) => (
                      <li key={key}>
                        <span>{key}</span> {selectedSpecimenData[key]}
                      </li>
                    ))}
                </ul>
              )}
            </Box>
            {selectedSpecimenData?.specimenImage && selectedSpecimenData?.specimenSpecimenImage ? (
              <div>
                <Typography variant="h6" style={{ marginBottom: "8px" }}>
                  {specimenTracking.specimenImage}:
                </Typography>
                <img
                  src={selectedSpecimenData?.specimenSpecimenImage}
                  alt="Specimen"
                  style={{ width: "100%", maxWidth: "450px" }}
                />
              </div>
            ) : null}
          </Box>
        ) : (
          <Typography variant="h4" align="center" style={{ padding: "3em 0 4em" }}>
            {specimenTracking.noImages}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SpecimenImagesDesktopModal;
