import React, { useCallback, useMemo, useState } from "react";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { Button, FormControlLabel, TextField, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import CellPhone from "components/shared/CellPhone";
import { useApp } from "../../../util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { makeNewReferalStyles } from "../../NewReferral/styles";

export const EditProvider = ({ record, mode, handleClose }) => {
  const app = useApp();
  const { classes } = makeNewReferalStyles();

  const [error, setError] = useState({
    npi: false,
    firstName: false,
    lastName: false,
    cellphone: false,
  });
  const errorText = "This field is required";
  const firstNameErrorText = "First Name wasn't found for provided NPI.";
  const lastNameErrorText = "Last Name wasn't found for provided NPI.";
  const cellphoneErrorText = "This field is required, and should be 10 digits long.";
  const cellphoneHelperText = "Phone number must be 10 digit";
  const automaticallyFilledHelperText =
    "This field will be automatically filled when you input an NPI.";

  const onSaveClicked = async (values) => {
    setError({
      ...error,
      npi: !values.npi,
      cellphone: !values.cellphone || values.cellphone.length < 10,
    });

    const isError = !values.npi || !values.cellphone || values.cellphone.length < 10;

    if (!isError) {
      try {
        if (mode === "add") {
          const providerForPost = {
            NPI: values.npi?.trim(),
            cellphone: values.cellphone?.trim(),
            departmentId: null,
            enabled: values.enabled,
            singleMessage: values.singleMessage,
          };
          const url = API_ENDPOINTS.provider_celllphone;
          await axiosClient.post(url, providerForPost);
        } else if (mode === "edit") {
          const providerForPut = {
            NPI: values.npi?.trim(),
            cellphone: values.cellphone?.trim(),
            enabled: values.enabled,
            singleMessage: values.singleMessage,
          };
          const url = `${API_ENDPOINTS.provider_celllphone}/${record.id}`;
          await axiosClient.put(url, providerForPut);
        } else {
          throw new Error("Invalid Mode provided");
        }
        handleClose();
        app.addInfoMsg("Record has been saved successfully.");
        // eslint-disable-next-line
      } catch (error: any) {
        app.addError(error.response?.data?.description || error.response?.data?.error);
      }
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      npi: record.NPI || "",
      firstName: record.firstName || "",
      lastName: record.lastName || "",
      cellphone: record.cellphone || "",
      enabled: record.enabled,
      singleMessage: record.singleMessage,
    },
    onSubmit: onSaveClicked,
  });

  const fetchNamesByNPI = useCallback(
    async (npiTyped) => {
      if (npiTyped) {
        const url = `api/s/console/provider-cellphone/search-npi?NPI=${npiTyped}`;
        try {
          const response = await axiosClient.get(url);
          if (response.status === 200 && response.data?.firstName && response.data?.lastName) {
            setFieldValue("firstName", response.data.firstName);
            setFieldValue("lastName", response.data.lastName);
            setError({ ...error, firstName: false, lastName: false });
          }
        } catch (e) {
          setFieldValue("firstName", "");
          setFieldValue("lastName", "");
          setError({ ...error, firstName: true, lastName: true });
        }
      } else {
        setFieldValue("firstName", "");
        setFieldValue("lastName", "");
        setError({ ...error, firstName: false, lastName: false });
      }
    },
    [error, setFieldValue],
  );

  const debouncedFetchNamesByNPI = useMemo(() => {
    return debounce(fetchNamesByNPI, 1000);
  }, [fetchNamesByNPI]);

  const handleOnNPIChange = useCallback(
    (e) => {
      const npiTyped = e.target.value;
      setFieldValue("npi", npiTyped);
      debouncedFetchNamesByNPI(npiTyped);
    },
    [debouncedFetchNamesByNPI, setFieldValue],
  );

  return (
    <>
      <TextField
        classes={{ root: classes.modalTextField }}
        id="npi"
        name="npi"
        size="small"
        value={values.npi}
        variant="outlined"
        label="NPI *"
        onChange={handleOnNPIChange}
        error={error.npi}
        helperText={error.npi && errorText}
        disabled={mode === "edit"}
        data-cy="npiInputField"
      />
      <TextField
        classes={{ root: classes.modalTextField }}
        id="firstName"
        name="firstName"
        size="small"
        value={values.firstName}
        variant="outlined"
        label="First Name"
        disabled
        error={error.firstName}
        helperText={error.firstName ? firstNameErrorText : automaticallyFilledHelperText}
        data-cy="firstNameInputField"
      />
      <TextField
        classes={{ root: classes.modalTextField }}
        id="lastName"
        name="lastName"
        size="small"
        value={values.lastName}
        variant="outlined"
        label="Last Name"
        disabled
        error={error.lastName}
        helperText={error.lastName ? lastNameErrorText : automaticallyFilledHelperText}
        data-cy="lastNameInputField"
      />
      <CellPhone
        id="cellphone"
        name="cellphone"
        cellPhone={values.cellphone}
        setCellPhone={(newVal) => {
          setFieldValue("cellphone", newVal);
        }}
        error={error.cellphone}
        helperText={error.cellphone ? cellphoneErrorText : cellphoneHelperText}
        data-cy="cellphoneInputField"
      />
      <FormControlLabel
        control={
          <Switch
            checked={values.enabled}
            onChange={(event) => setFieldValue("enabled", event.target.checked)}
            name="isEnabled"
          />
        }
        label={values.enabled ? "Enabled" : "Disabled"}
      />
      <FormControlLabel
        control={
          <Switch
            checked={values.singleMessage}
            onChange={(event) => setFieldValue("singleMessage", event.target.checked)}
            name="isEnabled"
          />
        }
        label={
          values.singleMessage
            ? "Receive 1 daily text for all shifts"
            : "Receive 1 text for each shift"
        }
      />
      <Typography variant="body2">* This fields are reqiured</Typography>
      <Button
        autoFocus
        onClick={() => handleSubmit()}
        type="submit"
        color="primary"
        variant="contained"
        className={classes.saveButton}
        data-cy="saveButton"
      >
        Save
      </Button>
    </>
  );
};
