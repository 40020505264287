import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FamilyJournalView from "./Journal/FamilyJournalView";
import { MessagingProvider } from "./context/messagingContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";

const FamilyMemberInviteLayout = () => {
  const [steps, setSteps] = useState([]);
  const [lastMessageDate, setLastMessageDate] = useState();
  const [sessionName, setSessionName] = useState("");
  const { user } = useAuth();
  const { sessionId } = useParams();
  const userId = user?.userId;

  const getMyMessages = async () => {
    const uri = `/api/s/family-journal/sessions/${sessionId}/family/members/${userId}/messaging/logs`;
    try {
      const { data } = await axiosClient.get(uri);

      if (data) {
        // Remove this filter after implementation of different type of messages
        const filterData = data.data.filter(
          (message) => message.type === "default" || message.type === "media",
        );
        setLastMessageDate(`${filterData[0].date}T${filterData[0].time}`);
        setSteps(filterData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSession = async () => {
    const uri = `/api/s/family-journal/assignations/${sessionId}`;
    try {
      const { data } = await axiosClient.get(uri);

      if (data) {
        const newSessionName = data?.assignation?.name
          ? data?.assignation?.name
          : `${data?.patient?.first_name} Family Journal`;
        setSessionName(newSessionName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMyMessages();
    getSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessagingProvider>
      <FamilyJournalView
        steps={steps}
        lastMessageDate={lastMessageDate}
        sessionName={sessionName}
      />
    </MessagingProvider>
  );
};

export default FamilyMemberInviteLayout;
