export const colDefs = [
  {
    id: "name",
    isString: true,
    disablePadding: false,
    label: "Specimen Location name",
    searchable: true,
    sortable: true,
  },
  {
    id: "description",
    isString: true,
    disablePadding: false,
    label: "Description",
    searchable: true,
    sortable: true,
  },
  {
    id: "finalDestination",
    numeric: false,
    disablePadding: false,
    label: "Is Final Destination",
    searchable: false,
    isCustomContent: true,
    func(val) {
      return val === true ? "Yes" : "No";
    },
  },
  {
    id: "departmentName",
    isString: true,
    disablePadding: false,
    label: "Department",
    searchable: true,
    sortable: true,
  },
];

export interface SpecimenLocation {
  specimenLocationName: string;
  specimenLocationDescription: string;
  departmentName: string;
}
