const parseTime = timeStr => {
  const [hours, minutes] = timeStr.split(":").map(Number);
  return hours * 60 + minutes; 
};

const isOverlap = (exsitingTimeArray, input) => {
  let overlap = false;

  const arrayWhereOverlapWillBeChecked = [
    ...new Map(
      [...input, ...exsitingTimeArray].map(item => [item.uuid, item])
    ).values(),
  ];

  input.forEach(inputItem => {
    arrayWhereOverlapWillBeChecked
      .filter(obj => obj.uuid !== inputItem.uuid)
      .forEach(existingItem => {
        // Check if the dates overlap
        const dateOverlap =
          inputItem.adjustedHoursEffectiveStart ===
            existingItem.adjustedHoursEffectiveStart &&
          inputItem.adjustedHoursEffectiveEnd ===
            existingItem.adjustedHoursEffectiveEnd;

        if (dateOverlap) {
          // Parse start and end times for both items
          const inputStart = parseTime(inputItem.adjustStart);
          const inputEnd = parseTime(inputItem.adjustEnd);
          const existingStart = parseTime(existingItem.adjustStart);
          const existingEnd = parseTime(existingItem.adjustEnd);

          // Check if the time ranges overlap
          const timeOverlap =
            (inputStart >= existingStart && inputStart < existingEnd) ||
            (inputEnd > existingStart && inputEnd <= existingEnd) ||
            (inputStart <= existingStart && inputEnd >= existingEnd);

          if (timeOverlap) {
            overlap = true;
          }
        }
      });
  });

  return overlap;
};

export { isOverlap };
