/* eslint-disable react/jsx-boolean-value */
import React, { useCallback, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import { IconButton, Modal, Paper, Switch, Theme, Typography } from "@mui/material";
import { MessageLeft, MessageRight } from "./Message";
import { TextInput } from "./TextInput";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import dog from "static/images/dog.png";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    width: "80vw",
    height: "80vh",
    maxWidth: "500px",
    maxHeight: "1000px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },

  paper2: {
    width: "80vw",
    maxWidth: "500px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },

  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10%",
  },

  messagesBody: {
    width: "calc( 100% - 20px )",
    margin: 10,
    overflowY: "scroll",
    height: "calc( 100% - 80px )",
  },

  modalTitle: {
    marginTop: "8px",
    fontSize: "22px",
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  modalContainer: {
    marginRight: "30%",
    marginLeft: "30%",
    margin: "120px",
    overflow: "auto",
    borderRadius: "10px",
    border: "none",
    [theme.breakpoints.down("md")]: {
      marginRight: "5%",
      marginLeft: "5%",
      margin: "10px",
      overflow: "auto",
    },
  },

  flexContainer: {
    display: "flex",
    alignSelf: "flex-start",
    paddingLeft: "10px",
  },

  langPosition: {
    display: "flex",
  },

  langLabel: {
    paddingTop: "8px",
  },
}));

export const AiDiagnoseModal = ({ onClose }) => {
  // eslint-disable-next-line
  const [logs, setLogs] = useState<any>([]);
  const [lang, setLang] = useState<string>("English");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();
  const app = useApp();
  const params = new URLSearchParams(window.location.search);
  const currentVirtualVisit = app?.vs_visits_state?.currentVirtualVisit.id ?? params.get("vsId");

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  const askQuestion = useCallback(async (prompt: string) => {
    setIsLoading(true);
    const userLog = {
      name: "User",
      prompt,
    };
    setLogs((prev) => [...prev, userLog]);
    scrollToBottom();
    const url = API_ENDPOINTS.askAIssistant.replace("{currentVirtualVisit}", currentVirtualVisit);
    try {
      const { data } = await axiosClient.post(url, {
        ask: prompt,
        lang,
      });
      const assitanceAnswer = data.data.answers[0];
      const answers = {
        name: "Assistant",
        prompt: assitanceAnswer,
      };
      setLogs((prev) => [...prev, answers]);
      setIsLoading(false);
      scrollToBottom();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Modal open={true}>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.flexContainer}>
            <Typography className={classes.modalTitle}>Talk to our AIssistant</Typography>
            <IconButton
              aria-label="close"
              onClick={() => onClose()}
              className={classes.closeButton}
              size="large"
            >
              <Close />
            </IconButton>
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.langPosition}>
              <Typography className={classes.langLabel}>Selected Language: {lang}</Typography>
              <Switch
                checked={lang === "English"}
                onChange={(event) => {
                  setLang(event.target.checked ? "English" : "Spanish");
                }}
                name="lang"
              />
            </div>
          </div>
          <Paper id="style-1" className={classes.messagesBody}>
            <div ref={bottomRef}>
              {logs.map(({ name, prompt }) =>
                name === "User" ? (
                  <MessageLeft key={name} message={prompt} photoURL={dog} displayName="User" />
                ) : (
                  <MessageRight key={name} message={prompt} />
                ),
              )}
            </div>
          </Paper>
          <TextInput askQuestion={askQuestion} isLoading={isLoading} />
        </Paper>
      </div>
    </Modal>
  );
};
