import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditableComponent from "../EditableComponent";
import { useLocationContext } from "../LocationEditPage/LocationContext";
import RenderInputs from "./RenderInputs";
import { AdditionalLinks, IdentifierInfo } from "../LocationEditPage/utils/types";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

interface Input {
  label: string;
  name: string;
  type: string;
}

interface CommonTabProps {
  title: string;
  inputs: Input[];
  initialValues: AdditionalLinks | IdentifierInfo;
  selectOptions?: { [key: string]: string[] };
  className?: string;
  payloadName: string;
}

const CommonTab: React.FC<CommonTabProps> = ({
  title,
  inputs,
  initialValues,
  selectOptions,
  className,
  payloadName,
}) => {
  const app = useApp();
  const { locationId } = useParams<{ locationId: string }>();
  const { currentPayload, setCurrentPayload } = useLocationContext();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [initialValuesState, setInitialValuesState] = useState(initialValues);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    setCurrentPayload((prevPayload: any) => ({
      ...prevPayload,
      [payloadName]: {
        ...(prevPayload?.[payloadName] || {}),
        [name]: value,
      },
    }));
  };

  const handleEdit = async () => {
    setIsEditing(!isEditing);

    if (currentPayload) {
      setIsLoading(true);

      try {
        const response = await axiosClient.patch(
          `${API_ENDPOINTS.dataCurationLocations}/${locationId}`,
          { ...currentPayload },
        );
        app.addInfoMsg(response.data.message);
        setInitialValuesState(values);
      } catch (error) {
        app.addError("Error updating location. Please try again.");
      } finally {
        setCurrentPayload(null);
        setIsLoading(false);
        setIsEditing(false);
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentPayload(null);
    setValues(initialValuesState);
  };

  useEffect(() => {
    if (!currentPayload) {
      setValues(initialValuesState);
    }
  }, [currentPayload, initialValuesState]);

  return (
    <main>
      <div style={{ maxWidth: "900px", padding: "1em" }}>
        <EditableComponent
          key="123"
          title={title}
          onEdit={handleEdit}
          onCancel={handleCancel}
          isEditing={isEditing}
          isLoading={isLoading}
          currentPayload={currentPayload}
        >
          <RenderInputs
            inputs={inputs}
            disabled={!isEditing}
            handleChange={handleChange}
            inputsValues={values}
            selectOptions={selectOptions}
            className={className}
          />
        </EditableComponent>
      </div>
    </main>
  );
};

export default CommonTab;
