import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Html5QrcodeScanType } from "html5-qrcode";
import ScannedSpecimenDetailsModal from "../components/ScannedSpecimenDetailModal";
import PatientMismatchErrorModal from "../components/modals/PatientMismatchErrorModal";
import { AddManuallyPickDropModal } from "../components/modals/addManuallyPickDropSpecimenModal";
import { SkeletonSpecimenPhoto } from "../components/skeletonSpecimenPhoto";
import Html5QrcodePlugin from "components/QRCodeReader";
import { WebcamPhoto } from "components/WebcamPhoto";
import { Features } from "util/constants/config";
import { useAddSpecimenTrackScanned } from "hooks/specimenTracking/useAddSpecimenTrackScanned/useAddSpecimenTrackScanned";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import useTranslation from "hooks/useTranslation";
import DynamoScanner from "../../../utils/DynamoScanner/DynamoScanner";
import { capturePhoto } from "./utils";
import { useApp } from "util/AppContext";
import { hasFeature } from "util/hasFeature";
import { usePlaySound } from "util/playSounds";
import { SoundToggleButton } from "util/playSounds/soundToggleButton";
import { SpecimenDetails } from "views/SpecimenTracking/types";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";

const Scanners = ({
  addScannedCode,
  setShowAddManually,
  showAddManually,
  handleScanPickOrDrop,
  handleScanNewSpecimen,
  isAdding,
  isPickAndDrop,
  isAlreadyScanned,
  isMobile,
  setScannedCodes,
}) => {
  const app = useApp();
  const navigate = useNavigate();
  const { classes } = useSpecimenStyles();
  const { location } = app.specimen_tracking_state;
  const {
    web: {
      specimenTrackingAdd: { scanNew },
    },
  } = useTranslation();

  const { addSpecimenTrackScanning } = useAddSpecimenTrackScanned();
  const isDynamoSoftBarcodeReaderEnabled = hasFeature(Features.ENABLE_DYNAMSOFT_BARCODE);
  const isDocumentVisible = useDocumentVisibility();
  const { playSound } = usePlaySound();

  const [specimenData, setSpecimenData] = useState<SpecimenDetails | null>(null);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showMismatchModal, setShowMismatchModal] = useState<any>({ show: false, data: [] });
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);
  const webcamRef = useRef<Webcam>(null);

  const addSpecimenPhoto = async (image: File) => {
    const formData = new FormData();
    formData.append("photo", image);
    try {
      const { data } = await addSpecimenTrackScanning(formData);
      if (data?.data.specimenExternalId) {
        if (data?.data.patientMatch === false) {
          return setShowMismatchModal({
            show: true,
            patient: data.data,
          });
        }

        addScannedCode(data);
        if (!isAlreadyScanned(data.data.specimenExternalId)) {
          setSpecimenData(data.data);
          setShowDetailsModal(true);
        }
      } else {
        playSound({ type: "error" });
        app.addError(data?.error);
      }
    } catch (e) {
      playSound({ type: "error" });
      app.addError(`Could not read Specimen Id. Try again, make sure label text is in focus.`);
    } finally {
      setLoadingPhoto(false);
    }
  };

  const renderComponentScanners = () => {
    if (!loadingPhoto) {
      if (isAdding) {
        return (
          <WebcamPhoto
            webcamRef={webcamRef}
            capture={(e) => capturePhoto(e, addSpecimenPhoto, setLoadingPhoto, webcamRef)}
            skeleton={SkeletonSpecimenPhoto}
            showAddManually={showAddManually}
            setShowAddManually={setShowAddManually}
            handleScanNewSpecimen={handleScanNewSpecimen}
          />
        );
      }
      if (isDynamoSoftBarcodeReaderEnabled && isPickAndDrop && isMobile) {
        return (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <DynamoScanner onSuccessRead={handleScanPickOrDrop} />
            <Box width="120px">
              <AddManuallyPickDropModal handleScanPickOrDrop={handleScanPickOrDrop} />
            </Box>
          </Box>
        );
      }
      if (isDynamoSoftBarcodeReaderEnabled && isPickAndDrop && !isMobile) {
        return (
          <Box width="120px">
            <AddManuallyPickDropModal handleScanPickOrDrop={handleScanPickOrDrop} />
          </Box>
        );
      }
      return (
        <Html5QrcodePlugin
          fps={1}
          qrbox={{ width: 200, height: 200 }}
          disableFlip={false}
          qrCodeSuccessCallback={handleScanPickOrDrop}
          supportedScanTypes={[Html5QrcodeScanType.SCAN_TYPE_CAMERA]}
        />
      );
    }

    return <CircularProgress data-testid="loadingPhoto" />;
  };

  useEffect(() => {
    if (!isDocumentVisible && isMobile) {
      navigate("/console/specimen-tracking/pick-flow");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentVisible]);

  return (
    <Box data-testid="Scanners">
      <Typography
        className={classes.title}
        style={{ marginTop: "1em" }}
        color="inherit"
        variant="body1"
      >
        {scanNew}
      </Typography>
      {location && location.name && (
        <Typography className={classes.scannerLocationTitle} color="inherit" variant="body1">
          {location.name}
        </Typography>
      )}
      {!isMobile && (
        <Box className={classes.soundToggleButton}>
          <SoundToggleButton />
        </Box>
      )}
      <Box
        className="qr-container"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ padding: isAdding ? "0 16px" : "0" }}
      >
        {renderComponentScanners()}
        {isMobile && (
          <Box className={classes.soundToogleButtonMobile}>
            <SoundToggleButton />
          </Box>
        )}
      </Box>
      <ScannedSpecimenDetailsModal
        detailsData={specimenData as SpecimenDetails}
        showDetailsModal={showDetailsModal}
        setShowDetailsModal={setShowDetailsModal}
        setScannedCodes={setScannedCodes}
      />
      <PatientMismatchErrorModal
        showMismatchModal={showMismatchModal}
        setShowMismatchModal={setShowMismatchModal}
        handleScanNewSpecimen={handleScanNewSpecimen}
        setShowDetailsModal={setShowDetailsModal}
        setSpecimenData={setSpecimenData}
      />
    </Box>
  );
};

export default Scanners;
