import React from "react";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { useSinglePatientStyles } from "./styles";
import ProfileDefault from "static/images/sample_image_2.png";

const PatientCard = ({ patient }) => {
  const { classes } = useSinglePatientStyles();
  const patientPhoto = null;
  return patient ? (
    <div>
      <Card className={classes.root} style={{ marginBottom: "20px" }}>
        <CardActionArea>
          <div className={classes.media} style={{ textAlign: "left" }}>
            <div className={classes.patientName}>
              {patient.firstName} {patient.lastName}
            </div>
            {patient.MRN && (
              <div>
                <b>MRN:</b> {patient.MRN}
              </div>
            )}
          </div>
          <CardContent className={classes.cardContent}>
            <div>
              {patientPhoto && patientPhoto != null && (
                <CardMedia
                  component="img"
                  alt="Mychart Profile Picture"
                  className={classes.img}
                  title="Mychart  Profile Picture"
                  src={`data:image/jpeg;base64,${patientPhoto}`}
                />
              )}
              {!patientPhoto && (
                <CardMedia
                  component="img"
                  alt="Default Profile Picture"
                  className={classes.img}
                  title="Default Profile Picture"
                  image={ProfileDefault}
                />
              )}
            </div>
            <div className={classes.cardContentDiv}>
              {patient.zip && (
                <div className={classes.insideCards}>
                  <b>ZIP: </b>
                  <span>{patient.zip}</span>
                </div>
              )}
              {patient.dob && (
                <div>
                  <b>DOB: </b>
                  <span>{patient.dob}</span>
                </div>
              )}
              {patient.gender && (
                <div className={classes.insideCards}>
                  <b>GENDER: </b>
                  <span>{patient.gender}</span>
                </div>
              )}
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  ) : (
    <h1>Hello Patient</h1>
  );
};

export default PatientCard;
