import { useQuery } from "react-query";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

export interface ParentLocation {
  address: string;
  id: string;
  name: string;
  nameFull: string;
  parentLocationId: string | null;
  parentLocationName: string | null;
  source: string;
  status: string;
  type: string;
  uuid: string;
  parentLocationType?: string | null;
  parentLocationWebpageUrl?: string | null;
}

async function searchParentLocations() {
  const response = await axiosClient.get(API_ENDPOINTS.dataCurationLocations, {
    params: {
      search_by: "type",
      search_text: "Hospital,Multispecialty Clinic",
      order_by: "name",
      page_size: 50,
    },
  });
  if (response?.data?.status === "error") {
    throw new Error("Error searching parent locations");
  }
  return response?.data?.data as ParentLocation[];
}

const useSearchParentLocations = () =>
  useQuery(["dataCuration", "searchParentLocations"], searchParentLocations);

export { useSearchParentLocations };
