/* eslint-disable */
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CustomizedDialogs from "./EditQuickPhraseModal";
import { makeStyles } from "tss-react/mui";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import "../../App.css";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  cardMain: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "14pt",
    textDecoration: "none",
  },
  backgroundSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
  topGrid: {
    flexGrow: 1,
    margin: "auto",
    width: "80%",
  },
}));

export default function ReferralListView() {
  const app = useApp();

  const { classes } = useStyles();
  const listSelection = "Available";
  const [showModal, setShowModal] = useState(false);
  const [recordToBeEdit, setRecordToBeEdit] = useState(null);
  const [mode, setMode] = useState("add");

  const colDefs = [
    {
      id: "label",
      numeric: false,
      disablePadding: true,
      label: "Label",
      searchable: true,
      sortable: true,
    },
    {
      id: "phrase",
      numeric: false,
      disablePadding: true,
      label: "Phrase",
      searchable: true,
      sortable: true,
    },
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
      searchable: true,
      sortable: true,
    },
    {
      id: "speciality",
      numeric: false,
      disablePadding: true,
      label: "Speciality",
      searchable: false,
      sortable: true,
    },
    {
      id: "context",
      numeric: false,
      disablePadding: true,
      label: "Context",
      searchable: false,
      sortable: true,
    },
  ];

  const handleEditRecord = (record) => {
    setMode("edit");
    setShowModal(true);
    console.log("handleEditRecord ", record);
    setRecordToBeEdit(record);
  };
  const onCloseEditRecordModal = () => {
    setRecordToBeEdit(null);
  };
  const onSaveEditRecordModal = async (updatedRecord) => {
    const operation = mode === "add" ? "create" : "update";
    const post_url = `/api/s/console/virtualvisit/quick_phrases/${operation}`;
    const updatedQuickPhrase = {
      id: updatedRecord.id,
      label: updatedRecord.label,
      phrase: updatedRecord.phrase,
      category: updatedRecord.category,
      speciality: updatedRecord.speciality,
      context: updatedRecord.context,
    };
    try {
      const { data } = await axiosClient.post(post_url, updatedQuickPhrase);
      if (data && data.data.length > 0) {
        app.addInfoMsg("Record has been saved/updated successfully.");
        closeModal();
      }
    } catch {
      app.addError("Error: Phrase Record not saved/updated.");
    }
  };

  const handleDeleteRecord = async (record) => {
    if (!window.confirm("Are you sure do you want to delete this record ?")) {
      return false;
    }
    try {
      const delete_url = "/api/s/console/virtualvisit/quick_phrases/delete";
      const { data } = await axiosClient.post(delete_url, { id: record.id });
      if (data && data.data.length > 0) {
        app.addInfoMsg("Record has been deleted successfully.");
        closeModal();
      }
    } catch {
      app.addError("Error: Phrase Record not deleted.");
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const addNewRecord = () => {
    setMode("add");
    setRecordToBeEdit({
      id: "",
      label: "",
      phrase: "",
      category: "",
      speciality: "",
      context: "",
    });
    setShowModal(true);
  };
  return (
    <form className={classes.root} noValidate data-testid="form-root" autoComplete="off">
      <Grid
        container
        spacing={8}
        className={classes.root}
        alignItems="center"
        justifyContent="center"
        data-testid="main-grid"
      >
        <Grid
          container
          spacing={8}
          className={classes.topGrid}
          alignItems="flex-end"
          justifyContent="center"
          data-testid="top-grid"
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => addNewRecord()}
            style={{ marginBottom: "20px" }}
            data-testid="add-button"
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12} style={{ padding: "0" }} data-testid="table-grid">
          <GenericEnhancedTable
            gridConfig={defaultGridConfig}
            gridType={listSelection}
            tableTitle="Quick Phrase List"
            colDefs={colDefs}
            url="/api/s/console/virtualvisit/quick_phrases/list"
            orderBy="id"
            order="desc"
            onEditHandler={handleEditRecord}
            onDeleteHandler={handleDeleteRecord}
            refreshSourceData={!showModal}
          />
        </Grid>
        <CustomizedDialogs
          show={showModal}
          setShow={setShowModal}
          record={recordToBeEdit}
          onClose={onCloseEditRecordModal}
          mode={mode}
          onSave={onSaveEditRecordModal}
        />
      </Grid>
      <br />
    </form>
  );
}
