import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { MedicalExaminer } from "../submission/Steps/MedicalDeterminationStep";
import useTranslation from "hooks/useTranslation";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

interface QueryResult {
  data: { data: MedicalExaminer[] };
}

export const useGetMedicalExaminers = () => {
  const [isUserMedicalExaminer, setIsUserMedicalExaminer] = useState<boolean>(false);
  const [medicalExaminersList, setMedicalExaminersList] = useState<MedicalExaminer[]>([]);
  const { isStaff } = useUserType();
  const app = useApp();
  const { user } = useAuth();
  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const currentUserId = Number(user.userId);

  const fetchQuery = (): Promise<QueryResult> => axiosClient.get(API_ENDPOINTS.dotMedicalExaminers);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.medicalExaminers],
    queryFn: fetchQuery,
    staleTime: Infinity,
    onError: () => app.addError(errorMsg),
    enabled: isStaff,
  });

  useEffect(() => {
    if (data?.data) {
      setMedicalExaminersList(data?.data?.data);
      const medicalExaminers = data?.data?.data;

      const getExaminerIdByUserId: MedicalExaminer | undefined = medicalExaminers.find(
        (t: MedicalExaminer) => t.userId === currentUserId,
      );

      if (getExaminerIdByUserId) {
        setIsUserMedicalExaminer(true);
      }
    }
  }, [data]);

  return {
    isUserMedicalExaminer,
    medicalExaminersList,
    isFetching,
    isLoading,
    refetch,
  };
};
