import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { isAlphabetsOnly } from "util/util_functions";

export default function PCPName({ defaultValue, pcpName, setFieldValue }) {
  const [open, setOpen] = useState(false);
  const [pcpNameList, setPcpNameList] = useState([]);
  const [selectedPCPOption, setSelectedPCPOption] = useState(defaultValue);
  const [pcpInputName, setPcpInputName] = useState("");
  const [loading, setLoading] = useState(false);
  const app = useApp();

  const searchPCPName = async () => {
    setLoading(true);
    const url = "/api/s/virtualvisit/search-primary-care-physician";
    try {
      const { data } = await axiosClient.get(url, {
        params: { search_text: pcpName, record_size: 20 },
      });
      setPcpNameList([...data.data]);
      setLoading(false);
    } catch (error) {
      app.addError("Could not search primary care physician ");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setPcpNameList([]);
    }
  }, [open]);

  useEffect(() => {
    if (pcpName && pcpName.length > 1) {
      searchPCPName();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcpName]);

  useEffect(() => {
    if (defaultValue?.name) {
      setSelectedPCPOption(defaultValue);
      setPcpInputName(defaultValue.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Autocomplete
      id="pcp-name-demo"
      style={{ minWidth: 260 }}
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => (option?.name ? option.name : "")}
      options={pcpNameList}
      loading={loading}
      value={selectedPCPOption}
      defaultValue=""
      onChange={(event, newValue) => {
        if (isAlphabetsOnly(newValue?.name)) {
          if (newValue === null) {
            setFieldValue("primaryCarePhysicianName", "");
            setFieldValue("primaryCarePhysicianNpi", "");
            setPcpInputName("");
          } else {
            setFieldValue("primaryCarePhysicianName", newValue.name);
            setFieldValue("primaryCarePhysicianNpi", newValue.id);
            setPcpInputName(newValue.name);
          }
          setSelectedPCPOption(newValue);
        }
      }}
      inputValue={pcpInputName}
      onInputChange={(event, newValue) => {
        if (isAlphabetsOnly(newValue)) {
          setPcpInputName(newValue);
        }
        setFieldValue("primaryCarePhysicianName", newValue);
        setFieldValue("primaryCarePhysicianNpi", "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Primary care physician"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
