import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";
import { SpecimenDepartmentInfo } from "views/SpecimenTracking/types";

interface SpecimenDepartmentsData {
  data: SpecimenDepartmentInfo[];
}
export const useGetSpecimenDepartments = () => {
  const app = useApp();
  const { programId } = usePrograms();
  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();
  const params = programId ? { program_id: programId } : {};

  const fetchQuery = (): Promise<SpecimenDepartmentsData> =>
    axiosClient.get(API_ENDPOINTS.get_user_departments, { params });

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.specimenTrackingDepartments],
    queryFn: fetchQuery,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });
  return {
    data,
    refetch,
    isLoading,
    isFetching,
  };
};
