import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

type BackButtonProps = {
  url: string;
  onClick?: () => void;
};

const noop = () => {};

const BackButton: React.FC<BackButtonProps> = ({ url, onClick = noop }: BackButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick();
    navigate(url);
  };

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      tabIndex={0}
      type="button"
      style={{
        margin: "16px",
        marginRight: "auto",
        display: "flex",
      }}
    >
      Go to List
    </Button>
  );
};

export default BackButton;
