import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ChatWindow from "components/Video/components/ChatWindow/ChatWindow";
import useChatContext from "components/Video/hooks/useChatContext/useChatContext";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";

const VirtualSessionChat = () => {
  const { vsId } = useParams<{ vsId: string }>();
  const app = useApp();
  const { setVsId, connect: chatConnect, messages, setIsChatWindowOpen } = useChatContext();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (vsId) {
      setVsId(vsId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  useEffect(() => {
    if (isMobile) {
      setIsChatWindowOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const getTwilioToken = async () => {
    try {
      const url = `/api/s/virtualvisit/sessions/${vsId}/outroom-token`;
      const { data } = await axiosClient.get(url);
      chatConnect(data?.token);
    } catch (e) {
      app.addError("Problem Joining Virtual Session.");
    }
  };

  useEffect(() => {
    getTwilioToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ChatWindow vsId={Number(vsId)} messages={messages} />;
};

export default VirtualSessionChat;
