import React, { FC } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Card, CardActionArea, Grid, SvgIcon, Typography } from "@mui/material";
import useStyles from "./Appoitment.styles";
import avatarSource from "../../static/images/sample_image_2.png";

interface Props {
  id?: number;
  name: string;
  specialty: string;
  time: string;
  avatar: string;
  handleCardClick: (id) => void;
}

const AppointmentsCard: FC<Props> = ({ id, name, specialty, time, avatar, handleCardClick }) => {
  const { classes } = useStyles();

  return (
    <Card elevation={24} className={classes.card}>
      <CardActionArea onClick={() => handleCardClick(id)}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          className={classes.CardContainer}
        >
          <Grid item>
            <img
              src={avatar || avatarSource}
              height={60}
              width={60}
              className={classes.avatar}
              alt=""
            />
          </Grid>
          <Grid item>
            <Typography className={classes.title}>{name} </Typography>
            <Typography className={classes.text}>{specialty}</Typography>
            <Typography className={classes.text}>{time}</Typography>
          </Grid>
          <SvgIcon component={ArrowForwardIosIcon} className={classes.arrow} />
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default AppointmentsCard;
