import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import PropTypes from "prop-types";
import qString from "query-string";
import HelpModal from "components/HelpModal";
import CellPhone from "components/shared/CellPhone";
import { Features, epicUrl } from "util/constants/config";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import { hasFeature } from "util/hasFeature";
import useStyles from "styles/Authorization/authorization.styles";

export const SIGN_UP_LINK_HREF = `${epicUrl}/signup`;
export const RESET_USERNAME_LINK_HREF = `${epicUrl}/recoverlogin.asp`;
export const RESET_PASSWORD_LINK_HREF = `${epicUrl}/passwordreset.asp`;
const HELP_LINK_HREF = `${epicUrl}/publicforms.asp?mode=showform&formname=CustomerService`;

export const AuthLink = ({ text, href }) => {
  const { classes } = useStyles();

  return (
    <a className={classes.links} href={href} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
};

AuthLink.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default function LoginView() {
  const { classes } = useStyles();
  const app = useApp();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showIdpLogins, setShowIdpLogins] = useState(false);
  const [showSmsLogin, setShowSmsLogin] = useState(true);
  const [loginHeader, setLoginHeader] = useState("Please Login");
  const [cellPhone, setCellPhone] = useState("");
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isSmsLoginLoading, setIsSmsLoginLoading] = useState(false);
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
  const forceMFA = hasFeature(Features.MFA_ENABLED);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const loginToken = new URLSearchParams(location.search).get("t");
  const params = qString.parse(location.search);

  let redirect = new URLSearchParams(location.search).get("r");

  if (!redirect && params && params.redirect && params.redirect.length > 0) {
    redirect = params.redirect;
  }

  redirect = redirect ? decodeURIComponent(redirect) : null;

  const ctxId = new URLSearchParams(location.search).get("ctx");
  const showInstantActivation = new URLSearchParams(location.search).get("showIA");

  const submitButtonRef = useRef(null);
  let { from } = location.state || {
    from: { pathname: `/${window.location.search.replace("?departmentId=0&programId=0", "")}` },
  };
  if (redirect && redirect.length > 0) {
    from = {
      from: { pathname: redirect },
    };
  }

  const {
    web: {
      signinView: { cellphoneLoginMsgSent },
    },
  } = useTranslation();

  const handleVerifyToken = async (login_token, ctxId) => {
    await auth.verifySmsLoginLink(
      login_token,
      (userId, error) => {
        if (!userId || userId.length < 1 || error) {
          if (error?.response?.status === 412) {
            const params = new URLSearchParams(window.location.search);
            const token = params.get("t");
            const r = params.get("r");
            redirect = `/activation/${token}?r=${r}`;
          } else {
            app.addError("Failed to verify login token, please try again.");
          }
        }
        if (redirect && redirect.length > 0) {
          if (redirect) {
            redirect = redirect.startsWith("/") ? redirect : `/${redirect}`;
          }
          navigate(redirect);
        } else {
          navigate(`/${location.search}`);
        }
      },
      ctxId,
    );
  };

  useEffect(() => {
    if (loginToken && loginToken.length > 0) {
      handleVerifyToken(loginToken, ctxId);
      return;
    }

    const url = new URL(window.location.href);
    const idpLogin = url.searchParams.get("idpLogin");
    if (idpLogin === "true") {
      setShowIdpLogins(true);
    }

    const smsLogin = url.searchParams.get("smsLogin");
    if (smsLogin === "true") {
      setShowSmsLogin(true);
    }

    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (submitButtonRef?.current) {
          submitButtonRef.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loginHeaderParam = new URLSearchParams(location.search).get("loginHeader");
    if (loginHeaderParam && loginHeaderParam.length > 0) {
      setLoginHeader(loginHeaderParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginHeader]);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setIsLoginButtonDisabled(true);

    const str = "?";
    Object.keys(params).forEach((k, i) =>
      i < Object.keys(params).length - 1 ? `${str + k}=${params[k]}&` : `${str + k}=${params[k]}`,
    );
    const program = params?.program;
    await auth.directLogin(email, password, (user, error) => {
      if (!user || error) {
        app.addError(
          error.response?.data?.description || "Error: Signin attempt failed, please try again",
        );
        setIsLoginButtonDisabled(false);
        return;
      }

      if (forceMFA) {
        if (redirect && redirect.length > 0) {
          redirect = redirect.startsWith("/") ? redirect : `/${redirect}`;
        }
        const search = program ? `?program=${program}` : "";
        navigate(`/activation?cell_phone=${user.cellPhone}&r=${redirect}&search=${search}`);
      } else {
        /* eslint-disable */
        if (redirect && redirect.length > 0) {
          if (redirect && !redirect.startsWith("/")) {
            redirect = `/${redirect}`;
          }
          const search = program ? `?program=${program}` : "";
          navigate(`${redirect.replace("undefined", "home")}${search}`);
        } else {
          navigate(from, { replace: true });
        }
      }
    });
  };

  const handleSmsLogin = async (event) => {
    event.preventDefault();
    if (!cellPhone || cellPhone.length < 10) {
      app.addError(
        "Oops, your number format isn't correct.  Please enter a 10-digit only cell phone number.",
      );
      return;
    }

    setIsSmsLoginLoading(true);
    const apiUrl = "/api/cellphonelogin";
    const params = {};
    if (app.isEmbedded()) {
      params.uiEmbedded = true;
    }
    const url = new URL(window.location.href);
    const uc = url.searchParams.get("userContext");
    if (uc && uc.length > 0) {
      params.userContext = uc;
    }
    if (redirect && redirect.length > 0) {
      params.redirect = redirect;
    }
    try {
      await axiosClient.post(apiUrl, { cell_phone: cellPhone }, { params });
      app.addInfoMsg(cellphoneLoginMsgSent);
      setIsSmsLoginLoading(false);
      navigate(`/activation?cell_phone=${cellPhone}&r=${redirect}`);
    } catch {
      setIsSmsLoginLoading(false);
      app.addError("Failed to send Text message login, please contact support.");
    }
  };

  const handleAmazonLogin = (event) => {
    event.preventDefault();
    const options = {};
    options.scope = "profile";
    options.pkce = true;
    options.scope_data = {
      profile: { essential: false },
    };
    window.amazon.Login.authorize(options, (response) => {
      if (response.error) {
        // eslint-disable-next-line
        alert(`oauth error ${response.error}`);
        return;
      }
      window.amazon.Login.retrieveToken(response.code, (response) => {
        if (response.error) {
          // eslint-disable-next-line
          alert(`oauth error ${response.error}`);
          return;
        }
        auth.oauthLogin("amazon.com", response.access_token, (user, error) => {
          if (!user || error) {
            app.addError("Error: Signin attempt failed, please try again");
            return;
          }
          navigate(from, { replace: true });
        });
      });
    });
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item classes={{ root: classes.root }}>
          <Paper sx={{ boxShadow: [0, 24] }} classes={{ root: classes.paper }}>
            <h1>Get connected to extraordinary care</h1>
            <h2>{loginHeader}</h2>
            <form noValidate autoComplete="off" onSubmit={handleLoginSubmit}>
              <Grid container alignItems="center" justifyContent="center">
                <TextField
                  id="email"
                  data-cy="mychartEmail"
                  label="MyChart Username"
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  variant="outlined"
                  className={classes.textField}
                  value={email}
                  onInput={(e) => setEmail(e.target.value)}
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  data-cy="mychartPassword"
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  className={classes.textField}
                  value={password}
                  onInput={(e) => setPassword(e.target.value)}
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  data-cy="loginBtn"
                  ref={submitButtonRef}
                  className={classes.loginButton}
                  disabled={isLoginButtonDisabled}
                  fullWidth
                >
                  Log In
                </Button>
              </Grid>
            </form>

            {showIdpLogins && (
              <a href id="LoginWithAmazon" onClick={handleAmazonLogin}>
                <img
                  border="0"
                  alt="Login with Amazon"
                  src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                  width="156"
                  height="32"
                />
              </a>
            )}

            {showSmsLogin && (
              <form onSubmit={handleSmsLogin}>
                <p className={classes.noLoginText}>Or log in with your cell phone</p>
                <CellPhone cellPhone={cellPhone} setCellPhone={(value) => setCellPhone(value)} />
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  className={classes.textMessageLoginButton}
                  fullWidth
                  disabled={isSmsLoginLoading}
                  startIcon={isSmsLoginLoading && <CircularProgress size={24} color="secondary" />}
                >
                  Send Text Message Login
                </Button>
              </form>
            )}

            <Box classes={{ root: classes.noLoginContainer }}>
              <p>No login?</p>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(`/instantactivation${location.search}`);
                }}
              >
                Activate or Signup
              </Button>
            </Box>
            {showInstantActivation && (
              <Grid item xs={12}>
                <AuthLink href={SIGN_UP_LINK_HREF} text="Signup" />
              </Grid>
            )}
            {showIdpLogins && (
              <>
                <Box classes={{ root: classes.noLoginContainer }}>
                  <p>No login?</p>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    Signup
                  </Button>
                </Box>
                <Box classes={{ root: classes.noLoginContainer }}>
                  <p>Forgot Password?</p>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/forgotpwd");
                    }}
                  >
                    Reset Password
                  </Button>
                </Box>
              </>
            )}
            <Box mt={2}>
              <AuthLink href={RESET_USERNAME_LINK_HREF} text="Forgot username?" />
              <AuthLink href={RESET_PASSWORD_LINK_HREF} text="Forgot password?" />
              <Box mt={1}>
                <AuthLink href={HELP_LINK_HREF} text="Help?" />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <HelpModal isOpen={isHelpModalOpen} handleClose={() => setIsHelpModalOpen(false)} />
    </>
  );
}
