import React, { useEffect, useState } from "react";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { DepartmentFilteredList, EditUserProgramProps } from "../types";
import { makeNewReferalStyles } from "../../NewReferral/styles";

export const EditUserProgram: React.FC<EditUserProgramProps> = ({
  selectedProgram,
  setSelectedProgram,
  selectedDepartment,
  setSelectedDepartment,
  setSelectedRoleName,
  programsList,
  selectedRole,
  departmentUsersData,
  setDepartmentUsersData,
  setSelectedRole,
  userID,
  getSelectedUserDepartmentsData,
  rolesList,
  mode,
  onSaveClicked,
  handleClose,
  setUserRecord,
  userRecord,
}) => {
  const { classes } = makeNewReferalStyles();
  const app = useApp();

  const [departmentFilteredList, setDepartmentFilteredList] = useState<DepartmentFilteredList[]>(
    [],
  );
  const [primaryDepartmentId, setPrimaryDepartmentId] = useState<string | number>("");
  const [newUserId, setNewUserId] = useState<number | string | null>(null);
  const [showUserError, setShowUserError] = useState<boolean>(false);
  const [isNewUserSaved, setIsNewUserSaved] = useState<boolean>(false);

  useEffect(() => {
    const presettedPrimaryDepartment = departmentUsersData.find(
      (department) => department.is_primary,
    );

    if (presettedPrimaryDepartment?.is_primary) {
      setPrimaryDepartmentId(presettedPrimaryDepartment.department_id);
    }
  }, [departmentUsersData]);

  useEffect(() => {
    setSelectedProgram("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDepartmentsAgainstSelectedProgram = async (program_id: string) => {
    try {
      const url = `/api/s/console/config/programs/${program_id}/departments`;
      const { data } = await axiosClient.get(url);
      setDepartmentFilteredList(data.data);
    } catch (error) {
      console.error(error);
      app.addError("Failed to get departments against selected program.");
    }
  };

  const addNewDepartmentUserRecord = async () => {
    try {
      let userIdToUse;

      if (mode === "add" && !isNewUserSaved) {
        const userIdFromSaveClicked = await onSaveClicked(false);
        // @ts-ignore-next-line
        setNewUserId(userIdFromSaveClicked);
        setIsNewUserSaved(true);
        userIdToUse = userIdFromSaveClicked;
        setUserRecord({ ...userRecord, userId: userIdFromSaveClicked });
      } else {
        userIdToUse = userRecord.userId;
      }

      const { data } = await axiosClient.post(API_ENDPOINTS.add_new_department_user_record, {
        user_id: userIdToUse,
        program_id: selectedProgram,
        department_id: selectedDepartment,
        role_id: selectedRole,
      });

      if (data.data) {
        setSelectedProgram("");
        setSelectedDepartment("");
        setSelectedRole("");
        setDepartmentFilteredList([]);
        app.addInfoMsg("New record has been successfully saved.");
        getSelectedUserDepartmentsData(userIdToUse);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.status === 409) {
        app.addError("The user has already been linked to the department with this role.");
      } else {
        setShowUserError(true);
        app.addError("Failed to add new department user record.");
      }
    }
  };

  const handleOnChangeProgramDropdown = (e) => {
    const programId = e.target.value;
    const selProgram = programsList.find((pl) => {
      return pl.id === parseInt(programId, 10);
    });
    setSelectedProgram(selProgram ? String(selProgram.id) : "");
    setSelectedDepartment("");
    getDepartmentsAgainstSelectedProgram(programId);
  };

  const handleOnChangeDepartmentDropdown = (e) => {
    const selDepart = departmentFilteredList.find((dfl) => {
      return dfl.id === parseInt(e.target.value, 10);
    });
    setSelectedDepartment(selDepart ? String(selDepart.id) : "");
  };

  const handleOnChangeRoleDropdown = (e) => {
    const selRole = rolesList.find((rl) => {
      return rl.id === parseInt(e.target.value, 10);
    });
    setSelectedRole(selRole?.id ? selRole.id : "");
    setSelectedRoleName(selRole?.role_name ? selRole?.role_name : "");
  };

  const removeDepartmentUserRecordBtn = async (department_user_id, index) => {
    try {
      if (mode === "add") {
        const temp = [...departmentUsersData];
        temp.splice(index, 1);
        setDepartmentUsersData(temp);
      } else if (mode === "edit") {
        const { data } = await axiosClient.post(API_ENDPOINTS.remove_department_user_record, {
          department_user_id,
        });
        if (data.data) {
          app.addInfoMsg("Record has been successfully removed.");
          getSelectedUserDepartmentsData(userID as string);
        }
      }
    } catch (error) {
      console.error(error);
      app.addError("Failed to remove department user record.");
    }
  };

  const changePrimaryDepartment = async () => {
    const id = mode === "add" ? newUserId : userID;
    const url = `/api/s/console/user/${id}/departments/primary`;

    try {
      await axiosClient.post(url, {
        department_id: primaryDepartmentId,
      });
      app.addInfoMsg("Primary department was changed successfully.");
      handleClose();
    } catch (e) {
      app.addError("Something went wromg. Please contact support.");
    }
  };

  return (
    <>
      <div className={classes.tableContainer}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead className={classes.modalTableRow}>
            <TableRow>
              <TableCell align="center" className={classes.modalTableCell}>
                Program
              </TableCell>
              <TableCell align="center" className={classes.modalTableCell}>
                Department
              </TableCell>
              <TableCell align="center" className={classes.modalTableCell}>
                Role
              </TableCell>
              <TableCell align="center" className={classes.modalTableCell}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="input-row" className={classes.modalTableRow}>
              <TableCell align="center" className={classes.modalTableCell}>
                <TextField
                  label="Program *"
                  variant="outlined"
                  size="small"
                  value={selectedProgram}
                  onChange={(e) => handleOnChangeProgramDropdown(e)}
                  fullWidth
                  select
                >
                  {programsList.map((program) => (
                    <MenuItem value={program.id} key={program.id}>
                      {program.name}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell align="center" className={classes.modalTableCell}>
                <TextField
                  label="Department *"
                  variant="outlined"
                  size="small"
                  value={selectedDepartment}
                  onChange={(e) => handleOnChangeDepartmentDropdown(e)}
                  select
                  fullWidth
                  disabled={!departmentFilteredList.length}
                >
                  {departmentFilteredList?.map((department) => (
                    <MenuItem value={department.id} key={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell align="center" className={classes.modalTableCell}>
                <TextField
                  label="Role *"
                  variant="outlined"
                  size="small"
                  value={selectedRole}
                  onChange={(e) => handleOnChangeRoleDropdown(e)}
                  fullWidth
                  select
                >
                  {rolesList.map((role) => (
                    <MenuItem value={role.id} key={role.id}>
                      {role.role_name}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell
                align="center"
                classes={{ body: classes.tableCellBody }}
                className={classes.modalTableCell}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon>Add</AddIcon>}
                  onClick={() => addNewDepartmentUserRecord()}
                  disabled={!selectedProgram || !selectedDepartment || !selectedRole}
                  fullWidth
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            {departmentUsersData.map((du, index) => (
              <TableRow
                key={du.department_user_id}
                id={du.department_user_id}
                className={classes.modalTableRow}
              >
                <TableCell component="th" scope="row" className={classes.modalTableCell}>
                  {du.program_name}
                </TableCell>
                <TableCell align="center" className={classes.modalTableCell}>
                  {du.department_name}
                </TableCell>
                <TableCell align="center" className={classes.modalTableCell}>
                  {du.role_name}
                </TableCell>
                <TableCell align="center" className={classes.modalTableCell}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<RemoveIcon>Remove</RemoveIcon>}
                    onClick={() => removeDepartmentUserRecordBtn(du.department_user_id, index)}
                    fullWidth
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Typography variant="body2" className={classes.helperText}>
        * This fields are reqiured
      </Typography>
      <Box marginTop="32px">
        <Typography variant="body1" className={classes.selectPrimaryText}>
          Choose primary department:
        </Typography>
        <Grid container className={classes.selectPrimaryContainer}>
          <TextField
            label="Department"
            select
            variant="outlined"
            size="small"
            value={primaryDepartmentId}
            onChange={(e) => setPrimaryDepartmentId(Number(e.target.value))}
            disabled={!departmentUsersData.length}
          >
            {departmentUsersData.map((department) => (
              <MenuItem key={department.department_id} value={department.department_id}>
                {department.department_name}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            color="primary"
            onClick={changePrimaryDepartment}
            disabled={!primaryDepartmentId}
          >
            Submit
          </Button>
        </Grid>
        {showUserError && (
          <Typography color="error" align="center" style={{ marginTop: "8px" }}>
            Complete the User Info in the ADD USER tab
          </Typography>
        )}
      </Box>
    </>
  );
};
