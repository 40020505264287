import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  CONNECT_VIRTUAL_VISIT,
  TOGGLE_QUEUE_TAB,
} from "components/Video/hooks/useVirtualVisitReducer/Actions";
import CellPhone from "components/shared/CellPhone";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { WaitingRoomTab } from "util/constants";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { useAuth } from "util/Security";
import { AXIOS_OPTIONS, UserTypes, axiosClient, getApiHost } from "util/api_helper";
import { isMobile } from "util/deviceUtils";
import { GetCurrentProgram, useProgramType } from "util/programsHelpers";
import { validateEmail } from "util/util_functions";
import { useStyles } from "./VideoVisitInvite.styles";
import share from "static/svg/share.svg";

export default function VideoVisitInvite({
  defaultCellPhone = "",
  handleClose,
  isVirtualVisitCreated = false,
  isAdhocSession = false,
}) {
  const { classes, cx } = useStyles();
  const { user } = useAuth();
  const app = useApp();
  const currentProgram = GetCurrentProgram();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const { isAdhoc, isVUC } = useProgramType(currentProgram);
  const { vsId } = useParams();
  const queryClient = useQueryClient();
  const { programId } = usePrograms();
  const vsIdQuery = params.get("vsId");
  const checkedCellPhone = "cellPhone";
  const checkedEmail = "email";

  const [cellPhone, setCellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [conferencePhone, setConferencePhone] = useState("");
  const [conferenceCode, setConferenceCode] = useState("");
  const [checked, setChecked] = useState(checkedCellPhone);
  const [skipRegistration, setSkipRegistration] = useState();
  const [virtualSession, setVirtualSession] = useState();
  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const apiHost = getApiHost();

  const isPatient = user.userType === UserTypes.PATIENT || user.userType === UserTypes.CONSUMER;
  const defaultMessage = !isAdhoc
    ? "Check in for your Atlantic Virtual Urgent Care visit. "
    : `Join your virtual call with Dr. ${user.firstName} ${user.lastName}. `;

  const [message, setMessage] = useState(defaultMessage);

  const isEmailEmptyButChecked = !email && checked === checkedEmail;
  const isCellPhoneButChecked = !cellPhone && checked === checkedCellPhone;

  const showSkipRegistration = !isVirtualVisitCreated && isVUC;

  const loadInvitedUser = async () => {
    const url = `${apiHost}/api/s/console/user/${virtualSession.patient_id}`;

    try {
      const resp = await axios.get(url, AXIOS_OPTIONS);

      setCellPhone(resp?.data?.data.cell_phone || "");
      setEmail(resp?.data?.data.email || "");
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  const getVirtualSession = async () => {
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.twilio_get_virtual_session, {
        vs_id: vsId,
      });

      setVirtualSession(data);
    } catch (error) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    if (vsId) {
      getVirtualSession();
    } else {
      setVirtualSession(app.vs_visits_state.currentVirtualVisit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId, app.vs_visits_state.currentVirtualVisit]);

  useEffect(() => {
    if (!isVirtualVisitCreated) {
      setCellPhone(defaultCellPhone);
    } else if (
      virtualSession?.currently_assigned_staff_user_id &&
      virtualSession?.patient_id &&
      !isPatient
    ) {
      loadInvitedUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualSession?.patient_id, virtualSession?.currently_assigned_staff_user_id]);

  const handleConferenceCall = async () => {
    if (!cellPhone || cellPhone.length < 10) {
      app.addError(
        "Oops, your number format isn't correct.  Please enter a 10-digit only cell phone number.",
      );
      return;
    }

    const cellPhoneParsed = cellPhone.replace(/\D/g, "");
    if (
      cellPhoneParsed.length > 0 &&
      (cellPhoneParsed.length < 10 || cellPhoneParsed.length > 14)
    ) {
      app.addError(
        "Oops, your number format isn't correct.  Please enter a 10-digit only cell phone number.",
      );
      return;
    }
    try {
      const apiUrl = "/api/s/console/virtualvisit/invitebycall";
      await axiosClient.post(apiUrl, {
        cell_phone: cellPhoneParsed,
        virtual_session_id: virtualSession?.virtual_session_id,
        program: virtualSession?.program?.name,
      });
      app.addInfoMsg(
        "Succcessfully iniated outbound call to user. They will join shortly if they accept.",
      );
      handleClose();
    } catch {
      app.addError(
        "Failed to conference call user in for virtual session, please contact support.",
      );
    }
  };

  const handleInviteSend = async (e) => {
    e.preventDefault();

    if (!!cellPhone && cellPhone.length < 10 && checked === checkedCellPhone) {
      app.addError(
        "Oops, your number format isn't correct. Please enter a 10-digit only cell phone number.",
      );
      return;
    }

    if (checked === checkedEmail && !validateEmail(email)) {
      app.addError("Please enter a valid email.");
      return;
    }
    let vsId;
    setIsSendingInvite(true);
    try {
      if (checked === checkedCellPhone) {
        if (!isVirtualVisitCreated) {
          const url = `${apiHost}${API_ENDPOINTS.send_virtual_visit_invite_by_cell_and_create_vs}`;

          const { data } = await axios.post(
            url,
            {
              cell_phone: cellPhone,
              custom_message: message,
              program: currentProgram?.name,
              skipRegistration,
            },
            AXIOS_OPTIONS,
          );
          await queryClient.invalidateQueries("fetch-awaiting-vs-visit", programId);

          vsId = data?.data?.virtual_session_id;
        } else {
          const url = `${apiHost}${API_ENDPOINTS.send_virtual_visit_invite_by_cell}`;
          const data = {
            cell_phone: isAdhocSession ? defaultCellPhone : cellPhone,
            virtual_session_id: vsIdQuery || virtualSession?.virtual_session_id,
            custom_message: message,
          };
          if (isAdhocSession) {
            data.other_cell_phone = cellPhone;
          }
          await axios.post(url, data, AXIOS_OPTIONS);
          vsId = virtualSession?.virtual_session_id || vsIdQuery;
        }
      }

      if (checked === checkedEmail) {
        const url = `${apiHost}${API_ENDPOINTS.send_virtual_visit_invite_by_email}`;

        if (!isVirtualVisitCreated) {
          await axios.post(
            url,
            {
              email,
              custom_message: message,
              program: currentProgram?.name,
              skipRegistration,
            },
            AXIOS_OPTIONS,
          );
        } else {
          await axios.post(
            url,
            {
              email,
              custom_message: message,
              virtual_session_id: vsIdQuery || virtualSession?.virtual_session_id,
            },
            AXIOS_OPTIONS,
          );
        }
      }

      app.addInfoMsg("Succcessfully sent invite.");
      setIsSendingInvite(false);
      handleClose();
      if (isAdhoc) {
        params.set("vsId", vsId);
        app.dispatch({
          type: CONNECT_VIRTUAL_VISIT,
          payload: Number(vsId),
        });
        if (isMobile) {
          app.dispatch({
            type: TOGGLE_QUEUE_TAB,
            payload: WaitingRoomTab.PATIENT,
          });
        }
      }
      navigate({ search: params.toString() });
    } catch (e) {
      console.log(`Problem sending vs invite ${e}`);
      app.addError("Problem sending invite.");
      setIsSendingInvite(false);
    }
  };

  useEffect(() => {
    const loadConferenceCallDetails = async () => {
      try {
        const apiUrl = "/api/s/virtualvisit/conferencecalldetails";
        const { data } = await axiosClient.get(apiUrl, {
          params: { virtual_session_id: virtualSession?.virtual_session_id },
        });
        setConferencePhone(data.data.conferenceNumber);
        setConferenceCode(data.data.conferenceCode);
      } catch {
        console.log("Failed to get virtual session conference details, please try again.");
      }
    };
    if (virtualSession?.virtual_session_id) {
      loadConferenceCallDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualSession?.virtual_session_id]);

  return (
    <form onSubmit={handleInviteSend} className={classes.root}>
      {conferencePhone && conferencePhone.length > 0 && (
        <h2 className={classes.conferenceText}>
          Conference Dial-in: {conferencePhone}, code: {conferenceCode}
        </h2>
      )}
      <p className={classes.subtitle}>
        Enter a cell phone number and that user will get a SMS text message link sent to them with
        an invite.
      </p>
      <RadioGroup value={checked} onChange={(e) => setChecked(e.target.value)}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          wrap="wrap"
          className={cx({
            [classes.radioGroup]: !showSkipRegistration,
            [classes.radioGroupWithSkip]: showSkipRegistration,
          })}
        >
          <FormControlLabel
            control={<Radio color="primary" />}
            value={checkedCellPhone}
            label="Cell Phone"
            className={cx({
              [classes.radioGroupLabelWithSkip]: showSkipRegistration,
            })}
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            value={checkedEmail}
            label="Email"
            className={cx({
              [classes.radioGroupLabelWithSkip]: showSkipRegistration,
            })}
          />
        </Grid>
      </RadioGroup>
      {showSkipRegistration && (
        <Grid container alignItems="center" justifyContent="center" className={classes.root}>
          <Box display="flex" alignItems="center" className={classes.skipRegistrationCheckbox}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setSkipRegistration(!skipRegistration)}
                  checked={skipRegistration}
                  title="Patient will receive a link that will push them right to the consent step."
                  data-testid="send-invite-skip-registration"
                />
              }
              label="Skip Registration"
            />
          </Box>
        </Grid>
      )}
      <CellPhone
        className={classes.textField}
        cellPhone={cellPhone}
        setCellPhone={setCellPhone}
        disabled={checked === checkedEmail}
      />
      <TextField
        id="emailAddress"
        label="Email Address"
        variant="outlined"
        className={classes.textField}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={checked === checkedCellPhone}
      />
      <TextField
        label="Message"
        classes={{ root: classes.message }}
        variant="outlined"
        multiline
        minRows={3}
        maxRows={5}
        className={classes.textField}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className={classes.buttonsWrapper}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={isSendingInvite || isEmailEmptyButChecked || isCellPhoneButChecked}
          data-testid="send-invite"
        >
          {isSendingInvite ? (
            <CircularProgress size={24} className={classes.fileButtonLoadingSpinner} />
          ) : (
            <img src={share} alt="" className={classes.btnIcon} />
          )}
          <span>Send Invite</span>
        </Button>
        {isVirtualVisitCreated && (
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={handleConferenceCall}
            className={classes.button}
            disabled={!cellPhone || !virtualSession?.virtual_session_id}
          >
            Conference Call
          </Button>
        )}
      </div>
    </form>
  );
}
