/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer } from "react";
import { SET_SOUND_ENABLE_DISABLE } from "./actions";

interface State {
  enabled: boolean;
}

interface Action {
  type: string;
  payload: any;
}

const initialState: State = {
  enabled: true,
};

const PlaySoundsReducer = (state: State, { type, payload }: Action): State => {
  if (type === SET_SOUND_ENABLE_DISABLE) {
    return { ...state, enabled: payload };
  }

  return { ...state };
};

export const usePlaySoundsReducer = () => {
  return useReducer(PlaySoundsReducer, initialState);
};
