import { makeStyles } from "tss-react/mui";

export const useConversationStyles = makeStyles()((theme) => ({
  buttonContainer: {
    marginTop: "10px",
    paddingLeft: "20px",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  container: {
    boxSizing: "border-box",
    width: "100%",
    height: `calc(100vh - ${theme.headerHeight})`,
  },
  header: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: "100%",
    boxSizing: "border-box",
    padding: "15px",
  },
  backBtn: {
    border: "none",
    "&.MuiButton-root": {
      minWidth: "20px",
    },
  },
  messageContainer: {
    width: "90%",
    margin: "auto",
    background: theme.palette.common.white,
    boxSizing: "border-box",
  },
  message: {
    padding: "20px",
  },
  messageTime: {
    fontSize: "12px",
  },
  arrow: {
    color: theme.palette.common.white,
    marginTop: "-5px",
  },
  title: {
    fontWeight: 500,
    fontSize: "20px",
    margin: "10px 0px",
    textAlign: "left",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  queueContainer: {
    overflowY: "scroll",
    flexGrow: 1,
    "&.MuiList-padding": {
      paddingTop: "0px",
    },
  },
  queueContainerNativeApp: {
    height: "calc(100vh - 10px)",
  },
  leftView: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "0px !important",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  queueHeader: {
    height: "60px",
    paddingLeft: "20px",
    paddingTop: "15px",
  },
  noConversation: {
    textAlign: "left",
    paddingLeft: "20px",
  },
  noConversationTitle: {
    fontSize: "16px",
    fontWeight: 700,
  },
  noConversationText: {
    fontSize: "16px",
    color: "#757575",
  },
}));
