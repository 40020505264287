export const rowsProviders = [
  {
    name: "John Doe",
    npi: 1234567890,
    providerType: "Physician",
    specialty: "Cardiology",
    practiceGroups: "Group A",
  },
  {
    name: "Jane Smith",
    npi: 9876543210,
    providerType: "Physician Assistant",
    specialty: "Orthopedics",
    practiceGroups: "Group B",
  },
  {
    name: "Robert Brown",
    npi: 1122334455,
    providerType: "Nurse Practitioner",
    specialty: "Pediatrics",
    practiceGroups: "Group A",
  },
  {
    name: "Emily White",
    npi: 5566778899,
    providerType: "Physician",
    specialty: "Dermatology",
    practiceGroups: "Group C",
  },
  {
    name: "Michael Green",
    npi: 9988776655,
    providerType: "Physician",
    specialty: "Neurology",
    practiceGroups: "Group D",
  },
  {
    name: "Sarah Blue",
    npi: 2233445566,
    providerType: "Nurse",
    specialty: "Oncology",
    practiceGroups: "Group E",
  },
];

export const rowsLocations = [
  {
    id: "LOC001",
    type: "Warehouse",
    feedType: "Standard",
    comercialEntity: "Entity A",
    address: "1234 Elm St, Springfield",
    name: "Springfield Warehouse",
  },
  {
    id: "LOC002",
    type: "Store",
    feedType: "Premium",
    comercialEntity: "Entity B",
    address: "5678 Oak St, Shelbyville",
    name: "Shelbyville Store",
  },
  {
    id: "LOC003",
    type: "Office",
    feedType: "Standard",
    comercialEntity: "Entity C",
    address: "9101 Pine St, Capital City",
    name: "Capital City Office",
  },
  {
    id: "LOC004",
    type: "Warehouse",
    feedType: "Standard",
    comercialEntity: "Entity D",
    address: "1123 Maple St, Ogdenville",
    name: "Ogdenville Warehouse",
  },
  {
    id: "LOC005",
    type: "Store",
    feedType: "Basic",
    comercialEntity: "Entity E",
    address: "1415 Birch St, North Haverbrook",
    name: "North Haverbrook Store",
  },
  {
    id: "LOC006",
    type: "Office",
    feedType: "Premium",
    comercialEntity: "Entity F",
    address: "1617 Cedar St, Brockway",
    name: "Brockway Office",
  },
];

export const rowsChangeLog = [
  {
    id: "LOG1",
    dateAndTime: "2023-06-01 10:15:00",
    type: "Update",
    entityType: "Product",
    editedPage: "Product Details",
    entityId: "PROD001",
    user: "John Doe",
    name: "LOG1",
  },
  {
    id: "LOG2",
    dateAndTime: "2023-06-02 12:30:00",
    type: "Delete",
    entityType: "User",
    editedPage: "User Management",
    entityId: "USR002",
    user: "Jane Smith",
    name: "LOG2",
  },
  {
    id: "LOG3",
    dateAndTime: "2023-06-03 14:45:00",
    type: "Create",
    entityType: "Order",
    editedPage: "Order Entry",
    entityId: "ORD003",
    user: "Alice Johnson",
    name: "LOG3",
  },
  {
    id: "LOG4",
    dateAndTime: "2023-06-04 16:00:00",
    type: "Update",
    entityType: "Customer",
    editedPage: "Customer Details",
    entityId: "CUST004",
    user: "Bob Brown",
    name: "LOG4",
  },
  {
    id: "LOG5",
    dateAndTime: "2023-06-05 18:15:00",
    type: "Create",
    entityType: "Inventory",
    editedPage: "Inventory Management",
    entityId: "INV005",
    user: "Charlie Davis",
    name: "LOG5",
  },
  {
    id: "LOG6",
    dateAndTime: "2023-06-06 20:30:00",
    type: "Delete",
    entityType: "Supplier",
    editedPage: "Supplier List",
    entityId: "SUP006",
    user: "Diana Evans",
    name: "LOG6",
  },
];
