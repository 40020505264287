/** @format */
import { makeStyles } from "tss-react/mui";

export const makeTabStyles = makeStyles()(() => ({
  tabRoot: {
    "& .MuiTabs-scroller": {},
    "& .tabs": {},
    "& .MuiButtonBase-root": {
      "& .MuiTouchRipple-root": {},
    },
  },
  root: {
    flexGrow: 1,
  },
}));

export const useListStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 320,
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    border: "1px solid #ccc",
    borderBottom: "none",
    "&:first-child": {
      borderRadius: "5px 5px 0 0",
    },
    "&:last-child": {
      borderRadius: "0 0 5px 5px",
      //   backgroundColor: "yellow",
      borderBottom: "1px solid #888",
    },
  },
}));
