import * as Yup from "yup";
import { Tag } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/PatientProfile/Modals/components/OrderTags";
import { VirtualSessionQueue } from "util/constants";

export type FormValues = {
  tags: Tag[];
  notes: string;
  transferQueue: string;
};

export const initialValues: FormValues = {
  tags: [],
  notes: "",
  transferQueue: VirtualSessionQueue.REGISTERING,
};

export const validationSchema = Yup.object({
  transferQueue: Yup.string().required("This field is required."),
});
