import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { EditProvider } from "./components/EditProvider";
import { useIsMobile } from "util/deviceUtils";
import { makeNewReferalStyles } from "../NewReferral/styles";

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = ({ id, children, onClose, ...other }) => {
  const { classes } = useStyles();
  return (
    <MuiDialogTitle id={id} className={classes.root} {...other}>
      <b>{children}</b>
      {onClose && (
        <IconButton
          data-cy="closeModalButton"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

const TABS = {
  user: 0,
};

export default function EditProviderModal({ show, setShow, record, mode }) {
  const { classes } = makeNewReferalStyles();

  const [open, setOpen] = useState(false);

  const emptyRecord = {
    NPI: "",
    firstName: "",
    lastName: "",
    cellphone: "",
    id: -1,
    specialty: "",
    enabled: true,
  };
  const [providerRecord, setProviderRecord] = useState(emptyRecord);

  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
    setShow(false);
    setProviderRecord(emptyRecord);
  };

  const handleModalVisibility = () => {
    if (show) {
      setProviderRecord({ ...record });
      setOpen(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    handleModalVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      classes={{ paperWidthMd: classes.modalDialog }}
      fullScreen={isMobile}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        className={classes.modalDialogTitle}
      >
        <Tabs value={TABS.user} aria-label="createVirtualSessionOptions">
          <Tab label={`${mode === "add" ? "Add" : "Edit"} Provider`} />
        </Tabs>
      </DialogTitle>
      <MuiDialogContent dividers classes={{ root: classes.formContainer }}>
        <EditProvider handleClose={handleClose} mode={mode} record={providerRecord} />
      </MuiDialogContent>
    </Dialog>
  );
}
