/* eslint-disable */
import React, { createContext, useEffect, useRef, useState } from "react";
import { Client, Conversation, Message } from "@twilio/conversations";
import useVideoContext from "components/Video/hooks/useVideoContext/useVideoContext";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
  convRecvd: boolean;
  chatClient?: Client | null;
  loading: boolean;
  setLoading: (value: boolean) => void;
  setVsId: (vsId: string) => void;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider = ({ children }) => {
  const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const {
    vs_visits_state,
    vs_visits_state: { onlyConversation },
  } = useApp();
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client | null>(null);
  const [convRecvd, setConvRecvd] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const auth = useAuth();
  const isConsumer = auth?.user?.userType === "consumer";
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile();
  const [vsId, setVsId] = useState<string>();

  useEffect(() => {
    setVsId(vs_visits_state?.currentVirtualVisit?.id || vsId);
  }, [vsId, vs_visits_state?.currentVirtualVisit?.id]);

  useEffect(() => {
    if (isMobile) {
      setIsChatWindowOpen(false);
    }
    if (!isMobile) {
      setIsChatWindowOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const connect = (token: string) => {
    setLoading(true);
    Client.create(token)
      .then((client) => {
        setChatClient(client);
      })
      .catch(() => {
        setLoading(false);
        onError(new Error("There was a problem connecting to Twilio's conversation service."));
      });
  };

  useEffect(() => {
    if (onlyConversation || !isMobile) {
      setIsChatWindowOpen(true);
    }
  }, [onlyConversation, isMobile]);

  const handleGetConversations = async () => {
    try {
      let newConversation;
      if (!room && vsId) {
        const url = isConsumer
          ? `/api/s/virtualvisit/sessions/${vsId}/conversation-id`
          : `/api/s/console/virtualvisit/sessions/${vsId}/conversation-id`;
        const resp = await axiosClient.get(url);
        newConversation = await chatClient?.getConversationByUniqueName(resp?.data?.conversationId);
      }
      if (room) {
        newConversation = await chatClient?.getConversationByUniqueName(room?.sid);
      }
      setConversation(newConversation);
      setConvRecvd(true);
      setLoading(false);
    } catch (error) {
      onError(new Error("There was a problem getting the Conversation associated with this room."));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) =>
        setMessages((oldMessages) => [...oldMessages, message]);
      conversation.getMessages().then((newMessages) => setMessages(newMessages.items));
      conversation.on("messageAdded", handleMessageAdded);
      return () => {
        conversation.off("messageAdded", handleMessageAdded);
        setMessages([]);
      };
    }
  }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) {
      setHasUnreadMessages(false);
    }
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (chatClient?.connectionState === "connected") {
      handleGetConversations();
    }

    if (chatClient?.connectionState === "disconnected") {
      setConvRecvd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatClient, onError, chatClient?.connectionState, room]);

  useEffect(() => {
    setConvRecvd(false);
  }, [room]);

  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen,
        setIsChatWindowOpen,
        connect,
        hasUnreadMessages,
        messages,
        conversation,
        convRecvd,
        chatClient,
        setLoading,
        loading,
        setVsId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
