import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import RequestDetails from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/RequestDetails";
import StaffNotes from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/StaffNotes";
import "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/stylees.css";
import { useVisitDetails } from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/styles";
import useTranslation from "hooks/useTranslation";

const VisitDetails = ({ currentVirtualVisit, profile, handleClose, isEditable = true }) => {
  const { classes } = useVisitDetails();

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const {
    web: {
      visitDetails: { tabRequestDetails, tabStaffNotes },
    },
  } = useTranslation();

  return (
    <div>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
        indicatorColor="primary"
        textColor="primary"
        classes={{
          indicator: classes.indicator,
          root: classes.tabsRoot,
        }}
      >
        <Tab
          label={tabRequestDetails}
          classes={{
            selected: classes.tabSelected,
            root: classes.tabRoot,
            textColorPrimary: classes.textColorPrimary,
          }}
        />
        <Tab
          label={tabStaffNotes}
          classes={{
            selected: classes.tabSelected,
            root: classes.tabRoot,
            textColorPrimary: classes.textColorPrimary,
          }}
        />
      </Tabs>
      <div className={classes.contentContainer}>
        {tab === 0 && (
          <RequestDetails
            currentVirtualVisit={currentVirtualVisit}
            profile={profile}
            handleClose={handleClose}
          />
        )}
        {tab === 1 && (
          <StaffNotes virtualSessionId={currentVirtualVisit.id} isEditable={isEditable} />
        )}
      </div>
    </div>
  );
};

export default VisitDetails;
