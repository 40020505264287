import React, { useEffect, useState } from "react";
import BackArrow from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import NoPatientFound from "./NoPatientFound";
import PatientForm from "./PatientForm";
import PatientList from "./PatientList";
import PatientSearchForm from "./PatientSearchForm";
import SearchComponent from "./SearchComponent";
import { formatISO, isValid } from "date-fns";
import Loader from "components/Loader";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { usePatientSearchStyles } from "./styles";

function noop() {
  return undefined;
}

const ReplaceThisPatientSearch = ({
  firstName,
  lastName,
  dob,
  zip,
  cellPhone,
  gender,
  setZip,
  setDob,
  setFirstName,
  setLastName,
  setCellPhone,
  MRN,
  setMRN,
  redirectAfterCreated,
  getPatientProfile,
  currentVirtualVisit,
  onConfirmMatch,
  isFormDisabled,
  ConfirmBtnTitle,
  noBorder,
  headerExists,
  vvId,
  isConfirmLoading = false,
  searchTitle = "Patient Search",
  searchDescription,
}) => {
  const app = useApp();
  const { classes } = usePatientSearchStyles({ noBorder });
  const [patients, setPatients] = useState([]);
  const [searchedPatients, setSearchedPatients] = useState(false);
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const myRegEx = /\/console\/chat-agent-dashboard\/(.*)/g;
  const match = myRegEx.exec(window.location.pathname);

  let patientIdFound = false;
  if (match && match[1] && match[1].length > 2) {
    patientIdFound = true;
  }

  const isInValidDob = (dob) => {
    return dob && dob instanceof Date && isValid(dob) ? dob.getTime() > new Date().getTime() : true;
  };

  useEffect(() => {
    if (MRN) {
      setIsSearchButtonDisabled(false);
      return;
    }

    if (lastName && dob) {
      setIsSearchButtonDisabled(isInValidDob(dob));
      return;
    }

    setIsSearchButtonDisabled(true);
  }, [MRN, lastName, dob, cellPhone]);

  const handleSearch = async () => {
    if (
      (!MRN || MRN?.length < 1) &&
      (!lastName || lastName?.length < 1) &&
      (!dob || dob?.length < 1)
    ) {
      app.addError("MRN or lastName, date of birth required for search");
      return;
    }

    if (cellPhone.length && cellPhone.length !== 10) {
      app.addError("Phone number must be 10 digit");
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await axiosClient.post(API_ENDPOINTS.search_patient, {
        first_name: firstName || null,
        last_name: lastName || null,
        dob: dob instanceof Date ? formatISO(dob, { representation: "date" }) : null,
        zip: zip || null,
        cell_phone: cellPhone || null,
        emr_mrn: MRN || null,
      });
      setIsLoading(false);
      if (data.data) {
        setPatients(data.data);
      }
      setSearchedPatients(true);
    } catch (error) {
      setIsLoading(false);
      setSearchedPatients(true);
      app.addError("Problem searching patients.");
    }
  };

  const showForm = () => {
    setSearchedPatients(false);
    setShowPatientForm(true);
  };

  const closePatientForm = () => {
    setSearchedPatients(false);
    setShowPatientForm(false);
  };

  useEffect(() => {
    return () => {
      setSearchedPatients(false);
      setShowPatientForm(false);
    };
  }, [vvId]);

  return !isLoading ? (
    searchedPatients || patientIdFound ? (
      (patients && patients.length > 0) || patientIdFound ? (
        <div className={classes.patientsListContainer}>
          <div className={classes.goBackBtnContainer}>
            <Button
              variant="text"
              size="medium"
              color="primary"
              startIcon={<BackArrow />}
              onClick={() => setSearchedPatients(false)}
              disabled={isConfirmLoading}
            >
              Go Back
            </Button>
          </div>
          <PatientList
            patients={patients}
            showForm={showForm}
            ConfirmBtnTitle={ConfirmBtnTitle}
            onConfirmMatchButtonClicked={onConfirmMatch}
            isConfirmLoading={isConfirmLoading}
          />
        </div>
      ) : (
        <div className={classes.patientsListContainer}>
          <div className={classes.goBackBtnContainer}>
            <Button
              variant="text"
              size="medium"
              color="primary"
              startIcon={<BackArrow />}
              onClick={() => setSearchedPatients(false)}
              disabled={isConfirmLoading}
            >
              Go Back
            </Button>
          </div>
          <NoPatientFound showForm={showForm} />
        </div>
      )
    ) : showPatientForm ? (
      <PatientForm
        firstNameDefault={firstName}
        lastNameDefault={lastName}
        dobDefault={dob}
        zipDefault={zip}
        cellPhoneDefault={cellPhone}
        genderDefault={gender}
        handleCancelPatientFormBtn={closePatientForm}
        redirectAfterCreated={redirectAfterCreated}
        getPatientProfile={getPatientProfile}
        currentVirtualVisit={currentVirtualVisit}
      />
    ) : (
      <div className={classes.patientSearchContainer}>
        <PatientSearchForm
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          zip={zip}
          cellPhone={cellPhone}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setDob={setDob}
          setZip={setZip}
          setCellPhone={setCellPhone}
          MRN={MRN}
          setMRN={setMRN}
          headerExists={headerExists}
          isFormDisabled={isFormDisabled}
          searchTitle={searchTitle}
          searchDescription={searchDescription}
        />
        {isFormDisabled || (
          <SearchComponent handleSearch={handleSearch} disabled={isSearchButtonDisabled} />
        )}
      </div>
    )
  ) : (
    <div className={classes.loaderContainer}>
      <Loader />
    </div>
  );
};

// this PatientSearch is probably the least logical react component I've ever
// seen. But I can't spare the time to replace it, and fixing it would take
// even longer, so I've done the worst thing I can do: band-aided together a
// wrapper component with a signature that isn't stupid.
const SelfContainedPatientSearch = (props) => {
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    dob: undefined,
    zip: "",
    cellPhone: "",
    MRN: "",
  });

  const changeField = (field) => (value) => setDetails((prev) => ({ ...prev, [field]: value }));

  const searchProps = { ...details, ...props };

  return (
    <ReplaceThisPatientSearch
      setZip={changeField("zip")}
      setDob={changeField("dob")}
      setFirstName={changeField("firstName")}
      setLastName={changeField("lastName")}
      setCellPhone={changeField("cellPhone")}
      setMRN={changeField("MRN")}
      /* details */

      redirectAfterCreated={false}
      getPatientProfile={noop}
      currentVirtualVisit={undefined}
      ConfirmBtnTitle="Confirm"
      vvId={0}
      isConfirmLoading={false}
      searchTitle="Patient Search"
      searchDescription="Search for a patient by MRN, last name, date of birth, or phone number"
      {...searchProps}
    />
  );
};

export { SelfContainedPatientSearch };

export default ReplaceThisPatientSearch;
