export const API_ENDPOINTS = {
  Twilio_Physician_Video_Join: "api/s/console/virtualvisit/join_video",
  QR_code: "api/s/generate-qrcode",
  QR_code_Verificaiton: "api/verifycredential",
  passport_number: "api/s/passport-number",
  vouch_verified_get: "api/s/vouched-id-user-data",
  search_patient: "api/s/console/patient/search",
  confirm_match_patient: "api/s/console/patient/confirm_match",
  fetch_single_patient: "/api/s/console/patient/single",
  fetch_staff_medication_statement: "/api/s/console/patient/medication-statement",
  fetch_quick_phrases: "api/s/console/virtualvisit/quick_phrases/list-by-context",
  program_basics: "/api/config/program_basics",
  programs_consumer: "/api/config/programs",
  get_patient_photo: "/api/s/console/patient/photo?patient_id=",
  Twilio_Video_start: `/api/s/virtualvisit/start_video`,
  Twilio_Video_join: `/api/s/virtualvisit/join_video`,
  twilio_start_virtual_session: "/api/s/virtualvisit/video/start/virtual-session",
  twilio_set_visit_reason: "/api/s/virtualvisit/reason-for-visit",
  twilio_set_visit_insurance_details: "/api/s/virtualvisit/insurance-card-manual",
  twilio_get_virtual_session: "/api/s/virtualvisit/video/get/virtual-session",
  twilio_get_estimated_time: "/api/s/virtualvisit/estimate-wait-time",
  twilio_get_sync_token: "/api/s/twilio/sync/token",
  update_user_info: "/api/s/user/update-user-info",
  create_vs_by_staff: "api/s/console/virtualvisit/create-virtual-session-for-staff",
  verify_cellphone: "api/s/console/virtualvisit/check-cell-phone-duplicate",
  transfer_queue: "api/s/console/virtualvisitconsole/transfer-queue",
  end_session_by_staff: "api/s/virtualvisit/end_video",
  get_programs_list: "/api/s/console/config/programs",
  get_departments_list: "/api/s/console/config/departments",
  get_roles_list: "/api/s/console/user/role-list",
  get_department_users_data: "/api/s/console/config/department_users",
  get_user_departments: "/api/s/users/me/departments",
  get_staff_list: "/api/s/console/virtualvisitconsole/get-users",
  get_awaiting_users_list: "/api/s/console/virtualvisit/awaiting_users",
  get_providers_list: "/api/s/console/referral/get-providers",
  get_specialty: "/api/s/console/referral/get-specialties",
  cellphone_verify: "api/s/cellphoneverify",
  add_new_department_user_record: "/api/s/console/config/add-new-department-user-record",
  remove_department_user_record: "/api/s/console/config/remove-department-user-record",
  get_staff_report: "/api/s/console/user/list",
  send_email_from_staff_report: "api/s/console/comms/sendemail",
  get_assigned_patients: "/api/s/console/patient/assigned-list",
  send_staff_message: "/api/s/console/virtualvisit/send_sms_msg",
  update_virtual_session: "/api/s/virtualvisit/update-virtual-session",
  add_consent: "/api/s/virtualvisit/add-consent",
  context_aware_linking_add_consent: "/api/dfd/consent/add-consent",
  programs: "/api/config/programs",
  send_virtual_visit_invite_by_cell: "/api/s/virtualvisit/invitebycell",
  send_virtual_visit_invite_by_cell_and_create_vs: "/api/s/console/virtualvisit/invitebycell",
  fetch_pateint_sessions: "api/s/virtualvisit/load_sessions",
  send_virtual_visit_invite_by_email: "/api/s/virtualvisit/send-email-invite",
  fetch_patient_messags: "/api/s/virtualvisit/load_messages",
  provider_celllphone: "/api/s/console/provider-cellphone",
  provider_survey: "/api/s/console/provider-survey",
  survey_config: "/api/s/console/survey-config",
  survey_config_dropdown_options: "/api/s/console/survey-config/dropdown-options",
  campaigns: "/api/s/console/config/campaigns",
  campaign_load: "/api/s/console/patient/campaign_load",
  campaignTags: "/api/s/console/patient/submission/tags",
  campaign_permissions: "/api/s/console/config/campaigns/permissions",
  consent_submit_sms: "/api/s/console/context-aware-linking/consent/submit",
  consent_submit_sms_resend: "/api/s/console/context-aware-linking/consent/resend",
  mobile_hospitals: "/api/aem-locations/mobile/hospitals",
  mobile_urgentcare: "/api/aem-locations/mobile/urgentcare",
  aem_locations: "/api/aem-locations",
  askAIssistant: "api/s/console/virtualvisit/sessions/{currentVirtualVisit}/my-aissistant/ask",
  askAIssistant_with_prompt: "api/s/console/my-aissistant/ask",
  getAIssistantQuickPhrases:
    "/api/s/console/virtualvisit/quick_phrases/list-by-context?context=MyAIssistant",
  getCampaignCallbackNumber: "/api/s/formio/form-submitted/campaign-callback-number",
  validateCaptcha: "/api/recaptcha/validate",
  pediatricEocSurvey: "/api/quick-access-survey/pediatric-endocrinology-survey/engage-survey",
  websiteEngageQuickAnswerSurvey: "/api/quick-access-survey/website-survey/engage-survey",
  engageQuickAnswerSurvey: "/api/quick-access-survey/engage-survey",
  campaignManagementNotifications:
    "/api/s/console/notification?type=CAMPAIGN_ERROR&status=UNREAD&page_size=20&page_num=0&order=desc&order_by=status",
  campaignManagementNotificationsMarkAsRead: "/api/s/console/notification",
  specimenTrackingLocations: "/api/s/console/specimen-tracking/locations",
  specimenTrackingLocationsCreate: "/api/s/console/specimen-tracking/locations/create",
  specimenTrackingLocationsDownloadQR: "/api/s/console/specimen-tracking/locations/generate_qr",
  specimenTrackingAddSpecimenManually: "/api/s/console/specimen-tracking/create-specimen-manual",
  specimenTrackingGetSpecimens: "/api/s/console/specimen-tracking/specimens",
  specimenTrackingDeleteSpecimen: "/api/s/console/specimen-tracking/specimens",
  specimenTrackingDeleteReason: "/api/s/console/specimen-tracking/specimen-deletion-reasons",
  specimenTrackingGetHistory: "/api/s/console/specimen-tracking/history",
  specimenTrackingAddSpecimenScanner: "/api/s/console/specimen-tracking/create-specimen",
  specimenTrackingAddSpecimenWithAdapter:
    "/api/s/console/specimen-tracking/create-specimen-with-adapter",
  specimenTrackingSpecimenConfirm: "/api/s/console/specimen-tracking/collect",
  specimenTrackingGetLast: "/api/s/console/specimen-tracking/last",
  specimenTrackingUpdateSpecimen: "/api/s/console/specimen-tracking/specimens",
  specimenTrackingDashboardList: "/api/s/console/reports/specimen-tracking/dashboard/list",
  specimenTrackingDashboardCsv: "/api/s/console/reports/specimen-tracking/dashboard/csv",
  specimentTrackingGetOrder: "/api/s/console/specimen-tracking/orders",
  specimenTrackingOrderList: "/api/s/console/reports/specimen-tracking/order/list",
  specimenTrackingOrderCsv: "/api/s/console/reports/specimen-tracking/order/csv",
  specimenTrackingAddSpecimenWithIds: "/api/s/console/specimen-tracking/create-specimen-with-ids",
  specimenTrackingAddSpecimenPhoto: "api/s/console/specimen-tracking/specimens/add-specimen-photo",
  specimenTrackingSendReportedIssueEmail: "/api/s/console/specimen-tracking/send-email",
  digitalImplants: "/api/s/digital-implant",
  digitalImplantAppleWallet: "/api/s/digital-implant",
  referralEpicGetReferrals: "/api/s/console/referral/emr/list",
  referralEpicGetCsv: "/api/s/console/referral/emr/list/csv",
  dotReadDriverLicense: "/api/s/dot-form/analyze-driver-license",
  dotSignatureUpload: "/api/s/dot-form/upload-signature",
  dotGetPatientFormId: "/api/s/dot-form/get-latest-dot-form",
  loginMsal: "/api/msal-auth/verifytoken",
  dotMedicalExaminers: "/api/s/console/dot-form/medical-examiner",
  dotGetProviderByNpi: "/api/s/console/dot-form/provider", // ?npi=1234567890
  dotPatchMedicalDetermination: "/api/s/console/dot-form/determination",
  dotPatchSteps: "/api/s/dot-form/",
  dotPatchStepsStaff: "/api/s/console/dot-form/",
  medicalExaminerPDF: "/api/s/console/dot-form/medical-determination-pdf/",
  dotGetAppointments: "api/s/console/dot-form/appointments",
  dotCheckAppointments: "/api/s/console/dot-form/check-appointment",
  dotUpdateAppointment: "/api/s/console/dot-form/update-appointment",
  dataCurationProviders: "/api/s/console/data-governance/provider/",
  dataCurationLocations: "/api/s/console/location",
  dataCurationLocationExport: "/api/s/console/location/export",
  dataCurationProviderExport: "/api/s/console/data-governance/provider/export",
  adjustedHoursDelete: "/api/s/adjusted-hours",
  dataCurationGetVisitTypes: "/api/s/console/data-governance/visit-types",
  dataCurationNetworkAffiliations: "/api/s/console/data-governance/network-affiliations",
  dataCurationCredentialedFacilities: "/api/s/console/data-governance/institutions",
  dataCurationLocaitonTypes: "/api/s/console/location/types",
  dataCurationSpecialtyServices: "/api/s/console/data-governance/specialty-services",
  dataCurationClaimedSpecialties: "/api/s/console/data-governance/claimed-specialties",
  dataCurationConditionsAndSymptoms: "api/s/console/data-governance/conditions-symptoms",
};
