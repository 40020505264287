/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer } from "react";
import { RECEIVE_MESSAGE, REMOVE_MESSAGE } from "./actions";

interface State {
  message: {
    showNavbar?: boolean;
    msalToken?: string;
  };
}

interface Action {
  type: string;
  payload: any;
}

const initialState: State = {
  message: {
    showNavbar: undefined,
    msalToken: undefined,
  },
};

const mobileDFDReducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case RECEIVE_MESSAGE:
      return { ...state, message: payload };
    case REMOVE_MESSAGE:
      return {
        ...state,
        message: {
          showNavbar: undefined,
          msalToken: undefined,
        },
      };

    default:
      return { ...state };
  }
};

export const useMobileDFDReducer = () => {
  return useReducer(mobileDFDReducer, initialState);
};
