/** @format */

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import NoPatientFound from "./NoPatientFound";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useSinglePatientStyles } from "./styles";
import ProfileDefault from "static/images/sample_image_2.png";

const SinglePatient = () => {
  const { classes } = useSinglePatientStyles();
  const [patientPhoto, setPatientPhoto] = useState(null);
  const [patient, setPatient] = useState(null);
  const app = useApp();
  const { patientId } = useParams();

  useEffect(() => {
    const callApis = async () => {
      const r = await getSinglePatient(patientId);
      if (r) {
        console.log(`getSinglePatient${r}`);
        setPatient(r);
      }
      const p = await getPatientPhoto(patientId);
      if (p) {
        console.log(`setPatientPhoto${p}`);
        setPatientPhoto(p);
      }
    };
    if (patientId) {
      console.log(`Single patient ${patientId}`);
      callApis();
    } else {
      console.log("Single patient no patient id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSinglePatient = async (patient_id) => {
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.fetch_single_patient, { patient_id });
      return data?.data || null;
    } catch (error) {
      console.error(error);
      app.addError("Problem loading Patient Info.");
      return null;
    }
  };

  const getPatientPhoto = async (patient_id) => {
    try {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.get_patient_photo}${patient_id}`);
      return data?.photo || null;
    } catch (error) {
      app.addError("Problem loading Patient Photo Info.");
      console.error(error);
    }
  };

  return (
    <>
      {patient && (
        <div>
          <Card className={classes.root} style={{ marginBottom: "20px" }}>
            <CardActionArea>
              <div className={classes.media}>
                <Typography variant="h6" color="textSecondary" className={classes.heading}>
                  {patient.firstName} {patient.lastName}
                </Typography>
                {patient.MRN && (
                  <Typography variant="p">
                    <b>MRN:</b> {patient.MRN}
                  </Typography>
                )}
              </div>
              <CardContent className={classes.cardContent}>
                <div>
                  {patientPhoto && patientPhoto != null && (
                    <CardMedia
                      component="img"
                      alt="Mychart Profile Picture"
                      className={classes.img}
                      title="Mychart  Profile Picture"
                      src={`data:image/jpeg;base64,${patientPhoto}`}
                    />
                  )}
                  {!patientPhoto && (
                    <CardMedia
                      component="img"
                      alt="Default Profile Picture"
                      className={classes.img}
                      title="Default Profile Picture"
                      image={ProfileDefault}
                    />
                  )}
                </div>
                <div className={classes.cardContentDiv}>
                  <div className={classes.insideCards} style={{ width: "60%" }}>
                    {patient.address && (
                      <div>
                        <div>
                          <Typography variant="small" component="small">
                            <b>STREET ADDRESS</b>
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="p" component="p">
                            {patient.address}
                          </Typography>
                        </div>
                      </div>
                    )}

                    {patient.cellPhone && (
                      <div>
                        <div>
                          <Typography variant="small" component="small">
                            <b>PHONE NUMBER</b>
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="p" component="p">
                            {patient.cellPhone}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.insideCards}>
                    {patient.dob && (
                      <div>
                        <div>
                          <Typography variant="small" component="small">
                            <b>DATE OF BIRTH</b>
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="p" component="p">
                            {patient.dob}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {patient.gender && (
                      <div>
                        <div>
                          <Typography variant="small" component="small">
                            <b>LEGAL SEX</b>
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="p" component="p">
                            {patient.gender}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      )}
      {!patient && <NoPatientFound />}
    </>
  );
};

export default SinglePatient;
