/** @format */
import React, { useState } from "react";
import queryString from "query-string";

const SecondLogin = () => {
  const [data, setData] = useState({
    response_type: "",
    scope: "",
    client_id: "",
  });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // const apiHost = getApiHost();
    // const apiHost = "http://127.0.0.1:5000";
    const code = "d91066829401422585c36a469d51ccf1";
    const stringifiedCode = queryString.stringify({ code });
    const redirectUri = `https://www.google.com/?${stringifiedCode}`;
    window.open(redirectUri, "_self");
    // ===============================================
    // Call API HERE FOR GETTING CODE
    // const uri = `${apiHost}/oauth/authorize?${stringified}`;
    // const respo = await fetch(uri);
    // const res = await respo.json();
    // console.log("Repsossssssss:", res);
    // ===============================================
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ margin: "10px" }}>
        <label htmlFor="confirm">
          <input id="confirm" type="checkbox" name="confirm" />
          <span>Consent?</span>
        </label>
      </div>
      <div style={{ margin: "10px" }}>
        <label htmlFor="client_id">
          <span>Client ID ?</span>
          <input
            id="client_id"
            type="text"
            name="client_id"
            value={data.client_id}
            onChange={handleChange}
          />
        </label>
      </div>
      <div style={{ margin: "10px" }}>
        <label htmlFor="response_type">
          <span>Response Type</span>
          <input
            id="response_type"
            type="text"
            name="response_type"
            value={data.response_type}
            onChange={handleChange}
          />
        </label>
      </div>
      <div style={{ margin: "10px" }}>
        <label htmlFor="scope">
          <span>Scope</span>
          <input id="scope" type="text" name="scope" value={data.scope} onChange={handleChange} />
        </label>
      </div>
      <br />
      <button type="submit">Submit</button>
      <hr />
    </form>
  );
};

const LoginWithHealthCare = () => {
  const [user, setUser] = useState({
    username: "",
  });

  const handleLogin = (e) => {
    e.preventDefault();
  };

  const [login, showLogin] = useState(false);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return login ? (
    <div style={{ margin: "10px" }}>
      <div>Logged in as {user.username}</div>
      <SecondLogin />
      <p>
        <button type="button" onClick={() => showLogin(false)}>
          Logout
        </button>
      </p>
    </div>
  ) : (
    <div>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          name="username"
          placeholder="username"
          value={user.username}
          onChange={handleChange}
        />
        <button type="submit" onClick={() => showLogin(true)}>
          Login / Signup
        </button>
      </form>
    </div>
  );
};

export default LoginWithHealthCare;
