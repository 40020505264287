import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const deviceSelectionStyles = makeStyles()((theme: Theme) => ({
  deviceSelectionContainer: {
    maxWidth: "608px",
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  reloadBtnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "center",
  },
  agreeSubtitle: {
    textAlign: "center",
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 30px 0px",
  },
  gutterBottom: {
    marginBottom: "1em",
  },
  marginTop: {
    marginTop: "1em",
  },
  deviceButton: {
    width: "100%",
    border: "2px solid #aaa",
    margin: "1em 0",
  },
  localPreviewContainer: {
    marginTop: "10px",
  },
  joinButtons: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      width: "100%",
      "& button": {
        margin: "0.5em 0",
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1.5em 0 1em",
    },
  },
  mobileButton: {
    padding: "0.8em 0",
    margin: 0,
  },
  button: {
    marginTop: theme.spacing(1),
    width: "47%",
    textTransform: "uppercase",
  },
  formControl: {
    marginTop: "10px",
    minWidth: 260,
  },
  label: {
    backgroundColor: theme.palette.common.white,
  },
  linkBtn: {
    padding: 0,
    textDecoration: "underline",
    margin: "0px 8px",
  },
  reloadPrompt: {
    fontSize: 12,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  errorMessage: {
    padding: "5px",
    color: "red",
    fontSize: "16px",
    border: "1px solid red",
    [theme.breakpoints.down("md")]: {
      margin: "0px 5px",
      fontSize: "12px",
    },
  },
  titleVideoScreen: {
    fontSize: "18px",
    marginLeft: "10px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textField: {
    marginTop: "16px",
  },
}));
