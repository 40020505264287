import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  labelSmallText: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Alegreya Sans",
    textTransform: "none",
  },
  formControl: {
    marginTop: "10px",
    minWidth: 260,
    width: "100%",
    display: "block",
  },
  label: {
    backgroundColor: theme.palette.common.white,
  },
  tabRoot: {
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Alegreya Sans",
    fontWeight: 500,
  },
  tabSelected: {
    color: theme.palette.primary.main,
  },
  textColorPrimary: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Alegreya Sans",
    textTransform: "capitalize",
    fontSize: "24px",
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tabsRoot: {},
  inputRoot: {
    fontSize: 24,
    height: "56px",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "20px",
    lineHeight: "32px",
    fontFamily: "Alegreya Sans",
    fontWeight: 500,
    padding: "16px",
    width: "100%",
    marginTop: "10px",
  },
  screenerTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Alegreya Sans",
  },
  screenerTitleContainer: {
    display: "flex",
    alignItems: "center",
    padding: "38px 0px 20px 0px",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
  },
  img: {
    width: "50px",
    height: "50px",
    marginBottom: "20px",
  },
  labelRoot: {
    fontSize: 18,
    fontWeight: 400,
  },
  textField: {
    width: "100%",
  },
  actionBtnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
  },
  actionsContainer: {
    display: "grid",
    gridTemplateColumns: "70px 70px 70px",
    gap: "30px",
    marginTop: "46px",
    justifyContent: "center",
  },
  actionBtn: {
    width: "59px",
    height: "59px",
    borderRadius: "59px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    backgroundColor: theme.palette.secondary.main,
    cursor: "pointer",
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      cursor: "not-allowed",
    },
  },
  actionBtnLabel: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "12px",
    lineHeight: "16px",
    width: "70px",
    fontFamily: "Alegreya Sans",
    textAlign: "center",
    marginTop: "9px",
  },
  container: {
    padding: "24px 24px 40px 24px",
    boxSizing: "border-box",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      overflowY: "auto",
    },
    "@media(max-width: 375px)": {
      padding: "14px 24px 40px 24px",
    },
  },
  actionSingleContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "46px",
  },
  error: {
    position: "absolute",
    bottom: "-25px",
    color: theme.palette.error.main,
    fontSize: "14px",
    lineHeight: "18px",
    fontFamily: "Alegreya Sans",
    marginTop: "10px",
  },
  textFieldContainer: {
    position: "relative",
    width: "100%",
  },
}));

export default useStyles;
