import React, { useEffect, useState } from "react";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import { ReportBySpecimenFilters } from "./reportBySpecimenFilters";
import { format } from "date-fns";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import { useGetSpecimenReports } from "hooks/specimenTracking/useGetSpecimenReports/useGetSpecimenReports";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import useTranslation from "hooks/useTranslation";
import { axiosClient } from "util/api_helper";
import { exportCSV as exportCsvFile } from "util/util_functions";
import { colDefsReport } from "views/SpecimenTracking/utils/definitionColumnsListReport";
import { useSpecimenTrackingReportsStyle } from "../styles";

export const ReportBySpecimen = () => {
  const initialDate = new Date();
  initialDate.setDate(initialDate.getDate() - 7);
  const { data, getSpecimenReport } = useGetSpecimenReports();
  const { classes } = useSpecimenTrackingReportsStyle();
  const {
    web: {
      specimenTrackingReport: { exportCSV },
    },
  } = useTranslation();

  const [selectedInitialDate, setSelectedInitialDate] = useState<string>();
  const [selectedFinalDate, setSelectedFinalDate] = useState<string>();

  const exportToCSV = async () => {
    try {
      const params = {
        start_date: selectedInitialDate || format(initialDate, "MM/dd/yyyy"),
        end_date: selectedFinalDate || format(new Date(), "MM/dd/yyyy"),
      };
      const { data } = await axiosClient.get(API_ENDPOINTS.specimenTrackingDashboardCsv, {
        headers: { Accept: "text/csv" },
        params,
      });
      exportCsvFile(data, "specimen-tracking-report");
    } catch (err) {
      console.log(err);
    }
  };

  const titleButtons = [
    {
      name: exportCSV,
      action: () => exportToCSV(),
      icon: <SaveAltIcon style={{ color: "#fff", width: 25, height: 25, marginRight: "0.5em" }} />,
      dataCy: "reportsBtnExportCSV",
      className: classes.exportToCsvBtn,
    },
  ];

  const handleDates = (initDate: string, endDate: string) => {
    const newInitDate = initDate.split("-").join("/");
    const newEndDate = endDate.split("-").join("/");
    getSpecimenReport(newInitDate, newEndDate);
    setSelectedInitialDate(newInitDate);
    setSelectedFinalDate(newEndDate);
  };

  useEffect(() => {
    getSpecimenReport(format(initialDate, "MM/dd/yyyy"), format(new Date(), "MM/dd/yyyy"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenericEnhancedTable
      gridConfig={{
        ...defaultGridConfig,
        showActionColumn: false,
        allowDelete: false,
        allowEdit: false,
        allowHistory: false,
        showSearchBar: false,
        showTableToolbar: true,
        showCustomButton: false,
        showScannedSpecimenImage: false,
      }}
      colDefs={colDefsReport}
      orderBy="lastTrackedDate"
      order="desc"
      titleButtons={titleButtons}
      hideCheckboxes
      url={API_ENDPOINTS.specimenTrackingDashboardList}
      customFilter={
        <ReportBySpecimenFilters
          handleDates={handleDates}
          report={data?.report}
          totalRecords={data?.totalRecords || 0}
          initialDate={initialDate}
          finalDate={new Date()}
        />
      }
      urlParams={{
        start_date: selectedInitialDate || format(initialDate, "MM/dd/yyyy"),
        end_date: selectedFinalDate || format(new Date(), "MM/dd/yyyy"),
      }}
    />
  );
};
