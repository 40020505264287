import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Help } from "@mui/icons-material";
import Assessment from "@mui/icons-material/Assessment";
import { IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import SpecimenTrackingListDesktop from "./SpecimenTrackingListDesktop";
import SpecimenTrackingListMobile from "./SpecimenTrackingListMobile";
import { TitleSelects } from "components/GenericEnhancedTable/types";
import { useGetSpecimenDepartments } from "hooks/specimenTracking/useGetSpecimenDepartments/useGetSpecimenDepartments";
import { usePrograms } from "util/ProgramsContext";
import { useIsMobile } from "util/deviceUtils";
import { ProgramType } from "util/programsTypes";
import { UrlParamType } from "views/SpecimenTracking/types";
import { useSpecimenStyles } from "../../styles";

const SpecimenTrackingList = () => {
  const navigate = useNavigate();
  const { classes } = useSpecimenStyles();
  const isMobile = useIsMobile();
  const { data: dataDepartments } = useGetSpecimenDepartments();
  const { programId, getProgramIdByType } = usePrograms();
  const specimenTrackingProgramId = programId === getProgramIdByType(ProgramType.SpecimenTracking);

  const [departments, setDepartments] = useState<number[]>([]);
  const [urlParams, setUrlParams] = useState<UrlParamType>({});

  const addSpecimen = () => {
    return navigate("/console/specimen-tracking/location");
  };

  const goToReports = () => {
    navigate("/console/specimen-tracking/reports");
  };

  const removeKeyParams = (key: keyof UrlParamType) => {
    setUrlParams((current) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: toDelete, ...rest } = current;
      return rest;
    });
  };

  const handleDepartments = (departmentsName: string[]) => {
    if (departmentsName.length === 0) {
      removeKeyParams("department_ids");
    } else {
      const departmentsId = dataDepartments?.data
        .filter((item) => departmentsName.includes(item.department_name))
        .map((item) => item.department_id);

      if (departmentsId) {
        setUrlParams({
          ...urlParams,
          department_ids: departmentsId.toString(),
        });
      }
    }
  };

  const handleChangeSelectDepartments = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = value;
    setDepartments(newValue);
    handleDepartments([...newValue]);
  };

  const titleButtons = [
    {
      name: "Reports",
      action: () => goToReports(),
      icon: <Assessment style={{ color: "#fff", width: 25, height: 25 }} />,
      dataCy: "reportsBtn",
      className: classes.titleButton,
    },
    {
      name: "Add specimen",
      action: () => addSpecimen(),
      dataCy: "AddSpecimenBtn",
      className: classes.titleButton,
    },
  ];
  const titleSelects: TitleSelects[] = [
    {
      id: "multiple-checkbox-departments",
      labelId: "multiple-checkbox-label-departments",
      placeholder: "All Departments",
      value: departments,
      data: dataDepartments?.data.map(({ department_id, department_name }) => {
        return { id: department_id, name: department_name };
      }),
      onChange: handleChangeSelectDepartments,
    },
  ];

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {specimenTrackingProgramId && (
        <Grid
          container
          className={classes.root}
          alignItems="center"
          justifyContent="center"
          data-testid="specimenTrackingList"
        >
          <Grid item xs={12}>
            {isMobile ? (
              <SpecimenTrackingListMobile addSpecimen={addSpecimen} />
            ) : (
              <SpecimenTrackingListDesktop
                titleButtons={titleButtons}
                titleSelects={titleSelects}
              />
            )}
          </Grid>
          <IconButton
            onClick={() => navigate(`/console/specimen-tracking/help-center`)}
            className={classes.helpBtn}
          >
            <Help />
            {!isMobile && <Typography>Help</Typography>}
          </IconButton>
        </Grid>
      )}
    </form>
  );
};

export default SpecimenTrackingList;
