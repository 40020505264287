import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useTranslation from "hooks/useTranslation";
import { useIsMobile } from "util/deviceUtils";

export const NotMeetStandard = ({
  medicalDeterminationData,
  handleQuestionChange,
  classes,
  disabled,
}) => {
  return (
    <Grid xs={12} sm={12} container className={classes.examinerInnerQuestionsMargin}>
      <Grid xs={12} sm container>
        <FormControl variant="outlined" fullWidth>
          <div style={{ display: "flex" }}>
            <Grid item xs={12} sm style={{ display: "flex" }}>
              <TextField
                id="notMeetStandard"
                name="notMeetStandardTextField"
                size="small"
                defaultValue={medicalDeterminationData?.data?.comment}
                variant="outlined"
                onChange={(ev) => handleQuestionChange(ev, "data.comment")}
                error={false}
                helperText={false}
                data-cy="NotMeetStandardInput"
                data-testid="notMeetStandardInput"
                disabled={disabled}
              />
            </Grid>
          </div>
        </FormControl>
      </Grid>
      <Box width="100%" />
    </Grid>
  );
};

const MeetStandardWithMonitoringComponent = ({
  medicalDeterminationData,
  handleQuestionChange,
  classes,
  checkboxesList,
  disabled,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const isMobile = useIsMobile();
  const {
    web: {
      dotForm: {
        staff: {
          medicalDetermination: { driverQualifedFor, driverQualifiedForoptions },
        },
      },
    },
  } = useTranslation();

  const { month3, month6, year1, other } = driverQualifiedForoptions;

  const today = new Date();
  const maxDate = new Date(today.getFullYear() + 2, today.getMonth(), today.getDate());

  useEffect(() => {
    if (medicalDeterminationData?.data?.monitoring?.comment) {
      setSelectedDate(new Date(medicalDeterminationData?.data?.monitoring?.comment));
    }
  }, [medicalDeterminationData?.data?.monitoring?.comment]);

  const handleDateChange = (date) => {
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    setSelectedDate(date);
    handleQuestionChange(
      {
        target: {
          value: formattedDate,
          name: "stateOther",
          checked: false,
        },
      },
      "data.monitoring.comment",
    );
  };

  return (
    <Grid
      xs={12}
      sm={8}
      container
      className={classes.examinerInnerQuestionsMargin}
      data-testid="meetsStandarWithMonitoringComponent"
    >
      <Grid xs={12} sm container>
        <FormControl variant="outlined" fullWidth>
          <div style={{ display: "flex" }}>
            <Grid xs={12} sm item style={{ display: "flex" }}>
              <TextField
                id="other"
                name="other"
                size="small"
                defaultValue={medicalDeterminationData?.data?.comment || ""}
                variant="outlined"
                onChange={(ev) => handleQuestionChange(ev, "data.comment")}
                error={false}
                helperText={false}
                data-cy="MedicalDet-OtherInput"
                disabled={disabled}
              />
            </Grid>
          </div>
        </FormControl>
      </Grid>
      <Box width="100%" />
      <Grid xs={12} sm container style={{ marginTop: "16px" }}>
        <FormControl variant="outlined" fullWidth>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Grid item xs={12} sm={5}>
              <Typography className={classes.timeWindowTitle}>{driverQualifedFor}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <RadioGroup
                aria-label="qualified-for"
                name="qualifiedFor"
                value={medicalDeterminationData?.data?.monitoring?.qualifiedFor}
                onChange={(ev) => handleQuestionChange(ev, "data.monitoring.qualifiedFor")}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value={month3}
                  control={<Radio />}
                  label={month3}
                  data-cy="MedicalDet-QualifiedFor_3Months"
                  disabled={disabled}
                />
                <FormControlLabel
                  value={month6}
                  control={<Radio />}
                  label={month6}
                  data-cy="MedicalDet-QualifiedFor_6Months"
                  disabled={disabled}
                />
                <FormControlLabel
                  value={year1}
                  control={<Radio />}
                  label={year1}
                  data-cy="MedicalDet-QualifiedFor_1Year"
                  disabled={disabled}
                />
                <FormControlLabel
                  value={other}
                  control={<Radio />}
                  label={other}
                  data-cy="MedicalDet-QualifiedFor_Other"
                  disabled={disabled}
                />
              </RadioGroup>
              <DatePicker
                name="stateOther"
                label={other}
                value={selectedDate}
                disabled={
                  medicalDeterminationData?.data?.monitoring?.qualifiedFor !== other || disabled
                }
                onChange={handleDateChange}
                maxDate={maxDate}
                minDate={new Date()}
                slotProps={{
                  textField: {
                    id: "stateOther",
                    variant: "outlined",
                    required: medicalDeterminationData?.data?.monitoring?.qualifiedFor === other,
                    error:
                      medicalDeterminationData?.data?.monitoring?.qualifiedFor === other &&
                      medicalDeterminationData?.data?.monitoring?.comment === "",
                    helperText: false,
                    inputProps: {
                      "data-cy": "MedicalDet-QualifiedFor_OtherInput",
                    },
                  },
                }}
              />
            </Grid>
          </div>
        </FormControl>
      </Grid>
      <Box width="100%" />
      <Grid item xs={12} sm>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "0.5rem",
          }}
        >
          {checkboxesList.map(({ id, name, value, label, cyName }) => (
            <div
              key={id}
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    data-cy={cyName}
                    checked={value}
                    onChange={(ev) => handleQuestionChange(ev, `data.monitoring.${name}`)}
                    name={name}
                  />
                }
                label={label}
                disabled={disabled}
              />
              {name === "exemption" ? (
                <TextField
                  id="statelWaiverExceptionType"
                  name="statelWaiverExceptionType"
                  size="small"
                  defaultValue={medicalDeterminationData?.data?.monitoring?.exemption?.type}
                  variant="outlined"
                  onChange={(ev) => handleQuestionChange(ev, "data.monitoring.exemption.type")}
                  disabled={!medicalDeterminationData?.data?.monitoring?.exemption || disabled}
                  helperText={false}
                  required={medicalDeterminationData?.data?.monitoring?.exemption}
                  error={medicalDeterminationData?.data?.monitoring?.exemption?.type === undefined}
                  data-cy="MedicalDet-StatelWaiverInput"
                />
              ) : null}
            </div>
          ))}
        </FormGroup>
      </Grid>
      <Box width="100%" />
    </Grid>
  );
};

export const MeetStandardWithMonitoring = React.memo(MeetStandardWithMonitoringComponent);
