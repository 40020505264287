import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { ADDITIONAL_INFORMATION, AdditionalInformation, StepsProps } from "../types/constants";
import useTranslation from "hooks/useTranslation";
import { useIsMobile } from "util/deviceUtils";
import useStyles from "../styles/forms.styles";
import icon from "static/images/ahsIcons/vaccine_card.svg";

const DriverSuccessStep: React.FC<StepsProps> = ({ formData }) => {
  const { classes } = useStyles({
    alignLeft: false,
  });

  const { healthHistory: currentHealthHistory } = formData;
  const data = currentHealthHistory?.healthHistory;

  const isMobile = useIsMobile();

  const [additionalInformationList, setAdditionalInformationList] =
    useState<(AdditionalInformation | null)[]>();

  useEffect(() => {
    if (!data) {
      return;
    }

    const historyQuestions = Object.keys(data).map((key) => {
      return {
        key,
        value: data[key]?.answer,
        additionalInformationId: data[key]?.additionalInformationId,
      };
    });

    const result = ADDITIONAL_INFORMATION.map((item) => {
      if (
        historyQuestions.some((t) => t.additionalInformationId === item.id && t.value === "YES")
      ) {
        return item;
      }

      return null;
    });
    setAdditionalInformationList(result.filter((t) => t !== null));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    web: {
      dotForm: { patient },
    },
  } = useTranslation();

  const {
    confirmation: {
      historyReceived,
      thankYou,
      subtitle,
      legend,
      noAdditionalInformation,
      saveOrPrint,
      contactLocation,
    },
  } = patient;

  if (additionalInformationList?.length === 0) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.successStep_container}
        data-testid="driver-success-step"
      >
        <img src={icon} alt="" style={{ width: "90px" }} />
        <Typography align="center" className={classes.centeredTitle}>
          {historyReceived}
        </Typography>
        <Typography align="center" style={{ fontSize: "18px" }}>
          {thankYou}
        </Typography>
        <Typography align="center" style={{ fontSize: "18px" }}>
          {noAdditionalInformation}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.successStep_container}
      data-testid="driver-success-step-with-additional-information"
    >
      <Typography align="center" style={{ fontSize: "24px", fontWeight: "bold", margin: "0 8px" }}>
        {subtitle}
      </Typography>
      <Typography align="center" style={{ fontSize: "18px", margin: "0 14px" }}>
        {legend}
      </Typography>
      {additionalInformationList?.map((addInfo) => (
        <Card key={addInfo?.title} className={classes.additionalInformationCard}>
          <CardContent>
            <Typography align="center" className={classes.formTitle}>
              {addInfo?.title}
            </Typography>
            <div className={classes.addressCardBody}>
              {addInfo?.showAsAnOrderedList ? (
                <ol className={classes.cardUL} data-testid="additional-information-card-ol">
                  {addInfo?.information.map((item) => (
                    <li key={item} className={classes.olLi}>
                      {item}
                    </li>
                  ))}
                </ol>
              ) : (
                <span data-testid="additional-information-card-info-text">
                  {addInfo?.information[0]}
                </span>
              )}
            </div>
          </CardContent>
        </Card>
      ))}
      <Grid
        item
        xs={12}
        md={12}
        container
        spacing={1}
        alignItems="center"
        className="hide-on-print"
        style={{ justifyContent: "center" }}
      >
        <Button
          variant="contained"
          color="primary"
          aria-label="Print this list"
          size="medium"
          onClick={() => {
            window.print();
          }}
          style={{ margin: "0 1.2em", width: isMobile ? "100%" : "70%" }}
          data-testid="save-or-print-button"
        >
          {saveOrPrint}
        </Button>
      </Grid>
      <Typography align="center" style={{ fontSize: "18px" }}>
        {contactLocation}
      </Typography>
    </Grid>
  );
};

export default DriverSuccessStep;
