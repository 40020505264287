import React, { useCallback, useEffect } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { MeetStandardWithMonitoring, NotMeetStandard } from "./Common";
import { getDefaultCheckboxList, getDeterminationQuestions } from "../../constants";
import { DeterminationQuestions, DeterminationTypesProps } from "views/DOTForm/types/constants";
import useTranslation from "hooks/useTranslation";
import useStyles from "../../../styles/forms.styles";

const defaultStateValue = {
  notMeetStandard: false,
  meetStandardInResolution: false,
  meetStandardWithMonitoring: false,
};

const StateMedicalExaminerDeterminationStep: React.FC<DeterminationTypesProps> = ({
  formData,
  handleChange,
  isDisabled,
}) => {
  const { classes } = useStyles({
    alignLeft: false,
  });

  const [stateOptions, setStateOptions] = React.useState(defaultStateValue);

  const {
    web: {
      dotForm: {
        staff: {
          medicalDetermination: { state },
        },
      },
    },
  } = useTranslation();

  const medicalDeterminationData = formData;

  const {
    title,
    checkboxList: { grandFather, waiverException },
  } = state;

  const getDetermination = useCallback(
    (determination: string): boolean => {
      return medicalDeterminationData?.determination === determination;
    },
    [medicalDeterminationData?.determination],
  );

  const stateDeterminationQuestions: DeterminationQuestions[] = getDeterminationQuestions(
    "state",
    state.questions,
    getDetermination,
  );

  useEffect(() => {
    if (formData) {
      const currentDetermination = stateDeterminationQuestions.find(
        (item) => item.determination === formData?.stateDetermination,
      );
      if (currentDetermination?.textAreaName) {
        setStateOptions((prev) => ({
          ...prev,
          ...defaultStateValue,
          [currentDetermination.textAreaName]: true,
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const checkboxesList = getDefaultCheckboxList(state.checkboxList, medicalDeterminationData);
  const stateCheckboxList = [
    {
      id: 4,
      name: "grandFatheredFromRequirements",
      label: grandFather,
      value: medicalDeterminationData?.data?.monitoring?.grandFatheredFromRequirements,
      cyName: "ST-MedicalDet-GrandFather",
    },
    {
      id: 5,
      name: "exemption",
      label: waiverException,
      value: medicalDeterminationData?.data?.monitoring?.exemption,
      cyName: "ST-MedicalDet-Exemption",
    },
  ];

  checkboxesList.push(...stateCheckboxList);

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    responseType: string,
  ) => {
    const { value, name, checked } = event.target;
    const getNameIndex = stateDeterminationQuestions.find((item) => item.textAreaName === name);
    // if the user selects a different determination, clear the previous response
    if (medicalDeterminationData?.federalDetermination) {
      handleChange(`medicalDetermination.federalDetermination`, undefined);
    }

    // if the user changes to a different determination, clear the previous data
    if (getNameIndex && value) {
      handleChange(`medicalDetermination.data`, "");
    }

    if (value || checked !== undefined) {
      handleChange(`medicalDetermination.${responseType}`, value || checked);
      handleChange(`medicalDetermination.determinationType`, "STATE");
    }

    // delete the response if the user unchecks the box
    if (checked !== undefined && checked === false && !value) {
      handleChange(`medicalDetermination.${responseType}`, undefined);
    }

    // keeps track of the current determination
    if (getNameIndex) {
      setStateOptions({
        ...stateOptions,
        ...defaultStateValue,
        [name]: !!value,
      });
    }
  };

  return (
    <Grid container data-testid="state-determination">
      {/* Section 1 Start */}
      <Typography className={classes.timeWindowTitle} style={{ fontWeight: 700 }}>
        {title}
      </Typography>
      <Grid xs={12} sm={12} container>
        <Grid container xs={12} md={12}>
          <FormGroup style={{ width: "100%" }}>
            {stateDeterminationQuestions.map(
              ({ question, determination, textAreaName, cyName }, index) => (
                <div key={determination}>
                  <Grid item xs={12} md className={classes.checkboxListLeftAlign}>
                    <RadioGroup
                      data-testid={`stateDetermination-${index}`}
                      name={question}
                      data-cy={cyName}
                      value={
                        medicalDeterminationData?.stateDetermination
                          ? medicalDeterminationData?.stateDetermination
                          : ""
                      }
                      onChange={(ev) => handleQuestionChange(ev, "stateDetermination")}
                      row
                    >
                      <FormControlLabel
                        value={determination}
                        name={textAreaName}
                        control={<Radio />}
                        label={question}
                        disabled={isDisabled}
                      />
                    </RadioGroup>
                    <Grid item xs={12}>
                      {stateOptions.notMeetStandard &&
                        medicalDeterminationData?.stateDetermination === determination && (
                          <NotMeetStandard
                            medicalDeterminationData={medicalDeterminationData}
                            handleQuestionChange={handleQuestionChange}
                            classes={classes}
                            disabled={isDisabled}
                          />
                        )}
                      {stateOptions.meetStandardWithMonitoring &&
                        medicalDeterminationData?.stateDetermination === determination && (
                          <MeetStandardWithMonitoring
                            medicalDeterminationData={medicalDeterminationData}
                            handleQuestionChange={handleQuestionChange}
                            classes={classes}
                            checkboxesList={checkboxesList}
                            disabled={isDisabled}
                          />
                        )}
                    </Grid>
                  </Grid>
                  <Box width="100%" />
                </div>
              ),
            )}
          </FormGroup>
        </Grid>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default StateMedicalExaminerDeterminationStep;
