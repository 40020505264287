import React, { useCallback } from "react";
import { useFormik } from "formik";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useGetSpecimenDepartments } from "hooks/specimenTracking/useGetSpecimenDepartments/useGetSpecimenDepartments";
import { useApp } from "../../util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useStyles } from "../../styles/Modal/ModalForm.styles";

const SpecimenTrackingLocationForm = ({ record, mode, handleClose }) => {
  const app = useApp();
  const { classes } = useStyles();
  const { data: dataDepartments } = useGetSpecimenDepartments();

  const onSaveClicked = async ({
    specimenTrackingLocationName,
    specimenTrackingLocationDescription,
    finalDestination,
    departmentId,
  }) => {
    const isError = !specimenTrackingLocationName || !departmentId;

    if (!isError) {
      try {
        if (mode === "add") {
          const specimenTrackingLocationForPost = {
            name: specimenTrackingLocationName.replace("'", ""),
            description: specimenTrackingLocationDescription.replace("'", ""),
            finalDestination: finalDestination || false,
            departmentId: departmentId || null,
          };
          const url = API_ENDPOINTS.specimenTrackingLocationsCreate;
          await axiosClient.post(url, specimenTrackingLocationForPost);
        } else if (mode === "edit") {
          const specimenTrackingLocationForPut = {
            name: specimenTrackingLocationName.replace("'", ""),
            description: specimenTrackingLocationDescription.replace("'", ""),
            finalDestination: finalDestination || false,
            departmentId: departmentId || null,
          };
          const url = `${API_ENDPOINTS.specimenTrackingLocations}/${record.id}`;
          await axiosClient.put(url, specimenTrackingLocationForPut);
        } else {
          throw new Error("Invalid Mode provided");
        }
        handleClose();
        app.addInfoMsg("Specimen tracking location has been saved successfully.");
        // eslint-disable-next-line
      } catch (error: any) {
        const errorMsg = error.response?.data?.description || error.response?.data?.error;
        app.addError(errorMsg || "Error while saving specimen tracking location");
      }
    } else {
      app.addError("Please, fill the obligatory fields");
    }
  };

  const {
    values: {
      specimenTrackingLocationName,
      specimenTrackingLocationDescription,
      finalDestination,
      departmentId,
    },
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      specimenTrackingLocationName: record.name || "",
      finalDestination: (record.finalDestination && "true") || "false",
      specimenTrackingLocationDescription: record.description || "",
      departmentId: record.departmentId || "",
    },
    onSubmit: onSaveClicked,
  });

  const onChangeSpecimenTrackingLocationDescription = useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue("specimenTrackingLocationDescription", value);
    },
    [setFieldValue],
  );

  const onChangeSpecimenTrackingLocationName = useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue("specimenTrackingLocationName", value);
    },
    [setFieldValue],
  );

  const onChangeSpecimenTrackingFinalDestination = useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue("finalDestination", value);
    },
    [setFieldValue],
  );

  return (
    <>
      <FormControl variant="outlined" fullWidth className={classes.fullWidth}>
        <InputLabel id="department-name-label">Department Name*</InputLabel>
        <Select
          variant="standard"
          data-testid="department-name-select"
          data-cy="department-name-select"
          fullWidth
          labelId="department-name-label"
          label="Department Name"
          id="department-name-dropdown"
          value={departmentId}
          onChange={(event) => {
            setFieldValue("departmentId", event.target.value);
          }}
        >
          {dataDepartments?.data.map((department) => (
            <MenuItem key={department.department_id} value={department.department_id}>
              {department.department_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        data-cy="specimen-tracking-location-name"
        classes={{ root: classes.fullWidth }}
        id="specimenTrackingLocationName"
        name="specimenTrackingLocationName"
        size="small"
        value={specimenTrackingLocationName}
        variant="outlined"
        label="Location Name*"
        onChange={onChangeSpecimenTrackingLocationName}
      />

      <TextField
        data-cy="specimen-tracking-location-description"
        classes={{ root: classes.fullWidth }}
        id="specimenTrackingLocationDescription"
        name="specimenTrackingLocationDescription"
        size="small"
        value={specimenTrackingLocationDescription}
        variant="outlined"
        label="Location Description"
        onChange={onChangeSpecimenTrackingLocationDescription}
      />

      <FormControl variant="outlined">
        <FormLabel>Is final destination?</FormLabel>
        <RadioGroup
          row
          aria-label="final_destination"
          name="final_destination"
          value={finalDestination}
          onChange={onChangeSpecimenTrackingFinalDestination}
        >
          <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </FormControl>
      <Typography variant="body2">* These fields are required</Typography>
      <Button
        autoFocus
        onClick={() => handleSubmit()}
        type="submit"
        color="primary"
        variant="contained"
        className={classes.saveButton}
        data-cy="saveButton"
      >
        Save
      </Button>
    </>
  );
};

export default SpecimenTrackingLocationForm;
