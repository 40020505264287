import React, { useState } from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import AppRoutes from "./Routes";
import queryClient from "queryClient.config";
import { makeStyles } from "tss-react/mui";
import { ChatProvider } from "./components/Video/components/ChatProvider";
import ErrorDialog from "./components/Video/components/ErrorDialog/ErrorDialog";
import { VideoProvider } from "./components/Video/components/VideoProvider";
import useConnectionOptions from "./components/Video/utils/useConnectionOptions/useConnectionOptions";
import AppInit from "components/AppInit/AppInit";
import { GenericEnhancedTableProvider } from "components/GenericEnhancedTableContext";
import { NewVersionNotifier } from "components/NewVersionNotifier";
import { useAppState } from "components/Video/state";
import { ErrorAlert, InfoAlert, SuccessAlert, WarningAlert } from "components/shared/Alerts";
import { ProgramsLoadingProvider } from "views/console/components/useProgramsLoading";
import { ProvideApp } from "./util/AppContext";
import { ProgramsProvider } from "./util/ProgramsContext";
import { ProvideAuth } from "./util/Security";
import { isRNApp } from "util/RNWebView";
import { VUCRedirectProvider } from "util/VUCRedirectContext";
import { useIsMobile } from "util/deviceUtils";
import "./App.css";

const useStyles = makeStyles()((theme) => ({
  app: {
    textAlign: "center",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.headerHeight,
  },
  embedded: {
    paddingTop: 0,
  },
}));

function App() {
  const { classes, cx } = useStyles();
  const url = new URL(window.location.href);
  const embedded = url.searchParams.get("embedded");
  const isEmbedded = embedded === "true" || isRNApp;
  const isMobile = useIsMobile();
  const [appInit, setAppInit] = useState(false);

  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <ProvideAuth>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          style={{ top: "65px" }}
          dense={isMobile}
          Components={{
            info: InfoAlert,
            warning: WarningAlert,
            success: SuccessAlert,
            error: ErrorAlert,
          }}
        >
          <ProvideApp>
            <VideoProvider options={connectionOptions} onError={setError}>
              <ErrorDialog dismissError={() => setError(null)} error={error} />
              <ChatProvider>
                <BrowserRouter>
                  <ProgramsProvider>
                    <VUCRedirectProvider>
                      <ProgramsLoadingProvider>
                        <GenericEnhancedTableProvider>
                          <div className={cx(classes.app, { [classes.embedded]: isEmbedded })}>
                            {!appInit && (
                              <AppInit
                                onAppInitHandler={() => {
                                  setAppInit(true);
                                }}
                              />
                            )}
                            {appInit && <AppRoutes />}
                          </div>
                          <NewVersionNotifier />
                        </GenericEnhancedTableProvider>
                      </ProgramsLoadingProvider>
                    </VUCRedirectProvider>
                  </ProgramsProvider>
                </BrowserRouter>
              </ChatProvider>
            </VideoProvider>
          </ProvideApp>
        </SnackbarProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
      </QueryClientProvider>
    </ProvideAuth>
  );
}

export default App;
