export const RNWebView = window.ReactNativeWebView;
export const isRNApp = Boolean(RNWebView);

export function postRNOpenMeetingRoom(vsId) {
  if (isRNApp) {
    RNWebView.postMessage(
      JSON.stringify({
        type: "open_meeting_view",
        data: {
          vsId,
        },
      }),
    );
  }
}

export function postRNGoBack() {
  if (isRNApp) {
    RNWebView.postMessage(
      JSON.stringify({
        type: "go_back",
        data: undefined,
      }),
    );
  }
}
