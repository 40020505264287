import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import EditableComponent from "../../../EditableComponent";
import { handleEditProvider, onCancelSection } from "../../Common/SectionsEditHandler";
import { useProviderContext } from "../../ProviderContext";
import AdditionalInformationSection from "./AdditionalInfoSection";
import NotesSection from "./NotesSection";
import { useApp } from "util/AppContext";
import { EditableComponentProps } from "../../types";

const Notes: React.FC = () => {
  const app = useApp();
  const { providerId } = useParams<{ providerId: string }>();
  const {
    currentPayload,
    setCurrentPayload,
    currentlyEditing,
    setCurrentlyEditing,
    selectedTab,
    refetch,
  } = useProviderContext();

  const [sections, setSections] = React.useState({
    notes: true,
    additionalInfo: true,
  });
  const [isEditing, setIsEditing] = React.useState({
    notes: false,
    additionalInfo: false,
  });
  const [isLoading, setIsLoading] = React.useState({
    notes: false,
    additionalInfo: false,
  });

  const notesConfig: EditableComponentProps[] = [
    {
      title: "Notes",
      slug: "notes",
      component: NotesSection,
    },
    {
      title: "Additional Information",
      slug: "additionalInfo",
      component: AdditionalInformationSection,
    },
  ];

  const handleCancelSection = () => {
    onCancelSection({
      currentlyEditing,
      setIsEditing,
      setSections,
      setIsLoading,
      setCurrentPayload,
      setCurrentlyEditing,
    });
  };

  useEffect(() => {
    handleCancelSection();
  }, [selectedTab]);

  return (
    <Grid item xs={12} md={8} marginTop="1em">
      {notesConfig.map(({ title, component, slug, showActionButton, data, customAction }) => (
        <EditableComponent
          key={slug}
          title={title}
          onEdit={() =>
            handleEditProvider(
              slug,
              setIsEditing,
              setSections,
              setCurrentlyEditing,
              currentPayload,
              setIsLoading,
              providerId,
              refetch,
              app,
              handleCancelSection,
            )
          }
          onCancel={handleCancelSection}
          currentPayload={currentPayload}
          showActionButton={showActionButton}
          customAction={customAction}
          isEditing={isEditing[slug]}
          isLoading={isLoading[slug]}
          disableSection={currentlyEditing !== null && currentlyEditing !== slug}
        >
          {component({ shouldDisableForm: sections[slug], data })}
        </EditableComponent>
      ))}
    </Grid>
  );
};

export default Notes;
