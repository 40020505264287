/** @format */
import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  rootDiv: {
    padding: "5px 30px",
    fontFamily: "'Times New Roman', Times, serif",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  root: {
    textDecoration: "underline",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "5px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  para: {
    fontSize: "14px",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      textAlign: "justify",
      "& ul": {
        paddingLeft: "30px",
      },
    },
  },
}));

const VirtualVisitConsent = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.rootDiv}>
      <Typography variant="h6" className={classes.root}>
        CONSENT FOR TREATMENT, PAYMENT AND HEALTH CARE OPERATIONS, INCLUDING ADMISSION AND MEDICAL
        TREATMENT AUTHORIZATION
      </Typography>
      <Typography component="p" className={classes.para}>
        <b>GENERAL CONSENT, AUTHORIZATION, PATIENT RIGHTS AND RESPONSIBILITIES</b>
        <br />
        I authorize Atlantic Health System (referred to as “the Hospital”), Atlantic Medical Group
        (“AMG”), Hospital staff, AMG staff and the physician(s) participating in my care to render
        hospital and medical care for my condition, which may include routine diagnostic procedures
        and such other medical treatment as may be deemed advisable by the physician(s)
        participating in my care. This may or may not include admission to the Hospital. I
        acknowledge that no guarantees have been made to me about the outcome of my medical care and
        treatment. I understand and acknowledge that the majority of the physicians at the Hospital
        are members of the Voluntary Medical Staff and are not employees or agents of the Hospital,
        but are either independent contractors or independent practitioners who have been granted
        the privilege of using the Hospital’s facilities for the care and treatment of their
        patients. This includes, but is not limited to, Emergency Department physicians,
        anesthesiologists, cardiologists, neonatologists, obstetricians, pathologists, radiologists,
        surgeons, the on call physician, telehealth providers and other consultants who may treat
        me. I understand that telehealth involves the use of information and communications
        technologies, including telephones, remote patient monitoring devices, or other electronic
        means, to support clinical health care, provider consultation, patient and professional
        health-related education, public health, health administration, and other services. I
        consent to treatment and care by AHS affiliates and by their physicians and health care
        providers, including those who are located at sites other than the one at which I am present
        and who provide treatment and care through telehealth. I understand that doctors in
        training, medical and nursing students and paramedical personnel may observe and participate
        in my care under the supervision of the Hospital or AMG staff and my physician(s). I
        authorize the Hospital to arrange for the disposition of all specimens and tissues. These
        consents and authorizations shall also apply to the admission and medical treatment of a
        newborn infant who is delivered by me during my hospitalization. I understand that it may be
        necessary for my healthcare provider(s) to take photographs, films, recordings and/or other
        like images and that the presence of a vendor representative may be required for medical,
        educational and/or continuity of care purposes.
        <br />
        <br />
        I hereby acknowledge receipt of a Statement of Patient Rights and Responsibilities. I
        understand that professional personnel are available to explain the Statement. Not
        applicable to Emergency Department Treatment Authorization.
        <br />
        <br />
        The hospital maintains a current list of patients and their location in the hospital.  I do
        not permit my location to be provided to friends, family and/or visitors.
        <br />
        <br />
      </Typography>
      <Typography component="p" className={classes.para}>
        <b>FINANCIAL ARRANGEMENTS</b>
        <br />
        I understand the Hospital charges do not include the fees of my treating physician or the
        fees for services provided by other Voluntary Medical Staff who may treat me. I understand
        that I am financially responsible for the payment of my physician fees and these fees may
        not be covered by my insurance plan. I authorize payment of medical insurance benefits
        (including managed care, Medicare and Medicaid, when applicable) directly to the Hospital
        and/or any physician(s) participating in my care. I understand that some insurance and
        managed care entities require pre-approval of certain hospitalizations, procedures and
        surgeries, and it may be my responsibility to obtain appropriate pre-approvals. If I am
        receiving hospital billed services, a copy of “An Important Message from Medicare”, “An
        Important Message from TRICARE”, and/or “Notice of Charity Care and Reduced Charge Charity
        Care” has been made available to me. I understand my rights as outlined in the document I
        have received, if any. In addition, a deposit may be requested if I have been classified as
        a self-pay patient. Not applicable to Emergency Department Treatment Authorization. I
        authorize the Hospital and all clinical providers who have provided care to me, along with
        any billing services, collection agencies, attorneys or other agents who may work on their
        behalf, to contact me on my cell phone and/or home phone using automatic telephone dialing
        systems or other computer assisted technology.  I do not authorize such contact at this
        time.
        <br />
        <br />
      </Typography>
      <Typography component="p" className={classes.para}>
        <b>PROTECTED HEALTH INFORMATION</b>
        <br />
        I have received a copy of the Notice of Privacy Practices for Protected Health Information
        (the “Notice”). This Notice provides a complete description of the uses and disclosures of
        my Personal Protected Health Information (“PHI”). I have had an opportunity to review this
        information before signing this form. I consent to the Hospital, AMG and/or any physician(s)
        participating in my care releasing my PHI (either in writing or verbally) in order to carry
        out treatment, payment or health care operations. This includes any medical information
        (including drug and alcohol abuse treatment information, psychiatric treatment information,
        and HIV related information including HIV testing results (if applicable), which may be
        needed to process claims for medical insurance (or managed care) benefits relative to this
        hospitalization (including precertification or verification, if necessary), or which may be
        needed to conduct continued care planning.
        <br />
        <br />
      </Typography>
      <Typography component="p" className={classes.para}>
        <b>AUTHORIZATION TO DRAW BLOOD</b>
        <br />
        In the event that any individual participating in my care is accidentally exposed to my
        blood or bodily fluids, I authorize the Hospital to draw my blood and test it for the
        presence of blood borne pathogens such as the Human Immunodeficiency Virus (“HIV”). I
        understand that if such testing is necessary the Hospital or my physician will make all
        reasonable efforts to notify me. I consent to the confidential disclosure of the test
        results to the authorized medical provider treating the person who has been exposed to my
        blood or bodily fluids, so that appropriate treatment determinations may be made. ____ By
        initialing here I decline to be tested for HIV and I refuse the disclosure of my blood test
        results.
        <br />
        <br />
      </Typography>
      <Typography component="p" className={classes.para}>
        <b>VALUABLES</b>
        <br />
        I understand that the Hospital recommends all personal belongings and valuables be sent home
        with a family member or friend. I assume all risk for loss or damage to any personal
        belongings retained by me. The Hospital will not replace or reimburse me for any personal
        belongings which are lost, broken or stolen during my admission.
        <br />
        <br />
      </Typography>
      <Typography component="p" className={classes.para}>
        <b>OUTPATIENT SERVICES IN HOSPITAL SETTING</b>
        <br />
        <br />
        I understand that I am having care, testing, procedure(s) or treatment that is considered an
        outpatient procedure in a hospital setting. As such, there may be different requirements for
        deductibles and/or copays than for a doctor office visit. I understand that it is my
        responsibility to fully understand the requirements of my insurance company or managed care
        entity and that I am responsible for payment of any copayments, deductibles, and charges as
        required. _____ (Initial) I understand that there will be two components to my bill: the
        professional services provided by my physician and the tests and/or procedures conducted by
        the Hospital.
        <br />
        <br />
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography component="p" className={classes.para}>
        <b>Notice of Charity Care and Reduced Charge Charity Care</b>
        <br />
        <br />
        This health care facility will provide services without charge or at a reduced charge to New
        Jersey residents who cannot afford to pay and who meet specific income, assets and other
        eligibility criteria.* If you think you may be eligible, please contact the Business Office
        of this facility and ask to be screened for charity care. * Eligibility criteria are
        specified in N.J.A.C. 8:31 B-4.37
        <br />
        <br />
      </Typography>
      <Typography component="p" className={classes.para}>
        <b>
          State of New Jersey Hospital Patient Rights As a patient in Atlantic Health System you
          have the following rights under state law and regulations: MEDICAL CARE
        </b>
        <br />
        <br />
        • To receive the care and health services that the hospital is required by law to provide. •
        To receive considerate and respectful care consistent with sound nursing and medical
        practices. • To receive an understandable explanation from your physician of your complete
        medical condition, recommended treatment, expected results, risks involved and reasonable
        medical alternatives. If your physician believes that some of this information would be
        detrimental to your health or beyond your ability to understand, the explanation must be
        given to your next of kin or guardian. • To give informed written consent prior to the start
        of specified, non-emergency medical procedures or treatments. Your physician should explain
        to you in words you understand specific details about the recommended procedure or
        treatment, any risks involved, time required for recovery, and any reasonable medical
        alternatives. • To refuse medication and treatment to the extent permitted by law and to be
        informed of the medical consequences of this act. • To expect and receive appropriate
        assessment, management and treatment of pain as an integral component of your care. • To be
        included in experimental research only if you give informed written consent. You have the
        right to refuse to participate. • To contract directly with a New Jersey licensed registered
        professional nurse of your choosing for private professional care during your
        hospitalization.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>COMMUNICATION AND INFORMATION</b>
        <br />
        <br />
        To be informed of the names and functions of all health care professionals providing you
        with personal care. • To receive, as soon as possible, the services of a translator or
        interpreter if you need one to help you communicate with the hospital’s health care
        personnel. • To be informed of the names and functions of any outside health care and
        educational institutions involved in your treatment. You may refuse to allow their
        participation. • To receive, upon request, the hospital’s written policies and procedures
        regarding life-saving methods and the use or withdrawal of life support mechanisms. • To be
        advised in writing of the hospital’s rules regarding the conduct of patients and visitors. •
        To receive a summary of your patient rights that includes the name and phone number of the
        hospital staff member to whom you can ask questions or complain about any possible violation
        of your rights.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>MEDICAL RECORDS</b>
        <br />
        <br />
        To have prompt access to the information in your medical record. If your physician feels
        that this access is detrimental to your health, your next of kin or guardian has a right to
        see your record. • To obtain a copy of your medical record, at a reasonable fee, within 30
        days after a written request to the hospital.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>COST OF HOSPITAL CARE</b>
        <br />
        <br />
        To receive a copy of the hospital payment rates. If you request an itemized bill, the
        hospital must provide one, and explain any questions you may have. You have a right to
        appeal any charges. • To be assisted in obtaining public assistance and the private health
        care benefits to which you may be entitled.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>DISCHARGE PLANNING</b>
        <br />
        <br />
        To receive information and assistance from your attending physician and other health care
        providers if you need to arrange for continuing health care after your discharge from the
        hospital. • To receive sufficient time before discharge to arrange for continuing health
        care needs. • To be informed by the hospital about any appeal process to which you are
        entitled by law if you disagree with the hospital’s discharge plans.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>TRANSFERS</b>
        <br />
        <br />
        • To be transferred to another facility only when you or your family has made the request,
        or in instances where the transferring hospital is unable to provide you with the care you
        need. • To receive an advance explanation from a physician of the reasons for your transfer
        and possible alternatives.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>PERSONAL NEEDS</b>
        <br />
        <br />
        To be treated with courtesy, consideration, and respect for your dignity and individuality.
        • To have access to storage space in your room for private use. The hospital must also have
        a system to safeguard your personal property.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>FREEDOM FROM ABUSE AND RESTRAINTS</b>
        <br />
        <br />
        To freedom from physical and mental abuse. • To freedom from restraints, unless they are
        authorized by a physician for a limited period of time to protect the safety of you or
        others.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>PRIVACY AND CONFIDENTIALITY</b>
        <br />
        <br />
        • To have physical privacy during medical treatment and personal hygiene functions, unless
        you need assistance. • To confidential treatment of information about you. Information in
        your records will not be released to anyone outside the hospital without your approval,
        unless it is required by law.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>LEGAL RIGHTS</b>
        <br />
        <br />
        • To treatment and medical services without discrimination based on race, age, religion,
        national origin, sex, sexual preference, handicap, diagnosis, ability to pay, or source of
        payment. • To exercise all your constitutional, civil, and legal rights.
        <br />
        <br />
      </Typography>

      <Typography component="p" className={classes.para}>
        <b>QUESTIONS AND COMPLAINTS</b>
        <br />
        <br />
        To present questions or grievances to a designated hospital staff member and to receive a
        response in a reasonable period of time. Grievances may be presented to the Hospital’s
        Patient Representative: • Chilton Medical Center: 973-831-5359 • Newton Medical Center:
        973-579-8379 • Hackettstown Medical Center: 908-850-7766 • Overlook Medical Center:
        908-522-5273 • Morristown Medical Center: 973-971-5142 The Joint Commission Office of
        Quality Monitoring at 1-800-994-6610 or complaint@jointcommission.org You may also directly
        contact the NJ Department of Health Complaint Hotline at 1-800-792-9770. This list of
        Patient Rights is an abbreviated summary of the current New Jersey laws and regulations
        governing the rights of hospital patients. For complete information, consult the NJ
        Department of Health regulations N.J.A.C. 8:43G -4.1, or Public Law 1989-Chapter 170.
        <br />
        <br />
      </Typography>

      <br />
      <br />
      <br />
      <br />
      <Typography component="p" className={classes.para}>
        <b>New Jersey Department of Health</b>
        <br />
        <b>YOUR RIGHT TO MAKE HEALTH CARE DECISIONS IN NEW JERSEY</b>
        <br />
        <br />
        This document explains your rights to make decisions about your own health care under New
        Jersey law. It also tells you how to plan ahead for your health care if you become unable to
        decide for yourself because of an illness or accident. It contains a general statement of
        your rights and some common questions and answers. Your Basic Rights
        <br />
        You have the right to receive an understandable explanation from your doctor of your
        complete medical condition, expected results, benefits and risks of the treatment
        recommended by your doctor, and reasonable medical alternatives. You have the right to
        accept or refuse any procedure or treatment used to diagnose or treat your physical or
        mental condition, including life-sustaining treatment. You als o have the right to control
        decisions about your health care in the event you become unable to make your own decisions
        in the future by completing an advance directive.
        <br />
        <br />
        What happens if I’m unable to decide about my health care?
        <br />
        If you become unable to make treatment decisions, due to illness or an accident, those
        caring for you will need to know about your values and wishes in making decisions on your
        behalf. That’s why it is important to write an advance directive.
        <br />
        <br />
        What is an advance directive?
        <br />
        An advance directive is a document that allows you to direct who will make health care
        decisions for you and to state your wishes for medical treatment if you become unable to
        decide for your future. Your advance directive may be used to accept or refuse any procedure
        or treatment, including life-sustaining treatment.
        <br />
        <br />
        What types of advance directives can I use?
        <br />
        There are three kinds of advance directives that you can use to say what you want and who
        you want your doctors to listen to. A PROXY DIRECTIVE (also called a “durable power of
        attorney for health care”) lets you name a “health care representative”, such as a family
        member or friend, to make health care decisions on your behalf. An INSTRUCTION DIRECTIVE
        (also called a “living will”) lets you state what kinds of medical treatments you would
        accept or reject in certain situations. A COMBINED DIRECTIVE lets you do both. It lets you
        name a health care representative and tells that person your treatment wishes.
        <br />
        <br />
        Who can fill out these forms?
        <br />
        You can fill out an advance directive in New Jersey if you are 18 years or older and you are
        able to make your own decisions. You do not need a lawyer to fill it out.
        <br />
        <br />
        Who should I talk to about advance directives?
        <br />
        You should talk to your doctor about it and give a copy to him or her. You should also give
        a copy to your health care representative, family member(s), or others close to you. Bring a
        copy with you when you must receive care from a hospital, nursing home, or other health care
        agency. Your advance directive becomes part of your medical records.
        <br />
        <br />
        What if I don’t have an advance directive?
        <br />
        If you become unable to make treatment decisions and you do not have an advance directive,
        your close family members will talk to your doctor and in most cases, may then make
        decisions on your behalf. However, if your family members, doctor, or other caregivers
        disagree about your medical care, it may be necessary for a court to appoint someone as your
        legal guardian. (This also may be needed if you do not have a family member to make
        decisions on your behalf.) That’s why it’s important to put your wishes in writing to make
        it clear who should decide for you and to help your family and doctor know what you want.
        Will my advance directive be followed?
        <br />
        Yes. Everyone responsible for your care must respect your wishes that you have stated in
        your advance directive. However, if your doctor, nurse, or other professional has a sincere
        objection to respecting your wishes to refuse life-sustaining treatment, he or she may have
        your care transferred to another professional who will carry them out.
        <br />
        <br />
        What if I change my mind?
        <br />
        You can change or revoke any of these documents at a later time.
        <br />
        <br />
        Will I still be treated if I don’t fill out an advance directive?
        <br />
        Yes. You don’t have to fill out any forms if you don’t want to and you will still get
        medical treatment. Your insurance company also cannot deny coverage based on whether or not
        you have an advance directive.
        <br />
        <br />
        What other information and resources are available to me?
        <br />
        Your doctor or a member of our staff can provide you with more information about our
        policies on advance directives. You also may ask for written informational materials and
        help. If there is a question or disagreement about your health care wishes, we have an
        ethics committee or other individuals who can help.
        <br />
        <br />
      </Typography>
    </div>
  );
};

export default VirtualVisitConsent;
