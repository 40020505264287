import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ isActive?: boolean }>()((theme, params = { isActive: false }) => {
  const { isActive } = params;
  return {
    appCard: {
      backgroundColor: isActive ? "rgba(0, 0, 0, 0.12)" : "unset",
      marginBottom: "16px",
      borderRadius: "10px",
    },
    appCardWithTimeSlots: {
      backgroundColor: isActive ? "rgba(0, 0, 0, 0.12)" : "unset",
      marginBottom: "16px",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
    cardActionAreaWithTimeSlots: {
      width: "55%",

      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    appCardRN: {
      backgroundColor: isActive ? "rgba(0, 0, 0, 0.12)" : "white",
      marginBottom: "20px",
      borderRadius: "10px",
      width: "350px",
      height: "130px",
    },
    titleCard: {
      fontSize: "22px",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
    titleCardRN: {
      fontSize: "18px",
      fontWeight: 500,
    },
    cardDesc: {
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    cardDescRN: {
      fontSize: "12px",
    },
    img: {
      width: "100%",
      height: "100%",
      borderRadius: "5px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "100%",
      },
    },
    imgRN: {
      width: "50px",
      height: "60px",
      borderRadius: "5px",
    },
    headerContainer: {
      paddingTop: "48px",
      paddingBottom: "24px",
      maxWidth: 800,
    },
    title: {
      marginTop: "20px",
      marginBottom: "32px",
    },
    searchContainer: {
      marginBottom: "24px",
      gap: "8px",
      justifyContent: "flex-end",
    },
    map: {
      width: "100%",
      height: 400,
    },
    modal: {
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.common.black}`,
      padding: "20px",
    },
    form: {
      width: "100%",
    },
    formCountry: {
      marginTop: "0px",
      width: "100%",
    },
    modalContainer: {
      marginRight: "30%",
      marginLeft: "30%",
      margin: "120px",
      overflow: "auto",
      borderRadius: "10px",
      border: "none",
      [theme.breakpoints.down("md")]: {
        marginRight: "5%",
        marginLeft: "5%",
        margin: "10px",
        overflow: "auto",
      },
    },
    modalTitle: {
      fontSize: "22px",
      fontWeight: 500,
    },
    block: {
      marginBottom: "20px",
    },
    buttonApply: {
      color: theme.palette.common.white,
      backgroundColor: "blue",
    },
    suggestionsContainer: {
      padding: "10px",
      boxShadow: `0px 2px 3px ${theme.palette.grey[600]}`,
    },
    filterItem: {
      backgroundColor: "#F5F5F5",
      borderRadius: "10px",
      padding: "10px",
      marginRight: "5px",
      marginBottom: "5px",
    },
    filterItemRN: {
      backgroundColor: "#F5F5F5",
      height: "25px",
      borderRadius: "5px",
      padding: "5px",
      marginRight: "5px",
    },
    sort: {
      marginTop: "10px",
      marginBottom: "10px",
      cursor: "pointer",
    },
    sortChoose: {
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: 600,
    },
    divider: {
      marginTop: "10px",
      marginLeft: "10px",
      marginRight: "10px",
    },
    titleRN: {
      display: "none",
    },
    buttonUrgentCare: {
      color: "white",
    },
    buttonUrgentCareActive: {
      color: theme.palette.secondary.main,
    },
    changeViewBtn: {
      display: "none",
    },
    changeViewBtnRN: {
      display: "contents",
      alignItems: "center",
    },
    headerRN: {
      backgroundColor: theme.palette.primary.main,
    },
    iconRN: {
      color: "white",
    },
    inputRN: {
      backgroundColor: "white",
      borderRadius: "5px",
    },
    scrollableContainer: {
      position: "absolute",
      width: "95%",
      zIndex: 10,
    },
    list: {
      display: "flex",
      overflow: "auto",
      padding: 0,
      margin: 0,
    },
    item: {
      display: "flex",
      width: "400px",
      marginRight: "10px",
      borderRadius: "8px",
    },
  };
});

export default useStyles;
