import * as Yup from "yup";

export const signinSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  cellPhone: Yup.string().required("Cell Phone is required").min(10, "Cell Phone must be 10 digit"),
  email: Yup.string().required("Email is required").email("Email is not valid"),
  dob: Yup.date()
    .nullable()
    .max(new Date(), "Date of Birth should not be after current date")
    .required("Date of Birth is required")
    .typeError("Invalid Date of Birth"),
  zip: Yup.string().required("Zip Code is required").min(5, "Zip code must be 5 digit"),
});
