import { API_ENDPOINTS, axiosClient } from "util/api_helper";

export const UseMsal = () => {
  const login = async (token: string) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosClient.get(API_ENDPOINTS.loginMsal, { headers });
      return data;
    } catch (error) {
      console.error("[MSAL] Error during login:", error);
      throw error;
    }
  };

  return {
    login,
  };
};
