/** @format */
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  button: {
    width: "100%",
    textTransform: "uppercase",
    height: "42px",
  },
  divStyle: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    justifyContent: "center",
  },
}));

const SearchComponent = ({ handleSearch, disabled }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.divStyle}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<SearchIcon />}
        onClick={handleSearch}
        disabled={disabled}
        data-cy="searchButton"
      >
        Search
      </Button>
    </div>
  );
};

export default SearchComponent;
