import { makeStyles } from "tss-react/mui";

export const usePatientChatStyles = makeStyles()((theme) => ({
  loaderDiv: {
    display: "flex",
    height: "80vh",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "74vh",
    overflowY: "auto",
  },
  closeChatWindow: {
    cursor: "pointer",
    display: "flex",
    background: "transparent",
    border: "0",
    padding: "0.4em",
  },
  headerMessage: {
    textAlign: "left",
    padding: "20px",
    marginBottom: "0px",
    paddingBottom: "0px",
  },
  staffMessage: {
    textAlign: "left",
    backgroundColor: "#E0E0E0",
    borderRadius: "5px",
    padding: "5px",
    width: "70%",
    marginRight: "auto",
  },
  patientMessage: {
    textAlign: "right",
    backgroundColor: theme.palette.primary.light,
    borderRadius: "5px",
    padding: "5px",
    width: "70%",
    marginLeft: "auto",
    "& span": {
      color: `${theme.palette.common.white} !important`,
    },
  },
  chatWindowContainer: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      top: "67px",
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 100,
    },
  },
  textFieldContainer: {
    padding: "0px 10px 50px 10px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 10px 80px 10px",
    },
  },
  positionEnd: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "16px",
    },
  },
}));

export const useVisitDetails = makeStyles()((theme) => ({
  headingLine: {
    height: 30,
  },
  subHeading: {
    fontSize: "12px",
  },
  subText: {
    fontSize: "14px",
  },
  topHeading: {
    color: theme.palette.common.black,
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "18px",
    textAlign: "left",
    margin: "0",
  },
  root: {
    textAlign: "left",
    position: "relative",
    width: "100%",
    "&::before": {
      content: '""',
      background: theme.palette.secondary.main,
      position: "absolute",
      height: "6rem",
      width: "100%",
    },
  },
  media: {
    textAlign: "left",
    boxSizing: "border-box",
  },
  descriptionText: {
    marginTop: 0,
  },
  descriptionContainer: {
    flexGrow: 1,
  },
  cardContainer: {
    padding: "2rem 1rem",
    position: "relative",
    gap: "1rem",
    flexWrap: "nowrap",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#dbdbdb",
  },
  img: {
    width: "100%",
    height: "100%",
    maxWidth: 110,
    maxHeight: 110,
    position: "relative",
  },
  cardContent: {
    display: "flex",
  },
  heading: {
    fontSize: 18,
    color: theme.palette.common.black,
  },
  cardContentDiv: {
    padding: 0,
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    textAlign: "left",
  },
  insideCards: {
    width: "60%",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  cardTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  tagsContainer: {
    display: "flex",
  },
  tag: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: 10,
    marginLeft: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 10,
    paddingRight: 10,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 3,
  },
  fontSize18: {
    fontSize: "18px",
  },
  tabRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: "6px 6px 0px 0px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "unset",
  },
  tabSelected: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "6px 6px 0px 0px",
    [theme.breakpoints.down("md")]: {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "none",
    },
  },
  textColorPrimary: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Alegreya Sans",
    textTransform: "capitalize",
    fontSize: "24px",
    "&.Mui-selected": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  indicator: {
    display: "none",
  },
  tabsRoot: {
    marginBottom: "-1px",
  },
  contentContainer: {
    textAlign: "left",
    background: theme.palette.common.white,
    borderRadius: "0px 6px 6px 6px",
    marginBottom: "40px",
    padding: "24px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  detTitle: {
    color: "#rgba(0, 0, 0, 0.87)",
    fontFamily: "Alegreya Sans",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    marginRight: "7px",
  },
  detItem: {
    marginBottom: "8px",
  },
  cellPhone: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  wrapper: {
    margin: "60px 0px",
  },
  detDescr: {
    fontFamily: "Alegreya Sans",
  },
}));

export const PhysicianNotesStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    marginBottom: "20px",
  },
  textField: {
    textAlign: "left",
    letterSpacing: "0.15px",
    color: theme.palette.common.white,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    margin: "4px 0px",
  },
  textFieldDesc: {
    marginTop: "4px",
    letterSpacing: "0.15px",
    color: theme.palette.common.white,
    textAlign: "left",
  },
  tagContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
  },
  tag: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "center",
    padding: "2px 10px",
    minWidth: "60px",
    textTransform: "uppercase",
    background: "#E0E0E0",
    borderRadius: "16px",
  },
  text: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  icon: {
    color: theme.palette.common.white,
    paddingTop: "5px",
  },
}));
