import React from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { FormControl, InputLabel, Typography } from "@mui/material";
import { waitingUsersTableStyles } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/styles";
import { ORDER } from "util/constants";

type OrderByProps = {
  order: ORDER;
  setOrder: (order: ORDER) => void;
};

const OrderBy: React.FC<OrderByProps> = ({ order, setOrder }) => {
  const { classes } = waitingUsersTableStyles();

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel className={classes.label}>OrderBy</InputLabel>
      {order === ORDER.asc && (
        <div
          data-testid="order-asc"
          className={classes.orderByContainer}
          onClick={() => setOrder(ORDER.desc)}
        >
          <Typography variant="body1">ASC</Typography>
          <ArrowUpward fontSize="small" className={classes.arrow} />
        </div>
      )}
      {order === ORDER.desc && (
        <div
          data-testid="order-desc"
          className={classes.orderByContainer}
          onClick={() => setOrder(ORDER.asc)}
        >
          <Typography variant="body1">DESC</Typography>
          <ArrowDownward fontSize="small" className={classes.arrow} />
        </div>
      )}
    </FormControl>
  );
};

export default OrderBy;
