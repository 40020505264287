import { useState } from "react";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

interface PatientInfo {
  mrn: string;
  patientLastName: string;
}

interface UseSearchPatientParams {
  endpoint: string;
  searchBy: string;
  getErrorMessage: () => string;
}

export const useGetPatientInfo = ({
  endpoint,
  searchBy,
  getErrorMessage,
}: UseSearchPatientParams) => {
  const app = useApp();
  const [patientInfo, setPatientInfo] = useState<PatientInfo | undefined>();
  const [error, setError] = useState<string>("");

  const handleSearchPatient = async (searchText: string) => {
    try {
      const resp = await axiosClient.get(
        `${endpoint}?search_by=${searchBy}&search_text=${searchText}`,
      );
      if (resp.data) {
        if (resp.data.data.length > 0) {
          setPatientInfo({
            mrn: resp.data.data[0].mrn || resp.data.data[0].orderMrn,
            patientLastName:
              resp.data.data[0].patientLastName || resp.data.data[0].orderPatientLastName,
          });
        } else {
          setPatientInfo({ mrn: "", patientLastName: "" });
          app.addError(getErrorMessage());
        }
      }
    } catch {
      app.addError(getErrorMessage());
    }
  };

  return { patientInfo, error, setError, handleSearchPatient };
};
