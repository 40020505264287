import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { withStyles } from "tss-react/mui";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

const Title = withStyles(({ children, classes, onClose, ...other }) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
}, styles);

const Content = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const Actions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

interface EditModalProps {
  readonly show: boolean;
  readonly onSave: (data: any) => void;
  readonly onClose: () => void;
  readonly children: React.ReactNode;
  readonly title: string;
  readonly subtitle: string;
  readonly isError?: boolean;
  readonly saveButtonText?: string;
}

export default function GenericModal({
  show,
  onSave,
  onClose,
  children,
  title,
  subtitle,
  isError,
  saveButtonText = "Save",
}: EditModalProps) {
  const handleClose = () => {
    onClose();
  };

  const onSaveClicked = () => {
    onSave("");
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="change-request-modal"
      open={show}
      maxWidth="md"
      PaperProps={{ style: { maxWidth: "800px" } }}
    >
      <Title id="change-request-modal-title" onClose={handleClose}>
        {title}
      </Title>
      <Content dividers style={{ padding: "16px 26px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="500" style={{ marginBottom: "16px" }}>
          {subtitle}
        </Typography>
        {children}
      </Content>
      <Actions style={{ display: "flex", justifyContent: "center", padding: "16px 0" }}>
        <Button
          autoFocus
          onClick={() => handleClose()}
          variant="outlined"
          style={{ width: "250px" }}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={() => onSaveClicked()}
          color="primary"
          variant="contained"
          disabled={isError}
          style={{ width: "250px" }}
        >
          {saveButtonText}
        </Button>
      </Actions>
    </Dialog>
  );
}
