import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { QuerySteps } from "../Steps";
import { Program } from "components/Video/utils/types";
import VirtualVisitConsent from "components/consents/VirtualVisitConsent";
import { ConsentPath } from "util/constants";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useProgramType } from "util/programsHelpers";
import { useStyles } from "./styles";

type Props = {
  vsId: number | undefined;
  setStep: (step: QuerySteps) => void;
};

const Consent: React.FC<Props> = ({ vsId, setStep }) => {
  const app = useApp();
  const [program, setProgram] = useState<Program>();
  const { isAdhoc } = useProgramType(program);

  const { classes } = useStyles({ isAdhoc });

  const onSubmit = (values: { consent: boolean }, actions: FormikHelpers<{ consent: boolean }>) => {
    localStorage.setItem("consent", JSON.stringify(values.consent));
    actions.setSubmitting(false);
    setStep(isAdhoc ? QuerySteps.PREVIEW : QuerySteps.START_TEST);
  };

  const formik = useFormik({
    initialValues: { consent: false },
    validationSchema: Yup.object({
      consent: Yup.boolean().isTrue(),
    }),
    onSubmit,
  });

  const getVirtualSession = async () => {
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.twilio_get_virtual_session, {
        vs_id: vsId,
      });
      setProgram(data.program);
      formik.setFieldValue("consent", !!data.consent_id);
    } catch (e) {
      app.addError("Something went wrong. Please, contact support.");
    }
  };

  useEffect(() => {
    if (vsId) {
      getVirtualSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  return (
    <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
      <Box flexBasis="80%" overflow="auto" marginBottom="16px">
        <VirtualVisitConsent />
      </Box>
      <div className={classes.bottomNav}>
        <FormControlLabel
          classes={{ label: classes.checkBoxText }}
          control={
            <Checkbox
              name="consent"
              checked={formik.values.consent}
              onChange={formik.handleChange}
              color="primary"
            />
          }
          label={
            <>
              To continue please agree to Atlantic Health System's treatment, payment, and
              healthcare operations outlined on{" "}
              <Link to={ConsentPath.VIRTUAL_VISIT} target="_blank" rel="noreferrer">
                this form
              </Link>
            </>
          }
        />
        <div className={classes.buttonsContainer}>
          {!isAdhoc && (
            <Button
              className={classes.button}
              onClick={() => setStep(QuerySteps.INSURANCE_DETAILS)}
              variant="outlined"
            >
              Back
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={formik.isSubmitting || !formik.isValid || !program}
          >
            Next
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Consent;
