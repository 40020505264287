import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "tss-react/mui";
import { useApp } from "../util/AppContext";
import { useAuth } from "../util/Security";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  paper: {
    flexGrow: 1,
    margin: "auto",
    width: "80%",
  },
  textField: {
    width: "40ch",
  },
}));

export default function SignupView() {
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [gender, setGender] = useState("");
  const [code, setCode] = useState("");
  const sentCode = false;
  const auth = useAuth();
  const navigate = useNavigate();
  const app = useApp();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/home" } };

  const handleSignupSubmit = async (event) => {
    event.preventDefault();
    if (
      email.length < 1 ||
      phone.length < 1 ||
      password.length < 1 ||
      firstName.length < 1 ||
      lastName.length < 1 ||
      dob.length < 1 ||
      zipCode.length < 1
    ) {
      app.addError("Please enter values for all fields to be able to register, thank you.");
      return;
    }
    await auth.directSignup(
      email,
      password,
      phone,
      firstName,
      lastName,
      dob,
      street1,
      street2,
      gender,
      zipCode,
      (user, error) => {
        if (!user || error) {
          app.addError("Error: Signup attempt failed, please try again");
          return;
        }
        // setSentCode(true); // TODO later send code
        navigate(from, { replace: true });
      },
    );
  };

  const handleSignupConfirmationSubmit = async (event) => {
    event.preventDefault();
    await auth.confirmSignUp(email, code, password, (error) => {
      if (error) {
        app.addError(`Error: Signup confirmation attempt failed: ${error.message}`);
        return;
      }
      navigate("/home", { replace: true });
    });
  };

  return (
    <div className={classes.root}>
      <br />
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <h1>Welcome to MyAtlantic</h1>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h3>Register for your central access point to Atlantic Health System.</h3>
          </Paper>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {!sentCode && (
              <form className={classes.root} noValidate autoComplete="off">
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      className={classes.textField}
                      value={email}
                      onInput={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="phone"
                      label="Cell Phone"
                      variant="outlined"
                      className={classes.textField}
                      value={phone}
                      onInput={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="firstName"
                      label="Legal First Name"
                      variant="outlined"
                      className={classes.textField}
                      value={firstName}
                      onInput={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="lastName"
                      label="Legal Last Name"
                      variant="outlined"
                      className={classes.textField}
                      value={lastName}
                      onInput={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      style={{
                        width: "40ch",
                      }}
                      format="MM/dd/yyyy"
                      margin="normal"
                      label="Date of Birth"
                      value={dob}
                      onChange={(date) => {
                        setDob(date);
                      }}
                      inputVariant="outlined"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          helperText: "MM/DD/YYYY",
                          inputVariant: "outlined",
                          id: "sign-up-dob",
                        },
                        openPickerButton: {
                          "aria-label": "change date",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="street1"
                      label="Street Line 1"
                      variant="outlined"
                      className={classes.textField}
                      value={street1}
                      onInput={(e) => setStreet1(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="street2"
                      label="Street Line 2"
                      variant="outlined"
                      className={classes.textField}
                      value={street2}
                      onInput={(e) => setStreet2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="zipCode"
                      label="Zip Code"
                      variant="outlined"
                      className={classes.textField}
                      value={zipCode}
                      onInput={(e) => setZipCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="gender"
                      label="Sex at Birth"
                      select
                      value={gender}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <MenuItem key="male" value="male">
                        Male
                      </MenuItem>
                      <MenuItem key="female" value="female">
                        Female
                      </MenuItem>
                      <MenuItem key="other" value="other">
                        Other
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      className={classes.textField}
                      value={password}
                      helperText="Password must least 8 characters and contain an Uppercase, lowercase, number, and special character: '$', '@', '#', '%', '!', '#', '*', '&amp;'"
                      onInput={(e) => setPassword(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSignupSubmit}
                    >
                      Sign Up
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
            {sentCode && (
              <form className={classes.root} noValidate autoComplete="off">
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      We sent you a verification code, pleae enter it below:
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="code"
                      label="Verification Code"
                      variant="outlined"
                      value={code}
                      onInput={(e) => setCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSignupConfirmationSubmit}
                    >
                      Verify Account
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
