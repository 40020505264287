import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

interface CampaignTags {
  data: string[];
}

export const useGetCampaignTags = (submissionId?: number) => {
  const { addError } = useApp();
  const url = submissionId
    ? `${API_ENDPOINTS.campaignTags}/${submissionId}`
    : API_ENDPOINTS.campaignTags;

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchQuery = (): Promise<CampaignTags> => axiosClient.get(url);

  const {
    data: tags,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.campaignTags, submissionId],
    queryFn: fetchQuery,
    staleTime: 0,
    enabled: true,
    onError: () => addError(errorMsg),
  });

  return {
    tags,
    refetch,
    isLoading,
    isFetching,
  };
};
