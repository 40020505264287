import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { StepTitleToolbar } from "./Common";
import { StepsProps } from "../types/constants";
import { SignaturePad } from "components/SignaturePad";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { urltoFile } from "util/urlToFile";
import useStyles from "../styles/forms.styles";

const DriverSignaturePad = ({ onSave, formId }) => {
  const {
    web: {
      dotForm: {
        patient: {
          signature: {
            title,
            body,
            savedSignature,
            noImageSaved,
            replaceSignature,
            errorOcurredSavingSignature,
            signatureSavedCorrectly,
          },
        },
      },
    },
  } = useTranslation();
  const app = useApp();
  const { classes } = useStyles({});

  const [image, setImage] = useState<string | undefined>(undefined);
  const [showSignaturePad, setShowSignaturePad] = useState(true);

  async function handleImageSave(image: string) {
    const signatureImage = await urltoFile(image, "signatureImage.png", "image/png");
    const formData = new FormData();
    formData.append("signatureImage", signatureImage);

    try {
      const result = await axiosClient.post(
        `${API_ENDPOINTS.dotSignatureUpload}/${formId}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (result.data?.error.message) {
        app.enqueueSnackbar(result.data?.error.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      } else {
        setImage(image);
        setShowSignaturePad(false);
        app.enqueueSnackbar(signatureSavedCorrectly, {
          variant: "success",
          autoHideDuration: 2000,
        });
        onSave(image);
      }
      return result;
    } catch (error) {
      console.log(error);
      app.enqueueSnackbar(errorOcurredSavingSignature, {
        variant: "error",
        autoHideDuration: 2000,
      });
      return null;
    }
  }

  const handleReplaceImage = () => {
    setImage("");
    onSave("");
    setShowSignaturePad(true);
  };

  return (
    <Grid item>
      <Typography align="center" variant="h4" className={classes.centeredTitle}>
        {title}
      </Typography>
      <Grid item style={{ marginTop: "10px" }}>
        <Typography variant="body1">{body[0]}</Typography>
      </Grid>
      <Grid item style={{ marginBottom: "10px" }}>
        <Typography variant="body1">
          {body[1]}
          <a
            href="https://www.ecfr.gov/current/title-49/subtitle-B/chapter-III/subchapter-B/part-390#390.35"
            target="_blank"
            rel="noreferrer"
          >
            {body[2]}
          </a>
          {body[3]}
          <a
            href="https://www.ecfr.gov/current/title-49/subtitle-B/chapter-III/subchapter-B/part-390#390.37"
            target="_blank"
            rel="noopener noreferrer"
          >
            {body[4]}
          </a>
          {body[5]}
          <a
            href="https://www.ecfr.gov/current/title-49/subtitle-B/chapter-III/subchapter-B/part-386/subpart-G"
            target="_blank"
            rel="noopener noreferrer"
          >
            {body[6]}
          </a>
          {body[7]}
        </Typography>
      </Grid>

      {showSignaturePad ? (
        <SignaturePad onSave={handleImageSave} /> // eslint-disable-line react/jsx-no-bind
      ) : (
        <div>
          {image ? (
            <img
              src={image}
              alt={savedSignature}
              style={{ width: "100%", height: "auto" }}
              data-cy="savedSignatureStep"
            />
          ) : (
            <p>{noImageSaved}</p>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleReplaceImage}
            style={{ marginTop: "10px" }}
            disabled={!image}
          >
            {replaceSignature}
          </Button>
        </div>
      )}
    </Grid>
  );
};

const DriverSignatureStep: React.FC<StepsProps> = ({
  handleChange,
  showTitle = false,
  isFormDisabled = false,
  handleStepChange,
  formId,
}) => {
  const { classes } = useStyles({
    alignLeft: showTitle,
  });

  const {
    web: {
      dotForm: {
        patient: { signature: d },
      },
    },
  } = useTranslation();
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(isFormDisabled);
  const [isSaveMode, setIsSaveMode] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const signatureImageRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    const setContinueButtonAsDisabled = () => {
      if (!signatureImageRef.current) {
        handleStepChange?.({ shouldDisableContinueButton: true });
      }
    };

    setContinueButtonAsDisabled();
  }, [handleStepChange]);

  const actionClickHandler = (isSaveMode: boolean) => {
    handleStepChange?.({
      saveProgress: true,
      shouldDisableContinueButton: !signatureImageRef.current,
    });
  };

  const showStepTitle = () => {
    if (showTitle || isMobile) {
      return (
        <Typography align="center" className={classes.formTitle}>
          {d.title}
        </Typography>
      );
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      className={showTitle ? classes.relationship_wrapper_dashboard : classes.relationship_wrapper}
      spacing={3}
      data-testid="signature-form"
    >
      <Grid item container xs={12} md={12}>
        <Grid item xs={12} md={6}>
          {showStepTitle()}
        </Grid>
        {(shouldDisableForm || isSaveMode) && (
          <StepTitleToolbar
            classes={classes}
            actionClickHandler={() => actionClickHandler(isSaveMode)}
            isSaveMode={isSaveMode}
          />
        )}
      </Grid>
      <Grid item container direction="column" spacing={2} className="disclaimer">
        <DriverSignaturePad
          onSave={(image) => {
            signatureImageRef.current = image;
            handleChange("signatureImage", image);
            handleStepChange?.({ shouldDisableContinueButton: false });
          }}
          formId={formId}
        />
      </Grid>
    </Grid>
  );
};

export default DriverSignatureStep;
