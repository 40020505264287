import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import useTranslation from "hooks/useTranslation";
import { useStyles } from "../../utils/styles";

interface Props {
  handleSave: () => void;
  handleCancel?: () => void;
  isEditing: boolean;
  isLoading: boolean;
  disableEditButton: boolean;
}

const HoursAndScheduleHeader = ({
  handleSave,
  handleCancel,
  isEditing,
  isLoading,
  disableEditButton,
}: Props) => {
  const { classes } = useStyles();
  const {
    web: {
      dataCuration: {
        location: {
          hoursAndSchedule: { disclaimerHeader, titleHeader },
        },
      },
    },
  } = useTranslation();

  const {
    locationData: { hoursAndSchedule },
    currentPayload,
    setCurrentPayload,
  } = useLocationContext();
  const editButtonText = isEditing ? "Save" : "Edit";

  const [overrideMessage, setOverrideMessage] = useState(hoursAndSchedule.overrideMessage || "");

  const handleOverrideMessageChange = () => {
    setCurrentPayload((prevPayload) => ({
      ...prevPayload,
      hoursAndSchedule: {
        ...(prevPayload?.hoursAndSchedule || {}),
        overrideMessage,
      },
    }));
  };

  useEffect(() => {
    if (!currentPayload) {
      setOverrideMessage(hoursAndSchedule.overrideMessage || "");
    }
  }, [currentPayload]);

  return (
    <>
      <Box className={classes.scheduleHeader}>
        <Typography variant="subtitle1" fontWeight="bold" color="#043070">
          {disclaimerHeader}
        </Typography>
      </Box>
      <Box className={classes.scheduleHeader} data-testid="hours-schedule-element">
        <Typography variant="h6">{titleHeader}</Typography>
        <div>
          {isEditing && (
            <Button
              onClick={handleCancel}
              variant="outlined"
              color="primary"
              id="edit_button"
              style={{ width: "64px", height: "40px", marginRight: "16px" }}
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={handleSave}
            variant={isEditing ? "contained" : "outlined"}
            color="primary"
            id="edit_button"
            disabled={disableEditButton}
            style={{ width: "64px", height: "40px" }}
          >
            {isLoading ? (
              <CircularProgress color="inherit" style={{ width: "22px", height: "22px" }} />
            ) : (
              editButtonText
            )}
          </Button>
        </div>
      </Box>

      <Grid item xs={12} md={12} paddingTop="24px">
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="overrideMessage"
            name="overrideMessage"
            size="medium"
            value={overrideMessage}
            onChange={(event) => setOverrideMessage(event.target.value)}
            onBlur={handleOverrideMessageChange}
            variant="outlined"
            label="Override Message"
            disabled={!isEditing}
            helperText={false}
            data-cy="Practice-overrideMessageInput"
            data-testid="Practice-overrideMessageInput"
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default HoursAndScheduleHeader;
