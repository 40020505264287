import { API_ENDPOINTS, axiosClient } from "util/api_helper";

const doPatch = async (providerId, currentPayload, refetch, app, onCancelSection) => {
  try {
    await axiosClient.patch(`${API_ENDPOINTS.dataCurationProviders}${providerId}`, {
      ...currentPayload,
    });
    await refetch();
    app.addInfoMsg("Provider updated successfully");
  } catch (error: any) {
    if (error.response) {
      app.addError(error.response.data.description);
    } else {
      app.addError("Error updating location. Please try again.");
    }
  } finally {
    onCancelSection();
  }
};

export const onCancelSection = ({
  currentlyEditing,
  setIsEditing,
  setSections,
  setIsLoading,
  setCurrentPayload,
  setCurrentlyEditing,
}) => {
  if (typeof currentlyEditing === "string") {
    setIsEditing((prev) => ({
      ...prev,
      [currentlyEditing]: false,
    }));
    setSections((prev) => ({
      ...prev,
      [currentlyEditing]: true,
    }));
    setIsLoading((prev) => ({
      ...prev,
      [currentlyEditing]: false,
    }));
  }
  setCurrentPayload(null);
  setCurrentlyEditing(null);
};

export const handleEditProvider = async (
  slug,
  setIsEditing,
  setSections,
  setCurrentlyEditing,
  currentPayload,
  setIsLoading,
  providerId,
  refetch,
  app,
  onCancelSection,
) => {
  setIsEditing((prev) => ({
    ...prev,
    [slug]: !prev[slug],
  }));
  setSections((prev) => ({
    ...prev,
    [slug]: !prev[slug],
  }));

  setCurrentlyEditing(slug);

  if (currentPayload) {
    setIsLoading((prev) => ({
      ...prev,
      [slug]: true,
    }));

    await doPatch(providerId, currentPayload, refetch, app, onCancelSection);
  }
};
