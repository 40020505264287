import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { QuerySteps } from "./Steps";
import UploadService from "components/FileUploadWidget/upload-files.service";
import UploadImages from "components/FileUploadWidget/upload-images.component";
import { SET_IS_IMAGES_LIST_SAVED } from "components/Video/hooks/usePatientStepsReducer/Actions";
import useTranslation from "hooks/useTranslation";
import { useUserInfo } from "hooks/useUserInfo/useUserInfo";
import { useApp } from "util/AppContext";
import { getIsProgramName } from "util/programsHelpers";
import { useConsultationStyles } from "./styles";

interface UploadReportsProps {
  setStep: (queryStep: QuerySteps) => void;
}

const UploadReports = ({ setStep }: UploadReportsProps) => {
  const {
    web: {
      common: { next, back },
      uploadReports: { title, subtitle, uploadLabel },
    },
  } = useTranslation();
  const { classes } = useConsultationStyles();
  const { programName, vsId } = useParams<{ programName: string; vsId: string }>();
  const app = useApp();
  const { user } = useUserInfo();
  const userId = user?.userId;
  const { isImageListSaved } = app.patient_steps_state;
  const { isPhysicalTherapy } = getIsProgramName(programName);

  const uploadImage = async (id: string | number) => {
    const imagesList2 = app.patient_steps_state.imagesList;
    const { contextType } = app.patient_steps_state;
    const { contextID } = app.patient_steps_state;
    let uploadedImages = 0;

    for (let i = 0; i < imagesList2.length; i++) {
      const imageInfo = imagesList2[i];
      let imageToBeUpload = imageInfo.currentFile;
      const imageType = imageInfo.imageType ? imageInfo.imageType : "General";
      if (imageInfo.capturedImage) {
        imageToBeUpload = new File([imageInfo.currentFile], imageInfo.name, {
          type: "image/png",
        });
      }
      await UploadService.upload(imageToBeUpload, contextType, contextID, imageType, id)
        // eslint-disable-next-line
        .then((response) => {
          if (response.status === 200) {
            uploadedImages += 1;
          }
          setStep(QuerySteps.SCHEDULE);
          app.patient_steps_dispatch({ type: SET_IS_IMAGES_LIST_SAVED, payload: true });
        })
        .catch((err) => {
          setStep(QuerySteps.UPLOAD);
          console.error(err);
          app.patient_steps_dispatch({ type: SET_IS_IMAGES_LIST_SAVED, payload: false });
        });
    }
    if (imagesList2.length !== uploadedImages) {
      app.addError("Problem uploading images, please contact support.");
    } else {
      setStep(!isPhysicalTherapy ? QuerySteps.SCHEDULE : QuerySteps.LOCATION);
    }
  };

  const handleNext = async () => {
    if (!isImageListSaved) {
      try {
        await uploadImage(userId as string | number);
      } catch (error) {
        app.addError("Error: Failed to upload image");
      }
    } else {
      setStep(!isPhysicalTherapy ? QuerySteps.SCHEDULE : QuerySteps.LOCATION);
    }
  };

  return (
    <>
      <h1 className={classes.heading}>{title}</h1>
      <div className={classes.subTitleText}>{subtitle}</div>
      <div className={classes.uploadLabel}>{uploadLabel}</div>
      <UploadImages
        contextType="Consultation Request"
        contextID={vsId}
        upload_msg="Take Picture"
        imageType="Consultation Request"
        allowMultipleFiles
      />
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          onClick={() => {
            setStep(QuerySteps.SCREENING);
          }}
          variant="contained"
        >
          {back}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          data-cy="uploadNextBtn"
        >
          {next}
        </Button>
      </div>
    </>
  );
};

export default UploadReports;
