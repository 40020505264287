import { React, useCallback, useEffect, useState } from "react";
import Loader from "components/Loader";
import ChatWindow from "components/Video/components/ChatWindow/ChatWindow";
import { usePatientChatStyles } from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/styles";
import useChatContext from "components/Video/hooks/useChatContext/useChatContext";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { UserTypes, axiosClient } from "util/api_helper";

const PatientChat = ({ vsId, isReadOnly = false }) => {
  const { classes } = usePatientChatStyles();
  const [loading, setLoading] = useState(false);
  const { messages, convRecvd, chatClient } = useChatContext();

  const app = useApp();
  const auth = useAuth();
  const [lastMessages, setLastMessages] = useState([]);
  const [renderedMessages, setRenderedMessages] = useState([]);
  const isPatient =
    auth?.user.userType === UserTypes.PATIENT || auth?.user.userType === UserTypes.CONSUMER;

  const handleGetMessages = useCallback(async () => {
    try {
      setLoading(true);
      let url = `/api/s/console/virtualvisit/load_messages?vv_id=${vsId}`;
      if (isPatient) {
        url = `/api/s/virtualvisit/load_messages?vv_id=${vsId}`;
      }
      const { data } = await axiosClient.get(url);
      setLastMessages(data.data);
    } catch (err) {
      app.addError(err.response?.data?.description);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  useEffect(() => {
    if (vsId) {
      handleGetMessages();
    }
    return () => setLastMessages([]);
  }, [vsId, handleGetMessages]);

  useEffect(() => {
    if (convRecvd && chatClient?.connectionState === "connected") {
      setRenderedMessages(messages);
    } else {
      setRenderedMessages(lastMessages);
    }

    return () => setRenderedMessages([]);
  }, [convRecvd, chatClient?.connectionState, lastMessages, messages]);

  return !loading ? (
    <ChatWindow
      messages={renderedMessages}
      vsId={vsId}
      handleGetMessages={handleGetMessages}
      isReadOnly={isReadOnly}
    />
  ) : (
    <div className={classes.loaderDiv}>
      <Loader text="" />
    </div>
  );
};

export default PatientChat;
