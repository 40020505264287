import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  content: {
    textAlign: "center",
    margin: 0,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: ["Alegreya Sans", "sans-serif"].join(","),
  },
  text: {
    fontWeight: 400,
    lineHeight: 1.75,
  },
  btnText: {
    fontSize: "14px",
  },
  descriptionText: {
    fontSize: "14px",
    marginTop: 0,
  },
  btnPrimary: {
    backgroundColor: "#1a237e",
    width: "190px",
    height: "38px",
    border: 0,
    boxShadow: "none",
    borderRadius: "0px",
    color: "white",
    textTransform: "uppercase",
    marginTop: 0,
    "&:hover": {
      backgroundColor: "#283593",
    },
  },

  img: {
    maxHeight: "370px",
  },

  h1: {
    fontWeight: 400,
    fontSize: "3.052rem",
    lineHeight: 1.3,
  },
}));

export default useStyles;
