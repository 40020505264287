import React, { useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import { camelCase, upperFirst } from "lodash";
import { Close, TransferWithinAStation } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { FormValues, initialValues, validationSchema } from "./FormModel";
import { makeStyles } from "tss-react/mui";
import Tags from "../components/OrderTags";
import { VirtualSessionQueue } from "util/constants";
import useDisconnectActiveSession from "../useDisconnectActiveSession";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { API_ENDPOINTS, UserTypes, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { useProgramType } from "util/programsHelpers";
import { transformCase } from "util/util_functions";

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    marginBottom: "8px",
  },
}));

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const TransferModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const { isVUC } = useProgramType();
  const disconnectSession = useDisconnectActiveSession();

  const app = useApp();
  const currentVirtualVisit = app?.vs_visits_state?.currentVirtualVisit;

  const doShowTags =
    isVUC &&
    (user.userType === UserTypes.PHYSICIAN ||
      currentVirtualVisit.queue === VirtualSessionQueue.PHYSICIAN_VISIT);

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      if (currentVirtualVisit.queue === VirtualSessionQueue.PHYSICIAN_VISIT) {
        await axiosClient.put(
          `/api/s/console/virtualvisit/sessions/${currentVirtualVisit.id}/tags`,
          values.tags,
        );
      }
      await axiosClient.post(API_ENDPOINTS.transfer_queue, {
        virtual_session_id: currentVirtualVisit.id,
        queue: values.transferQueue,
        ...(values.notes && { notes: values.notes }),
      });
      disconnectSession();
      app.addInfoMsg("User has been transferred successfully.");
      actions.setSubmitting(false);
      handleClose();
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  useEffect(() => {
    if (currentVirtualVisit.checkout_note) {
      formik.setFieldValue("notes", currentVirtualVisit.checkout_note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit]);

  useEffect(() => {
    if (formik.values.tags.length) {
      formik.setFieldValue("transferQueue", VirtualSessionQueue.CHECKOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.tags]);

  return (
    <Dialog onClose={handleClose} open={isOpen} fullScreen={isMobile} maxWidth="sm" fullWidth>
      <DialogTitle>Transfer Session</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className={classes.closeButton}
        size="large"
      >
        <Close />
      </IconButton>
      <DialogContent>
        <form id="transfer-session-modal" onSubmit={formik.handleSubmit}>
          {doShowTags && (
            <Box marginBottom="8px">
              <Typography variant="body1">
                Please check the orders that correspond to the patient’s visit.
              </Typography>
              <Box marginBottom="8px">
                <Tags
                  checkedTags={formik.values.tags}
                  setCheckedTags={(tags) => formik.setFieldValue("tags", tags)}
                />
              </Box>
              <TextField
                className={classes.textField}
                label="Other Notes"
                multiline
                fullWidth
                minRows={1}
                maxRows={4}
                variant="outlined"
                value={formik.values.notes}
                onChange={formik.handleChange}
                name="notes"
                data-cy="notesInputDialog"
              />
            </Box>
          )}
          <TextField
            label="Transfer to"
            variant="outlined"
            name="transferQueue"
            fullWidth
            select
            value={formik.values.transferQueue}
            onChange={formik.handleChange}
            error={formik.touched.transferQueue && !!formik.errors.transferQueue}
            helperText={formik.touched.transferQueue && formik.errors.transferQueue}
            data-cy="transferSelect"
          >
            {[
              VirtualSessionQueue.REGISTERING,
              VirtualSessionQueue.NURSE_TRIAGE,
              VirtualSessionQueue.PHYSICIAN_VISIT,
              VirtualSessionQueue.CHECKOUT,
            ].map((queue) => (
              <MenuItem
                value={queue}
                key={queue}
                data-cy={`transferOption${upperFirst(camelCase(queue))}`}
              >
                {transformCase(queue)}
              </MenuItem>
            ))}
          </TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          form="transfer-session-modal"
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formik.isValid || formik.isSubmitting}
          startIcon={<TransferWithinAStation />}
          data-cy="transferButtonDialog"
        >
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferModal;
