import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, CircularProgress, Step, StepLabel, Stepper, Typography } from "@mui/material";
import AppointmentDetails from "./AppointmentDetails";
import AppointmentInfoScreen from "./AppointmentInfoScreen";
import DateAndTime from "./DateAndTime";
import PatientInfo from "./PatientInfo";
import ReviewAndBook from "./ReviewAndBook";
import { AppointmentInfo, PatientInformation, ProviderInfo } from "components/FindADoctor/types";
import { API_HOST } from "util/constants/config";
import { useApp } from "util/AppContext";
import useStyles from "./BookingOnline.styles";

const STEP = {
  first: 0,
  second: 1,
  third: 2,
  fourth: 3,
};

const BookingOnline = () => {
  const location = useLocation();
  const providerId = location.state;
  const [provider, setProvider] = useState<ProviderInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientRelationship, setPatientRelationship] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");
  const [appointment, setApponitment] = useState<AppointmentInfo>();
  const [value, setValue] = useState<PatientInformation>();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    { title: "Appointment Info", id: 0 },
    { title: "Date and Time", id: 1 },
    { title: "Patient Info", id: 2 },
    { title: "Review and Book", id: 3 },
  ];
  const { classes } = useStyles();
  const app = useApp();

  const getProvider = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("dfd.kyruus.token");
      const url = `${API_HOST.kyruusApi}v9/atlantic/providers/${providerId}?access_token=${accessToken}`;
      const { data } = await axios(url);
      setProvider(data);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const changePage = (page) => {
    if (page < activeStep) {
      setActiveStep(page);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} className={classes.stepperDesctop}>
        {steps.map((item) => (
          <Step key={item.id}>
            <StepLabel onClick={() => changePage(item.id)}>{item.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.stepperMobile}>
        {activeStep > 0 && (
          <Button onClick={handleBack} className={classes.backButton}>
            <ArrowBackIosIcon className={classes.arrowBack} />
            Back
          </Button>
        )}
        <Typography>{steps[activeStep].title}</Typography>
      </div>
      {activeStep === steps.length ? (
        <>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <div>
          {isLoading ? (
            <Box marginTop="24px">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {provider && (
                <AppointmentDetails
                  providerImg={provider.provider.image_url}
                  fullName={provider.provider.name.full}
                  specialty={provider.provider.specialties[0].name}
                  number={provider.provider.contacts[0].value}
                  patientRelationship={patientRelationship}
                  purpose={purpose}
                  appointment={appointment}
                />
              )}
              {activeStep === STEP.first && provider && (
                <AppointmentInfoScreen
                  fullName={provider.provider.name.full}
                  appointmentPurpose={provider.provider.appointment_ehr_purposes}
                  videoVisit={provider.provider.video_visit}
                  number={provider.provider.contacts[0].value}
                  handleNext={handleNext}
                  setPatientRelationship={setPatientRelationship}
                  patientRelationship={patientRelationship}
                  setPurpose={setPurpose}
                  purpose={purpose}
                />
              )}
              {activeStep === STEP.second && provider && (
                <DateAndTime
                  providerId={providerId}
                  purpose={purpose}
                  patientRelationship={patientRelationship.toLocaleLowerCase()}
                  number={provider.provider.contacts[0].value}
                  handleNext={handleNext}
                  setApponitment={setApponitment}
                />
              )}
              {activeStep === STEP.third && provider && (
                <PatientInfo setValue={setValue} handleNext={handleNext} />
              )}
              {activeStep === STEP.fourth && provider && (
                <ReviewAndBook patientInformation={value} />
              )}
            </>
          )}
        </div>
      )}
    </Box>
  );
};

export default BookingOnline;
