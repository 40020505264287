import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import EditProviderModal from "./EditProviderModal";
import GenericEnhancedTable, { defaultGridConfig } from "../../components/GenericEnhancedTable";
import { API_ENDPOINTS } from "../../util/Api_Endpoints";
import { Provider, colDefs } from "./utils";

export default function ProviderList() {
  const [showModal, setShowModal] = useState(false);
  const [recordToBeEdit, setRecordToBeEdit] = useState<Provider | null>(null);
  const [mode, setMode] = useState("add");

  const gridConfig = {
    ...defaultGridConfig,
    allowDelete: false,
    showTableToolbar: false,
  };

  const openEditModal = useCallback((record) => {
    setMode("edit");
    setShowModal(true);
    setRecordToBeEdit(record);
  }, []);

  const openAddModal = useCallback(() => {
    setMode("add");
    setRecordToBeEdit({
      NPI: "",
      firstName: "",
      lastName: "",
      cellphone: "",
      id: -1,
      specialty: "",
      enabled: true,
    });
    setShowModal(true);
  }, []);

  return (
    <Box padding={["24px 24px 16px 24px", "40px 32px 16px 32px"]}>
      <Grid container alignItems="flex-end" justifyContent="center">
        <Box paddingBottom={["40px", "20px"]}>
          <Button
            data-cy="AddCellphoneModalBtn"
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={openAddModal}
          >
            Add
          </Button>
        </Box>

        {/* @ts-ignore-next-line */}
        <GenericEnhancedTable
          gridConfig={gridConfig}
          tableTitle="Provider List"
          colDefs={colDefs}
          url={API_ENDPOINTS.provider_celllphone}
          onEditHandler={openEditModal}
          refreshSourceData={!showModal}
          defaultSearchBy="NPI"
          hideCheckboxes
        />

        <EditProviderModal
          show={showModal}
          setShow={setShowModal}
          record={recordToBeEdit}
          mode={mode}
        />
      </Grid>
    </Box>
  );
}
