import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Features } from "util/constants/config";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { hasFeature } from "util/hasFeature";

const useStyles = makeStyles()((theme) => ({
  paper: {
    boxSizing: "border-box",
    maxWidth: "640px",
    width: "100%",
    padding: "32px 40px",

    [theme.breakpoints.down("md")]: {
      padding: "28px 22px",
      maxWidth: "560px",
    },
  },
  title: {
    margin: 0,
    marginBottom: "8px",
    fontSize: "32px",
    fontFamily: "unset",
    lineHeight: "100%",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
  },
  subtitle: {
    margin: 0,
    marginBottom: "24px",
    fontSize: "16px",
    color: theme.palette.primary.main,
  },
  textMessageLoginButton: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    fontWeight: 600,
    marginTop: "24px",
  },
  label: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Alegreya Sans",
    fontSize: "16px",
    color: theme.palette.common.black,
    maxWidth: "calc(100% - 20px)",
  },
}));

const WebsiteSurveysVerifyCaptcha = () => {
  const [loadCaptcha, setLoadCaptcha] = useState("");
  const [submitCaptcha, setSubmitCaptcha] = useState("");
  const isCaptchaEnabled = hasFeature(Features.ENABLE_CAPTCHA);
  const [inValidCaptcha, setInvalidCaptcha] = useState(isCaptchaEnabled);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const app = useApp();
  const captchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY;

  const getReCaptchaToken = (action) => {
    if (window.grecaptcha) {
      const {
        grecaptcha: { enterprise },
      } = window;
      enterprise.ready(() => {
        enterprise.execute(captchaKey, { action: "websitesurvey" }).then((token) => {
          if (action === "load") {
            setLoadCaptcha(token);
          } else if (action === "submit") {
            setSubmitCaptcha(token);
          }
        });
      });
    }
  };

  const handleConfirmCaptcha = async () => {
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.websiteEngageQuickAnswerSurvey, {
        captcha: submitCaptcha,
        action: "websitesurvey",
      });
      navigate(`/website-survey-form/f/${data.form_submission}/s/${data.hash}`);
    } catch (error) {
      app.addError("Oops, an unexpected error has occurred!");
    }
  };

  const validateCaptcha = async (token) => {
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.validateCaptcha, {
        captcha: token,
        action: "websitesurvey",
      });
      if (!data.success) {
        setInvalidCaptcha(false);
        getReCaptchaToken("submit"); // re-validate captcha on submit
      }
    } catch (error) {
      app.addError("Oops, an unexpected error occurred validating your humanity!");
    }
  };

  useEffect(() => {
    if (!window.reCaptchaInitialized && captchaKey && isCaptchaEnabled) {
      const scriptTag = document.createElement("script");
      scriptTag.id = "recaptchaScript";
      scriptTag.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaKey}`;
      scriptTag.async = false;
      document.body.appendChild(scriptTag);
      scriptTag.onload = () => {
        getReCaptchaToken("load");
      };
      window.reCaptchaInitialized = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptchaEnabled]);

  useEffect(() => {
    if (isCaptchaEnabled) {
      if (inValidCaptcha && loadCaptcha) {
        validateCaptcha(loadCaptcha);
      } else if (submitCaptcha) {
        handleConfirmCaptcha();
      }
    } else {
      handleConfirmCaptcha();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inValidCaptcha, loadCaptcha, submitCaptcha, isCaptchaEnabled]);

  const componentStyle = {
    marginTop: "30px",
  };
  return (
    <Grid container alignItems="center" justifyContent="center" style={componentStyle}>
      {inValidCaptcha && (
        <div data-testid="checking-recaptcha">
          <Typography variant="h1" className={classes.title} data-testid="phone-verification-title">
            Please wait while we check you are not a robot...
          </Typography>
        </div>
      )}
    </Grid>
  );
};

export default WebsiteSurveysVerifyCaptcha;
