/* eslint-disable */
import React, { useEffect } from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import SmsIcon from "@mui/icons-material/Sms";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { lighten } from "@mui/material/styles";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Names" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "cell", numeric: false, disablePadding: false, label: "Cell" },
  { id: "dob", numeric: false, isDate: true, disablePadding: false, label: "DOB" },
  { id: "signupDate", numeric: false, isDate: true, disablePadding: false, label: "Signup Date" },
  { id: "address", numeric: false, disablePadding: false, label: "Address" },
  { id: "context", numeric: false, disablePadding: false, label: "Context" },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              data-testid="sortLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden} data-testid="descending">
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles()((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color:
      theme.palette.mode === "light" ? theme.palette.secondary.main : theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === "light"
        ? lighten(theme.palette.secondary.light, 0.85)
        : theme.palette.secondary.dark,
  },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { classes, cx } = useToolbarStyles();
  const { numSelected, tableTitle, selected, handleSelectedAction } = props;

  return (
    <Toolbar
      className={cx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
          data-testid="numSelected"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
          data-testid="tableTitle"
        >
          {tableTitle}
        </Typography>
      )}

      {numSelected > 0 && (
        <>
          <Tooltip title="Assign In Transit">
            <IconButton
              aria-label="Assign In Transit"
              onClick={() => {
                handleSelectedAction(selected, "AssignInTransit");
              }}
              data-testid="assignInTransit"
              size="large"
            >
              <AssignmentIndIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Assign Phone Only">
            <IconButton
              aria-label="Assign Phone Only"
              onClick={() => {
                handleSelectedAction(selected, "AssignPhoneOnly");
              }}
              data-testid="assignPhoneOnly"
              size="large"
            >
              <PhoneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Mark Has Vaccine">
            <IconButton
              aria-label="Mark Has Vaccine"
              onClick={() => {
                handleSelectedAction(selected, "MarkHasVaccine");
              }}
              data-testid="markHasVaccine"
              size="large"
            >
              <LocalHospitalIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Send SMS">
            <IconButton
              aria-label="Send Sms"
              onClick={() => {
                handleSelectedAction(selected, "SendSms");
              }}
              data-testid="sendSms"
              size="large"
            >
              <SmsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Send Email">
            <IconButton
              aria-label="Send Email"
              onClick={() => {
                handleSelectedAction(selected, "SendEmail");
              }}
              data-testid="sendEmail"
              size="large"
            >
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string,
  handleSelectedAction: PropTypes.func,
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const { classes } = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { tableTitle, dataItems, handleSelectedAction } = props;
  let rows = dataItems;

  if (!rows) {
    rows = [];
  }
  useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          selected={selected}
          numSelected={selected.length}
          tableTitle={tableTitle}
          handleSelectedAction={handleSelectedAction}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.email);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.email)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.email + index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.cell_phone}</TableCell>
                      <TableCell>{row.dob ? row.dob.substring(0, 10) : ""}</TableCell>
                      <TableCell>
                        {row.form_submit_dttm ? row.form_submit_dttm.substring(0, 10) : ""}
                      </TableCell>
                      <TableCell>
                        {row.addr_str_1 &&
                          `${row.addr_str_1} ${row.addr_str_1} ${row.city} ${row.state} ${row.zip}`}{" "}
                        {row.zip && row.zip}
                      </TableCell>
                      <TableCell>
                        Status: {row.ops_status}, Form Submitted: {row.form_submit_dttm} <br />
                        Context:{" "}
                        {!row.ops_status || row.ops_status === "in_transit"
                          ? `Last touched:${row.staff_email}`
                          : row.send_dttm
                          ? `Comms sent:${row.send_dttm} - ${row.context}`
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
