import { VirtualSessionQueue } from "util/constants";
import { useUserType } from "hooks/useUserType/useUserType";

export const useGetDefaultQueueView = (isConsultation: boolean) => {
  const { isPhysician, isNurse, isRegStaff } = useUserType();

  switch (true) {
    case isRegStaff:
      return VirtualSessionQueue.REGISTERING_AND_CHECKOUT;
    case isNurse:
      return VirtualSessionQueue.NURSE_TRIAGE;
    case isPhysician:
      return VirtualSessionQueue.PHYSICIAN_VISIT;
    case isConsultation:
      return "all";
    default:
      return VirtualSessionQueue.REGISTERING;
  }
};
