import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import {
  AuthLink,
  RESET_PASSWORD_LINK_HREF,
  RESET_USERNAME_LINK_HREF,
  SIGN_UP_LINK_HREF,
} from "./LoginView";
import HelpModal from "components/HelpModal";
import { epicUrl } from "util/constants/config";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import useStyles from "../../styles/Authorization/authorization.styles";

interface ParamsType {
  uiEmbedded?: boolean;
  userContext?: string;
  redirect?: string;
}

const SingInLink = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const historyPushWithParams = (path) => {
    if (!path) {
      return;
    }
    const params = new URLSearchParams(location.search);
    navigate({ pathname: path, search: params.toString() });
  };

  return (
    <Button
      variant="outlined"
      onClick={() => {
        historyPushWithParams("/login");
      }}
    >
      Sign In
    </Button>
  );
};

export default function CellPhoneInstantActivation() {
  const { classes } = useStyles();
  const app = useApp();

  const [cellPhone, setCellPhone] = useState<string>("");
  const [detailedMsg, setDetailedMsg] = useState<string | null>(null);
  const [nextStep, setNextStep] = useState<string | null>(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const [isActivating, setIsActivating] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleCellInstantActivation = async () => {
    setIsActivating(true);
    if (!cellPhone || cellPhone.length < 10) {
      app.addError(
        "Oops, your number format isn't correct.  Please enter a 10-digit only cell phone number.",
      );
      return;
    }

    const cellPhoneParsed = cellPhone.replace(/\D/g, "");
    if (cellPhoneParsed.length < 10 || cellPhoneParsed.length > 14) {
      app.addError(
        "Oops, your number format isn't correct.  Please enter a 10-digit only cell phone number.",
      );
      return;
    }
    const apiUrl = "/api/activation/initiatebycellphone";
    const params: ParamsType = {};
    if (app.isEmbedded()) {
      params.uiEmbedded = true;
    }
    const url = new URL(window.location.href);
    const uc = url.searchParams.get("userContext");
    if (uc && uc.length > 0) {
      params.userContext = uc;
    }
    const r = url.searchParams.get("r");
    if (r && r.length > 0) {
      params.redirect = decodeURIComponent(r);
    } else if (url.searchParams.get("ssoinit")) {
      // special use case
      params.redirect = decodeURIComponent("ssoinit");
    }
    try {
      const { data } = await axiosClient.post(apiUrl, { cell_phone: cellPhoneParsed }, { params });
      if (!data?.next_step) {
        app.addError("Failed to start instant activation, please contact support.");
        setDetailedMsg("");
        setNextStep("tech_support");
        return;
      }
      if (data.next_step) {
        setNextStep(data.next_step);
      }
      if (data.message) {
        setDetailedMsg(data.message);
      }
      if (data.next_step && data.next_step === "sms_verify") {
        app.addInfoMsg(
          "Succcessfully sent you an SMS text message for verification.  Please check your text message to continue.",
        );
      }
    } catch {
      app.addError("Failed to start instant activation, please contact support.");
      setDetailedMsg("");
      setNextStep("tech_support");
    }
    setIsActivating(false);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (submitButtonRef?.current) {
          submitButtonRef.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={11} classes={{ root: classes.root }}>
        <Paper elevation={24} classes={{ root: classes.paper }}>
          <h1>MyChart Account Activation</h1>
          <h3 className={classes.normalText}>
            Please enter your cell phone number to start account activation. We'll text you the next
            step.
          </h3>

          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <TextField
                id="cellPhone"
                label="Cell Phone"
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                variant="outlined"
                className={classes.textField}
                value={cellPhone}
                onInput={(e) => setCellPhone((e.target as HTMLInputElement).value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                ref={submitButtonRef}
                onClick={handleCellInstantActivation}
                disabled={isActivating}
                startIcon={isActivating && <CircularProgress size={24} />}
              >
                Start Instant Activation
              </Button>
            </Grid>
          </Grid>

          {!nextStep && (
            <Grid item xs={12}>
              <h3 className={classes.normalText}>
                If you are an existing user, please sign in here.
              </h3>
              <SingInLink />
              <br />
              <br />
              <h3 className={classes.normalText}>
                No Cell Phone? You can use our alternative signup process{" "}
                <a href={`${epicUrl}/signup`} target="_blank" rel="noreferrer">
                  here.
                </a>
              </h3>
            </Grid>
          )}
          {nextStep === "new_registration" && (
            <Grid item xs={12}>
              <h3 className={classes.normalText}>
                We did not find any account matching the cell phone number provided. Please use the
                link below to register as a new user.
              </h3>
              <AuthLink href={SIGN_UP_LINK_HREF} text="Sign Up" />
              <SingInLink />
            </Grid>
          )}
          {nextStep === "sms_login_sent" && (
            <Grid item xs={12}>
              <h3 className={classes.normalText}>
                We found your account and will send you a text message to verify your identity.
              </h3>
              <span>Check your text messages or </span>
              <SingInLink />
            </Grid>
          )}
          {nextStep === "new_registration_invite" && (
            <Grid item xs={12}>
              <h3 className={classes.normalText}>
                We did not find an account matching the cell phone number provided. We sent you a
                text message to start the new user registration process.
              </h3>
              <span>Check your text messages or </span>
              <SingInLink />
            </Grid>
          )}
          {nextStep === "tech_support" && (
            <Grid item xs={12}>
              <span>There was a problem starting instant activation. {detailedMsg}</span>
              <Button
                className={classes.links}
                color="primary"
                onClick={() => setIsHelpModalOpen(true)}
              >
                Help?
              </Button>
              <span> or </span>
              <SingInLink />
            </Grid>
          )}
          {nextStep === "patient_pair" && (
            <Grid item xs={12}>
              <h3 className={classes.normalText}>
                We found multiple accounts matching the cell phone number you entered. You are not
                eligible for instant activatiton. Please use the link below to set up your account.
              </h3>
              <AuthLink href={SIGN_UP_LINK_HREF} text="Sign Up" />
              <SingInLink />
            </Grid>
          )}
          {nextStep === "password_reset" && (
            <Grid item xs={12}>
              <h3 className={classes.normalText}>
                The cell phone number you entered matches an existing account.
              </h3>
              <SingInLink />
              <AuthLink href={RESET_USERNAME_LINK_HREF} text="Forgot username?" />
              <AuthLink href={RESET_PASSWORD_LINK_HREF} text="Forgot password?" />
            </Grid>
          )}
          <HelpModal isOpen={isHelpModalOpen} handleClose={() => setIsHelpModalOpen(false)} />
        </Paper>
      </Grid>
    </Grid>
  );
}
