/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText } from "@mui/material";
import { nav } from "./nav";
import { makeStyles } from "tss-react/mui";
import logo from "../../static/svg/logo_header.svg";

const useStyles = makeStyles()({
  list: {
    width: 260,
  },
  fullList: {
    width: "auto",
  },
  menuItem: {
    padding: "12px 16px",
    fontFamily: "Roboto, sans-serif",
  },
  logo: {
    height: "40px",
    margin: "25px 0px 0px 15px",
  },
});

export default function SideDrawerView({ toggleMenuDrawer, handleHelpModalOpen }) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.list} role="presentation">
      <img title="Atlantic Health System" className={classes.logo} src={logo} alt="" />
      <List>
        {nav.map(({ key, global, text, link, isButton }) => (
          <ListItem
            onClick={toggleMenuDrawer(false)}
            onKeyDown={toggleMenuDrawer(false)}
            button
            key={key}
            className={classes.menuItem}
          >
            <ListItemText
              onClick={() => {
                if (isButton) {
                  handleHelpModalOpen();
                  return;
                }
                global === true ? window.open(link) : navigate(link);
              }}
              primary={key === "Menu" ? "Menu" : null}
            >
              {text}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
