import { useLocation } from "react-router-dom";
import { PROGRAM_TYPES, ProgramNames } from "./constants";
import { Program } from "components/Video/utils/types";
import { permissions } from "components/shared/permissions";
import { usePrograms } from "util/ProgramsContext";

export const GetCurrentProgram = (): Program | undefined => {
  const { programId, programList } = usePrograms();
  const currentProgram = programList.find((program) => program.id === programId);

  return currentProgram;
};

export const useProgramType = (
  program?: Program,
): {
  isAdhoc: boolean;
  isVUC: boolean;
  isConsultation: boolean;
  isOnDemand: boolean;
} => {
  const currentProgram = program || GetCurrentProgram();

  const isVUC = currentProgram?.program_type === PROGRAM_TYPES.virtualSessionsQueue;
  const isAdhoc = currentProgram?.program_type === PROGRAM_TYPES.adhocVirtual;
  const isConsultation = currentProgram?.program_type === PROGRAM_TYPES.consultation;
  const isOnDemand = currentProgram?.program_type === PROGRAM_TYPES.specialistOnDemand;

  return { isAdhoc, isVUC, isConsultation, isOnDemand };
};

export const useProgramTypeByPathname = (): {
  isAdhoc: boolean;
  isVUC: boolean;
  isConsultation: boolean;
  isOnDemand: boolean;
} => {
  const location = useLocation();

  const isVUC = location.pathname.includes(permissions.virtualvisit);
  const isAdhoc = location.pathname.includes(permissions.adhocVirtual);
  const isConsultation = location.pathname.includes(permissions.consultation);
  const isOnDemand = location.pathname.includes(permissions.specialistOnDemand);

  return { isVUC, isAdhoc, isConsultation, isOnDemand };
};

export const getIsProgramName = (
  programName?: string,
): { isPhysicalTherapy: boolean; isNeurologyInquiry: boolean; isOncologyInquiry: boolean } => {
  const isPhysicalTherapy = programName
    ? programName.replace(encodeURIComponent("&"), "&") === ProgramNames.PHYSICAL_THERAPY
    : false;
  const isNeurologyInquiry = programName === ProgramNames.NEUROLOGY_INQUIRY;
  const isOncologyInquiry = programName === ProgramNames.ONCOLOGY_INQUIRY;

  return { isPhysicalTherapy, isNeurologyInquiry, isOncologyInquiry };
};
