import React, { useState } from "react";
import DataCurationTable from "..";
import EnableDisableModal from "./EnableDisableModal";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { colDefsNeedsReview } from "../utils/colDefs";

const NeedsReviewTable: React.FC = () => {
  const [selectedRowForModal, setSelectedRowForModal] = useState<any>([]);

  return (
    <>
      <EnableDisableModal
        show={selectedRowForModal.length > 0}
        onClose={() => setSelectedRowForModal([])}
        selectedLocation={selectedRowForModal}
      />
      <DataCurationTable
        key="needsReviewTable"
        dataUrl={API_ENDPOINTS.dataCurationLocations}
        colDefs={colDefsNeedsReview}
        linkTo="location"
        id="needsReviewTable"
        allowSelection
        defaultOrderBy="id"
        cellWidth="auto"
        filterByStatus="NEEDS_REVIEW"
        setSelectedRow={(selectedRow) => setSelectedRowForModal(selectedRow)}
        forceReload={selectedRowForModal.length === 0}
      />
    </>
  );
};

export default NeedsReviewTable;
