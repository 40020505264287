import React, { useState } from "react";
import { AddAPhoto, Edit, Image, KeyboardArrowDownRounded } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import SpecimenPhotoModal from "./modals/SpecimenPhotoModal";
import InputsDataHandler from "./InputsDataHandler";
import { useUpdateSpecimen } from "hooks/specimenTracking/useUpdateSpecimen/useUpdateSpecimen";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { SpecimenAddInitialValues } from "views/SpecimenTracking/types";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";

const ScannedCodeCard = ({
  code,
  index,
  setShowDeleteModal,
  setDeleteId,
  scannedCodes,
  setScannedCodes,
  dobFormatted,
  formatValues,
}) => {
  const app = useApp();
  const { classes } = useSpecimenStyles();
  const {
    web: {
      common: { remove, edit, cancel, update },
      specimenTracking: { takePhotoOfSpecimen, replacePhotoOfSpecimen },
    },
  } = useTranslation();
  const { updateSpecimen } = useUpdateSpecimen();

  const { specimenExternalId, orderDob } = code.data;

  const [expanded, setExpanded] = useState<boolean[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<SpecimenAddInitialValues | null>(null);
  const [updatedInputDOB, setUpdatedInputDOB] = useState<Date | null | undefined>(orderDob);
  const [showSpecimenPhotoModal, setShowSpecimenPhotoModal] = useState<boolean>(false);
  const [replacePhoto, setReplacePhoto] = useState<boolean>(false);

  const handleExpandClick = (index: number) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const onEditSpecimen = () => {
    setIsEdit(true);
    setEditingItem(formatValues(code, updatedInputDOB));
  };

  const handleEditingItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // @ts-ignore-next-line
    setEditingItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleUpdateEditingItem = async () => {
    if (!editingItem) {
      console.error("editingItem is null. Cannot update.");
      return;
    }

    const indexOfEditingItem = scannedCodes.findIndex((item) => item.data.id === code.data.id);

    if (indexOfEditingItem !== -1) {
      const updatedScannedCodes = [...scannedCodes];

      const payload = {
        order: {
          orderExternalId: editingItem.orderOrderExternalId,
          mrn: editingItem.orderMrn,
          patientFirstName: editingItem.orderPatientFirstName,
          patientLastName: editingItem.orderPatientLastName,
          dob: dobFormatted(updatedInputDOB),
        },
        specimenExternalId: editingItem.specimenExternalId,
        specimenType: editingItem.specimenType,
        description: editingItem.description,
      };

      try {
        const { data: responseData } = await updateSpecimen(code.data.id, payload);

        updatedScannedCodes[indexOfEditingItem] = {
          ...updatedScannedCodes[indexOfEditingItem],
          data: responseData.data,
        };

        setScannedCodes(updatedScannedCodes);
        app.addInfoMsg("Specimen has been edited successfully.");
      } catch {
        app.addError("Error when trying to edit the Specimen.");
      }
    }

    setIsEdit(false);
    setEditingItem(null);
  };

  const updateSpecimenImage = (updatedImage, specimenExternalId) => {
    setScannedCodes((prevCodes) => {
      return prevCodes.map((code) => {
        if (code.data.specimenExternalId === specimenExternalId) {
          return { ...code, data: { ...code.data, specimenImage: updatedImage } };
        }
        return code;
      });
    });
  };

  return (
    <>
      <Box display="flex" className={classes.codeCardWrapper}>
        <Button
          onClick={() => {
            setReplacePhoto(false);
            setShowSpecimenPhotoModal(true);
          }}
          style={{ height: "50px", border: "1px solid #d8d7d7", background: "#f5f5f5" }}
          data-cy="photoModalImgBtn"
        >
          {code.data.specimenImage ? (
            <Image color="primary" fontSize="large" />
          ) : (
            <AddAPhoto color="secondary" fontSize="large" />
          )}
        </Button>
        <Box className={classes.scannedCodesCard}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="inherit" variant="h6" align="left" style={{ fontSize: "18px" }}>
              {specimenExternalId}
            </Typography>
            <IconButton
              onClick={() => handleExpandClick(index)}
              aria-expanded={expanded[index]}
              aria-label="show more"
              style={{
                transform: expanded[index] ? "rotate(180deg)" : "rotate(0)",
                padding: "8px",
              }}
              data-cy="ExpandCardIcon"
              data-testid="expandCardIcon"
              size="large"
            >
              <KeyboardArrowDownRounded />
            </IconButton>
          </Box>
          <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
            {isEdit ? (
              <InputsDataHandler
                isDisabled={isEdit}
                values={editingItem}
                handleChange={handleEditingItemChange}
                setInputDOB={setUpdatedInputDOB}
                inputDOB={updatedInputDOB}
              />
            ) : (
              <InputsDataHandler
                isDisabled={isEdit}
                values={formatValues(code, updatedInputDOB)}
                handleChange={handleEditingItemChange}
                inputDOB={orderDob}
                setInputDOB={setUpdatedInputDOB}
              />
            )}

            {isEdit ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ gap: "8px", margin: "8px 0" }}
              >
                <Button
                  onClick={() => setIsEdit(false)}
                  variant="contained"
                  color="primary"
                  data-cy="btnCancelEditSpecimen"
                  data-testid="btnCancelEditSpecimen"
                  style={{ width: "100%" }}
                >
                  {cancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  data-cy="btnUpdateSpecimen"
                  data-testid="btnUpdateSpecimen"
                  onClick={() => handleUpdateEditingItem()}
                  style={{ width: "100%" }}
                >
                  {update}
                </Button>
              </Box>
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ gap: "8px", margin: "8px 0" }}
                >
                  <Button
                    startIcon={<Edit />}
                    onClick={() => onEditSpecimen()}
                    variant="contained"
                    color="primary"
                    data-cy="BtnEditSpecimenData"
                    data-testid="BtnEditSpecimenData"
                    style={{ width: "100%" }}
                  >
                    {edit}
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    variant="contained"
                    color="primary"
                    data-cy="BtnRemovepec"
                    data-testid="BtnRemovepec"
                    onClick={() => {
                      setDeleteId(code.data.id);
                      setShowDeleteModal(true);
                    }}
                    style={{ width: "100%" }}
                  >
                    {remove}
                  </Button>
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  style={{ width: "100%", background: "white", marginBottom: "12px" }}
                  onClick={() => {
                    setShowSpecimenPhotoModal(true);
                    setReplacePhoto(true);
                  }}
                  data-cy="photoModalBtn"
                >
                  {code.data.specimenImage ? replacePhotoOfSpecimen : takePhotoOfSpecimen}
                </Button>
              </>
            )}
          </Collapse>
        </Box>
      </Box>

      <SpecimenPhotoModal
        open={showSpecimenPhotoModal}
        onClose={() => setShowSpecimenPhotoModal(false)}
        specimenDetail={code.data}
        replacePhoto={replacePhoto}
        updateSpecimenImage={updateSpecimenImage}
      />
    </>
  );
};

export default ScannedCodeCard;
