import { useState } from "react";
import uploadFilesService from "components/FileUploadWidget/upload-files.service";
import { CLEAR_IMAGES_LIST } from "components/Video/hooks/usePatientStepsReducer/Actions";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";

export const useUploadImages = () => {
  const app = useApp();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const imagesList2 = app.patient_steps_state.imagesList;
  const { contextType } = app.patient_steps_state;
  const { contextID } = app.patient_steps_state;
  let uploadedImages = 0;
  let isSuccessful = false;

  const uploadImages = async (): Promise<boolean> => {
    setIsLoading(true);
    for (let i = 0; i < imagesList2.length; i++) {
      const imageInfo = imagesList2[i];
      let imageToBeUpload = imageInfo.currentFile;
      const imageType = imageInfo.imageType ? imageInfo.imageType : "General";
      if (imageInfo.capturedImage) {
        imageToBeUpload = new File([imageInfo.currentFile], imageInfo.name, {
          type: "image/png",
        });
      }
      await uploadFilesService
        .upload(imageToBeUpload, contextType, contextID, imageType, user.userId)
        // eslint-disable-next-line
        .then((response) => {
          if (response.status === 200) {
            uploadedImages += 1;
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    setIsLoading(false);
    if (imagesList2.length !== uploadedImages) {
      app.addError("Problem uploading images, please contact support.");
      isSuccessful = false;
    } else {
      isSuccessful = true;
      app.patient_steps_dispatch({ type: CLEAR_IMAGES_LIST, payload: null });
    }
    return isSuccessful;
  };

  return { uploadImages, isLoading };
};
