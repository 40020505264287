import { localeTranslationsEn } from "locale/en";

const language = window.navigator?.language?.split("-")[0]?.toLowerCase();

const useTranslation = () => {
  const translations = () => {
    switch (language) {
      case "en":
        return localeTranslationsEn;

      default:
        return localeTranslationsEn;
    }
  };
  return translations();
};

export default useTranslation;
