import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import ScannedCodesList from "./views/ScannedCodesList";
import Scanners from "./views/Scanners";
import { useAddSpecimenTrackManually } from "hooks/specimenTracking/useAddSpecimenTrackManual/useAddSpecimenTrackManual";
import { useConfirmSpecimen } from "hooks/specimenTracking/useConfirmSpecimen/useConfirmSpecimen";
import { useGetSpecimenById } from "hooks/specimenTracking/useGetSpecimenById/useGetSpecimenById";
import useTranslation from "hooks/useTranslation";
import { LockOrientation } from "util/useLockOrientation";
import { useApp } from "util/AppContext";
import { useIsMobile } from "util/deviceUtils";
import { usePlaySound } from "util/playSounds";
import {
  SpecimenAddResponse,
  SpecimenInfoAddNewManuallyData,
  SpecimenTrackAction,
  SpecimenTrackingStatus,
} from "views/SpecimenTracking/types";

const SpecimenTrackingAddSpecimen = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { location } = app.specimen_tracking_state;
  const params = new URLSearchParams(window.location.search);
  const actionType = (params.get("actionType") as SpecimenTrackAction) || SpecimenTrackAction.ADD;
  const isAdding = actionType === SpecimenTrackAction.ADD;

  const { addSpecimenTrackManually } = useAddSpecimenTrackManually();
  const { confirmSpecimen } = useConfirmSpecimen();
  const { getSpecimenById } = useGetSpecimenById();
  const { playSound } = usePlaySound();
  const [scannedCodes, setScannedCodes] = useState<SpecimenAddResponse[]>([]);
  const [specimenCodeAlreadyRead, setSpecimenCodeAlreadyRead] = useState<boolean>(false);
  const [showAddManually, setShowAddManually] = useState<boolean>(false);
  const {
    web: {
      specimenTrackingAdd: {
        msgAddSpecimenSuccess,
        msgAddSpecimenAlreadyScanned,
        msgAddSpecimenErrorScanned,
        msgAddSpecimenManuallyError,
        msgAddSpecimenNotFound,
        msgAddSpecimensSuccess,
        msgAddSpecimensUpdateSuccess,
        msgAddSpecimensConfirmError,
      },
    },
  } = useTranslation();

  const dobFormatted = (dateToFormat) => {
    if (typeof dateToFormat === "object") {
      return `${
        dateToFormat.getMonth() > 8
          ? dateToFormat.getMonth() + 1
          : `0${dateToFormat.getMonth() + 1}`
      }/${
        dateToFormat.getDate() > 9 ? dateToFormat.getDate() : `0${dateToFormat.getDate()}`
      }/${dateToFormat.getFullYear()}`;
    }
    return dateToFormat;
  };

  const isAlreadyScanned = (codeId) => {
    return scannedCodes.some((scannedCode) => scannedCode.data.specimenExternalId === codeId);
  };

  const addScannedCodeToList = (item: SpecimenAddResponse) => {
    if (!isAlreadyScanned(item.data.specimenExternalId)) {
      app.addInfoMsg(msgAddSpecimenSuccess);
      setScannedCodes((prev) => [...prev, item]);
      playSound({ type: "success" });
      return { data: item, msg: msgAddSpecimenSuccess };
    }
    return { data: undefined, msg: msgAddSpecimenAlreadyScanned };
  };

  const getSpecimenForPickOrdDrop = async (external_id: string) => {
    try {
      const { data } = await getSpecimenById(external_id);

      if (data?.data[0]?.specimenExternalId) {
        const scannedSpcimenData = {
          data: { ...data.data[0] },
          error: "",
          message: "",
        };

        return addScannedCodeToList(scannedSpcimenData);
      }
      playSound({ type: "error" });
      return { data: undefined, msg: msgAddSpecimenNotFound };
    } catch {
      playSound({ type: "error" });
      app.addError(msgAddSpecimenErrorScanned);
    } finally {
      setSpecimenCodeAlreadyRead(false);
    }
  };

  /**
   * Retrieves specimen data for adding a new specimen manually.
   * If the response from the API is valid (2xx status). Either we created the specimen or updated a non-confirmed one
   * and we add the specimen to the list of scanned codes. If the specimen was already confirmed or was already in the list,
   * we display an error message.
   *
   * @param {string} orderId - The ID of the order.
   * @param {string} specimenId - The ID of the specimen.
   * @returns {Promise<{ data: any, msg: string }>} An object containing the response data or error message.
   */
  const getSpecimenForAddNewSpecimen = async (orderId, specimenId) => {
    try {
      const payload: SpecimenInfoAddNewManuallyData = {
        orderExternalId: orderId,
        specimenExternalId: specimenId,
      };
      const response = await addSpecimenTrackManually(payload);

      if (response?.status >= 200 && response?.status < 300) {
        const specimenData = response.data.data;

        // Check if specimen data is valid. The following if is there more as sanity check.
        // If the response is successful, we should have a specimenExternalId and the specimen status should be MANUAL.
        if (
          specimenData &&
          specimenData.specimenExternalId &&
          specimenData.status === SpecimenTrackingStatus.MANUAL
        ) {
          return addScannedCodeToList(response.data);
        }
        playSound({ type: "error" });
        return { data: undefined, msg: msgAddSpecimenNotFound };
      }
      if (response.status === 409) {
        return { data: undefined, msg: response?.data?.error || msgAddSpecimenAlreadyScanned };
      }
      playSound({ type: "error" });
      return { data: undefined, msg: msgAddSpecimenErrorScanned };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // Handle unexpected errors
      playSound({ type: "error" });
      app.addError(error?.response?.data?.error || msgAddSpecimenManuallyError);
    } finally {
      // Reset flag
      setSpecimenCodeAlreadyRead(false);
    }
  };

  const handleScanPickOrDrop = async (decodedText: string) => {
    if (!specimenCodeAlreadyRead) {
      setSpecimenCodeAlreadyRead(true);
      const resp = await getSpecimenForPickOrdDrop(decodedText);
      return resp;
    }
  };

  const handleScanNewSpecimen = async (orderId: string, specimenId: string) => {
    if (!isAlreadyScanned(specimenId)) {
      const resp = await getSpecimenForAddNewSpecimen(orderId, specimenId);
      return resp;
    }
    return { data: undefined, msg: msgAddSpecimenAlreadyScanned };
  };

  const onConfirmSpecimens = async () => {
    const specimensToConfirm: number[] = scannedCodes.map((specimen) => specimen.data.id);
    try {
      const resp = await confirmSpecimen({
        collectedAt: Number(location.id),
        collectedSpecimens: specimensToConfirm,
        type: actionType,
      });
      if (resp.data) {
        navigate("/console/specimen-tracking");
        if (isAdding) {
          return app.addInfoMsg(msgAddSpecimensSuccess);
        }
        app.addInfoMsg(msgAddSpecimensUpdateSuccess);
      }
    } catch {
      app.addError(msgAddSpecimensConfirmError);
    }
  };

  useEffect(() => {
    if (!location) {
      navigate("/console/specimen-tracking");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <LockOrientation isMobile={isMobile} orientation="portrait">
      <Grid style={{ maxWidth: "600px", margin: "0 auto" }} data-testid="AddOrUpdateSpecimensView">
        <Scanners
          addScannedCode={addScannedCodeToList}
          showAddManually={showAddManually}
          setShowAddManually={setShowAddManually}
          handleScanPickOrDrop={handleScanPickOrDrop}
          handleScanNewSpecimen={handleScanNewSpecimen}
          isAdding={isAdding}
          isAlreadyScanned={isAlreadyScanned}
          isPickAndDrop={
            actionType === SpecimenTrackAction.DROP_OFF ||
            actionType === SpecimenTrackAction.PICK_UP
          }
          isMobile={isMobile}
          setScannedCodes={setScannedCodes}
        />
        <ScannedCodesList
          scannedCodes={scannedCodes}
          setScannedCodes={setScannedCodes}
          onConfirmSpecimens={onConfirmSpecimens}
          actionType={actionType}
          dobFormatted={dobFormatted}
        />
      </Grid>
    </LockOrientation>
  );
};

export default SpecimenTrackingAddSpecimen;
