import React, { useCallback } from "react";
import { Typography } from "@mui/material";
import useTranslation from "../../../hooks/useTranslation";
import { useStyles } from "../../../styles/Mobile/hero.styles";
import AHSLogo from "../../../static/images/ahs-outline-white.png";
import HomeHeroImg from "../../../static/images/mobile/mobilehomehero.png";
import MyChartLogo from "../../../static/images/mobile/mychartlogo.png";
import MyChartIcon from "../../../static/svg/mobile/myChartIcon.png";

interface HeroProps {
  variant: "home" | "image" | "onlyText" | "textAndLogo" | "empty";
  imgSrc?: string;
  text?: string;
  displaceText?: boolean;
}

const Hero = ({ variant, imgSrc, text, displaceText = false }: HeroProps) => {
  const { classes, cx } = useStyles();
  const { mobile } = useTranslation();
  const { MyChartDesc, SignInMyChart } = mobile;

  const handleMyChartSignInPress = useCallback(() => {
    window.open("myatlantic://openlogin", "_blank");
  }, []);

  return (
    <>
      {variant === "home" && (
        <div className={classes.homeHeroContainer}>
          <img className={classes.homeImage} src={HomeHeroImg} alt="" />
          <img data-testid="myChartLogo" className={classes.myChartLogo} src={MyChartLogo} alt="" />
          <Typography className={classes.description}>{MyChartDesc}</Typography>
          <button
            className={classes.button}
            type="button"
            onClick={handleMyChartSignInPress}
            data-cy="hero-home-my-chart-button"
          >
            <img src={MyChartIcon} alt="" className={classes.myChartFolderIcon} />
            <Typography className={classes.buttonText}>{SignInMyChart}</Typography>
          </button>
        </div>
      )}
      {variant === "image" && imgSrc && (
        <img className={classes.imageVariant} src={imgSrc} alt="" />
      )}
      {variant === "onlyText" && text && (
        <div className={classes.onlyTextContainer}>
          <Typography className={cx(classes.text, displaceText && classes.displacedText)}>
            {text}
          </Typography>
        </div>
      )}
      {variant === "empty" && <div className={classes.onlyTextContainer} />}
      {variant === "textAndLogo" && text && (
        <div className={`${classes.onlyTextContainer} ${classes.alignLeftContainer}`}>
          <img
            className={classes.ahsLogo}
            title="Atlantic Health System"
            src={AHSLogo}
            alt="Atlantic Health System"
          />
          <Typography className={classes.text}>{text}</Typography>
        </div>
      )}
    </>
  );
};

export default Hero;
