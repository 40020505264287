import { APP_ENVIRONMENT, Environment, Features, releaseStages } from "util/constants/config";

const environmentsByStage = {
  [Environment.DEV]: [Environment.DEV],
  [Environment.INTEGRATION]: [Environment.DEV, Environment.INTEGRATION],
  [Environment.TEST]: [Environment.DEV, Environment.TEST],
  [Environment.TEST_ONLY]: [Environment.TEST],
  [Environment.INT_ONLY]: [Environment.INTEGRATION],
  [Environment.PRODUCTION]: [
    Environment.TEST,
    Environment.INTEGRATION,
    Environment.DEV,
    Environment.PRODUCTION,
  ],
  [Environment.DEVPROD]: [Environment.DEV, Environment.PRODUCTION],
  [Environment.TEST_INT]: [Environment.DEV, Environment.TEST, Environment.INTEGRATION],
  [Environment.NOOP]: [Environment.NOOP],
  [Environment.PRODUCTION_ONLY]: [Environment.PRODUCTION],
};

const inStage = (releaseStage: Environment): boolean => {
  const allowListedEnvironments = environmentsByStage[releaseStage];

  if (!allowListedEnvironments) {
    return false;
  }
  if (allowListedEnvironments[0] === Environment.NOOP) {
    return false;
  }

  return allowListedEnvironments.includes(APP_ENVIRONMENT);
};

export const hasFeature = (feature: Features): boolean => {
  if (!feature) {
    return false;
  }
  const releaseStage = releaseStages[feature];

  return inStage(releaseStage);
};
