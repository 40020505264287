import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, CircularProgress, Container, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import Map, { MarkerProp } from "../../../components/shared/Map";
import CONSTANTS from "../constants";
import useTranslation from "hooks/useTranslation";
import { Location } from "../LocationList/utils";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

type Hospital = {
  id: number;
  address: string;
  lat: number;
  lng: number;
  title: string;
  phone: string;
  directions: string;
};

type HospitalMarker = MarkerProp & Omit<Hospital, "lat" | "lng" | "id">;

type InfoWindowFC = React.FC<{ hospital?: HospitalMarker; text: string }>;

const useStyles = makeStyles()(() => ({
  circularProgressContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
  errorTextContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    marginTop: "12px",
    marginLeft: "40px",
    marginRight: "40px",
  },
}));

const InfoWindow: InfoWindowFC = ({ hospital, text }) => {
  if (hospital) {
    return (
      <Container maxWidth="xs">
        <Typography gutterBottom variant="body1">
          {hospital?.title}
        </Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          {hospital.address}
        </Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          {hospital.phone}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open(hospital.directions, "_blank")}
        >
          {text}
        </Button>
      </Container>
    );
  }

  return null;
};

const MapView = () => {
  const location = useLocation();
  const { classes } = useStyles();
  const isHospitalVariant = location.state?.from === CONSTANTS.FINDHOSPITAL;
  const [chosenMarker, setChosenMarker] = useState<HospitalMarker>();
  const [retrievingLocations, setRetrievingLocations] = useState(true);
  const [locationsError, setLocationsError] = useState(false);
  const [locations, setLocations] = useState<Array<Location>>([]);

  const { mobile } = useTranslation();
  const { DrivingDirections } = mobile;

  const onMarkerClick = (id) => {
    const currentIndex = locations.findIndex((marker) => marker.id === id);
    const currentMarker = locations[currentIndex];
    setChosenMarker(currentMarker?.id === chosenMarker?.id ? undefined : currentMarker);
  };

  const filterLocationsWithNoCoordinates = (locations: Array<Location>) => {
    return locations.filter((location) => location.coordinates);
  };

  useEffect(() => {
    const fetchLocations = async () => {
      const url = isHospitalVariant
        ? API_ENDPOINTS.mobile_hospitals
        : API_ENDPOINTS.mobile_urgentcare;
      setRetrievingLocations(true);
      try {
        const response = await axiosClient.get(url);
        if (response.status === 200) {
          const mappeableLocations = filterLocationsWithNoCoordinates(response.data?.data);
          setLocations(mappeableLocations);
        }
      } catch (e) {
        setLocationsError(true);
      }
      setRetrievingLocations(false);
    };
    fetchLocations();
    // eslint-disable-next-line
  }, []);

  if (retrievingLocations) {
    return (
      <div className={classes.circularProgressContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (locationsError) {
    return (
      <div className={classes.errorTextContainer}>
        <Typography>
          An error occured while fetching the{" "}
          {isHospitalVariant ? "hospitals" : "urgent care locations"}. Please try again later.
        </Typography>
      </div>
    );
  }

  return (
    <PageContainer noGap>
      <Map
        containerStyle={{
          width: "100%",
          height: "100%",
        }}
        markers={locations}
        chosenMarker={chosenMarker}
        onMarkerClick={onMarkerClick}
        infoWindowProps={{
          children: <InfoWindow hospital={chosenMarker} text={DrivingDirections} />,
        }}
      />
    </PageContainer>
  );
};
export default MapView;
