import React from "react";
import DatePicker from "react-datepicker";
import { TextField } from "@mui/material";
import { setHours, setMinutes } from "date-fns";
import { TimePickerProps } from "./types";
import { useStyles } from "./TimePicker.styles";

/**
 * @deprecated Use `TimePicker` from `@mui/x-date-pickers` instead
 */
const TimePicker = ({
  selectedTime,
  label,
  onChangeHandler,
  timeInterval,
  error,
  minHours,
  maxHours,
}: TimePickerProps) => {
  const { classes } = useStyles();
  return (
    <DatePicker
      popperClassName={classes.timePopper}
      calendarClassName={classes.timeCalendar}
      selected={selectedTime}
      onChange={(date) => {
        if (date) {
          onChangeHandler(date);
        }
      }}
      customInput={
        <TextField label={label} variant="outlined" error={error} data-testid="time-picker-input" />
      }
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeInterval}
      timeCaption="Time"
      dateFormat="h:mm aa"
      showPopperArrow={false}
      minTime={setHours(setMinutes(new Date(), 0), minHours)}
      maxTime={setHours(setMinutes(new Date(), 0), maxHours)}
    />
  );
};

export default TimePicker;
