import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useAppEvent } from "../../hooks/useAppEvent/useAppEvent";
import { useAuth } from "util/Security";

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
    height: "100ch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "24px",
    fontWeight: 500,
    marginBottom: "30px",
  },
}));

export default function AppInit(props) {
  useAppEvent();
  const { onAppInitHandler } = props;
  const auth = useAuth();
  const { classes } = useStyles();

  useEffect(() => {
    const checkIfLoggedIn = async () => {
      if (!auth.user) {
        await auth.checkLogin((user) => {
          onAppInitHandler(user);
        });
      }
    };
    checkIfLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.title}>App Loading...</div>
      <CircularProgress />
    </div>
  );
}
