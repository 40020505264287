import React, { useCallback, useEffect, useState } from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  InsertDriveFile,
  KeyboardArrowDown,
  Update as UpdateIcon,
  VideoCall as VideoCallIcon,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import MobileReportView from "views/Report/MobileReportView";
import { EnhancedTableHead, EnhancedTableToolbar, SearchFilter } from "./components";
import { EnhacedTableCollapsed } from "./components/EnhacedTableCollapsed";
import EnhancedTableColDefs from "./components/EnhancedTableColDefs";
import { useDebounce } from "hooks/useDebounce";
import { useGenericEnhancedTableContext } from "hooks/useGenericEnhanceTableContext";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { GenericEnhancedTableParams, GenericEnhancedTableProps, GridConfig } from "./types";
import { useStyles } from "./styles";

export const defaultGridConfig: GridConfig = {
  showActionColumn: true,
  showActionColumnOnStart: true,
  allowDelete: true,
  allowEdit: true,
  allowOverwrite: false,
  allowHistory: false,
  showTableToolbar: true,
  showCustomButton: false,
  showSearchBar: true,
  showScannedSpecimenImage: false,
};

export default function GenericEnhancedTable({
  tableTitle,
  colDefs,
  url,
  hideCheckboxes,
  dataItems,
  orderBy,
  order,
  defaultSearchBy = "",
  defaultSearchText = "",
  gridConfig = defaultGridConfig,
  onEditHandler,
  onOverwriteHandler,
  onHistoryHandler,
  onDeleteHandler,
  refreshSourceData,
  setOpen,
  onRowClicked,
  mobileReportView,
  toolbarButtons,
  titleButtons,
  titleSelects,
  onShowScannedSpecimenImageHandler,
  customFilter,
  urlParams,
  collapsedTableKey,
  collapsedTableColDefs,
  makeCustomActions = () => [],
  showRowLengthId,
  rowToId = (row) => row.id,
  toolbarDynamicContent,
}: Readonly<GenericEnhancedTableProps>) {
  const app = useApp();
  const isMobile = useIsMobile();
  const { selected, setSelected, sourceData, setSourceData } = useGenericEnhancedTableContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedData, setSelectedData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [searchBy, setSearchBy] = useState(defaultSearchBy);
  const [searchByTag, setSearchByTag] = useState(null);
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [clickedRowId, setClickedRowId] = useState<number>();
  const [internalRefresh, setInternalRefresh] = useState(false);
  const [pageSize, setPageSize] = useState(isMobile ? 10 : 50);
  const [headOrder, setHeadOrder] = useState(order);
  const [headOrderBy, setHeadOrderBy] = useState(orderBy);
  const [openCollapsedRow, setOpenCollapsedRow] = useState<boolean[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { programId, departmentId } = usePrograms();
  const debouncedSearchText = useDebounce(searchText);

  const { classes, cx } = useStyles({
    isLoading,
    showScannedSpecimenImage: gridConfig.showScannedSpecimenImage,
  });

  const getApiData = async (tableOrder, tableOrderBy) => {
    setIsLoading(true);
    try {
      let params: GenericEnhancedTableParams = {
        page_no: page,
        page_size: pageSize,
        order: tableOrder,
        order_by: tableOrderBy,
        search_by: searchBy,
        search_text: searchText,
        program_id: programId,
        department_id: departmentId,
        ...urlParams,
      };
      if (searchByTag) {
        params = {
          ...params,
          tag_id: searchByTag,
        };
      }
      const response = await axiosClient.get(url ?? "", { params });

      if (response?.data) {
        setHeadOrder(tableOrder);
        setHeadOrderBy(tableOrderBy);
        setSourceData(response.data.data ?? []);
        setTotalRecords(response.data.totalRecords ?? 0);
      }
    } catch (e) {
      app.addError("Something went wrong, please contact support");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelected([]);
    setSelectedData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (url) {
      getApiData(headOrder, headOrderBy);
    } else {
      setSourceData(dataItems ?? []);
      setTotalRecords(dataItems?.length ?? 0);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataItems,
    page,
    url,
    urlParams,
    pageSize,
    searchBy,
    debouncedSearchText,
    refreshSourceData,
    internalRefresh,
    programId,
    departmentId,
    searchByTag,
    headOrder,
    headOrderBy,
  ]);

  useEffect(() => {
    setPage(0);
  }, [urlParams]);

  const handleInternalRefresh = (searchById) => {
    setSearchBy(searchById);
    setInternalRefresh(!internalRefresh);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = headOrderBy === property && headOrder === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setHeadOrder(newOrder);
    setHeadOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sourceData.map(rowToId);
      setSelected(newSelecteds);
      setSelectedData(sourceData);
      return;
    }
    setSelected([]);
    setSelectedData([]);
  };

  const handleClick = (event, rowId) => {
    const selectedIndex = selected.indexOf(rowId);
    const newSelected =
      selectedIndex === -1
        ? [...selected, rowId]
        : [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)];

    setSelected(newSelected);
  };

  const handleClickData = (event, row) => {
    const selectedIndex = selectedData.indexOf(row);

    const newSelectedData =
      selectedIndex === -1
        ? [...selectedData, row]
        : [...selectedData.slice(0, selectedIndex), ...selectedData.slice(selectedIndex + 1)];

    setSelectedData(newSelectedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCollapseClick = (index) => {
    setOpenCollapsedRow((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const filterSourceDataBySearch = useCallback((key, newSearchText) => {
    setSearchBy((prev) => {
      if (key !== prev || (key === prev && searchText !== newSearchText)) {
        setPage(0);
      }
      return key;
    });
    setSearchText(newSearchText);
  }, []);

  const onClickedRowHandler = (event, row, index) => {
    if (onRowClicked) {
      onRowClicked(row, index);
    }
    const id = rowToId(row);
    setClickedRowId((clickedRowId) => (id !== clickedRowId ? id : undefined));
  };

  return (
    <div
      className={cx(classes.root, {
        [classes.rootMobile]: isMobile,
      })}
      style={{ position: "relative" }}
    >
      {mobileReportView && isMobile ? (
        <MobileReportView sourceData={sourceData} onClickedRowHandler={onClickedRowHandler} />
      ) : (
        <>
          {gridConfig.showSearchBar && (
            <SearchFilter
              filterSourceData={filterSourceDataBySearch}
              colDefs={colDefs}
              handleRefreshData={handleInternalRefresh}
              setSearchByTag={setSearchByTag}
              defaultSearchBy={defaultSearchBy}
            />
          )}
          {customFilter || null}

          {gridConfig.showTableToolbar && (
            <EnhancedTableToolbar
              selected={selected}
              selectedData={selectedData}
              setSelected={setSelected}
              setSelectedData={setSelectedData}
              tableTitle={tableTitle}
              buttons={toolbarButtons}
              titleButtons={titleButtons}
              titleSelects={titleSelects}
              dynamicContent={toolbarDynamicContent}
            />
          )}
          <TableContainer className={classes.tableContainer}>
            <Table
              stickyHeader
              className={`${classes.table} ${collapsedTableKey && classes.tableHeaderBackground}`}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={headOrder}
                orderBy={headOrderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={sourceData.length}
                colDefs={colDefs}
                gridConfig={gridConfig}
                hideCheckboxes={hideCheckboxes}
                collapsedTable={collapsedTableKey}
              />
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={colDefs.length} className={classes.loadingCell}>
                      <CircularProgress className={classes.loadingSpinner} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {sourceData.map((row, index) => {
                    const id = rowToId(row);
                    const isItemSelected = isSelected(id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <>
                        <TableRow
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={id}
                          className={cx(
                            classes.tableRow,
                            {
                              [classes.clickedTableRow]: id === clickedRowId,
                            },
                            collapsedTableKey && classes.tableCellCollapse,
                          )}
                          selected={isItemSelected}
                          onClick={(e) => onClickedRowHandler(e, row, index)}
                          data-cy="tableRow"
                          style={{ backgroundColor: collapsedTableKey ? "#F5F5F5" : "#FFF" }}
                        >
                          {collapsedTableKey && (
                            <TableCell style={{ width: "5em" }}>
                              <IconButton
                                size="small"
                                onClick={() => handleCollapseClick(index)}
                                data-testid="collapseButton"
                              >
                                <KeyboardArrowDown
                                  style={{
                                    transition: ".2s",
                                    transform: openCollapsedRow[index]
                                      ? "rotate(180deg)"
                                      : "rotate(0)",
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          )}
                          {!hideCheckboxes && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                className={classes.checkbox}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleClick(event, rowToId(row));
                                  handleClickData(event, row);
                                }}
                              />
                            </TableCell>
                          )}
                          {gridConfig.showActionColumn && gridConfig.showActionColumnOnStart && (
                            <TableCell>
                              <Grid container spacing={1} style={{ flexWrap: "wrap" }}>
                                {gridConfig.allowEdit && (
                                  <Grid item>
                                    <Button
                                      startIcon={<EditIcon />}
                                      onClick={() => (onEditHandler ? onEditHandler(row) : null)}
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                    >
                                      Edit
                                    </Button>
                                  </Grid>
                                )}
                                {gridConfig.allowOverwrite && (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      startIcon={<UpdateIcon />}
                                      onClick={() =>
                                        onOverwriteHandler ? onOverwriteHandler(row) : null
                                      }
                                      size="small"
                                    >
                                      Overwrite
                                    </Button>
                                  </Grid>
                                )}
                                {gridConfig.allowHistory && (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      startIcon={<UpdateIcon />}
                                      onClick={() =>
                                        onHistoryHandler ? onHistoryHandler(row) : null
                                      }
                                      size="small"
                                    >
                                      History
                                    </Button>
                                  </Grid>
                                )}
                                {gridConfig.showScannedSpecimenImage && (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      startIcon={<InsertDriveFile />}
                                      onClick={() =>
                                        onShowScannedSpecimenImageHandler
                                          ? onShowScannedSpecimenImageHandler(row)
                                          : null
                                      }
                                      size="small"
                                    >
                                      Image
                                    </Button>
                                  </Grid>
                                )}
                                {gridConfig.allowDelete && (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      startIcon={<DeleteIcon />}
                                      onClick={() =>
                                        onDeleteHandler ? onDeleteHandler(row) : null
                                      }
                                      size="small"
                                      style={{ zIndex: "3" }}
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                )}
                                {makeCustomActions(row).map((props) => {
                                  const { text, key, ...buttonProps } = props;

                                  const keyLabel = `custom-action-button-${key}`;
                                  return (
                                    <Grid item key={keyLabel}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={null}
                                        size="small"
                                        {...buttonProps}
                                      >
                                        {text}
                                      </Button>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </TableCell>
                          )}
                          <EnhancedTableColDefs
                            colDefs={colDefs}
                            row={row}
                            showRowLengthId={showRowLengthId}
                            rowToId={rowToId}
                          />
                          {gridConfig.showCustomButton && (
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                endIcon={<VideoCallIcon />}
                                onClick={() => {
                                  if (setOpen) {
                                    setOpen(false);
                                  }
                                }}
                              >
                                Join Video
                              </Button>
                            </TableCell>
                          )}
                          {gridConfig.showActionColumn && !gridConfig.showActionColumnOnStart && (
                            <TableCell>
                              <Grid container spacing={1}>
                                {gridConfig.allowEdit && (
                                  <Grid item>
                                    <Button
                                      startIcon={<EditIcon />}
                                      onClick={() => (onEditHandler ? onEditHandler(row) : null)}
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                    >
                                      Edit
                                    </Button>
                                  </Grid>
                                )}
                                {gridConfig.allowOverwrite && (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      startIcon={<UpdateIcon />}
                                      onClick={() =>
                                        onOverwriteHandler ? onOverwriteHandler(row) : null
                                      }
                                      size="small"
                                    >
                                      Overwrite
                                    </Button>
                                  </Grid>
                                )}
                                {gridConfig.allowDelete && (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      startIcon={<DeleteIcon />}
                                      onClick={() =>
                                        onDeleteHandler ? onDeleteHandler(row) : null
                                      }
                                      size="small"
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </TableCell>
                          )}
                        </TableRow>
                        {collapsedTableKey && (
                          <EnhacedTableCollapsed
                            data={row[collapsedTableKey]}
                            collapseTableColDefs={collapsedTableColDefs}
                            open={openCollapsedRow[index]}
                            rowToId={rowToId}
                          />
                        )}
                      </>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}
      {!isLoading && totalRecords > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalRecords}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}
