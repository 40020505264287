import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isAxiosError } from "axios";
import { CLEAR_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { Program, useGetDepartments, useGetPrograms } from "../hooks";
import { useProgramsLoading } from "./useProgramsLoading";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { useAuth } from "util/Security";

export const useGetProgramAndDepartment = () => {
  const {
    programId,
    setProgramId,
    setProgramList,
    departmentId,
    setDepartmentId,
    setDepartmentList,
    setProgramOverrideStatus,
  } = usePrograms();

  const { pathname } = useLocation();
  const { addError, dispatch } = useApp();
  const { user } = useAuth();
  const { setIsProgramsLoading } = useProgramsLoading();
  const { getProgramsList } = useGetPrograms();
  const { getDepartmentsList } = useGetDepartments();

  const isAccessToProgramsAllowed = user && window.location.host.startsWith("app");

  const getPrograms = async () => {
    if (user.userId) {
      setIsProgramsLoading(true);
      try {
        const dataPrograms = await getProgramsList();
        const programs = dataPrograms?.data || [];
        setProgramList(programs);

        let program: Program | null | undefined = null;
        if (pathname) {
          program = programs.find((program) => program.url_path === pathname);
        }
        if (program?.id) {
          setProgramId(Number(program?.id));
          setProgramOverrideStatus(program?.program_override_status ?? "");
        }
      } catch (error) {
        if (isAxiosError(error) && error.response?.status !== 401) {
          addError("Unexpected error loading. Please contact support");
        }
      } finally {
        setIsProgramsLoading(false);
      }
    }
  };

  const getDepartments = async () => {
    setIsProgramsLoading(true);
    if (!programId) {
      setDepartmentId(0);
      setDepartmentList([]);
      setIsProgramsLoading(false);
      return;
    }

    try {
      const dataDepartments = await getDepartmentsList(programId);
      const departments = dataDepartments?.data || [];
      setDepartmentList(departments);
      setDepartmentId(departmentId || 0);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status !== 401) {
        addError("Unexpected error loading. Please contact support");
      }
    } finally {
      setIsProgramsLoading(false);
    }
  };

  useEffect(() => {
    if (isAccessToProgramsAllowed) {
      getDepartments();
    }
    dispatch({ type: CLEAR_VIRTUAL_VISIT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  useEffect(() => {
    dispatch({ type: CLEAR_VIRTUAL_VISIT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  useEffect(() => {
    if (isAccessToProgramsAllowed && (programId || programId === 0)) {
      getDepartments();
      getPrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
};
