import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { Button, CircularProgress, FormControl, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    margin: `${theme.spacing(0)} auto`,
    marginLeft: " 0.7rem",
    paddingRight: "1rem",
  },

  wrapText: {
    width: "100%",
  },

  button: {
    margin: theme.spacing(1),
  },

  formControl: {
    minWidth: "100%",
    maxWidth: "100%",
  },

  flexFormControl: {
    minWidth: "100%",
    maxWidth: "100%",
    flexDirection: "row",
  },
}));

export const TextInput = ({ askQuestion, isLoading, disableInput }) => {
  const { classes } = useStyles();
  const [prompt, setPrompt] = useState<string | undefined>("");

  const submit = (event) => {
    event.preventDefault();
    askQuestion(prompt);
  };

  return (
    <form className={classes.wrapForm} autoComplete="off" onSubmit={submit}>
      <FormControl variant="standard" className={classes.flexFormControl}>
        <TextField
          variant="standard"
          id="standard-text"
          label="Type patient diagnosis"
          value={prompt}
          className={classes.wrapText}
          onChange={(e) => setPrompt(e.target.value)}
          disabled={disableInput}
          data-testid="aiSearchInput"
        />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          data-testid="aiSearchButton"
          className={classes.button}
          disabled={isLoading || disableInput}
        >
          {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
        </Button>
      </FormControl>
    </form>
  );
};
