import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCheckPermissions } from "./useCheckPermissions";
import { useAuth } from "util/Security";
import { calculatePathBase } from "util/util_functions";
import { IUseStepsReloadProps } from "../types";

export { useCheckPermissions };

export const useStepsReload = ({ virtual_session_id, inSession }: IUseStepsReloadProps): void => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathBase = calculatePathBase(location.pathname);

  useEffect(() => {
    const params = new URLSearchParams();
    if (virtual_session_id) {
      if (!inSession) {
        params.append("step", "preview");
      } else {
        params.append("step", "in-session");
      }
    } else {
      params.delete("step");
    }
    navigate({
      pathname: `${pathBase}/${virtual_session_id}`,
      search: params.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtual_session_id, navigate, inSession]);
};

type VirtualSession = {
  virtual_session_id: number;
  status: string;
  queue: string;
  patient_id?: number;
};

export const useJoinButtonText = (virtualSession: VirtualSession) => {
  const rejoinText = "Rejoin My Visit";
  const startText = "Join Waiting Room";
  const auth = useAuth();
  const userId = auth?.user.userId;
  const joinText =
    window.location.pathname.includes("videoinvite") && userId !== virtualSession?.patient_id
      ? "Join Visit"
      : rejoinText;

  const [startBtnText, setstartBtnText] = useState<string>(rejoinText);
  const [reloadCondition, setreloadCondition] = useState<boolean>(false);

  useEffect(() => {
    const condition =
      virtualSession &&
      virtualSession.virtual_session_id &&
      virtualSession.status === "New" &&
      !virtualSession.queue;
    const newText = condition ? startText : joinText;
    setstartBtnText(newText);
    setreloadCondition(Boolean(condition));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualSession]);

  return [startBtnText, !reloadCondition];
};
