import React from "react";
import DataCurationTable from "./DataCurationTable";
import NeedsReviewTable from "./DataCurationTable/NeedsReviewTable";
import GenericTabs, { TabConfig } from "./Tabs";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import {
  colDefsChangeLog,
  colDefsLocations,
  colDefsProviders,
} from "./DataCurationTable/utils/colDefs";
import { rowsChangeLog } from "./DataCurationTable/utils/mocks";

const DataCurationPage: React.FC = () => {
  const providerUrl = "provider";
  const locationUrl = "location";

  const tabsConfig: TabConfig = {
    label: ["Providers", "Locations", "Change Log", "Needs Review"],
    component: [
      <DataCurationTable
        key="providersTable"
        dataUrl={API_ENDPOINTS.dataCurationProviders}
        exportJsonUrl={API_ENDPOINTS.dataCurationProviderExport}
        exportJsonFilename={`AHSProviders_${new Date().toISOString()}.json`}
        exportJsonInChunks
        colDefs={colDefsProviders}
        linkTo={providerUrl}
        id="providersTable"
        allowSelection
        defaultOrderBy="lastName"
        cellWidth="auto"
      />,
      <DataCurationTable
        key="locationsTable"
        dataUrl={API_ENDPOINTS.dataCurationLocations}
        exportJsonUrl={API_ENDPOINTS.dataCurationLocationExport}
        exportJsonFilename="locations.zip"
        colDefs={colDefsLocations}
        linkTo={locationUrl}
        id="locationsTable"
        allowSelection
        defaultOrderBy="id"
        cellWidth="auto"
        newButtonUrl="/console/data-curation/add-location"
      />,
      <DataCurationTable
        key="changeLogTable"
        rows={rowsChangeLog}
        colDefs={colDefsChangeLog}
        showLocationsFilter={false}
        showAddNewButton={false}
        showExportCsvButton
        id="changeLogTable"
        allowSelection={false}
        cellWidth="auto"
      />,
      <NeedsReviewTable key="needsReviewTable" />,
    ],
  };

  return <GenericTabs config={tabsConfig} />;
};

export default DataCurationPage;
