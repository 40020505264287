import React from "react";
import { useLocation } from "react-router-dom";
import { Theme } from "@mui/material";
import { useAppState } from "../../state";
import UserMenu from "./UserMenu/UserMenu";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(40, 42, 43)",
    height: `calc(100vh - ${theme.headerHeight})`,
  },
  container: {
    position: "relative",
    flex: "1",
  },
  innerContainer: {
    display: "flex",
    width: "888px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px",
    },
  },
  content: {
    background: theme.palette.common.white,
    width: "100%",
    padding: "4em",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      padding: "2em",
    },
  },
  title: {
    color: theme.palette.common.white,
    margin: "1em 0 0",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      fontSize: "1.1rem",
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  hideContent?: boolean;
}

const IntroContainer = ({ children, hideContent = false }: IntroContainerProps) => {
  const { classes, cx } = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  return (
    <div className={classes.background}>
      {user && location.pathname !== "/login" && <UserMenu />}
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div
            className={cx({
              [classes.content]: !hideContent,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
