import { API_ENDPOINTS, axiosClient } from "util/api_helper";

export const handleUpdateCellPhone = async ({ app, cellPhone, patientId }) => {
  try {
    await axiosClient.post(API_ENDPOINTS.cellphone_verify, {
      patient_id: patientId,
      cell_phone: cellPhone,
    });
    app.addInfoMsg(
      "We sent a text message to your cell phone with a link to log in. Please check your text message to continue.",
    );
  } catch (err) {
    if (err.response.status === 409) {
      app.addError(
        "Error: Another user already has registered this cell phone. Please check you have entered it properly and if so please contact support",
      );
    }
    app.addError("Failed to send Text message login, please contact support.");
  }
};
