import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../util/Security";
import { getApiHost } from "../util/api_helper";

export default function SsoInit() {
  const navigate = useNavigate();
  const auth = useAuth();
  if (auth.user?.mrn && auth.user?.mrn.length > 0) {
    const apiHost = getApiHost();
    const ssoInitUrl = `${apiHost}/samlsso/idp/init-unsolicited-response`;
    window.location = ssoInitUrl;
  } else {
    navigate("/id/start?r=ssoinit");
  }

  return (
    <div>
      <br />
      <div>
        <span>Patient Portal Loading...</span>
      </div>
      <br />
    </div>
  );
}
