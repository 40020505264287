import { useEffect, useState } from "react";
import { useAuth } from "util/Security";
import { UserTypes } from "util/api_helper";

export const useUserType = () => {
  const auth = useAuth();
  const [staffType, setStaffType] = useState(auth?.user.userType || "");
  const [isPatient, setIsPatient] = useState(
    auth?.user.userType === UserTypes.PATIENT || auth?.user.userType === UserTypes.CONSUMER,
  );
  const [isAdmin, setIsAdmin] = useState(auth?.user.userType === UserTypes.ADMIN);
  const [isRegStaff, setIsRegStaff] = useState(
    auth?.user.userType === UserTypes.REGISTRATION_STAFF,
  );
  const [isNurse, setIsNurse] = useState(auth?.user.userType === UserTypes.NURSE);
  const [isSpecimenTech, setIsSpecimenTech] = useState(
    auth?.user.userType === UserTypes.SPECIMEN_TECH,
  );
  const [isManager, setIsManager] = useState(auth?.user?.userType === UserTypes.MANAGER);
  const [isStaff, setIsStaff] = useState(
    !(auth?.user.userType === UserTypes.PATIENT || auth?.user.userType === UserTypes.CONSUMER),
  );
  const [isPhysician, setIsPhysician] = useState(auth?.user.userType === UserTypes.PHYSICIAN);

  useEffect(() => {
    if (auth?.user.userType) {
      setStaffType(auth?.user.userType);
    }
  }, [auth]);
  useEffect(() => {
    setIsPatient(
      auth?.user.userType === UserTypes.PATIENT || auth?.user.userType === UserTypes.CONSUMER,
    );
  }, [auth]);
  useEffect(() => {
    setIsAdmin(auth?.user.userType === UserTypes.ADMIN);
  }, [auth]);
  useEffect(() => {
    setIsRegStaff(auth?.user.userType === UserTypes.REGISTRATION_STAFF);
  }, [auth]);
  useEffect(() => {
    setIsNurse(auth?.user.userType === UserTypes.NURSE);
  }, [auth]);
  useEffect(() => {
    setIsPhysician(auth?.user.userType === UserTypes.PHYSICIAN);
  }, [auth]);
  useEffect(() => {
    setIsSpecimenTech(auth?.user.userType === UserTypes.SPECIMEN_TECH);
  }, [auth]);
  useEffect(() => {
    setIsManager(auth?.user?.userType === UserTypes.MANAGER);
  }, [auth]);

  useEffect(() => {
    setIsStaff(!isPatient);
  }, [isPatient]);

  return {
    staffType,
    isPatient,
    isAdmin,
    isRegStaff,
    isNurse,
    isPhysician,
    isStaff,
    isSpecimenTech,
    isManager,
  };
};
