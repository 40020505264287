/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { FormValues, getValidationSchema, initialValues } from "./FormModel";
import Tags from "../components/OrderTags";
import { DISCONNECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { Department } from "components/Video/utils/types";
import useDisconnectActiveSession from "../useDisconnectActiveSession";
import { Record, useGetPhysicians } from "hooks/useGetPhysicians/useGetPhysicians";
import { useGetSpecialtys } from "hooks/useGetSpecialty/useGetSpecialty";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { useProgramType } from "util/programsHelpers";
import { useStyles } from "./styles";

enum HeardAboutUsThrough {
  PHYSICIAN_REFERRAL = "Physician Referral",
  FRIEND_COMMUNITY_REFERRAL = "Friend/community referral",
  AHS_WEB_SITE = "AHS Web site",
  GOOGLE_SEARCH = "Google search",
  ADVERTISEMENT = "Advertisement",
}

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const CloseSessionModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const [referralPhysician, setReferralPhysician] = useState<string>("");
  const [referralPhysicianName, setReferralPhysicianName] = useState<Record | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timerKeyPressPhysician = useRef<any>();
  const WAITING_TIME_SECONDS = useRef(1500).current;

  const {
    web: {
      closeSesionModal: {
        visitCompleted,
        confirmationTitle,
        checkOrders,
        otherNotes,
        appointmentScheduled,
        heardAboutUs,
        heardAboutUsSpeciality,
        physicianReferral,
        loadingMoreData,
        endSession,
      },
    },
  } = useTranslation();

  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const { isAdhoc, isConsultation, isVUC } = useProgramType();
  const { departmentList }: { departmentList: Department[] } = usePrograms();
  const disconnectSession = useDisconnectActiveSession();

  const app = useApp();
  const currentVirtualVisit = app?.vs_visits_state?.currentVirtualVisit;

  const {
    data: specialtysList,
    refetch: getSpecialtys,
    isLoading: loadingSpecialtys,
    isFetching: fetchingSpecialtys,
  } = useGetSpecialtys();

  const {
    data: physiciansList,
    getPhysiciansList,
    fetchNextPage: fetchNextPagePhysician,
    hasNextPage: hasNexPagePhysician,
    isFetchingNextPage,
  } = useGetPhysicians();

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      if (!isAdhoc) {
        await axiosClient.put(
          `/api/s/console/virtualvisit/sessions/${currentVirtualVisit.id}/tags`,
          values.tags,
        );
      }
      if (values.assignedDepartmentId) {
        await axiosClient.post("/api/s/console/virtualvisitconsole/assigned-department", {
          virtual_session_id: currentVirtualVisit.id,
          assigned_department_id: values.assignedDepartmentId,
        });
      }
      await axiosClient.post(API_ENDPOINTS.end_session_by_staff, {
        virtualSessionId: currentVirtualVisit.id,
        ...(values.notes && { notes: values.notes }),
        ...(values.patientReferenceSource && {
          patientReferenceSource: values.patientReferenceSource,
        }),
        ...(values.patientReferencePhysicianId && {
          patientReferencePhysician: values.patientReferencePhysicianId,
        }),
      });
      app.dispatch({
        type: DISCONNECT_VIRTUAL_VISIT,
        payload: currentVirtualVisit.id,
      });
      app.addInfoMsg("Virtual session was ended, please select another from the list.");
      disconnectSession();
      handleClose();
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
    actions.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: getValidationSchema(isConsultation),
  });

  useEffect(() => {
    formik.setFieldValue("patientReferencePhysicianId", undefined);
    setReferralPhysician("");
    setReferralPhysicianName(null);
    if (formik.values.patientReferenceSpeciality) {
      getPhysiciansList(formik.values.patientReferenceSpeciality, referralPhysician);
    }
  }, [formik.values.patientReferenceSpeciality]);

  useEffect(() => {
    if (formik.values.patientReferenceSpeciality) {
      getPhysiciansList(formik.values.patientReferenceSpeciality, referralPhysician);
    }
  }, [referralPhysician]);

  useEffect(() => {
    if (formik.values.patientReferenceSource === HeardAboutUsThrough.PHYSICIAN_REFERRAL) {
      getSpecialtys();
    }
  }, [formik.values.patientReferenceSource]);

  useEffect(() => {
    if (currentVirtualVisit.checkout_note) {
      formik.setFieldValue("notes", currentVirtualVisit.checkout_note);
    }
  }, [currentVirtualVisit]);

  useEffect(() => {
    if (currentVirtualVisit.department_id) {
      formik.setFieldValue("assignedDepartmentId", currentVirtualVisit?.department_id);
    }
  }, [currentVirtualVisit.department_id]);
  return (
    <Dialog onClose={handleClose} open={isOpen} fullScreen={isMobile} maxWidth="sm" fullWidth>
      <DialogTitle>{visitCompleted}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className={classes.closeButton}
        size="large"
      >
        <Close />
      </IconButton>
      <DialogContent>
        <form id="close-session-modal" onSubmit={formik.handleSubmit}>
          {isAdhoc && <Typography variant="body1">{confirmationTitle}</Typography>}
          {(isConsultation || isVUC) && (
            <>
              <Typography variant="body1">{checkOrders}</Typography>
              <Box marginBottom="8px">
                <Tags
                  checkedTags={formik.values.tags}
                  setCheckedTags={(tags) => formik.setFieldValue("tags", tags)}
                />
              </Box>
              <TextField
                className={classes.textField}
                label={otherNotes}
                multiline
                fullWidth
                minRows={1}
                maxRows={4}
                variant="outlined"
                value={formik.values.notes}
                disabled={!formik.values.tags.length}
                onChange={formik.handleChange}
                name="notes"
                data-cy="notesInputDialog"
              />
            </>
          )}
          {isConsultation && (
            <>
              <TextField
                className={classes.textField}
                label={appointmentScheduled}
                id="AppointmentScheduled"
                select
                variant="outlined"
                fullWidth
                name="assignedDepartmentId"
                value={formik.values.assignedDepartmentId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.assignedDepartmentId && !!formik.errors.assignedDepartmentId}
                helperText={
                  formik.touched.assignedDepartmentId && formik.errors.assignedDepartmentId
                }
              >
                {departmentList.map((department) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.textField}
                label={heardAboutUs}
                id="HeardAbout"
                select
                variant="outlined"
                fullWidth
                name="patientReferenceSource"
                value={formik.values.patientReferenceSource}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.patientReferenceSource && !!formik.errors.patientReferenceSource
                }
                helperText={
                  formik.touched.patientReferenceSource && formik.errors.patientReferenceSource
                }
              >
                {Object.values(HeardAboutUsThrough).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              {formik.values.patientReferenceSource === HeardAboutUsThrough.PHYSICIAN_REFERRAL &&
                specialtysList && (
                  <>
                    <TextField
                      className={classes.textField}
                      label={heardAboutUsSpeciality}
                      id="HeardAboutUsSpeciality"
                      select
                      variant="outlined"
                      fullWidth
                      name="patientReferenceSpeciality"
                      value={formik.values.patientReferenceSpeciality ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.patientReferenceSpeciality &&
                        !!formik.errors.patientReferenceSpeciality
                      }
                      helperText={
                        formik.touched.patientReferenceSpeciality &&
                        formik.errors.patientReferenceSpeciality
                      }
                    >
                      {specialtysList
                        ? specialtysList.data.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : (loadingSpecialtys || fetchingSpecialtys) && <CircularProgress />}
                    </TextField>

                    <Autocomplete
                      id="HeardAboutUsPhysicianId"
                      value={referralPhysicianName}
                      loading={isFetchingNextPage}
                      loadingText={loadingMoreData}
                      fullWidth
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue("patientReferencePhysicianId", newValue.id);
                          setReferralPhysicianName(newValue);
                        } else {
                          formik.setFieldValue("patientReferencePhysicianId", undefined);
                          setReferralPhysicianName(null);
                        }
                      }}
                      onInputChange={(event, value) => {
                        clearTimeout(timerKeyPressPhysician.current);
                        timerKeyPressPhysician.current = setTimeout(() => {
                          setReferralPhysician(value);
                        }, WAITING_TIME_SECONDS);
                      }}
                      ListboxProps={{
                        onScroll: async (event) => {
                          const node = event.currentTarget;
                          if (node.scrollTop + node.clientHeight === node.scrollHeight) {
                            if (hasNexPagePhysician) {
                              await fetchNextPagePhysician();
                            }
                          }
                        },
                      }}
                      options={
                        (physiciansList &&
                          physiciansList.pages.flatMap((item) => item.data.records)) ||
                        []
                      }
                      renderInput={(params) => (
                        <TextField
                          className={classes.textField}
                          variant="outlined"
                          {...params}
                          label={physicianReferral}
                        />
                      )}
                    />
                  </>
                )}
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          form="close-session-modal"
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Close fontSize="small" />}
          disabled={!formik.isValid || formik.isSubmitting}
          data-cy="closeSessionButtonDialog"
        >
          {endSession}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseSessionModal;
