import moment from "moment";
import { generateTimeWindowString } from "util/util_functions";

export const colDefs = [
  {
    id: "surveyName",
    numeric: false,
    disablePadding: true,
    label: "Survey name",
    searchable: true,
    sortable: true,
  },
  {
    id: "formName",
    numeric: false,
    disablePadding: true,
    label: "Form",
    searchable: true,
    sortable: true,
  },
  {
    id: "intervalMinutes",
    numeric: false,
    disablePadding: true,
    label: "Send SMS every (hours)",
    searchable: false,
    sortable: true,
    isCustomContent: true,
    func: (val) => {
      return val ? val / 60 : "\u2013";
    },
  },
  {
    id: "actionStartRunning",
    numeric: false,
    disablePadding: true,
    label: "SMS Time Window",
    searchable: false,
    sortable: false,
    isCustomContent: true,
    func: (val, row) => {
      return generateTimeWindowString(val, row.actionEndsRunning);
    },
  },
  {
    id: "automationParams",
    numeric: false,
    disablePadding: true,
    label: "Send after On-Call (hours)",
    searchable: false,
    sortable: true,
    isCustomContent: true,
    func: (val) => {
      return val?.only_send_after ? val.only_send_after / 60 : "\u2013";
    },
  },
  {
    id: "departmentName",
    numeric: false,
    disablePadding: true,
    label: "Department",
    searchable: true,
    sortable: true,
  },
  {
    id: "lastSuccesfulExecution",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "Last Execution",
    searchable: false,
    sortable: true,
  },
];

export interface SurveyConfiguration {
  surveyName: string;
  formName: string;
  intervalMinutes: string;
  actionStartRunning: string;
  actionEndsRunning: string;
  sendAfterMinutes: string;
  departmentName: string;
  enabled: boolean;
}

const generateTimeWindowOptions = () => {
  const items: Array<{ displayText: string; value: string }> = [];
  for (let i = 0; i < 24; i++) {
    items.push({
      displayText: moment({ hour: i }).format(i === 0 ? "HH A" : "hh A"),
      value: moment({ hour: i }).format("HH:00:00"),
    });
  }
  return items;
};

export const timeWindowOptions = generateTimeWindowOptions();
