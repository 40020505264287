/* eslint-disable */
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SmsIcon from "@mui/icons-material/Sms";
import {
  Box,
  Divider,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { MessagingProvider } from "../context/messagingContext";
import { makeStyles } from "tss-react/mui";
import { getUtcTimeAgo } from "util/util_functions";

const useStyles = makeStyles()((theme) => ({
  dashboard: {
    height: `calc(100vh - ${theme.headerHeight})`,
    flexWrap: "nowrap",
    boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.14)",
    borderTop: "1px solid rgba(0,0,0,0.01)",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 375,
    margin: "0 auto",
    paddingTop: 20,
  },
  label: {
    height: 50,
  },
  title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  date: {
    fontSize: "20px",
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  colorLibStepIconRoot: {
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    color: theme.palette.common.white,
    width: 50,
    height: 50,
    minHeight: 50,
    minWidth: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
}));

const ColorlibStepIcon = ({ icon: iconType }) => {
  const { classes } = useStyles();

  const icons = {
    default: <SmsIcon />,
    invitation: <SmsIcon />,
    media: <PhotoCameraIcon />,
    accepted: <CheckIcon />,
  };

  return <div className={classes.colorLibStepIconRoot}>{icons[iconType]}</div>;
};

const FamilyJournalView = ({ steps, lastMessageDate, sessionName }) => {
  const { classes } = useStyles();

  const StepComponent = ({ step }) => {
    if (step.type === "invitation") {
      return <Typography>Invite Sent to</Typography>;
    }

    if (step.type === "accepted") {
      return <Typography>Invite Accepted to</Typography>;
    }

    return <Typography>{step.body}</Typography>;
  };

  return (
    <MessagingProvider>
      <Grid container spacing={0} className={classes.dashboard}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className={classes.title}>{sessionName || "Family Journal"}</span>
          <span className={classes.date}>{`Last updated ${getUtcTimeAgo(lastMessageDate)}`}</span>
          <span style={{ fontSize: 16 }}>
            Reminder: This is one-way communication, for any questions and concerns call the
            facility's nurses' station
          </span>
          <Divider />
        </div>

        <Box sx={{ maxWidth: 400 }}>
          <Stepper nonLinear activeStep={steps.length - 1} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.id} active>
                <StepLabel
                  className={classes.label}
                  StepIconComponent={ColorlibStepIcon}
                  icon={step.type}
                >
                  {step.date}
                  <div className={classes.step}>
                    <StepComponent
                      className={classes.step}
                      step={step}
                      ColorlibStepIcon={ColorlibStepIcon}
                    />
                  </div>
                </StepLabel>
                <StepContent>
                  {step.type === "media" && (
                    <Box
                      component="img"
                      sx={{
                        height: 300,
                        width: 300,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="Patient Photo"
                      src={step.media}
                    />
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
    </MessagingProvider>
  );
};

export default FamilyJournalView;
