import { makeStyles } from "tss-react/mui";

export const useSpecimenTrackingReportsStyle = makeStyles()(() => ({
  rootContainer: {
    width: "100%",
    maxWidth: "1562px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
    marginBottom: "1em",
  },
  backButton: {
    alignItems: "center",
    background: "transparent",
    outline: "none",
    border: "none",
    color: "inherit",
    marginRight: "auto",
    position: "absolute",

    "&:focus": {
      outline: "none",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "24px 0 24px 0",
  },
  reportTitle: {
    fontSize: "18px",
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
  },
  tabsContainer: {
    padding: "0 1.5em",
    "& .Mui-selected:focus": {
      outline: "none",
    },
  },
  toogleButton: {
    height: "100%",
    width: "7em",
    padding: 0,
    textTransform: "capitalize",
    color: "black",
  },
  selectedToogleButton: {
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "#043070",
      backgroundColor: "#efefef87",
    },
    "&.Mui-selected, &.Mui-selected:focus": {
      outline: "none",
    },
    "&.Mui-selected, &.Mui-selected:active": {
      outline: "none",
    },
  },
  exportToCsvBtn: {
    fontSize: "16px!important",
    width: "10em",
    height: "3em",
  },
}));
