import { MutableRefObject } from "react";
import Webcam from "react-webcam";

export interface Props {
  webcamRef: MutableRefObject<Webcam | null>;
  capture: (e) => void;
  skeleton?: () => JSX.Element;
  setShowAddManually: (showAddManually: boolean) => void;
  showAddManually: boolean;
  handleScanNewSpecimen: (orderId: string, specimenId: string) => void;
}

export enum FacingModesTypes {
  ENVIRONMENT = "environment",
  USER = "user",
  LEFT = "left",
  RIGHT = "right",
}
