import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { SpecimenAddResponse, SpecimenInfoManuallyPatchData } from "views/SpecimenTracking/types";

export const useUpdateSpecimenParts = () => {
  const updateSpecimenParts = (id: number, data: SpecimenInfoManuallyPatchData) => {
    const resp = axiosClient.patch<SpecimenAddResponse>(
      `${API_ENDPOINTS.specimenTrackingUpdateSpecimen}/${id}`,
      {
        ...data,
      },
    );
    return resp;
  };

  return {
    updateSpecimenParts,
  };
};
