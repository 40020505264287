import React, { useEffect } from "react";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { Button, ListItem, ListItemAvatar, alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import useChatContext from "../../components/Video/hooks/useChatContext/useChatContext";
import useVideoContext from "../../components/Video/hooks/useVideoContext/useVideoContext";
import { CONNECT_VIRTUAL_VISIT } from "../../components/Video/hooks/useVirtualVisitReducer/Actions";
import { useApp } from "../../util/AppContext";
import { useProgramType } from "util/programsHelpers";
import { IConversationListItemProps } from "./types";

const useStyles = makeStyles<{ selectedItem: boolean }>()((theme, { selectedItem }) => ({
  listItem: {
    position: "relative",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
    minHeight: "112px",
    display: "flex",
    alignItems: "center",
    backgroundColor: selectedItem ? alpha(theme.palette.primary.main, 0.2) : "unset",
    "&:hover": {
      backgroundColor: selectedItem
        ? alpha(theme.palette.primary.main, 0.24)
        : alpha(theme.palette.primary.main, 0.04),
    },
  },
  inline: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "14px",
    display: "block",
    marginTop: "6px",
    marginBottom: "12px",
  },
  img: {
    width: "40px",
    height: "40px",
  },
  avatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginRight: "10px",
  },
  header: {
    fontSize: "16px",
    fontWeight: 500,
  },
  timeslot: {
    fontSize: "12px",
    color: selectedItem ? "rgba(0, 0, 0, 0.46)" : "rgba(0, 0, 0, 0.26)",
    letterSpacing: "0.4px",
    display: "block",
  },
  joinBtn: {
    marginTop: "24px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `solid 1px ${theme.palette.secondary.main}`,
    fontWeight: 600,
    padding: "4px 6px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
  },
  rescheduleButton: {
    marginTop: "8px",
  },
}));

const ConversationListItem = ({
  lastMessage,
  listItemTitle,
  sessionId,
  icon,
  timeStr,
  canBeRescheduled,
  program,
}: IConversationListItemProps) => {
  const url = useResolvedPath("").pathname;
  const navigate = useNavigate();
  const { setIsChatWindowOpen, chatClient } = useChatContext();
  const {
    dispatch,
    vs_visits_state: { currentVirtualVisit, patientVirtualSession },
  } = useApp();
  const { room } = useVideoContext();
  const { isAdhoc } = useProgramType(program);

  const handleClick = (vsId: number) => {
    room?.disconnect();
    dispatch({
      type: CONNECT_VIRTUAL_VISIT,
      payload: vsId,
    });
    setIsChatWindowOpen(true);
    navigate(`${url}/${vsId}/chatroom`);
  };

  const selectedItem =
    currentVirtualVisit?.id === sessionId ||
    patientVirtualSession?.virtual_session_id === sessionId;
  const { classes } = useStyles({ selectedItem });

  useEffect(() => {
    if (selectedItem) {
      dispatch({
        type: CONNECT_VIRTUAL_VISIT,
        payload: currentVirtualVisit?.id || patientVirtualSession?.virtual_session_id,
      });
    }
  }, [patientVirtualSession?.virtual_session_id, currentVirtualVisit?.id, selectedItem, dispatch]);

  const handleJoinSession = (e) => {
    e.stopPropagation();
    room?.disconnect();
    if (chatClient?.connectionState === "connected") {
      chatClient?.shutdown();
    }
    dispatch({
      type: CONNECT_VIRTUAL_VISIT,
      payload: sessionId,
    });
    sessionStorage.setItem("hideStepper", JSON.stringify(true));
    navigate(`/videoinvite/${sessionId}?step=preview&fromConversations=true`);
  };

  const reschedule = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    navigate(`/inquiryrequest/${program.name}/${sessionId}/schedule`);
  };

  return (
    <ListItem
      alignItems="flex-start"
      className={classes.listItem}
      onClick={() => handleClick(sessionId)}
      data-cy={sessionId}
    >
      <ListItemAvatar className={classes.avatar}>
        <img alt="Remy Sharp" src={icon} className={classes.img} />
      </ListItemAvatar>
      <div>
        <div className={classes.header}>{listItemTitle}</div>
        <div className={classes.inline}>{lastMessage}</div>
        <div className={classes.timeslot}>{timeStr}</div>
        {canBeRescheduled && (
          <Button
            color="primary"
            variant="contained"
            className={classes.rescheduleButton}
            onClick={reschedule}
          >
            Reschedule
          </Button>
        )}
        {isAdhoc && (
          <button type="button" className={classes.joinBtn} onClick={handleJoinSession}>
            Join session
          </button>
        )}
      </div>
    </ListItem>
  );
};

export default ConversationListItem;
