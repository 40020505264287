import React from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { waitingUsersTableStyles } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/styles";
import { CONNECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { ALL_PATIENTS, ASSIGNED_TO_ME, UNASSIGNED, Urgency } from "util/constants";
import { useApp } from "util/AppContext";

type FilterByProps = {
  filterBy: string;
  setFilterBy: (filterBy: string) => void;
};

const FilterBy = ({ filterBy, setFilterBy }: FilterByProps) => {
  const { classes } = waitingUsersTableStyles();
  const app = useApp();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const handleFilterByChange = (event) => {
    setFilterBy(event.target.value);
    app.dispatch({
      type: CONNECT_VIRTUAL_VISIT,
      payload: null,
    });
    params.set("filterBy", event.target.value);
    navigate({ search: params.toString() });
  };

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel id="demo-simple-select-label" className={classes.label}>
        Filter By
      </InputLabel>
      <Select
        variant="standard"
        className={(classes.select, "select-form-filter")}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filterBy}
        onChange={(e) => handleFilterByChange(e)}
      >
        <MenuItem value={ALL_PATIENTS}>All Patients</MenuItem>
        <MenuItem value={ASSIGNED_TO_ME}>Assigned to me</MenuItem>
        <MenuItem value={UNASSIGNED}>Unassigned</MenuItem>
        <MenuItem value={Urgency.ROUTINE}>Routine</MenuItem>
        <MenuItem value={Urgency.URGENT}>Urgent</MenuItem>
        <MenuItem value={Urgency.LOW_PRIORITY}>Low Priority</MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterBy;
