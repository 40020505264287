import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "@formio/react";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import "./FormEmbeddedView.css";
import { useStyles } from "./styles";

export default function FormEmbeddedView() {
  const { classes } = useStyles();
  const { submission_id: submissionId, campaign_id: campaignId } = useParams();
  const [trackingId, setTrackingId] = useState(null);
  const [formSubmissionToken, setFormSubmissionToken] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formContext, setFormContext] = useState(null);
  const navigate = useNavigate();
  const app = useApp();

  const loadForm = async (feid, campaignId) => {
    try {
      const getUrl = `/api/s/formio/forms/${feid}?campaignId=${campaignId}`;
      const { data } = await axiosClient.get(getUrl);
      setFormData(data);
    } catch (error) {
      if (error.response.status === 409) {
        app.addInfoMsg("Form already submitted");
        navigate(`/surveysubmitted/${campaignId}`);
      } else {
        app.addError(`Error loading form: ${error?.response?.data?.description}`);
      }
    }
  };

  const loadFormSubission = async (fsid) => {
    try {
      const getUrl = `/api/s/form-submissions/${fsid}`;
      const { data } = await axiosClient.get(getUrl);

      setTrackingId(data.data.f?.external_tracking_id);
      setFormSubmissionToken(data.data.fs.security_token);
      setFormContext({ ...data.data.context });
    } catch (error) {
      console.error(`Error loading form: ${error?.response?.data?.description}`);
    }
  };

  const handleFormioSubmissionError = (error, campaignId) => {
    if (error.response.status === 409) {
      app.addInfoMsg("Form already submitted");
      navigate(`/surveysubmitted/${campaignId}`);
    } else {
      const errorDesc = error?.response?.data?.description;
      if (errorDesc) {
        app.addError(`Could not submit form: ${errorDesc}`);
      } else {
        app.addError("Could not submit form!");
      }
    }
  };

  const handleFormioSubmission = async (submission) => {
    try {
      const submitUrl = `/api/s/formio/forms/${trackingId}/submission?campaignId=${campaignId}`;
      await axiosClient.post(submitUrl, submission);
      app.addInfoMsg("Form was successfully submitted!");
      navigate(`/surveysubmitted/${campaignId}`);
    } catch (error) {
      handleFormioSubmissionError(error, campaignId);
    }
  };

  useEffect(() => {
    if (submissionId) {
      loadFormSubission(submissionId);
    }
    // eslint-disable-next-line
  }, [submissionId]);

  useEffect(() => {
    if (trackingId) {
      loadForm(trackingId, campaignId);
    }
    // eslint-disable-next-line
  }, [trackingId]);

  return (
    <div className={classes.root}>
      {formData && formSubmissionToken && (
        <Form
          form={{ components: formData.components }}
          onSubmit={handleFormioSubmission}
          options={{
            evalContext: {
              token: formSubmissionToken,
              context: formContext,
            },
          }}
        />
      )}
    </div>
  );
}
