import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { loadTypeahead } from "../KyruusRequests";
import { useApp } from "util/AppContext";
import { RNWebView } from "util/RNWebView";
import useStyles from "../NewAppointment.styles";

const AutocompleteSearch = ({ setSearch }) => {
  const [inputTypeahead, setInputTypeahead] = useState("");
  const [typeahead, setTypeahead] = useState([]);
  const app = useApp();

  useEffect(() => {
    if (inputTypeahead) {
      loadTypeahead(inputTypeahead, setTypeahead, app);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTypeahead]);

  const { classes, cx } = useStyles();

  return (
    <Grid container className={classes.searchContainer}>
      <Autocomplete
        title="autoComplete"
        onChange={(event, value) => {
          if (!value) {
            setSearch("");
            setTypeahead([]);
          }
          if (value) {
            setSearch(value.name);
          }
        }}
        options={typeahead}
        groupBy={(option) => option?.title}
        getOptionLabel={(option) => option.name}
        renderOption={(option) => {
          const matches = match(option.name, inputTypeahead);
          const parts = parse(option.name, matches);

          return (
            <div>
              {parts.map((part) => (
                <span
                  key={part.text}
                  style={{
                    fontWeight: part.highlight ? 800 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search provider name or specialty"
            onChange={(e) => setInputTypeahead(e.target.value)}
            variant="outlined"
            className={cx({ [classes.inputRN]: RNWebView })}
          />
        )}
      />
    </Grid>
  );
};

export default AutocompleteSearch;
