import React, { useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import GenericTabs, { TabConfig } from "../Tabs";
import CommonTab from "../common/CommonTab";
import FadSection from "../common/FadSection";
import { LocationContextProvider } from "./LocationContext";
import LocationPageTab from "./TabPanes/GeneralInfo";
import HoursAndSchedule from "./TabPanes/HoursAndSchedule";
import BackButton from "views/console/components/BackButton";
import { useGetLocationById } from "hooks/dataCuration/useGetLocationById";
import { additionalLinksInputs, identifierInfoInputs, identifierInfoSelectOptions } from "../utils";
import { useStyles } from "./utils/styles";
import { LocationData } from "./utils/types";

const LocationPage: React.FC = () => {
  const { classes } = useStyles();
  const { locationId } = useParams<{ locationId: string }>();
  const { locationData, isLoading, refetch } = useGetLocationById(locationId);

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("selectedTab");

  const [currentPayload, setCurrentPayload] = useState<LocationData | null>(null);
  const [currentlyEditing, setCurrentlyEditing] = useState<string | null>(null);

  const pageParams = localStorage.getItem("locationsTable-pageParams");

  if (isLoading) {
    return (
      <CircularProgress
        data-testid="locationPage-loader"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );
  }

  if (!locationData) {
    return <Navigate to="/error/404" />;
  }

  const {
    additionalLinks,
    identifierInfo,
    generalInfo: {
      name: { nameFull, buildingPictureUrl },
    },
  } = locationData;

  const publishedPageUrl = `https://www.atlantichealth.org${locationData?.generalInfo.contactInfo.webUrl}`;

  const tabsConfig: TabConfig = {
    label: ["General Info", "Additional Links", "Hours & Schedule", "Identifier Info"],
    component: [
      LocationPageTab,
      <CommonTab
        key="additionalLinks"
        title="Additional Links"
        inputs={additionalLinksInputs}
        initialValues={additionalLinks}
        payloadName="additionalLinks"
      />,
      HoursAndSchedule,
      <CommonTab
        key="identifierInfo"
        title="Identifier Info"
        inputs={identifierInfoInputs}
        initialValues={identifierInfo}
        selectOptions={identifierInfoSelectOptions}
        className={classes.identifierInfoInput}
        payloadName="identifierInfo"
      />,
    ],
  };

  return (
    <LocationContextProvider
      value={{
        locationData,
        currentPayload,
        setCurrentPayload,
        currentlyEditing,
        setCurrentlyEditing,
        selectedTab,
        refetch,
      }}
    >
      <div style={{ display: "flex" }}>
        <BackButton
          url={`/console/data-curation/?selectedTab=1&${pageParams}`}
          onClick={() => localStorage.removeItem("locationsTable-pageParams")}
        />
        <FadSection
          title={
            <Box display="flex" alignItems="center" gap="8px">
              <span>Show on Location Finder</span>
              <Tooltip title="Use this to enable or disable this page on the website." arrow>
                <IconButton
                  size="small"
                  aria-label="Show on Location Finder info"
                  sx={{ transform: "translateY(-10px)" }}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          }
          aemUrl="https://www.atlantichealth.org/locations/morristown-medical-center"
          publishedPageUrl={publishedPageUrl}
          enable={locationData.locationFinder}
          dataType="location"
        />
      </div>
      <Box display="flex" alignItems="center" gap="16px" padding="16px 18px 0 18px">
        {buildingPictureUrl && (
          <img src={buildingPictureUrl} alt="" style={{ maxWidth: "200px" }} />
        )}
        <Typography variant="h4" color="primary" textAlign="left">
          {nameFull}
        </Typography>
      </Box>
      <GenericTabs config={tabsConfig} />
    </LocationContextProvider>
  );
};

export default LocationPage;
