/* eslint-disable react/jsx-boolean-value */
import React, { SetStateAction, useCallback, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Badge,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TextInput } from "./TextInput";
import { makeStyles, withStyles } from "tss-react/mui";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import avatarBoy from "static/svg/avatar_boy.svg";
import firstAidKit from "static/svg/first_aid_kit_2.svg";
import musicBox from "static/svg/music_box.svg";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: "0px",
    width: "1rem",
  },

  paper: {
    width: "80vw",
    height: "80vh",
    maxWidth: "1000px",
    maxHeight: "1000px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },

  paper2: {
    width: "80vw",
    maxWidth: "500px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },

  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10%",
  },

  messagesBody: {
    width: "100%",
    padding: 10,
    overflowY: "scroll",
    height: "100%",
  },

  modalTitle: {
    marginTop: "8px",
    fontSize: "22px",
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  modalContainer: {
    marginRight: "30%",
    marginLeft: "30%",
    margin: "120px",
    overflow: "auto",
    borderRadius: "10px",
    border: "none",
    [theme.breakpoints.down("md")]: {
      marginRight: "5%",
      marginLeft: "5%",
      margin: "10px",
      overflow: "auto",
    },
  },

  flexContainer: {
    display: "flex",
    alignSelf: "flex-start",
    paddingLeft: "10px",
    flexDirection: "column",
    width: "100%",
  },

  langPosition: {
    display: "flex",
  },

  menu: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },

  langLabel: {
    paddingTop: "8px",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    alignSelf: "flex-start",
    marginLeft: "0.8rem",
  },

  options: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  badgeContentSize: {
    width: "50px",
    height: "50px",
  },

  inputsContainer: {
    display: "flex",
    width: "100%",
  },

  textareaResponse: {
    color: "black",
  },
}));

const StyledBadge = withStyles(Badge, () => ({
  badge: {
    height: "13px",
    minWidth: "13px",
    padding: 0,
  },
}));

const PROMPT_TYPES = {
  DIAGNOSIS_EXPLAINER: 1,
  DRMARIO: 2,
  SINGTOME: 3,
};

export const AiDiagnoseModal = ({ onClose }) => {
  // eslint-disable-next-line
  const [answer, setAnswer] = useState<String>("");
  const [lang, setLang] = useState<SetStateAction<string>>("English");
  const { DIAGNOSIS_EXPLAINER, DRMARIO, SINGTOME } = PROMPT_TYPES;
  const [currentPrompt, setCurrentPrompt] = useState<{ id: number; text: string } | undefined>({
    id: 0,
    text: "",
  });
  const options = [
    {
      id: DIAGNOSIS_EXPLAINER,
      text: "Explain {procedure} in simple terms in the perspective of a physician talking to a patient",
      label: "Diagnosis Explainer",
      image: firstAidKit,
      testId: "diagnosisExplainer",
    },
    {
      id: DRMARIO,
      text: "Explain {procedure} as if talking to a child in the perspective of Mario from Mario Brothers",
      label: "Ask Dr Mario",
      image: avatarBoy,
      testId: "drMario",
    },
    {
      id: SINGTOME,
      text: "Explain {procedure} like its song lyrics",
      label: "Sing it to me",
      image: musicBox,
      testId: "singItToMe",
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  const setPrompt = (id: number) => {
    setCurrentPrompt(options.find((t) => t.id === id));
  };

  const askQuestion = useCallback(
    async (prompt: string) => {
      setAnswer(() => "");
      const replacedPrompt = currentPrompt?.text.replace("{procedure}", prompt);
      setIsLoading(true);
      const url = API_ENDPOINTS.askAIssistant_with_prompt;
      try {
        const { data } = await axiosClient.post(url, {
          ask: replacedPrompt,
          lang,
        });

        const assitanceAnswer = data?.data?.answers[0] || "";
        setAnswer(assitanceAnswer);
        setIsLoading(false);
        scrollToBottom();
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
      // eslint-disable-next-line
    },
    [currentPrompt, lang],
  );
  return (
    <Modal open={true}>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.flexContainer} data-testid="aissistant-title">
            <Typography className={classes.modalTitle}>Talk to our AIssistant</Typography>
            <IconButton
              aria-label="close"
              onClick={() => onClose()}
              className={classes.closeButton}
              size="large"
            >
              <Close />
            </IconButton>
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.menu}>
              {options.map(({ id, label, image, testId }) => (
                <div className={classes.options} key={id}>
                  <IconButton
                    aria-label={label}
                    onClick={() => {
                      setPrompt(id);
                    }}
                    data-testid={testId}
                    size="large"
                  >
                    <StyledBadge
                      badgeContent=""
                      color="secondary"
                      invisible={currentPrompt?.id !== id}
                      className={classes.badgeContentSize}
                    >
                      <img src={image} alt="" />
                    </StyledBadge>
                  </IconButton>
                  <span>{label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.inputsContainer}>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel id="language">Language</InputLabel>
              <Select
                variant="standard"
                labelId="language"
                id="languageSelect"
                data-testid="languageSelect"
                value={lang}
                onChange={(event: SelectChangeEvent<string | ((prevState: string) => string)>) => {
                  setLang(event?.target?.value as string);
                }}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
              </Select>
            </FormControl>
            <TextInput
              askQuestion={askQuestion}
              isLoading={isLoading}
              disableInput={currentPrompt?.id === 0}
            />
          </div>

          <Paper id="style-1" className={classes.messagesBody}>
            <TextField
              id="filled-multiline-static"
              data-testid="aiResponseTextArea"
              style={{
                width: "100%",
                height: "100%",
              }}
              InputProps={{
                classes: {
                  input: classes.textareaResponse,
                },
              }}
              multiline
              variant="outlined"
              minRows={14}
              defaultValue={answer}
              disabled
            />
          </Paper>
        </Paper>
      </div>
    </Modal>
  );
};
