import React, { useEffect } from "react";
import { Conversation, Message } from "@twilio/conversations";
import PatientChatHeader from "../../../../views/PatientConversations/PatientChatHeader";
import Loader from "../../../Loader";
import ChatInput from "./ChatInput/ChatInput";
import ChatWindowHeader from "./ChatWindowHeader/ChatWindowHeader";
import MessageList from "./MessageList/MessageList";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import useJoinedParticipantNotify from "../../hooks/useJoinedParticipantNotify/useJoinedParticipant";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { CONNECT_VIRTUAL_SESSION } from "../../hooks/useVirtualVisitReducer/Actions";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "../../../../util/AppContext";
import { isRNApp } from "util/RNWebView";
import { useChatWindowStyles } from "./styles";

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

interface Props {
  messages?: Message[];
  vsId?: number;
  isReadOnly?: boolean;
  handleGetMessages?: () => void;
}

export default function ChatWindow({
  messages = [],
  vsId,
  isReadOnly = false,
  handleGetMessages,
}: Props) {
  const { classes, cx } = useChatWindowStyles();
  const { isChatWindowOpen, conversation, loading } = useChatContext();
  const { room } = useVideoContext();
  useJoinedParticipantNotify();
  const { isPatient } = useUserType();
  const {
    vs_visits_state: { onlyConversation },
    dispatch,
    setVideoFinished,
  } = useApp();

  const onBlurRoomDisconnect = () => {
    if (room) {
      room?.disconnect();
    }

    setVideoFinished(true);
    dispatch({
      type: CONNECT_VIRTUAL_SESSION,
      payload: null,
    });
  };

  useEffect(() => {
    return () => {
      onBlurRoomDisconnect();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <aside
      className={cx(
        classes.chatWindowContainer,
        !isChatWindowOpen && classes.hide,
        room && classes.chatWindowContainerRoom,
        isRNApp && classes.chatWindowContainerNativeApp,
      )}
      data-testid="chatWindow"
    >
      {isPatient && onlyConversation && <PatientChatHeader disabled={isReadOnly} />}
      {!isRNApp && !(isPatient && onlyConversation) && <ChatWindowHeader />}
      {loading ? (
        <div className={classes.loaderDiv}>
          <Loader text="" />
        </div>
      ) : (
        <MessageList messages={messages} />
      )}
      {!isReadOnly && (
        <ChatInput
          conversation={conversation as Conversation}
          isChatWindowOpen={isChatWindowOpen}
          vsId={vsId}
          handleGetMessages={handleGetMessages}
        />
      )}
    </aside>
  );
}
