import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import { useAuthAppstoreLogin } from "./useAuthAppstoreLogin";
import { useApp } from "util/AppContext";
import { Props } from "./types";
import useStylesModal from "./style";
import useStyles from "styles/Authorization/authorization.styles";

export const AppStoreLogin = ({ showAppstoreLogin, setShowAppstoreLogin }: Props) => {
  const { classes } = useStyles();
  const { classes: classesModal } = useStylesModal();
  const [email, setEmail] = useState("appstore@atlantichealth.org");
  const [password, setPassword] = useState("");
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
  const auth = useAuthAppstoreLogin();
  const app = useApp();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setIsLoginButtonDisabled(true);

    await auth.directLogin(email, password, (user, error) => {
      if (!user || error) {
        app.addError(
          error.response?.data?.description || "Error: Signin attempt failed, please try again",
        );
        setIsLoginButtonDisabled(false);
        return;
      }

      window.location.replace("https://app-test.atlantichealth.org/");
    });
  };

  const handleClose = () => {
    setShowAppstoreLogin(false);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (submitButtonRef?.current) {
          submitButtonRef.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Modal
      open={showAppstoreLogin}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classesModal.modal}>
        <form noValidate autoComplete="off" onSubmit={handleLoginSubmit}>
          <Grid container alignItems="center" justifyContent="center">
            <TextField
              id="email"
              data-cy="mychartEmail"
              label="MyChart Username"
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
              variant="outlined"
              className={classes.textField}
              value={email}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              disabled
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              data-cy="mychartPassword"
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
              className={classes.textField}
              value={password}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-cy="loginBtn"
              ref={submitButtonRef}
              className={classes.loginButton}
              disabled={isLoginButtonDisabled}
              fullWidth
            >
              Log In
            </Button>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
