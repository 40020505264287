import React from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { LocalAudioTrack } from "twilio-video";
import { SELECTED_AUDIO_INPUT_KEY } from "util/constants";
import useDevices from "../../../hooks/useDevices/useDevices";
import useMediaStreamTrack from "../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useCustomStyles } from "./useCustomStyles";

export default function AudioInputListPreview() {
  const { classes } = useCustomStyles();
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div className={classes.containerDiv}>
      <div className={classes.labelTitle}>Audio Input</div>
      <div>
        {audioInputDevices.length > 0 ? (
          <FormControl variant="standard" fullWidth>
            <Select
              fullWidth
              onChange={(e) => replaceTrack(e.target.value as string)}
              value={localAudioInputDeviceId || ""}
              variant="outlined"
            >
              {audioInputDevices.map((device) => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography>{localAudioTrack?.mediaStreamTrack.label || "No Local Audio"}</Typography>
        )}
      </div>
    </div>
  );
}
