import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { signinSchema } from "components/Consultation/SigninView/SigninSchema";
import { QuerySteps } from "components/Consultation/Steps";
import { handleFetchProgram } from "components/Consultation/utils/handleFetchProgram";
import { ProgramData } from "components/Consultation/utils/types";
import CellPhone from "components/shared/CellPhone";
import InputErrorMsg from "components/shared/InputErrorMsg/InputErrorMsg";
import ZipCode from "components/shared/ZipCode/ZipCode";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import { formatPhoneWithHyphens } from "util/util_functions";
import { useStyles } from "./SigninView.style";

const today = new Date();

interface SigninViewProps {
  setStep: (queryStep: QuerySteps) => void;
}

const SigninView = ({ setStep }: SigninViewProps) => {
  const { classes } = useStyles();
  const app = useApp();
  const { programName } = useParams<{ programName: string }>();
  const encondedProgramName = useMemo(() => encodeURIComponent(programName || ""), [programName]);
  const [programData, setProgramsData] = useState<ProgramData | null>(null);
  const [programNumber, setProgramNumber] = useState(programData?.phone_number);
  const navigate = useNavigate();

  const [disabledNextBtn, setDisabledNextBtn] = useState(false);

  const auth = useAuth();

  const formattedProgramNumber = formatPhoneWithHyphens(programNumber);

  const {
    web: {
      signinView: {
        existingUserMsg,
        emailSentMsg,
        scheduleCall,
        description,
        firstNameLabel,
        lastNameLabel,
        emailLabel,
        birthdateLabel,
        nextButton,
        existingPatient,
        singInLink,
        phoneRequestAt,
        bannedEmailMsg,
      },
      common: { errorMsg },
    },
  } = useTranslation();

  const handleNext = async ({ firstName, lastName, email, cellPhone, dob, zip }) => {
    try {
      const resp = await axiosClient.post("/api/signup", {
        first_name: firstName,
        last_name: lastName,
        email,
        cell_phone: cellPhone,
        dob,
        zip,
        program: decodeURIComponent(programName || ""),
      });
      if (resp?.status === 202 && resp.data?.via === "sms") {
        app.addInfoMsg(existingUserMsg);
        navigate(`/activation?cell_phone=${cellPhone}&r=/inquiryrequest/${encondedProgramName}`);
      }

      if (resp?.status === 202 && resp.data?.via === "email") {
        app.addInfoMsg(emailSentMsg);
      }

      if (resp.data.userId) {
        auth.setUserDirectly(resp.data);
        setStep(QuerySteps.SCREENING);
      }
    } catch (e) {
      app.addError(errorMsg);
    }
  };

  const { values, handleChange, handleSubmit, setFieldValue, errors } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      cellPhone: "",
      email: "",
      dob: null,
      zip: "",
    },
    validationSchema: signinSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      handleNext(values);
    },
  });

  const valuesChange = (e) => {
    handleChange(e);
    const email = e.target?.value;
    if (email.indexOf("@") !== -1) {
      const emailServer = email.split("@")[1].toLowerCase();
      setDisabledNextBtn(emailServer.indexOf("atlantichealth.org") !== -1);
    }
  };

  useEffect(() => {
    if (programName) {
      handleFetchProgram({
        programName: encodeURIComponent(programName),
        handleSuccess: (data) => setProgramsData(data),
        app,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programName]);

  useEffect(() => {
    setProgramNumber(programData?.phone_number);
  }, [programData, programName]);

  return (
    <>
      <Typography variant="h6" className={classes.heading}>
        {scheduleCall}
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>
        {description}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label={firstNameLabel}
              name="firstName"
              variant="outlined"
              value={values.firstName}
              fullWidth
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
              onChange={handleChange}
              error={Boolean(errors?.firstName)}
              inputProps={{ "data-testid": "firstNameInput" }}
            />
            <InputErrorMsg error={errors?.firstName} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label={lastNameLabel}
              name="lastName"
              variant="outlined"
              value={values.lastName}
              fullWidth
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
              onChange={handleChange}
              error={Boolean(errors?.lastName)}
              inputProps={{ "data-testid": "lastNameInput" }}
            />
            <InputErrorMsg error={errors?.lastName} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CellPhone
              cellPhone={values.cellPhone}
              setCellPhone={(cellPhone) => setFieldValue("cellPhone", cellPhone)}
              error={Boolean(errors?.cellPhone)}
              isShrink
              showMask={false}
              showRequiredInLabel
              data-testid="cellPhoneInput"
            />
            <InputErrorMsg error={errors?.cellPhone} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label={emailLabel}
              name="email"
              variant="outlined"
              value={values.email}
              fullWidth
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
              onChange={(e) => valuesChange(e)}
              error={Boolean(errors?.email)}
              inputProps={{ "data-testid": "emailInput" }}
            />
            <InputErrorMsg error={disabledNextBtn ? bannedEmailMsg : errors?.email} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <DatePicker
              data-testid="calendar"
              name="dob"
              format="MM/dd/yyyy"
              label={birthdateLabel}
              value={values.dob}
              onChange={(date) => setFieldValue("dob", date)}
              disableFuture
              slotProps={{
                textField: {
                  helperText: "MM/DD/YYYY",
                  error: Boolean(errors.dob),
                },
              }}
              maxDate={today}
            />
            <InputErrorMsg error={errors?.dob} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ZipCode
              setZip={(zip) => setFieldValue("zip", zip)}
              zip={values.zip}
              isError={Boolean(errors.zip)}
              data-testid="zipInput"
            />
            <InputErrorMsg error={errors?.zip} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              disabled={disabledNextBtn}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.btnContainer}
              data-testid="button"
            >
              {nextButton}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={classes.text}>
              {existingPatient}
              <Link
                href={`/login?smsLogin=true&r=/inquiryrequest/${encondedProgramName}/screening`}
                className={classes.link}
                underline="hover"
              >
                {singInLink}
              </Link>{" "}
            </div>
            <div className={classes.text}>
              <span>{phoneRequestAt}</span>{" "}
              <Link href={`tel:${programNumber}`} className={classes.link} underline="hover">
                {formattedProgramNumber}
              </Link>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SigninView;
