import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { StepTitleToolbar } from "./Common";
import { parse } from "date-fns";
import { DOT_PATIENT_STEPS, StepsProps } from "../types/constants";
import CellPhone from "components/shared/CellPhone";
import ZipCode from "components/shared/ZipCode/ZipCode";
import useTranslation from "hooks/useTranslation";
import { formatDate } from "../utils";
import { useIsMobile } from "util/deviceUtils";
import useStyles from "../styles/forms.styles";

const PersonalInformationStep: React.FC<StepsProps> = ({
  handleChange,
  formData,
  isFormDisabled = false,
  showTitle = false,
  handleStepChange,
}) => {
  const { classes } = useStyles({
    alignLeft: showTitle,
  });
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(isFormDisabled);
  const [isSaveMode, setIsSaveMode] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const title = DOT_PATIENT_STEPS[0];
  const { personalInformation: data } = formData;
  const {
    web: {
      dotForm: { patient },
    },
  } = useTranslation();

  const {
    personalInformation: {
      lastName,
      firstName,
      middleInitial,
      dateOfBirth,
      streetAddress,
      city,
      StateProvince,
      zip,
      driversLicense,
      issuingState,
      email,
      clpcdl,
      deniedMedicalCertificate,
    },
  } = patient;

  const showStepTitle = () => {
    if (showTitle || isMobile) {
      return (
        <Typography align="center" className={classes.formTitle}>
          {title}
        </Typography>
      );
    }
  };

  const actionClickHandler = (isSaveMode: boolean) => {
    if (!isSaveMode) {
      setShouldDisableForm(false);
      setIsSaveMode(true);
    } else {
      setShouldDisableForm(true);
      setIsSaveMode(false);
      if (handleStepChange) {
        handleStepChange({ saveProgress: true });
      }
    }
  };

  const handleEmail = (e) => {
    handleChange("personalInformation.email", e.target.value);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      className={classes.personalInformation_wrapper}
      data-testid="personalInformationStep"
    >
      <Grid container xs={12} md={12} style={{ padding: "0 8px" }}>
        <Grid item xs={12} md={6}>
          {showStepTitle()}
        </Grid>
        {(shouldDisableForm || isSaveMode) && (
          <StepTitleToolbar
            classes={classes}
            actionClickHandler={actionClickHandler}
            isSaveMode={isSaveMode}
          />
        )}
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="lastName"
            name="lastName"
            size="medium"
            value={data?.lastName}
            variant="outlined"
            onChange={(e) => handleChange("personalInformation.lastName", e)}
            disabled
            label={lastName}
            helperText={false}
            data-cy="PersonalInformation-LastNameInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="firstName"
            name="firstName"
            size="medium"
            value={data?.firstName}
            variant="outlined"
            label={firstName}
            onChange={(e) => handleChange("personalInformation.firstName", e)}
            disabled
            helperText={false}
            data-cy="PersonalInformation-FirstNameInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="middleName"
            name="middleName"
            size="medium"
            value={data?.driverLicense?.middleName}
            variant="outlined"
            label={middleInitial}
            onChange={(e) => handleChange("personalInformation.driverLicense.middleName", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-MiddleNameInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <DatePicker
          format="MM/dd/yyyy"
          label={dateOfBirth}
          value={parse(data?.dob, "yyyy-MM-dd", new Date())}
          disabled
          data-cy="PersonalInformation-DobInput"
          onChange={(date: Date | null) => {
            if (!date) {
              return;
            }
            handleChange("personalInformation.dob", formatDate(date));
          }}
          slotProps={{
            textField: {
              id: "dob",
              variant: "outlined",
            },
            openPickerButton: {
              "aria-label": "change date",
            },
          }}
        />
      </Grid>
      <Box width="100%" />
      {/* Section 2 start */}
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="address"
            name="address"
            size="medium"
            value={data?.driverLicense?.address}
            variant="outlined"
            label={streetAddress}
            onChange={(e) => handleChange("personalInformation.driverLicense.address", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-StreetInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="city"
            name="city"
            size="medium"
            value={data?.driverLicense?.city}
            variant="outlined"
            label={city}
            onChange={(e) => handleChange("personalInformation.driverLicense.city", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-CityInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="state"
            name="state"
            size="medium"
            value={data?.driverLicense?.state}
            variant="outlined"
            label={StateProvince}
            onChange={(e) => handleChange("personalInformation.driverLicense.state", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-StateInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <ZipCode
            setZip={(zip) => {
              handleChange("personalInformation.driverLicense.zipCode", zip);
            }}
            zip={data?.driverLicense?.zipCode}
            label={zip}
            isError={false}
            data-testid="zipInput"
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-ZipInput"
          />
        </FormControl>
      </Grid>
      {/* Section 3 start */}
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="driversLicense"
            name="driversLicense"
            size="medium"
            value={data?.driverLicense?.driverLicenseNumber}
            variant="outlined"
            label={driversLicense}
            onChange={(e) =>
              handleChange("personalInformation.driverLicense.driverLicenseNumber", e)
            }
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-DriversLicenseInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="issuingState"
            name="issuingState"
            size="medium"
            value={data?.driverLicense?.issuingState}
            variant="outlined"
            label={issuingState}
            onChange={(e) => handleChange("personalInformation.driverLicense.issuingState", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-IssuingStateInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <CellPhone
            error={false}
            cellPhone={data?.cellphone}
            setCellPhone={(value) => handleChange("personalInformation.cellphone", value)}
            disabled
            data-cy="PersonalInformation-CellPhoneInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            type="email"
            id="email"
            name="email"
            size="medium"
            value={data?.email}
            variant="outlined"
            label={email}
            onChange={(e) => handleEmail(e)}
            helperText={false}
            data-cy="PersonalInformation-EmailInput"
            data-testid="PersonalInformation-EmailInput"
            disabled={shouldDisableForm}
          />
        </FormControl>
      </Grid>
      <Grid
        container
        xs={12}
        style={{ marginTop: isMobile ? "0" : "24px", paddingLeft: isMobile ? "0" : "10px" }}
      >
        <Grid item xs={12} sm={3}>
          <FormControl
            variant="outlined"
            fullWidth
            className={
              isMobile ? classes.radioButtons_container : classes.radioButtons_containerLeftNoGap
            }
          >
            <Typography align="left" className={classes.titleLowerFontSize}>
              {clpcdl}
            </Typography>
            <RadioGroup
              row
              aria-label="clpCdlHolder"
              name="clpCdlHolder"
              value={!!data?.clpCdlHolder || false}
              onChange={(e) =>
                handleChange("personalInformation.clpCdlHolder", e.target.value === "true")
              }
            >
              <FormControlLabel
                value
                control={<Radio />}
                label="Yes"
                disabled={shouldDisableForm}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
                disabled={shouldDisableForm}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl
            variant="outlined"
            fullWidth
            className={
              isMobile ? classes.radioButtons_container : classes.radioButtons_containerLeftNoGap
            }
          >
            <Typography align="left" className={classes.titleLowerFontSize}>
              {deniedMedicalCertificate}
            </Typography>
            <RadioGroup
              row
              aria-label="denialMedicalCertificate"
              name="denialMedicalCertificate"
              value={data?.denialMedicalCertificate || "NOT_SURE"}
              onChange={(e) =>
                handleChange("personalInformation.denialMedicalCertificate", e.target.value)
              }
            >
              <FormControlLabel
                // eslint-disable-next-line react/jsx-boolean-value
                value="YES"
                control={<Radio />}
                label="Yes"
                disabled={shouldDisableForm}
              />
              <FormControlLabel
                value="NO"
                control={<Radio />}
                label="No"
                disabled={shouldDisableForm}
              />
              <FormControlLabel
                value="NOT_SURE"
                control={<Radio />}
                label="Not Sure"
                disabled={shouldDisableForm}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalInformationStep;
