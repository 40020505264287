/** @format */
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import TimeLineCard from "./TimeLineCard";
import { useTimeLineStyles } from "./styles";

const TimeLineItem = ({ vaccine, i, length }) => {
  const classes = useTimeLineStyles();
  let dose = "";
  switch (i) {
    case 1:
      dose = "2nd dose";
      break;

    case 2:
      dose = "3rd dose";
      break;
    case 3:
      dose = "4th dose";
      break;
    case 4:
      dose = "5th dose";
      break;
    case 5:
      dose = "6th dose";
      break;
    default:
      dose = "1st dose";
      break;
  }
  return (
    <TimelineItem>
      <TimelineOppositeContent className={classes.opposite} />
      <TimelineSeparator className={classes.separ}>
        <CheckCircleIcon className={classes.checkCircle} color="primary" fontSize="small" />
        {i !== length - 1 && <TimelineConnector className={classes.verticalLine} />}
      </TimelineSeparator>
      <TimelineContent>
        <TimeLineCard
          title={dose}
          lot={vaccine.lotNumber}
          date_administered={vaccine.occurrenceDateTime}
          vac_provider={vaccine.location.name}
        />
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimeLineItem;
