import React from "react";
import { Chip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  chip: {
    borderRadius: 10,
    width: 64,
    "& > *": {
      padding: 0,
    },
  },
}));

const TimeSlotPickerChip = ({
  timeLabel,
  onClick,
}: {
  timeLabel?: string;
  onClick: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Chip
      variant="outlined"
      label={timeLabel || "---"}
      onClick={onClick}
      className={classes.chip}
      disabled={!timeLabel}
    />
  );
};

export default TimeSlotPickerChip;
