import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { getLocalTime } from "util/util_functions";

const useStyles = makeStyles()({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
});

const VirtualSessionEvents = ({ profile }) => {
  const { classes } = useStyles();
  const { addError } = useApp();

  const [events, setEvents] = useState([]);

  const getVSEvents = async () => {
    const url = "/api/s/console/virtualvisit/vs_events";
    try {
      const { data } = await axiosClient.get(url, {
        params: { vv_id: profile.virtual_session_info?.id },
      });
      setEvents(data.data);
    } catch (e) {
      addError("Problem with loading user events. Please contact support.");
    }
  };

  useEffect(() => {
    if (profile.virtual_session_info?.id) {
      getVSEvents();
    }
    // eslint-disable-next-line
  }, [profile]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className="header-message">
            VS Events
          </Typography>
        </Grid>
      </Grid>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={12}>
          {!events.length && <Alert severity="info">No events found in the system.</Alert>}
          {events.length > 0 && (
            <Timeline>
              {events.map((event) => {
                return (
                  <TimelineItem key={event.name + event.type}>
                    <TimelineOppositeContent>
                      <Typography color="textSecondary">
                        {getLocalTime(event.event_dttm)}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>
                        {event.type} - {event.name}{" "}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default VirtualSessionEvents;
