import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()({
  signatureCanvas: {
    border: "1px solid #000",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  },
  closeIconButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
  },
  actionButtonsContainer: {
    marginTop: "1em",
    marginBotton: "1em",
    textAlign: "center",
  },
});
