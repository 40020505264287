import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Checkbox, FormControlLabel, InputLabel, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { QuerySteps } from "./Steps";
import { isToday } from "date-fns";
import {
  CLEAR_IMAGES_LIST,
  SET_IS_IMAGES_LIST_SAVED,
} from "../Video/hooks/usePatientStepsReducer/Actions";
import { handleConsent } from "./useVirtualSession/useConsent";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { getIsProgramName } from "util/programsHelpers";
import { useConsultationStyles } from "./styles";

interface SchedulingProps {
  setStep: (queryStep: QuerySteps) => void;
}

type Slot = { endDateTime: string; startDateTime: string };

type SlotDay = {
  date: string;
  slots: Array<Slot>;
};

const ScheduleView = ({ setStep }: SchedulingProps) => {
  const { classes, cx } = useConsultationStyles();
  const { vsId, programName } = useParams<{ vsId: string; programName: string }>();
  const app = useApp();

  const [consent, setConsent] = useState<boolean>(false);
  const [slotsPageNumber, setSlotsPageNumber] = useState(1);
  const [slotsPerDay, setSlotsPerDay] = useState<SlotDay[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<Slot>();

  const {
    web: {
      Schedule: { submitText },
    },
  } = useTranslation();

  const { isPhysicalTherapy } = getIsProgramName(programName);
  const isDayToday = useMemo(() => isToday(new Date(slotsPerDay[0]?.date)), [slotsPerDay]);
  const currentDate = `${moment(slotsPerDay[0]?.date).format("MMM D")} - ${moment(
    slotsPerDay[1]?.date,
  ).format("MMM D")}`;
  const selectedDate = `${moment(selectedSlot?.startDateTime).format("MMM DD")} between
    ${moment(selectedSlot?.startDateTime).format("h:mma")} and ${moment(
    selectedSlot?.endDateTime,
  ).format("h:mma")}.
  `;

  const getNextTwoDays = () => {
    setSlotsPageNumber((slotsPageNumber) => slotsPageNumber + 1);
  };

  const getPrevTwoDays = () => {
    setSlotsPageNumber((slotsPageNumber) => slotsPageNumber - 1);
  };

  const getSlotsPerDay = async () => {
    try {
      const url = `api/s/virtualvisit/sessions/${vsId}/time-slots`;

      const { data } = await axiosClient.get(url, {
        params: {
          page_size: 2,
          page_num: slotsPageNumber,
        },
      });
      setSlotsPerDay(data);
    } catch (error) {
      app.addError("Something went wrong. Please, contact support.");
    }
  };

  useEffect(() => {
    getSlotsPerDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsPageNumber, vsId]);

  const getSelectedSlot = async () => {
    try {
      const { data } = await axiosClient.get(
        `/api/s/virtualvisit/sessions/${vsId}/time-slots/selected`,
      );
      setSelectedSlot(data);
    } catch (e) {
      app.addError("Something wend wrong. Please, contact support.");
    }
  };

  useEffect(() => {
    getSelectedSlot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  const handleSelectSlot = async (slot: Slot) => {
    try {
      const { data } = await axiosClient.put(
        `/api/s/virtualvisit/sessions/${vsId}/time-slots/selected`,
        slot,
      );
      setSelectedSlot(data);
    } catch (e) {
      app.addError("Something wend wrong. Please contact support.");
    }
  };

  const handleBackStep = () => {
    setStep(isPhysicalTherapy ? QuerySteps.LOCATION : QuerySteps.UPLOAD);
  };

  const handleNext = async () => {
    try {
      const url = "/api/s/virtualvisit/consumersubmit";
      await axiosClient.post(url, { virtual_session_id: vsId });
      if (consent) {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const consentChecked: any = {
          is_consent: consent,
          virtual_session_id: vsId,
        };
        await handleConsent({ consentChecked });
      } else {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const consentChecked: any = {
          is_consent: consent,
          virtual_session_id: vsId,
        };
        await handleConsent({ consentChecked, app });
      }
      setStep(QuerySteps.THANK_YOU);
      app.patient_steps_dispatch({ type: CLEAR_IMAGES_LIST, payload: null });
      app.patient_steps_dispatch({ type: SET_IS_IMAGES_LIST_SAVED, payload: false });
    } catch (err) {
      app.addError("Problem saving requested appointment range.");
      console.error(err);
    }
  };

  return (
    <>
      <h1 className={classes.heading}>Schedule Your Call</h1>
      <div className={classes.subTitle}>
        Please select a date and window of time that is good for us to call you back.
      </div>
      <Paper elevation={4} className={classes.paper}>
        <div className={classes.scheduleHeader}>
          <IconButton
            color="primary"
            className={classes.nextDays}
            onClick={getPrevTwoDays}
            disabled={isDayToday}
            size="large"
          >
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
          <div className={classes.calDatesHeader}>{currentDate}</div>
          <IconButton
            color="primary"
            className={classes.nextDays}
            onClick={getNextTwoDays}
            data-cy="nextBtn"
            size="large"
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </div>
        {selectedSlot && (
          <div className={classes.timeSlotActive}>
            <div>
              <span>You have selected </span>
              <span className={classes.boldText}>{selectedDate}</span>
            </div>
            <div>We will give you a call within this timeframe.</div>
          </div>
        )}
        <div className={classes.slots} data-cy="slotsWidget">
          {slotsPerDay.map(({ date, slots }) => (
            <div className={classes.slotCard} key={date} role="row">
              <div className={classes.calDates} role="columnheader">
                {moment(date).format("ddd MMM D")}
              </div>
              {slots.map((slot) => {
                const slotDuration = `${moment(slot.startDateTime).format("h:mma")} - ${moment(
                  slot.endDateTime,
                ).format("h:mma")}`;
                const isSelected = slot.startDateTime === selectedSlot?.startDateTime;

                return (
                  <button
                    className={cx({
                      [classes.timeSlot]: true,
                      [classes.timeSlotSelected]: isSelected,
                    })}
                    key={slot.startDateTime}
                    onClick={() => handleSelectSlot(slot)}
                    data-cy="dateSlots"
                    type="button"
                    role="gridcell"
                    tabIndex={0}
                  >
                    {slotDuration}
                  </button>
                );
              })}
              {!slots.length && <p className={classes.noSlot}>No Slot Available</p>}
            </div>
          ))}
        </div>
      </Paper>
      <div className={classes.uploadLabel}>
        <FormControlLabel
          control={
            <Checkbox
              name="checkedB"
              value={consent}
              checked={consent}
              onChange={() => {
                setConsent(!consent);
              }}
              color="primary"
              data-cy="consentCheckbox"
            />
          }
          label={
            <InputLabel className={classes.consentLabel}>
              By checking here, I consent to electronic communication and confirmation of this
              appointment.*
            </InputLabel>
          }
        />
        <div className={classes.smallText}>
          *I understand and accept the risk that electronic mail/e-mail is not a secure or
          confidential form of communication because it is sent across the internet, where it could
          be intercepted and read by unauthorized parties. Atlantic Health System cannot be
          responsible for unauthorized access to email messages or guarantee the security of email
          messages or their attachments.
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          onClick={handleBackStep}
          variant="contained"
          data-testid="Back"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!consent || !selectedSlot}
          className={classes.button}
          onClick={handleNext}
          data-testid="Submit"
          data-cy="scheduleSubmit"
        >
          {submitText}
        </Button>
      </div>
    </>
  );
};

export default ScheduleView;
