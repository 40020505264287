import React, { FC } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Button, Card, Grid, SvgIcon, Typography } from "@mui/material";
import useStyles from "./AppoitmentDetails.styles";

interface Props {
  name: string;
  date: string;
  time: string;
  department: string;
  phone: string;
}

const UpcomingVisit: FC<Props> = ({ name, date, time, department, phone }) => {
  const { classes } = useStyles();

  return (
    <Card elevation={24} className={classes.card}>
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Typography align="left" className={classes.textUpcoming}>
            Upcoming Appointment
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="left" className={classes.title}>
            {`Clinical Visit with ${name}`}
          </Typography>
        </Grid>
        <Grid container>
          <div className={classes.iconArea}>
            <SvgIcon component={CalendarTodayIcon} />
          </div>
          <Typography align="left" className={classes.text}>
            {date}
          </Typography>
        </Grid>
        <Grid container>
          <div className={classes.iconArea}>
            <SvgIcon component={AccessTimeIcon} />
          </div>
          <Typography align="left" className={classes.text}>
            {time}
          </Typography>
        </Grid>
        <Grid container>
          <div className={classes.iconArea}>
            <SvgIcon component={LocationOnIcon} />
          </div>
          <Typography align="left" className={classes.text}>
            {department}
          </Typography>
        </Grid>
        <Grid container>
          <div className={classes.iconArea}>
            <SvgIcon component={PhoneIcon} />
          </div>
          <Typography align="left" className={classes.text}>
            {phone}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" className={classes.button}>
            Reschedule
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default UpcomingVisit;
