/** @format */

/* eslint-disable */
import Theme from "Theme";
import { cloneDeep, isEqual } from "lodash";
import _ from "lodash";
import moment from "moment";
import { saveAs } from "file-saver";
import jwt_decode from "jwt-decode";
import { VirtualSessionQueue } from "./constants";
import { getStrTimeFromDate } from "util/dateHelpers";

export const isTokenExpired = (token) => {
  const decoded = jwt_decode(token);
  const isExpired = new Date(decoded.exp * 1000) < new Date();
  return isExpired;
};

export const diffMinutes = (dt2, dt1) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export const getAgeByDOB = (dob) => {
  if (!dob) {
    return "Unknown Age";
  }

  return `${moment(dob, "MM/DD/YYYY").fromNow(true)} old`;
};

export const getTimeAgo = (dateTime) => {
  return moment.utc(dateTime).local().fromNow();
};

export const getUtcTimeAgo = (dateTime) => {
  return moment.utc(dateTime).fromNow();
};

export const transformCase = (string) =>
  string
    .split("_")
    .map((s) => s[0].toUpperCase() + s.substring(1, s.length))
    .join(" ");

export const getAge = (birthDate) =>
  !birthDate ? null : Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

export const getMessageFromTime = (ttime, queue) => {
  let time = parseInt(ttime);
  // TODO for now force time to be short always until we have accurate estimate
  time = 10;
  const message = {
    greetings: "Welcome to the virtual waiting room. Our Staff will be with you shortly",
    availability: "Busy",
    color: "blue",
    waitMessage: "You will be contacted shortly",
  };

  if (queue === VirtualSessionQueue.REGISTERING) {
    message.greetings =
      "Welcome to the virtual waiting room. Our Registering Staff will be with you shortly";
  } else if (queue === VirtualSessionQueue.NURSE_TRIAGE) {
    message.greetings =
      "Welcome to the virtual waiting room. Our Nursing Staff will be with you shortly";
  } else if (queue === VirtualSessionQueue.PHYSICIAN_VISIT) {
    message.greetings =
      "Welcome to the virtual waiting room. Our Physician will be with you shortly";
  } else if (queue === VirtualSessionQueue.CHECKOUT) {
    message.greetings =
      "Welcome to the virtual waiting room. Our Checkout Staff will be with you shortly";
  }
  if (time) {
    if (time <= 10) {
      message.availability = "Available";
      message.color = "green";
      message.waitMessage = "wait times could be between 5-10 minutes";
    } else if (time <= 25) {
      message.availability = "Moderately Busy";
      message.color = "#FFC700";
      message.waitMessage = "wait times could be between 10-25 minutes";
    } else if (time <= 45) {
      message.availability = "Busy";
      message.color = "red";
      message.waitMessage = "wait times could be between 25-45 minutes";
    } else {
      message.availability = "";
      message.color = "red";
      message.waitMessage = "";
      message.greetings = "Thank you for your patience, somebody will be with you shortly";
    }
  }
  return message;
};

export const isDigitsOnly = (value) => /^\d+$/.test(value) || value === "";
export const isAlphabetsOnly = (value) => /^[A-Za-z ]+$/.test(value) || value === "";

export const normalizePhoneNumber = (value) => {
  if (!value) {
    return value;
  }

  const currentValue = value.replace(/[^\d]/g, "");
  const currentLength = currentValue.length;

  if (currentLength === 0) {
    return currentValue;
  }

  if (currentLength < 4) {
    return currentValue;
  }

  if (currentLength < 7) {
    return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  }

  return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

export const normalizeZipCode = (value) => {
  if (!value) {
    return value;
  }

  const currentValue = value.replace(/[^\d]/g, "");
  const currentLength = currentValue.length;

  if (currentLength < 5) {
    return currentValue;
  }

  return currentValue.slice(0, 5);
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getEmptyFields = (fields) => {
  return Object.entries(fields)
    .filter(([key, value]) => !value)
    .reduce((acc, [key, value]) => {
      acc[key] = "This field is required";
      return acc;
    }, {});
};

export const getLocalTime = (datetime) => {
  const date = moment(datetime);
  if (date.isValid()) {
    const localFormattedDate = moment.utc(datetime).local().format("DD MMM YYYY, h:mm A");

    return localFormattedDate;
  }
  return datetime;
};

export const getTime = (datetime) => {
  const date = moment(datetime);
  if (date.isValid()) {
    const localFormattedDate = moment.utc(datetime).local().format("DD MMM YYYY");

    return localFormattedDate;
  }
  return datetime;
};

export const getDate = (dateTime) => {
  // This is a date, not datetime. Use UTC to ignore TZ offset
  const date = moment.utc(dateTime);

  if (date.isValid()) {
    return date.format("DD MMM YYYY");
  }
  return dateTime;
};

export const convertJsonDataToCSV = (fileName, arrayOfJson, colDefs) => {
  const replacer = (key, value) => (value === null ? "" : value);
  const header_keys = colDefs.map((item) => {
    return item.id;
  });
  const header_labels = colDefs.map((item) => {
    return item.label;
  });
  let csv = arrayOfJson.map((row) =>
    header_keys.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","),
  );
  csv.unshift(header_labels.join(","));
  csv = csv.join("\r\n");

  const file = new Blob([csv], {
    type: "text/csv",
  });
  saveAs(file, `${fileName}.csv`);
};

export const calculatePathBase = (pathname) => {
  let pathBase = pathname;
  if (pathBase.includes("videoinvite")) {
    pathBase = "/videoinvite";
  } else {
    pathBase = "/video";
  }
  return pathBase;
};

export const getConsultationCalendarLabel = (date1, date2) => {
  const startDate = moment(date1);
  const endDate = moment(date2);
  if (startDate.isValid() && endDate.isValid()) {
    const monthName = startDate.format("MMM");
    const startTime = moment.utc(date1).local().format("h:mmA");
    const endTime = moment.utc(date2).local().format("h:mmA");
    const day = startDate.date();
    return `${day} ${monthName}, ${startTime} - ${endTime}`;
  }
  return "";
};

export const getAddress = (...address) => address.filter(Boolean).join(", ");

export const isCorrectDateFormat = (dateString, format) =>
  moment(dateString, format, true).isValid();

export const isDateFormat = (dateString) =>
  isCorrectDateFormat(dateString, "MM/DD/YYYY") || isCorrectDateFormat(dateString, "YYYY-MM-DD");

export const isDateStringValid = (dateString) =>
  !Number(dateString) && moment(new Date(dateString)).isValid();

export const formatPhoneWithHyphens = (phone) =>
  phone?.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

export const getTimeByMs = (ms) => moment.utc(ms).format("HH:mm:ss");

export const exportCSV = (textFile, fileName) => {
  const file = new Blob([textFile], {
    type: "text/csv",
  });
  saveAs(file, `${fileName}.csv`);
};

export const capitalizeFirstChar = (s) => s && s[0].toUpperCase() + s.slice(1);

export const objectDeepDiff = (data, oldData) => {
  const record = {};
  Object.keys(data).forEach((key) => {
    // Checks that isn't an object and isn't equal
    if (typeof data[key] !== "object" && !isEqual(data[key], oldData[key])) {
      record[key] = data[key];
    }
    // If is an object, and the object isn't equal
    if (typeof data[key] === "object" && !isEqual(data[key], oldData[key])) {
      record[key] = objectDeepDiff(data[key], oldData[key]);
    }
  });
  return record;
};

export const transformTimeSlotsToString = (timeSlots) => {
  const timeSlotsCopy = cloneDeep(timeSlots);
  const workingHours = timeSlotsCopy.time_slots.working_hours;
  Object.keys(workingHours).forEach((key) => {
    workingHours[key].start_time = getStrTimeFromDate(workingHours[key].start_time);
    workingHours[key].end_time = getStrTimeFromDate(workingHours[key].end_time);
  });
  return timeSlotsCopy;
};

export const getDiffFromRecordConfig = (currentValues, configValues) => {
  const initialConfigCopy = cloneDeep(configValues);
  const timeSlotsCopy = transformTimeSlotsToString(currentValues);

  if (timeSlotsCopy.time_slots && timeSlotsCopy.time_slots.working_hours) {
    if (initialConfigCopy.virtual_sessions) {
      initialConfigCopy.virtual_sessions = timeSlotsCopy;
    }
  }

  return objectDeepDiff(initialConfigCopy, configValues);
};

const getPrettifiedTime = (time) => {
  // should receive time as hh:mm:ss
  const momentTime = moment(time, ["hh:mm:ss"]);
  return momentTime.hour() === 0 ? momentTime.format("HH A") : momentTime.format("hh A");
};

export const generateTimeWindowString = (start, end) => {
  if (start && !end) {
    return `From ${getPrettifiedTime(start)}`;
  }
  if (!start && end) {
    return `Until ${getPrettifiedTime(end)}`;
  }
  if (start && end) {
    return `${getPrettifiedTime(start)} - ${getPrettifiedTime(end)}`;
  }
  return "\u2013";
};

export const getFullNameFromParts = (firstName, lastName, fallbackName) => {
  if (firstName && lastName) {
    return `${_.capitalize(lastName)}, ${_.capitalize(firstName)}`;
  } else if (firstName || lastName) {
    return _.capitalize(firstName) || _.capitalize(lastName);
  } else {
    return fallbackName || "-";
  }
};

// Recaptcha mocks for testing
export const mockGrecaptcha = ({
  forceCallbackType = "success",
  reset = nop,
  execute = nop,
  token = "g-token",
} = {}) => {
  global.window.grecaptcha = {
    ready: (callback) => callback(),
    render: (_html, reaptchaConfig) => {
      const callback = reaptchaConfig[reaptchaCallbackName(forceCallbackType)];
      callback(token);
    },
    reset: reset,
    execute: execute,
  };
};

const nop = () => {};

const reaptchaCallbackName = (type) => {
  switch (type) {
    case "success":
      return "callback";
    case "error":
      return "error-callback";
    case "expire":
      return "expired-callback";
    default:
      throw `Unknown callback type ${type}. Supported types: success, error, expire`;
  }
};
