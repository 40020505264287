export enum Steps {
  ConsultationStep,
  SignInStep,
  ScreeningStep,
  UploadStep,
  LocationStep,
  SchedulingStep,
  AppointmentScheduledStep,
}

export enum QuerySteps {
  SCREENING = "screening",
  UPLOAD = "upload",
  SCHEDULE = "schedule",
  LOCATION = "location",
  THANK_YOU = "thank-you",
}

export const StepsMapping = {
  [QuerySteps.SCREENING]: Steps.ScreeningStep,
  [QuerySteps.UPLOAD]: Steps.UploadStep,
  [QuerySteps.SCHEDULE]: Steps.SchedulingStep,
  [QuerySteps.LOCATION]: Steps.LocationStep,
  [QuerySteps.THANK_YOU]: Steps.AppointmentScheduledStep,
};
