import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { SpecimenAddResponse } from "views/SpecimenTracking/types";

export const useGetSpecimenById = () => {
  const getSpecimenById = (externalId: string) => {
    const params = {
      search_by: "specimenExternalId",
      search_text: externalId,
    };
    const resp = axiosClient.get<SpecimenAddResponse>(API_ENDPOINTS.specimenTrackingGetSpecimens, {
      params,
    });
    return resp;
  };

  return {
    getSpecimenById,
  };
};
