import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import RichTextArea from "components/RichTextArea";
import useTranslation from "hooks/useTranslation";
import { ProviderGeneralInfoAndLocation, TabPaneProps } from "../../types";

const AdditionalInformationSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}: TabPaneProps) => {
  const {
    providerGeneralInfoAndLocation: { additionalInformation },
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const [formData, setFormData] = useState({
    ...additionalInformation,
    professionalInterests: additionalInformation.professionalInterests || [],
  });
  const [videoTitle, setVideoTitle] = useState<string>("");
  const [videoId, setVideoId] = useState<string>();
  const [loadingVideo, setLoadingVideo] = useState<boolean>(false);

  const {
    web: {
      dataCuration: { providerProfile },
    },
  } = useTranslation();

  const {
    generalInformation: {
      additionalInformation: { aboutLabel, areasOfFocus, videoUrl },
    },
  } = providerProfile;

  const handleChange = (key: string, e: any) => {
    const newValue = e?.target?.value || e;
    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== additionalInformation[key]) {
      setCurrentPayload((prev: ProviderGeneralInfoAndLocation) => ({
        ...(prev || {}),
        [key]: newValue,
      }));
    }
  };

  const youTubeUrlParser = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = regExp.exec(url);
    return match && match[7].length === 11 ? match[7] : false;
  };

  const handleUrlChange = async (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLoadingVideo(true);
    const id = youTubeUrlParser(e.target.value);
    if (id) {
      setVideoId(id);
      const data = await fetch(
        `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}&format=json`,
      );
      const json = await data.json();
      setVideoTitle(json.title);
    } else {
      setVideoId("");
      setVideoTitle("");
    }
    setLoadingVideo(false);
  };

  useEffect(() => {
    handleUrlChange({ target: { value: formData.videoUrl } } as React.FocusEvent<HTMLInputElement>);
  }, []);

  useEffect(() => {
    if (currentPayload === null) {
      setFormData({
        ...additionalInformation,
        professionalInterests: additionalInformation.professionalInterests || [],
      });
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      style={{ padding: "24px 0 32px 0", width: "100%" }}
      data-testid="additional-information-section"
    >
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <RichTextArea
            initialValue={formData?.about || ""}
            onChange={(content) => handleChange("about", content)}
            placeHolder={aboutLabel}
            readOnly={shouldDisableForm}
            data-cy="AdditionalInformation-aboutInput"
            data-testid="AdditionalInformation-aboutInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            value={formData?.professionalInterests || []}
            freeSolo
            autoSelect
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip label={option} key={key} {...tagProps} />;
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={areasOfFocus}
                placeholder="Add Professional Interests by pressing enter"
              />
            )}
            onChange={(_, newValue) => handleChange("professionalInterests", newValue)}
            disabled={shouldDisableForm}
            data-testid="AdditionalInformation-professionalInterestsInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="videoUrl"
            name="videoUrl"
            size="medium"
            value={
              formData?.videoUrl !== undefined
                ? formData.videoUrl
                : additionalInformation.videoUrl || ""
            }
            variant="outlined"
            label={videoUrl}
            onChange={(e) => handleChange("videoUrl", e.target.value)}
            onBlur={handleUrlChange}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="AdditionalInformation-videoUrlInput"
            data-testid="AdditionalInformation-videoUrlInput"
          />
          {videoId && (
            <Box display="flex" flexDirection="column">
              {loadingVideo ? (
                <CircularProgress style={{ margin: "4em" }} />
              ) : (
                <a
                  href={formData.videoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textAlign: "start" }}
                >
                  <img
                    src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                    style={{ maxWidth: "35%", marginTop: "16px" }}
                    alt="video thumbnail"
                    data-testid="AdditionalInformation-videoThumbnail"
                  />
                  <Typography style={{ textAlign: "start", marginTop: "10px" }}>
                    {videoTitle}
                  </Typography>
                </a>
              )}
            </Box>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AdditionalInformationSection;
