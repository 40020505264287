export const colDefs = [
  {
    id: "NPI",
    numeric: false,
    disablePadding: true,
    label: "NPI",
    searchable: true,
    sortable: true,
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "cellphone",
    numeric: false,
    disablePadding: true,
    label: "Cell Phone",
    searchable: true,
    sortable: false,
  },
  {
    id: "specialty",
    numeric: false,
    disablePadding: true,
    label: "Specialty",
    searchable: false,
    sortable: true,
  },
];

export interface Provider {
  NPI: string;
  firstName: string;
  lastName: string;
  cellphone: string;
  id: number;
  specialty: string;
  enabled: boolean;
}
