import { makeStyles } from "tss-react/mui";
import { SpecimenReportCardType } from "./types";

export const useSpecimenReportCardStyles = makeStyles<{ type: SpecimenReportCardType }>()(
  (_theme, { type }) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "200px",
      height: "170px",
      borderRadius: 4,
      boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
      backgroundColor: type === "primary" ? "#043070" : "#EB8025",
    },
    textStyle: {
      paddingTop: "0.4em",
      fontWeight: "bold",
      color: "white",
      lineHeight: 1.1,
    },
    headerStyle: {
      fontWeight: "bold",
      color: "white",
    },
    footerStyle: {
      fontWeight: "lighter",
      color: "white",
      lineHeight: 1.1,
    },
  }),
);
