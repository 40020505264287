import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import PhysicianNotes from "./PhysicianNotes";
import PatientChat from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/PatientChat";
import PatientProfileCard from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/PatientProfileCard";
import VirtualSessionEvents from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/VirtualSessionEvents";
import VirtualSessionUsers from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/VirtualSessionUsers";
import VisitDetails from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/VisitDetails";
import ReplaceThisPatientSearch from "views/console/components/PatientSearch";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useVisitDetails } from "./styles";

const VirtualVisitDetails = ({
  profile,
  currentVirtualVisit,
  showEvents,
  showUserDetails,
  showChat,
  isReadOnly = false,
  getPatientProfile,
  onConfirmMatchSuccess,
  handleClose = () => {},
}) => {
  const { classes } = useVisitDetails();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState(null);
  const [zip, setZip] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState(false);
  const [MRN, setMRN] = useState("");
  const app = useApp();

  useEffect(() => {
    setFirstName(profile?.first_name || "");
    setLastName(profile?.last_name || "");
    setDob(profile?.dob ? new Date(profile.dob) : null);

    setZip(profile?.zip || "");

    setCellPhone(profile?.cell_phone || "");

    setGender(profile?.gender || "");

    setMRN(profile?.emr_mrn || "");
  }, [profile]);

  const onConfirmMatch = async (patient) => {
    if (!patient) {
      return;
    }

    const vsId = currentVirtualVisit?.id;
    try {
      setLoading(true);
      await axiosClient.post(API_ENDPOINTS.confirm_match_patient, {
        userID: currentVirtualVisit?.patient_info?.id,
        MRN: patient.MRN,
        patientID: patient.patientId,
      });
      setLoading(false);
      if (onConfirmMatchSuccess) {
        onConfirmMatchSuccess(vsId);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status === 409) {
        app.addError(
          "Failed to update patient user with MRN, another active user already is linked to this MRN, can search and compare with Patient Report.",
        );
      } else {
        app.addError("Failed to update patient user with MRN, please try again or contact support");
      }
      app.addError("Problem on confirm match request.");
    }
  };

  return (
    <div>
      <PhysicianNotes currentVirtualVisit={currentVirtualVisit} />
      {profile && profile.emr_mrn && <PatientProfileCard profile={profile} />}
      {profile && !profile.emr_mrn && (
        <ReplaceThisPatientSearch
          style={{ marginBottom: "10px" }}
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          zip={zip}
          cellPhone={cellPhone}
          gender={gender}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setDob={setDob}
          setZip={setZip}
          MRN={MRN}
          setCellPhone={setCellPhone}
          setGender={setGender}
          setMRN={setMRN}
          vvId={profile.virtual_session_info?.id}
          redirectAfterCreated={false}
          getPatientProfile={getPatientProfile}
          currentVirtualVisit={currentVirtualVisit}
          onConfirmMatch={onConfirmMatch}
          setLoading={loading}
          isFormDisabled={isReadOnly}
        />
      )}
      <div className={classes.wrapper}>
        <VisitDetails
          currentVirtualVisit={currentVirtualVisit}
          profile={profile}
          handleClose={handleClose}
        />
      </div>
      <Grid container>
        {showChat && (
          <Grid item xs={12} className={classes.wrapper}>
            <PatientChat vsId={profile.virtual_session_info?.id} />
          </Grid>
        )}
        {showUserDetails && (
          <Grid item xs={12} className={classes.wrapper}>
            <VirtualSessionUsers profile={profile} />
          </Grid>
        )}
        {showEvents && (
          <Grid item xs={12} className={classes.wrapper}>
            <VirtualSessionEvents profile={profile} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default VirtualVisitDetails;
