import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { exportCSV } from "util/util_functions";
import "../../App.css";
import GenericEnhancedTable, { defaultGridConfig } from "../../components/GenericEnhancedTable";
import { colDefs } from "./colDefs";
import { useStyles } from "./styles";

export default function ReferralEpicListView() {
  const { classes } = useStyles();
  const { addError } = useApp();
  const listSelection = "Available";

  const exportDataToCSV = async () => {
    try {
      const reqUrl = API_ENDPOINTS.referralEpicDownloadCsv;
      const { data } = await axiosClient.get(reqUrl);
      exportCSV(data, "referral-list-report");
    } catch (error) {
      addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let timeoutId;
    const handlePopState = () => { timeoutId = setTimeout(() => window.scrollTo(0, 0), 0); return null; }
    window.addEventListener("popstate", handlePopState);
    return () => { clearTimeout(timeoutId); return window.removeEventListener("popstate", handlePopState) };
  }, []);

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        container
        spacing={8}
        className={classes.topGrid}
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <div style={{ marginTop: "30px" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveAltIcon />}
            onClick={() => exportDataToCSV()}
          >
            Export (CSV)
          </Button>
        </div>
      </Grid>

      <Grid
        container
        spacing={8}
        className={classes.root}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <GenericEnhancedTable
            gridConfig={{
              ...defaultGridConfig,
              allowDelete: false,
              allowEdit: false,
              showActionColumn: false,
              showActionColumnOnStart: false,
              allowOverwrite: false,
            }}
            gridType={listSelection}
            tableTitle="Referral Epic List"
            colDefs={colDefs}
            url={API_ENDPOINTS.referralEpicGetReferrals}
            orderBy="created_at"
            order="desc"
            hideCheckboxes
          />
        </Grid>
      </Grid>
      <br />
    </form>
  );
}
