import { getFullNameFromParts } from "util/util_functions";

export const colDefs = [
  {
    id: "specimenOrderOrderExternalId",
    numeric: false,
    isString: true,
    disablePadding: true,
    label: "Order ID",
    searchable: true,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenOrderMrn",
    numeric: false,
    isString: true,
    disablePadding: true,
    label: "MRN",
    searchable: true,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenOrderPatientName",
    numeric: false,
    disablePadding: true,
    label: "Patient Name",
    searchable: true,
    sortable: true,
    mobile: true,
    isCustomContent: true,
    func: (_, row) =>
      getFullNameFromParts(row.specimenOrderPatientFirstName, row.specimenOrderPatientLastName),
  },
  {
    id: "specimenSpecimenExternalId",
    numeric: false,
    isString: true,
    disablePadding: true,
    label: "Specimen ID",
    searchable: true,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenStatus",
    numeric: false,
    isString: true,
    disablePadding: false,
    label: "Status",
    searchable: false,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenSpecimenType",
    numeric: false,
    isString: true,
    disablePadding: false,
    label: "Type",
    searchable: false,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenDescription",
    numeric: false,
    isString: true,
    disablePadding: false,
    label: "Info",
    searchable: false,
    sortable: true,
    mobile: true,
  },
  {
    id: "collectedDatetime",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "Collected Date",
    searchable: true,
    sortable: true,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
  {
    id: "collectedAtName",
    numeric: false,
    isString: true,
    disablePadding: true,
    label: "Tracked Location",
    searchable: false,
    sortable: true,
    mobile: true,
  },
  {
    id: "collectedBy",
    numeric: false,
    disablePadding: true,
    label: "Tracked By",
    searchable: false,
    sortable: true,
    mobile: true,
    isCustomContent: true,
    func: (_, row) => getFullNameFromParts(row.collectedByFirstName, row.collectedByLastName),
  },
];
