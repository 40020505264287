import { TVirtualSession } from "./types";
import REQUEST from "../../static/images/ahsIcons/request.png";
import VUC from "../../static/images/ahsIcons/vuc.png";

export const programsIcons = {
  "Virtual Urgent Care": VUC,
  "Virtual Behavioral Health": VUC,
  "Virtual Neuro Triage": VUC,
  "Oncology Inquiry": REQUEST,
  "Neurology Inquiry": REQUEST,
  "Adhoc Virtual Visits": VUC,
};

export const getLabel = ({ patient_reason }: TVirtualSession): string => {
  if (!patient_reason) {
    return "";
  }
  switch (patient_reason) {
    case "yes":
      return "";

    case "no":
      return "";

    default:
      return patient_reason
        ?.split("_")
        .map((str) => str[0].toUpperCase() + str.substring(1, str.length))
        .join(" ");
  }
};
