import React, { Dispatch, FC, SetStateAction } from "react";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ethnicitis, races, sexes, states } from "./ConstantsForAutocomplete";
import { PatientInformation } from "components/FindADoctor/types";
import { Mask } from "util/constants";
import useStyles from "./BookingOnline.styles";

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  date: "",
  homePhone: "",
  mobilePhone: "",
  email: "",
  gender: "",
  language: "",
  securityNumber: "",
  race: "",
  ethnicity: "",
  primaryInsurance: "",
  insuranceGroup: "",
  insuranceMember: "",
  reason: "",
};

const transactionSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zip is required"),
  date: yup.string().required("Date of Birth is required"),
  homePhone: yup.string().required("Home Phone is required"),
  email: yup.string().required("Home Phone is required").email("Email format address@example.com"),
  gender: yup.string().required("Legal Sex is required"),
  reason: yup.string().required("Reason for visit is required"),
});

interface Props {
  setValue: Dispatch<SetStateAction<PatientInformation | undefined>>;
  handleNext: () => void;
}

const PatientInfo: FC<Props> = ({ setValue, handleNext }) => {
  const { classes } = useStyles();

  const { handleChange, handleSubmit, values, errors } = useFormik<PatientInformation>({
    initialValues,
    validationSchema: transactionSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      setValue(values);
      handleNext();
    },
  });

  return (
    <Container maxWidth="md">
      <Grid container spacing={3}>
        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            label="First Name *"
            placeholder="Legal First Name"
            variant="outlined"
            value={values.firstName}
            name="firstName"
            onChange={handleChange}
          />
          {errors.firstName && (
            <Typography align="left" className={classes.error}>
              {errors.firstName}
            </Typography>
          )}
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Middle Name"
            placeholder="Legal Middle Name"
            variant="outlined"
            value={values.middleName}
            name="middleName"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Last Name *"
            placeholder="Legal Last Name"
            variant="outlined"
            value={values.lastName}
            name="lastName"
            onChange={handleChange}
          />
          {errors.lastName && (
            <Typography align="left" className={classes.error}>
              {errors.lastName}
            </Typography>
          )}
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Address *"
            placeholder="Street Address"
            variant="outlined"
            value={values.address}
            name="address"
            onChange={handleChange}
          />
          {errors.address && (
            <Typography align="left" className={classes.error}>
              {errors.address}
            </Typography>
          )}
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            label="City *"
            placeholder="City"
            variant="outlined"
            value={values.city}
            name="city"
            onChange={handleChange}
          />
          {errors.city && (
            <Typography align="left" className={classes.error}>
              {errors.city}
            </Typography>
          )}
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>State *</InputLabel>
            <Select
              variant="standard"
              label="State"
              placeholder="State"
              value={values.state}
              name="state"
              onChange={handleChange}
            >
              {states.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {errors.state && (
              <Typography align="left" className={classes.error}>
                {errors.state}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Zip *"
            placeholder="Zip"
            variant="outlined"
            type="number"
            value={values.zip}
            name="zip"
            onChange={handleChange}
          />
          {errors.zip && (
            <Typography align="left" className={classes.error}>
              {errors.zip}
            </Typography>
          )}
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            placeholder="Date of Birth"
            type="date"
            variant="outlined"
            value={values.date}
            name="date"
            onChange={handleChange}
          />
          {errors.date && (
            <Typography align="left" className={classes.error}>
              {errors.date}
            </Typography>
          )}
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <InputMask
            alwaysShowMask={false}
            mask={Mask.CELL_PHONE}
            value={values.homePhone}
            name="homePhone"
            onChange={handleChange}
          >
            {/* @ts-ignore */}
            {(props) => (
              <TextField {...props} fullWidth label="Home Phone Number *" variant="outlined" />
            )}
          </InputMask>
          {errors.homePhone && (
            <Typography align="left" className={classes.error}>
              {errors.homePhone}
            </Typography>
          )}
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <InputMask
            alwaysShowMask={false}
            mask={Mask.CELL_PHONE}
            value={values.mobilePhone}
            name="mobilePhone"
            onChange={handleChange}
          >
            {/* @ts-ignore */}
            {(props) => (
              <TextField {...props} fullWidth label="Mobile Phone Number" variant="outlined" />
            )}
          </InputMask>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Email *"
            placeholder="username@email.com"
            variant="outlined"
            value={values.email}
            name="email"
            onChange={handleChange}
          />
          {errors.email && (
            <Typography align="left" className={classes.error}>
              {errors.email}
            </Typography>
          )}
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Legal Sex *</InputLabel>
            <Select
              variant="standard"
              label="Legal Sex *"
              placeholder="Sex"
              value={values.gender}
              name="gender"
              onChange={handleChange}
            >
              {sexes.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.gender && (
            <Typography align="left" className={classes.error}>
              {errors.gender}
            </Typography>
          )}
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Preferred Language"
            placeholder="Language"
            variant="outlined"
            value={values.language}
            name="language"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <InputMask
            alwaysShowMask={false}
            mask="9999"
            value={values.securityNumber}
            name="securityNumber"
            onChange={handleChange}
          >
            {/* @ts-ignore */}
            {(props) => (
              <TextField
                {...props}
                fullWidth
                label="Last 4 Digits of Social Security Number"
                placeholder="XXXX"
                variant="outlined"
              />
            )}
          </InputMask>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Race</InputLabel>
            <Select
              variant="standard"
              label="Race"
              placeholder="Race"
              value={values.race}
              name="race"
              onChange={handleChange}
            >
              {races.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Ethnicity</InputLabel>
            <Select
              variant="standard"
              label="Ethnicity"
              placeholder="Ethnicity"
              value={values.ethnicity}
              name="ethnicity"
              onChange={handleChange}
            >
              {ethnicitis.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Primary Insurance"
            placeholder="Primary Insurance"
            variant="outlined"
            value={values.primaryInsurance}
            name="primaryInsurance"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Insurance Group ID Number"
            placeholder="Insurance Group ID Number"
            variant="outlined"
            value={values.insuranceGroup}
            name="insuranceGroup"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Insurance Member ID Number"
            placeholder="Insurance Member ID Number"
            variant="outlined"
            value={values.insuranceMember}
            name="insuranceMember"
            onChange={handleChange}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label="What is the reason for your visit? *"
            placeholder="What is the reason for your visit?"
            variant="outlined"
            multiline
            minRows={5}
            value={values.reason}
            name="reason"
            onChange={handleChange}
          />
        </Grid>
        {errors.reason && (
          <Typography align="left" className={classes.error}>
            {errors.reason}
          </Typography>
        )}
      </Grid>

      <Button onClick={() => handleSubmit()} className={classes.nextButton}>
        Next
      </Button>
    </Container>
  );
};

export default PatientInfo;
