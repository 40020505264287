export const colDefs = [
  {
    id: "mrn",
    numeric: false,
    disablePadding: true,
    label: "MRN",
    searchable: true,
    sortable: true,
  },
  {
    id: "orderNumber",
    numeric: false,
    disablePadding: true,
    label: "Order Number",
    searchable: true,
    sortable: true,
  },
  {
    id: "dob",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "DOB",
    searchable: false,
    sortable: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Gender",
    searchable: false,
    sortable: true,
  },
  {
    id: "Address1",
    numeric: false,
    disablePadding: false,
    label: "Address line 1",
    searchable: false,
    sortable: true,
  },
  {
    id: "Address2",
    numeric: false,
    disablePadding: false,
    label: "Address line 2",
    searchable: false,
    sortable: true,
  },
  {
    id: "patientFirstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    searchable: false,
    sortable: true,
  },
  {
    id: "patientLastName",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "zipCode",
    numeric: false,
    disablePadding: false,
    label: "Zip Code",
    searchable: true,
    sortable: true,
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    searchable: false,
    sortable: true,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
    searchable: false,
    sortable: true,
  },
  {
    id: "cellPhone",
    numeric: false,
    disablePadding: false,
    label: "Cellphone",
    searchable: false,
    sortable: true,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    searchable: false,
    sortable: true,
  },
  {
    id: "maritalStatus",
    numeric: false,
    disablePadding: false,
    label: "Marital status",
    searchable: false,
    sortable: true,
  },
  {
    id: "specimenActionCode",
    numeric: false,
    disablePadding: false,
    label: "Specimen action code",
    searchable: false,
    sortable: true,
  },
  {
    id: "orderingProvider",
    numeric: false,
    disablePadding: false,
    label: "Ordering Provider",
    searchable: false,
    sortable: true,
    isCustomContent: true,
    func: (_, row) => row.orderingProvider?.raw_data,
  },
  {
    id: "diagnosis",
    numeric: false,
    disablePadding: false,
    label: "Diagnosis code",
    searchable: true,
    sortable: true,
    isCustomContent: true,
    func: (_, row) => row.diagnosis?.code,
  },
  {
    id: "referredDept",
    numeric: false,
    disablePadding: false,
    label: "Department",
    searchable: false,
    sortable: true,
    isCustomContent: true,
    func: (_, row) => row.referredDept?.raw_data,
  },
  {
    id: "universalServiceInfo",
    numeric: false,
    disablePadding: false,
    label: "Referral universal service info",
    searchable: false,
    sortable: true,
    isCustomContent: true,
    func: (_, row) => row.universalServiceInfo?.raw_data,
  },
  {
    id: "insuranceCompany",
    numeric: false,
    disablePadding: false,
    label: "Insurance Company",
    searchable: false,
    sortable: true,
    isCustomContent: true,
    func: (_, row) => row.insuranceCompany?.name,
  },
  {
    id: "created_at",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "Created On",
    searchable: false,
    sortable: true,
  },
];
