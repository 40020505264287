import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { waitingUsersTableStyles } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/styles";
import { usePrograms } from "util/ProgramsContext";

const ProgramOverrideStatus = () => {
  const { classes } = waitingUsersTableStyles();
  const { programOverrideStatus, handleUpdateProgramOverrideStatus } = usePrograms();

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel id="programOverrideLabel" className={classes.label}>
        Program Override Status
      </InputLabel>
      <Select
        variant="standard"
        className={(classes.select, "select-form-filter")}
        labelId="programOverrideLabel"
        id="programOverride-select"
        value={programOverrideStatus}
        onChange={(e) => handleUpdateProgramOverrideStatus(e.target.value)}
      >
        <MenuItem value="">None</MenuItem>
        <MenuItem value="Normal Operations">Normal Operations</MenuItem>
        <MenuItem value="Closed For Holiday">Closed For Holiday</MenuItem>
        <MenuItem value="At Max Capacity">At Max Capacity</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ProgramOverrideStatus;
