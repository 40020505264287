import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppointmentScheduled from "components/Consultation/AppointmentScheduled";
import Consultation from "components/Consultation/ConsultationLaunch";
import Layout from "components/Consultation/Layout";
import {
  Screening as PTScreening,
  Location as PTTLocation,
} from "components/Consultation/PhysicalTherapyCall/Steps";
import ScheduleView from "components/Consultation/ScheduleView";
import Screening, { StateType } from "components/Consultation/Screening";
import SigninView from "components/Consultation/SigninView";
import { QuerySteps, Steps, StepsMapping } from "components/Consultation/Steps";
import UploadReports from "components/Consultation/UploadReports";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { ProgramNames } from "util/constants";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

const ProgramsPreJoinScreens = () => {
  const [step, setStep] = useState<Steps>(Steps.ConsultationStep);
  const [open, setOpen] = React.useState(false);
  const [state, setStateVal] = useState<StateType | string>("New Jersey");
  const [diagnosis, setDiagnosis] = useState(false);
  const [diagnosisDetails, setDiagnosisDetails] = useState("");
  const [receivingTreatment, setReceivingTreatment] = useState("no");
  const [clinicalTrials, setClinicalTrials] = useState("no");
  const [hadSurgery, setSurgery] = useState<string>("no");
  const navigate = useNavigate();
  const { programName, page, vsId } = useParams<{
    programName: string;
    page: string;
    vsId: string;
  }>();

  const app = useApp();
  const auth = useAuth();
  const decodedProgramName = decodeURIComponent(programName || "");

  const handleChangeStep = (queryStep: QuerySteps) => {
    if (vsId) {
      navigate(`/inquiryrequest/${programName}/${vsId}/${queryStep}`);
    }
  };

  const handleCreateVSession = async () => {
    try {
      const virtualSessionUrl = "/api/s/virtualvisit/video/start/virtual-session";
      const { data } = await axiosClient.post(virtualSessionUrl, {
        program: decodedProgramName,
      });
      navigate(
        `/inquiryrequest/${encodeURIComponent(programName || "")}/${
          data?.virtual_session_id
        }/screening`,
      );
    } catch (err) {
      // @ts-ignore
      app.addError(err?.response.data.description);
    }
  };

  useEffect(() => {
    const getVirtualSession = async (vsId) => {
      try {
        const { data } = await axiosClient.post(API_ENDPOINTS.twilio_get_virtual_session, {
          vs_id: vsId,
        });
        app.dispatch({ type: UPDATE_AND_SELECT_VIRTUAL_VISIT, payload: data });
      } catch (error) {
        app.addError("Something went wrong. Please contact support");
      }
    };
    if (vsId) {
      getVirtualSession(vsId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  useEffect(() => {
    if (programName && !vsId && auth?.user) {
      if (step !== Steps.ConsultationStep) {
        handleCreateVSession();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, auth?.user, programName, vsId]);

  useEffect(() => {
    if (!programName) {
      setStep(Steps.ConsultationStep);
      navigate("/inquiryrequest");
    } else if (vsId && page) {
      setStep(StepsMapping[page]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, programName, vsId, navigate]);

  useEffect(() => {
    if (step === Steps.ConsultationStep && !programName) {
      setOpen(true);
    }
  }, [step, programName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (decodedProgramName === ProgramNames.PHYSICAL_THERAPY) {
    return (
      <Layout step={step}>
        <>
          {step === Steps.ConsultationStep && (
            <Consultation setStep={setStep} setOpen={setOpen} open={open} />
          )}
          {step === Steps.SignInStep && <SigninView setStep={handleChangeStep} />}
          {step === Steps.ScreeningStep && <PTScreening setStep={handleChangeStep} />}
          {step === Steps.UploadStep && <UploadReports setStep={handleChangeStep} />}
          {step === Steps.LocationStep && <PTTLocation setStep={handleChangeStep} />}
          {step === Steps.SchedulingStep && <ScheduleView setStep={handleChangeStep} />}
          {step === Steps.AppointmentScheduledStep && <AppointmentScheduled />}
        </>
      </Layout>
    );
  }

  return (
    <Layout step={step}>
      <>
        {step === Steps.ConsultationStep && (
          <Consultation setStep={setStep} setOpen={setOpen} open={open} />
        )}
        {step === Steps.SignInStep && <SigninView setStep={handleChangeStep} />}
        {step === Steps.ScreeningStep && (
          <Screening
            setStep={handleChangeStep}
            diagnosis={diagnosis}
            setDiagnosis={setDiagnosis}
            diagnosisDetails={diagnosisDetails}
            setDiagnosisDetails={setDiagnosisDetails}
            receivingTreatment={receivingTreatment}
            setReceivingTreatment={setReceivingTreatment}
            clinicalTrials={clinicalTrials}
            setClinicalTrials={setClinicalTrials}
            hadSurgery={hadSurgery}
            setSurgery={setSurgery}
            state={state}
            setStateVal={setStateVal}
          />
        )}
        {step === Steps.UploadStep && <UploadReports setStep={handleChangeStep} />}
        {step === Steps.SchedulingStep && <ScheduleView setStep={handleChangeStep} />}
        {step === Steps.AppointmentScheduledStep && <AppointmentScheduled />}
      </>
    </Layout>
  );
};

export default ProgramsPreJoinScreens;
