import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { eachDayOfInterval, formatISO } from "date-fns";
import { overwriteConfigSchema } from "views/Config/OverwriteConfigModal/overwriteConfigSchema";
import TimeSlotsOverwrite from "components/TimeSlotsOverwrite/TimeSlotsOverwrite";
import DateRangePicker from "components/shared/DatePickers/DateRangePicker/DateRangePicker";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { convertAllObjectStringToDate, convertDateArrayToWorkDays } from "util/dateHelpers";
import { getDiffFromRecordConfig } from "util/util_functions";
import { InitialValues } from "views/Config/OverwriteConfigModal/types";
import { useStyles } from "./OverwriteConfigModal.styles";

const OverwriteConfigModal = ({ record, open, setOpen }) => {
  const {
    json: { virtual_sessions: virtualSessions },
  } = record;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<InitialValues>({});
  const [daysToOverwrite, setDaysToOverwrite] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);
  const [startDate, endDate] = dateRange;
  const { classes, cx } = useStyles({ isLoading });
  const { addError, addInfoMsg } = useApp();

  useEffect(() => {
    if (virtualSessions && virtualSessions.time_slots) {
      const { duration, capacity, working_hours: workingHours } = virtualSessions.time_slots;
      const timeSlotsInitValues = {
        capacity,
        duration,
        working_hours: convertAllObjectStringToDate(workingHours),
      };
      setInitialValues({ ...virtualSessions, time_slots: timeSlotsInitValues });
    }
  }, [virtualSessions]);

  const sendData = async (newConfig) => {
    try {
      await axiosClient.post(
        `/api/s/console/config/${record.id}/overrides`,
        JSON.stringify(newConfig),
      );

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleClose();
      addInfoMsg(`${record.name} was overwritten successfully`);
    } catch {
      addError("Problem overwriting record, please contact support.");
    }
    setIsLoading(false);
  };

  const submitForm = (currentValues) => {
    const diff = getDiffFromRecordConfig(currentValues, record.json);

    if (!isEmpty(diff) && startDate && endDate) {
      const newConfig = {
        startDate: formatISO(startDate, { representation: "date" }),
        endDate: formatISO(endDate, { representation: "date" }),
        overrideJson: {
          ...diff,
        },
      };

      setIsLoading(true);
      sendData(newConfig);
    } else {
      addError("You must change something to overwrite record");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: overwriteConfigSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: true,
    onSubmit: submitForm,
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setDateRange([null, null]);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const allDays = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });

      const days = convertDateArrayToWorkDays(allDays);
      setDaysToOverwrite(days);
    } else {
      setDaysToOverwrite([]);
    }
  }, [startDate, endDate]);

  const dateRangeChangeHandler = (date: Array<Date | null>) => {
    setDateRange(date);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6" className={classes.title}>
          Overwrite Record
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content} dividers>
        <div className={classes.container}>
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item className={classes.textField}>
              <TextField
                fullWidth
                id="configName"
                value={record.name}
                name="configName"
                variant="outlined"
                label="Config Name"
                disabled
              />
            </Grid>
            <Grid
              container
              justifyContent="center"
              item
              className={cx(classes.textField, classes.datePickerContainer)}
            >
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onDateRangeChange={dateRangeChangeHandler}
              />
            </Grid>
          </Grid>
          <form onSubmit={formik.handleSubmit} noValidate>
            {virtualSessions?.time_slots && startDate && endDate && (
              <TimeSlotsOverwrite
                daysToOverwrite={daysToOverwrite}
                timeSlots={virtualSessions.time_slots}
                formik={formik}
              />
            )}
          </form>
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          onClick={() => formik.handleSubmit()}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
      {isLoading && (
        <div className={classes.spinner}>
          <CircularProgress color="primary" />
        </div>
      )}
    </Dialog>
  );
};

export default OverwriteConfigModal;
