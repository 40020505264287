import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CONSTANTS from "../../../views/Mobile/constants";
import useTranslation from "hooks/useTranslation";
import GetCareSelectedIcon from "../../../static/svg/mobile/getcare-s.svg";
import GetCareIcon from "../../../static/svg/mobile/getcare.svg";
import HomeSelectedIcon from "../../../static/svg/mobile/home-s.svg";
import HomeIcon from "../../../static/svg/mobile/home.svg";
import LocationsSelectedIcon from "../../../static/svg/mobile/locations-s.svg";
import LocationsIcon from "../../../static/svg/mobile/locations.svg";
import MoreSelectedIcon from "../../../static/svg/mobile/more-s.svg";
import MoreIcon from "../../../static/svg/mobile/more.svg";

interface FooterProps {
  location;
}

const useStyles = makeStyles()(() => ({
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "0.5em 0 0.25em",
    backgroundColor: "#fff",
    borderTop: "1px solid #dbdbdb",
  },
  button: {
    minWidth: "60px",
    display: "flex",
    flexDirection: "column",
    gap: "0.25em",
    fontSize: "0.75em",
    background: "transparent",
    outline: "none",
    border: "none",
    alignItems: "center",
    color: "inherit",

    "&:focus": {
      outline: "none",
    },
  },
  buttonIcon: {
    height: "2.5em",
    width: "2.5em",
    fill: "#5d5d5d",
  },
  selectedButtonIcon: {
    height: "2.5em",
    width: "2.5em",
    fill: "#3676c4",
  },
  buttonText: {
    fontSize: "1em",
    color: "#5d5d5d",
  },
  selectedButtonText: {
    fontSize: "1em",
    color: "#3676c4",
  },
}));

const Footer = ({ location }: FooterProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { mobile } = useTranslation();
  const { Home, GetCare, Locations, More } = mobile;

  const links = [
    {
      url: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.HOME}`,
      text: Home,
      icon: HomeIcon,
      selectedIcon: HomeSelectedIcon,
      testId: "footer-home-button",
    },
    {
      url: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.CARE}`,
      text: GetCare,
      icon: GetCareIcon,
      selectedIcon: GetCareSelectedIcon,
      testId: "footer-get-care-button",
    },
    {
      url: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.LOCATIONS}`,
      text: Locations,
      icon: LocationsIcon,
      selectedIcon: LocationsSelectedIcon,
      testId: "footer-locations-button",
    },
    {
      url: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.MORE}`,
      text: More,
      icon: MoreIcon,
      selectedIcon: MoreSelectedIcon,
      testId: "footer-more-button",
    },
  ];

  const parentLocations = {
    [CONSTANTS.APPOINTMENTS]: CONSTANTS.HOME,
    [CONSTANTS.FINDHOSPITAL]: CONSTANTS.LOCATIONS,
    [CONSTANTS.HOSPITAL]: CONSTANTS.LOCATIONS,
    [CONSTANTS.FINDURGENTCARE]: CONSTANTS.LOCATIONS,
    [CONSTANTS.URGENTCARE]: CONSTANTS.LOCATIONS,
    [CONSTANTS.PATIENTVISITOR]: CONSTANTS.LOCATIONS,
    [CONSTANTS.PARKING]: CONSTANTS.LOCATIONS,
    [CONSTANTS.ABOUT]: CONSTANTS.MORE,
  };

  const parentLocation = parentLocations[location] ?? location;

  const handleLinkPressed = useCallback(
    (url) => {
      navigate(url, { state: { from: location } });
    },
    [location, navigate],
  );

  return (
    <div className={classes.footerContainer}>
      {links.map(({ url, selectedIcon, icon, text, testId }) => {
        const isSelected = url.indexOf(parentLocation) !== -1;
        return (
          <button
            type="button"
            className={classes.button}
            key={url}
            onClick={() => handleLinkPressed(url)}
            data-cy={testId}
          >
            <img
              className={isSelected ? classes.selectedButtonIcon : classes.buttonIcon}
              src={isSelected ? selectedIcon : icon}
              alt=""
            />
            <Typography className={isSelected ? classes.selectedButtonText : classes.buttonText}>
              {text}
            </Typography>
          </button>
        );
      })}
    </div>
  );
};
export default Footer;
