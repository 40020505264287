/** @format */
import { makeStyles } from "tss-react/mui";

export const useSinglePatientStyles = makeStyles()((theme) => ({
  root: {
    width: "95%",
    textAlign: "left",
    height: 230,
    position: "realtive",
  },
  media: {
    height: 75,
    background: theme.palette.secondary.main,
    paddingLeft: 180,
    paddingTop: 18,
    boxSizing: "border-box",
  },
  img: {
    width: 150,
    height: 180,
    position: "relative",
    top: -70,
  },
  cardContent: {
    display: "flex",
  },
  heading: {
    fontSize: 18,
    color: theme.palette.common.black,
    margin: "0",
  },
  cardContentDiv: {
    padding: 0,
    marginLeft: 15,
    height: 110,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  insideCards: {
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
}));

export const usePatientSearchStyles = makeStyles()((theme) => ({
  patientSearchContainer: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
    justifyContent: "center",
    flexDirection: "column",
    border: (props) => (props?.noBorder ? "none" : "1px solid rgba(0, 0, 0, 0.12)"),
    borderRadius: "6px",
    padding: "24px",
    boxSizing: "border-box",
    width: "100%",
  },
  patientFormContainer: {
    textAlign: "left",
    background: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    marginBottom: "1rem",
    padding: "24px",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "60px",
  },
  title: {
    fontSize: "26px",
    color: "rgb(40, 42, 43)",
    fontWeight: 500,
    lineHeight: "34px",
    textAlign: "center",
    marginBottom: "15px",
  },
  headerTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "20px",
    lineHeight: "32px",
    fontFamily: "Alegreya Sans",
    fontWeight: 500,
    padding: "16px",
    width: "calc(100% + 48px)",
    marginTop: "-15px",
    marginLeft: "-24px",
    marginBottom: "20px",
  },
  searchDescription: {
    display: "flex",
    fontSize: 14,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  saveNote: {
    paddingTop: "5px",
  },
  header: {
    color: theme.palette.secondary.main,
  },
  chatWidget: {
    height: "600px",
  },
  confirmationModal: {
    width: "80%",
    height: 400,
  },
  textFieldRoot: {
    margin: "16px 0",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  textField: {
    width: "100%",
  },
  searchTextFieldLabel: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  searchTextFieldOutline: {
    borderColor: "rgba(0, 0, 0, 0.23)",
  },
  inputRoot: {
    borderRadius: "4px",
    height: "56px",
  },
  fieldsContainer: {
    display: "grid",
    gridTemplateColumns: "calc(50% - 6px) calc(50% - 6px)",
    gap: "12px",
  },
  button: {
    height: "42px",
    marginTop: "20px",
  },
  patientSearch: {
    marginLeft: theme.spacing(1),
    width: "25ch",
  },
  calendarRoot: {
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
    },
  },
  calendarLabel: {
    fontFamily: "inherit",
  },
  patientsListContainer: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    padding: "12px",
    boxSizing: "border-box",
    width: "100%",
  },
  goBackBtnContainer: {
    width: "100%",
    textAlign: "left",
  },
  dividerFullWidth: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
}));

export const useSearchHeader = makeStyles()(() => ({
  heading: {
    textAlign: "left",
  },
  padeHeading: {
    fontWeight: "bold",
    textAlign: "left",
    margin: 0,
  },
}));

export const usePatientListStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
    maxHeight: 380,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },

  name: {
    fontWeight: "bold",
    marginBottom: "15px",
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: "15px",
  },
  circle: {
    position: "relative",
    left: 15,
    "&::after": {
      display: "flex",
      content: `""`,
      alignItems: "center",
      justifyContent: "center",
      width: 5,
      height: 5,
      background: theme.palette.common.black,
      borderRadius: "50%",
      position: "absolute",
      left: -10,
      top: 6,
    },
  },
  listIcon: {
    position: "relative",
    top: "5px",
    height: "20px",
    width: "20px",
    marginRight: "5px",
  },
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px rgba(0, 0, 0, 0.15)",
  },
}));
