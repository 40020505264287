/* eslint-disable */
import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import AddTeamMemberDialog from "./AddTeamMemberDialog";
import ConfirmationDialog from "./TeamMembersConfirmationDialog";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "../../util/AppContext";
import { useAuth } from "../../util/Security";
import { axiosClient } from "../../util/api_helper";
import useStyles from "./TeamMembers.styles";

const TeamMembers = ({ userRecord = {}, isConfigView = false }) => {
  const { classes } = useStyles();
  const { addInfoMsg, addError } = useApp();
  const { user } = useAuth();
  const { userId } = user;
  const { isPhysician } = useUserType();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isEditOn, setIsEditOn] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(-1);

  useEffect(() => {
    getAllTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllTeamMembers = async () => {
    const url = `api/s/console/user/${isConfigView ? userRecord?.userId : userId}/team-members`;
    try {
      const { data } = await axiosClient.get(url);
      setTeamMembers(data);
    } catch (error) {
      addError(`Failed to load team members`);
    }
  };

  const createTeamMember = async ({ firstName, lastName, role, cellPhone }) => {
    const url = `api/s/console/user/${isConfigView ? userRecord?.userId : userId}/team-members`;
    try {
      const { data } = await axiosClient.post(url, {
        firstName,
        lastName,
        role,
        cellPhone,
      });
      if (data) {
        setTeamMembers([...teamMembers, data]);
      }
      setIsOpen(false);
      addInfoMsg(`Team member has been added successfully`);
    } catch (error) {
      if (error?.response?.status === 409) {
        addError("Failed to add new team member. This phone number already exists");
      } else {
        addError("Failed to add new team member");
      }
    }
  };

  const removeTeamMember = async (id) => {
    const url = `api/s/console/user/${
      isConfigView ? userRecord?.userId : userId
    }/team-members/${id}`;
    try {
      const { status } = await axiosClient.delete(url);
      if (status === 204) {
        const filteredTeamMembers = teamMembers.filter(({ user }) => user?.id !== id);
        setTeamMembers(filteredTeamMembers);
        addInfoMsg(`Team member has been removed successfully`);
      }
    } catch (error) {
      addError("Failed to remove team member");
    }
    setIsConfirmationDialogOpen(false);
  };

  const editTeamMember = async (value, id) => {
    const url = `api/s/console/user/${
      isConfigView ? userRecord?.userId : userId
    }/team-members/${id}`;
    try {
      const { data } = await axiosClient.patch(url, { role: value });
      if (data) {
        const { relationship } = teamMembers.find(({ user }) => user?.id === id);
        if (relationship?.type) {
          relationship.type = data?.relationship?.type;
          addInfoMsg(`Team member has been updated successfully`);
        }
      }
      setIsEditOn(false);
    } catch (error) {
      addError("Failed to update team member");
      setIsEditOn(false);
    }
  };

  const handleEddit = (id) => {
    const { user } = teamMembers.find(({ user }) => user?.id === id);
    if (user?.id) {
      setIsEditOn(true);
      setSelectedUserId(user?.id);
    } else {
      setIsEditOn(false);
      addError("Failed to update team member");
    }
  };
  const handleRemove = (id) => {
    const { user } = teamMembers.find(({ user }) => user?.id === id);
    if (user?.id) {
      setIsConfirmationDialogOpen(true);
      setSelectedUserId(user?.id);
    } else {
      setIsConfirmationDialogOpen(false);
      addError("Failed to remove team member");
    }
  };
  const handleDialogOpen = () => setIsOpen(true);
  const handleDialogClose = () => setIsOpen(false);

  const TeamMember = ({ teamMemberInfo }) => {
    const { relationship, user } = teamMemberInfo;
    const { type } = relationship;
    const { cellPhone, id } = user;

    return (
      <Paper elevation={2} className={classes.paperContainer}>
        <div className={classes.memberInfoContainer}>
          <Typography paragraph>{`${
            cellPhone.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3") || "Unknown"
          }`}</Typography>
          {!isEditOn || selectedUserId !== id ? (
            <Typography paragraph>{type}</Typography>
          ) : (
            <FormControl variant="standard" size="small">
              <Select
                variant="standard"
                label="Role"
                onChange={(event) => editTeamMember(event.target.value, id)}
                value={type}
                inputlabelprops={{ shrink: false }}
                disableUnderline
              >
                <MenuItem value="Tier 1">Tier 1</MenuItem>
                <MenuItem value="Backup">Backup</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
        {isPhysician || (
          <div className={classes.iconContainer}>
            {isEditOn && selectedUserId === id ? (
              <IconButton onClick={() => setIsEditOn(false)} size="large">
                <CheckIcon color="secondary" />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleEddit(id)} size="large">
                <EditIcon color="primary" />
              </IconButton>
            )}
            <IconButton onClick={() => handleRemove(id)} size="large">
              <ClearIcon color="primary" />
            </IconButton>
          </div>
        )}
      </Paper>
    );
  };

  return (
    <div className={classes.teamMembersContainer}>
      <div className={classes.teamMembersHeader}>
        {!isConfigView && <Typography paragraph>My Team</Typography>}
        {isConfigView && <Typography paragraph>{`${userRecord?.firstName}'s Team`}</Typography>}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDialogOpen()}
          disabled={isPhysician}
        >
          ADD NEW MEMBER
        </Button>
      </div>
      <div className={classes.teamMember}>
        {teamMembers?.map((teamMember, index) => (
          <TeamMember key={index} teamMemberInfo={teamMember} />
        ))}
      </div>
      <AddTeamMemberDialog
        isOpen={isOpen}
        handleClose={handleDialogClose}
        createTeamMember={createTeamMember}
      />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        handleClose={() => setIsConfirmationDialogOpen(false)}
        removeTeamMember={removeTeamMember}
        id={selectedUserId}
      />
    </div>
  );
};

export default TeamMembers;
