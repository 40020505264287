import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useChatInputStyles = makeStyles()((theme: Theme) => ({
  chatInputContainer: {
    borderTop: "1px solid #e4e7e9",
    borderBottom: "1px solid #e4e7e9",
    padding: "10px 15px",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      position: "sticky",
      bottom: "0px",
    },
  },
  textArea: {
    width: "100%",
    border: "0",
    resize: "none",
    fontSize: "14px",
    outline: "none",
    fontFamily: "Alegreya Sans",
  },
  button: {
    padding: "0.56em",
    minWidth: "auto",
    "&:disabled": {
      background: "none",
      "& path": {
        fill: "#d8d8d8",
      },
    },
  },
  buttonContainer: {
    margin: "1em 0 0 1em",
    display: "flex",
  },
  fileButtonContainer: {
    position: "relative",
    marginRight: "1em",
  },
  fileButtonLoadingSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textAreaContainer: {
    display: "flex",
    marginTop: "0.4em",
    padding: "0.48em 0.7em",
    border: "2px solid rgba(0,0,0,0.12)",
    borderRadius: "4px",
  },
  isTextareaFocused: {
    borderColor: theme.palette.primary.main,
    borderRadius: "4px",
  },
  sendIcon: {
    color: "#878d91",
  },
  sendIconDisabled: {
    color: "#b7bcbf",
  },
  quickPhrasesContainer: {
    display: "flex",
  },
  formControl: {
    minWidth: "100%",
    maxWidth: "100%",
  },
  quickPhrasesMenuItem: {
    maxWidth: "400px",
  },
}));
