import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { grey } from "@mui/material/colors";
import AddPatient from "./AddPatient";
import SearchHeader from "./SearchHeader";

export default function IconLabelButtons({ showForm }) {
  return (
    <div>
      <SearchHeader records={[]} />
      <div>
        <ErrorOutlineIcon style={{ fontSize: 120, color: grey[400] }} />
      </div>
      <AddPatient title="Add New Patient" showIcon showForm={showForm} />
    </div>
  );
}
