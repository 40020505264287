import React, { useState } from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import ProofOfVaccination from "components/ImprovedProofOfVaccinations";
import Loader from "components/Loader";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import "../../App.css";
import { useStyles } from "./styles";

export default function ConsoleVaccineCardView() {
  const { classes } = useStyles();
  const app = useApp();
  const [emrPatientId, setEmrPatientId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [altQRCodeURL, setAltQRCodeURL] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [zipCode, setZipCode] = useState("");

  const handleChangeCellPhone = (e) => {
    if (e.length <= 10) {
      setCellPhone(Number(e) ? e : "");
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = "/api/s/console/patient/fhir/immunizations";
      const url = new URL(window.location.href);
      const forceiis = url.searchParams.get("forceiis");
      const params = {};
      if (forceiis && forceiis === "true") {
        params.forceiis = true;
      } else {
        params.forceiis = false;
      }
      if (emrPatientId && emrPatientId.length > 5) {
        params.emr_patient_id = emrPatientId;
      } else {
        params.firstName = firstName;
        params.lastName = lastName;
        params.dob = dob;
        params.zipCode = zipCode;
        params.cellPhone = cellPhone;
      }
      const { data } = await axiosClient.get(apiUrl, { params });
      let uid = emrPatientId;
      if (!emrPatientId || emrPatientId.length < 1) {
        uid = data?.userId;
      }
      setAltQRCodeURL(`/api/s/console/patient/immunizations/qrcode/${uid}`);
      setResponse(data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Problem searching for immunization records: ${error}`);
      app.addError(`Problem searching for immunization records: ${error}`);
      setIsLoading(false);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    if (
      (emrPatientId && emrPatientId.length > 5) ||
      (firstName && firstName.length > 0 && lastName && lastName.length > 1 && dob && zipCode)
    ) {
      await fetchData();
    } else {
      app.addError(
        "Please provide a patient MRN or patient demograhpics to search for immunization records",
      );
    }
  };

  const vaccines = response?.immunizations || [];

  return (
    <div className={classes.root}>
      <h2 className={classes.header}>Console - Digital Vaccine Card Lookup</h2>
      <Paper className={classes.paper}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <TextField
                id="emrPatientId"
                label="Emr Patient ID"
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                variant="outlined"
                className={classes.textField}
                value={emrPatientId}
                onChange={(e) => setEmrPatientId(e.target.value)}
              />
              <br />
              <br />
              <div>- OR -</div>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" component="fieldset">
                <Grid
                  container
                  spacing={8}
                  className={classes.root}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: 300 }}
                      id="patient-first-name"
                      value={firstName}
                      name="patient-first-name"
                      variant="outlined"
                      label="First Name*"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: 300 }}
                      id="patient-last-name"
                      value={lastName.lastName}
                      name="patient-last-name"
                      error={!!lastName.error}
                      label="Last Name*"
                      variant="outlined"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: 300 }}
                      id="dob"
                      type="date"
                      className={classes.textField}
                      variant="outlined"
                      error={!!dob.error}
                      label="Date of birth*"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={dob.dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: 300 }}
                      error={!!zipCode.error}
                      id="zip-code"
                      value={zipCode.zipCode}
                      name="zip code"
                      variant="outlined"
                      label="Zip Code*"
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: 300 }}
                      id="cell-phone"
                      error={!!cellPhone.error}
                      value={cellPhone.number}
                      name="cell-phone"
                      variant="outlined"
                      label="Cell Phone (optional)"
                      onChange={(e) => handleChangeCellPhone(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" type="button" onClick={handleSearch}>
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {vaccines && !isLoading ? (
        <ProofOfVaccination vaccines={vaccines} showQR altQRCodeURL={altQRCodeURL} />
      ) : (
        <div>{isLoading && <Loader />}</div>
      )}
      <br />
      <br />
    </div>
  );
}
