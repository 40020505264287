import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { isRNApp } from "./RNWebView";
import useTranslation from "hooks/useTranslation";

interface Props {
  children: JSX.Element | null;
  orientation: "landscape" | "portrait";
  isMobile?: boolean;
}

export const LockOrientation = ({ children, orientation, isMobile }: Props) => {
  const {
    web: {
      common: { rotateDeviceToLandscape, rotateDeviceToPortrait },
    },
  } = useTranslation();

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches,
  );

  const handleOrientationChange = (event) => {
    setIsPortrait(event.matches);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    mediaQuery.addEventListener("change", handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  if (isRNApp || !isMobile) {
    return children;
  }

  if (orientation === "landscape" && !isPortrait) {
    return children;
  }

  if (orientation === "portrait" && isPortrait) {
    return children;
  }
  return (
    <Box display="flex" alignItems="center" justifyContent="center" marginTop={10}>
      <Typography variant="h4">
        {isPortrait ? rotateDeviceToLandscape : rotateDeviceToPortrait}
      </Typography>
    </Box>
  );
};
