import React from "react";
import { MobileNavigationLink } from "../MobileNavigationLink";
import { makeStyles } from "tss-react/mui";
import BannerButton from "../../../components/Mobile/BannerButton/BannerButton";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import CONSTANTS from "../constants";
import useTranslation from "../../../hooks/useTranslation";
import AllLocationsIcon from "../../../static/svg/mobile/alllocations.svg";
import FindDoctorIcon from "../../../static/svg/mobile/finddoctor.svg";
import HospitalIcon from "../../../static/svg/mobile/hospital.svg";
import PatientVisitorIcon from "../../../static/svg/mobile/patientvisitor.svg";
import UrgentCareIcon from "../../../static/svg/mobile/urgentcare.svg";

const useStyles = makeStyles()(() => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.6em",
    paddingTop: "1.5em",
    zIndex: 2,
  },
}));

const Locations = () => {
  const { classes } = useStyles();
  const { mobile } = useTranslation();
  const { FindHospital, FindUrgentCare, PatientVisitorServices, AllLocations, PhysicianPractices } =
    mobile;

  const locationLinks: Array<MobileNavigationLink> = [
    {
      destination: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.FINDHOSPITAL}`,
      icon: HospitalIcon,
      title: FindHospital,
      shouldPushNavigation: true,
      testId: "locations-view-find-hospital-button",
    },
    {
      destination: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.FINDURGENTCARE}`,
      icon: UrgentCareIcon,
      title: FindUrgentCare,
      shouldPushNavigation: true,
      testId: "locations-view-find-urgent-care-button",
    },
    {
      destination:
        "https://www.atlantichealth.org/search-results.html#tab=location-tab&f[loctype]=Physician%20Practice]",
      title: PhysicianPractices,
      icon: FindDoctorIcon,
      testId: "locations-physician-practices-button",
      shouldPushNavigation: false,
    },
    {
      destination: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.PATIENTVISITOR}`,
      icon: PatientVisitorIcon,
      title: PatientVisitorServices,
      shouldPushNavigation: true,
      testId: "locations-view-patient-visitor-button",
    },
    {
      destination: "https://www.atlantichealth.org/locations.html",
      icon: AllLocationsIcon,
      title: AllLocations,
      shouldPushNavigation: false,
      testId: "locations-view-all-locations-button",
    },
  ];

  return (
    <PageContainer>
      <div className={classes.buttonContainer}>
        {locationLinks.map(({ title, shouldPushNavigation, icon, destination, testId }) => (
          <BannerButton
            key={title}
            title={title}
            border="orange"
            showRightArrow
            shouldPushNavigation={shouldPushNavigation}
            navigateTo={destination as string}
            navigateFrom={CONSTANTS.LOCATIONS}
            icon={icon}
            testId={testId}
          />
        ))}
      </div>
    </PageContainer>
  );
};
export default Locations;
