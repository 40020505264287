import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

interface SpecimenDeleteReasonsDataResponse {
  data: [
    {
      value: string;
      label: string;
    },
  ];
}
export const useGetSpecimenDeleteReasons = () => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchQuery = (): Promise<SpecimenDeleteReasonsDataResponse> =>
    axiosClient.get(API_ENDPOINTS.specimenTrackingDeleteReason);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.specimenTrackingDeleteReason],
    queryFn: fetchQuery,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  return {
    data: data?.data,
    refetch,
    isLoading,
    isFetching,
  };
};
