import React from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";
import GenericEnhancedTable, { defaultGridConfig } from "../components/GenericEnhancedTable";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { exportCSV } from "util/util_functions";
import "../App.css";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  cardMain: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "14pt",
    textDecoration: "none",
  },
  backgroundSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
  topGrid: {
    flexGrow: 1,
    margin: "auto",
    width: "80%",
  },
}));

export default function ReferralListView() {
  const { classes } = useStyles();
  const { addError } = useApp();
  const listSelection = "Available";
  defaultGridConfig.allowDelete = false;
  defaultGridConfig.allowEdit = false;
  defaultGridConfig.showActionColumn = false;

  const colDefs = [
    {
      id: "source",
      numeric: false,
      disablePadding: true,
      label: "Source",
      searchable: true,
      sortable: true,
    },
    {
      id: "specialist",
      numeric: false,
      disablePadding: false,
      label: "Specialist",
      searchable: true,
      sortable: true,
    },
    {
      id: "diagnosis_code",
      numeric: false,
      disablePadding: false,
      label: "Reason for Visit",
      searchable: false,
      sortable: true,
    },
    {
      id: "cell_phone",
      numeric: false,
      disablePadding: false,
      label: "Cell Phone",
      searchable: true,
      sortable: true,
    },
    {
      id: "emr_mrn",
      numeric: false,
      disablePadding: false,
      label: "MRN",
      searchable: true,
      sortable: true,
    },
    {
      id: "zip_code",
      numeric: false,
      disablePadding: false,
      label: "Zip Code",
      searchable: false,
      sortable: true,
    },
    {
      id: "city",
      numeric: false,
      disablePadding: false,
      label: "City",
      searchable: false,
      sortable: true,
    },
    {
      id: "first_name",
      numeric: false,
      disablePadding: true,
      label: "First Name",
      searchable: false,
      sortable: true,
    },
    {
      id: "last_name",
      numeric: false,
      disablePadding: true,
      label: "Last Name",
      searchable: true,
      sortable: true,
    },
    {
      id: "outbound_clicked_dttm",
      numeric: false,
      isDate: true,
      disablePadding: false,
      label: "Outbound Clicked On",
      searchable: false,
      sortable: true,
    },
    {
      id: "dob",
      numeric: false,
      isDate: true,
      disablePadding: false,
      label: "DOB",
      searchable: false,
      sortable: true,
    },
    {
      id: "urgency",
      numeric: false,
      disablePadding: false,
      label: "Urgency",
      searchable: false,
      sortable: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      searchable: false,
      sortable: true,
    },
    {
      id: "insurance_company",
      numeric: false,
      disablePadding: false,
      label: "Ins Company",
      searchable: false,
      sortable: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      searchable: true,
      sortable: true,
    },
    {
      id: "address_1",
      numeric: false,
      disablePadding: false,
      label: "Address line 1",
      searchable: false,
      sortable: true,
    },
    {
      id: "address_2",
      numeric: false,
      disablePadding: false,
      label: "Address line 2",
      searchable: false,
      sortable: true,
    },
    {
      id: "state",
      numeric: false,
      disablePadding: false,
      label: "State",
      searchable: false,
      sortable: true,
    },
    {
      id: "note",
      numeric: false,
      disablePadding: false,
      label: "note",
      searchable: false,
      sortable: true,
    },
    {
      id: "agent_engaged_dttm",
      numeric: false,
      isDate: true,
      disablePadding: false,
      label: "Agent Engaged On",
      searchable: false,
      sortable: true,
    },
    {
      id: "appointment_dttm",
      numeric: false,
      isDate: true,
      disablePadding: false,
      label: "Appointment On",
      searchable: false,
      sortable: true,
    },
    {
      id: "created_at",
      numeric: false,
      isDate: true,
      disablePadding: false,
      label: "Created On",
      searchable: false,
      sortable: true,
    },
  ];

  const exportDataToCSV = async () => {
    try {
      const reqUrl = `/api/s/console/referral/list/csv`;
      const { data } = await axiosClient.get(reqUrl);
      exportCSV(data, "referral-list-report");
    } catch (error) {
      addError("Something went wrong. Please contact support.");
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        container
        spacing={8}
        className={classes.topGrid}
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <div style={{ marginTop: "30px" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveAltIcon />}
            onClick={() => exportDataToCSV()}
          >
            Export (CSV)
          </Button>
        </div>
      </Grid>

      <Grid
        container
        spacing={8}
        className={classes.root}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <GenericEnhancedTable
            gridConfig={defaultGridConfig}
            gridType={listSelection}
            tableTitle="Referral List"
            colDefs={colDefs}
            url="/api/s/console/referral/list"
            orderBy="created_at"
            order="desc"
          />
        </Grid>
      </Grid>
      <br />
    </form>
  );
}
