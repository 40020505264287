import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { useApp } from "../../util/AppContext";
import { timeWindowOptions } from "./utils";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useStyles } from "../../styles/Modal/ModalForm.styles";

const SurveyConfigurationForm = ({ record, mode, handleClose }) => {
  const app = useApp();
  const { classes } = useStyles();

  const [retrievingDropdownOptions, setRetrievingDropdownOptions] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<{
    surveyNames?: Array<string>;
    departments?: Array<{ id: number; name: string }>;
    forms?: Array<{ id: number; name: string }>;
  }>({});

  const [error, setError] = useState({
    intervalMinutes: false,
    sendAfterMinutes: false,
    surveyName: false,
    formId: false,
  });
  const biggerThanZeroErrorText = "Must be a number bigger than 0.";

  const onSaveClicked = async ({
    surveyName,
    formId,
    departmentId,
    intervalMinutes,
    actionStartRunning,
    actionEndsRunning,
    sendAfterMinutes,
    enabled,
  }) => {
    const intervalMinutesError = intervalMinutes ? parseInt(intervalMinutes, 10) <= 0 : false;
    const sendAfterMinutesError = sendAfterMinutes ? parseInt(sendAfterMinutes, 10) <= 0 : false;
    setError({
      ...error,
      intervalMinutes: intervalMinutesError,
      sendAfterMinutes: sendAfterMinutesError,
      surveyName: !surveyName,
    });

    const isError = intervalMinutesError || sendAfterMinutesError || !surveyName;

    if (!isError) {
      try {
        if (mode === "add") {
          const surveyConfigForPost = {
            actionEndsRunning: actionEndsRunning || null,
            actionStartRunning: actionStartRunning || null,
            automationParams: {
              only_send_after: sendAfterMinutes ? parseInt(sendAfterMinutes, 10) * 60 : null,
            },
            formId: formId || null,
            intervalMinutes: intervalMinutes ? parseInt(intervalMinutes, 10) * 60 : null,
            surveyName: surveyName.replace("'", ""),
            departmentId: departmentId || null,
            enabled,
          };
          const url = API_ENDPOINTS.survey_config;
          await axiosClient.post(url, surveyConfigForPost);
        } else if (mode === "edit") {
          const surveyConfigForPut = {
            actionEndsRunning: actionEndsRunning || null,
            actionStartRunning: actionStartRunning || null,
            automationParams: {
              only_send_after: sendAfterMinutes ? parseInt(sendAfterMinutes, 10) * 60 : null,
            },
            formId: formId || null,
            intervalMinutes: intervalMinutes ? parseInt(intervalMinutes, 10) * 60 : null,
            surveyName,
            departmentId: departmentId || null,
            enabled,
          };
          const url = `${API_ENDPOINTS.survey_config}/${record.id}`;
          await axiosClient.put(url, surveyConfigForPut);
        } else {
          throw new Error("Invalid Mode provided");
        }
        handleClose();
        app.addInfoMsg("Survey Configuration has been saved successfully.");
        // eslint-disable-next-line
      } catch (error: any) {
        app.addError(error.response?.data?.description || error.response?.data?.error);
      }
    }
  };

  const {
    values: {
      surveyName,
      formId,
      departmentId,
      intervalMinutes,
      actionStartRunning,
      actionEndsRunning,
      sendAfterMinutes,
      enabled,
    },
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      surveyName: record.surveyName || "",
      formId: record.formId || "",
      intervalMinutes: record.intervalMinutes / 60 || "",
      actionStartRunning: record.actionStartRunning || "",
      actionEndsRunning: record.actionEndsRunning || "",
      sendAfterMinutes: record.sendAfterMinutes / 60 || "",
      departmentId: record.departmentId || "",
      enabled: record.enabled,
    },
    onSubmit: onSaveClicked,
  });

  const onChangeIntervalMinutes = useCallback(
    (event) => {
      const { value } = event.target;
      const regEx = /[^\d]/g;
      setFieldValue("intervalMinutes", value.replace(regEx, ""));
    },
    [setFieldValue],
  );

  const onChangeSendAfterMinutes = useCallback(
    (event) => {
      const { value } = event.target;
      const regEx = /[^\d]/g;
      setFieldValue("sendAfterMinutes", value.replace(regEx, ""));
    },
    [setFieldValue],
  );

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const url = API_ENDPOINTS.survey_config_dropdown_options;
      setRetrievingDropdownOptions(true);
      try {
        const response = await axiosClient.get(url);
        if (response.status === 200 && response.data) {
          setDropdownOptions(response.data);
        } else {
          throw new Error();
        }
      } catch (e) {
        app.addError("There was an error while fetching the options. Try again later.");
        handleClose();
      }
      setRetrievingDropdownOptions(false);
    };
    fetchDropdownOptions();
    // eslint-disable-next-line
  }, []);

  if (retrievingDropdownOptions) {
    return <CircularProgress className={classes.circularProgress} />;
  }

  return (
    <>
      <FormControl variant="outlined" fullWidth className={classes.fullWidth}>
        <InputLabel id="survey-name-label">Survey Name *</InputLabel>
        <Select
          variant="standard"
          data-testid="survey-name-select"
          data-cy="survey-name-select"
          fullWidth
          labelId="survey-name-label"
          label="Survey Name *"
          id="survey-name-dropdown"
          value={surveyName}
          onChange={(event) => {
            setFieldValue("surveyName", event.target.value);
            setError({ ...error, surveyName: false });
          }}
          error={error.surveyName}
        >
          {dropdownOptions?.surveyNames?.map((surveyName) => (
            <MenuItem key={surveyName} value={surveyName}>
              {surveyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth className={classes.fullWidth}>
        <InputLabel id="form-name-label">Form Name *</InputLabel>
        <Select
          variant="standard"
          data-testid="form-name-select"
          data-cy="form-name-select"
          fullWidth
          labelId="form-name-label"
          label="Form Name *"
          id="form-name-dropdown"
          value={formId}
          onChange={(event) => {
            setFieldValue("formId", event.target.value);
          }}
        >
          {dropdownOptions?.forms?.map((form) => (
            <MenuItem key={form.id} value={form.id}>
              {form.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth className={classes.fullWidth}>
        <InputLabel id="department-name-label">Department Name</InputLabel>
        <Select
          variant="standard"
          data-testid="department-name-select"
          data-cy="department-name-select"
          fullWidth
          labelId="department-name-label"
          label="Department Name"
          id="department-name-dropdown"
          value={departmentId}
          onChange={(event) => {
            setFieldValue("departmentId", event.target.value);
          }}
        >
          {dropdownOptions?.departments?.map((department) => (
            <MenuItem key={department.id} value={department.id}>
              {department.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="body2">* These fields are required</Typography>
      <Typography className={classes.timeWindowTitle}>SMS Information</Typography>
      <TextField
        classes={{ root: classes.fullWidth }}
        id="intervalMinutes"
        name="intervalMinutes"
        size="small"
        value={intervalMinutes}
        variant="outlined"
        label="Send SMS every (hours)"
        onChange={onChangeIntervalMinutes}
        error={error.intervalMinutes}
        helperText={error.intervalMinutes && biggerThanZeroErrorText}
        data-cy="intervalMinutesInputField"
      />
      <TextField
        classes={{ root: classes.fullWidth }}
        id="sendAfterMinutes"
        name="sendAfterMinutes"
        size="small"
        value={sendAfterMinutes}
        variant="outlined"
        label="Send after On-Call (hours)"
        onChange={onChangeSendAfterMinutes}
        error={error.sendAfterMinutes}
        helperText={error.sendAfterMinutes && biggerThanZeroErrorText}
        data-cy="sendAfterMinutesInputField"
      />
      <div className={classes.timeWindowContainer}>
        <FormControl variant="outlined" className={classes.timeWindowDropdown}>
          <InputLabel id="time-window-from-label">From</InputLabel>
          <Select
            variant="standard"
            data-testid="time-window-from-select"
            labelId="time-window-from-label"
            label="From"
            id="time-window-from-dropdown"
            value={actionStartRunning}
            onChange={(event) => {
              setFieldValue("actionStartRunning", event.target.value);
            }}
          >
            {timeWindowOptions.map((timeWindowObj) => (
              <MenuItem key={timeWindowObj.value} value={timeWindowObj.value}>
                {timeWindowObj.displayText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.timeWindowDropdown}>
          <InputLabel id="time-window-to-label">To</InputLabel>
          <Select
            variant="standard"
            data-testid="time-window-to-select"
            labelId="time-window-to-label"
            label="To"
            id="time-window-to-dropdown"
            value={actionEndsRunning}
            onChange={(event) => {
              setFieldValue("actionEndsRunning", event.target.value);
            }}
          >
            {timeWindowOptions.map((timeWindowObj) => (
              <MenuItem key={timeWindowObj.value} value={timeWindowObj.value}>
                {timeWindowObj.displayText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <FormControl variant="outlined" fullWidth className={classes.fullWidth}>
        <FormControlLabel
          control={
            <Switch
              checked={enabled}
              onChange={(event) => setFieldValue("enabled", event.target.checked)}
              name="enabled"
            />
          }
          label={enabled ? "Enabled" : "Disabled"}
        />
      </FormControl>
      <Button
        autoFocus
        onClick={() => handleSubmit()}
        type="submit"
        color="primary"
        variant="contained"
        className={classes.saveButton}
        data-cy="saveButton"
      >
        Save
      </Button>
    </>
  );
};

export default SurveyConfigurationForm;
