import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  media: {
    height: 140,
    width: "100%",
    margin: "auto",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  chatCard: {
    height: "100%",
    borderRadius: 10,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Roboto Sans, sans-serif",
  },
  descContainer: {
    padding: "0 16px",
  },
  descrIcon: {
    marginRight: "5px",
  },
  imgContainer: {
    paddingBottom: "0px !important",
  },
  title: {
    fontSize: "22px",
    lineHeight: "22px",
    margin: "12px 0px",
    fontWeight: 500,
  },
}));

export default useStyles;
