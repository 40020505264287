import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import io, { Socket } from "socket.io-client";
import { Subtitle } from "components/Video/types";
import useLocalAudioToggle from "../useLocalAudioToggle/useLocalAudioToggle";
import { useApp } from "util/AppContext";
import { getApiHost } from "util/api_helper";

const useCaptions = () => {
  const [showCaptions, setShowCaptions] = useState<boolean>(false);
  const [isCaptionDisabled, setIsCaptionDisabled] = useState<boolean>(false);
  const [subtitles, setSubtitles] = useState<Subtitle[]>([]);
  const [audioContext, setAudioContext] = useState<AudioContext>();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [socketIO, setSocketIO] = useState<Socket | null>(null);
  const [start, setStart] = useState<boolean>(false);
  const { virtual_session_id: virtualSessionId } = useParams<{ virtual_session_id: string }>();
  const [isEnabled] = useLocalAudioToggle();
  const { addError } = useApp();
  const captionsError = "Problem with closed caption service. Try to reload page.";
  const audioError = "Problem with the audio service. Try to reload page.";

  const addOrUpdateSubtitles = (event) => {
    const data = JSON.parse(event);
    const {
      transcript,
      user: { first_name: firstName, last_name: lastName, id: userId },
      is_final: isFinal,
    } = data;
    const userName = !firstName && !lastName ? "Unnamed User" : `${firstName} ${lastName}`;
    const receivedSubtitle = {
      speakerName: userName,
      speakerId: userId,
      message: transcript,
      id: userId,
    };

    setSubtitles((prev) => {
      if (prev.length === 0) {
        return [receivedSubtitle];
      }

      const prevCaption = prev[prev.length - 1];
      const { speakerName, message, speakerId, id } = receivedSubtitle;
      if (prevCaption.speakerId === speakerId) {
        prevCaption.speakerName = speakerName;
        prevCaption.message = message;
        prevCaption.speakerId = speakerId;
        prevCaption.id = id;
      }

      if (isFinal) {
        if (prevCaption.speakerId !== speakerId) {
          return [...prev, receivedSubtitle];
        }
        return [...prev, { ...receivedSubtitle, message: "" }];
      }

      return [...prev];
    });
  };

  const onDisconnectClosedCaption = () => {
    if (socketIO?.connected) {
      socketIO?.off("connect");
      socketIO?.off("message");
      socketIO?.emit("leave", { room: parseFloat(virtualSessionId || "") });
      socketIO?.disconnect();
    }
  };

  const onDisconnectAudio = () => {
    if (socket?.connected) {
      socket?.emit(
        "stop",
        {
          event: "stop",
          room: parseFloat(virtualSessionId || ""),
        },
        () => {
          socket?.disconnect();
        },
      );

      audioContext?.close();
      setSocket(null);
    }
  };

  const onDisconnect = () => {
    onDisconnectClosedCaption();
    onDisconnectAudio();
    setShowCaptions(false);
  };

  window.addEventListener("beforeunload", () => {
    onDisconnect();
  });

  const createAudioStream = useCallback(async () => {
    if (socket) {
      try {
        await navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(async (stream) => {
            const { AudioContext } = window;
            const context = new AudioContext({ sampleRate: 16000 });
            setAudioContext(context);
            const source = context.createMediaStreamSource(stream);
            await context.audioWorklet.addModule(
              `${process.env.PUBLIC_URL}/worklet/speechToTextWorklet.js`,
            );
            const recorder = new AudioWorkletNode(context, "speechToTextWorklet", {
              outputChannelCount: [1],
            });

            source.connect(recorder).connect(context.destination);
            recorder.port.onmessage = ({ data }) => {
              if (socket.connected) {
                socket.emit("message", data.buffer);
              }
            };
          });
      } catch (e) {
        setShowCaptions(false);
        setIsCaptionDisabled(true);
        addError(audioError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    // Did not add the check for the feature flag here because we are already checking it in MenuBar
    if (showCaptions) {
      try {
        const opts = {
          withCredentials: true,
          transports: ["websocket"],
        };
        const socketIo = io(`${getApiHost().replace(/https?/gi, "wss")}/captions`, opts);
        setSocketIO(socketIo);
        socketIo.on("connect", () => {
          socketIo.emit("join", { room: parseFloat(virtualSessionId || "") });
        });
        socketIo.on("message", (event) => {
          addOrUpdateSubtitles(event);
        });
      } catch (error) {
        addError(captionsError);
      }
    } else {
      onDisconnectClosedCaption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualSessionId, showCaptions]);

  useEffect(() => {
    if (isEnabled && showCaptions) {
      try {
        const opts = {
          withCredentials: true,
          transports: ["websocket"],
        };
        const socket = io(`${getApiHost().replace(/https?/gi, "wss")}/audio-stream`, opts);
        setSocket(socket);
        socket.on("connect", () => {
          socket.emit("start", {
            start: { mediaFormat: { encoding: "audio/linear16", sampleRate: 16000 } },
            room: parseFloat(virtualSessionId || ""),
          });
        });
        socket.on("error", () => {
          onDisconnectAudio();
          setShowCaptions(false);
          setIsCaptionDisabled(true);
          addError(audioError);
        });
        socket.on("start", () => {
          setStart(true);
          console.log("Starting...");
        });
      } catch (e) {
        onDisconnectAudio();
        setShowCaptions(false);
        setIsCaptionDisabled(true);
        addError(audioError);
      }
    } else {
      onDisconnectAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, showCaptions]);

  useEffect(() => {
    if (isEnabled && socket && start) {
      createAudioStream();
    }
  }, [isEnabled, createAudioStream, socket, start]);

  return {
    showCaptions,
    setShowCaptions,
    subtitles,
    isCaptionDisabled,
    onDisconnect,
  };
};

export default useCaptions;
