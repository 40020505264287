import React from "react";
import { Typography } from "@mui/material";
import { MobileNavigationLink } from "../MobileNavigationLink";
import { makeStyles } from "tss-react/mui";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import CONSTANTS, { FIND_A_DOCTOR_URL, MY_CHART_VIRTUAL_URGENT_CARE } from "../constants";
import BannerButton from "components/Mobile/BannerButton/BannerButton";
import useTranslation from "../../../hooks/useTranslation";

const useStyles = makeStyles()(() => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5em",
    padding: "1.5em 1em",
    zIndex: 1,
  },
  rowTitle: {
    fontSize: "1.25em",
    textAlign: "left",
    lineHeight: "1",
    fontWeight: 500,
  },
  flexFill: {
    flex: "1",
  },
  allServicesLink: {
    fontSize: "1em",
    textDecoration: "underline",
    color: "#171a5b",
    fontWeight: 500,
  },
}));

const GetCare = () => {
  const { classes } = useStyles();
  const { mobile } = useTranslation();
  const {
    Services,
    FindDoctor,
    FindDoctorAptDesc,
    MakeAppointment,
    MakeAppointmentDesc,
    Walkins,
    WalkinsDesc,
    VirtualCare,
    VirtualCareDesc,
    UrgentCare,
  } = mobile;

  const appointmentLinks: Array<MobileNavigationLink> = [
    {
      destination: FIND_A_DOCTOR_URL,
      title: FindDoctor,
      subtitle: FindDoctorAptDesc,
      shouldPushNavigation: false,
      testId: "get-care-view-find-doctor-button",
    },
    {
      destination: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.APPOINTMENTS}`,
      title: MakeAppointment,
      subtitle: MakeAppointmentDesc,
      shouldPushNavigation: true,
      testId: "get-care-view-make-appointment-button",
    },
  ];

  const getCareLinks: Array<MobileNavigationLink> = [
    {
      destination: `${CONSTANTS.mobileBaseUrl}${CONSTANTS.FINDURGENTCARE}`,
      title: Walkins,
      subtitle: WalkinsDesc,
      shouldPushNavigation: true,
      testId: "get-care-view-walk-ins-button",
    },
    {
      destination: MY_CHART_VIRTUAL_URGENT_CARE,
      title: VirtualCare,
      subtitle: VirtualCareDesc,
      shouldPushNavigation: false,
      testId: "get-care-view-virtual-urgent-care-button",
    },
  ];

  return (
    <PageContainer>
      <div className={classes.rowContainer}>
        <Typography className={classes.rowTitle}>{Services}</Typography>
        <div className={classes.flexFill} />
      </div>
      {appointmentLinks.map(({ title, subtitle, destination, shouldPushNavigation, testId }) => (
        <BannerButton
          key={title}
          title={title}
          subtitle={subtitle}
          border="orange"
          showRightArrow
          shouldPushNavigation={shouldPushNavigation}
          navigateTo={destination as string}
          navigateFrom={CONSTANTS.CARE}
          testId={testId}
        />
      ))}
      <div className={classes.rowContainer}>
        <Typography className={classes.rowTitle}>{UrgentCare}</Typography>
      </div>
      {getCareLinks.map(({ title, subtitle, destination, shouldPushNavigation, testId }) => (
        <BannerButton
          key={title}
          title={title}
          subtitle={subtitle}
          border="blue"
          showRightArrow
          shouldPushNavigation={shouldPushNavigation}
          navigateTo={destination as string}
          navigateFrom={CONSTANTS.CARE}
          testId={testId}
        />
      ))}
    </PageContainer>
  );
};
export default GetCare;
