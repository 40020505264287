import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
import useStyles from "./style";
import error400 from "static/svg/error/400.svg";
import error401 from "static/svg/error/401.svg";
import error403 from "static/svg/error/403.svg";
import error404 from "static/svg/error/404.svg";
import error500 from "static/svg/error/500.svg";

interface TextType {
  title: string;
  description: string;
  description2?: string;
  button: string;
}

const HtmlError = () => {
  const error = useTranslation();
  const [text, setText] = useState<TextType | undefined>(undefined);
  const [imgSource, setImgSource] = useState<string | undefined>(undefined);
  const { classes } = useStyles();
  const location = useLocation();

  const getError = () => {
    const status = location.pathname.replace("/error/", "");
    const img = { 400: error400, 401: error401, 403: error403, 404: error404, 500: error500 };
    try {
      setText(error.web.errors[`error${status}`]);
      setImgSource(img[status]);
    } catch (e) {
      setText(error.web.errors[`error${404}`]);
      setImgSource(img[404]);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getError();
    navigate(
      {
        search: "",
      },
      { replace: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={classes.content}>
      {text && imgSource && (
        <>
          <img className={classes.img} src={imgSource} alt={location.pathname} />
          <h1 className={classes.h1}>{text.title}</h1>
          <p className={classes.descriptionText}>
            {text.description}
            {text.description2 && <br /> && text.description2}
          </p>
          <Link to="/">
            <button
              type="button"
              className={[classes.btnPrimary, classes.text, classes.btnText].join(" ")}
            >
              {text.button}
            </button>
          </Link>
        </>
      )}
    </section>
  );
};

export default HtmlError;
