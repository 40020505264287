import { Features } from "util/constants/config";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { hasFeature } from "util/hasFeature";
import { SpecimenAddResponse } from "views/SpecimenTracking/types";

export const useAddSpecimenTrackScanned = () => {
  const isSpecimenTrackingTextractAdapterEnabled = hasFeature(
    Features.ENABLE_SPECIMEN_TRACKING_TEXTRACT_ADAPTER,
  );
  const addSpecimenTrackScanning = (data: FormData) => {
    const resp = axiosClient.post<SpecimenAddResponse>(
      isSpecimenTrackingTextractAdapterEnabled
        ? API_ENDPOINTS.specimenTrackingAddSpecimenWithAdapter
        : API_ENDPOINTS.specimenTrackingAddSpecimenScanner,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return resp;
  };

  return {
    addSpecimenTrackScanning,
  };
};
