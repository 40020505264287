import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import SpecimenTrackingLocationForm from "./SpecimenTrackingLocationForm";
import { useIsMobile } from "util/deviceUtils";
import { NewSpecimenLocationInfo } from "./types";
import { useStyles } from "../../styles/Modal/AddModal.styles";

const emptyRecord = {
  specimenTrackingLocationName: "",
  specimenTrackingLocationDescription: "",
  departmentId: undefined,
  finalDestination: "false",
};

const SpecimenTrackingLocationModal = ({ show = false, setShow, mode, record }) => {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(show);
  const [specimenLocationRecord, setSpecimenLocationRecord] =
    useState<NewSpecimenLocationInfo>(emptyRecord);

  const handleClose = () => {
    setShow(false);
    setSpecimenLocationRecord(emptyRecord);
  };

  const handleModalVisibility = () => {
    if (show) {
      setSpecimenLocationRecord({ ...record });
    } else {
      handleClose();
    }
    setOpen(show);
  };

  useEffect(() => {
    handleModalVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      classes={{ paperWidthMd: classes.modalDialog }}
      fullScreen={isMobile}
    >
      <MuiDialogTitle id="customized-dialog-title" className={classes.modalDialogTitle}>
        <Tabs value={0} aria-label="createVirtualSessionOptions">
          <Tab label={`${mode === "add" ? "Add" : "Edit"} Specimen tracking location`} />
        </Tabs>
        <IconButton
          data-cy="closeModalButton"
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          data-testid="close-add-modal-button"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers classes={{ root: classes.formContainer }}>
        <SpecimenTrackingLocationForm
          handleClose={handleClose}
          mode={mode}
          record={specimenLocationRecord}
        />
      </MuiDialogContent>
    </Dialog>
  );
};

export default SpecimenTrackingLocationModal;
