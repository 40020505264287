import React from "react";
import Chip from "@mui/material/Chip";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { FiltersProps, MessageFiltersArray } from "./types";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));
const Filters = ({ filter, handleChangeFilter }: FiltersProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {MessageFiltersArray.map((m) => (
        <Chip
          key={m}
          label={m.toUpperCase()}
          component="a"
          clickable
          color={m === filter ? "primary" : "default"}
          onClick={() => handleChangeFilter(m)}
        />
      ))}
    </div>
  );
};

export default Filters;
