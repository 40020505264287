import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import TeamMembersForm from "./TeamMembersForm";
import useStyles from "./TeamMembers.styles";

const AddTeamMemberDialog = ({ isOpen, handleClose, createTeamMember }) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(isOpen);

  useEffect(() => setOpen(isOpen), [isOpen]);

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialogContainer}>
      <DialogTitle>Add New Contact</DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <TeamMembersForm createTeamMember={createTeamMember} />
      </DialogContent>
    </Dialog>
  );
};

export default AddTeamMemberDialog;
