import React, { Dispatch, FC, SetStateAction } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, CardActionArea, Grid, Typography } from "@mui/material";
import { AppointmentPurpose } from "components/FindADoctor/types";
import useStyles from "./BookingOnline.styles";

const PATIENT_RELATIONSHIP = {
  established: "Established",
  new: "New",
};

const OPTION = {
  followUP: "Follow Up",
  VirtualVisit: "Virtual Visit",
};

interface Props {
  fullName: string;
  videoVisit: boolean;
  appointmentPurpose: Array<AppointmentPurpose>;
  handleNext: () => void;
  number: string;
  setPatientRelationship: Dispatch<SetStateAction<string>>;
  patientRelationship: string;
  setPurpose: Dispatch<SetStateAction<string>>;
  purpose: string;
}

const AppointmentInfo: FC<Props> = ({
  fullName,
  videoVisit,
  appointmentPurpose,
  handleNext,
  number,
  setPatientRelationship,
  patientRelationship,
  setPurpose,
  purpose,
}) => {
  const { classes, cx } = useStyles();

  const handelPrevVisit = (visit) => {
    setPurpose("");
    setPatientRelationship(visit);
  };

  const establishedPatient: Array<AppointmentPurpose> = appointmentPurpose?.filter(
    (item) => item.patient_relationship === "established",
  );
  const newPatient: Array<AppointmentPurpose> = appointmentPurpose?.filter(
    (item) => item.patient_relationship === "new",
  );

  return (
    <>
      <Typography className={classes.title}>{`Have you seen ${fullName}, MD before?`}</Typography>
      <Grid container justifyContent="center">
        <Button
          className={cx({
            [classes.buttonActive]: patientRelationship === PATIENT_RELATIONSHIP.established,
            [classes.button]: patientRelationship !== PATIENT_RELATIONSHIP.established,
          })}
          onClick={() => handelPrevVisit(PATIENT_RELATIONSHIP.established)}
        >
          YES
        </Button>
        <Button
          className={cx({
            [classes.buttonActive]: patientRelationship === PATIENT_RELATIONSHIP.new,
            [classes.button]: patientRelationship !== PATIENT_RELATIONSHIP.new,
          })}
          onClick={() => handelPrevVisit(PATIENT_RELATIONSHIP.new)}
        >
          NO
        </Button>
      </Grid>
      <Grid className={classes.optionContainer}>
        {establishedPatient && patientRelationship === PATIENT_RELATIONSHIP.established && (
          <>
            <Typography className={classes.title}>
              What is the main reason for your visit?
            </Typography>
            {establishedPatient.map((item) => (
              <CardActionArea
                className={cx({
                  [classes.optionActive]: purpose === item.name,
                  [classes.option]: purpose !== item.name,
                })}
                onClick={() => setPurpose(item.name)}
                key={item.name}
              >
                <Typography
                  align="left"
                  className={cx({
                    [classes.optionTitleActive]: purpose === item.name,
                    [classes.optionTitle]: purpose !== item.name,
                  })}
                >
                  {item.name}
                </Typography>
              </CardActionArea>
            ))}
          </>
        )}

        {newPatient && patientRelationship === PATIENT_RELATIONSHIP.new && (
          <>
            <Typography className={classes.title}>
              What is the main reason for your visit?
            </Typography>
            {newPatient.map((item) => (
              <CardActionArea
                className={cx({
                  [classes.optionActive]: purpose === item.name,
                  [classes.option]: purpose !== item.name,
                })}
                onClick={() => setPurpose(item.name)}
                key={item.name}
              >
                <Typography
                  align="left"
                  className={cx({
                    [classes.optionTitleActive]: purpose === item.name,
                    [classes.optionTitle]: purpose !== item.name,
                  })}
                >
                  {item.name}
                </Typography>
              </CardActionArea>
            ))}
          </>
        )}

        {!newPatient?.length && (
          <Grid className={classes.attentionContainer}>
            <WarningIcon />
            <Typography data-testid="newPatient">
              Sorry! {fullName}, DO does not accept new patients via online booking. Please contact
              the office directly at {number} to book an appointment.
            </Typography>
          </Grid>
        )}
        {!videoVisit && purpose === OPTION.VirtualVisit && (
          <Grid className={classes.attentionContainer}>
            <WarningIcon />
            <Typography data-testid="videoVisit">
              Sorry! {fullName}, MD does not accept patients via online booking. Please contact the
              office directly at {number} to book an appointment.
            </Typography>
          </Grid>
        )}
        {purpose && purpose !== OPTION.VirtualVisit && (
          <Button onClick={handleNext} className={classes.nextButton}>
            Next
          </Button>
        )}
      </Grid>
    </>
  );
};

export default AppointmentInfo;
