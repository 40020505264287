import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { handleUpdateCellPhone } from "components/LoginWithAtlanticHealth/useUpdateCellPhone/useUpdateCellPhone";
import CellPhone from "components/shared/CellPhone";
import { PHONE_MAX_LENGTH } from "util/constants";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";

export default function CellPhoneDialoge() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const auth = useAuth();
  const app = useApp();
  const [cellPhone, setCellPhone] = useState("");
  const [msg, setMsg] = useState(
    "Please provide your cell phone number so we can verify it for security purposes.",
  );
  const [action, setAction] = useState("");
  const patientId = auth.user?.patientId;

  const handleCellPhoneUpdate = async () => {
    if (patientId) {
      await handleUpdateCellPhone({ cellPhone, patientId, app });
      setOpen(false);
    }
  };

  useEffect(() => {
    if (cellPhone) {
      handleCellPhoneUpdate({ cellPhone, patient_id: patientId, app });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkCellStatus = async () => {
      await auth.checkLogin(() => {
        if (
          auth?.user?.userStatus &&
          auth?.user?.userStatus !== "proxy_unconfirmed" &&
          auth?.user?.userStatus !== "proxy_confirmed"
        ) {
          if (
            auth?.user?.cellPhone &&
            auth?.user?.cellPhone?.length > 0 &&
            (auth?.user?.cellVerifiedAt === null || auth?.user?.cellVerifiedAt?.length < 1)
          ) {
            setCellPhone(auth.user.cellPhone);
            setMsg(
              "Please provide your cell phone number so we can verify it for security purposes.",
            );
            setAction("Verify");
            setOpen(true);
          } else if (auth?.user?.cellPhone === null || auth?.user?.cellPhone?.length < 1) {
            setMsg(
              "Please confirm your cell phone number so we can verify it for security purposes.",
            );
            setAction("Confirm");
            setOpen(true);
          }
        }
      }, true);
    };
    checkCellStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{msg}</DialogTitle>
        <DialogContent>
          <CellPhone setCellPhone={setCellPhone} cellPhone={cellPhone} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCellPhoneUpdate}
            data-cy="confirmCellPhoneBtn"
            fullWidth
            disabled={cellPhone.length !== PHONE_MAX_LENGTH}
          >
            {action} Cell Phone
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
