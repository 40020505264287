/* eslint-disable */
import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import ChatImg from "../static/images/chat-icon.png";
import CheckinImg from "../static/images/checkin-icon.png";
import FindADocImg from "../static/images/find-a-doc-icon.png";
import VideoVisitImg from "../static/images/video-visit-icon.png";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  header: {
    color: theme.palette.secondary.main,
  },
  cardMain: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  cardDesc: {
    color: theme.palette.primary.main,
    fontSize: "14pt",
    textDecoration: "none",
  },
}));

export default function GetCareView() {
  const { classes, cx } = useStyles();

  function MediaCard(props) {
    const { title, imgUrl, desc, actionUrl } = props;
    return (
      <Card className={classes.root}>
        <a href={actionUrl} target="_blank" rel="noreferrer">
          <CardActionArea>
            <CardMedia
              className={cx(classes.cardMain, classes.media)}
              image={imgUrl}
              title={title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
              <Typography className={classes.cardDesc} component="p">
                {desc}
              </Typography>
            </CardContent>
          </CardActionArea>
        </a>
      </Card>
    );
  }

  return (
    <div className={classes.root}>
      <h1 className={classes.header}>Atlantic Health Get Care Menu. How may we help you?</h1>
      <Grid
        container
        spacing={8}
        className={classes.root}
        alignItems="center"
        justifyContent="center"
      >
        <Grid container item xs={12} spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={4}>
            <MediaCard
              title="Instant Video Visits"
              imgUrl={VideoVisitImg}
              desc="Get a telehealth video visit now"
            />
          </Grid>
          <Grid item xs={4}>
            <MediaCard
              title="Find-A-Doctor"
              imgUrl={FindADocImg}
              desc="Search physicians and book an appointment"
              actionUrl="https://findadoctor.atlantichealth.org/search"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={4}>
            <MediaCard title="Chat Live" imgUrl={ChatImg} desc="Chat with a care navigator" />
          </Grid>
          <Grid item xs={4}>
            <MediaCard
              title="Walkin Urgent Care"
              imgUrl={CheckinImg}
              desc="Find a close by location and checkin"
              actionUrl="https://www.atlantichealth.org/conditions-treatments/urgent-care.html"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
