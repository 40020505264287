import React, { useState } from "react";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import PatientDetailsModal from "./PatientDetailsModal";
import { colDefs, gridConfig } from "./helpers";
import { makeStyles } from "tss-react/mui";
import GenericEnhancedTable from "components/GenericEnhancedTable";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { exportCSV } from "util/util_functions";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "40px 32px 32px",
    [theme.breakpoints.down("md")]: {
      padding: "24px",
    },
  },
  exportButton: {
    marginBottom: "30px",
    alignSelf: "flex-end",
  },
}));

export default function ParientList() {
  const { classes } = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [patientRecord, setPatientRecord] = useState(null);
  const app = useApp();

  const onRowClicked = (row) => {
    setPatientRecord(row);
    setShowModal(true);
  };

  const exportDataToCSV = async () => {
    try {
      const reqUrl = "/api/s/console/patient/list/csv";
      const { data } = await axiosClient.get(reqUrl);
      exportCSV(data, "patient-list-report");
    } catch (error) {
      app.addError("Something went wrong. Contact support");
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Button
        className={classes.exportButton}
        variant="contained"
        color="primary"
        startIcon={<SaveAltIcon />}
        onClick={() => exportDataToCSV()}
      >
        Export (CSV)
      </Button>
      <GenericEnhancedTable
        tableTitle="Patient List"
        gridConfig={gridConfig}
        colDefs={colDefs}
        url="/api/s/console/patient/list"
        orderBy="last_login_at"
        order="desc"
        defaultSearchBy="cell_phone"
        onEditHandler={onRowClicked}
        refreshSourceData={!showModal}
        hideCheckboxes
      />
      <PatientDetailsModal
        show={showModal}
        setShow={setShowModal}
        currentPatientRecord={patientRecord}
      />
    </form>
  );
}
