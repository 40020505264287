import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import useStyles from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/StaffNotes.styles";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { getLocalTime } from "util/util_functions";

type Note = {
  id: string | number;
  desc: string;
  create_dttm: string;
  creator_id: string;
};

interface Props {
  virtualSessionId: number;
  isEditable?: boolean;
}

const StaffNotes = ({ virtualSessionId, isEditable = true }: Props) => {
  const { classes } = useStyles();
  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState<Note[]>([]);
  const app = useApp();

  const loadNotes = async () => {
    try {
      const response = await axiosClient.get(
        `api/s/console/virtualvisit/loadnotes?virtual_session_id=${virtualSessionId}&order=desc`,
      );
      setNotes(response.data.notes);
    } catch (e) {
      app.addError("Warning: failed to load notes");
    }
  };

  useEffect(() => {
    loadNotes();
    // eslint-disable-next-line
  }, [virtualSessionId]);

  const handleSaveNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosClient.post("api/s/console/virtualvisit/savenote", {
        note: message,
        context_id: virtualSessionId,
        context: "VirtualSession",
      });
      const newNotes = [{ ...data.note }, ...notes];
      setNotes(newNotes);
      setMessage("");
    } catch (e) {
      app.addError("Warning: failed to save note");
    }
  };

  return (
    <div>
      <div className={classes.notesContainer}>
        {notes?.map((item: Note) => {
          const formattedDate = getLocalTime(item.create_dttm);
          return (
            <div className={classes.noteContainer} key={item.id}>
              <div className={classes.noteDesc}>{item.desc}</div>
              <div className={classes.noteDate}>{formattedDate}</div>
            </div>
          );
        })}
      </div>
      {isEditable && (
        <form>
          <TextField
            id="outlined-basic"
            label="Add Note"
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className={classes.btnContainer}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleSaveNote}
              type="submit"
            >
              Add Note
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default StaffNotes;
