import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: "64px",
    gap: "32px",

    [theme.breakpoints.down("md")]: {
      paddingTop: "32px",
      gap: 0,
    },
  },
  paper: {
    boxSizing: "border-box",
    maxWidth: "640px",
    width: "100%",
    padding: "32px 40px",

    [theme.breakpoints.down("md")]: {
      padding: "28px 22px",
      maxWidth: "560px",
    },
  },
  title: {
    margin: 0,
    marginBottom: "8px",
    fontSize: "32px",
    fontFamily: "unset",
    fontWeight: 600,
    color: theme.palette.primary.main,
    opacity: 0.87,
  },
  subtitle: {
    margin: 0,
    fontSize: "18px",
    fontWeight: 200,
  },
  dobContainer: {
    marginTop: "32px",
    gap: "16px",
  },
  gapContainer: {
    gap: "16px",
  },
  codeInput: {
    margin: "0 auto",
    marginBottom: "24px",
    "& input": {
      border: `solid 1px ${theme.palette.primary.main} !important`,
      color: theme.palette.primary.main,
      fontSize: "28px !important",
      fontFamily: "Alegreya Sans,Roboto,sans-serif !important",
      fontWeight: 600,
      caretColor: `${theme.palette.primary.main} !important`,
    },
  },
  dropzoneText: {
    fontSize: "18px",
    marginTop: "16px",
    marginBottom: 0,
  },
  spinnerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 300,
    width: "100%",
  },
  scanner: {
    objectFit: "cover",
    aspectRatio: "1/1",
  },
  fileDisplay: {
    maxWidth: "100%",
    maxHeight: "40vh",
    aspectRatio: "1/1 auto",
    objectFit: "cover",
  },
  mediaErrorNotice: {
    width: "100%",
    aspectRatio: "16/9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    backgroundColor: "#DDD",
    color: theme.palette.text.secondary,
  },
  faqLink: {
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "20px",
  },
}));
