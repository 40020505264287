import React from "react";
import { Close } from "@mui/icons-material";
import {
  IconButton,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
} from "@mui/material";
import { withStyles } from "tss-react/mui";
import { dialogTitleStyles } from "./styles";

export const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export const DialogActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

export const DialogTitle = withStyles(
  ({ children, classes, onClose, ...other }) => (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" classes={{ root: classes.title }}>
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <Close />
        </IconButton>
      )}
    </MuiDialogTitle>
  ),
  dialogTitleStyles,
);
