import { useState } from "react";
import { useQuery } from "react-query";
import { API_ENDPOINTS, AXIOS_OPTIONS, axiosClient, getApiHost } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

export interface Program {
  app_url: string | null;
  config_id: number | null;
  consumer_desc: string | null;
  id: number;
  image_url: string | null;
  name: string;
  phone_number: string | null;
  program_code: string;
  program_override_status: string | null;
  program_type: string;
  short_name: string;
  url_path: string;
}

interface ProgramsResponse {
  data: Program[];
}

export const useGetPrograms = () => {
  const [enabled, setEnabled] = useState(false);

  const fetchQuery = async (): Promise<ProgramsResponse> => {
    const apiHost = getApiHost();
    const url = `${apiHost}${API_ENDPOINTS.get_programs_list}`;
    const response = await axiosClient.get(url, AXIOS_OPTIONS);
    return response.data;
  };

  const { data, isFetching, refetch, isFetched } = useQuery({
    queryKey: [QUERY_KEYS.programs],
    queryFn: fetchQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled,
  });

  const getProgramsList = async (): Promise<ProgramsResponse | undefined> => {
    if (isFetched && data) {
      return data;
    }

    setEnabled(true);
    const result = await refetch();

    if (result.isSuccess) {
      return result.data;
    }

    return undefined;
  };

  return {
    getProgramsList,
    isFetching,
    data,
  };
};
