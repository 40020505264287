export const colDefs = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    searchable: false,
    sortable: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
    searchable: true,
    sortable: true,
  },
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "Username",
    searchable: true,
    sortable: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    searchable: true,
    sortable: true,
  },
  {
    id: "cell_phone",
    numeric: false,
    disablePadding: true,
    label: "Cell Phone",
    searchable: true,
    sortable: true,
  },
  {
    id: "user_type",
    numeric: false,
    disablePadding: true,
    label: "Role",
    searchable: true,
    sortable: true,
  },
  {
    id: "last_login_at",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "Last Login At",
    searchable: false,
    sortable: true,
  },
  {
    id: "user_status",
    numeric: false,
    disablePadding: true,
    label: "User Status",
    searchable: false,
    sortable: true,
  },
  {
    id: "failed_login_attempts",
    numeric: false,
    disablePadding: true,
    label: "User Locked",
    searchable: false,
    isCustomContent: true,
    func(val) {
      if (val >= 10) {
        return "<b>True</b>";
      }
      return "False";
    },
    sortable: true,
  },
  {
    id: "external_tracking_id",
    numeric: false,
    disablePadding: true,
    label: "External ID",
    searchable: false,
    sortable: true,
  },
  {
    id: "emr_staff_id",
    numeric: false,
    disablePadding: true,
    label: "Emr Staff Id",
    searchable: false,
    sortable: true,
  },
  {
    id: "emr_staff_username",
    numeric: false,
    disablePadding: true,
    label: "Emr Staff Username",
    searchable: false,
    sortable: true,
  },
];

export const mockRecord = {
  id: "",
  username: "",
  email: "",
  cell_phone: "",
  user_type: "",
  user_status: "",
};

export const mockEditRecord = {
  id: "2",
  username: "EditUser",
  email: "",
  cell_phone: "",
  user_type: "STAFF",
  user_status: "fully_registered",
};

export const mockRoleList = [
  {
    external_name_prd: "AWS.Connect.Prod.Users",
    id: 1,
    role_name: "STAFF",
  },
  {
    external_name_prd: "AWS.Connect.Prod.Admins",
    id: 2,
    role_name: "ADMIN",
  },
  {
    external_name_prd: "AWS.DFD.UrgentVirtualCare.Prod.Physician",
    id: 3,
    role_name: "PHYSICIAN",
  },
  {
    external_name_prd: "AWS.DFD.UrgentVirtualCare.Prod.RegistrationStaff",
    id: 4,
    role_name: "REGISTRATION_STAFF",
  },
  {
    external_name_prd: "AWS.DFD.UrgentVirtualCare.Prod.Nurse",
    id: 5,
    role_name: "NURSE",
  },
  {
    external_name_prd: "AWS.DFD.InPatient.Prod.InPatientMgr",
    id: 6,
    role_name: "IN_PATIENT_MGR",
  },
  {
    external_name_prd: "",
    id: 7,
    role_name: "CAMPAIGN_MGMT",
  },
  {
    external_name_prd: "AWS.DFD.JointReplacement.Prod.CareNavigator",
    id: 8,
    role_name: "CARE_NAVIGATOR",
  },
  {
    external_name_prd: "",
    id: 9,
    role_name: "PATIENT_SUPPORT",
  },
  {
    external_name_prd: "",
    id: 10,
    role_name: "STAFF_SURVEYS",
  },
];

export const mockProgramsList = [
  {
    app_url: null,
    config_id: null,
    consumer_desc: null,
    id: 1,
    image_url: null,
    name: "Dept of Transportation",
    phone_number: null,
    program_code: "DOT",
    program_override_status: null,
    program_type: "dot",
    short_name: "DOT Form",
    url_path: "/console/dot-form",
  },
  {
    app_url:
      "https://www.atlantichealth.org/conditions-treatments/urgent-care/virtual-urgent-care.html",
    config_id: 1,
    consumer_desc: "",
    id: 2,
    image_url: "",
    name: "Virtual Urgent Care",
    phone_number: "",
    program_code: "VUC",
    program_override_status: null,
    program_type: "virtual_sessions_queue",
    short_name: "Virtual Care",
    url_path: "/console/virtualvisit/waitingroom",
  },
  {
    app_url: "https://www.atlantichealth.org/conditions-treatments/behavioral-health.html",
    config_id: null,
    consumer_desc: "",
    id: 3,
    image_url: "",
    name: "Virtual Behavioral Health",
    phone_number: "",
    program_code: "behavioralondemand",
    program_override_status: null,
    program_type: "virtual_sessions_queue",
    short_name: "Virtual BH",
    url_path: "/console/virtualvisit/behavioralondemand",
  },
  {
    app_url: "",
    config_id: 2,
    consumer_desc: "",
    id: 4,
    image_url: "",
    name: "Virtual Neuro Triage",
    phone_number: "",
    program_code: "neuroondemand",
    program_override_status: null,
    program_type: "specialist_on_demand",
    short_name: "Virtual Neuro Triage",
    url_path: "/console/virtualvisit/neuroondemand",
  },
  {
    app_url: "",
    config_id: null,
    consumer_desc: "",
    id: 6,
    image_url: "",
    name: "Family Journal",
    phone_number: "",
    program_code: "FamilyJournal",
    program_override_status: null,
    program_type: "in_patient_communications",
    short_name: "Family Journal",
    url_path: "/console/family-journal/search",
  },
  {
    app_url: "https://www.atlantichealth.org/conditions-treatments/neuroscience.html",
    config_id: 3,
    consumer_desc: "",
    id: 8,
    image_url: "",
    name: "Neurology Inquiry",
    phone_number: "9085225914",
    program_code: "neuroconsult",
    program_override_status: null,
    program_type: "consultation",
    short_name: "Neuro Inquiry",
    url_path: "/console/consultation/neuroconsult",
  },
  {
    app_url: "https://www.atlantichealth.org/conditions-treatments/cancer-care.html",
    config_id: 4,
    consumer_desc: "",
    id: 9,
    image_url: "",
    name: "Oncology Inquiry",
    phone_number: "1112223333",
    program_code: "oncologyconsult",
    program_override_status: null,
    program_type: "consultation",
    short_name: "Onco Inquiry",
    url_path: "/console/consultation/oncologyconsult",
  },
  {
    app_url:
      "https://www.atlantichealth.org/conditions-treatments/orthopedics/total-joint-replacement.html",
    config_id: null,
    consumer_desc: "Treatment and surgical solutions",
    id: 10,
    image_url: "/body_leg.svg",
    name: "Joint Replacement",
    phone_number: "9739717883",
    program_code: "CJRKnee",
    program_override_status: null,
    program_type: "care_navigation",
    short_name: "Joint replacement",
    url_path: "/console/carenavigator/cjr/kneereplacement",
  },
  {
    app_url: "",
    config_id: null,
    consumer_desc: "",
    id: 11,
    image_url: "",
    name: "Adhoc Virtual Visits",
    phone_number: "",
    program_code: "adhoc",
    program_override_status: null,
    program_type: "adhoc_virtual",
    short_name: "",
    url_path: "/console/adhoc-virtual",
  },
  {
    app_url:
      "https://www.atlantichealth.org/conditions-treatments/visiting-nurse/home-health/remote-patient-monitoring.html",
    config_id: null,
    consumer_desc: "",
    id: 12,
    image_url: "",
    name: "COPD Monitoring Program",
    phone_number: "",
    program_code: "COPDRPM",
    program_override_status: null,
    program_type: "rpm",
    short_name: "COPD RPM",
    url_path: "/console/rpm/copd",
  },
  {
    app_url:
      "https://www.atlantichealth.org/conditions-treatments/visiting-nurse/home-health/remote-patient-monitoring.html",
    config_id: null,
    consumer_desc: "",
    id: 13,
    image_url: "",
    name: "Hypertension Monitoring Program",
    phone_number: "",
    program_code: "CHFRPM",
    program_override_status: null,
    program_type: "rpm",
    short_name: "Hypertension RPM",
    url_path: "/console/rpm/chf",
  },
  {
    app_url: "https://www.atlantichealth.org/conditions-treatments/diabetes-endocrinology.html",
    config_id: null,
    consumer_desc: "",
    id: 14,
    image_url: "",
    name: "Diabetes Monitoring Program",
    phone_number: "",
    program_code: "DiabetiesRPM",
    program_override_status: null,
    program_type: "rpm",
    short_name: "Diabetes RPM",
    url_path: "/console/rpm/diabetes",
  },
  {
    app_url: "https://www.atlantichealth.org/conditions-treatments/visiting-nurse/home-health.html",
    config_id: null,
    consumer_desc: "",
    id: 15,
    image_url: "",
    name: "Remote Monitoring Program",
    phone_number: "",
    program_code: "GeneralRPM",
    program_override_status: null,
    program_type: "rpm",
    short_name: "RPM",
    url_path: "/console/rpm/vitals",
  },
  {
    app_url: "https://www.atlantichealth.org/conditions-treatments/integrative-medicine.html",
    config_id: null,
    consumer_desc: "Welness Program",
    id: 16,
    image_url: "/body_leg.svg",
    name: "Employee Preventative Care",
    phone_number: "",
    program_code: "PreventC",
    program_override_status: null,
    program_type: "wellness_program",
    short_name: "Preventative Care",
    url_path: "/console/wellness/preventativecare",
  },
  {
    app_url: "",
    config_id: 5,
    consumer_desc: "",
    id: 17,
    image_url: "",
    name: "Virtual Immediate Care",
    phone_number: "",
    program_code: "VIC",
    program_override_status: null,
    program_type: "virtual_sessions_queue",
    short_name: "Immediate Care",
    url_path: "/console/immediate-care/waitingroom",
  },
  {
    app_url:
      "https://www.atlantichealth.org/conditions-treatments/rehabilitation-physical-therapy/physical-therapy.html",
    config_id: 6,
    consumer_desc: "",
    id: 18,
    image_url: "",
    name: "Physical Therapy & Rehab Inquiry",
    phone_number: "8558622778",
    program_code: "PTI",
    program_override_status: null,
    program_type: "consultation",
    short_name: "PT Inquiry",
    url_path: "/console/consultation/physical-therapy",
  },
  {
    app_url: "",
    config_id: null,
    consumer_desc: "",
    id: 19,
    image_url: "",
    name: "Specimen Tracking",
    phone_number: "",
    program_code: "ST",
    program_override_status: null,
    program_type: "specimen_tracking",
    short_name: "Specimen Tracking",
    url_path: "/console/specimen-tracking",
  },
];

export const mockUserData = [
  {
    addr_str_1: null,
    addr_str_2: null,
    cell_phone: "2025550131",
    cell_verified_at: null,
    city: "Edison",
    dob: "09/09/1999",
    email: "staffsurveys@ahs.com",
    emr_fhir_id: null,
    emr_mrn: null,
    emr_patient_id: null,
    emr_staff_id: null,
    emr_staff_username: null,
    external_tracking_id: null,
    first_name: "Surveyor",
    gender: null,
    id: 4,
    last_login_at: null,
    last_name: "Staff",
    state: "NJ",
    user_status: "user_lite",
    user_type: "STAFF_SURVEYS",
    username: null,
    zip: "08818",
  },
];
