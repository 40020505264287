import React from "react";
import { Carousel } from "react-responsive-carousel";
import { AddAPhoto } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";

interface Props {
  specimenLabelImage: string;
  specimenSecondImage: string;
  setShowModal: (arg: boolean) => void;
}

const SpecimenImagesCarousel = ({
  specimenLabelImage,
  specimenSecondImage,
  setShowModal,
}: Props) => {
  const { classes } = useSpecimenStyles();

  return (
    <div style={{ width: "100%" }} data-cy="specimenImageHistory">
      {specimenLabelImage ? (
        <Carousel
          centerMode
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          className={classes.carouselSpecimensHistory}
        >
          <img
            src={specimenLabelImage}
            alt="Specimen"
            style={{ width: "100%" }}
            data-testid="specimen-label-image"
          />

          {specimenSecondImage ? (
            <img
              src={specimenSecondImage}
              alt="Specimen"
              style={{ width: "100%" }}
              data-testid="specimen-second-image"
            />
          ) : (
            <button
              type="button"
              className={classes.addPhotoButton}
              onClick={() => setShowModal(true)}
              data-testid="add-specimen-photo"
            >
              <AddAPhoto fontSize="large" />
              <Typography variant="h6">Add Specimen Image</Typography>
            </button>
          )}
        </Carousel>
      ) : (
        <div style={{ width: "100%" }}>
          {specimenSecondImage ? (
            <img
              src={specimenSecondImage}
              alt="Specimen"
              style={{ width: "100%" }}
              data-testid="specimen-second-image"
            />
          ) : (
            <button
              type="button"
              className={classes.addPhotoButton}
              onClick={() => setShowModal(true)}
              data-testid="add-specimen-photo"
            >
              <AddAPhoto fontSize="large" />
              <Typography variant="h6">Add Specimen Image</Typography>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SpecimenImagesCarousel;
