/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, FormControl } from "@mui/material";
import TextField from "@mui/material/TextField/TextField";
import { makeStyles } from "tss-react/mui";
import Loader from "../components/Loader";
import { useApp } from "../util/AppContext";
import { axiosClient } from "util/api_helper";

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    boxSizing: "border-box",
    width: "100%",
    height: "42px",
    marginTop: "20px",
    textTransform: "uppercase",
  },
  formControl: {
    minWidth: 320,
  },
  mainTitle: {
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "36px",
    textAlign: "center",
    letterSpacing: "0.25px",
    maxWidth: "608px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      fontSize: "24px",
    },
  },
  subtitle: {
    margin: "40px 0px 20px 0px",
    fontSize: "16px",
    lineHeight: "26px",
    textAlign: "center",
    letterSpacing: "0.25px",
  },
  operHoursTitle: {
    fontSize: "22px",
    margin: "0px 0px 20px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  callTitle: {
    fontSize: "22px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "608px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
}));

export default function OffHoursCheck() {
  const navigate = useNavigate();
  const location = useLocation();
  const app = useApp();
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [showOffHoursMsg, setShowOffHoursMsg] = useState(false);
  const [cellPhone, setCellPhone] = useState("");
  const redirectPath = new URLSearchParams(location.search).get("r");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Check current hours of operation
    const date = new Date();
    const currentHour = date.getHours();
    const currentDay = date.getDay();
    const openingWeekDays = [1, 2, 3, 4, 5];
    const openingWeekendDays = [6, 0];
    const startingHour = 8;
    const endingHour = openingWeekDays.includes(currentDay) ? 19 : 15; // give buffer 1 hr for accepting new before clinic actually closes
    if (
      (!openingWeekDays.includes(currentDay) && !openingWeekendDays.includes(currentDay)) ||
      currentHour < startingHour ||
      currentHour > endingHour
    ) {
      setShowOffHoursMsg(true);
      setIsLoading(false);
    } else {
      navigate({ pathname: `/${redirectPath}`, search: params.toString() });
    }
    // eslint-disable-next-line
  }, []);

  const handleSaveCallBack = async () => {
    if (!cellPhone || cellPhone.length < 10) {
      app.addError("Please enter valid cell phone.");
      return;
    }
    try {
      const url = "/api/user/savecallback";
      await axiosClient.post(url, {
        cellPhone,
        context: "Virtual Urgent Care",
      });
      app.addInfoMsg(
        "Successfully saved your call back request.  You will receive a text message when clinic opens next.",
      );
      setTimeout(() => {
        window.location.pathname = "/home";
      }, 2000);
    } catch {
      app.addError("Failed to save callback, please contact support.");
      setTimeout(() => {
        window.location.pathname = "/home";
      }, 2000);
    }
  };

  const checkCellPhoneValidation = () => {
    let isValid = true;
    if (!cellPhone) {
      if (!cellPhone || cellPhone.match(/^[0-9]+$/) == null || cellPhone.toString().length !== 10) {
        isValid = false;
      }
    }
    return isValid;
  };

  const handleCellPhoneOnChange = (e) => {
    const { value } = e.target;
    if ((value && value.match(/^[0-9]+$/) == null) || value.toString().length > 10) {
      return;
    }

    setCellPhone(e.target.value);
  };
  return !isLoading ? (
    <>
      {showOffHoursMsg && (
        <div className={classes.container}>
          <div className={classes.mainTitle}>
            <div>Virtual Urgent Care is now closed.</div>
            <div className={classes.operHoursTitle}>
              Operating hours are Mon-Fri 8am-8pm, Sat-Sun 8am-4pm
            </div>
            <div className={classes.callTitle}>
              If a medical problem is life-threatening, call 911 immediately.
            </div>
          </div>
          <form className={classes.form}>
            <div className={classes.subtitle}>
              If you provide your cell phone number we will text message you back when the clinic is
              open.
            </div>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="outlined-multiline-static"
                label="Cell Phone"
                variant="outlined"
                value={cellPhone}
                onChange={(e) => handleCellPhoneOnChange(e)}
                helperText="Phone number must be 10 digit"
                placeholder="##########"
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveCallBack}
              disabled={!cellPhone || !checkCellPhoneValidation()}
              className={classes.button}
            >
              Notify Me
            </Button>
          </form>
        </div>
      )}
    </>
  ) : (
    <Loader text="" bgColor="" />
  );
}
