import React from "react";
import { Card, Divider, Typography } from "@mui/material";
import useStyles from "./DoctorInfo.styles";

interface Props {
  gender: string;
  aboutDoctor: string;
  languages: Array<string>;
  affiliations: Array<{ name: string; rank: string; type: string }>;
  notes: string;
  video?: { id: string; rank: number; type: string; url: string };
  fullName: string;
}

const DoctorDescription: React.FC<Props> = ({
  gender,
  aboutDoctor,
  languages,
  affiliations,
  notes,
  video = { id: "", rank: 0, type: "", url: "" },
  fullName,
}) => {
  const { classes } = useStyles();

  return (
    <Card className={classes.appCard} elevation={8}>
      <Typography className={classes.title} align="center">
        About {fullName}
      </Typography>
      {!!video.id && (
        <iframe
          width="100%"
          height="340px"
          src={`https://www.youtube.com/embed/${video.id}`}
          title="YouTube video player"
        />
      )}
      <div>
        {!!gender && (
          <>
            <Typography className={classes.titleDescription} align="center">
              Gender
            </Typography>
            <Typography className={classes.desc} align="center" data-testid="gender">
              {gender}
            </Typography>
          </>
        )}
        {!!aboutDoctor && (
          <>
            <Divider variant="middle" className={classes.divider} />
            <Typography className={classes.titleDescription} align="center">
              Professional Statement
            </Typography>
            <Typography className={classes.desc} align="center" data-testid="aboutDoctor">
              {aboutDoctor}
            </Typography>
          </>
        )}

        {languages.length > 0 && (
          <>
            <Divider variant="middle" className={classes.divider} />
            <Typography className={classes.titleDescription} align="center" data-testid="language">
              Language
            </Typography>
            {languages.map((item) => (
              <Typography className={classes.desc} align="center" key={item}>
                {item}
              </Typography>
            ))}
          </>
        )}
        {affiliations.length > 0 && (
          <>
            <Divider variant="middle" className={classes.divider} />
            <Typography
              className={classes.titleDescription}
              align="center"
              data-testid="affiliations"
            >
              Affiliations
            </Typography>
            <div>
              {affiliations.map((item) => (
                <Typography className={classes.desc} align="center" key={item.name}>
                  {item.name}
                </Typography>
              ))}
            </div>
          </>
        )}
        {!!notes && (
          <>
            <Divider variant="middle" className={classes.divider} />
            <Typography className={classes.titleDescription} align="center" data-testid="notes">
              Notes
            </Typography>
            <Typography className={classes.descNotes} align="center">
              {notes}
            </Typography>
          </>
        )}
      </div>
    </Card>
  );
};

export default DoctorDescription;
