import React, { useEffect, useState } from "react";
import { Dialog, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import CreateAdhocSession from "../Adhoc/CreateAdhocSession";
import CreateVsVisit from "../CreateVsVisit/CreateVsVisit";
import CreateSpeсialistSession from "../SpecialistOnDemand/CreateSpecialistSession";
import DialerScreen from "./DialerScreen";
import DialogContent from "./DialogContent";
import DialogTitle from "./DialogTitle";
import ReplaceThisPatientSearch from "../../../../../../views/console/components/PatientSearch";
import { Modes } from "./constants";
import { useUserType } from "hooks/useUserType/useUserType";
import { useIsMobile } from "util/deviceUtils";
import { useProgramType } from "util/programsHelpers";
import useStyles from "./CreateVirtualSessionModal.styles";

const TABS = {
  dialer: 0,
  patientSearch: 1,
};

function CreateVirtualSessionModal({ show, setShow, mode, setMode, modalTitle, isConsultation }) {
  const theme = useTheme();
  const btnTitle = isConsultation ? "Create Consultation" : "Create Virtual Visit";
  const { classes } = useStyles();
  const { isMobile } = useIsMobile();
  const { isRegStaff, isAdmin } = useUserType();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isAdhoc, isOnDemand } = useProgramType();
  const [tabValue, setTableValue] = useState(isOnDemand ? TABS.patientSearch : TABS.dialer);

  const [firstName, setFirstName] = useState("");
  const [MRN, setMRN] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState();
  const [zip, setZip] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    if (isOnDemand) {
      setTableValue(TABS.patientSearch);
      if (isAdmin && isRegStaff) {
        setShow(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnDemand, isRegStaff, isMobile]);

  const clearFields = () => {
    setFirstName("");
    setMRN("");
    setLastName("");
    setDob("");
    setZip("");
    setCellPhone("");
    setMode(Modes.add);
  };

  const handleClose = () => {
    clearFields();
    setShow(false);
  };

  const onConfirmMatch = (patient) => {
    if (isOnDemand) {
      setMode(Modes.createNewSpecialistSession);
    } else {
      setMode(Modes.createNewVsVisit);
    }

    if (patient) {
      setPatient(patient);
    }
    if (patient?.cellPhone) {
      setCellPhone(patient.cellPhone);
    }
    if (isAdhoc) {
      setShow(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTableValue(newValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      fullScreen={fullScreen}
      className={classes.dialog}
      keepMounted
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {mode !== Modes.add && modalTitle}
      </DialogTitle>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="createVirtualSessionOptions">
        <Tab label="Instant Connect" disabled={isOnDemand} />
        <Tab label="Patient Search" data-cy="searchTab" />
      </Tabs>
      <DialogContent>
        <div>
          {tabValue === TABS.dialer && <DialerScreen handleClose={handleClose} />}
          {tabValue === TABS.patientSearch && (
            <>
              {mode === Modes.add && (
                <ReplaceThisPatientSearch
                  style={{ marginBottom: "10px" }}
                  firstName={firstName}
                  lastName={lastName}
                  dob={dob}
                  zip={zip}
                  noBorder
                  headerExists
                  cellPhone={cellPhone}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  setDob={setDob}
                  setZip={setZip}
                  setCellPhone={setCellPhone}
                  onConfirmMatch={onConfirmMatch}
                  isFormDisabled={false}
                  setMRN={setMRN}
                  MRN={MRN}
                  ConfirmBtnTitle={btnTitle}
                  searchTitle={isOnDemand && "Neuro Consultation"}
                  searchDescription={isOnDemand && "Lookup patient to begin neuro consultation"}
                />
              )}
              {mode === Modes.createNewVsVisit && !isAdhoc && (
                <CreateVsVisit patient={patient} handleClose={handleClose} />
              )}
              {mode === Modes.createNewSpecialistSession && (
                <CreateSpeсialistSession patient={patient} />
              )}
              {mode === Modes.createNewVsVisit && isAdhoc && (
                <CreateAdhocSession
                  handleClose={() => {
                    clearFields();
                    setTableValue(0);
                  }}
                  cellPhone={cellPhone}
                  patient={patient}
                />
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CreateVirtualSessionModal;
