/* eslint-disable */
import React, { createContext, useCallback, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApp } from "./AppContext";
import { POST_OPTIONS, getApiHost } from "./api_helper";
import qString from "query-string";

const ProgramsContext = createContext();
ProgramsContext.displayName = "ProgramsContext";

export const ProgramsProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = qString.parse(location?.search);

  const pId = parseInt(query.programId, 10) ? parseInt(query.programId, 10) : 0;
  const dId = parseInt(query.departmentId, 10) ? parseInt(query.departmentId, 10) : 0;
  const [programId, setProgramId] = useState(pId);
  const [departmentId, setDepartmentId] = useState(dId);
  const [programOverrideStatus, setProgramOverrideStatus] = useState("");
  const [programList, setProgramList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const app = useApp();

  const handleChangeProgram = (programId) => {
    setProgramId(programId);
    const search = qString.stringify({ ...query, departmentId, programId });
    navigate(`${window.location.pathname}?${search}`);
  };

  const handleDepartmentChange = (departmentId) => {
    setDepartmentId(departmentId);
    const search = qString.stringify({ ...query, departmentId, programId });
    navigate({ search });
  };

  const handleProgramOverrideChangeSync = async (programOverride, cb) => {
    if (!programId) {
      return;
    }
    try {
      const apiHost = getApiHost();

      const response = await fetch(`${apiHost}/api/console/config/program/override`, {
        ...POST_OPTIONS,
        body: JSON.stringify({
          programOverride,
          programId,
        }),
      });
      const resp = await response.json();
      if (resp.error) {
        app.addError("Problem updating program override status.");
        return;
      }
      if (cb) {
        cb(programOverride);
      }
    } catch (error) {
      console.log(`Unexpected problem updating program override status ${error}`);
      app.addError("Unexpected problem updating program override status.");
    }
  };

  const handleUpdateProgramOverrideStatus = (value) => {
    handleProgramOverrideChangeSync(value, (programOverride) => {
      setProgramOverrideStatus(programOverride);
      localStorage.setItem("programOverrideStatus", programOverride);
    });
  };

  const getProgramIdByType = useCallback(
    (programType) => {
      let program = programList.find((program) => program.program_type === programType);
      return program ? program.id : {};
    },
    [programList],
  );

  return (
    <ProgramsContext.Provider
      value={{
        departmentList,
        setDepartmentList,
        departmentId,
        setDepartmentId: handleDepartmentChange,
        programId,
        setProgramId: handleChangeProgram,
        programList,
        setProgramList,
        programOverrideStatus,
        setProgramOverrideStatus,
        handleUpdateProgramOverrideStatus,
        getProgramIdByType,
      }}
    >
      {children}
    </ProgramsContext.Provider>
  );
};

export const usePrograms = () => {
  return useContext(ProgramsContext);
};
