import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import SpecimenTrackingList from "./views/List";
import { makeStyles } from "tss-react/mui";
import { useGetSpecimenLocations } from "hooks/specimenTracking/useGetSpecimenLocations/useGetSpecimenLocations";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export function SpecimenTrackingView() {
  const { classes } = useStyles();

  const { getSpecimenLocationsList } = useGetSpecimenLocations();

  useEffect(() => {
    getSpecimenLocationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={classes.root}>
      <SpecimenTrackingList />
    </Grid>
  );
}

export default SpecimenTrackingView;
