import React from "react";
import useStyles from "./StaffEndCall.styles";

const StaffEndCall = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        You have disconnected from the virtual session. You can still view and transfer or close it
        in the waiting room queue view in your other browser tab.
      </div>
      <button type="button" onClick={() => window.close()} className={classes.button}>
        Close Tab
      </button>
    </div>
  );
};

export default StaffEndCall;
