import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, FormLabel, Grid, Paper, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CellPhone from "components/shared/CellPhone";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    boxSizing: "border-box",
    maxWidth: "640px",
    width: "100%",
    padding: "32px 40px",

    [theme.breakpoints.down("md")]: {
      padding: "28px 22px",
      maxWidth: "560px",
    },
  },
  title: {
    margin: 0,
    marginBottom: "8px",
    fontSize: "32px",
    fontFamily: "unset",
    lineHeight: "100%",
    color: theme.palette.primary.main,
    fontWeight: 600,
    opacity: 0.87,
  },
  subtitle: {
    margin: 0,
    marginBottom: "24px",
    fontSize: "16px",
    color: theme.palette.primary.main,
  },
  textMessageLoginButton: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    fontWeight: 600,
    marginTop: "24px",
  },
  label: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Alegreya Sans",
    fontSize: "16px",
    color: theme.palette.common.black,
    maxWidth: "calc(100% - 20px)",
  },
}));

const ConfirmPatientPhone = () => {
  const [cellPhone, setCellPhone] = useState("");
  const [cellPhoneSent, setCellPhoneSent] = useState(false);
  const [consentId, setConsentId] = useState("");
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const app = useApp();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const mrn = params.get("emr_mrn");
  const email = params.get("email");
  const dob = params.get("dob");
  const firstName = params.get("first_name");
  const lastName = params.get("last_name");
  const cellphone = params.get("cellphone");

  useEffect(() => {
    if (cellphone) {
      setCellPhone(cellphone);
    }
  }, [cellphone]);

  const handleConfirmPhoneNumber = async (event) => {
    event.preventDefault();

    if (!cellPhoneSent) {
      try {
        const body = {
          emr_mrn: mrn,
          email,
          dob,
          first_name: firstName,
          last_name: lastName,
          cellphone: cellPhone,
        };
        const { data } = await axiosClient.post(API_ENDPOINTS.consent_submit_sms, body);
        setConsentId(data.epic_consent_id);
        app.addInfoMsg(`An SMS to ${cellPhone} has been sent!`);
        setTimeout(() => {
          navigate("/confirm-patient/thankyou?is_staff=true");
        }, 3000);
        setCellPhoneSent(true);
      } catch (error) {
        app.addError("Oops, an unexpected error has occurred!");
      }
    } else {
      try {
        const body = {
          emr_mrn: mrn,
          epic_consent_id: consentId,
          cellphone: cellPhone,
        };
        await axiosClient.post(API_ENDPOINTS.consent_submit_sms_resend, body);
        app.addInfoMsg(`An SMS to ${cellPhone} has been resent!`);
      } catch (error) {
        app.addError("Oops, an unexpected error has occurred!");
      }
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Paper elevation={!isMobile ? 24 : 0} className={classes.paper}>
        <Typography variant="h1" className={classes.title}>
          Patient phone verification
        </Typography>
        <Grid container spacing={1} style={{ marginTop: "30px" }}>
          <Grid item xs={6} sm={6}>
            <FormLabel className={classes.label} data-testid="phone-verification-info-mrn">
              MRN
            </FormLabel>
            <p className={classes.subtitle}>{mrn}</p>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormLabel className={classes.label} data-testid="phone-verification-info-email">
              Email
            </FormLabel>
            <p className={classes.subtitle}>{email || "Email not found"}</p>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormLabel className={classes.label} data-testid="phone-verification-info-first-name">
              First Name
            </FormLabel>
            <p className={classes.subtitle}>{firstName || "First Name not found"}</p>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormLabel className={classes.label} data-testid="phone-verification-last-name">
              Last Name
            </FormLabel>
            <p className={classes.subtitle}>{lastName || "Last Name not found"}</p>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormLabel className={classes.label} data-testid="phone-verification-dob">
              Date of birth
            </FormLabel>
            <p className={classes.subtitle}>{dob || "Date of birth not found"}</p>
          </Grid>
        </Grid>
        <form onSubmit={handleConfirmPhoneNumber}>
          <p className={classes.subtitle}>Confirm Patient Phone</p>
          <CellPhone cellPhone={cellPhone} setCellPhone={(value) => setCellPhone(value)} />
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            className={classes.textMessageLoginButton}
            data-testid="confirmPhone"
            disabled={cellPhone === "" || cellPhone.length < 9}
            fullWidth
          >
            {cellPhoneSent ? "Resend Message" : "Send Message"}
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default ConfirmPatientPhone;
