import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { Close as CloseIcon, FlipCameraIos, PhotoCamera } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  btnContainer: {
    justifyContent: "center",
  },
  webCamContainer: {
    width: "100%",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    padding: theme.spacing(1),
  },
  closeBtn: {
    color: theme.palette.grey[500],
  },
}));

export default function WebcamCapture({ openCamera, setOpenCamera, webcamRef, capture }) {
  const videoConstraints = {
    facingMode: openCamera,
  };

  const [open, setOpen] = useState(true);
  const [enableButton, setEnableButton] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { classes } = useStyles();

  const handleClose = () => {
    setOpenCamera("");
    setOpen(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEnableButton(true);
    }, 1000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <Dialog
      id="image_dialog"
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton onClick={handleClose} className={classes.closeBtn} size="large">
          <CloseIcon data-testid="close-icon" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!enableButton && <span>Please wait</span>}
        <Webcam
          audio={false}
          ref={webcamRef}
          videoConstraints={videoConstraints}
          screenshotFormat="image/png"
          className={classes.webCamContainer}
        />
      </DialogContent>
      <DialogActions className={classes.btnContainer}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PhotoCamera />}
          onClick={(e) => {
            setOpen(false);
            setOpenCamera("");
            capture(e);
          }}
          disabled={!enableButton}
          data-cy="capturePhoto"
        >
          Capture photo
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FlipCameraIos />}
          onClick={() => {
            setOpenCamera("environment");
          }}
          disabled={!enableButton}
        >
          Flip Camera
        </Button>
      </DialogActions>
    </Dialog>
  );
}
