import { getFullNameFromParts } from "util/util_functions";

export const colDefsReport = [
  {
    id: "journeyTime",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Journey Time (Minutes)",
    searchable: false,
    sortable: true,
    mobile: true,
    isString: true,
  },
  {
    id: "type",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Type",
    searchable: false,
    sortable: true,
    mobile: true,
    isString: true,
  },
  {
    id: "departmentShortName",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Department",
    searchable: false,
    sortable: true,
    mobile: true,
    isString: true,
  },
  {
    id: "collectedAtName",
    numeric: false,
    isCustomContent: true,
    disablePadding: true,
    label: "Location",
    searchable: false,
    sortable: true,
    mobile: true,
    isString: true,
  },
  {
    id: "specimenOrderOrderExternalId",
    numeric: false,
    disablePadding: true,
    label: "Order ID",
    searchable: true,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenOrderMrn",
    numeric: false,
    disablePadding: true,
    label: "MRN",
    searchable: true,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenOrderPatientName",
    numeric: false,
    disablePadding: true,
    label: "Patient Name",
    searchable: true,
    sortable: true,
    mobile: true,
    isCustomContent: true,
    func: (_, row) =>
      getFullNameFromParts(row.specimenOrderPatientFirstName, row.specimenOrderPatientLastName),
  },
  {
    id: "specimenSpecimenExternalId",
    numeric: false,
    isString: true,
    disablePadding: true,
    label: "Specimen ID",
    searchable: true,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenSpecimenType",
    numeric: false,
    disablePadding: true,
    label: "Specimen Type",
    searchable: false,
    sortable: true,
    mobile: true,
  },
  {
    id: "specimenDescription",
    numeric: false,
    disablePadding: true,
    label: "Specimen Info",
    searchable: false,
    sortable: true,
    mobile: true,
  },
  {
    id: "firstTrackedDate",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "First Tracked Date",
    searchable: true,
    sortable: true,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
  {
    id: "lastTrackedDate",
    numeric: false,
    isDate: true,
    disablePadding: true,
    label: "Last Tracked Date",
    searchable: false,
    sortable: true,
    mobile: true,
    customInput: {
      type: "date",
    },
  },
];
