import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Close as CloseIcon, SaveAlt as SaveAltIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import VSDetailsModal from "./VSDetailsModal";
import { colDefs, consultationColDefs } from "./colDefs";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import { useProgramType } from "util/programsHelpers";
import { convertJsonDataToCSV } from "util/util_functions";
import { useVsReportStyles } from "./styles";

export default function VirtualSessionReport({ setViewReportMode = null }) {
  const { classes } = useVsReportStyles();
  const navigate = useNavigate();
  const app = useApp();
  const auth = useAuth();
  const { programId, departmentId } = usePrograms();
  const { isConsultation, isAdhoc } = useProgramType();

  const listSelection = "Available";
  const isMobile = useIsMobile();
  const currentColDefs = isConsultation ? consultationColDefs : colDefs;

  const [showModal, setShowModal] = useState(false);
  const [virtualSession, setVirtualSession] = useState(null);

  const gridConfig = {
    ...defaultGridConfig,
    showActionColumn: false,
    showTableToolbar: false,
    allowDelete: false,
    allowEdit: false,
  };

  const onRowClicked = (row) => {
    app.dispatch({
      type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
      payload: row,
    });
    setVirtualSession(row);
    setShowModal(true);
  };

  const exportDataToCSV = async () => {
    try {
      const reqUrl = "/api/s/console/virtualvisit/virtual-session-report";
      const { data } = await axiosClient.get(reqUrl, {
        params: {
          page_no: 0,
          page_size: 10,
          order: "desc",
          order_by: "id",
          search_by: "",
          search_text: "",
          all_data: true,
          program_id: programId,
          department_id: departmentId,
        },
      });
      convertJsonDataToCSV("virtual-session-report", data?.data, colDefs);
    } catch (error) {
      app.addError(error.response?.data?.description);
    }
  };

  const url = isAdhoc
    ? `/api/s/console/virtualvisit/virtual-session-report?owned_by=${auth.user.userId}`
    : "/api/s/console/virtualvisit/virtual-session-report";

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.topBtnContainer}>
        {isMobile && (
          <div className={classes.closeBtnWrapper}>
            <Button
              onClick={() => {
                if (setViewReportMode) {
                  setViewReportMode(false);
                } else {
                  navigate("/console/virtualvisit/waitingroom");
                }
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveAltIcon />}
          onClick={() => exportDataToCSV()}
        >
          Export (CSV)
        </Button>
      </div>
      <div className={classes.tableContainer}>
        <GenericEnhancedTable
          gridConfig={gridConfig}
          gridType={listSelection}
          tableTitle="Virtual Session Report"
          colDefs={currentColDefs}
          url={url}
          mobileReportView
          orderBy="id"
          order="desc"
          defaultSearchBy="type"
          onRowClicked={onRowClicked}
          hideCheckboxes
        />
      </div>
      <VSDetailsModal
        show={showModal}
        setShow={setShowModal}
        currentVirtualVisit={virtualSession}
      />
    </form>
  );
}
