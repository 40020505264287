import React from "react";
import { Create, Save } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import useTranslation from "hooks/useTranslation";

export const StepTitleToolbar = ({ classes, actionClickHandler, isSaveMode }) => {
  const {
    web: {
      dotForm: {
        staff: {
          common: { saveForm, edit },
        },
      },
    },
  } = useTranslation();

  return (
    <Grid item xs={12} md={6} className={classes.closeButton}>
      <div className={classes.inlineActionButtons}>
        <IconButton
          aria-label="disable form"
          onClick={() => actionClickHandler(isSaveMode)}
          size="large"
        >
          {isSaveMode ? <Save /> : <Create />}
        </IconButton>
        <span>{isSaveMode ? saveForm : edit}</span>
      </div>
    </Grid>
  );
};
