/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from "react";
import Video, { CreateLocalTrackOptions, LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from "util/constants";
import { getDeviceInfo, isPermissionDenied } from "../../../../../util/deviceUtils";

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);
  const audioTrackRef = useRef<LocalAudioTrack>();
  const videoTrackRef = useRef<LocalVideoTrack>();

  useEffect(() => {
    audioTrackRef.current = audioTrack;
  }, [audioTrack]);

  useEffect(() => {
    videoTrackRef.current = videoTrack;
  }, [videoTrack]);

  useEffect(() => {
    // This cleanup function only runs when the VideoProvider is unmounted
    return () => {
      if (audioTrackRef.current) {
        audioTrackRef.current.stop();
        setAudioTrack(undefined);
      }

      if (videoTrackRef.current) {
        videoTrackRef.current.stop();
        setVideoTrack(undefined);
      }
    };
  }, []); // Refs are used above so that we can keep this dependency array empty, so it only runs on unmount
  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return Video.createLocalAudioTrack(options)
      .then((newTrack) => {
        setAudioTrack(newTrack);
        return newTrack;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    );

    const options: CreateLocalTrackOptions = {
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } }),
    };

    return Video.createLocalVideoTrack(options)
      .then((newTrack) => {
        setVideoTrack(newTrack);
        return newTrack;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      videoTrack.detach();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const getAudioAndVideoTracks = useCallback(async () => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } =
      await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices) {
      return Promise.resolve();
    }
    if (isAcquiringLocalTracks || audioTrack || videoTrack) {
      return Promise.resolve();
    }

    setIsAcquiringLocalTracks(true);

    const selectedAudioDeviceId = localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const selectedVideoDeviceId = localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId,
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    );

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied("camera" as PermissionName);
    const isMicrophonePermissionDenied = await isPermissionDenied("microphone" as PermissionName);

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;

    const localTrackConstraints = {
      video: shouldAcquireVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } }),
      },
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice
          ? { deviceId: { exact: selectedAudioDeviceId! } }
          : hasAudioInputDevices),
    };

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newVideoTrack = tracks.find((track) => track.kind === "video") as LocalVideoTrack;
        const newAudioTrack = tracks.find((track) => track.kind === "audio") as LocalAudioTrack;
        if (newVideoTrack) {
          setVideoTrack(newVideoTrack);
          // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          localStorage.setItem(
            SELECTED_VIDEO_INPUT_KEY,
            newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? "",
          );
        }
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack);
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error();
          error.name = "NotAllowedError";
          throw error;
        }

        if (isCameraPermissionDenied) {
          throw new Error("CameraPermissionsDenied");
        }

        if (isMicrophonePermissionDenied) {
          throw new Error("MicrophonePermissionsDenied");
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [audioTrack, videoTrack, isAcquiringLocalTracks]);

  const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
  )[];

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  };
}
