import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import TimeSlotPickerGrid from "components/TimeSlotPicker/TimeSlotPickerGrid";
import { mockTimeSlotInfo } from "components/TimeSlotPicker/helpers";
import { RNWebView } from "util/RNWebView";
import useStyles from "./NewAppointment.styles";

interface Props {
  title: string;
  imgUrl: string;
  desc: string;
  actionUrl?: string;
  msg: string;
  handleCardClick: (value: string) => void;
  isActive?: boolean;
  hospital?: string;
  number?: string;
  currentView?: number;
  showTimeSlots?: boolean;
  handleTimeSlotClick?: (id: string | number) => void;
}

const VIEWS = {
  map: 0,
  list: 1,
};

const ProviderCard = ({
  title,
  imgUrl,
  desc,
  actionUrl,
  msg,
  handleCardClick,
  isActive = false,
  hospital,
  number,
  currentView,
  showTimeSlots,
  handleTimeSlotClick,
}: Props) => {
  const { classes, cx } = useStyles({ isActive });
  const navigate = useNavigate();

  return (
    <Card
      elevation={8}
      className={cx(
        RNWebView && currentView === VIEWS.map ? classes.appCardRN : classes.appCard,
        showTimeSlots && classes.appCardWithTimeSlots,
      )}
    >
      <CardActionArea
        onClick={() => {
          if (actionUrl) {
            if (actionUrl.startsWith("/")) {
              navigate(actionUrl);
            } else {
              window.open(actionUrl, "_blank");
            }
          }
          if (handleCardClick) {
            handleCardClick(msg);
          } else {
            console.log(actionUrl + msg);
          }
        }}
        className={cx(showTimeSlots && classes.cardActionAreaWithTimeSlots)}
      >
        <Grid container alignItems={RNWebView ? "flex-start" : "center"}>
          <Grid item xs={showTimeSlots ? 4 : 2}>
            <CardContent>
              <img
                src={imgUrl}
                alt={title}
                className={cx(classes.img, { [classes.imgRN]: RNWebView })}
              />
            </CardContent>
          </Grid>
          <Grid item xs={showTimeSlots ? 8 : 10}>
            <CardContent>
              <Typography
                className={cx(classes.titleCard, {
                  [classes.titleCardRN]: RNWebView,
                })}
                align="left"
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={cx(classes.cardDesc, {
                  [classes.cardDescRN]: RNWebView,
                })}
                align="left"
              >
                {desc}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={cx(classes.cardDesc, {
                  [classes.cardDescRN]: RNWebView,
                })}
                align="left"
              >
                {hospital}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                className={cx(classes.cardDesc, {
                  [classes.cardDescRN]: RNWebView,
                })}
                align="left"
              >
                {number}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
      {showTimeSlots && (
        <TimeSlotPickerGrid
          timeSlotInfo={mockTimeSlotInfo}
          amountOfTimeSlotsToShow={5}
          handleTimeSlotClick={handleTimeSlotClick}
        />
      )}
    </Card>
  );
};

ProviderCard.defaultProps = {
  actionUrl: "",
};

export default ProviderCard;
