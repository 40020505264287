/** @format */

/* eslint-disable */
import { useReducer } from "react";
import moment from "moment";
import {
  ADD_VIRTUAL_VISIT_TOKEN,
  CLEAR_VIRTUAL_VISIT,
  CONNECT_VIRTUAL_SESSION,
  CONNECT_VIRTUAL_VISIT,
  DISCONNECT_VIRTUAL_VISIT,
  FETCH_NEW_VIRTUAL_VISITS,
  FETCH_PATIENT_VIRTUAL_SESSION,
  FETCH_VIRTUAL_VISITS,
  FETCH_VIRTUAL_VISITS_CONVERSATIONS,
  JOIN_PATIENT_SESSION,
  PARTICIPANT_JOINED,
  PARTICIPANT_LEFT,
  SELECT_CURRENT_PROFILE,
  START_PATIENT_SESSION,
  TOGGLE_PATIENT_PROFILE,
  TOGGLE_QUEUE_TAB,
  TOGGLE_WAITING_DIALOGUE,
  TOGGLE_WAITING_LIST,
  UPDATE_AND_SELECT_VIRTUAL_VISIT,
  UPDATE_VIRTUAL_VISITS,
} from "./Actions";
import { Urgency, WaitingRoomTab } from "util/constants";

const initialState = {
  virtualVisits: [],
  currentVirtualVisit: null,
  toggleWaitingList: true,
  togglePatientProfile: false,
  joinVirtualSessionId: null,
  getNewList: false,
  patientInSession: false,
  currentProfile: {},
  currentTab: WaitingRoomTab.QUEUE,
  toggleWaitingDialogue: false,
  joinedParticipant: null,
  leftParticipant: null,
  consultationVvs: [],
  secondOpinionVvs: [],
  allneuroVvs: [],
  onlyConversation: false,
  patientVirtualSession: null,
};
const sortDates = (a, b) => {
  if (moment(a.start_dttm) < moment(b.start_dttm)) {
    return -1;
  }
  if (moment(a.start_dttm) > moment(b.start_dttm)) {
    return 1;
  }
  return 0;
};
const virtualVisitsReducer = (state, { type, payload, departmentId }) => {
  const { virtualVisits } = state;
  switch (type) {
    case FETCH_VIRTUAL_VISITS: {
      const urgentVvs = [...payload.filter((t) => t.urgency === Urgency.URGENT)];

      const routineVvs = [...payload.filter((t) => t.urgency === Urgency.ROUTINE)];

      const lowPriorityVvs = [...payload.filter((t) => t.urgency === Urgency.LOW_PRIORITY)];
      const sortedVvs = [...urgentVvs, ...routineVvs, ...lowPriorityVvs];

      const secondOpinionVvs = [...payload.filter((v) => v.patient_reason === "2nd Opinion")];
      const consultationVvs = [...payload.filter((v) => v.patient_reason === "Inquiry")];
      const allneuroVvs = [...sortedVvs.filter((v) => v.type === "consultation")];

      return {
        ...state,
        virtualVisits: sortedVvs,
        getNewList: false,
        secondOpinionVvs,
        consultationVvs,
        allneuroVvs,
      };
    }

    case FETCH_VIRTUAL_VISITS_CONVERSATIONS:
      const sortedConvVvs = payload.map((t) => t.vs).sort(sortDates);
      return { ...state, virtualVisits: [...sortedConvVvs], onlyConversation: true };

    case UPDATE_VIRTUAL_VISITS:
      const tempVsVisits2 = [...state.virtualVisits];
      const vsVisitIndex = tempVsVisits2.findIndex((v) => v.id === payload.id);
      if (vsVisitIndex > 0) {
        tempVsVisits2[vsVisitIndex] = payload;
        localStorage.setItem("virtual_session_id", payload.id);
        localStorage.setItem("external_tracking_id", payload.external_tracking_id);
      }
      return { ...state, virtualVisits: [...tempVsVisits2] };

    case UPDATE_AND_SELECT_VIRTUAL_VISIT: {
      const currentVirtualVisit = payload;

      const modifiedVirtualVisits = state.virtualVisits.map((virtualVisit) =>
        virtualVisit.id === currentVirtualVisit.id ? currentVirtualVisit : virtualVisit,
      );
      const modifiedConsultationVvs = state.consultationVvs.map((virtualVisit) =>
        virtualVisit.id === currentVirtualVisit.id ? currentVirtualVisit : virtualVisit,
      );
      const modifiedSecondOpinionVvs = state.secondOpinionVvs.map((virtualVisit) =>
        virtualVisit.id === currentVirtualVisit.id ? currentVirtualVisit : virtualVisit,
      );
      const modifiedAllNeuroVvs = state.allneuroVvs.map((virtualVisit) =>
        virtualVisit.id === currentVirtualVisit.id ? currentVirtualVisit : virtualVisit,
      );

      localStorage.setItem("virtual_session_id", currentVirtualVisit.id);
      localStorage.setItem("external_tracking_id", currentVirtualVisit.external_tracking_id);

      return {
        ...state,
        virtualVisits: modifiedVirtualVisits,
        consultationVvs: modifiedConsultationVvs,
        secondOpinionVvs: modifiedSecondOpinionVvs,
        allneuroVvs: modifiedAllNeuroVvs,
        currentVirtualVisit,
      };
    }

    case ADD_VIRTUAL_VISIT_TOKEN:
      const tempVsVisits = [...virtualVisits];
      const index = tempVsVisits.findIndex((u) => u.id === payload.id);
      if (index >= 0) {
        tempVsVisits[index] = { ...tempVsVisits[index], token: payload.token };
        const localStorageTokens = JSON.parse(localStorage.getItem("VS_VISIT_TOKENS")) || [];
        const innerIndex = localStorageTokens.findIndex((u) => u.id === payload.id);
        if (innerIndex < 0) {
          localStorage.setItem(
            "VS_VISIT_TOKENS",
            JSON.stringify([...localStorageTokens, { id: payload.id, token: payload.token }]),
          );
        } else {
          localStorageTokens[index] = { id: payload.id, token: payload.token };
          localStorage.setItem("VS_VISIT_TOKENS", JSON.stringify([...localStorageTokens]));
        }
      }
      return { ...state, virtualVisits: [...tempVsVisits] };

    case FETCH_NEW_VIRTUAL_VISITS:
      return { ...state, getNewList: true };

    case CONNECT_VIRTUAL_VISIT:
      const vs_id = payload;
      const virtual_visit = virtualVisits.find((u) => vs_id === u.id);
      if (virtual_visit) {
        localStorage.setItem("virtual_session_id", virtual_visit.id);
        localStorage.setItem("external_tracking_id", virtual_visit.external_tracking_id);
      } else {
        localStorage.removeItem("virtual_session_id");
        localStorage.removeItem("external_tracking_id");
      }
      return {
        ...state,
        currentVirtualVisit: virtual_visit,
      };

    case CLEAR_VIRTUAL_VISIT:
      localStorage.removeItem("virtual_session_id");
      localStorage.removeItem("external_tracking_id");
      return {
        ...state,
        currentVirtualVisit: null,
      };

    case SELECT_CURRENT_PROFILE:
      return { ...state, currentProfile: payload };
    case CONNECT_VIRTUAL_SESSION:
      return {
        ...state,
        joinVirtualSessionId: payload,
      };

    case TOGGLE_WAITING_LIST:
      return { ...state, toggleWaitingList: payload };

    case TOGGLE_PATIENT_PROFILE:
      return { ...state, togglePatientProfile: payload };

    case DISCONNECT_VIRTUAL_VISIT: {
      const removedId = payload;

      const filteredVirtualVisits = state.virtualVisits.filter(
        (virtualVisit) => virtualVisit.id !== removedId,
      );
      const filteredConsultationVvs = state.consultationVvs.filter(
        (virtualVisit) => virtualVisit.id !== removedId,
      );
      const filteredSecondOpinionVvs = state.secondOpinionVvs.filter(
        (virtualVisit) => virtualVisit.id !== removedId,
      );
      const filteredAllNeuroVvs = state.allneuroVvs.filter(
        (virtualVisit) => virtualVisit.id !== removedId,
      );

      localStorage.removeItem("virtual_session_id");
      localStorage.removeItem("external_tracking_id");

      return {
        ...state,
        virtualVisits: filteredVirtualVisits,
        consultationVvs: filteredConsultationVvs,
        secondOpinionVvs: filteredSecondOpinionVvs,
        allneuroVvs: filteredAllNeuroVvs,
        currentTab: WaitingRoomTab.QUEUE,
        currentVirtualVisit: {},
      };
    }

    case JOIN_PATIENT_SESSION:
      return { ...state, patientInSession: true };

    case START_PATIENT_SESSION:
      return { ...state, patientInSession: false };

    case TOGGLE_QUEUE_TAB:
      return { ...state, currentTab: payload };

    case TOGGLE_WAITING_DIALOGUE:
      return { ...state, toggleWaitingDialogue: payload };

    case PARTICIPANT_JOINED:
      return { ...state, joinedParticipant: payload };
    case PARTICIPANT_LEFT:
      const sameParticipant = state.joinedParticipant?.identity === payload?.identity;
      return {
        ...state,
        leftParticipant: payload,
        joinedParticipant: sameParticipant ? null : state.joinedParticipant,
      };

    case FETCH_PATIENT_VIRTUAL_SESSION:
      return { ...state, patientVirtualSession: payload };
    default:
      return { ...state };
  }
};
export const useVirtualVisitReducer = () => {
  return useReducer(virtualVisitsReducer, initialState);
};
