/* eslint-disable */
import { React, useEffect, useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Alert, Grid, Link, Paper, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { getLocalTime } from "util/util_functions";

const useStyles = makeStyles()({
  table: {
    minWidth: "650px",
  },
  title: {
    marginTop: 0,
    marginBottom: "8px",
  },
  container: {
    marginBottom: "24px",
  },
  alert: {
    width: "100%",
  },
});

const UserEvents = ({ userId }) => {
  const { classes } = useStyles();
  const app = useApp();
  const [userEvents, setUserEvents] = useState([]);

  const getUserEvents = async () => {
    try {
      const url = `/api/s/console/patient/user_events?user_id=${userId}`;
      const res = await axiosClient.get(url);

      setUserEvents(res?.data?.data);
    } catch (e) {
      app.addError("Something went wrong, please contact support");
    }
  };

  useEffect(() => {
    if (userId) {
      getUserEvents();
    }
    // eslint-disable-next-line
  }, [userId]);

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        User Events
      </Typography>
      <Grid container component={Paper} className={classes.container}>
        {!userEvents.length && (
          <Alert className={classes.alert} severity="info">
            No userEvents found in the system.
          </Alert>
        )}
        {!!userEvents.length && (
          <Timeline>
            {userEvents.map((userEvent, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent>
                  <Typography color="textSecondary">
                    {getLocalTime(userEvent.event_dttm)}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography>
                    ({userEvent.event_type}) - <b>{userEvent.event_name}</b>
                  </Typography>
                  {userEvent?.patient_verification_link && (
                    <Link
                      href={userEvent?.patient_verification_link}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      Patient ID Verification Audit
                    </Link>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        )}
      </Grid>
    </>
  );
};

export default UserEvents;
