/** @format */

/* eslint-disable */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ClosedCaptioningAck from "components/consents/ClosedCaptioningAck";
import { ConsentPath } from "util/constants";
import { useIsMobile } from "../../../util/deviceUtils";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import AtlanticLogo from "../../../static/svg/logo_header.svg";

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: "64px",
    gap: "32px",

    [theme.breakpoints.down("md")]: {
      paddingTop: "32px",
      gap: 0,
    },
  },
  media: {
    height: 60,
    width: 60,
    margin: "2px 5px",
  },
  paper: {
    boxSizing: "border-box",
    maxWidth: "640px",
    width: "100%",
    padding: "32px 40px",

    [theme.breakpoints.down("md")]: {
      padding: "28px 22px",
      maxWidth: "560px",
    },
  },
  media2: {
    height: 60,
    width: 180,
    margin: "2px 5px",
    marginTop: "5px",
  },
  image: {
    maxWidth: "90%",
    height: "auto",
  },
  para: {
    textAlign: "left",
    fontSize: "16pt",
  },
  iconDiv: {
    height: "60px",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  error: {
    color: "red",
  },
  consentLabel: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Alegreya Sans",
    fontSize: "16px",
    color: theme.palette.common.black,
    letterSpacing: "0.15px",
    lineHeight: "22px",
    fontWeight: "normal",
    margin: "10px 0px",
  },
  checkBoxText: {
    fontSize: "16px",
    textAlign: "left",
  },
}));

const PhoneNumberAuth = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [consent, setConsent] = useState(false);
  const params = new URLSearchParams(location.search);
  const hash = params.get("s");
  const consentId = params.get("consentId");
  const lastFour = params.get("lastFour");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      is_consent: consent,
      epic_consent_id: consentId,
      consent_type: "closed_caption_consent",
      security: {
        secretHash: hash,
        lastFour,
      },
      signed_dtt: moment().format(),
    };
    try {
      await axiosClient.post(API_ENDPOINTS.context_aware_linking_add_consent, body);
      navigate("/confirm-patient/thankyou");
    } catch (error) {}
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      wrap="nowrap"
      className={classes.root}
    >
      <Paper elevation={!isMobile ? 24 : 0} className={classes.paper}>
        <Grid container direction="row" justifyContent="center">
          <div className={classes.media2}>
            <img className={classes.image} src={AtlanticLogo} alt="Atlantic Logo" />
          </div>
        </Grid>
        <>
          <Typography variant="body2" paragraph className={classes.para} data-testid="consent-text">
            Acknowledgement for Patients
            <br />
          </Typography>
          <Box flexBasis="80%" overflow="auto" marginBottom="16px">
            <ClosedCaptioningAck />
          </Box>
          <FormControlLabel
            classes={{ label: classes.checkBoxText }}
            control={
              <Checkbox
                name="checkedB"
                value={consent}
                checked={consent}
                onChange={() => {
                  setConsent(!consent);
                }}
                color="primary"
                data-testid="consentCheckbox"
                data-cy="consentCheckbox"
              />
            }
            label={
              <InputLabel className={classes.consentLabel}>
                To continue, please agree to Atlantic Health System’s closed captioning
                acknowledgement outlined on this form{" "}
                <a href={ConsentPath.CLOSED_CAPTIONS_CONSENT} target="_blank" rel="noreferrer">
                  this form
                </a>
              </InputLabel>
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            data-cy="nextButton"
            data-testid="nextButton"
            disabled={!consent}
          >
            Submit
          </Button>
        </>
      </Paper>
    </Grid>
  );
};

export default PhoneNumberAuth;
