import React, { ReactNode, createContext, useContext } from "react";
import { ProviderGeneralInfoAndLocation } from "./types";

interface ProviderContextType {
  providerGeneralInfoAndLocation: any;
  providerExperience: any;
  providerClinical: any;
  providerNotes: any;
  visitTypesList: any;
  affiliationsList: any;
  currentPayload: ProviderGeneralInfoAndLocation | null;
  setCurrentPayload: (currentFormInfo: any) => void;
  currentlyEditing: string | null;
  setCurrentlyEditing: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTab: string | null;
  refetch: () => void;
}

const ProviderContext = createContext<ProviderContextType | undefined>(undefined);

export const ProviderContextProvider: React.FC<{
  value: ProviderContextType;
  children: ReactNode;
}> = ({ value, children }) => {
  return <ProviderContext.Provider value={value}>{children}</ProviderContext.Provider>;
};

export const useProviderContext = (): ProviderContextType => {
  const context = useContext(ProviderContext);
  if (context === undefined) {
    throw new Error("useProviderContext must be used within a ProviderContextProvider");
  }
  return context;
};
