import { green, red } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";

export const useCreateVsFormStyles = makeStyles()(() => ({
  loaderDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnsContainer: {
    display: "grid",
    gridTemplateColumns: "calc(50% - 10px) calc(50% - 10px)",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  formContainer: {
    padding: "10px 20px 20px 20px",
    boxSizing: "border-box",
  },
  formControl: {
    width: "100%",
  },
  inputsContainer: {
    gap: "10px",
  },
  verifiedUserIcon: {
    color: green[500],
  },
  cancelIcon: {
    color: red[500],
  },
  sendInvite: {
    marginBottom: "8px",
  },
}));

export const useSinglePatientStyles = makeStyles()((theme) => ({
  patientName: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  root: {
    width: "80%",
    textAlign: "left",
    height: 230,
    position: "realtive",
    margin: "0px auto 20px",
    [theme.breakpoints.down("md")]: {
      height: "190px",
    },
  },
  media: {
    height: 75,
    background: theme.palette.secondary.main,
    paddingLeft: 210,
    paddingTop: 18,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "60px",
      height: 60,
      paddingTop: "7px",
    },
  },
  img: {
    width: 180,
    height: 180,
    position: "relative",
    top: -70,
    [theme.breakpoints.down("md")]: {
      width: 30,
      height: 30,
      top: -63,
    },
  },
  cardContent: {
    display: "flex",
  },
  heading: {
    fontSize: 18,
    color: theme.palette.common.black,
    margin: "0",
  },
  cardContentDiv: {
    padding: 0,
    marginLeft: 15,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  insideCards: {
    boxSizing: "border-box",
    marginBottom: "10px",
  },
  mt20: {
    marginTop: "20px",
  },
}));
