/* eslint-disable */
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ alignLeft?: boolean }>()((theme, props) => ({
  dotForm_mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "28px 0px 1em 0px",
    "& .MuiStepper-root": {
      padding: "0",
    },
    "& .MuiStepIcon-root": {
      fontSize: "2em",
      position: "relative",
      bottom: "4px",
    },
  },
  capitalizeText: {
    textTransform: "capitalize",
  },
  personalInformation_wrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "16px",
    marginBottom: "28px",
  },
  relationship_wrapper: {
    width: "100%",
    maxWidth: "calc(min(24rem, 95vw))",
    padding: "16px",
    marginBottom: "1rem",
    textAlign: "initial",
  },
  relationship_wrapper_dashboard: {
    padding: "0 16px 24px 24px",
    width: "100%",
  },
  healthHistory_wrapper: {
    margin: "0 auto",
    width: "100%",
    maxWidth: "600px",
  },
  healthHistory_fullPage_wrapper: {
    margin: "0 auto",
    width: "100%",
    padding: "16px",
  },
  questions_wrapper: {
    padding: "16px 24px",
  },
  formTitle: {
    fontSize: props?.alignLeft ? "32px" : "22px",
    fontWeight: "bold",
    lineHeight: "normal",
    margin: "0 0 16px 0",
    textAlign: props?.alignLeft ? "initial" : "center",
    color: "#043070",
  },
  stepperDesktop: {
    margin: "1em auto 2em auto",
    maxWidth: "1200px",
    width: "100%",
  },
  stepperMobile: {
    width: "100%",
    padding: "0 24px",
  },
  actionsButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    padding: "16px 0",
    "& button": {
      [theme.breakpoints.up("md")]: {
        fontSize: "20px",
      },
    },
  },
  radioButtons_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    "& .MuiFormGroup-row": {
      height: "38px",
      "& .MuiFormControlLabel-root": {
        margin: "0 24px 0 0",
        height: "38px",
        "& .MuiIconButton-root": {
          padding: "0",
          marginRight: "4px",
        },
        "& .MuiFormControlLabel-label": {
          [theme.breakpoints.up("md")]: {
            fontSize: "20px",
          },
        },
      },
    },
  },
  radioButtons_containerCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    "& .MuiFormGroup-row": {
      height: "38px",
      justifyContent: "center",
      alignItems: "center",
      gap: "22px",
      "& .MuiFormControlLabel-root": {
        margin: "0",
        height: "38px",
        "& .MuiIconButton-root": {
          padding: "0",
          marginRight: "4px",
        },
        "& .MuiFormControlLabel-label": {
          [theme.breakpoints.up("md")]: {
            fontSize: "20px",
          },
        },
      },
    },
  },
  radioButtons_containerLeftNoGap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    "& .MuiFormGroup-row": {
      height: "38px",
      justifyContent: "start",
      alignItems: "start",
      gap: "22px",
      "& .MuiFormControlLabel-root": {
        margin: "0",
        height: "38px",
        "& .MuiIconButton-root": {
          padding: "0",
          marginRight: "4px",
        },
        "& .MuiFormControlLabel-label": {
          [theme.breakpoints.up("md")]: {
            fontSize: "14px",
          },
        },
      },
    },
  },
  timeWindowTitle: {
    fontSize: "16px",
    color: "#043070",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
  },
  titleLowerFontSize: {
    fontSize: "14px",
    color: "#043070",
  },
  centeredTitle: {
    fontSize: "18px",
    color: "#043070",
    marginTop: "8px",
    marginBottom: "8px",
    alignSelf: "center",
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
  successStep_container: {
    padding: "0 1em 1em",
    gap: "1.5em",
    margin: "0 auto",
    "& a": {
      textDecoration: "underline",
    },
  },
  generiContainer: {
    padding: "3em 0 1em 0",
    gap: "1.5em",
    width: "80%",
    margin: "0 auto",
  },
  alignedLeftQuestions: {
    textAlign: "left",
  },
  alignedSlideLeftQuestionsWithFullPage: {
    textAlign: "left",
  },
  alignedLeftQuestionsWithFullPage: {
    textAlign: "left",
    height: "400px",
    overflowY: "scroll",
  },
  alignedCenterQuestions: {
    textAlign: "center",
  },
  textArea: {
    backgroundColor: "#fff",
  },
  centeredLabel: {
    fontSize: "14px",
    color: "#043070",
    marginTop: "8px",
    marginBottom: "8px",
    alignSelf: "center",
    textAlign: "initial",
  },
  leftLabel: {
    fontSize: "14px",
    color: "#043070",
    marginTop: "8px",
    marginBottom: "8px",
    alignSelf: "flex-start",
    textAlign: "initial",
  },

  sectionTitle: {
    color: "#043070",
    marginTop: "8px",
    marginBottom: "8px",
  },
  sectionTitleMuted: {
    fontSize: "13px",
    color: theme.palette.grey[500],
    marginTop: "8px",
    marginBottom: "8px",
  },
  sectionSubtitleTitle: {
    color: "#043070",
    marginTop: "8px",
    marginBottom: "8px",
  },
  timeWindowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "65%",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  timeWindowDropdown: {
    flex: 1,
  },
  circularProgress: {
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "0px",
    bottom: "0px",
    margin: "auto",
  },
  centerMobileTitle: {
    margin: "auto",
    fontWeight: "bold",
  },
  closeButton: {
    color: theme.palette.grey[500],
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  inlineActionButtons: {
    display: "flex",
    flexDirection: "column",
    marginRight: "1em",
  },
  checkboxListLeftAlign: {
    textAlign: "left",
    marginLeft: "2em",
  },
  examinerInnerQuestionsMargin: {
    marginLeft: "2em",
    paddingRight: "2em",
    paddingBottom: "1em",
  },
  addressCardRoot: {
    cursor: "pointer",
    minWidth: 300,
    width: "auto",
    "&:hover": {
      border: "1px solid #043070",
    },
    "& .MuiCardContent-root": {
      maxWidth: "50%",
    },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
    marginBottom: "2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedAddress: {
    border: "1px solid #043070",
  },
  additionalInformationCard: {
    border: "1px solid #ff8501",
    minWidth: 150,
    maxWidth: "1100px",
    width: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 20px",
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardHeader: {
    "& p": {
      fontSize: 20,
      color: "white",
      fontWeight: "bold",
    },
  },
  cardBody: {
    backgroundColor: "white",
    padding: "5px",
    height: "275px",
    borderBottomLeftRadius: "3px",
    borderBottomRightRadius: "3px",
  },
  addressCardBody: {
    backgroundColor: "white",
    padding: "1px",
    height: "auto",
    borderBottomLeftRadius: "3px",
    borderBottomRightRadius: "3px",
    fontFamily: "Alegreya Sans",
  },
  cardUL: {
    textAlign: "left",
  },
  olLi: {
    marginBottom: "0.5em",
  },
  mutedText: {
    color: theme.palette.grey[500],
    fontWeight: "normal",
  },
  faqAnswer: {
    flexDirection: "column",
    textAlign: "left",
  },
  faqPage: {
    marginTop: "2em",
    paddingBottom: "4em",
  },
  faqSection: {
    "& > :first-child": {
      marginBottom: "0.5em",
    },
    textAlign: "left",
    marginTop: "1.5em",
  },
  faqTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  alert: {
    textAlign: "initial",
  },
  updatedBy: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "14px",
    fontStyle: "italic",
  },
  faqLink: {
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "20px",
  },
  chipsRoot: {
    display: "flex",
    justifyContent: "center",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    flexDirection: "column",
    alignItems: "start",
    width: "50%",
    overflow: "scroll",
    maxHeight: "200px",
    height: "auto",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  practiceLocation_cardRoot: {
    minWidth: 275,
    minHeight: 100,
    backgroundColor: "#eeeeee",
    padding: "5px",
    marginBottom: "16px",
    "& > div": {
      padding: "2px",
    },
    "& > div:last-child": {
      paddingBottom: "2px",
    },
  },
  practiceLocation_title: {
    fontSize: 14,
    textAlign: "initial",
    padding: "5px",
    fontWeight: "bold",
  },
  practiceLocation_cardHeader: {
    "& p": {
      fontSize: 18,
    },
  },
  practiceLocation_cardBody: {
    backgroundColor: "#D9D9D9",
    padding: "5px",
    height: 100,
    borderBottomLeftRadius: "1px",
    borderBottomRightRadius: "1px",
    fontFamily: "Alegreya Sans",
    textAlign: "initial",
    overflowY: "auto",
  },
}));

export default useStyles;
