import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import CodeVerification from "views/Authorization/CodeVerification";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { useIsMobile } from "util/deviceUtils";
import RoutesHelper from "util/routesHelper";
import { useStyles } from "styles/verificationViews/verificationViews.styles";

export default function AuthCodeLogin() {
  const { classes } = useStyles();

  const [dob, setDob] = useState(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const app = useApp();
  let { loginToken } = useParams();
  const { redirectHelper } = RoutesHelper();
  if (!loginToken || loginToken.length < 1) {
    loginToken = new URLSearchParams(location.search).get("t");
  }
  const params = new URLSearchParams(location.search);

  const syncDemographicInfo = params.get("sync_demographic_info");
  const authCode = params.get("auth_code");
  const cellPhone = params.get("cell_phone");

  const isMobile = useIsMobile();

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    if (!dob || dob.length < 6) {
      app.addError("Oops, your date of birth format isn't correct.  Please try again.");
      return;
    }

    const dobFormatted = `${dob.getMonth() > 8 ? dob.getMonth() + 1 : `0${dob.getMonth() + 1}`}/${
      dob.getDate() > 9 ? dob.getDate() : `0${dob.getDate()}`
    }/${dob.getFullYear()}`;

    await auth.tokenDobLogin(loginToken, authCode, cellPhone, dobFormatted, async (user, error) => {
      if (syncDemographicInfo && !auth?.user?.dob) {
        redirectHelper(params, undefined, "/");
        return;
      }

      if (!user || error) {
        app.addError("Invalid Date of Birth, please try again.");
        return;
      }

      if (user && user.userId) {
        await auth.checkLogin(() => {}, true);
        if (
          window.location.pathname.startsWith("/activation") &&
          user.dob &&
          user.lastName &&
          user.patientId &&
          user.patientId.length > 0
        ) {
          navigate({ pathname: "/setuserpwd", search: params.toString() });
        } else {
          redirectHelper(params, undefined, "/");
        }
      }
    });
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      wrap="nowrap"
      className={classes.root}
    >
      {!loginToken && <CodeVerification />}
      {(authCode || loginToken) && (
        <Paper elevation={!isMobile ? 24 : 0} className={classes.paper}>
          <Typography variant="h2" className={classes.title}>
            Date of Birth Verification
          </Typography>
          <Typography variant="h3" className={classes.subtitle}>
            Please verify your date of birth for security purposes
          </Typography>
          <form id="dob-form" noValidate autoComplete="off" onSubmit={handleLoginSubmit}>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              className={classes.dobContainer}
            >
              <DesktopDatePicker
                label="Date of Birth"
                format="MM/dd/yyyy"
                openTo="month"
                value={dob}
                onChange={(e) => setDob(e)}
                readOnly={false}
                slotProps={{
                  toolbar: {
                    hidden: true,
                  },
                  textField: {
                    helperText: "MM/DD/YYYY",
                    fullWidth: true,
                    inputProps: {
                      id: "dobInput",
                      readOnly: false,
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={!dob}
                data-cy="dobVerifyBtn"
              >
                Verify
              </Button>
            </Grid>
          </form>
        </Paper>
      )}
    </Grid>
  );
}
