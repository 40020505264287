import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Steps } from "../Steps";
import { makeStyles } from "tss-react/mui";
import { useUserInfo } from "hooks/useUserInfo/useUserInfo";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { createCYidfromText } from "util/deviceUtils";

const useStyles = makeStyles()(() => ({
  formControl: {
    width: "100%",
  },
  dialog: {
    minWidth: "320px",
    maxWidth: "370px",
    width: "100%",
  },
}));

export default function Consultation({ open, setOpen, setStep }) {
  const [speciality, setSpeciality] = useState("");
  const [programsData, setProgramsData] = useState();
  const { user } = useUserInfo();
  const app = useApp();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { programName } = useParams();

  const handleChange = (e) => {
    let program = e.target.value;
    setSpeciality(program);

    // Escapes '&' character whenever a program has it i.e Physical Medicine & Rehab
    if (program.includes("&")) {
      program = program.replace("&", encodeURIComponent("&"));
    }
    navigate(`/inquiryrequest/${program}`);
  };

  const handleStart = () => {
    if (user) {
      setStep(Steps.ScreeningStep);
      setOpen(false);
    } else {
      setOpen(false);
      setStep(Steps.SignInStep);
    }
  };

  const handleFetchProgramList = async () => {
    try {
      const { data } = await axiosClient.get(API_ENDPOINTS.programs, {
        params: { program_type: "consultation" },
      });
      setProgramsData(data.data);
    } catch (err) {
      app.addError("Problem Fetching Programs");
    }
  };

  useEffect(() => {
    if (programName) {
      setSpeciality(programName);
      handleStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programName]);

  useEffect(() => {
    handleFetchProgramList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialog }}
      onClose={() => setOpen(false)}
    >
      <DialogTitle id="form-dialog-title">Speak with a Care Team</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select a speciality</DialogContentText>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Specialities</InputLabel>
          {programsData?.length > 0 && (
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={speciality}
              onChange={handleChange}
              data-testid="select"
              data-cy="injuiryType"
            >
              {programsData?.map((option) => {
                return (
                  <MenuItem
                    value={option.name}
                    key={option.name}
                    data-cy={createCYidfromText(option.name)}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleStart} disabled={!speciality}>
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
}
