import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import OrderProgressBar from "../components/OrderProgressBar";
import ScannedCodeCard from "../components/ScannedCodeCard";
import { useProgramsLoading } from "views/console/components/useProgramsLoading";
import useTranslation from "hooks/useTranslation";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";

const ScannedCodesList = ({
  scannedCodes,
  setScannedCodes,
  onConfirmSpecimens,
  actionType,
  dobFormatted,
}) => {
  const { classes } = useSpecimenStyles();
  const navigate = useNavigate();
  const {
    web: {
      common: { cancel, save, yes, no },
      specimenTrackingAdd: {
        listTitleAdd,
        listTitleDrop,
        listTitlePick,
        removeSpecimen,
        emptyListAdd,
        emptyListDrop,
        emptyListPick,
        codes,
        code,
      },
    },
  } = useTranslation();
  const { isProgramsLoading } = useProgramsLoading();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const removeScannedCode = (id: number) => {
    const newScannedCodes = scannedCodes.filter(({ data }) => data.id !== id);
    setScannedCodes(newScannedCodes);
    setShowDeleteModal(false);
  };

  const getSpecimenId = () => {
    return scannedCodes.find(({ data }) => data.id === deleteId)?.data.specimenExternalId;
  };

  const formatValues = (code, inputDOB) => {
    return {
      description: code.data.description,
      orderDob: inputDOB,
      orderMrn: code.data.orderMrn,
      orderOrderExternalId: code.data.orderOrderExternalId,
      orderPatientFirstName: code.data.orderPatientFirstName,
      orderPatientLastName: code.data.orderPatientLastName,
      specimenExternalId: code.data.specimenExternalId,
      specimenType: code.data.specimenType,
    };
  };

  const getEmptyListTitle = () => {
    let emptyListText = "";

    switch (actionType) {
      case "DROP_OFF":
        emptyListText = emptyListDrop;
        break;
      case "PICK_UP":
        emptyListText = emptyListPick;
        break;
      default:
        emptyListText = emptyListAdd;
        break;
    }

    return emptyListText;
  };

  const getListTitle = () => {
    let listTitle = "";

    switch (actionType) {
      case "DROP_OFF":
        listTitle = listTitleDrop;
        break;
      case "PICK_UP":
        listTitle = listTitlePick;
        break;
      default:
        listTitle = listTitleAdd;
        break;
    }

    return listTitle;
  };

  const quantity = `${scannedCodes.length} ${scannedCodes.length > 1 ? codes : code}`;

  const getSpecimenCount = (specimenCountAdd, specimenCountPickOrDrop) => {
    return actionType === "ADD" ? specimenCountAdd : specimenCountPickOrDrop;
  };

  const groupedScannedCodes = scannedCodes.reduce((result, item) => {
    const externalId = item.data.orderOrderExternalId;
    const updatedResult = { ...result };

    if (!updatedResult[externalId]) {
      updatedResult[externalId] = [];
    }

    updatedResult[externalId].push(item);
    return updatedResult;
  }, {});

  const groupedSpecimensByOrderId = Object.keys(groupedScannedCodes).map((externalId) => (
    <div key={externalId}>
      <OrderProgressBar
        actionType={actionType}
        orderId={externalId}
        specimensCount={getSpecimenCount(
          groupedScannedCodes[externalId].length,
          groupedScannedCodes[externalId][0].data.orderConfirmedSpecimenCount,
        )}
        scannedCodes={groupedScannedCodes[externalId].length}
      />
      {groupedScannedCodes[externalId].map((code, index) => {
        return (
          <ScannedCodeCard
            key={code.data.specimenExternalId}
            code={code}
            index={index}
            setShowDeleteModal={setShowDeleteModal}
            setDeleteId={setDeleteId}
            scannedCodes={scannedCodes}
            setScannedCodes={setScannedCodes}
            dobFormatted={dobFormatted}
            formatValues={formatValues}
          />
        );
      })}
    </div>
  ));

  return (
    <Box style={{ paddingBottom: "4em" }}>
      {!scannedCodes.length ? (
        <Typography className={classes.noData}>{getEmptyListTitle()}</Typography>
      ) : (
        <Box>
          <Typography className={classes.title}>{getListTitle()}</Typography>
          {groupedSpecimensByOrderId}
          <Dialog open={showDeleteModal} aria-labelledby="help-dialog-title" fullWidth>
            <DialogTitle id="help-dialog-title" className={classes.dialogTitle}>
              {`${removeSpecimen} ${getSpecimenId()}?`}
            </DialogTitle>
            <DialogActions className={classes.confirmationModal}>
              <Button onClick={() => setShowDeleteModal(false)} variant="contained" autoFocus>
                {no}
              </Button>
              <Button
                onClick={() => {
                  removeScannedCode(deleteId);
                }}
                color="primary"
                variant="contained"
                autoFocus
              >
                {yes}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
      <Box display="flex" alignItems="center" className={classes.actionButtonsContainer}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "100%" }}
          onClick={() => {
            navigate("/console/specimen-tracking");
          }}
          disabled={isProgramsLoading}
        >
          {cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!scannedCodes.length}
          style={{ width: "100%" }}
          onClick={() => onConfirmSpecimens()}
          data-cy="btn-specimen-confirm"
          data-testid="btn-confirm-specimens"
        >
          {save} {quantity}
        </Button>
      </Box>
    </Box>
  );
};

export default ScannedCodesList;
