import React from "react";
import { useParams } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { QuerySteps } from "../../Steps";
import { useConsultationStyles } from "components/Consultation/styles";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

interface ScreeningProps {
  setStep: (queryStep: QuerySteps) => void;
}

const Screening = ({ setStep }: ScreeningProps) => {
  const { classes } = useConsultationStyles();
  const app = useApp();
  const { vsId } = useParams<{ vsId: string }>();

  const {
    web: {
      common: { errorMsg, yes, no, next, requiredQuestionserrorMsg },
      screeningPT: {
        titleScreening,
        havePrescription,
        yesHavePrescription,
        noHavePrescription,
        popUpNoPrescription,
        vehicleAccident,
        workersCompensationClaim,
        surgeryInformation,
        haveSurgery,
        ifHaveSurgery,
        yesHaveSurgery,
      },
    },
  } = useTranslation();

  const createVirtualSession = async (
    { hadSurgery, diagnosisDetails, workersCompensation, motorVehicleAccident }: typeof values,
    actions: FormikHelpers<typeof values>,
  ) => {
    const patientReason = "PT inquiry";
    try {
      const { data } = await axiosClient.patch(`/api/s/virtualvisit/sessions/${vsId}`, {
        patient_reason: patientReason,
        surgery: hadSurgery,
        workersCompensation,
        motorVehicleAccident,
        ...(!!diagnosisDetails && { visit_details: diagnosisDetails }),
      });
      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: data?.data,
      });
      setStep(QuerySteps.UPLOAD);
    } catch (err) {
      let error = errorMsg;
      if (!hadSurgery || !workersCompensation || !motorVehicleAccident || !diagnosisDetails) {
        error = requiredQuestionserrorMsg;
      }
      app.addError(error);
    }
    actions.setSubmitting(false);
  };

  const { values, handleChange, handleSubmit, setFieldValue, isSubmitting } = useFormik({
    initialValues: {
      diagnosis: null,
      motorVehicleAccident: null,
      workersCompensation: null,
      hadSurgery: "",
      diagnosisDetails: "",
    },
    onSubmit: createVirtualSession,
  });

  return (
    <div data-testid="PTScreeningStep">
      <Typography variant="h2" className={classes.pTHeading}>
        {titleScreening}
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth variant="outlined" className={classes.radioFormControl}>
          <FormLabel className={classes.label} id="pt-diagnosis-radio-buttons-group-label">
            {havePrescription}
          </FormLabel>
          <RadioGroup
            row
            aria-label="diagnosis"
            name="diagnosis"
            value={values.diagnosis}
            className={classes.radioGroup}
            onChange={(e) => setFieldValue("diagnosis", JSON.parse(e.target.value))}
            data-testid="radioDiagnosis"
          >
            <Box paddingTop={1} display="flex" flexDirection="column" width="100%">
              <FormControlLabel
                className={classes.label}
                value
                control={<Radio color="primary" />}
                label={yesHavePrescription}
                data-cy="diagnosisYes"
                data-testid="diagnosisYes"
              />
              <FormControlLabel
                className={classes.label}
                value={false}
                control={<Radio color="primary" />}
                label={noHavePrescription}
                data-cy="diagnosisNo"
                data-testid="diagnosisNo"
              />
            </Box>
          </RadioGroup>
          {/* we only need to show it when its false, if we do !values.diagnosis it will appear when its null */}
          {values.diagnosis !== null && values.diagnosis === false && (
            <FormLabel className={classes.sublabelDiagnosis} data-testid="diagnosisNoMessage">
              {popUpNoPrescription}
            </FormLabel>
          )}
        </FormControl>
        {values.diagnosis !== null && values.diagnosis === true && (
          <>
            <FormControl fullWidth variant="outlined" className={classes.radioFormControl}>
              <FormLabel
                className={classes.label}
                id="pt-motor-vehicle-accident-radio-buttons-group-label"
              >
                {vehicleAccident}
              </FormLabel>
              <RadioGroup
                row
                aria-label="motorVehicleAccident"
                name="motorVehicleAccident"
                value={values.motorVehicleAccident}
                className={classes.radioGroup}
                onChange={(e) => setFieldValue("motorVehicleAccident", JSON.parse(e.target.value))}
              >
                <Box paddingTop={1} display="flex" flexDirection="column" width="100%">
                  <FormControlLabel
                    className={classes.label}
                    value
                    control={<Radio color="primary" />}
                    label={yes}
                    data-cy="motorVehicleYes"
                    data-testid="motorVehicleYes"
                  />
                  <FormControlLabel
                    className={classes.label}
                    value={false}
                    control={<Radio color="primary" />}
                    label={no}
                  />
                </Box>
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth variant="outlined" className={classes.radioFormControl}>
              <FormLabel
                className={classes.label}
                id="pt-workers-compensation-radio-buttons-group-label"
              >
                {workersCompensationClaim}
              </FormLabel>
              <RadioGroup
                row
                aria-label="workersCompensation"
                name="workersCompensation"
                value={values.workersCompensation}
                className={classes.radioGroup}
                onChange={(e) => setFieldValue("workersCompensation", JSON.parse(e.target.value))}
              >
                <Box paddingTop={1} display="flex" flexDirection="column" width="100%">
                  <FormControlLabel
                    className={classes.label}
                    value
                    control={<Radio color="primary" />}
                    label={yes}
                  />
                  <FormControlLabel
                    className={classes.label}
                    value={false}
                    control={<Radio color="primary" />}
                    label={no}
                    data-cy="compensationNo"
                    data-testid="compensationNo"
                  />
                </Box>
              </RadioGroup>
            </FormControl>
            <Typography variant="h2" className={classes.pTHeading}>
              {surgeryInformation}
            </Typography>
            <FormControl variant="standard" className={classes.formControlContainer}>
              <FormControl variant="outlined" className={classes.radioFormControl}>
                <FormLabel className={classes.label} id="pt-surgery-radio-buttons-group-label">
                  {haveSurgery}
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="hadSurgery"
                  name="hadSurgery"
                  value={values.hadSurgery}
                  className={classes.radioGroup}
                  onChange={handleChange}
                  data-testid="radioSurgery"
                >
                  <Box paddingTop={1} display="flex" flexDirection="column" width="100%">
                    <FormControlLabel
                      className={classes.label}
                      value={yes}
                      control={<Radio color="primary" />}
                      label={yesHaveSurgery}
                      data-cy="surgeryYes"
                      data-testid="surgeryYes"
                    />
                    <FormControlLabel
                      className={classes.label}
                      value={no}
                      control={<Radio color="primary" />}
                      label={no}
                      data-cy="surgeryNo"
                      data-testid="surgeryNo"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="outlined-multiline-static"
                  label={ifHaveSurgery}
                  multiline
                  fullWidth
                  minRows={8}
                  variant="outlined"
                  value={values.diagnosisDetails}
                  onChange={handleChange}
                  name="diagnosisDetails"
                  InputLabelProps={{
                    classes: {
                      root: classes.textLabel,
                    },
                  }}
                  data-testid="diagnosisDetails"
                  data-cy="diagnosisDetails"
                />
                {!values.diagnosisDetails && <FormHelperText>(Optional)</FormHelperText>}
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                data-cy="nextButton"
                disabled={!values.diagnosis || isSubmitting}
              >
                {next}
              </Button>
            </FormControl>
          </>
        )}
      </form>
    </div>
  );
};

export default Screening;
