/* eslint-disable */
import React, { useContext, useState } from "react";

const MessagingContext = React.createContext();
MessagingContext.displayName = "MessagingContext";

export const MessagingProvider = ({ children }) => {
  const [membersList, setMembersList] = useState([]);
  const [assignedPatients, setAssignedPatients] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [filterBy, setfilterBy] = useState("assigned_to_me");

  return (
    <MessagingContext.Provider
      value={{
        membersList,
        setMembersList,
        assignedPatients,
        setAssignedPatients,
        selectedPatientId,
        setSelectedPatientId,
        isNewPatient,
        setIsNewPatient,
        filterBy,
        setfilterBy,
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export const useMessaging = () => {
  return useContext(MessagingContext);
};
