import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ isActive: boolean }>()((_theme, { isActive }) => ({
  appCard: {
    backgroundColor: isActive ? "rgba(0, 0, 0, 0.12)" : "unset",
    marginBottom: 16,
    borderRadius: 10,
  },
  title: {
    fontSize: "22px",
    lineHeight: "22px",
    margin: "12px 0px 0",
    fontWeight: 500,
  },
  cardDesc: {
    fontSize: "16px",
    lineHeight: "24px",
  },
}));

export default useStyles;
