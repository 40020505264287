import React from "react";
import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { addSpecimenDataInputs } from "views/SpecimenTracking/utils/addSpecimenInputFields";
import { SpecimenAddInitialValues, SpecimenTrackAction } from "views/SpecimenTracking/types";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";

interface Props {
  isDisabled: boolean;
  values: SpecimenAddInitialValues | null;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setInputDOB: React.Dispatch<React.SetStateAction<null | undefined | Date>>;
  inputDOB?: null | Date;
}

const InputsDataHandler: React.FC<Props> = ({
  isDisabled,
  values,
  handleChange,
  setInputDOB,
  inputDOB,
}) => {
  const { classes } = useSpecimenStyles();
  const dataInputs = addSpecimenDataInputs(isDisabled);
  const params = new URLSearchParams(window.location.search);
  const actionType = (params.get("actionType") as SpecimenTrackAction) || SpecimenTrackAction.ADD;

  const disableSpecimenId = (id: string, disabled: boolean) => {
    if (id === "specimenId" && actionType !== SpecimenTrackAction.ADD) {
      return true;
    }
    return disabled;
  };

  return (
    <FormControl variant="standard" className={classes.formContainer}>
      {dataInputs.map(
        ({ dataCy, id, label, disabled, required, onClick, autoFocus, type, isDOB }, index) => (
          <Box
            key={id + index.toString()}
            className={classes.formInfoContainer}
            data-testid="inputsHandler"
          >
            <Box display="flex" flexDirection="row">
              <Box display="flex" alignItems="center">
                <FormLabel className={classes.textLabel}>{`${label}:`}</FormLabel>
              </Box>
              <Box display="flex" style={{ flex: 1 }}>
                {isDOB ? (
                  <DatePicker
                    format="MM/dd/yyyy"
                    openTo="month"
                    value={inputDOB ? new Date(inputDOB) : null}
                    onChange={(e) => setInputDOB(e)}
                    data-cy="InputDateOfBirth"
                    data-testid="InputDateOfBirth"
                    disabled={disableSpecimenId(id, disabled)}
                    slotProps={{
                      textField: {
                        className: classes.inputTextField,
                      },
                      inputAdornment: {
                        classes: {
                          root: classes.textLabel,
                        },
                      },
                    }}
                  />
                ) : (
                  <TextField
                    variant="standard"
                    type={type}
                    autoFocus={autoFocus}
                    data-cy={dataCy}
                    data-testid={id}
                    disabled={disableSpecimenId(id, disabled)}
                    required={required}
                    className={classes.inputTextField}
                    id={id}
                    name={id}
                    value={values?.[id] || ""}
                    size="small"
                    onChange={handleChange}
                    onClick={onClick}
                    InputProps={{
                      classes: {
                        input: classes.textInput,
                      },
                      disableUnderline: disabled,
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.textLabel,
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        ),
      )}
    </FormControl>
  );
};

export default InputsDataHandler;
