import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useStyles } from "./TimeSlotsOverwrite.styles";
import TimePicker from "components/shared/DatePickers/TimePicker/TimePicker";
import InputErrorMsg from "components/shared/InputErrorMsg/InputErrorMsg";
import { getDateTimeFromTimeString, getStrTimeFromDate } from "util/dateHelpers";
import { capitalizeFirstChar } from "util/util_functions";
import { TimeSlotsOverwriteProps } from "./types";
import { TimeSlotsErrors } from "views/Config/OverwriteConfigModal/types";

const TimeSlotsOverwrite = ({ daysToOverwrite, timeSlots, formik }: TimeSlotsOverwriteProps) => {
  const { setFieldValue, getFieldProps, errors } = formik;
  const timeSlotsErrors = errors.time_slots as unknown as TimeSlotsErrors;
  const { classes } = useStyles();
  const [timeSlotsOverwrite, setTimeSlotsOverwrite] = useState(timeSlots);
  const [capacity, setCapacity] = useState<number>(timeSlots.capacity);
  const [duration, setDuration] = useState<number>(timeSlots.duration);

  const handleChangeTime = (date: Date, day: string, time: string) => {
    setFieldValue(`time_slots.working_hours.${day}.${time}`, date);
    const newTime = getStrTimeFromDate(date);
    setTimeSlotsOverwrite((prev) => ({
      ...prev,
      working_hours: {
        ...prev.working_hours,
        [day]: { ...prev.working_hours[day], [time]: newTime },
      },
    }));
  };

  const handleChangeCapacity = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCapacity(Number(event.target.value));
    formik.setFieldValue("time_slots.capacity", Number(event.target.value));
  };

  const handleChangeDuration = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDuration(Number(event.target.value));
    formik.setFieldValue("time_slots.duration", Number(event.target.value));
  };

  return (
    <>
      <Typography variant="h4" align="left" className={classes.title}>
        Time Slots
      </Typography>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.rowContainer}
      >
        <Grid item md={3}>
          <Typography variant="body1">Capacity</Typography>
        </Grid>
        <Grid item md={9} className={classes.inputContainer}>
          <TextField
            type="number"
            label="Capacity"
            variant="outlined"
            fullWidth
            value={capacity}
            onChange={handleChangeCapacity}
            inputProps={{ min: 0 }}
            {...getFieldProps}
            error={Boolean(timeSlotsErrors?.capacity)}
          />
        </Grid>
        <InputErrorMsg error={timeSlotsErrors?.capacity} />
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.rowContainer}
      >
        <Grid item md={3}>
          <Typography variant="body1">Slot minutes</Typography>
        </Grid>
        <Grid item md={9} className={classes.inputContainer}>
          <TextField
            type="number"
            label="Time slots minutes"
            variant="outlined"
            fullWidth
            value={duration}
            onChange={handleChangeDuration}
            inputProps={{ min: 1 }}
            {...getFieldProps}
            error={Boolean(timeSlotsErrors?.duration)}
          />
        </Grid>
        <InputErrorMsg error={timeSlotsErrors?.duration} />
      </Grid>

      {daysToOverwrite.map((day) => (
        <Grid container alignItems="center" justifyContent="space-between" key={day}>
          <Grid item md={3}>
            <Typography variant="body1">{capitalizeFirstChar(day)}</Typography>
          </Grid>
          <Grid item md={9}>
            <Grid container justifyContent="space-between" className={classes.daysContainer}>
              <Grid item className={classes.timeInputContainer}>
                <TimePicker
                  selectedTime={getDateTimeFromTimeString(
                    timeSlotsOverwrite.working_hours[day].start_time,
                  )}
                  label="From Hours"
                  onChangeHandler={(date) => {
                    handleChangeTime(date, day, "start_time");
                  }}
                  timeInterval={30}
                  error={Boolean(timeSlotsErrors?.working_hours?.[day]?.start_time)}
                  minHours={6}
                  maxHours={21}
                />
                <InputErrorMsg error={timeSlotsErrors?.working_hours?.[day]?.start_time} />
              </Grid>

              <Grid item className={classes.timeInputContainer}>
                <TimePicker
                  selectedTime={getDateTimeFromTimeString(
                    timeSlotsOverwrite.working_hours[day].end_time,
                  )}
                  label="To Hours"
                  onChangeHandler={(date) => {
                    handleChangeTime(date, day, "end_time");
                  }}
                  timeInterval={30}
                  error={Boolean(timeSlotsErrors?.working_hours?.[day]?.end_time)}
                  minHours={6}
                  maxHours={21}
                />
                <InputErrorMsg error={timeSlotsErrors?.working_hours?.[day]?.end_time} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default TimeSlotsOverwrite;
