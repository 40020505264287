import React, { useEffect, useState } from "react";
import PatientProfileCard from "components/Video/components/PreJoinScreensPhysician/VirtualVisitDetails/PatientProfileCard";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import ReplaceThisPatientSearch from "views/console/components/PatientSearch";
import { Maybe, User, VirtualSession } from "../Video/utils/types";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

type SearchByMRNProps = {
  patient: User;
  setPatient: React.Dispatch<React.SetStateAction<User>>;
  virtualSession: VirtualSession;
};

const SearchAndMatchWidget: React.FC<SearchByMRNProps> = ({
  patient,
  setPatient,
  virtualSession,
}) => {
  const app = useApp();

  const [firstName, setFirstName] = useState<Maybe<string>>("");
  const [lastName, setLastName] = useState<Maybe<string>>("");
  const [dob, setDob] = useState<Maybe<string>>("");
  const [cellPhone, setCellPhone] = useState<Maybe<string>>("");
  const [zip, setZip] = useState<Maybe<string>>("");
  const [MRN, setMRN] = useState<Maybe<string>>("");
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  useEffect(() => {
    setFirstName(patient.first_name || "");
    setLastName(patient.last_name || "");
    setDob(patient.dob || "");
    setCellPhone(patient.cell_phone || "");
    setZip(patient.zip || "");
    setMRN(patient.emr_mrn || "");
  }, [patient]);

  const onConfirmMatch = async (matchPatient) => {
    if (!matchPatient) {
      return;
    }

    try {
      setIsConfirmLoading(true);
      const response = await axiosClient.post(API_ENDPOINTS.confirm_match_patient, {
        userID: patient.id,
        MRN: matchPatient.MRN,
        patientID: matchPatient.patientId,
      });
      const { data } = response.data;

      app.addInfoMsg("Patient matched successfully.");
      setPatient(data);
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 400) {
        app.addError(
          "Failed to update patient user with MRN, another active user already is linked to this MRN, can search and compare with Patient Report.",
        );
      } else {
        app.addError("Failed to update patient user with MRN, please try again or contact support");
      }
    } finally {
      setIsConfirmLoading(false);
    }
  };

  return !patient.emr_mrn ? (
    // @ts-expect-error
    <ReplaceThisPatientSearch
      firstName={firstName}
      lastName={lastName}
      dob={dob}
      zip={zip}
      cellPhone={cellPhone}
      gender={patient.gender}
      setFirstName={setFirstName}
      setLastName={setLastName}
      setDob={setDob}
      setZip={setZip}
      MRN={MRN}
      setCellPhone={setCellPhone}
      setMRN={setMRN}
      ConfirmBtnTitle="Confirm Match"
      currentVirtualVisit={virtualSession}
      onConfirmMatch={onConfirmMatch}
      vvId={virtualSession.virtual_session_id}
      isConfirmLoading={isConfirmLoading}
    />
  ) : (
    <PatientProfileCard
      // Commented out fields are optional in this component.
      // For now they are disabled, but should be added.
      profile={{
        first_name: patient.first_name,
        last_name: patient.last_name,
        addr_str_1: patient.addr_str_1,
        addr_str_2: patient.addr_str_2,
        city: patient.city,
        dob: patient.dob,
        // patientPhoto
        emr_mrn: patient.emr_mrn,
        cell_phone: patient.cell_phone,
        zip: patient.zip,
        gender: patient.gender,
        // proxy_owner_user
      }}
    />
  );
};

export default SearchAndMatchWidget;
