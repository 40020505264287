/* eslint-disable */
import React, { useCallback, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import ChatWindow from "../../components/Video/components/ChatWindow/ChatWindow";
import useChatContext from "../../components/Video/hooks/useChatContext/useChatContext";
import useGetPatientMessages from "../../components/Video/hooks/useGetPatientMessages/useGetPatientMessages";
import { useGetPatientVS } from "../../hooks/useGetPatientVS/useGetPatientVS";
import { useConversationStyles } from "./useConversationStyles";
import { useApp } from "../../util/AppContext";
import { axiosClient } from "../../util/api_helper";
import { MessageFilters } from "./types";

const ConversationListItem = () => {
  const vsId = Number(useParams<{ vsId: string }>().vsId);
  const [messageFilter]: any = useOutletContext();

  const { classes } = useConversationStyles();
  const { connect: chatConnect, convRecvd, messages, setLoading, loading } = useChatContext();
  const app = useApp();
  const readOnlyChat = messageFilter === MessageFilters.CLOSED;

  const getNewToken = useCallback(async () => {
    try {
      const url = `/api/s/virtualvisit/sessions/${vsId}/outroom-token`;
      const { data } = await axiosClient.get(url);
      chatConnect(data?.token);
    } catch (error) {
      console.error(error);
      app.addError("Problem Joining Virtual Session.");
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  const { data: readOnlyMessages, refetch } = useGetPatientMessages({
    enabled: false,
    vsId,
  });

  const getPatientVs = useGetPatientVS();

  useEffect(() => {
    if (vsId) {
      getPatientVs.getMessages({ vsId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  useEffect(() => {
    if (vsId && messageFilter === MessageFilters.ACTIVE) {
      getNewToken();
    } else {
      refetch();
    }
  }, [getNewToken, refetch, vsId, messageFilter]);

  return (
    <>
      {loading && !convRecvd ? (
        <div className={classes.flexCenter}>
          <Loader text="" />
        </div>
      ) : (
        <ChatWindow
          isReadOnly={readOnlyChat}
          messages={readOnlyChat ? readOnlyMessages?.data?.data : messages}
          vsId={vsId}
        />
      )}
    </>
  );
};

export default ConversationListItem;
