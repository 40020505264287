import React from "react";
// requires a loader
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Link from "@mui/material/Link";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  linkRoot: {
    display: "inline-block",
    position: "absolute",
    top: 0,
    left: 0,
    fontWeight: "bold",
    backgroundColor: theme.palette.info.light,
    color: theme.palette.common.white,
    padding: 5,
  },
}));

const ImageCarousal = ({ fullScreen, open, handleClose, images }) => {
  const { classes } = useStyles();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      style={{ background: "transparent" }}
    >
      <DialogContent>
        <Carousel>
          {images.map((image) => (
            <div key={image.url}>
              <img src={image.url} alt="" />
              <Link
                classes={{
                  root: classes.linkRoot,
                }}
                href={image.url}
                download
                underline="none"
              >
                Download
              </Link>
            </div>
          ))}
        </Carousel>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCarousal;
