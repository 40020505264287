import React, { ReactNode, useEffect, useState } from "react";
import axios from "axios";
import WarningModal from "./WarningModal";

interface ErrorHandlerProps {
  children: ReactNode;
  axiosClient: typeof axios;
}

const ErrorBoundaryAxios: React.FC<ErrorHandlerProps> = ({ children, axiosClient }) => {
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    const reqInterceptor = axiosClient.interceptors.request.use((req) => {
      setError(null);
      return req;
    });

    const resInterceptor = axiosClient.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error.response && error.response.status === 401) {
          setError("Unauthorized");
          return Promise.reject(new Error("Unauthorized"));
        }
        return Promise.reject(error);
      },
    );
    return () => {
      axiosClient.interceptors.request.eject(reqInterceptor);
      axiosClient.interceptors.response.eject(resInterceptor);
    };
  }, [axiosClient]);

  useEffect(() => {
    if (error === "Unauthorized") {
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const closeModal = () => {
    setError(null);
    window.location.href = "/";
  };

  return (
    <>
      {error && (
        <WarningModal
          isShown={!!error}
          goBack={closeModal}
          text="Your Session has expired, Please log in again"
        />
      )}
      {children}
    </>
  );
};

export default ErrorBoundaryAxios;
