import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  formControl: {
    display: "flex",
    width: "100%",
    "& .MuiSelect-outlined": {
      padding: "18.5px 14px",
    },
    "& .MuiTextField-root": {
      width: "100%",
      margin: "10px 0",
      "& input": {
        padding: "18.5px 14px",
      },
    },
    "& button": {
      margin: "10px 0",
    },
  },
  dialogContainer: {
    "& .MuiPaper-root": {
      minWidth: 330,
    },
    "& h2": {
      margin: 5,
      textAlign: "center",
      fontSize: "1.5rem",
    },
    "& button": {
      width: "100%",
    },
  },
  dialogContent: {
    padding: "10px 32px",
  },
  dialogContentText: {
    margin: 0,
    fontSize: "1.6rem",
    color: theme.palette.common.black,
    textAlign: "center",
  },
  teamMembersContainer: {
    display: "flex",
    flexDirection: "column",
  },
  teamMember: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
  },
  teamMembersHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: 332,
    margin: "10px auto",
    padding: "20px 0",
    "& p": {
      margin: 0,
      alignSelf: "center",
      fontSize: 25,
    },
  },
  paperContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    marginBottom: 8,
    width: 312,
    maxHeight: 500,
  },
  membersContainer: {
    width: 675,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "left",
    background: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    padding: "12px",
    "& h4": {
      marginBottom: 8,
      marginTop: 8,
    },
  },
  iconContainer: {
    position: "relative",
    display: "flex",
    "& svg": {
      "&:hover": {
        fill: theme.palette.secondary.main,
      },
    },
  },
  memberInfoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    "& p": {
      margin: 0,
    },
    "& p:first-child": {
      fontWeight: 700,
      fontSize: "1.2rem",
    },
    "& .MuiSelect-root": {
      padding: 3,
    },
  },
}));

export default useStyles;
