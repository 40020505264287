import { useQuery } from "react-query";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";
import { PracticeLocation } from "views/DataCuration/ProviderProfile/types";

async function searchLocations(searchText: string) {
  const response = await axiosClient.get(API_ENDPOINTS.dataCurationLocations, {
    params: {
      search_by: "nameFull",
      search_text: searchText,
      page_size: 50,
    },
  });
  if (response?.data?.status === "error") {
    throw new Error("Error searching locations");
  }
  return response?.data?.data as PracticeLocation[];
}

const useSearchLocations = (searchText: string) =>
  useQuery(
    [QUERY_KEYS.dataCuration, QUERY_KEYS.searchLocations, searchText],
    () => searchLocations(searchText),
    {
      enabled: !!searchText,
    },
  );

export { useSearchLocations };
