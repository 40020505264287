import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack, Check } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useSpecimenStyles } from "views/SpecimenTracking/styles";

const SpecimenTrackingReportAnIssueView = () => {
  const app = useApp();
  const auth = useAuth();
  const userEmail = auth.user.email;
  const { classes } = useSpecimenStyles();
  const navigate = useNavigate();
  const {
    web: {
      specimenTrackingHelpCenter: {
        reportAnIssue: {
          title,
          text,
          buttonText,
          emailSubject,
          successfulReportedText,
          returnToSpecimenTracking,
          errorMsg,
        },
      },
    },
  } = useTranslation();

  const [emailBody, setEmailBody] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const emailTemplate = `
    An issue has been reported in Specimen Tracking within the Digital Experience Platform App.

    Reported by: ${userEmail}
    Issue reported:
    ${emailBody}

    Please follow up as needed to resolve the issue.
  `;

  const sendEmail = async () => {
    setIsLoading(true);
    try {
      const res = await axiosClient.post(API_ENDPOINTS.specimenTrackingSendReportedIssueEmail, {
        subject: emailSubject,
        email_body: emailTemplate,
      });

      if (res.status === 200) {
        setShowSuccess(true);
      }
    } catch {
      app.addError(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className={classes.helpCenter_wrapper}>
      <header>
        <IconButton
          onClick={() => navigate("/console/specimen-tracking/help-center")}
          data-testid="backButton"
        >
          <ArrowBack />
        </IconButton>
        <Typography fontWeight={600} fontSize="18px" position="relative" top="6px">
          {title}
        </Typography>
      </header>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box maxWidth="600px" margin="0 auto">
          {showSuccess ? (
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              justifyContent="center"
              alignItems="center"
            >
              <Typography whiteSpace="pre-wrap" fontWeight="500">
                {successfulReportedText}
              </Typography>
              <Check style={{ width: "60px", height: "60px", opacity: 0.6 }} />
              <Button
                onClick={() => navigate("/console/specimen-tracking")}
                variant="contained"
                style={{ width: "100%", maxWidth: "600px" }}
              >
                {returnToSpecimenTracking}
              </Button>
            </Box>
          ) : (
            <Box padding="0 8px">
              <Typography fontWeight={500} textAlign="left">
                {text}
              </Typography>
              <TextField
                value={emailBody}
                onChange={(e) => setEmailBody(e.target.value)}
                variant="outlined"
                multiline
                minRows={4}
                placeholder="Type your issue here"
                style={{ width: "100%", margin: "8px 0" }}
              />
              <Button
                onClick={sendEmail}
                disabled={!emailBody}
                variant="contained"
                fullWidth
                style={{ maxWidth: "600px" }}
              >
                {buttonText}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </main>
  );
};

export default SpecimenTrackingReportAnIssueView;
