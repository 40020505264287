import { useEffect, useState } from "react";
import { FormikHelpers } from "formik";
import { FormValues } from "./formModel";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const useGetInsuranceInfo = (setFieldValue: FormikHelpers<FormValues>["setFieldValue"]) => {
  const app = useApp();
  const [insuranceCompaniesRefList, setInsuranceCompaniesRefList] = useState<
    Array<{ id: string; name: string }>
  >([]);

  const GetInsuranceCompanies = async () => {
    try {
      const uri = "/api/console/referral/insurance-company-list";
      const { data } = await axiosClient.get(uri);
      const topInsuranceCompanies = data.data;
      setInsuranceCompaniesRefList(
        [{ id: "-1", name: "Self Pay / No Insurance" }].concat(topInsuranceCompanies),
      );
    } catch (error) {
      console.error(error);
      app.addError("Problem loading insurance companies list.");
      setInsuranceCompaniesRefList([]);
    }
  };

  const GetPcp = async () => {
    try {
      const uri = "/api/s/fhir/primarycareprovider";
      const { data } = await axiosClient.get(uri);

      if (data?.pcp?.PCPs?.length) {
        const pcp = data.pcp.PCPs[0];
        setFieldValue("primaryCarePhysicianName", pcp.name);
      }
    } catch (error) {
      app.addError("Problem loading Primary care physician.");
      console.error(error);
    }
  };

  const GetCoverage = async () => {
    try {
      const uri = "/api/s/fhir/coverage";
      const { data } = await axiosClient.get(uri);
      if (data.coverage && data.coverage.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let latestCoverage: any = null;
        data.coverage.forEach((coverage) => {
          if (coverage.coveragePeriodStartDate) {
            if (!latestCoverage) {
              latestCoverage = coverage;
              return;
            }
            const d1 = Date.parse(coverage.coveragePeriodStartDate);
            const d2 = Date.parse(latestCoverage.coveragePeriodStartDate);
            if (d1 > d2) {
              latestCoverage = coverage;
            }
          }
        });

        if (latestCoverage) {
          const planId = latestCoverage.payorId;
          setFieldValue("insuranceCompanyId", planId);
        }
      }
    } catch (error) {
      app.addError("Problem loading coverage.");
      console.error(error);
    }
  };

  useEffect(() => {
    GetInsuranceCompanies();
    GetPcp();
    GetCoverage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    insuranceCompaniesRefList,
    setInsuranceCompaniesRefList,
  };
};

export default useGetInsuranceInfo;
