/** @format */
import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  rootDiv: {
    padding: "5px 30px",
    fontFamily: "'Times New Roman', Times, serif",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  root: {
    textDecoration: "underline",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "5px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  para: {
    fontSize: "14px",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      textAlign: "justify",
      "& ul": {
        paddingLeft: "30px",
      },
    },
  },
}));
const HippaDocument = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.rootDiv}>
      <Typography variant="h6" className={classes.root}>
        HIPAA Authorization
      </Typography>
      <Typography component="p" className={classes.para}>
        I hereby authorize the disclosure of my Protected Health Information (as defined below) by
        Atlantic Health System and its personnel (“<b>AHS</b>
        ”) and the use of my <b>Protected Health Information</b> by Alclear Healthpass, LLC and its
        personnel (“<b>CLEAR</b>”) as described in this HIPAA authorization form (“
        <b>authorization</b>”). I understand that AHS will not condition my treatment, payment,
        enrollment or eligibility for benefits on whether I sign this authorization.
      </Typography>
      <Typography component="p" className={classes.para}>
        For purposes of this authorization, "Protected Health Information" refers to the following:
      </Typography>
      <ul>
        <li>My name; and</li>
        <li>
          Details regarding COVID-19 vaccine(s) that have been administered to me - namely, the
          vaccine manufacturer for the vaccine that was administered to me and the date(s) on which
          such vaccine(s) were administered to me.
        </li>
      </ul>
      <Typography component="p" className={classes.para}>
        I authorize the above Protected Health Information to be disclosed by AHS to CLEAR for the
        reason set forth below.
      </Typography>
      <Typography component="p" className={classes.para}>
        I authorize AHS to disclose such Protected Health Information to CLEAR and CLEAR to use such
        Protected Health Information for the following reasons:
      </Typography>
      <ul>
        <li>
          To allow CLEAR to create a digital vaccine card for my use and provide me services related
          to the vaccine card product.
        </li>
      </ul>

      <Typography component="p" className={classes.para}>
        I may revoke this authorization in writing at any time by contacting AHS. I understand my
        revocation will not affect any uses or disclosures of my Protected Health Information that
        were made by AHS in reliance on this authorization before AHS’ receipt of my revocation. If
        I do not revoke it, this authorization will expire upon fulfilling the reason for use and
        disclosure of my Protected Health Information noted above. If I wish to revoke this
        authorization, I must write to AHS at: info@atlantichealth.org
      </Typography>
      <Typography component="p" className={classes.para}>
        This authorization is voluntary, and I understand that once disclosure of Protected Health
        Information is authorized, it may be re-disclosed by the recipient and no longer protected
        by federal privacy regulations.
      </Typography>
      <Typography component="p" className={classes.para}>
        I have had full opportunity to read and consider the contents of this authorization, and I
        agree to it.
      </Typography>
    </div>
  );
};

export default HippaDocument;
