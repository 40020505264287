import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import EditableComponent from "../../../EditableComponent";
import { handleEditProvider, onCancelSection } from "../../Common/SectionsEditHandler";
import { useProviderContext } from "../../ProviderContext";
import AdditionalInformationSection from "./AdditionalInformationSection";
import InsuranceAcceptedSection from "./InsuranceAcceptedSection";
import PersonalInformationSection from "./PersonalInformationSection";
import PracticeLocationSection from "./PracticeLocationSection";
import PracticeSection from "./PracticeTabSection";
import SchedulingInformationSection from "./SchedulingInformationSection";
import { useStyles } from "../../../utils/styles";
import { useApp } from "util/AppContext";
import { EditableComponentProps } from "../../types";

const GeneralInfoAndLocation: React.FC = () => {
  const { classes } = useStyles();
  const app = useApp();
  const { providerId } = useParams<{ providerId: string }>();
  const {
    currentPayload,
    setCurrentPayload,
    currentlyEditing,
    setCurrentlyEditing,
    selectedTab,
    refetch,
  } = useProviderContext();

  const [sections, setSections] = useState({
    personalInformation: true,
    practice: true,
    additionalInformation: true,
    insurancesAccepted: true,
    schInformation: true,
    practiceLocations: true,
  });

  const [isEditing, setIsEditing] = useState({
    personalInformation: false,
    practice: false,
    additionalInformation: false,
    insurancesAccepted: false,
    schInformation: false,
    practiceLocations: false,
  });

  const [isLoading, setIsLoading] = useState({
    personalInformation: false,
    practice: false,
    additionalInformation: false,
    schInformation: false,
    practiceLocations: false,
  });

  const handleCancelSection = () => {
    onCancelSection({
      currentlyEditing,
      setIsEditing,
      setSections,
      setIsLoading,
      setCurrentPayload,
      setCurrentlyEditing,
    });
  };

  const profileConfiguration: EditableComponentProps[] = [
    {
      title: "Personal Information",
      slug: "personalInformation",
      component: PersonalInformationSection,
    },
    {
      title: "Practice",
      slug: "practice",
      component: PracticeSection,
    },
    {
      title: "Additional Information",
      slug: "additionalInformation",
      component: AdditionalInformationSection,
    },
    {
      title: "Insurances Accepted",
      slug: "insurancesAccepted",
      component: InsuranceAcceptedSection,
    },
  ];

  const rightPanelConfiguration: EditableComponentProps[] = [
    {
      title: "Practice Locations",
      slug: "practiceLocations",
      component: PracticeLocationSection,
    },
    {
      title: "Scheduling Information",
      slug: "schInformation",
      component: SchedulingInformationSection,
    },
  ];

  useEffect(() => {
    handleCancelSection();
  }, [selectedTab]);

  return (
    <Grid container paddingTop="24px">
      <Grid item xs={12} md={8} paddingRight="32px">
        {profileConfiguration.map(({ title, component, slug, customAction, data }) => (
          <EditableComponent
            key={slug}
            title={title}
            onEdit={() =>
              handleEditProvider(
                slug,
                setIsEditing,
                setSections,
                setCurrentlyEditing,
                currentPayload,
                setIsLoading,
                providerId,
                refetch,
                app,
                handleCancelSection,
              )
            }
            customAction={customAction}
            isEditing={isEditing[slug]}
            isLoading={isLoading[slug]}
            disableSection={currentlyEditing !== null && currentlyEditing !== slug}
            currentPayload={currentPayload}
            onCancel={handleCancelSection}
          >
            {component({ shouldDisableForm: sections[slug], data })}
          </EditableComponent>
        ))}
      </Grid>
      <Grid item xs={12} md={4} className={classes.generalInfo_rightColumn}>
        {rightPanelConfiguration.map(({ title, component, slug, customAction, data }) => (
          <EditableComponent
            key={slug}
            title={title}
            onEdit={() =>
              handleEditProvider(
                slug,
                setIsEditing,
                setSections,
                setCurrentlyEditing,
                currentPayload,
                setIsLoading,
                providerId,
                refetch,
                app,
                handleCancelSection,
              )
            }
            customAction={customAction}
            isEditing={isEditing[slug]}
            isLoading={isLoading[slug]}
            disableSection={currentlyEditing !== null && currentlyEditing !== slug}
            onCancel={handleCancelSection}
            currentPayload={currentPayload}
          >
            {component({ shouldDisableForm: sections[slug], data })}
          </EditableComponent>
        ))}
      </Grid>
    </Grid>
  );
};

export default GeneralInfoAndLocation;
