import React from "react";
import moment from "moment";
import { List } from "@mui/material";
import ConversationListItem from "./ConversationListItem";
import NoConversations from "./NoConversations";
import { PROGRAM_TYPES } from "util/constants";
import { useConversationStyles } from "./useConversationStyles";
import { getLocalTime } from "../../util/util_functions";
import { getLabel, programsIcons } from "./pcUtils";
import { isRNApp } from "util/RNWebView";
import { ConversationListProps } from "./types";

const ConversationList = ({ conversations }: ConversationListProps) => {
  const { classes, cx } = useConversationStyles();

  return (
    <List
      className={cx({
        [classes.queueContainer]: true,
        [classes.queueContainerNativeApp]: isRNApp,
      })}
    >
      {conversations?.length ? (
        conversations.map(({ vs, program, vs_message }) => {
          const label = getLabel(vs);
          const labelStr = program.program_type !== "consultation" && label ? `- ${label}` : "";
          const listItemTitle = `${program?.name} ${labelStr}`;
          const lastMessage = vs_message?.message || "";
          const icon = programsIcons[program?.name];
          const timeStr = lastMessage
            ? getLocalTime(vs_message?.event_dttm)
            : getLocalTime(vs?.start_dttm);

          const hasRequestedStartPassed = moment(vs.requested_start_beg).isBefore(new Date());
          const canBeRescheduled =
            vs.type === PROGRAM_TYPES.consultation &&
            !!vs.requested_start_beg &&
            !hasRequestedStartPassed;

          return (
            <ConversationListItem
              key={vs?.id}
              sessionId={vs?.id}
              listItemTitle={listItemTitle}
              lastMessage={lastMessage}
              icon={icon}
              timeStr={timeStr}
              canBeRescheduled={canBeRescheduled}
              program={program}
            />
          );
        })
      ) : (
        <NoConversations />
      )}
    </List>
  );
};

export default ConversationList;
