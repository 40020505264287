import React from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { MobileNavigationLink } from "../MobileNavigationLink";
import PageContainer from "../../../components/Mobile/PageContainer/PageContainer";
import CONSTANTS from "../constants";
import BannerButton from "components/Mobile/BannerButton/BannerButton";
import Hero from "components/Mobile/Hero/Hero";
import UrgentCareBanner from "components/Mobile/UrgentCareBanner/UrgentCareBanner";
import useTranslation from "../../../hooks/useTranslation";
import { useStyles } from "../../../styles/Mobile/locationDetail.styles";
import AllLocationsIcon from "../../../static/svg/mobile/alllocations.svg";
import IndoorGPSIcon from "../../../static/svg/mobile/indoorgps.svg";
import ParkingIcon from "../../../static/svg/mobile/parking.svg";
import PatientVisitorIcon from "../../../static/svg/mobile/patientvisitor.svg";
import PhoneIcon from "../../../static/svg/mobile/phone.svg";

interface LocationDetailProps {
  variant: "hospital" | "urgentCare";
}

const LocationDetail = ({ variant }: LocationDetailProps) => {
  const { classes } = useStyles();
  const location = useLocation();
  const { title, img, address, cityzipcode, directions, phone, more, parkingUrl, indoor } =
    location.state?.location || {};

  const { mobile } = useTranslation();
  const {
    DrivingDirections,
    PatientVisitorServices,
    PatientResources,
    VisitorInformation,
    ParkingAccommodations,
    IndoorGPS,
    UrgentCareMore,
  } = mobile;
  const isHospitalVariant = variant === "hospital";

  const isCentraState = title === "CentraState Medical Center";
  const centraStatePatientResourcesUrl = "https://www.centrastate.com/patients/";
  const centraStateVisitorInformationUrl = "https://www.centrastate.com/visitors/";

  const launchTakeMeThere = () => {
    const launchiTunes =
      "https://apps.apple.com/us/app/take-me-there-atlantic-health/id1378622571?mt=8";
    const launchGooglePlay =
      "https://play.google.com/store/apps/details?id=com.connexient.medinav.morristown";

    const { userAgent } = navigator;
    const isAndroid = /android/i.test(userAgent);
    const appToLaunch = isAndroid ? launchGooglePlay : launchiTunes;

    window.location.href = isAndroid ? "Atlanticomc://" : "Morristown://";

    setTimeout(() => {
      window.location.href = appToLaunch;
    }, 1000);
  };

  const urgentCareLinks: Array<MobileNavigationLink> = [
    {
      destination: directions,
      icon: AllLocationsIcon,
      title: DrivingDirections,
      testId: "location-detail-view-driving-directions-button",
      shouldPushNavigation: false,
    },
    {
      destination: `tel:${phone}`,
      icon: PhoneIcon,
      title: phone,
      testId: "location-detail-view-phone-button",
      shouldPushNavigation: false,
    },
    {
      destination: more,
      icon: PatientVisitorIcon,
      title: UrgentCareMore,
      testId: "location-detail-more-button",
      shouldPushNavigation: false,
    },
  ];

  const hospitalLinks: Array<MobileNavigationLink> = [
    {
      destination: directions,
      icon: AllLocationsIcon,
      title: DrivingDirections,
      testId: "location-detail-view-driving-directions-button",
      shouldPushNavigation: false,
    },
    {
      destination: `tel:${phone}`,
      icon: PhoneIcon,
      title: phone,
      testId: "location-detail-view-phone-button",
      shouldPushNavigation: false,
    },
    {
      destination: isCentraState ? null : `${CONSTANTS.mobileBaseUrl}patientvisitor`,
      icon: PatientVisitorIcon,
      title: PatientVisitorServices,
      testId: "location-detail-view-patient-visitor-button",
      shouldPushNavigation: true,
    },
    {
      destination: parkingUrl,
      icon: ParkingIcon,
      title: ParkingAccommodations,
      testId: "location-detail-view-parking-button",
      shouldPushNavigation: false,
    },
    {
      destination: indoor,
      customOnClick: launchTakeMeThere,
      icon: IndoorGPSIcon,
      title: IndoorGPS,
      testId: "location-detail-view-indoor-gps-button",
      shouldPushNavigation: false,
    },
  ];

  const centraStateOptions = [
    {
      destination: centraStatePatientResourcesUrl,
      icon: PatientVisitorIcon,
      title: PatientResources,
      testId: "location-detail-view-patient-visitor-button",
      shouldPushNavigation: false,
    },
    {
      destination: centraStateVisitorInformationUrl,
      icon: AllLocationsIcon,
      title: VisitorInformation,
      testId: "location-detail-view-patient-visitor-button",
      shouldPushNavigation: false,
    },
  ];

  const linksToUse = isHospitalVariant ? hospitalLinks : urgentCareLinks;

  return (
    <PageContainer>
      {!isHospitalVariant && <UrgentCareBanner />}
      <div className={!isHospitalVariant ? classes.urgentCareMargin : ""}>
        <Hero variant="image" imgSrc={img} />
        <div className={classes.contentContainer}>
          <div className={classes.contentHeader}>
            <Typography className={classes.locationTitle}>{title}</Typography>
            <Typography className={classes.locationSubtitle}>{address}</Typography>
            <Typography className={classes.locationSubtitle}>{cityzipcode}</Typography>
          </div>
          {linksToUse
            .filter(({ destination }) => !!destination)
            .map(({ title, destination, icon, testId, shouldPushNavigation, customOnClick }) => (
              <BannerButton
                key={title}
                title={title}
                border="orange"
                showRightArrow
                customOnClick={customOnClick}
                navigateTo={destination as string}
                navigateFrom={isHospitalVariant ? CONSTANTS.HOSPITAL : CONSTANTS.URGENTCARE}
                shouldPushNavigation={shouldPushNavigation}
                icon={icon}
                testId={testId}
              />
            ))}
          {isCentraState &&
            centraStateOptions.map(({ title, destination, icon, testId, shouldPushNavigation }) => (
              <BannerButton
                key={title}
                title={title}
                border="orange"
                showRightArrow
                navigateTo={destination}
                navigateFrom={isHospitalVariant ? CONSTANTS.HOSPITAL : CONSTANTS.URGENTCARE}
                shouldPushNavigation={shouldPushNavigation}
                icon={icon}
                testId={testId}
              />
            ))}
        </div>
      </div>
    </PageContainer>
  );
};

export default LocationDetail;
