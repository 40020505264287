/** @format */
import React, { useState } from "react";
import { useAuth } from "../../util/Security";
import { getApiHost } from "../../util/api_helper";

const CreateClient = ({ setClient, client }) => {
  // const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const [user, setUser] = useState({
    client_name: "",
    client_uri: "",
    scope: "",
    redirect_uri: "",
    grant_type: "",
    response_type: "",
    token_endpoint_auth_method: "client_secret_basic",
  });
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const apiHost = getApiHost();
    // setLoading(true);
    fetch(`${apiHost}/api/s/console/admin/create-client`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ ...user, user_id: auth.user.userId }),
    })
      .then((resp) => {
        return resp.json();
      })
      .then(({ data }) => {
        // setLoading(false);
        setClient({
          ...client,
          ...data,
        });
      })
      .catch(() => {
        // setLoading(false);
      });
  };

  return (
    <div>
      <form action="" onSubmit={handleFormSubmit}>
        <label htmlFor="client_name">
          <span>Client Name</span>
          <input
            id="client_name"
            type="text"
            name="client_name"
            value={user.client_name}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="client_uri">
          <span>Client URI</span>
          <input
            id="client_uri"
            type="url"
            name="client_uri"
            value={user.client_uri}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="scope">
          <span>Allowed Scope</span>
          <input id="scope" type="text" name="scope" value={user.scope} onChange={handleChange} />
        </label>
        <label htmlFor="redirect_uri">
          <p>Redirect URIs</p>
          <textarea
            id="redirect_uri"
            name="redirect_uri"
            cols="30"
            rows="10"
            value={user.redirect_uri}
            onChange={handleChange}
          />
        </label>

        <label htmlFor="grant_type">
          <p>Allowed Grant Types</p>
          <textarea
            id="grant_type"
            name="grant_type"
            cols="30"
            rows="10"
            value={user.grant_type}
            onChange={handleChange}
          />
        </label>

        <label htmlFor="response_type">
          <p>Allowed Response Types</p>
          <textarea
            id="response_type"
            name="response_type"
            cols="30"
            rows="10"
            value={user.response_type}
            onChange={handleChange}
          />
        </label>

        <label htmlFor="token_endpoint_auth_method">
          <p>Token Endpoint Auth Method</p>
          <select
            id="token_endpoint_auth_method"
            name="token_endpoint_auth_method"
            value={user.token_endpoint_auth_method}
            onChange={handleChange}
            data-testid="select"
          >
            <option value="client_secret_basic">client_secret_basic</option>
            <option value="client_secret_post">client_secret_post</option>
            <option value="none">none</option>
          </select>
        </label>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default CreateClient;
