import React from "react";
import theme from "Theme";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
import { useCustomStyles } from "./useCustomStyles";

const ReloadButton = () => {
  const { classes } = useCustomStyles(theme);
  return (
    <Button
      variant="contained"
      className={classes.reloadBtn}
      startIcon={<RefreshIcon />}
      onClick={() => window.location.reload()}
    >
      Reload App
    </Button>
  );
};

export default ReloadButton;
