/** @format */
import React from "react";
import Theme from "Theme";
import { Typography } from "@mui/material";
import { useSearchHeader } from "./styles";

const SearchHeader = ({ records }) => {
  const { classes } = useSearchHeader();

  return (
    <div style={{ padding: "10px 15px", marginBottom: "10px" }}>
      <Typography className={classes.padeHeading} variant="h5">
        Patient Search Results
      </Typography>
      <Typography className={classes.heading}>
        <small style={{ color: Theme.palette.grey[600] }}>
          {records.length} record{records.length > 1 ? "s" : ""} found
        </small>
      </Typography>
    </div>
  );
};

export default SearchHeader;
