import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { SpecimenAddResponse, SpecimenInfoAddNewManuallyData } from "views/SpecimenTracking/types";

export const useAddSpecimenTrackManually = () => {
  const addSpecimenTrackManually = (data: SpecimenInfoAddNewManuallyData) => {
    const resp = axiosClient.post<SpecimenAddResponse>(
      API_ENDPOINTS.specimenTrackingAddSpecimenWithIds,
      {
        ...data,
      },
    );
    return resp;
  };

  return {
    addSpecimenTrackManually,
  };
};
