import { createContext } from "react";
import { CreateLocalTrackOptions, LocalAudioTrack, LocalVideoTrack, Room } from "twilio-video";
import { ErrorCallback } from "components/Video/types";

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack | void>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack | void>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
}

export const VideoContext = createContext<IVideoContext>(null!);
