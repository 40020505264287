import { Add } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GenericEnhancedTable, { defaultGridConfig } from "../../components/GenericEnhancedTable";
import { API_ENDPOINTS } from "../../util/Api_Endpoints";
import { IRecord } from "./types";
import EditUserModal from "./UserListModal";
import { colDefs } from "./utils";

export default function UserListView() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [recordToBeEdit, setRecordToBeEdit] = useState<IRecord | false>(false);
  const [mode, setMode] = useState<"add" | "edit">("add");

  const gridConfig = {
    ...defaultGridConfig,
    allowDelete: false,
    showTableToolbar: false,
  };

  const handleEditRecord = (record: IRecord) => {
    setMode("edit");
    setShowModal(true);
    setRecordToBeEdit(record);
  };

  const addNewRecord = () => {
    setMode("add");
    setRecordToBeEdit({
      id: "",
      username: "",
      email: "",
      cell_phone: "",
      user_type: "",
      user_status: "",
    });
    setShowModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let timeoutId: ReturnType<typeof setTimeout>;
    const handlePopState = () => { timeoutId = setTimeout(() => window.scrollTo(0, 0), 0); return null; }
    window.addEventListener("popstate", handlePopState);
    return () => { clearTimeout(timeoutId); return window.removeEventListener("popstate", handlePopState) };
  }, []);

  return (
    <Box padding={["24px 24px 16px 24px", "40px 32px 16px 32px"]}>
      <Grid container alignItems="flex-end" justifyContent="center" data-testid="userListContainer">
        <Box paddingBottom={["40px", "20px"]}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => addNewRecord()}
          >
            Add
          </Button>
        </Box>

        <GenericEnhancedTable
          gridConfig={gridConfig}
          /* @ts-ignore-next-line */
          gridType="Available"
          tableTitle="User List"
          colDefs={colDefs}
          url={API_ENDPOINTS.get_staff_report}
          orderBy="last_login_at"
          order="desc"
          defaultSearchBy="email"
          onEditHandler={handleEditRecord}
          refreshSourceData={!showModal}
          hideCheckboxes
        />

        <EditUserModal
          show={showModal}
          setShow={setShowModal}
          /* @ts-ignore-next-line */
          record={recordToBeEdit}
          mode={mode}
        />
      </Grid>
    </Box>
  );
}
