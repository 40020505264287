/* eslint-disable */
import React from "react";
import { Message } from "@twilio/conversations";
import MediaMessage from "./MediaMessage/MediaMessage";
import MessageInfo from "./MessageInfo/MessageInfo";
import MessageListScrollContainer from "./MessageListScrollContainer/MessageListScrollContainer";
import TextMessage from "./TextMessage/TextMessage";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useAuth } from "../../../../../util/Security";

interface MessageListProps {
  messages: Message[];
}

const getFormattedTime = (message?: Message) =>
  message?.dateCreated
    ?.toLocaleTimeString("en-us", { hour: "numeric", minute: "numeric" })
    .toLowerCase();

export default function MessageList({ messages }: MessageListProps) {
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const auth = useAuth();
  const { conversation } = useChatContext();

  return (
    <MessageListScrollContainer messages={messages}>
      {messages?.map((message, idx) => {
        const time = getFormattedTime(message)!;
        const previousTime = getFormattedTime(messages[idx - 1]);
        const isConsumer = auth?.user?.userType === "consumer";

        // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        const shouldDisplayMessageInfo =
          time !== previousTime || message.author !== messages[idx - 1]?.author;

        const isLocalParticipant = isConsumer
          ? localParticipant?.identity === message.author
          : localParticipant?.identity === message.author || message.author === "system";
        // @ts-ignore
        const isAuthor = isConsumer
          ? // @ts-ignore
            message.author === conversation?.configuration?.userIdentity
          : message.author === "system" ||
            // @ts-ignore
            message.author === conversation?.configuration?.userIdentity;

        // @ts-ignore
        return (
          <React.Fragment key={message.sid}>
            {shouldDisplayMessageInfo && (
              <MessageInfo
                author={message.author ? message.author : ""}
                isLocalParticipant={room ? isLocalParticipant : isAuthor}
                dateCreated={time}
              />
            )}
            {message.type === "text" && (
              <TextMessage
                body={message.body ? message.body : ""}
                isLocalParticipant={room ? isLocalParticipant : isAuthor}
              />
            )}
            {message.type === "media" && message.media && <MediaMessage media={message.media} />}
            {/* @ts-expect-error */}
            {message?.message && (
              <TextMessage
                // @ts-ignore
                body={message?.message}
                isLocalParticipant={
                  isConsumer
                    ? // @ts-ignore
                      Number(auth.user.userId) === message?.user_id
                    : // @ts-ignore
                      message.author === "system" || Number(auth.user.userId) === message?.user_id
                }
              />
            )}
          </React.Fragment>
        );
      })}
    </MessageListScrollContainer>
  );
}
