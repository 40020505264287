import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

type EditWarningPropsTypes = {
  title: string;
  bodyText: string;
  handleClose: () => void;
  handleSubmit: () => void;
};

const EditWarningDialog = ({
  title,
  bodyText,
  handleClose,
  handleSubmit,
}: EditWarningPropsTypes) => {
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{bodyText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          data-testid="appointment-warning-continue-button"
          data-cy="appointment-warning-continue-button"
        >
          Continue
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          data-testid="appointment-warning-close-button"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditWarningDialog;
