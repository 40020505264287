import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useLocation } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { QuerySteps } from "../../Steps";
import { FormValues, initialValues, validationSchema } from "./formModel";
import UploadImages from "components/FileUploadWidget/upload-images.component";
import { UPDATE_AND_SELECT_VIRTUAL_VISIT } from "components/Video/hooks/useVirtualVisitReducer/Actions";
import { Program } from "components/Video/utils/types";
import CellPhone from "components/shared/CellPhone";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { Mask, OTHER_VISIT_REASON } from "util/constants";
import { useUploadImages } from "../../useUploadImages";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import { useStyles } from "./styles";

type Props = {
  vsId: number;
  program: Program | undefined;
  showOffHoursMsg: boolean;
  setStep: (Step: QuerySteps) => void;
};

const VisitReasonForm: React.FC<Props> = ({ vsId, program, showOffHoursMsg, setStep }) => {
  const { classes } = useStyles();
  const app = useApp();
  const { user } = useAuth();
  const { isLoading: isLoadingImages, uploadImages } = useUploadImages();
  const location = useLocation();
  const isForceInvite = !!location.pathname.includes("videoinvite");

  const [visitReasons, setVisitReasons] = useState<string[]>([]);

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      const isUploadSuccessful = await uploadImages();
      if (!isUploadSuccessful) {
        return;
      }

      const patientVisitReason = {
        virtual_session_id: vsId,
        patient_reason:
          values.visitReason !== OTHER_VISIT_REASON ? values.visitReason : values.otherVisitReason,
        visit_details: values.visitReasonDetails,
        accidentRelated: values.accidentRelated,
        first_name: values.firstName,
        last_name: values.lastName,
        cell_phone: values.cellPhone,
        dob: values.dob,
        zip: values.zip,
      };

      const { data } = await axiosClient.post(
        API_ENDPOINTS.twilio_set_visit_reason,
        patientVisitReason,
      );

      if (
        (isForceInvite || !showOffHoursMsg) &&
        (!program || program.program_override_status !== "At Max Capacity")
      ) {
        setStep(QuerySteps.INSURANCE_DETAILS);
        app.dispatch({
          type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
          payload: {
            ...app.vs_visits_state.currentVirtualVisit,
            ...data,
          },
        });
      } else if (
        showOffHoursMsg ||
        (program && program.program_override_status === "At Max Capacity")
      ) {
        app.addInfoMsg("Successfully saved your request for a visit");
        setTimeout(() => {
          window.location.pathname = "/home";
        }, 2000);
      }
    } catch (e) {
      app.addError(
        "Failed to save visit reason, please check all required fields and try again or contact support.",
      );
    }
    actions.setSubmitting(false);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getVisitReasons = async () => {
    try {
      const url = `/api/s/config/programs/${program?.id}/visit-reasons`;
      const { data } = await axiosClient.get(url);
      setVisitReasons([...data.data, OTHER_VISIT_REASON]);
    } catch (error) {
      app.addError("Something went wrong. Please, contact support.");
    }
  };

  const getVirtualSession = async () => {
    try {
      const { data } = await axiosClient.post(API_ENDPOINTS.twilio_get_virtual_session, {
        vs_id: vsId,
      });

      if (data?.visit_reason) {
        if (!visitReasons.includes(data?.visit_reason)) {
          setFieldValue("otherVisitReason", data?.visit_reason);
          setFieldValue("visitReason", OTHER_VISIT_REASON);
        } else {
          setFieldValue("visitReason", data?.visit_reason);
        }
      }
      setFieldValue("visitReasonDetails", data.visit_details);
      setFieldValue("accidentRelated", data.accident_related === true ? "yes" : "no");
    } catch (e) {
      app.addError("Something went wrong. Please, contact support.");
    }
  };

  useEffect(() => {
    if (program?.id) {
      getVisitReasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program?.id]);

  useEffect(() => {
    if (vsId && !!visitReasons.length) {
      getVirtualSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId, visitReasons]);

  useEffect(() => {
    setFieldValue("firstName", user?.firstName || "");
    setFieldValue("lastName", user?.lastName || "");
    setFieldValue("dob", user?.dob || null);
    setFieldValue("cellPhone", user?.cellPhone || "");
    setFieldValue("zip", user?.zip || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId]);

  return (
    <>
      <div className={classes.mainTitle}>What's the reason for your visit today?</div>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        {(isForceInvite || !showOffHoursMsg) && (
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <Autocomplete
                id="visitReason"
                fullWidth
                options={visitReasons}
                getOptionLabel={(option) => option}
                onChange={(_, value) => setFieldValue("visitReason", value)}
                onBlur={handleBlur}
                value={values.visitReason}
                renderInput={(params) => (
                  <TextField
                    label="Visit Reason"
                    name="visitReason"
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    variant="outlined"
                    error={touched.visitReason && !!errors.visitReason}
                    helperText={touched.visitReason && errors.visitReason}
                    {...params}
                  />
                )}
              />
            </FormControl>
            {values.visitReason === OTHER_VISIT_REASON && (
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="otherVisitReason"
                  fullWidth
                  multiline
                  minRows={4}
                  label="Other Visit Reason"
                  name="otherVisitReason"
                  value={values.otherVisitReason}
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.otherVisitReason && !!errors.otherVisitReason}
                  helperText={touched.otherVisitReason && errors.otherVisitReason}
                />
              </FormControl>
            )}
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="visitDetails"
                label="Visit details (optional)"
                multiline
                minRows={4}
                variant="outlined"
                name="visitReasonDetails"
                value={values.visitReasonDetails}
                onChange={handleChange}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
              />
            </FormControl>
            {user && !user.firstName && (
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="firstName"
                  label="First Name"
                  InputProps={{
                    className: classes.inputRoot,
                  }}
                  variant="outlined"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  error={touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
              </FormControl>
            )}
            {user && !user.lastName && (
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="lastName"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    className: classes.inputRoot,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  error={touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
              </FormControl>
            )}
            {user && !user.dob && (
              <FormControl variant="outlined" className={classes.formControl}>
                <DatePicker
                  slotProps={{
                    textField: {
                      id: "dateOfBirth",
                      fullWidth: true,
                      variant: "outlined",
                      helperText: (touched?.dob && errors?.dob) ?? "MM/DD/YYYY",
                      error: Boolean(touched?.dob && errors?.dob),
                    },
                  }}
                  label="Date of Birth"
                  format="MM/dd/yyyy"
                  openTo="month"
                  name="dob"
                  value={values.dob}
                  onChange={(date) => {
                    setFieldValue("dob", date);
                  }}
                  onError={(error) => {
                    if (error && error !== errors.dob) {
                      setFieldError("dob", error.toString());
                    }
                  }}
                />
              </FormControl>
            )}
            {user && !user.zip && (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputMask
                  alwaysShowMask={false}
                  mask={Mask.ZIP_CODE}
                  name="zip"
                  value={values.zip}
                  onChange={(e) => setFieldValue("zip", e.target.value.replace(/\D/g, ""))}
                  onBlur={handleBlur}
                >
                  {/* @ts-ignore */}
                  {(inputProps) => (
                    <TextField
                      id="zip"
                      label="Zip"
                      variant="outlined"
                      InputProps={{
                        className: classes.inputRoot,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.label,
                        },
                      }}
                      error={touched.zip && !!errors.zip}
                      helperText={touched.zip && errors.zip}
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </FormControl>
            )}
            {user && !user.cellPhone && (
              <div className={classes.formControl}>
                <CellPhone
                  cellPhone={values.cellPhone}
                  setCellPhone={(value) => setFieldValue("cellPhone", value)}
                  error={Boolean(errors?.cellPhone)}
                  helperText={errors?.cellPhone}
                />
              </div>
            )}
            <FormControl variant="outlined" className={classes.radioFormControl}>
              <FormLabel>Is this Accident Related ?</FormLabel>
              <RadioGroup
                row
                aria-label="accident_related"
                name="accidentRelated"
                value={values.accidentRelated}
                onChange={handleChange}
              >
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </FormControl>
            <div className={classes.mainSubtitle}>
              If a photo would help with your visit, add it here. (optional)
            </div>
            <div className="container">
              <UploadImages
                contextType="VirtualSession"
                contextID={vsId}
                upload_msg="Take a picture of your symptoms"
                imageType="Chief Complaint Info"
              />
            </div>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || isSubmitting || isLoadingImages}
          className={classes.button}
          startIcon={isLoadingImages ? <CircularProgress /> : ""}
        >
          {program && program.program_override_status === "At Max Capacity" && !isForceInvite
            ? "Return to home"
            : isLoadingImages
            ? "Uploading images..."
            : "Next"}
        </Button>
      </form>
    </>
  );
};

export default VisitReasonForm;
