import React, { useCallback, useState } from "react";
import { Add, GetApp } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import SpecimenTrackingLocationModal from "./SpecimenTrackingLocationModal";
import GenericEnhancedTable, { defaultGridConfig } from "../../components/GenericEnhancedTable";
import { API_ENDPOINTS } from "../../util/Api_Endpoints";
import { colDefs } from "./utils";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { NewSpecimenLocationInfo } from "./types";

const SpecimenTrackingLocations = () => {
  const app = useApp();
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState("add");
  const [recordToBeEdit, setRecordToBeEdit] = useState<NewSpecimenLocationInfo | null>(null);

  const gridConfig = {
    ...defaultGridConfig,
    allowEdit: true,
    allowDelete: false,
    showSearchBar: false,
  };

  const openAddModal = () => {
    setMode("add");
    setRecordToBeEdit({
      specimenTrackingLocationName: "",
      specimenTrackingLocationDescription: "",
      departmentId: undefined,
      finalDestination: false,
    });
    setShowModal(true);
  };

  const openEditModal = useCallback((record) => {
    setMode("edit");
    setShowModal(true);
    setRecordToBeEdit(record);
  }, []);

  const downloadQR = async (row) => {
    try {
      const url = API_ENDPOINTS.specimenTrackingLocationsDownloadQR;
      const { data } = await axiosClient.get(url, {
        params: {
          barcode: row.barcode,
        },
      });
      const a = document.createElement("a");
      a.href = `data:image/png;base64,${data}`;
      a.download = `qr-code-${row.name}`;
      a.click();
    } catch (e) {
      app.addError("Problem downloading image file. Please, contact support.");
    }
  };

  return (
    <Box>
      <Grid container alignItems="flex-end" justifyContent="center">
        <Box paddingBottom={["40px", "20px"]}>
          <Button
            data-testid="open-add-modal-button"
            data-cy="AddSpecimenTrackingLocationsModalBtn"
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={openAddModal}
          >
            Add
          </Button>
        </Box>

        {/* @ts-ignore-next-line */}
        <GenericEnhancedTable
          gridConfig={gridConfig}
          tableTitle="Specimen Locations"
          colDefs={colDefs}
          url={API_ENDPOINTS.specimenTrackingLocations}
          onEditHandler={openEditModal}
          refreshSourceData={!showModal}
          hideCheckboxes
          makeCustomActions={(row) => [
            {
              startIcon: <GetApp />,
              text: "Download QR",
              onClick: () => downloadQR(row),
              key: "download-qr-button",
              "data-testid": "download-qr-button",
            },
          ]}
          orderBy="name"
          order="asc"
        />

        <SpecimenTrackingLocationModal
          show={showModal}
          setShow={setShowModal}
          mode={mode}
          record={recordToBeEdit}
        />
      </Grid>
    </Box>
  );
};

export default SpecimenTrackingLocations;
